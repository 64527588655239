import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import footerImg from '../../../../integration-engine-custom-mapper-api/app/assets/PDF-Footer.png'

const useStyles = makeStyles(() => ({
  footer: {
    padding: '30px 55px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: `url(${footerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
    backgroundSize: 'cover',
    color: 'white',
  },
  pageNumber: {
    fontSize: '40px',
    lineHeight: '55px',
    paddingRight: '40px',
    borderRight: '4px solid #00b2a9',
  },
  versionInfo: {
    fontSize: '16px',
    marginRight: '40px',
  },
}))

const ReportFooter = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.footer}>
          <span className={classes.pageNumber}>1</span>
          <span className={classes.versionInfo}>
            Data processed using Engine B Integration Engine {process.env.NX_APP_VERSION}
          </span>
        </div>
      </Grid>
    </Grid>
  )
}

export default ReportFooter
