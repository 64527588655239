import { gql } from '@apollo/client'

export const GET_PRE_UPLOADED_EXTRACTION_FILES = gql`
  query PreUploadedApiExtractionFiles(
    $clientName: String!
    $engagement: String!
  ) {
    preUploadedApiExtractionFiles(
      clientName: $clientName
      engagement: $engagement
    ) {
      name
      lastModified
      contentLength
    }
  }
`
