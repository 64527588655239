import { useApolloClient } from '@apollo/client'
import {
  getJoins,
  useIESelector,
  useIEDispatch,
  resetBasicDetails,
  JOIN_STATUS_COLORS,
  JOIN_STATUS,
  JOIN_STATUS_TITLES,
  setOutputFileDownloadStatus,
  setOutputFileDownloadProgress,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import TableJoinsFilters from './TableJoinsViewFilters'
import { useIntl } from 'react-intl'
import { ReactComponent as IconDownload } from '../../assets/IconDownload.svg'
import { ReactComponent as IconError } from '../../assets/IconAlertError.svg'
import {
  downloadFileFromAzureContainer,
  BlobContentTypes,
} from '@engine-b/integration-engine/data/azure-data-factory'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { enUS, enGB } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { EBTable, Dialog } from '@engine-b/shared/components'
import { trackPageView } from '../../services/AppInsights'

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      display: 'flex',
      '& td': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
    createJoinsContainer: {
      display: 'flex',
      gap: 15,
      padding: '25px 0px 25px 0px',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('lg')]: {
        padding: '0px 0px 25px 0px',
        justifyContent: 'flex-end',
      },
    },
    sectionStyles: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: `#F0FBFA 0% 0% no-repeat padding-box`,
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px !important',
      },
    },
    header: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '30px',
      lineHeight: '37px',
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
    },
    createJoinButton: {
      fontSize: '1rem',
      fontFamily:
        'Roboto, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    setErrorButton: {
      minWidth: '50px',
      marginRight: '10px',
      height: '34px',
      borderRadius: '5px',
      width: 'unset',
    },
    PopUpErrorIcon: {
      stroke: "red",
      marginRight: "0.5rem"
    },
    dialogCustomContent: {
      display: "flex",
      alignItems: "flexStart",
      justifyContent: "flexStart",
      flexDirection: "column",
      width: '100%',
      height: '100%'
    },
    PopUpErrorDiv: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 500,
      textAlign: "left",
      color: "#763531",
      padding: "0.5rem 1rem",
      border: '1px solid #763531',
      borderRadius: "0.5rem",
      backgroundColor: "#feeceb",
      width: '100%',
      marginBottom: '0.5rem',
      display: "flex",
      alignItems: "center"
    }
  })
})

export function StatusView() {
  const { 
    container, 
    header, 
    sectionStyles, 
    createJoinsContainer, 
    createJoinButton, 
    setErrorButton,
    dialogCustomContent,
    PopUpErrorIcon, 
    PopUpErrorDiv 
  } = useStyles()

  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const { locale } = useIntl()
  const azureClient = useContext(AzureClientContext)
  const [recordsToShow, setRecordsToShow] = useState<any>(5);
  const [errorDialogState, setErrorDialogState] = useState({
    open: false,
    joinsId: "",
    joinsName: "",
    errors: [],
    systemId: "",
    filePath: "",
    engagement: "",
    client: ""
  })

  const formatDate = (date) => {
    if (date) {
      return format(parseISO(date), 'P, h:mm:ss a', {
        locale: locale === 'en-US' ? enUS : enGB,
      })
    } else {
      return '-'
    }
  }
  const downloadOutPutFile = async (
    fileSystemId: string,
    filePath: string,
    rowIndex: number
  ) => {
    if (!fileSystemId || !filePath) {
      return
    }
    dispatch(setOutputFileDownloadProgress({ rowIndex, downloadProgress: '' }))
    dispatch(setOutputFileDownloadStatus({ rowIndex, isDownloading: true }))
    await downloadFileFromAzureContainer({
      azureClient,
      fileSystemId: fileSystemId,
      filePath,
      contentType: BlobContentTypes.CSV,
      downloadOptions: {
        onProgress: (progress) => {
          dispatch(
            setOutputFileDownloadProgress({
              rowIndex,
              downloadProgress: progress.percentCompletion,
            })
          )
        },
      },
    })
    dispatch(setOutputFileDownloadStatus({ rowIndex, isDownloading: false }))
    dispatch(setOutputFileDownloadProgress({ rowIndex, downloadProgress: '' }))
  }

  const readErrorsFromFile = async () => {
    if (
      errorDialogState.systemId !== '' && 
      errorDialogState.joinsId !== '' &&
      errorDialogState.engagement !== '' &&
      errorDialogState.client !== ''
    ) {
      const containerClient = azureClient.getFileSystemClient(errorDialogState.systemId)
      const fileClient = containerClient.getFileClient(`incoming/${errorDialogState.client}/${errorDialogState.engagement}/joins/${errorDialogState.joinsId}/output/metadata_new.json`)
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      const blob = new Blob([downloadBlob], { type: 'application/json' })
      const metadataJson = await blob.text()
      const fileContent = JSON.parse(metadataJson)
      setErrorDialogState({ ...errorDialogState, errors: fileContent })
    } else {
      setErrorDialogState({ ...errorDialogState, errors: ['No defined error'] })
    }
  }

  useEffect(() => {
    readErrorsFromFile()
  }, [
    errorDialogState.open,
    errorDialogState.joinsId
  ])

  const tableHeadersData = useMemo(() => {
    return [
      {
        title: 'Client Name',
        value: 'client',
        width: '100px',
        renderer: function (params) {
          const {
            row: { engagement },
          } = params
          return <Typography>{engagement.auditedEntity.name}</Typography>
        },
      },
      {
        title: 'Engagement Name',
        value: 'engagement',
        width: '100px',
        renderer: function (params) {
          const {
            row: { engagement },
          } = params
          return <Typography>{engagement.name}</Typography>
        },
      },
      {
        title: 'Name',
        value: 'name',
        width: '100px',
      },
      {
        title: 'Created By',
        value: 'createdBy',
        width: '100px',
      },
      {
        title: 'Start Date',
        value: 'startDate',
        width: '100px',
        renderer: function (params) {
          const {
            row: { startDate },
          } = params
          return <Typography>{formatDate(startDate)}</Typography>
        },
      },
      {
        title: 'End Date',
        value: 'completeAt',
        width: '100px',
        renderer: function (params) {
          const {
            row: { completeAt },
          } = params
          return <Typography>{formatDate(completeAt)}</Typography>
        },
      },
      {
        title: 'Status',
        value: 'status',
        width: '100px',
        renderer: function (params) {
          const {
            row: { status },
          } = params
          return (
            <Typography style={{ color: JOIN_STATUS_COLORS[status] }}>
              {JOIN_STATUS_TITLES[status] || 'N/A'}
            </Typography>
          )
        },
      },
      {
        title: 'Actions',
        value: 'actions',
        width: '100px',
        renderer: function ({ row, rowIndex }) {
          const fileSystemId =
          row?.engagement?.auditedEntity?.auditFirm?.systemName || ''
          const filePath = row?.path
          const isDownloading = row.isDownloading

          if (row.status == JOIN_STATUS.COMPLETED ) {
            return (
              <Tooltip
                title={!filePath ? 'No File Found' : 'Download Output CSV'}
              >
                <span>
                  <Button
                    disabled={!filePath}
                    style={{
                      minWidth: '50px',
                      marginRight: '10px',
                      height: '34px',
                      borderRadius: '5px',
                      width: 'unset',
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={
                      isDownloading
                        ? null
                        : () =>
                          downloadOutPutFile(fileSystemId, filePath, rowIndex)
                    }
                  >
                    {isDownloading ? (
                      <CircularProgress
                        size={'17px'}
                        color="inherit"
                        variant="determinate"
                        value={row.downloadProgress}
                        thickness={7.5}
                      />
                    ) : (
                      <IconDownload />
                    )}
                  </Button>
                </span>
              </Tooltip>
            )
          }
          if (row.status == JOIN_STATUS.FAILED || row.status == JOIN_STATUS.FAILED_TO_PROCESS) {
            return (
              <Tooltip
                title={'Click here for a list of Errors'}
              >
                <span>
                  <Button
                    style={{
                      backgroundColor: JOIN_STATUS_COLORS[row.status]
                    }}
                    className={setErrorButton}
                    color="inherit"
                    variant="contained"
                    onClick={() => 
                      setErrorDialogState({ 
                        open: true, 
                        joinsId: row.id,
                        joinsName: row.name,
                        systemId: row?.engagement?.auditedEntity?.auditFirm?.systemName || '', 
                        filePath: row?.path,
                        engagement: row?.engagement?.name,
                        client: row?.engagement?.auditedEntity?.name,
                        errors: [],
                      })
                    }
                  >
                    <IconError style={{stroke:"#fff"}} />
                  </Button>
                </span>
              </Tooltip>
            )
          } 
          return null 
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadingState = useIESelector((state) => state.joins.loading)
  const joinList = useIESelector((state) => state.joins.joinListing)

  useEffect(() => {
    dispatch(resetBasicDetails({ client }));
    dispatch(getJoins(client))
    trackPageView({name: 'Table Joins'});
  }, [])

  const [filterState, setFilterState] = useState({
    name: '',
    status: 'all',
    engagement: '',
    client: '',
  })

  const filteredJoins = useMemo(() => {
    return joinList.filter((row) => {
      const flagArr = [
        filterState.name === '' ||
        row.name.toLowerCase().includes(filterState.name.toLowerCase()),
        filterState.status === 'all' ||
        filterState.status === JOIN_STATUS[row.status],
        filterState.client === '' ||
        row.engagement?.auditedEntity?.id
          .toLowerCase()
          .includes(filterState.client.toLowerCase()),
        filterState.engagement === '' ||
        row.engagement?.id
          .toLowerCase()
          .includes(filterState.engagement.toLowerCase()),
      ]
      return !flagArr.includes(false)
    })
  }, [joinList, filterState])

  const applyFilter = ({ event, value, filterName }) => {
    setFilterState((state) => ({ ...state, [filterName]: value }))
  }
  const history = useHistory()

  const handleCreateJoin = () => {
    history.push('table-joins/create')
  }

  return (
    <section className={sectionStyles}>
      <Grid container className={container} spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography className={header}>Table Joins</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={createJoinsContainer}>
          <Button
            color='secondary'
            variant='contained'
            className={createJoinButton}
            onClick={handleCreateJoin}
          >
            Create Join
          </Button>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <TableJoinsFilters
            filterState={filterState}
            applyFilter={applyFilter}
          />
        </Grid>
        <Grid container item xs={12} direction="column" style={{ flexGrow: 1 }}>
          <EBTable
            loading={loadingState}
            headers={tableHeadersData}
            rows={filteredJoins}
            recordsPerPage={recordsToShow}
            changeRecords={(recordsToShow: any) => setRecordsToShow(recordsToShow)}
            footerContent={null}
            styles={{
              tableBoxStyles: {
                borderRadius: '8px',
              },
            }}
            emptyLabel={'No Data To Display'}
          />
        </Grid>
        <Dialog
          header={`Errors for ${errorDialogState.joinsName}`}
          open={errorDialogState.open}
          onClose={() => setErrorDialogState({ ...errorDialogState, open: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          confirmText={'OK'}
          width={'550px'}
          height={'400px'}
        >
          <div
            className={dialogCustomContent}
          >
            {errorDialogState.errors.map((error, index) => (
              <div 
                key={error}
                className={PopUpErrorDiv}
              >
                <IconError className={PopUpErrorIcon} />
                <Typography>{error}</Typography>
              </div>
            ))}
          </div>
        </Dialog>
      </Grid>
    </section>
  )
}

export default StatusView
