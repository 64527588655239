import { gql } from '@apollo/client'

export const GET_CUSTOM_ERPS_FOR_EDIT = gql`
  query GetCustomErpsForEdit {
    getCustomErpsForEdit {
      id
      name
      standard
      pipelineId
      extractType
      isActive
      auditFirm {
        id
        name
        systemName
      }
      inProgress
    }
  }
`
