import {
  Backdrop,
  Button,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  TextField,
  styled,
  Popper,
} from '@material-ui/core'
import ClientSystemCard from './ClientSystemCard'
import InfoCard from './InfoCard'
import EmptyBanner from './EmptyBanner'
import ERPInformation from './ERPInformation'
import React, { useContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import BounceLoader from 'react-spinners/BounceLoader'
import {
  useIESelector,
  useIEDispatch,
  submitCustomErp,
  setDialogData,
  Entity,
  getEntites,
  getErps,
  loadConfiguration,
  resetCustomErpState,
  getCustomErpsForEdit,
  updateSubmitError,
  updateConfigError,
  clearErps,
  getCDMVersion,
} from '@engine-b/integration-engine/data/state/redux'
import { Dialog } from '@engine-b/shared/components'
import { ReactComponent as IconError } from '../../assets/IconError.svg'
import {
  AzureClientContext,
  uploadCustomMappingFileToAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
import { useHistory } from 'react-router-dom'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel'

import {
  Description,
  INPUT_WIDTH,
  NEW_UI_INPUT_WIDTH,
} from '@engine-b/integration-engine/ui/form-components'
import { Autocomplete } from '@material-ui/lab'
import { SIDEBAR_WIDTH } from '@engine-b/integration-engine/ui/layout'
import { trackPageView } from '../../services/AppInsights'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '1em',
      height: '1em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      cursor: 'pointer',
      borderRadius: '3px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      transform: 'translateX(0px)',
    },
  },
  customERP: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '72px',
    minHeight: 'calc(100vh - 80px)',
    maxWidth: 'calc(100vw - 280px)',
    padding: '30px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    background: '#F0FBFA 0% 0% no-repeat padding-box',
    '& .save-exit-btn': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'fixed',
      bottom: 0,
      right: 0,
      background: '#F0FBFA 0% 0% no-repeat padding-box',
      [theme.breakpoints.down('md')]: {
        background: '#fff',
      },
      width: 'calc(100% - 280px)',
      height: '72px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
  },
  title: {
    font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
    letterSpacing: '0.3px',
    color: '#22353F',
    opacity: 1,
  },
  innerText: {
    display: 'inline',
    color: `${theme.palette.secondary.main}!important`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  clientSystemCard: {
    marginRight: '30px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
      marginBottom: '30px',
      width: '100%',
    },
  },
  ErpInfoClass: {
    flex: 1,
    width: '65%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  ERPContainer: {
    maxWidth: '100%',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  saveExitBtnItem: {
    justifyContent: 'end',
    marginTop: '30px',
    bottom: '40px',
    right: '0',
    backgroundColor: '#FFFFFF',
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    position: 'fixed',
    padding: '12px 30px',
    zIndex: 1,
  },
  MuiAutocomplete: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    color: 'black',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    marginRight: '10px',
    minWidth: `${INPUT_WIDTH}px`,
    width: NEW_UI_INPUT_WIDTH + 'px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.5px solid ' + theme.palette.secondary.light,
      borderRadius: '8px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'hsla(205, 34%, 34%, 1)',
    },
    '& .MuiInputBase-root': {
      height: '44px',
    },
    '& .MuiInputBase-input': {
      color: 'black',
      fontSize: '14px',
    },
  },
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    fontWeight: 'bold',
    fontSize: 'large',
  },
}))

export const erpTypes = {
  CUSTOM: 'Custom System',
  INDUSTRY: 'Industry System',
  STANDARD: `${
    process.env.NX_STANDARD_ERP_TITLE || 'Engine B'
  } Standard System`,
}

const AddCustomERP = () => {
  const {
    title,
    customERP,
    innerText,
    backdrop,
    clientSystemCard,
    ErpInfoClass,
    ERPContainer,
    saveExitBtnItem,
  } = useStyles()
  const client = useApolloClient()
  const dispatch = useIEDispatch()
  const history = useHistory()
  const { formatMessage, locale } = useIntl()
  const { instance, inProgress, accounts } = useMsal()
  const erpCollection = useIESelector((state) => state.erps)
  const classes = useStyles()
  const [erpSelected, setErpSelected] = useState<any>({})

  const {
    client_system: { systemName = '' },
    client_system,
    dialogData,
    entities,
    loader,
    yaml_file,
    can_upload_config,
    erpId,
    auditFirm,
    customErpType,
    submitError,
    configError,
    loadConfigurationIsRejected,
  } = useIESelector((state) => state.customERP)

  const azureClient = useContext(AzureClientContext)

  const [step, setStep] = useState(0)
  const [validateDialog, setValidateDialog] = useState(false)
  const [copyDialog, setCopyDialog] = useState(false)
  const [previewData, setPreviewData] = useState([])

  useEffect(() => {
    const allData = []
    for (const key in entities) {
      entities[key].forEach((entity) => {
        if (
          !entity.isPreviewConfirmed &&
          entity.isPreviewConfirmed !== undefined
        ) {
          allData.push(entity)
        }
      })
    }
    setPreviewData(allData)
  }, [entities.report, entities.system])

  const handleDialogClose = async (flag: boolean) => {
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    if (flag) {
      dispatch(submitCustomErp({ client, confirmed: flag, headers }))
      return
    }
    dispatch(setDialogData({ confirmed: flag, open: false, text: '' }))
  }

  const availableOptions = Object.keys(erpCollection)
    .map((id) => {
      return {
        title: erpCollection[id]?.name,
        value: id,
        auditFirmSystemName: erpCollection[id]?.auditFirm?.systemName,
        erpType: erpTypes[erpCollection[id]?.erpType],
      }
    })
    .sort(
      (erpIdA, erpIdB) =>
        erpIdA?.erpType?.localeCompare(erpIdB?.erpType) ||
        erpIdA?.title?.localeCompare(erpIdB?.title)
    )

  const submit = () => {
    if (validateInputs()) {
      dispatch(submitCustomErp({ client }))
    } else {
      setValidateDialog(true)
    }
  }

  const handleOnAdd = () => {
    setStep(1)
  }

  const uploadConfigFileToContainer = async () => {
    const file = new Blob([yaml_file], { type: 'yml' })
    const resp = await uploadCustomMappingFileToAzureContainer(
      azureClient,
      auditFirm,
      file,
      'mandatory-fields-erp',
      erpId + '.yml'
    )
    if (resp === 200) {
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.AddData))
    }
  }

  const validateInputs = () => {
    let entityFlag = 0

    entityFlag +=
      client_system.systemName && (client_system.system || client_system.report)
        ? 0
        : 1
    for (const key in entities) {
      if (client_system[key]) {
        entityFlag +=
          entities[key].length > 0 &&
          entities[key].reduce((acc, nextVal: Entity) => {
            console.log('---------------------------------')
            const date_format_check = nextVal.customErpFieldsConfig.filter(
              ({ data_type, date_format = '' }) =>
                data_type === 'datetime' && !date_format
            )
            return (
              acc &&
              nextVal.suggested_file_name &&
              nextVal.file_type &&
              nextVal.encoding_format &&
              nextVal.customErpFieldsConfig.length > 0 &&
              +nextVal.header_row > 0 &&
              date_format_check.length === 0
            )
          }, true)
            ? 0
            : 1
      }
    }
    return entityFlag === 0
  }

  useEffect(() => {
    if (can_upload_config) {
      uploadConfigFileToContainer()
    }
  }, [can_upload_config])

  useEffect(() => {
    if (!client_system.report && !client_system.system) {
      setStep(0)
    }
  }, [client_system.report, client_system.system])

  useEffect(() => {
    if (submitError && !dialogData?.open) setValidateDialog(true)
    if (configError && !dialogData?.open) setValidateDialog(true)
  }, [submitError, dialogData, configError])

  useEffect(() => {
    // componentDidMount goes here
    dispatch(getErps(client))
    dispatch(getEntites({ client }))
    dispatch(getCustomErpsForEdit({ client }))
    dispatch(getCDMVersion(client))
    trackPageView({ name: 'Add Custom ERP' })
    return () => {
      // ComponentWillUnmount goes here
      dispatch(resetCustomErpState())
      dispatch(clearErps())
    }
  }, [])

  useEffect(() => {
    if (customErpType === null) {
      setStep(0)
    }
  }, [customErpType])

  const getConfig = async ({ erpId, auditFirmSystemName }) => {
    try {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      dispatch(
        loadConfiguration({
          erpId,
          auditFirm: auditFirmSystemName,
          headers,
        })
      )
    } catch (error) {
      // TODO: if this endpoint gives an error perform rollback on save erp functionality
      return error
    }
  }

  const handleErpSelection = (selectedErp) => {
    if (selectedErp) {
      let systemName = ''
      if (selectedErp?.auditFirmSystemName)
        systemName = selectedErp?.auditFirmSystemName
      getConfig({
        erpId: selectedErp?.value,
        auditFirmSystemName: systemName,
      })
    }
    return
  }

  const onCopySave = (v: boolean) => {
    if (v) {
      if (erpSelected) {
        handleErpSelection(erpSelected)
        setStep(1)
      }
    }
    setCopyDialog(false)
  }

  const disableApply = () => {
    if (erpSelected === null) {
      return true
    } else if (Object.keys(erpSelected)?.length > 0) {
      return false
    } else {
      return true
    }
  }
  return (
    <>
      <section className={customERP}>
        <Typography
          style={{ display: 'flex', alignItems: 'center' }}
          className={title}
          color="primary"
        >
          {customErpType} Custom ERP
        </Typography>
        <InfoCard />
        <Grid container className={ERPContainer}>
          <Grid item className={clientSystemCard}>
            <ClientSystemCard
              step={step}
              setValidateDialog={setValidateDialog}
              onClick={handleOnAdd}
              onCopy={() => {
                setCopyDialog(true)
              }}
            />
          </Grid>
          {/* <Grid item style={{ flexGrow: 1, flexBasis: 0 }}> */}
          <Grid item className={ErpInfoClass}>
            {step === 0 || loadConfigurationIsRejected ? (
              <EmptyBanner
                text={
                  loadConfigurationIsRejected
                    ? 'Selected ERP has empty configuration'
                    : 'Please Add a New Custom ERP'
                }
              />
            ) : (
              <ERPInformation />
            )}
          </Grid>
        </Grid>
        <Grid item container className={saveExitBtnItem}>
          <FormControl>
            <Button
              onClick={submit}
              variant="contained"
              color="secondary"
              disabled={
                step === 0 ||
                loadConfigurationIsRejected ||
                previewData.length > 0
              }
              style={{ height: '44px' }}
            >
              Save & Exit
            </Button>
          </FormControl>
        </Grid>
        <Dialog
          cancelText="No"
          confirmText="Yes"
          open={dialogData?.open}
          onClose={handleDialogClose}
        >
          <>
            <IconError />
            <Typography component={'div'} className="dialog-title">
              You already have
              <Typography className={`dialog-title ${innerText}`}>
                {` ${dialogData?.text}`}
              </Typography>
              in
              <Typography className={`dialog-title ${innerText}`}>
                {` ${systemName}`}
              </Typography>
              , If you continue you will replace the existing settings.Are you
              sure you want to continue?
            </Typography>
          </>
        </Dialog>
        <Dialog
          cancelText={submitError || configError ? '' : 'Cancel'}
          confirmText="Ok"
          width={submitError || configError ? '750px' : '674px'}
          height={submitError ? '480px' : configError ? '300px' : '400px'}
          open={validateDialog}
          onClose={() => {
            setValidateDialog(false)
            dispatch(updateSubmitError(false))
            dispatch(updateConfigError(false))
          }}
        >
          <>
            <IconError />
            <br />
            <Typography component={'div'} className="dialog-title">
              {submitError ? (
                <>
                  This ERP has ingestions in progress at the moment so it can't
                  be saved. Please wait a moment and try again.
                  <br />
                  <br />
                  WARNING: Refreshing or navigating away from this page will
                  result in the changes being lost.`
                </>
              ) : configError ? (
                <> Invalid Configuration </>
              ) : (
                `Please fill out the required fields, select the date format for
                the records with date-time Data Type for all entities.`
              )}
            </Typography>
          </>
        </Dialog>

        <Dialog
          cancelText="Cancel"
          confirmText="Save"
          width="674px"
          height="400px"
          open={copyDialog}
          onClose={onCopySave}
          disabledApply={disableApply()}
        >
          <Grid item xs={12}>
            <Description
              inputLabelId={'erpLabel'}
              title={'Client System Name'}
              // description={
              //   'Please select from which client system the data was exported:'
              // }
            >
              {erpCollection ? (
                <Autocomplete
                  PopperComponent={StyledPopper}
                  options={availableOptions}
                  getOptionLabel={(option) => option?.title || ''}
                  value={erpSelected}
                  onChange={(e: any, value, reason) => {
                    setErpSelected(value)
                  }}
                  className={classes.MuiAutocomplete}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        hiddenLabel
                        placeholder="Please select an ERP system"
                      />
                    )
                  }}
                />
              ) : (
                <BounceLoader color={'#00A9AB'} size={20} />
              )}
            </Description>
          </Grid>
        </Dialog>
      </section>
      <Backdrop className={backdrop} open={loader}>
        <div style={{ width: '20%' }}>
          <LinearProgressWithLabel
            style={{
              height: '12px',
              borderRadius: '12px',
              border: '1px solid rgba(255, 255, 255, 0.5)',
            }}
            value={'Loading...'}
          />
        </div>
      </Backdrop>
    </>
  )
}

export default AddCustomERP
