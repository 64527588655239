import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import { loginRequest } from '../authConfig'

export function LoginRedirect() {
  const { instance } = useMsal()
  useEffect(() => {
    instance.loginRedirect(loginRequest)
  })
  return null
}
