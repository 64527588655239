import React, { useMemo, useState } from 'react'
import { makeStyles, Button, Checkbox } from '@material-ui/core'
import { Switch, Select, EBTable } from '@engine-b/shared/components'
import { ReactComponent as IconCalendar } from '../../../assets/IconCalendar.svg'
import DateFormatDialog from './DateFormatDialog'
import {
  resetCustomErpFieldConfig,
  updateCustomErpFieldConfig,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
const useStyles = makeStyles((theme) => ({
  dataTypeCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const dataTypeItems = [
  {
    value: 'string',
    label: 'String',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'decimal',
    label: 'Decimal',
  },
  {
    value: 'integer',
    label: 'Integer',
  },
  {
    value: 'datetime',
    label: 'Date Time',
  },
  {
    value: 'boolean',
    label: 'Boolean',
  },
  {
    value: 'currency',
    label: 'Currency',
  },
]

const CustomErpTable = (props) => {
  const { entityIndex, extractType } = props
  const { dataTypeCell } = useStyles()
  const [dateFormatDialogData, setDateFormatDialogData] = useState({
    params: null,
    display: false,
  })

  const { entities } = useIESelector((state) => state.customERP)
  const { mandatory_fields, customErpFieldsConfig, enableClearAll } =
    entities[extractType][entityIndex]

  const mandatoryFieldItems = useMemo(() => {
    return mandatory_fields.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [mandatory_fields])

  const dispatch = useIEDispatch()

  const handleDateClick = (params) => {
    setDateFormatDialogData({
      display: true,
      params: { ...params, fieldName: 'date_format' },
    })
  }

  const handleClose = (value, params) => {
    const dataToUpdate: any = { isOpen: false }
    if (params.row.cdm_field_names && !params.row.cdm_field_names.length) {
      dataToUpdate.required = false
      dataToUpdate.nullable = true
    }

    revertNullableValueChangesIfNotAllowed(dataToUpdate)

    dispatch(
      updateCustomErpFieldConfig({
        rowIndex: params.rowIndex,
        fieldData: dataToUpdate,
        entityIndex,
        extractType,
      })
    )
  }

  const handleOpen = (value, { rowIndex, required,nullable }) => {
    let dataToUpdate: any = { isOpen: true }
    if (required) {
      dataToUpdate.required = true
      dataToUpdate.nullable=nullable
    }
    
    revertNullableValueChangesIfNotAllowed(dataToUpdate)

    dispatch(
      updateCustomErpFieldConfig({
        rowIndex,
        fieldData: dataToUpdate,
        entityIndex,
        extractType,
      })
    )
  }

  const handleRowDataChange = (fieldValue, { fieldName, rowIndex = -1 }) => {
    let dataToUpdate: any = { [fieldName]: fieldValue }
    switch (fieldName) {
      case 'cdm_field_names':
        if (fieldValue && Array.isArray(fieldValue) && fieldValue.length) {
          dataToUpdate = { required: true, cdm_field_names: fieldValue,nullable:false }
        } else {
          dataToUpdate = { required: false, cdm_field_names: [],nullable:true }
        }
        revertNullableValueChangesIfNotAllowed(dataToUpdate)
        break
      case 'required':
        if (fieldValue) {
          handleOpen({}, { rowIndex: rowIndex, required: true, nullable:false })
          return
        } else {
          dataToUpdate = { required: false, cdm_field_names: [],nullable:true }
        }
        revertNullableValueChangesIfNotAllowed(dataToUpdate)
        break
      default:
        break
    }



    dispatch(
      updateCustomErpFieldConfig({
        rowIndex,
        fieldData: dataToUpdate,
        entityIndex,
        extractType,
      })
    )
  }

  function revertNullableValueChangesIfNotAllowed(dataToUpdate: any) {
    if (process.env.NX_ALLOW_BLANKS_FOR_MANDATORY_FIELDS !== 'false' && dataToUpdate.nullable !== undefined) {
      delete dataToUpdate.nullable
    }
  }

  const generateRows = (data) => {
    return data.map((data, i) => ({
      ...data,
      field_number: i + 1,
      isOpen: data.isOpen ? true : false,
    }))
  }

  const tableHeadersData = useMemo(() => {
    return [
      {
        title: '#',
        value: 'field_number',
      },
      {
        title: 'Fill Down',
        value: 'fill_down',
        renderer: function (params) {
          const {
            row: { fill_down },
          } = params
          return (
            <Checkbox
              inputProps={{ 'aria-label': 'primary checkbox' }}
              checked={fill_down || false}
              onChange={(e) => handleRowDataChange(e.target.checked, params)}
            />
          )
        },
      },
      {
        title: 'ERP Field Name',
        value: 'field_name',
      },
      {
        title: 'Data Type',
        value: 'data_type',
        width: '140px',
        renderer: function (params) {
          const {
            row: { data_type },
          } = params
          return (
            <div className={dataTypeCell}>
              <Select
                name="data_type"
                placeholder="Please Select Data Type"
                items={dataTypeItems}
                onChangeHandler={(e) => handleRowDataChange(e, params)}
                value={data_type}
                selectProps={{
                  style: {
                    maxWidth: '140px',
                  },
                }}
              />
              <div>
                {data_type === 'datetime' && (
                  <IconCalendar
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    onClick={() => handleDateClick(params)}
                  />
                )}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Blank Allowed',
        value: 'nullable',
        renderer: function (params) {
          const {
            row: { nullable },
          } = params
          return (
            <Switch
              name="nullable"
              color="secondary"
              checked={nullable}
              onChange={(e) => handleRowDataChange(e.target.checked, params)}
            />
          )
        },
      },
      {
        title: 'Field Required',
        value: 'required',
        renderer: function (params) {
          const {
            row: { required },
          } = params
          return (
            <Switch
              checked={required}
              name="required"
              color="secondary"
              onChange={(e) => handleRowDataChange(e.target.checked, params)}
            />
          )
        },
      },
      {
        title: 'Mandatory Fields',
        value: 'cdm_field_names',
        width: '200px',
        renderer: function (params) {
          const {
            row: { cdm_field_names, isOpen },
          } = params

          return (
            <Select
              name="cdm_field_names"
              placeholder="Please Select Mandatory Fields"
              items={mandatoryFieldItems}
              onChangeHandler={(e) => handleRowDataChange(e, params)}
              value={cdm_field_names}
              multiple
              selectProps={{
                onClose: (e) => handleClose(e, params),
                onOpen: (e) => handleOpen(e, params),
                open: isOpen ? true : false,
                renderValue: (selected: string[]) => {
                  return `${selected.length} Fields Selected`
                },
              }}
            />
          )
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIndex, extractType, mandatory_fields])
  return (
    <>
      <EBTable
        headers={tableHeadersData}
        rows={generateRows(customErpFieldsConfig)}
        recordsPerPage={5}
        showRecordsPerPage={false}
        footerContent={
          <Button
            onClick={() =>
              dispatch(resetCustomErpFieldConfig({ entityIndex, extractType }))
            }
            variant="outlined"
            disabled={!customErpFieldsConfig.length || !enableClearAll}
          >
            Clear Mapping
          </Button>
        }
      />
      <DateFormatDialog
        data={dateFormatDialogData}
        setData={setDateFormatDialogData}
        {...props}
      />
    </>
  )
}

export default CustomErpTable
