import { gql } from '@apollo/client'

export const GET_DATAINGESTION_GROUPINGS = gql`
  query getDataIngestionGroupings($dataIngestionId: String, $dataIngestionGrouperId: String) {
    getDataIngestionGroupings(
      dataIngestionId: $dataIngestionId,
      dataIngestionGrouperId: $dataIngestionGrouperId
    ) {
      id
      name
      startDate
      endDate
      createdBy
      isActive
      dataIngestionId
      grouperId
      status
      dataIngestion {
        id
        dataStartDate
        dataEndDate
        initiatedAt
        completeAt
        auditedEntity {
          name
          id
        }
        container {
          fileSystemId
          storageAccountURL
          inputPath
          incomingPath
          sharePath
          errorsPath
        }
        name
        engagement {
          id
          name
        }
        erp {
          id
        }
        cdmEntities {
          cdmEntity {
            systemName
            extractType
            name
            inputFiles {
              name
              id
            }
          }
        }
        uploadedFiles {
          fileNameByUser
        }
        status
      }
      grouper {
        id
        name
        groupingCodes {
          nominalCode
          glAccountName
          grouperId
          accountNameId
          mapped
          accountName
          fsCaption
          accountSubType
          accountType
          glMapNumber
        }
        complete
        version
      }
    }
  }
`
