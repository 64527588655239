/**
 * Take file as an input and return base64 string
 * @param file Input file
 * @returns Promise<String>
 */
export const binaryToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Accept file base64 url and convert into the javascript file object
 * @param url File url in base64 format
 * @returns File object
 */
export const base64ToBinary = async (url: string, fileName: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  const file = new File([blob], fileName)

  return file;
};
