import React, { useEffect, useRef, useState } from 'react'
import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
  Button,
  Checkbox,
  styled,
  Popper,
} from '@material-ui/core'
import {
  useIEDispatch,
  useIESelector,
  loadConfiguration,
  resetCustomErpState,
  EditErpTypes,
  updateClientSystemName,
  updateClientSystemReportType,
  archiveCustomErp,
  importCustomErpData,
  getCDMVersion,
} from '@engine-b/integration-engine/data/state/redux'
import { createFilterOptions } from '@material-ui/lab'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import _ from 'lodash'
import { ReactComponent as IconError } from '../../assets/IconError.svg'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { useApolloClient } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  csCard: {
    height: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #44697D33',
    borderRadius: '10px',
    '& .MuiCardContent-root': {
      padding: '30px',
    },
    '& .input-group': {
      marginBottom: '30px',
      '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px',
      },
    },
  },
  csSubtitle: {
    font: 'normal normal bold 20px/23px Arial',
    letterSpacing: '0.2px',
    color: '#22353F',
    margin: '30px',
    opacity: 1,
  },
  csLabel: {
    font: 'normal normal bold 16px/18px Arial',
    letterSpacing: '0.16px',
    color: '#44697D',
    marginBottom: '10px',
  },
  csHint: {
    font: 'normal normal normal 14px/16px Arial',
    letterSpacing: '0.14px',
    color: '#44697D',
    marginBottom: '10px',
  },
  addBtn: {
    display: 'block',
    width: '140px',
    minWidth: 'unset',
    height: '44px',
    marginTop: '60px',
    font: 'normal normal bold 16px/18px Arial',
    letterSpacing: '0.16px',
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: '32px',
  },
  deleteBtn: {
    display: 'block',
    width: '140px',
    minWidth: 'unset',
    height: '44px',
    marginTop: '30px',
    font: 'normal normal bold 16px/18px Arial',
    letterSpacing: '0.16px',
    color: 'rgb(222, 77, 77)',
    border: '1px solid',
    '&:hover': {
      border: '1px solid',
      backgroundColor: 'rgb(222, 77, 77, .1)',
    },
  },
  warnIcon: {
    width: '50px',
    height: '50px',
    marginBottom: '12px',
    background: 'orange',
    borderRadius: '50%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  },
}))

export interface ClientSystemProps {
  step: number
  onClick: () => void
  onCopy: () => void
  setValidateDialog: any
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    fontWeight: 'bold',
    fontSize: 'large',
  },
}))

const ClientSystemCard = (props: ClientSystemProps) => {
  const { step, onClick, onCopy } = props
  const { csCard, csSubtitle, csLabel, csHint, addBtn, deleteBtn, warnIcon } =
    useStyles()
  const [openProgressWarningDialog, setOpenProgressWarningDialog] =
    useState(false)
  const [newCustomERPName, setNewCustomERPName] = useState({})
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const filter = createFilterOptions<{
    title: string
    value: string
    type: EditErpTypes
    auditFirmSystemName: string
    isActive: boolean
    inProgress: boolean
  }>()
  //   Local State Variables
  const dispatch = useIEDispatch()
  const { systemName, report, system, editErpExtractType } = useIESelector(
    (state) => state.customERP.client_system
  )
  const { instance, inProgress, accounts } = useMsal()
  const { customErps, customErpType, erpId, entities } = useIESelector(
    (state) => state.customERP
  )
  const customERPData = useIESelector((state) => state.customERP)
  const cdmVersion = useIESelector((state) => state.customERP.cdm_version)

  const client = useApolloClient()
  const sysName = useRef(null)
  const buttonRef = useRef(null)
  const [validateDialog, setValidateDialog] = useState(false)

  useEffect(() => {
    // componentDidMount goes here
    dispatch(getCDMVersion(client))
    return () => {
      // ComponentWillUnmount goes here
    }
  }, [])

  useEffect(() => {
    if (
      (entities?.report?.length > 0 || entities?.system?.length > 0) &&
      !(!disableAddERPButton() || disableInput())
    ) {
      buttonRef.current.click()
    }
  }, [entities?.report?.length, entities?.system?.length])

  //   Functions
  const disableAddERPButton = () => {
    return systemName && (report || system) && !error
  }

  const disableCopyERPButton = () => {
    return systemName && !error
  }

  const disableImportExportButton = () => {
    return (
      customERPData.entities.report.length > 0 ||
      customERPData.entities.system.length > 0
    )
  }

  const onChangeHandler = (e: any) => {
    const extractType = e.target.name
    if (extractType) {
      dispatch(
        updateClientSystemReportType({
          extractType,
          checked: e.target.checked,
          type: EditErpTypes.new,
        })
      )
    }
  }

  const erpValidation = (flag, msg) => {
    setError(flag)
    setErrorMsg(msg)
  }

  const handleErpSelection = (selectedErp, reason) => {
    if (selectedErp?.type === EditErpTypes.new || reason === 'clear') {
      const result = customErps.find((ce) => ce.name === selectedErp?.value)

      result
        ? erpValidation(
            true,
            `Erp is already exists with name ${selectedErp?.value}`
          )
        : erpValidation(false, '')

      dispatch(resetCustomErpState())
    }

    const payload = {
      systemName: selectedErp?.title || null,
      type: selectedErp?.type || null,
      erpId: selectedErp?.value || '',
    }
    dispatch(updateClientSystemName(payload))
    if (selectedErp?.type === EditErpTypes.existing && selectedErp?.value) {
      erpValidation(false, '')
      getConfig({
        erpId: selectedErp?.value,
        auditFirmSystemName: selectedErp?.auditFirmSystemName,
      })
      return
    }
  }

  const availableOptions = customErps
    .filter((erp) => erp.isActive)
    .map((erp) => {
      return {
        title: erp.name,
        value: erp.id,
        type: EditErpTypes.existing,
        auditFirmSystemName: erp?.auditFirm?.systemName,
        isActive: erp?.isActive,
        inProgress: erp?.inProgress,
      }
    })

  const onDeleteErpHandler = () => {
    setDeleteDialog(true)
  }

  const handleClose = (confirmation) => {
    if (confirmation) {
      dispatch(archiveCustomErp({ client, payload: { erpId } }))
        .unwrap()
        .then(() => {
          const ele = sysName.current.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
          )[0]
          if (ele) ele.click()
        })
      setDeleteDialog(false)
    } else {
      setDeleteDialog(false)
    }
  }

  const handleProgressDialogClose = (confirmation) => {
    if (confirmation) {
      dispatch(resetCustomErpState())
      if (!_.isEmpty(newCustomERPName)) {
        handleErpSelection(newCustomERPName, 'select-option')
      }
      setOpenProgressWarningDialog(false)
    } else {
      setOpenProgressWarningDialog(false)
    }
  }

  const disableInput = () => {
    return step === 1
  }

  const getConfig = async ({ erpId, auditFirmSystemName }) => {
    try {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      dispatch(
        loadConfiguration({
          erpId,
          auditFirm: auditFirmSystemName,
          headers,
        })
      )
      onClick()
    } catch (error) {
      // TODO: if this endpoint gives an error perform rollback on save erp functionality
      return error
    }
  }

  const onExport = async () => {
    const d = { ...customERPData }
    delete d.customErps
    delete d.entitiesListItems
    const data = JSON.stringify(d)
    const blob = new Blob([data], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = `${systemName}.json`
    link.click()

    URL.revokeObjectURL(url)
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const onImport = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = function (event: any) {
      try {
        const replaceNewLineWithSpace = (config) => {
          return config.map((field) => ({
            ...field,
            nullable: field.nullable === '' ? true : field.nullable,
            field_name: field.field_name
              .replace(/^Unnamed: (\d+)$/, 'NO_NAME$1')
              .replace(/\n/g, ' ')
              .trim(),
          }))
        }
        if (event.target && event.target.result) {
          const entities = Object.keys(
            JSON.parse(event.target.result as string).entities
          )
          const updatedEntities = entities.map((entity) => {
            return JSON.parse(event.target.result as string).entities[
              entity
            ].map((report) => ({
              ...report,
              customErpFieldsConfig: replaceNewLineWithSpace(
                report.customErpFieldsConfig
              ),
              customErpFieldsDefault: replaceNewLineWithSpace(
                report.customErpFieldsDefault
              ),
            }))
          })

          const customERP = {
            ...JSON.parse(event.target.result as string),
            entities: Object.assign(
              {},
              ...entities.map((key, index) => ({
                [key]: updatedEntities[index],
              }))
            ),
          }

          if (erpId) {
            customERP.erpId = erpId
            customERP.client_system.systemName = systemName
          }
          customERP.cdm_version = customERPData?.cdm_version || cdmVersion
          dispatch(importCustomErpData(customERP))
        }
      } catch (err) {
        setValidateDialog(true)
        console.error('error', err)
      }
    }

    reader.readAsText(file)
    event.target.value = null
  }

  const openFileInput = () => {
    fileInputRef.current?.click()
  }

  return (
    <>
      <Card className={csCard}>
        <Typography className={csSubtitle}>Client System</Typography>
        <Divider />
        <CardContent>
          <div className="input-group">
            <Typography className={csLabel}>Client System Name</Typography>
            <Typography className={csHint}>
              Type in the name of the client's ERP system
            </Typography>
            <EBAutoComplete
              PopperComponent={StyledPopper}
              options={availableOptions}
              value={{ title: systemName }}
              getOptionLabel={(option) => option.title || ''}
              getOptionSelected={(option) => option.title === systemName}
              getOptionDisabled={({ inProgress }) => inProgress}
              ref={sysName}
              onChange={(e, value, reason) => {
                if (entities.report.length > 0 || entities.system.length > 0) {
                  setNewCustomERPName(value)
                  setOpenProgressWarningDialog(true)
                } else {
                  handleErpSelection(value, reason)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const index = options.findIndex(
                  ({ title }) => title === params.inputValue.trim()
                )
                if (
                  params.inputValue.trim().length <= 50 &&
                  params.inputValue.trim() !== ''
                ) {
                  if (index === -1) {
                    filtered.push({
                      value: params.inputValue,
                      title: `${params.inputValue}`,
                      type: EditErpTypes.new,
                      auditFirmSystemName: null,
                      isActive: true,
                      inProgress: false,
                    })
                  }
                }
                return filtered
              }}
              noOptionsText="Input should not be empty and must be less than 50 characters"
              renderOption={(option, state) => {
                if (option.type === EditErpTypes.new) {
                  return `Create ${option.title}`
                }
                return option.title
              }}
              size="small"
              placeholder="Enter Name"
              error={error}
              helperText={errorMsg}
              hiddenLabel={true}
            />
          </div>

          <div className="checkbox-group">
            <Typography className={csLabel}>Data Extract Type</Typography>
            <div className="checkbox-wrapper">
              <Checkbox
                id="report"
                name="report"
                checked={report}
                onChange={onChangeHandler}
                disabled={
                  editErpExtractType['report'] === EditErpTypes.existing &&
                  entities['report'].length !== 0
                }
              />
              <label htmlFor="report">Report Extract</label>
            </div>

            <div className="checkbox-wrapper">
              <Checkbox
                id="system"
                name="system"
                checked={system}
                onChange={onChangeHandler}
                disabled={
                  editErpExtractType['system'] === EditErpTypes.existing &&
                  entities['system'].length !== 0
                }
              />
              <label htmlFor="system">System Extract</label>
            </div>
          </div>

          <Button
            color="primary"
            variant="contained"
            className={addBtn}
            onClick={onClick}
            disabled={!disableAddERPButton() || disableInput()}
            ref={buttonRef}
          >
            Add
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={addBtn}
            onClick={onCopy}
            disabled={!disableCopyERPButton() || disableInput()}
          >
            Copy
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={addBtn}
            onClick={onExport}
            disabled={!disableImportExportButton()}
          >
            Export
          </Button>
          <>
            <Button
              variant="contained"
              color="primary"
              className={addBtn}
              disabled={disableImportExportButton()}
              onClick={openFileInput}
            >
              Import
            </Button>
            <input
              type="file"
              accept=".json"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={onImport}
            />
          </>
          {customErpType === EditErpTypes.existing && (
            <Button
              color="primary"
              variant="outlined"
              className={deleteBtn}
              onClick={onDeleteErpHandler}
              disabled={availableOptions?.length === 0}
            >
              Deactivate
            </Button>
          )}
          <Dialog
            cancelText="No"
            confirmText="Yes"
            open={deleteDialog}
            onClose={handleClose}
            width="650px"
            height="350px"
          >
            <>
              <div className={warnIcon}>
                <ReportProblemOutlinedIcon />
              </div>
              <Typography component={'div'} className="dialog-title">
                Are you sure you want to deactivate
              </Typography>
              <Typography
                color="secondary"
                component={'div'}
                className="dialog-title"
                style={{ display: 'inline-block' }}
              >
                {systemName}?
              </Typography>
            </>
          </Dialog>
        </CardContent>
      </Card>
      <Dialog
        cancelText={''}
        confirmText={'OK'}
        width={'750px'}
        height={'400px'}
        open={validateDialog}
        onClose={() => {
          setValidateDialog(false)
        }}
      >
        <>
          <IconError />
          <br />
          <Typography component={'div'} className="dialog-title">
            Invalid JSON
            <br />
            Please Import Valid JSON File.
          </Typography>
        </>
      </Dialog>

      {openProgressWarningDialog && (
        <Dialog
          cancelText="No"
          confirmText="Yes"
          open={openProgressWarningDialog}
          onClose={handleProgressDialogClose}
          width="650px"
          height="400px"
        >
          <>
            <div className={warnIcon}>
              <ReportProblemOutlinedIcon />
            </div>
            <Typography component={'div'} className="dialog-title">
              The ERP progress will be lost. Save the ERP group before leaving
              this page.
            </Typography>
            <Typography component={'div'} className="dialog-subtitle">
              Are you sure your want to lose this ERP?
            </Typography>
          </>
        </Dialog>
      )}
    </>
  )
}

export default ClientSystemCard
