import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import React from 'react'
import BounceLoader from 'react-spinners/BounceLoader'
import { SUPPORTED_MIME_TYPES } from './is-valid-file-type'
import DragAndDropFileUpload from './DragAndDropFileUpload/DragAndDropFileUpload'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { UploadStateMessage } from './UploadStateMessage'
import FileUpload from './FileUpload'

const useStyles = makeStyles((theme) =>
  createStyles({
    Row: {
      marginTop: '8px',
      marginBottom: '8px',
    },
    FileWidgetWrapper: {
      margin: '4px',
      width: '100%',
    },
    Icon: {
      marginLeft: 'auto',
      marginRight: 'auto',
      cursor: 'pointer',
    },
    inputFile: {
      wordWrap: 'break-word',
    },
  })
)

export function FileUploadWidget({
  fileMapping,
  uploadFile,
  copyFileToDI,
  handleDeleteEntity,
  index,
  selectedReportDetail,
  selectedCdmEntity,
  reportIndex,
  auditedEntity,
  engagementId,
  allowMultiple,
  value,
  setValue,
  loading,
  headerOptions,
  uploaded,
  handleInputChange,
}) {
  const classes = useStyles()
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )
  return (
    <div className={classes.FileWidgetWrapper}>
      {!azureContainer ? (
        <BounceLoader color={'#00A9AB'} size={20} />
      ) : (
        // new UI
        process.env.NX_ENABLE_50_GB_FILE_UPLOAD === 'true' ? (
            <DragAndDropFileUpload
              uploadFile={uploadFile}
              fileMapping={fileMapping}
              copyFileToDI={copyFileToDI}
              index={index}
              selectedReportDetail={selectedReportDetail}
              selectedCdmEntity={selectedCdmEntity}
              reportIndex={reportIndex}
              auditedEntity={auditedEntity} 
              engagementId={engagementId}
              allowMultiple={allowMultiple}
              value={value}
              setValue={setValue}
              loading={loading}
              headerOptions={headerOptions}
              uploaded={uploaded}
              handleInputChange={handleInputChange}
            />
        ) : (
          // Uncommenting the code below will show the old UI.
          // Wrap in a feature flag check to show the old or new UI as appropriate

          <Grid container direction={'row'} alignItems={'center'}>
            <Grid item xs={3} className={classes.inputFile}>
              {fileMapping.cdmFileName}
            </Grid>
            <Grid item xs={1}>
              <div className={classes.Icon}>
                <ArrowRightAltIcon />
              </div>
            </Grid>
            <Grid item xs={4}>
              <form>
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={uploadFile}
                  multiple={false}
                  accept={SUPPORTED_MIME_TYPES.join(',')}
                />
              </form>
            </Grid>
          </Grid>
        )
      )}
    </div>
  )
}
