import { gql } from '@apollo/client'

export const CREATE_DATA_INGESTION = gql`
  mutation createDataIngestion(
    $dataStartDate: DateTime
    $dataEndDate: DateTime
    $erpId: String!
    $auditedEntityName: String!
    $extractType: ExtractType!
    $clientCode: String
    $groupId: String
    $groupName: String
    $groupVersion: String
    $groupLastUpdatedBy: String
    $engagementId: String
    $ingestionName: String
  ) {
    createDataIngestion(
      dataStartDate: $dataStartDate
      dataEndDate: $dataEndDate
      erpId: $erpId
      auditedEntityName: $auditedEntityName
      extractType: $extractType
      clientCode: $clientCode
      groupId: $groupId
      groupName: $groupName
      groupVersion: $groupVersion
      groupLastUpdatedBy: $groupLastUpdatedBy
      engagementId: $engagementId
      ingestionName: $ingestionName
    ) {
      id
      name
      dataStartDate
      dataEndDate
      initiatedAt
      completeAt
      adfPipelineId
      auditedEntity {
        name
      }
      status
      erp {
        name
      }
      extractType
      container {
        storageAccountURL
        fileSystemId
        inputPath
        sharePath
        errorsPath
        incomingPath
      }
      createdBy
      clientCode
      isCustom
      groupId
      groupName
      groupVersion
      groupLastUpdatedBy
      engagement {
        name
        id
        auditedEntityId
      }
    }
  }
`
