import { withJsonFormsDetailProps, JsonFormsDispatch } from '@jsonforms/react';
import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { 
  findUISchema, 
  StatePropsOfControlWithDetail,
} from '@jsonforms/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    group: {
      '& fieldset': {
        border: 'none',
        padding: 0,
      },
    },
    TextField: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '44px',
      marginBottom: '16px',
    },
    required: {
      color: "#DE4D4D",
      fontSize: "20px",
      marginLeft: "2px"
    },
    descriptionLabel: {
      font: 'normal normal bold 16px/18px Arial',
      letterSpacing: '0.16px',
      color: '#44697D',
      paddingBottom: '16px',
    },
    errorLabel: {
      color: theme.palette.error.main,
      marginBottom: '16px',
      minWidth: '200px',
    }
  })
)

const Group = (
  {
    renderers,
    cells,
    uischemas,
    schema,
    label,
    path,
    visible,
    enabled,
    uischema,
    rootSchema
  ,
  }: StatePropsOfControlWithDetail) => {
  const classes = useStyles()
  const detailUiSchema = useMemo(
    () =>
      findUISchema(
        uischemas,
        schema,
        uischema.scope,
        path,
        'Group',
        uischema,
        rootSchema
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema]
  );

  return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' className={ classes.descriptionLabel }>
            { label } 
          </Typography>
        </Grid>
        <Grid item xs={12} className={ classes.group }>
          <JsonFormsDispatch
            visible={visible}
            enabled={enabled}
            schema={schema}
            uischema={detailUiSchema}
            path={path}
            renderers={renderers}
            cells={cells}
          />
        </Grid>
     </Grid>
  );

}

export const GroupControl = withJsonFormsDetailProps(Group);