import { ApolloClient } from '@apollo/client'
import { GET_CDM_ENTITIES } from '@engine-b/integration-engine/data/data-ingestion-api'
import { CdmEntity, Erp, ExtractType } from '@engine-b/shared/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type CdmEntityCollection = Record<CdmEntity['systemName'], CdmEntity>

const INITIAL_STATE: CdmEntityCollection = {}

interface getCdmEntitiesParams {
  client: ApolloClient<unknown>
  erpId: Erp['id']
  extractType: ExtractType
}

export const getCdmEntities = createAsyncThunk(
  'getCdmEntities',
  async ({ client, ...params }: getCdmEntitiesParams) => {
    const response = await client.query({
      query: GET_CDM_ENTITIES,
      variables: {
        erpId: params.erpId,
        extractType: params.extractType,
      },
    })
    return response
  }
)

const hiddenFiles = {
  microsoftNavisionUK: {
    SYSTEM: {
      ChartOfAccounts: ['GL_Account_Category_Raw_Data.csv'],
      glDetail: [
        'General_Ledger_Setup_Raw_Data.csv',
        'GL_Register_Raw_Data.csv',
      ],
    },
  },
  xero: {
    REPORT: {
      ChartOfAccounts: ['GL Detail Report.csv'],
    },
  },
}


const hideFiles = (cdmEntity) => {
  const record = hiddenFiles[cdmEntity.erp.id]
  if (
    record &&
    record[cdmEntity.extractType] &&
    record[cdmEntity.extractType][cdmEntity.systemName]
  ) {
    return {
      ...cdmEntity,
      inputFiles: cdmEntity.inputFiles.filter(
        (file) =>
          !record[cdmEntity.extractType][cdmEntity.systemName].includes(
            file.name
          )
      ),
    }
  } else {
    return cdmEntity
  }
}

export const cdmEntitiesSlice = createCdmEntitiesSlice(INITIAL_STATE)

export function createCdmEntitiesSlice(initialState: CdmEntityCollection) {
  return createSlice({
    name: 'cdmEntities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getCdmEntities.fulfilled, (state, action) => {
        const { cdmEntities } = action.payload.data
        const newState = {}
        cdmEntities.forEach((cdmEntity) => {
          newState[cdmEntity.systemName] = cdmEntity
        })
        return newState
      })

      builder.addCase(getCdmEntities.rejected, (state) => {
        state = null
      })
    },
  })
}

export const cdmEntitiesReducer = cdmEntitiesSlice.reducer
