import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    value: {
      color: '#fff',
      fontSize: '24px',
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: '16px'
    },
  })
)

const LinearProgressWithLabel = (props) => {
  const classes = useStyles()
  const {value, ...rest} = props;

  return (
    <>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.value}
        >{value}</Typography>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress color="secondary" {...rest} />
      </Box>
    </>
  )
}

export default LinearProgressWithLabel
