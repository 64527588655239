import { Box, InputLabel, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  erpLabel: {
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0px',
    color: '#344B5F',
    marginBottom: '9px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '33px',
  },
  mandate: {
    color: '#37AB3F',
    fontSize: '30px',
    marginLeft: '8px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
}))

const CustomLabel = ({ title }: { title: string }) => {
  const { erpLabel, mandate } = useStyles()

  return (
    <Box style={{ display: 'flex' }}>
      <InputLabel className={erpLabel}>{title}</InputLabel>
      <span className={mandate}>
        <sub>*</sub>
      </span>
    </Box>
  )
}

export default CustomLabel
