import { gql } from '@apollo/client'

export const TRIGGER_ANALYTICS_PIPELINE = gql`
  mutation Mutation(
    $triggerAnalyticsPipelineId: String!
    $pipelineMapPath: String!
  ) {
    triggerAnalyticsPipeline(
      id: $triggerAnalyticsPipelineId
      pipelineMapPath: $pipelineMapPath
    ) {
      run_id
    }
  }
`
