import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import React, { createContext, PropsWithChildren } from 'react'

export const AzureClientContext = createContext<DataLakeServiceClient>(null)

interface AzureClientProviderProps {
  value: DataLakeServiceClient
}

export const AzureClientProvider = ({
  children,
  value,
}: PropsWithChildren<AzureClientProviderProps>) => {
  return (
    <AzureClientContext.Provider value={value}>
      {children}
    </AzureClientContext.Provider>
  )
}
