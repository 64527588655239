import {
  DataLakeServiceClient,
  FileParallelUploadOptions,
} from '@azure/storage-file-datalake'

export interface UploadFileToAzureContainerOptions {
  azureClient: DataLakeServiceClient
  containerId: string
  file: Buffer | Blob | ArrayBuffer | ArrayBufferView
  inputPath: string
  uploadOptions?: FileParallelUploadOptions
}

export async function uploadFileToAzureContainer({
  azureClient,
  containerId,
  file,
  inputPath,
  uploadOptions = {},
}: UploadFileToAzureContainerOptions) {
  const containerClient = azureClient.getFileSystemClient(`${containerId}`)

  if (!file) {
    console.error('No file provided to upload')
    return
  }
  const fileClient = containerClient.getFileClient(inputPath)
  return fileClient.upload(file, uploadOptions)
}

export async function uploadCustomMappingFileToAzureContainer(
  azureClient,
  containerId,
  file,
  inputPath,
  mappingFileName
) {
  const containerClient = azureClient.getFileSystemClient(`${containerId}`)

  if (!file) {
    console.error('No file provided to upload')
    return
  }

  const fileClient = containerClient.getFileClient(
    `${inputPath}/${mappingFileName}`
  )

  return fileClient
    .upload(file, {
      // onProgress: (p) => {},
    })
    .then((resp) => resp._response.status)
    .catch((e) => {
      throw new Error(e)
    })
}
