import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AdditionalDetail {
  [key: string]: {
    [key: string]: string
  }
}

export interface HeaderOptions {
   header_row: number
   probability: number
}
export interface FileMapping {
  cdmFileName: string
  reportType: string
  originalFileName?: string
  fileNameByUser?: string
  size: number
  records: []
  uploaded: number
  state: UPLOAD_STATE
  saveFileInContainer: boolean
  additionalDetail?: AdditionalDetail
  header_row: string
  headerOptions: HeaderOptions[]
}

export enum UPLOAD_STATE {
  COMPLETE = 'complete',
  ERROR = 'error',
  UNSUPPORTED_FILE_TYPE = 'unsupported file type',
  IN_PROGRESS = 'upload in progress',
  NOT_STARTED = 'not started',
  VALIDATING_FILE = 'validating the input file',
  VALIDATING_FILE_COMPLETE = 'validating the input file complete',
  VALIDATING_FILE_FAILED = 'validating the input file terminated',
  VALIDATE_FILE_SIZE = 'file size should be less than 100MB',
  VALIDATE_EXCEL_FILE_SIZE = 'excel file size should be less than 100MB',
  ERROR_EXCEL_FILE_CONVERSION = 'Error converting excel file to csv',
  FILE_VALIDATION_FAILED = 'File validation failed',
  EMPTY_FILE_VALIDATION = 'Uploaded file is empty',
  PRE_UPLOADED_INPUT_FILE_NOT_FOUND = 'File does not exist - please select or upload.',
}

export type FileMappingCollection = Record<
  FileMapping['cdmFileName'],
  FileMapping
>

const INITIAL_STATE: FileMappingCollection = {}
const INIT_FILE_MAPPING: FileMapping = {
  cdmFileName: null,
  reportType: null,
  size: 0,
  uploaded: 0,
  records: [],
  state: UPLOAD_STATE.NOT_STARTED,
  saveFileInContainer: false,
  additionalDetail: null,
  header_row: '',
  headerOptions: [{
    header_row: null,
    probability: null
  }]
}

type updateFileMappingPayload = Pick<FileMapping, 'originalFileName'> &
  Partial<FileMapping>

export const fileMappingsSlice = createFileMappingsSlice(INITIAL_STATE)

export function createFileMappingsSlice(initialState: FileMappingCollection) {
  return createSlice({
    name: 'fileMappings',
    initialState,
    reducers: {
      clearAllFileMappings: (state) => {
        for (const fileName of Object.keys(state)) {
          delete state[fileName]
        }
      },
            addFileMapping: (state, { payload }) => {
        const { fileNames, reportType } = payload
        fileNames.forEach(
          (name) =>
          (state[`${reportType}_${name}`] = {
            ...INIT_FILE_MAPPING,
            cdmFileName: name,
            originalFileName: name,
            fileNameByUser: name,
            reportType,
          })
        )
      },
      updateFileMapping: (
        state,
        action: PayloadAction<updateFileMappingPayload>
      ) => {
        const { originalFileName, reportType, ...patch } = action.payload
        if (`${reportType}_${originalFileName}` in state) {
          state[`${reportType}_${originalFileName}`] = {
            ...state[`${reportType}_${originalFileName}`],
            ...patch,
          }
        }
      },
      updateFileMappingAdditionalDetail: (
        state,
        action: PayloadAction<updateFileMappingPayload>
      ) => {
        const { originalFileName, reportType, additionalDetail } = action.payload
        if (`${reportType}_${originalFileName}` in state) {
          state[`${reportType}_${originalFileName}`] = {
            ...state[`${reportType}_${originalFileName}`],
            additionalDetail: {
              GeneralLedgerDetail: {
                ...state[`${reportType}_${originalFileName}`].additionalDetail?.GeneralLedgerDetail,
                ...additionalDetail?.GeneralLedgerDetail
              }
            }
          }
        }
      },
      renameCdmFileName: (state) => {
        for (const key in state) {
          state[key] = {
            ...state[key],
            cdmFileName: key,
          }
        }
      },
      deleteFileMapping: (state, action) => {
        const { cdmFileNames, reportType } = action.payload
        for (const cdmFileName of cdmFileNames) {
          delete state[`${reportType}_${cdmFileName}`]
        }
      },
      updateDeleteFlag: (state, action) => {
        const { key, value } = action.payload
        state[key].saveFileInContainer = value
      },
      resetFileNameByUser: (state, { payload }) => {
        const { reportType, fileNameByUser} = payload
        state[`${reportType}_${fileNameByUser}`] = {
          ...state[`${reportType}_${fileNameByUser}`],
          fileNameByUser: fileNameByUser,
        }
      }
    },
  })
}

export const {
  clearAllFileMappings,
  addFileMapping,
  updateFileMapping,
  updateFileMappingAdditionalDetail,
  deleteFileMapping,
  renameCdmFileName,
  updateDeleteFlag,
  resetFileNameByUser,
} = fileMappingsSlice.actions

export const fileMappingsReducer = fileMappingsSlice.reducer
