import React from 'react'
import {
  Select as MuiSelect,
  MenuItem,
  SelectProps as MuiSelectProps,
  makeStyles,
  Checkbox,
} from '@material-ui/core'
import DropDownButton from '@material-ui/icons/KeyboardArrowDownRounded'

import PropTypes from 'prop-types'

interface Items {
  value: string | number
  label: string | number
  disabled?: boolean
}

interface SelectProps {
  items: Items[]
  name?: string
  placeholder: string
  onChangeHandler: (e: any) => void
  selectProps?: MuiSelectProps
  multiple?: boolean
  value: string | number | number[] | string[]
  dataTestId?: string
}

const useStyles = makeStyles((theme) => ({
  selectDropdown: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
  },
}))

const defaultSelectProps: MuiSelectProps = {
  variant: 'outlined',
  IconComponent: DropDownButton,
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
}

const menuItemRenderer = (
  items: Items[],
  selectedValues: any,
  placeholder: string,
  isMultiSelect: boolean
) => {
  if (isMultiSelect) {
    return items.map(({ value: itemValue, label = '', disabled = false }) => {
      return (
        <MenuItem key={itemValue} value={itemValue}>
          <Checkbox checked={selectedValues.indexOf(itemValue) > -1} />
          {label}
        </MenuItem>
      )
    })
  }
  const placeHolderItem = {
    value: -1,
    label: placeholder,
    disabled: true,
  }
  return [placeHolderItem, ...items].map(
    ({ value: itemValue, label = '', disabled = false }) => {
      return (
        <MenuItem key={itemValue} value={itemValue} disabled={disabled}>
          {label}
        </MenuItem>
      )
    }
  )
}

export const Select = ({
  items,
  placeholder = '',
  selectProps,
  name,
  multiple = false,
  value,
  dataTestId = '',
  onChangeHandler,
}: SelectProps) => {
  const classes = useStyles()
  return (
    <MuiSelect
      {...{
        ...defaultSelectProps,
        ...(selectProps && {
          ...selectProps,
        }),
        multiple,
        value,
        onChange: (e) => onChangeHandler(e.target.value)
      }}
      inputProps={{'data-testid': dataTestId}}
      className={classes.selectDropdown}
      name={name}
    >
      {menuItemRenderer(items, value, placeholder, multiple)}
    </MuiSelect>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  selectProps: PropTypes.object,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  onChangeHandler: PropTypes.func,
  open: PropTypes.bool
}

Select.defaultProps = {
  name: 'mySelect',
  placeholder: 'Please Select',
  items: [
    {
      value: 1,
      label: 'One',
      disabled: false,
    },
    {
      value: 2,
      label: 'Two',
      disabled: false,
    },
    {
      value: 3,
      label: 'Three',
      disabled: false,
    },
  ],
  multiple: false,
  selectProps: defaultSelectProps,
}

export default Select