import { useApolloClient } from '@apollo/client'
import { Path, DataLakeServiceClient } from '@azure/storage-file-datalake'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import {
  Run,
  useIEDispatch,
  useIESelector,
  STATUS_TITLES,
  STATUS_COLORS,
  getOperationLogs,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  createStyles,
  makeStyles,
  Typography,
  Card,
  Tooltip,
} from '@material-ui/core'
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  GetAppOutlined as GetAppOutlinedIcon,
} from '@material-ui/icons'
import { saveAs } from 'file-saver'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import BounceLoader from 'react-spinners/BounceLoader'
import { useIntl } from 'react-intl'
import { enUS, enGB } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { ReactComponent as IconDownload } from '../../../assets/IconDownload.svg'
import OutputFunction from './OutputFunction'
import JSZip from 'jszip'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'

const useStyles = makeStyles((theme) =>
  createStyles({
    tableData: {
      '& .MuiTableCell-root': {
        border: '1px solid    rgba(224, 224, 224, 1)',
      },
    },

    cardContainer: { flex: 1, marginTop: '35px' },
    cardStyles: {
      padding: '40px 50px',
      borderRadius: '8px',
      flex: 1,
    },
    fontStyle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.3px',
    },
    valueText: {
      fontFamily: theme.typography.fontFamily,
      '& span': {
        color: '#44697D',
      },
    },
    PipelineRunReport: {
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: '#F0FBFA 0% 0% no-repeat padding-box',
    },
    dataContainer: {
      marginBottom: '18px',
    },
    dataHeaders: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      marginTop: 10,
      letterSpacing: '0.18px',
      marginBottom: '12px',
      color: '#44697D',
    },
    dataValues: {
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.22px',
      color: '#22353F',
      '& > li': {
        padding: 0,
      },
    },
    backButtonStyles: {
      minWidth: 'unset',
      marginRight: '10px',
      width: 'unset',
    },
    importDateContainer: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
      },
    },
    dataIngestionID: {
      [theme.breakpoints.up('md')]: {
        marginTop: '10px',
      },
    },
    summaryActions: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    actionButtons: {
      marginRight: '10px',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      minWidth: '135px',
      height: '34px',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
      },
    },
  })
)

const XS_GRID_SIZE = 12
const MD_GRID_SIZE = 6
const LG_GRID_SIZE = 3

const getFileName = (path: string): string => {
  const pathParts = path.split('/')
  return pathParts[pathParts.length - 1]
}
export const AuditAnalyticsView = () => {
  const classes = useStyles()
  const { locale, formatMessage } = useIntl()
  const urlParams = useParams<{ operationId: string }>()
  const azureClient = useContext(AzureClientContext)
  const apolloClient = useApolloClient()
  const dispatch = useIEDispatch()
  const history = useHistory()
  const { operationLogs } = useIESelector((state) => state.auditAnalytics)
  const [errorFilePaths, setErrorFilePaths] = useState<Path[]>([])
  const operationLog = operationLogs.find((operationLog) => {
    return operationLog.id === urlParams.operationId
  })
  const [bundleFunctions, setBundleFunctions] = useState([])

  useEffect(() => {
    if (!operationLogs || operationLogs.length === 0) {
      dispatch(getOperationLogs(apolloClient))
    }
    if (operationLog) {
      let bFunctions = [...operationLog.bundle.bundleFunctions]
      bFunctions.sort((a: any, b: any) => a?.order - b?.order)
      setBundleFunctions(bFunctions)
    }
  }, [apolloClient, dispatch, operationLogs])

  const downloadErrorFile = useCallback(
    async function downloadErrorFile(filePath: string, fileName: string) {
      const containerClient = azureClient.getFileSystemClient(
        operationLog.dataIngestion.container.fileSystemId
      )
      const fileClient = containerClient.getFileClient(filePath)
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      const blob = new Blob([downloadBlob], { type: 'application/text' })

      saveAs(blob, fileName)
    },
    [operationLog, azureClient]
  )

  const downloadOutPutFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    operationLogId: string,
    operationLogName: string
  ) => {
    const zip = new JSZip() // Create a new instance of JSZip for creating a zip file
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const { bundle } = operationLog
    const functions = bundle?.bundleFunctions

    for (const el of functions) {
      const {
        order,
        function: { name },
      } = el
      const FILE_NAME =
        STATUS_TITLES[operationLog.status] === 'Failed'
          ? `tmp_${name}_${order}.json`
          : `tmp_${name}_${order}.csv`
      const fileClient = containerClient.getFileClient(
        `${diContainer.sharePath}/daa/${operationLogId}/out/${FILE_NAME}`
      )
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      zip.file(FILE_NAME, downloadBlob)
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' })
    saveAs(zipBlob, `original_for_${operationLogId}.zip`)
  }

  return operationLog ? (
    <section className={classes.PipelineRunReport}>
      <Grid container direction="column">
        <Grid item container>
          <Grid item>
            <Button
              onClick={() =>
                history.push(
                  localizeRouteKey(
                    formatMessage,
                    locale,
                    AppRoute.AuditAnalytics
                  )
                )
              }
              size="medium"
              color="primary"
              startIcon={<ArrowBackOutlinedIcon />}
              className={classes.backButtonStyles}
            />
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography
                  className={classes.fontStyle}
                  style={{ marginRight: '5px', display: 'inline-block' }}
                  variant="h5"
                >
                  {operationLog?.dataIngestion?.auditedEntity?.name || '--'}
                </Typography>
                <Typography
                  className={classes.fontStyle}
                  variant="h5"
                  style={{ display: 'inline-block', marginRight: '5px' }}
                >
                  -
                </Typography>
                <Typography
                  className={classes.fontStyle}
                  color="secondary"
                  variant="h5"
                  style={{ display: 'inline-block' }}
                >
                  Digital Audit Analytics
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.summaryActions}>
                <Button
                  className={classes.actionButtons}
                  style={{
                    minWidth: 'unset',
                    width: 'unset',
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    downloadOutPutFile(
                      azureClient,
                      operationLog.dataIngestion.container,
                      operationLog.id,
                      operationLog.name
                    )
                  }
                >
                  <IconDownload />
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginTop: '10px' }}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.valueText}>
                  Status :{' '}
                  <span style={{ color: STATUS_COLORS[operationLog.status] }}>
                    {STATUS_TITLES[operationLog.status]}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.importDateContainer}>
                <Typography className={classes.valueText}>
                  Date &amp; Time of Run :
                  <span style={{ marginLeft: '10px' }}>
                    {operationLog.initiatedAt
                      ? format(parseISO(operationLog.initiatedAt), 'Pp', {
                          locale: locale === 'en-US' ? enUS : enGB,
                        })
                      : '--'}
                  </span>
                </Typography>
              </Grid>
              <Grid item className={classes.dataIngestionID}>
                <Typography className={classes.valueText}>
                  Analytics ID : <span>{operationLog.id}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className={classes.cardContainer}>
          <Card variant="outlined" elevation={0} className={classes.cardStyles}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Operation Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Client Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog?.dataIngestion?.auditedEntity?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Engagement Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog?.dataIngestion?.engagement?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Ingestion Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog?.dataIngestion?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  File Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {`${operationLog?.bundle?.entity?.name}.csv` || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Bundle Used :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog.bundle.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={12}
                lg={9}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Created By :
                </Typography>
                <Typography className={classes.dataValues}>
                  {operationLog.createdBy}
                </Typography>
              </Grid>
              <Grid item xs={XS_GRID_SIZE}>
                <Grid container justify="space-between">
                  <Grid item xs={LG_GRID_SIZE}>
                    <Typography className={classes.dataHeaders}>
                      Output :
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {bundleFunctions.map((row, ind) => (
                <OutputFunction
                  key={`out-${ind}`}
                  bundleFn={row}
                  auditAnalyticsId={operationLog?.id}
                  dataIngestionContainer={operationLog?.dataIngestion.container}
                />
              ))}
              {errorFilePaths.length >= 1 && (
                <Grid item xs={XS_GRID_SIZE} md={12} lg={12}>
                  <Grid item>
                    <Typography
                      className={classes.dataHeaders}
                      style={{ fontSize: 'x-large', marginBottom: '20px' }}
                    >
                      Steps To Resolve Error :
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      1) Check file for errors
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      2) Re-upload file for ingestion
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      Download Error files: {errorFilePaths.length} file with
                      errors
                    </Typography>
                    <Grid
                      item
                      xs={XS_GRID_SIZE}
                      md={MD_GRID_SIZE}
                      lg={LG_GRID_SIZE}
                    >
                      <List>
                        {errorFilePaths.map((path: Path, i) => {
                          // TODO make this a component
                          const errorFileName = getFileName(path.name)
                          return (
                            <ListItem key={i}>
                              <ListItemText
                                primary={errorFileName}
                                className={classes.dataValues}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  onClick={() =>
                                    downloadErrorFile(path.name, errorFileName)
                                  }
                                  color="primary"
                                >
                                  <GetAppOutlinedIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </section>
  ) : (
    <BounceLoader
      color={'#44697d'}
      loading={operationLogs.length === 0}
      size={20}
    />
  )
}

export default AuditAnalyticsView
