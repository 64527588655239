import { gql } from '@apollo/client'

export const GET_BUNDLES = gql`
  query getBundles{
    getBundles {
    id
    name
    entity {
      name
      displayName
    }
    bundleFunctions {
      bundleId
      function {
        name
        id
      }
      order
      functionId
      params
      status
      source
    }
    }
  }
`
