export enum HELPER_TYPE {
  CLIENT_ENGAGEMENT_CREATE = 'CLIENT_ENGAGEMENT_CREATE',
  ENTITY_LEGAL_NAME = 'ENTITY_LEGAL_NAME',
  GROUPER_SELECTION = 'GROUPER_SELECTION',
  ACCOUNTING_DATES = 'ACCOUNTING_DATES',
  CLIENT_SYSTEM = 'CLIENT_SYSTEM',
  DATA_EXTRACT_TYPE = 'DATA_EXTRACT_TYPE',
  CDM_ENTITY_SELECTION_AND_MAPPING = 'CDM_ENTITY_SELECTION_AND_MAPPING',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  CLIENT_CODE = 'CLIENT_CODE',
}

export enum FILE_HELPER {
  FILE_SIZE_100_MEGABYTES = 100000000,
  FILE_SIZE_TEN_MEGABYTES = 104857600,
  FILE_SIZE_ONE_GIGABYTES = 1073741824,
  FILE_SIZE_50_GIGABYTES = 53687091200
}

export const HELPER_INFO = {
  [HELPER_TYPE.CLIENT_ENGAGEMENT_CREATE]: {
    title: 'Create New Client & Engagement',
    body: 'Add New Client Name and Engagement Name',
    body1: 'This feature is currently disabled. Please contact your administrator to enable it.',
  },
  [HELPER_TYPE.ENTITY_LEGAL_NAME]: {
    title: 'Entity Legal Name',
    body: 'This box captures the name of the entity (company, charity, body) that you are auditing. This will be used to keep all of the data for this entity collected together, and to enable different members of your team to access the data. It is best to use the entity’s full legal name to avoid confusion or duplication​.',
  },
  [HELPER_TYPE.GROUPER_SELECTION]: {
    title: 'Grouping',
    body: 'This dropdown allows the user to select the grouping that they would like to use to group the data. Please note that Grouping will created only for Trial Balance and will not appear in the dropdown until all values have been mapped.',
  },
  [HELPER_TYPE.ACCOUNTING_DATES]: {
    title: 'Accounting Dates',
    body: 'Please enter the start and end dates of the accounting period related to this audit. This information is used to keep data from the same audit together, and to enable the right members of your team to access the right data.​',
  },
  [HELPER_TYPE.CLIENT_SYSTEM]: {
    title: 'Client System',
    body: 'To process your data, we need to know which system your client uses for keeping records. Please select the system that you use from the drop-down menu. If you aren’t sure, this information should have been captured during audit planning. Currently we can only accept uploads from one system at a time, so if you have data from multiple systems please upload one now, and start another upload from the status page for each additional system.​',
  },
  [HELPER_TYPE.DATA_EXTRACT_TYPE]: {
    title: 'Data Extract Type',
    body: 'Data will need to be treated differently depending on whether the client has extracted it by running a report, or whether it is a direct extraction from the underlying database. Please select ‘Report Extract’ where a report has been run and ‘System Extract’ where a direct database extraction has been performed. ​',
  },
  [HELPER_TYPE.CDM_ENTITY_SELECTION_AND_MAPPING]: {
    title: 'CDM Selection and Mapping',
    body: 'Select the types of data you wish to upload, for example if you are looking to upload the General Ledger transaction listing, select GL Details. Sometimes multiple files are needed to produce a table from your ERP. In this case, the tool will prompt you to upload the necessary tables and identify which is which.​​',
  },
  [HELPER_TYPE.SUPPORT_EMAIL]: {
    title: 'Entity Information',
    body: 'To initiate a support ticket, please write a brief description of your query. A response will be sent to the email displayed on the screen. This is the email address registered on your Engine B account.',
  },
  [HELPER_TYPE.CLIENT_CODE]: {
    title: 'Client Code Information',
    body: 'This will be used to capture the client code related to audited entity (company, charity, body) that you are auditing. This will be used to keep all of the data for this entity collected together, and to enable different members of your team to access the data.',
  },
}
