import { gql } from '@apollo/client'

export const CREATE_ERP_EXTRACTION = gql`
  mutation createErpExtraction(
    $name: String!
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $erpConnectionId: String!
    $selectedEntities: [String!],
    $auditFirm: String!,
    $clientName: String!,
    $engagementName: String!,
    $preAuthConfigValues: JSON!,
  ) {
    createErpExtraction(
      name: $name 
      startDateTime: $startDateTime 
      endDateTime: $endDateTime 
      erpConnectionId: $erpConnectionId
      selectedEntities: $selectedEntities,
      auditFirm: $auditFirm,
      clientName: $clientName,
      engagementName: $engagementName,
      preAuthConfigValues: $preAuthConfigValues
    ) {
      name
    }
  }
`
