import {
  AuditedEntity,
  CdmEntity,
  DataIngestion,
  DataIngestionStatus,
  Erp,
  Engagement
} from '@engine-b/shared/types'

export type DataIngestionExtension = {
  status: RunStatus
  dataStartDate?: DataIngestion['dataStartDate']
  dataEndDate?: DataIngestion['dataEndDate']
  auditedEntityName: AuditedEntity['name']
  erpId?: Erp['id']
  extractType?: DataIngestion['extractType']
}

export interface Run extends Partial<Omit<DataIngestion, 'status  | erp'>> {
  uiState: RunStatus
  auditedEntityName?: AuditedEntity['name']
  erpId?: Erp['id']
  cdmEntitySystemNames?: CdmEntity['systemName'][]
  name: DataIngestion['name']
  engagement: Engagement
  preloaderInfo?: any
}

export type Runs = Run[]

export interface Report {
  company_name: string
  company_number?: number
  erpSystem: string
  pipeline_run_status: string
  pipeline_run_id: string
  process_run_by_email: string
  process_run_by_name: string
  pipeline_run_on: string
  errors: boolean
  total_successful_rows: number
  total_errors_count: number
  errors_detail: string
}

export interface ErrorReport {
  total_rows_processed: number
  total_successful_rows: number
  total_errors_count: number
}

export interface ErrorEntry {
  row: number
  error: string
}

export interface Groupings {
  id: string
  name: string
  version: string
  lastUpdatedBy: string
}

export type RunId = string

export enum RunStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  ERROR = 'ERROR',
  INITIALIZED = 'INITIALIZED',
  FAILED = 'FAILED',
  UPLOADING_FILES = 'UPLOADING_FILES',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  INIT_ERROR = 'INIT_ERROR',
}
export type StatusTitleMap = Record<RunStatus, string>

export const STATUS_TITLES: StatusTitleMap = {
  [RunStatus.NOT_STARTED]: 'Not started',
  [RunStatus.IN_PROGRESS]: 'In-progress',
  [RunStatus.COMPLETED]: 'Completed',
  [RunStatus.COMPLETED_WITH_ERRORS]: 'Completed with errors',
  [RunStatus.ERROR]: 'Error',
  [RunStatus.INITIALIZED]: 'Initialized',
  [RunStatus.FAILED]: 'Failed',
  [RunStatus.UPLOADING_FILES]: 'Uploading files',
  [RunStatus.UPLOAD_COMPLETE]: 'Upload complete',
  [RunStatus.UPLOAD_ERROR]: 'Upload error',
  [RunStatus.INIT_ERROR]: 'Error initializing',
}

export const STATUS_COLORS: StatusTitleMap = {
  [RunStatus.NOT_STARTED]: '',
  [RunStatus.IN_PROGRESS]: '#F7931E',
  [RunStatus.COMPLETED]: '#37AB3F',
  [RunStatus.COMPLETED_WITH_ERRORS]: '#F7931E',
  [RunStatus.ERROR]: '#DE4D4D',
  [RunStatus.INITIALIZED]: '',
  [RunStatus.FAILED]: '#DE4D4D',
  [RunStatus.UPLOADING_FILES]: '',
  [RunStatus.UPLOAD_COMPLETE]: '',
  [RunStatus.UPLOAD_ERROR]: '',
  [RunStatus.INIT_ERROR]: '',
}

export function mapDIStatusToRunStatus(
  diStatus: DataIngestionStatus
): RunStatus {
  return RunStatus[diStatus]
}

export function mapDItoRun(di: DataIngestion): Run {
  const { erp, status, cdmEntities, ...rest } = di
  const partial: Run = { ...rest, uiState: mapDIStatusToRunStatus(status) }
  if (rest.auditedEntity?.name) {
    partial.auditedEntityName = rest.auditedEntity.name
  }
  if (erp?.id) {
    partial.erpId = erp.id
  }

  if (cdmEntities && cdmEntities.length > 0) {
    partial.cdmEntities = cdmEntities
    partial.cdmEntitySystemNames = cdmEntities.map(
      (ce) => ce.cdmEntity.systemName
    )
  }
  return partial
}

export enum EBAutocompleteReason {
  CLEAR = 'clear',
  CREATE = 'create-option',
  ALL = 'all'
}
