import Ajv, { _, KeywordCxt } from 'ajv'
import addFormats from 'ajv-formats'
/**
 * Creates a new instance of AJV with the appropriate default configuration
 * including the formats we want to support.
 *
 * @returns A new instance of Ajv with the appropriate default configuration
 */
export function createDefaultAjv() {
  const ajv = new Ajv({
    useDefaults: true,
    allErrors: true,
    strict: true,
    /**
     * We used to have to turn off strictRequired as it doesn't deal properly with the use of "anyOf" which we use
     * in our schemas to allow us to define we require at least one of `extract_keys` or `jq_preformat` in a config.
     * See https://github.com/ajv-validator/ajv/issues/1571 (open since 2021)
     *
     * However, after the refactor of the extraction-erp-config.schema.json, so the anyOf/required is used within
     * a sub-schema that uses $ref to reference the properties, it seems that it does now work correctly.
     * So re-enabling but leaving this comment to help if this changes in future.
     */
    strictRequired: true,
    /**
     * Disable caching of schemas because the schemas we use are usually dynamic rather than static (e.g. based
     * on the ERP responses, or dynamic per ERP)
     */
    addUsedSchema: false,
  })

  /**
   * Add the formats that we want to support.
   * Note that we limit the formats we include to reduce any risks of ReDOS.
   * See https://ajv.js.org/guide/formats.html#string-formats
   */
  addFormats(ajv, ['date', 'time', 'date-time', 'uri', 'uuid'])

  /**
   * Add custom formats
   *
   * # Domain name format (including length validation and punycode)from https://stackoverflow.com/a/41193739
   *   This is a Fully Qualified Domain Name (FQDN) but WITHOUT the trailing dot (i.e. "example.com" not "example.com.")
   *   This is not the same as the `hostname` format in ajv-formats: "foo" is a hostname; "foo.com" is a domain name.
   */
  ajv.addFormat(
    'domain-name',
    /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/
  )

  ajv.addKeyword({
    keyword: 'ebDateGreaterThan',
    type: 'string',
    schemaType: 'string',
    error: {
      message:
        'Data range is invalid. Please make sure the End Date is set to later than the Start Date.',
    },
    validate: (schema, data, schemaCxt, dataCxt) => {
      if (dataCxt) {
        return (
          new Date(dataCxt['parentData']['start_date']) <
          new Date(dataCxt['parentData']['end_date'])
        )
      } else {
        return true
      }
    },
  })

  ajv.addKeyword({
    keyword: 'ebDateGreaterThanOrEqualTo',
    type: 'string',
    schemaType: 'string',
    error: {
      message:
        'Data range is invalid. Please make sure the End Date is set equal to or later than the Start Date.',
    },
    validate: (schema, data, schemaCxt, dataCxt) => {
      if (dataCxt) {
        return (
          new Date(dataCxt['parentData']['start_date']) <=
          new Date(dataCxt['parentData']['end_date'])
        )
      } else {
        return true
      }
    },
  })

  /**
   * Add an `ebRedacted` keyword that will redact a string to hide its contents.
   * This is not intended for manual use, but will be used by API Extraction to redact any schema properties
   * that use the `writeOnly` keyword by modifying those schemas to add in `ebRedacted` for use before returning
   * data from workflows.
   *
   * @example
   * "shortStri" => "..."
   * "longString" => "lon..."
   */
  ajv.addKeyword({
    keyword: 'ebRedacted',
    type: 'string',
    schemaType: 'boolean',
    modifying: true,
    code(cxt) {
      const { gen, it, data } = cxt
      //
      // To modify we have to access via the parentData and parentDataProperty.
      //
      // To explain why, assume we have object `foo = {bar: 'baz'}`.
      // Then we get called as effectively `code(data=foo.bar, parentData=foo, parentDataProperty='baz')`.
      // If we just said `data="new value"` we would be changing the `data` variable, not the original `foo.bar`.
      // So instead we says `parentData[parentDataProperty] = "new value"`, which changes the property in the object.
      //
      // Of course, we have to do all this in generated code for safety and performance.
      // See https://ajv.js.org/codegen.html
      //
      const { parentData, parentDataProperty } = it

      gen.code(
        _`${parentData}[${parentDataProperty}] = ${data}.length < 10 ? '...' : ${data}.slice(0,3) + '...'`
      )

      cxt.ok(true)
    },
  })

  return ajv
}
