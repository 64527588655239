export const SAVED_MAPPINGS_PATH = '/custom-mappings'

export const JsDateFormat = {
  '%H:%M:%S': 'HH:mm:ss',
  '%d %b %Y': 'dd MMM yyyy',
  '%d-%m-%y': 'dd-MM-yy',
  '%d-%m-%Y': 'dd-MM-yyyy',
  '%d-%m-%Y %H.%M': 'dd-MM-yyyy HH.mm',
  '%d-%m-%Y %H.%M.%S': 'dd-MM-yyyy HH.mm.ss',
  '%d-%m-%Y %H:%M': 'dd-MM-yyyy HH:mm',
  '%d-%m-%Y %H:%M:%S': 'dd-MM-yyyy HH:mm:ss',
  '%d-%b-%y': 'dd-MMM-yy',
  '%d-%b-%Y': 'dd-MMM-yyyy',
  '%d/%m/%y': 'dd/MM/yy',
  '%d/%m/%Y': 'dd/MM/yyyy',
  '%d/%m/%Y %H:%M:%S': 'dd/MM/yyyy HH:mm:ss',
  '%d/%m/%Y %I:%M:%S %p': 'dd/MM/yyyy hh:mm:ss a',
  '%m': 'MM',
  '%m-%d-%y': 'MM-dd-yy',
  '%m-%d-%Y': 'MM-dd-yyyy',
  '%m/%d/%y': 'MM/dd/yy',
  '%m/%d/%Y': 'MM/dd/yyyy',
  '%m/%d/%Y %I:%M:%S %p': 'MM/dd/yyyy hh:mm:ss a',
  '%Y': 'yyyy',
  '%Y-%m-%d': 'yyyy-MM-dd',
  '%Y-%m-%d %H:%M': 'yyyy-MM-dd HH:mm',
  '%Y-%m-%d %H:%M:%S': 'yyyy-MM-dd HH:mm:ss',
  '%Y-%m-%d %H:%M:%S.%f': 'yyyy-MM-dd HH:mm:ss.SSS',
  '%Y-%m-%dT%H:%M:%S.%f': "yyyy-MM-dd'T'HH:mm:ss.SSS",
  '%Y/%m/%d': 'yyyy/MM/dd',
  '%Y%m%d': 'yyyyMMdd',
}
