import { gql } from '@apollo/client'

export const GET_DATA_INGESTIONS = gql`
  query GetDataIngestions($ingestionId: String, $page: Int, $pageSize: Int, $auditedEntityId: String, $engagementId: String, $status: String, $initiatedAt: String, $erpId: String, $name: String) {
    dataIngestions(ingestionId: $ingestionId, page: $page, pageSize: $pageSize, auditedEntityId: $auditedEntityId, engagementId: $engagementId, status: $status, initiatedAt: $initiatedAt, erpId: $erpId, name: $name) {
      data {
        id
        dataStartDate
        dataEndDate
        initiatedAt
        completeAt
        adfPipelineId
        auditedEntity {
          name
          id
        }
        status
        erp {
          id
        }
        extractType
        isCustom
        container {
          fileSystemId
          storageAccountURL
          inputPath
          incomingPath
          sharePath
          errorsPath
        }
        cdmEntities {
          cdmEntity {
            systemName
            extractType
            name
            inputFiles {
              name
              id
            }
          }
        }
        createdBy
        clientCode
        uploadedFiles {
          fileNameByUser
        }
        groupId
        groupName
        groupVersion
        groupLastUpdatedBy
        name
        engagement {
          id
          name
        }
        preloaderInfo {
          metaJson
          disableFields
          additionalDetailOptions
          additionalDetailValues
          inputData {
            reportType
            fileName
            keepCopy
          }
        }
      }
      total
    }
  }
`

export const GET_DATA_INGESTION = gql`
query DataIngestion($ingestionId: String!) {
  dataIngestion(ingestionId: $ingestionId) {
    id
    dataStartDate
    dataEndDate
    initiatedAt
    completeAt
    adfPipelineId
    auditedEntity {
      name
      id
    }
    status
    erp {
      id
    }
    extractType
    isCustom
    container {
      fileSystemId
      storageAccountURL
      inputPath
      incomingPath
      sharePath
      errorsPath
    }
    cdmEntities {
      cdmEntity {
        systemName
        extractType
        name
        inputFiles {
          name
          id
        }
      }
    }
    createdBy
    clientCode
    uploadedFiles {
      fileNameByUser
    }
    groupId
    groupName
    groupVersion
    groupLastUpdatedBy
    name
    engagement {
      id
      name
    }
    preloaderInfo {
      metaJson
      disableFields
      additionalDetailOptions
      additionalDetailValues
      inputData {
        reportType
        fileName
        keepCopy
      }
    }
  }
}
`