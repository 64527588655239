import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
  Backdrop,
  Tooltip
} from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Select } from '@engine-b/shared/components'
import { uploadFileToAzureContainer, AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { ReactComponent as ExportIcon } from '../../assets/ExportIcon.svg'
import { ReactComponent as IconError } from '../../assets/IconError.svg'
import { ReactComponent as IconRobot } from '../../assets/IconRobot.svg'
import { green, red } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  useIEDispatch,
  useIESelector,
  getGroupingOptions,
  getCGfileGrouping,
  getCGfileGroupingCodes,
  getGroup,
  clearGrouping,
  saveGroupings,
  resetCustomGrouperState,
  setGroupingFileUploadError,
  setgroupingDuplicateAccountsError,
  resetGrouping,
  importGroupings,
  countRows,
  getDataIngestionGroupings,
  beginDataIngestionGroup,
  clearAllIngestionGroupings,
  getUgms,
  removeAutoGroups,
  setSelectedUgm,
  CUSTOM_GROUPING_DATA,
  isLoading,
  isLoadingEntities,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import ExistingGroupingDialog from './ExistingGroupingDialog'
import CustomGroupingTable from './CustomGroupingTable'
import { useMsal } from '@azure/msal-react'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import SaveGroupingDialog from './SaveGroupingDialog'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel'
import { SIDEBAR_WIDTH } from '@engine-b/integration-engine/ui/layout'
import { trackPageView } from '../../services/AppInsights'

const useStyles = makeStyles((theme) => ({
  customGrouping: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    minHeight: 'calc(100vh - 80px)',
    marginBottom: '72px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 30px 80px 30px',
    },
    background: '#F0FBFA 0% 0% no-repeat padding-box',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
  },
  title: {
    font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
    letterSpacing: '0.3px',
    color: '#22353F',
    opacity: 1,
  },
  header: {
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    width: '100%',
    padding: '10px 0px 10px 0px',
    [theme.breakpoints.up('lg')]: {
      padding: '0px 0px 25px 0px',
      justifyContent: 'flex-end',
    },
  },
  saveExitBtnItem: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '30px',
    bottom: '40px',
    right: '0',
    backgroundColor: '#FFFFFF',
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    position: 'fixed',
    padding: '12px 30px',
    zIndex: 30,
  },
  footer: {
    display: 'flex',
    gap: 5,
    justifyContent: 'justify-content',
    alignItems: 'center',
    zIndex: 10,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  mutedText: {
    font: 'normal normal bold 36px/42px Arial',
    letterSpacing: '0.36px',
    color: '#89A0AC',
    opacity: '0.4',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  linearProgress: {
    width: '20%',
  },
  line: {
    height: '12px',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.5)',
  },
  dialogBodyWarningMessage: {
    fontSize: '16px',
    marginBottom: '12px'
  },
  userAgreementWarning: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginBottom: '12px'
  },
  dialogTitle: {
    marginBottom: '12px'
  }
}))

const subTitleStyle = { fontSize: '20px' }

type ModalConfirmationState = {
  flag: boolean
  type: 'clear' | 'reset' | 'ugm_change'
  message: string
  ugm_name: string
}

const CLEAR_MESSAGE = 'Are you sure you want to clear all custom groupings?'
const RESET_MESSAGE = 'Are you sure you want to reset all custom groupings?'
const UGM_CHANGE_MESSAGE =
  'Changing the model will clear all selections. Are you sure you want proceed?'

const CustomGrouping = () => {
  const dispatch = useIEDispatch()
  const {
    loading,
    loadingSuggestions,
    loadingCGfile,
    loadingFileGrouping,
    loadingEntities,
    groupingLoadingState,
    groupingDetails,
    isGroupingSaved,
    groupingFileUploadError,
    groupingDuplicateAccountsError,
    dataIngestionGrouperId: diGId,
    AIGroupedRows,
    userGroupedRows,
    incompleteRows,
    hasRecommendations
  } = useIESelector((state) => state.customGrouper)
  const { ugms, selectedUgm } = useIESelector((state) => state.ugm)
  const { systemName } = useIESelector((state) => state.user)
  const { dataIngestionGroupings, run } = useIESelector((state) => state.runs)
  const dataIngestionRunId = useIESelector((state) => state.runs?.run?.id)

  const { type, id: typeId } = useParams<{ type: string; id: string }>()
  const [prevMapDialog, setPrevMapDialog] = useState(false)
  const [saveDialog, setSaveDialog] = useState(false)
  const [autoAssign, setAutoAssign] = useState(false)
  const [selectedRows, setSelectedRows] = useState(0)
  const [unassignedRows, setUnassignedRows] = useState(0)
  const [autoMappedRows, setAutoMappedRows] = useState(0)
  const [saveState, setSaveState] = useState({
    saveWithUnassignedRows: (unassignedRows > 0),
    saveWithAutoMappedRows: (autoMappedRows > 0),
  })
  const [isQuickGrouping, setIsQuickGrouping] = useState<boolean>(false)
  const [unassignedRowsWarningDialog, setunassignedRowsWarningDialog] = useState(false)
  const [autoMappedRowsWarningDialog, setAutoMappedRowsWarningDialog] = useState(false)
  const [entityFilterOptions, setEntityFilterOptions] = useState([])
  const azureClient = useContext(AzureClientContext)
  const client = useApolloClient()
  const { instance, inProgress, accounts } = useMsal()
  const { formatMessage, locale } = useIntl()
  const history = useHistory()

  const fileChangeHandler = async (e: any) => {
    dispatch(isLoading(true))
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const uploadPaths = [];

    for (const file of e.target.files) {
      const today = new Date()
      const dataAndTime = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}_${today.getHours()}${today.getMinutes()}`
      const uploadPath = `incoming/custom-grouper/${dataAndTime}_${file['name']}`
      await uploadFileToAzureContainer({
        azureClient,
        containerId: systemName,
        file,
        inputPath: uploadPath,
        uploadOptions: {
        },
      })
      uploadPaths.push(`${systemName}/${uploadPath}`)
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    dispatch(getCGfileGrouping({ formData: {paths: uploadPaths}, headers }))
      .then(() => {
        dispatch(isLoading(false))
      })
    setSaveState({
      saveWithUnassignedRows: false,
      saveWithAutoMappedRows: false,
    })
  }

  useEffect(() => {
    dispatch(getUgms({ client }))
    dispatch(getGroupingOptions({ client, fileName: selectedUgm }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (type === 'ingestion' && typeId) {
      setIsQuickGrouping(true)
      getGroupingCodes()
    }
    if (type === 'grouping' && typeId) {
      dispatch(getDataIngestionGroupings({ client, id: typeId, type }))
    }
  }, [type, typeId])

  useEffect(() => {
    trackPageView({name: 'Custom Groupings'});
    return () => {
      dispatch(resetCustomGrouperState())
      dispatch(clearAllIngestionGroupings())
    }
  }, [])

  useEffect(() => {
    if (type === 'grouping' && dataIngestionGroupings.length == 1) {
      loadGroup(dataIngestionGroupings[0].grouperId)
    }
  }, [dataIngestionGroupings])

  const loadEntities = async () => {
    dispatch(isLoadingEntities(true))
    setEntityFilterOptions([...new Set(groupingDetails?.groupingCodes.map(item => item.entity))])
  } 

  useEffect(() => {
    dispatch(countRows())
    loadEntities().then(() => dispatch(isLoadingEntities(false)))
  }, [groupingDetails])

  const classes = useStyles()

  const [open, setOpen] = React.useState<ModalConfirmationState>({
    flag: false,
    type: null,
    message: '',
    ugm_name: '',
  })

  const getGroupingCodes = async () => {
    dispatch(isLoading(true))
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'Application/Json',
    }
    const formData = {
      ingestion_id: typeId,
      audit_firm_name: systemName,
    }

    dispatch(getCGfileGroupingCodes({ formData, headers }))
      .then(() => {
        dispatch(isLoading(false))
      })
  }

  const handleClickOpen = (
    type: ModalConfirmationState['type'],
    value = ''
  ) => {
    /**
     * Reset mapping on UGM Change
     * 1) If there is no mapped records then reset grouping and change ugm
     * 2) If there is any mapped records then show warning dialog that you will lose mapping
     */
    const index = groupingDetails?.groupingCodes?.findIndex(
      (gc: CUSTOM_GROUPING_DATA) =>
        gc.mapped === 'yes' || gc.mapped === 'AutoAssigned'
    )
    if (value && index === -1) {
      dispatch(getGroupingOptions({ client, fileName: value }))
      dispatch(setSelectedUgm(value))
      dispatch(clearGrouping())
    } else {
      setOpen({
        type,
        flag: true,
        message:
          type === 'clear'
            ? CLEAR_MESSAGE
            : type === 'ugm_change'
            ? UGM_CHANGE_MESSAGE
            : RESET_MESSAGE,
        ugm_name: value,
      })
    }
  }

  const removeAutoGrouping = () => {
    dispatch(removeAutoGroups())
  }

  const handleClose = (flag) => {
    if (flag) {
      //dispatch action to clear data.
      if (open.type === 'clear') {
        dispatch(clearGrouping())
        setSaveState({
          saveWithUnassignedRows: false,
          saveWithAutoMappedRows: false,
        })
      } else if (open.type === 'reset') {
        dispatch(resetGrouping())
        setSaveState({
          saveWithUnassignedRows: false,
          saveWithAutoMappedRows: false,
        })
      } else if (open.type === 'ugm_change') {
        dispatch(clearGrouping())
        dispatch(getGroupingOptions({ client, fileName: open.ugm_name }))
        dispatch(setSelectedUgm(open.ugm_name))
        setSaveState({
          saveWithUnassignedRows: false,
          saveWithAutoMappedRows: false,
        })
      }
    }
    setOpen({
      type: null,
      flag: false,
      message: '',
      ugm_name: '',
    })
  }

  const loadGroup = (id: string) => {
    dispatch(getGroup({ client, id }))
  }

  const openModal = () => {
    setPrevMapDialog(true)
  }

  const onPrevMappingSelect = async (result: boolean, file_path: string) => {
    setPrevMapDialog(false)
  }

  const onGroupingSave = (flag: boolean, fileName: string, id: string) => {
    setSaveDialog(false)
    if (flag) {
      const groupingCodes = groupingDetails?.groupingCodes.map((gc) => ({
        nominalCode: gc.nominalCode?.toString(),
        glAccountName: gc.glAccountName || null,
        ...(gc.accountNameId !== -1 && { accountNameId: gc.accountNameId }),
        ...(gc.accountName !== -1 && { accountName: gc.accountName }),
        ...(gc.fsCaption !== -1 && { fsCaption: gc.fsCaption }),
        ...(gc.accountSubType !== -1 && {
          accountSubType: gc.accountSubType,
        }),
        ...(gc.accountType !== -1 && { accountType: gc.accountType }),
        ...(gc.glMapNumber != '' && { glMapNumber: gc.glMapNumber }),
      }))

      const variables = {
        grouping: {
          id,
          name: fileName,
          groupingCodes,
          complete: Boolean(
            !groupingDetails?.groupingCodes?.find((gc) => gc.mapped === 'no')
          ),
        },
        dataIngestionId: type === 'ingestion' ? typeId : dataIngestionRunId,
      }

      dispatch(saveGroupings({ client, variables }))
        .unwrap()
        .then((saveGroupingsRes) => {
          if (saveGroupingsRes?.meta?.requestStatus === 'rejected')
            throw 'Error occurred while Saving Groupings'
        })
    }
  }

  useEffect(() => {
    if (diGId) {
      dispatch(
        beginDataIngestionGroup({ client, dataIngestionGrouperId: diGId })
      )
        .unwrap()
        .then((beginDataIngestionGroupRes) => {
          if (beginDataIngestionGroupRes?.meta?.requestStatus === 'rejected')
            throw 'Error occurred while initializing data ingestion grouping'
        })
    }
  }, [diGId])

  useEffect(() => {
    setUnassignedRows(groupingDetails?.groupingCodes?.filter((x) => x.mapped === 'no').length)
    setAutoMappedRows(groupingDetails?.groupingCodes?.filter((x) => x.mapped === 'AutoAssigned').length)
  }, [groupingDetails?.groupingCodes])

  const onSaveHandler = () => {
    if (groupingDetails?.groupingCodes?.length) {
      if (autoMappedRows > 0 && !saveState.saveWithAutoMappedRows) {
        setAutoMappedRowsWarningDialog(true)
      }
      if (unassignedRows > 0 && !saveState.saveWithUnassignedRows) {
        setunassignedRowsWarningDialog(true)
      }
    }
    displaySaveDialog();
  }

  const displaySaveDialog = () => {
    if (unassignedRows == 0 && autoMappedRows == 0) {
      setSaveDialog(true)
    }
    else if (unassignedRows > 0 && autoMappedRows == 0) {
      setSaveDialog(saveState.saveWithUnassignedRows)
    }
    else if (unassignedRows == 0 && autoMappedRows > 0) {
      setSaveDialog(saveState.saveWithAutoMappedRows)
    }
    else {
      setSaveDialog((unassignedRows > 0 === saveState.saveWithUnassignedRows) && (autoMappedRows > 0 === saveState.saveWithAutoMappedRows))
    }
  }

  useEffect(() => {
    if ((unassignedRows === 0 && saveState.saveWithUnassignedRows === false) && saveState.saveWithAutoMappedRows) {
      setSaveDialog(true)
    } else if ((autoMappedRows === 0 && saveState.saveWithAutoMappedRows === false) && saveState.saveWithUnassignedRows) {
      setSaveDialog(true)
    } else {
      setSaveDialog(saveState.saveWithUnassignedRows && saveState.saveWithAutoMappedRows)
    }       
  }, [saveState])

  const onWarningClose = () => {
    dispatch(setGroupingFileUploadError(false))
    dispatch(setgroupingDuplicateAccountsError(false))
  }

  const handleAutoAssign = () => {
    setAutoAssign(true)
    setTimeout(() => {
      setAutoAssign(false)
    }, 1000)
  }

  useEffect(() => {
    if (isGroupingSaved)
      if (type === undefined) {
        history.push(localizeRouteKey(formatMessage, locale, AppRoute.Status))
      } else {
        history.push(
          localizeRouteKey(
            formatMessage,
            locale,
            `/status/${dataIngestionRunId}`
          )
        )
      }
  }, [isGroupingSaved])

  const getTotalSelectedRows = (rows) => {
    if (rows) setSelectedRows(rows.length)
  }

  const onExport = () => {
    const data = JSON.stringify({
      groupingCodes: groupingDetails?.groupingCodes,
    })
    const blob = new Blob([data], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = `${groupingDetails?.name || 'CustomGrouping'}.json`
    link.click()

    URL.revokeObjectURL(url)
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const reader = new FileReader()

    reader.onloadend = async function (event: any) {
      try {
        const variables = JSON.parse(event.target?.result as string)

        const isValidGroupingFileUpload = variables?.groupingCodes?.every(
          (item) =>
            item.hasOwnProperty('nominalCode') &&
            item.hasOwnProperty('glAccountName')
        )

        // If the pair of nominalCode and glAccountName is duplicated, return true
        const isPairDuplicated = (data) => {
          const pairs = new Set()

          for (let i = 0; i < data.length; i++) {
            const pair = `${data[i].nominalCode}:${data[i].glAccountName}`
            if (pairs.has(pair)) {
              return true
            }
            pairs.add(pair)
          }
          return false
        }

        if (!isValidGroupingFileUpload) {
          dispatch(setGroupingFileUploadError(true))
        } else if (isPairDuplicated(variables?.groupingCodes)) {
          dispatch(setgroupingDuplicateAccountsError(true))
        } else {
          await dispatch(importGroupings(variables))
        }
      } catch (err) {
        dispatch(setGroupingFileUploadError(true))
      }
    }

    if (file) {
      reader.readAsText(file)
    }
    event.target.value = null
  }

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  const handleSaveIncompleteMapping = (flag) => {
    setSaveState({
      ...saveState,
      saveWithUnassignedRows: flag,
    })
    setunassignedRowsWarningDialog(false)
  }

  const handleSaveWithAutoMappedRows = (flag) => {
    setSaveState({
      ...saveState,
      saveWithAutoMappedRows: flag,
    })
    setAutoMappedRowsWarningDialog(false)
  }

  

  return (
    <section className={classes.customGrouping}>
      <Grid container spacing={2} className={classes.header}>
        <Grid item xs={12} lg={3}>
          <Typography className={classes.title} color="primary">
            {isQuickGrouping ? `Quick Grouping - ${run ? run.name : ""}` : 'Custom Grouping'}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Grid container className={classes.buttonContainer}>
            {!isQuickGrouping && (
              <>
                <Button
                  startIcon={<ExportIcon />}
                  color="secondary"
                  style={{ height: '44px' }}
                  variant="contained"
                  onClick={() => document.getElementById('tb-upload').click()}
                >
                  Upload CDM File
                </Button>
                <input
                  style={{ display: 'none', height: '44px', padding: 0 }}
                  id="tb-upload"
                  type="file"
                  multiple={true}
                  onChange={fileChangeHandler}
                  onClick={(e: any) => (e.target.value = null)}
                />
              </>
            )}

            <Button
              color="primary"
              variant="contained"
              onClick={() => openModal()}
              style={{ height: '44px', padding: 0 }}
            >
              Existing Groupings
            </Button>
            
            {process.env.NX_ENABLE_GROUPER_RECOMMENDATIONS === 'true' && (
              <Tooltip
                title={
                  hasRecommendations
                    ? 'Click to Auto Group'
                    : 'There are no suggestions available for this Grouping.'
                }
                placement="top"
              >
                <Button
                  startIcon={<IconRobot />}
                  color="primary"
                  variant="contained"
                  onClick={() => handleAutoAssign()}
                  style={{ height: '44px', padding: 0 }}
                  disabled={!groupingDetails}
                  data-testid="auto-group-btn"
                >
                  Auto Group {selectedRows > 0 ? 'Selection' : 'All'}
                </Button>
              </Tooltip>
            )}
            <Button
              style={{ height: '44px', padding: 0 }}
              disabled={!groupingDetails}
              color="primary"
              variant="outlined"
              onClick={() => handleClickOpen('clear')}
            >
              Clear Groupings
            </Button>
            {!isQuickGrouping && (
              <Button
                disabled={!groupingDetails}
                style={{ height: '44px', padding: 0 }}
                color="primary"
                variant="outlined"
                onClick={() => handleClickOpen('reset')}
                data-testid="reset-custom-grouping-btn"
              >
                Reset
              </Button>
            )}
            <Button
              disabled={!groupingDetails}
              style={{ height: '44px', padding: 0 }}
              color="primary"
              variant="outlined"
              onClick={() => removeAutoGrouping()}
              data-testid="remove-auto-groups-custom-grouping-btn"
            >
              Remove Auto Groups
            </Button>
            <FormControl variant="filled" style={{ width: '25%' }} data-testid="ugm-select">
              {/* <InputLabel id="demo-simple-select-label">Select Ugm</InputLabel> */}
              <Select
                value={selectedUgm}
                items={ugms.map((item) => ({
                  label: item.fileName?.split('.')[0],
                  value: item.fileName,
                }))}
                onChangeHandler={(value) => {
                  handleClickOpen('ugm_change', value)
                }}
              />
            </FormControl>
            <Button
              color="primary"
              variant="contained"
              onClick={onExport}
              disabled={!groupingDetails}
              style={{ height: '44px', padding: 0 }}
            >
              Export
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!!groupingDetails}
              style={{ height: '44px', padding: 0 }}
              onClick={handleClick}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept=".json"
                multiple={false}
                style={{ display: 'none' }}
                onClick={(e) => {
                  e.currentTarget.value = ''
                }}
                onChange={onImport}
              />
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <CustomGroupingTable
          entityFilterOptions={entityFilterOptions}
          autoAssign={autoAssign}
          getTotalSelectedRows={getTotalSelectedRows}
          loading={
            loading ||
            loadingCGfile ||
            loadingFileGrouping ||
            groupingLoadingState ||
            loadingEntities
          }
          isQuickGrouping={isQuickGrouping}
        />
      </Grid>
      <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "12px"}}> 
          { groupingDetails?.groupingCodes.length > 0 ? <>
            <Tooltip title="Number of Rows that have been mapped by the User" placement="top-end">
              <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                <CheckCircleIcon style={{ width: '20px', height: '20px', color: green[500] }} />
                <Typography style={{marginRight: "12px"}} data-testid="user-grouped-rows">{userGroupedRows}</Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Number of Rows that have not been mapped yet" placement="top-end">
              <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                <CancelIcon style={{ width: '20px', height: '20px', color: red[500] }} />
                <Typography style={{marginRight: "12px"}} data-testid="incomplete-rows">{incompleteRows}</Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Number of Rows that have been mapped by AI" placement="top-end">
              <Grid item style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                <IconRobot style={{ width: '20px', height: '20px', fill: 'black', borderRadius: '50%', padding: '2px' }}/> 
                <Typography style={{marginRight: "12px"}} data-testid="ai-grouped-rows">{AIGroupedRows}</Typography>
              </Grid>
            </Tooltip>
          </>
          : <></>
        }
      </Grid>
      <Grid item container className={classes.saveExitBtnItem}>
        <Grid>
          {saveState.saveWithAutoMappedRows ? <Typography className={classes.userAgreementWarning}><CheckCircleIcon/> User has agreed to save current mapping with Auto Mapped Rows</Typography> : <></>}
          {saveState.saveWithUnassignedRows ? <Typography className={classes.userAgreementWarning}><CheckCircleIcon/> User has agreed to save current mapping with Unassigned Rows</Typography> : <></>}
        </Grid>
        <Button
          color="secondary"
          variant="contained"
          style={{ height: '44px' }}
          onClick={onSaveHandler}
          disabled={!groupingDetails}
        >
          {typeId &&
          groupingDetails?.groupingCodes?.filter((x) => x.mapped === 'no')
            .length == 0
            ? 'Save & Run Grouping'
            : 'Save Grouping'}
        </Button>
      </Grid>

      <ExistingGroupingDialog
        open={prevMapDialog}
        onClose={onPrevMappingSelect}
        onLoad={loadGroup}
        loading={loading && loadingSuggestions && loadingCGfile}
      />

      <Dialog
        cancelText="Cancel"
        confirmText="Proceed"
        width="674px"
        height="600px"
        open={unassignedRowsWarningDialog}
        onClose={(flag) => handleSaveIncompleteMapping(flag)}
      >
        <>      
          <IconError />
          <Typography className={`${classes.dialogTitle} dialog-title`}>
            You're trying to save a grouping map with {unassignedRows} unassigned {unassignedRows > 1 ? 'rows' : 'row'}.
          </Typography>
          <div className="dialog-body">
            <Typography className={classes.dialogBodyWarningMessage}>
              This action will save your current grouping map, but you won’t be able to use it until all account codes have been grouped. 
              If you want to use this grouping map, please map all rows before saving.
            </Typography>
          </div>
        </>
      </Dialog>

      <Dialog
        cancelText="Cancel"
        confirmText="Proceed"
        width="674px"
        height="600px"
        open={autoMappedRowsWarningDialog}
        onClose={(flag) => handleSaveWithAutoMappedRows(flag)}
      >    
        <>    
          <IconError />
          <Typography className={`${classes.dialogTitle} dialog-title`}>
            You're trying to save a grouping map with {autoMappedRows} Auto Mapped {autoMappedRows > 1 ? 'rows' : 'row'}.
          </Typography>
          <div className="dialog-body">
            <Typography className={classes.dialogBodyWarningMessage}>
              This map includes AI generated groupings that have not been reviewed by a user. 
              By clicking 'Proceed' you acknowledge and accept responsibility for the AI generated recommendations.
              Alternatively, you can manually validate each account code by pressing the robot icon and attempt to save again.
            </Typography>
            <Typography className={classes.dialogBodyWarningMessage}>
              If you want to continue editing this grouping map, without confirming all Auto Mapped rows, please use the "Remove Auto Groups" button before saving this grouping.
            </Typography>

          </div>
        </>
      </Dialog>

      <Dialog
        cancelText="No"
        confirmText="Yes"
        width="674px"
        height="400px"
        open={open.flag}
        onClose={(flag) => handleClose(flag)}
      >
        <>
          <IconError />
          <br />
          <Typography component={'div'} className="dialog-title">
            {open.message}
          </Typography>
        </>
      </Dialog>
      <SaveGroupingDialog
        open={saveDialog}
        onClose={onGroupingSave}
        existingFileName={groupingDetails?.name || ''}
      />
      <Dialog
        title=""
        open={groupingFileUploadError || groupingDuplicateAccountsError}
        confirmText="Ok"
        onClose={onWarningClose}
        height="490px"
        width="915px"
      >
        <>
          <IconError />
          <br />
          <Typography className="dialog-title" style={{ marginTop: '30px' }}>
            {groupingFileUploadError
              ? 'Unable to upload CDM file.'
              : 'Duplicate accounts'}
          </Typography>
          <br />
          <Typography style={subTitleStyle}>
            {groupingFileUploadError
              ? 'Please ensure all rows of the CDM file include values in both glAccountNumber and glAccountName columns.'
              : 'Your data has account names or account numbers which are duplicated, meaning one GLAccountNumber is associated with multiple GlAccountNames, or one GLAccountName is associated with more than one GLAccountNumber.'}
          </Typography>
          <br />
          <Typography style={subTitleStyle}>
            {groupingFileUploadError
              ? 'You may need to use the Custom Mapper to map these fields.'
              : 'You may need to use the Custom Mapper to map these fields in a way that makes each GLAccountNumber and GLAccountName unique.'}
          </Typography>
        </>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.linearProgress}>
          <LinearProgressWithLabel
            className={classes.line}
            value={'Loading...'}
          />
        </div>
      </Backdrop>
    </section>
  )
}

export default CustomGrouping