import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
import TableComponent from './TableComponent'

const useStyles = makeStyles((theme) =>
  createStyles({
    customSelect: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      opacity: 1,
      width: '220px',
      height: '44px',
    },
    inputSearch: {
      width: '324px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '7px',
      '& .MuiOutlinedInput-root': {
        height: '44px',
      },
    },
    refreshButton: {
      background: '#00B2A9 0% 0% no-repeat padding-box',
      color: '#fff',
      borderRadius: '8px',
      opacity: 1,
      width: '44px',
      height: '44px',
      marginLeft: '10px',
      '&.MuiIconButton-root:hover': {
        background: '#00B2A9 0% 0% no-repeat padding-box',
      },
    },
    gridCenter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    customCheckbox: {
      '& .MuiSvgIcon-root': {
        fontSize: '24px',
      },
    },
    mr20: {
      marginRight: '20px',
    },
    filter: {
      margin: '30px 0',
    },
  })
)

const FilterComponent = () => {
  const classes = useStyles()

  // local state variables
  const [selectedFile, setSelectedFile] = useState(['All'])
  const [search, setSearch] = useState('')
  const [mandatory, setMandatory] = useState(false)

  // redux state variables
  const cMapper = useIESelector((state) => state.customMapper)

  const optionsList = ['All']
  cMapper.files.forEach((file) => {
    optionsList.push(file.reportName)
  })

  const onChangeHandler = (e) => {
    const allSelected = e.target.value[e.target.value.length - 1] === 'All'
    const noneSelected = e.target.value.length === 0 || (e.target.value.length === 1 && e.target.value[0] === 'All');
    if (allSelected || (e.target.value.length === optionsList.length - 1 && !e.target.value.includes('All'))) {
      setSelectedFile(optionsList);
    } else if (noneSelected) {
      setSelectedFile([]);
    } else {
      const index = e.target.value.indexOf('All')
      if (index > -1) e.target.value.splice(index, 1)
      setSelectedFile(e.target.value)
    }
  }

  const onSearchHandler = (e) => {
    setSearch(e.target.value)
  }

  const onResetHandler = () => {
    setSearch('')
  }

  return (
    <>
      <Grid className={classes.filter} container justify="space-between">
        <Grid item className={classes.gridCenter}>
          <TextField
            variant="outlined"
            placeholder="Search CDM fields and Description"
            classes={{ root: classes.inputSearch }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={onSearchHandler}
          />
          <IconButton
            onClick={onResetHandler}
            className={classes.refreshButton}
          >
            <RefreshIcon
              style={{
                transform: 'rotate(270deg)',
              }}
            />
          </IconButton>
        </Grid>
        <Grid item className={classes.gridCenter}>
          <div className={classes.mr20}>
            <Checkbox
              value="mf"
              onChange={(e) => setMandatory(e.target.checked)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              className={classes.customCheckbox}
            />
            Mandatory Fields
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            multiple={true}
            value={selectedFile.length === optionsList.length ? ['All'] : selectedFile}
            onChange={onChangeHandler}
            className={classes.customSelect}
            renderValue={(value: Array<string>) => value.join(', ')}
          >
            {optionsList.map((option, i) => (
              <MenuItem value={option} key={i}>
                <Checkbox checked={selectedFile.indexOf(option) > -1} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      {/* Table Component */}
      <TableComponent
        search={search}
        mandatory={mandatory}
        files={selectedFile}
      />
    </>
  )
}

export default FilterComponent
