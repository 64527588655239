export const dateMaskValidation = (userInput: string) => {
  let valid = true

  // check for valid letters
  const regex = /^[y|M|d|T|H|m|s|ms|X|,|.|\/|\-|:|;| ]$/
  for (let i = 0; i < userInput.length; i++) {
    valid = regex.test(userInput[i])
    if (!valid) return valid
  }

  // check for jumbled letters
  for (let i = 0; i < userInput.length - 2; i++) {
    if (
      userInput[i] === userInput[i + 2] &&
      userInput[i] !== userInput[i + 1]
    ) {
      valid = false
      if (!valid) return valid
    }
  }

  // check for valid occurrence for letters
  const msCondition: number =
    (userInput.match(/ms/g) || []).length === 1 ? 3 : 2
  valid =
    ((userInput.match(/y/g) || []).length === 0 ||
      (userInput.match(/y/g) || []).length === 2 ||
      (userInput.match(/y/g) || []).length === 4) &&
    (userInput.match(/M/g) || []).length <= 3 &&
    (userInput.match(/d/g) || []).length <= 2 &&
    (userInput.match(/T/g) || []).length <= 1 &&
    (userInput.match(/H/g) || []).length <= 2 &&
    (userInput.match(/m/g) || []).length <= msCondition &&
    (userInput.match(/s/g) || []).length <= msCondition &&
    (userInput.match(/ms/g) || []).length <= 1 &&
    ((userInput.match(/X/g) || []).length === 0 ||
      (userInput.match(/X/g) || []).length === 3)
  return valid
}

export const dateValidation = (userInput: string) => {
  let valid = true

  // check for valid letters
  const regex = /^[y|M|d|,|.|\/|\-|:|;| ]$/
  for (let i = 0; i < userInput.length; i++) {
    valid = regex.test(userInput[i])
    if (!valid) return valid
  }

  // check for jumbled letters
  for (let i = 0; i < userInput.length - 2; i++) {
    if (
      userInput[i] === userInput[i + 2] &&
      userInput[i] !== userInput[i + 1]
    ) {
      valid = false
      if (!valid) return valid
    }
  }

  // check for valid occurrence for letters
  const msCondition: number =
    (userInput.match(/ms/g) || []).length === 1 ? 3 : 2
  valid =
    ((userInput.match(/y/g) || []).length === 0 ||
      (userInput.match(/y/g) || []).length === 2 ||
      (userInput.match(/y/g) || []).length === 4) &&
    (userInput.match(/M/g) || []).length <= 3 &&
    (userInput.match(/d/g) || []).length <= 2
  return valid
}

export const timeMaskValidation = (userInput: string) => {
  let valid = true

  // check for valid letters
  const regex = /^[H|m|s|ms|X|,|.|\/|\-|:|;| ]$/
  for (let i = 0; i < userInput.length; i++) {
    valid = regex.test(userInput[i])
    if (!valid) return valid
  }

  // check for jumbled letters
  for (let i = 0; i < userInput.length - 2; i++) {
    if (
      userInput[i] === userInput[i + 2] &&
      userInput[i] !== userInput[i + 1]
    ) {
      valid = false
      if (!valid) return valid
    }
  }

  // check for valid occurrence for letters
  const msCondition: number =
    (userInput.match(/ms/g) || []).length === 1 ? 3 : 2
  valid =
    (userInput.match(/H/g) || []).length <= 2 &&
    (userInput.match(/m/g) || []).length <= msCondition &&
    (userInput.match(/s/g) || []).length <= msCondition &&
    (userInput.match(/ms/g) || []).length <= 1 &&
    ((userInput.match(/X/g) || []).length === 0 ||
      (userInput.match(/X/g) || []).length === 3)
  return valid
}
