import React, { ReactElement } from 'react'
import {
  Dialog as MuiDialog,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  Theme,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconClose } from './assets/IconClose.svg'

import PropTypes from 'prop-types'

type StyleProps = {
  height: string
  width: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    customDialog: {
      '& .MuiDialog-paper': {
        height: (props) => props.height,
        width: (props) => props.width,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
        borderRadius: '10px',
      },
      '& .dialogHeader': {
        display: 'flex',
        width: '100%',
        justifyContent: 'stretch',
        alignItems: 'center',
        '& .dialogHeaderCloseBtn': {},
        '& .dialogHeaderMain': {
          width: '95%',
        },
        '& .dialog-header': {
          font: 'normal normal bold 26px/40px Arial',
          letterSpacing: '0.26px',
          color: '#44697D',
          opacity: 1,
        },
      },
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      padding: '8px 8px 8px 0px',
      minWidth: '100%',
      height: 'auto',
      flex: 'none',
      '& .dialog-title': {
        // margin: '30px auto 20px auto',
        font: 'normal normal bold 26px/40px Arial',
        letterSpacing: '0.26px',
        color: '#44697D',
        opacity: 1,
      },
      '& .dialog-subtitle': {
        marginTop: '12px',
        font: 'normal normal bold 22px/26px Arial',
        letterSpacing: '0.22px',
        color: '#DE4D4D',
      },
    },
    actions: {
      width: '100%',
      // padding: '0 30px 30px 0',
      '& button': {
        textTransform: 'none',
        borderRadius: '5px',
        height: '44px',
        color: '#fff',
      },
    },
    noBtn: {
      minWidth: 'unset',
      width: '132px',
      height: '44px',
      background: '#89A0AC 0% 0% no-repeat padding-box',
      '&.MuiButton-contained:hover': {
        background: '#89A0AC 0% 0% no-repeat padding-box',
      },
    },
    yesBtn: {
      minWidth: 'unset',
      width: '132px',
      height: '44px',
      background: '#00B2A9 0% 0% no-repeat padding-box',
      '&.MuiButton-contained:hover': {
        background: '#00B2A9 0% 0% no-repeat padding-box',
      },
    },
  }
})
export interface DialogComponentProps {
  header?: string
  title?: string
  subtitle?: string
  cancelText?: string
  confirmText?: string
  open: boolean
  children?: ReactElement | null
  height?: string
  width?: string
  onClose: (isOpen: boolean) => void
  disabledApply?: boolean
}
export const Dialog = ({
  header = '',
  title = '',
  subtitle = '',
  cancelText = 'Cancel',
  confirmText = 'Submit',
  open = false,
  onClose,
  children = null,
  height = '430px',
  width = '1020px',
  disabledApply = false,
}: DialogComponentProps) => {
  const classes = useStyles({ width, height })
  return (
    <MuiDialog open={open} maxWidth={false} className={classes['customDialog']}>
      <Grid container>
        <Grid item className="dialogHeader">
          <Grid className="dialogHeaderMain">
            {header && (
              <Typography className="dialog-header">{header}</Typography>
            )}
          </Grid>
          <Grid className="dialogHeaderCloseBtn">
            <IconClose
              style={{ cursor: 'pointer' }}
              onClick={() => onClose(false)}
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogContent className={classes['dialogContent']}>
        {title && <Typography className="dialog-title">{title}</Typography>}
        {subtitle && (
          <Typography className="dialog-subtitle">{subtitle}</Typography>
        )}
        {children}
      </DialogContent>
      <DialogActions className={classes['actions']}>
        {cancelText && (
          <Button
            variant="contained"
            color="secondary"
            className={classes['noBtn']}
            onClick={() => onClose(false)}
          >
            {cancelText}
          </Button>
        )}
        <Button
          variant="contained"
          color="default"
          className={classes['yesBtn']}
          disabled={disabledApply}
          onClick={() => onClose(true)}
          data-testid="confirm-btn"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

Dialog.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element,
  width: PropTypes.string,
  height: PropTypes.string,
}

Dialog.defaultProps = {
  header: '',
  title: '',
  subtitle: '',
  cancelText: '',
  confirmText: 'Submit',
  open: false,
  children: null,
  height: '430px',
  width: '1020px',
}

export default Dialog
