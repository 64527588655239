import React, { useState } from 'react'
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles'
import { AccordionDetails, Typography } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},
    header: {
      padding: '22px 26px',
      background: '#f2f2f2',
      border: '1px solid #44697d',
    },
    headerTitle: {
      color: '#22353F',
      fontSize: '21px',
      fontWeight: 'bold',
    },
    summary: {
      position: 'relative',
      border: '1px solid #44697d',
      borderTop: 'none',
      padding: '0px 40px',
    },
    chevronIcon: {
      position: 'absolute',
      right: '20px',
      top: '7px',
      height: '35px',
      width: 'auto',
    },
    details: {
      background: '#22353F',
      color: '#fff',
      padding: '20px',
    },
  })
)

const Accordion = withStyles({
  root: {
    '&.Mui-expanded': {
      margin: '0',
    },
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  expanded: {
    '& svg': {
      top: '15px',
      fill: '#fa5622',
    },
  },
})(MuiAccordionSummary)

type Faq = {
  question: string
  answer: string
}

interface Props {
  headerTitle: string
  faqs: Faq[]
  style?: React.CSSProperties
}

export function FAQAccordion({ headerTitle, faqs, style }: Props) {
  const classes = useStyles()

  const [openPanel, setOpenPanel] = useState(null)

  const handleChange = (index: number) => () => {
    const nextValue = openPanel === index ? null : index
    setOpenPanel(nextValue)
  }

  return (
    <div className={classes.container} style={style}>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>{headerTitle}</Typography>
      </div>
      {faqs &&
        faqs.map((faq, index) => (
          <Accordion
            square
            expanded={openPanel === index}
            onChange={handleChange(index)}
            key={faq.question}
          >
            <AccordionSummary className={classes.summary}>
              <Typography>{ReactHtmlParser(faq.question)}</Typography>
              {openPanel === index ? (
                <ChevronUpIcon className={classes.chevronIcon} />
              ) : (
                <ChevronDownIcon className={classes.chevronIcon} />
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography>{ReactHtmlParser(faq.answer)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}
