import React from 'react'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    navLink: {
      textDecoration: 'none',
    },
    container: {
      border: '4px solid #44697d',
      borderRadius: '10px',
      padding: '20px',
      cursor: 'pointer',
      '&.disabled': {
        border: '4px solid #CCCCCC',
        cursor: 'default',
        '& button': {
          background: '#CCCCCC',
          cursor: 'default',
        },
      },
    },
    top: {
      display: 'flex',
    },
    title: {
      color: '#00B2A9',
      fontWeight: 'bold',
      fontSize: '17px',
    },
    subtitle: {
      color: 'initial',
    },
    iconWrapper: {
      marginRight: '20px',
      '& > svg': {
        height: '80px',
        width: '85px',
      },
    },
    buttonContainer: {
      width: '100%',
      textAlign: 'right',
    },
    button: {
      transition: 'border 250ms, background 250ms',
      background: '#45647E',
      borderRadius: '12px',
      borderStyle: 'none',
      color: '#fff',
      fontSize: '17px',
      fontWeight: 'bold',
      cursor: 'pointer',
      width: '190px',
      height: '40px',
    },
  })
)

interface Props {
  icon: JSX.Element
  style?: React.CSSProperties
  title: string
  body: string
  url: string
  disabled?: boolean
}

function GetInTouchComponent({ icon, title, body, disabled }: Partial<Props>) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, disabled ? 'disabled' : '')}>
      <div className={classes.top}>
        <div className={classes.iconWrapper}>{icon}</div>
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle}>{body}</Typography>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.button}>Get in Touch</button>
      </div>
    </div>
  )
}

export const GetInTouch = (props: Props) => {
  const classes = useStyles()
  return (
    <>
      {props.disabled ? (
        <div style={props.style}>
          <GetInTouchComponent {...props} />
        </div>
      ) : (
        <NavLink to={props.url} className={classes.navLink} style={props.style}>
          <GetInTouchComponent {...props} />
        </NavLink>
      )}
    </>
  )
}
