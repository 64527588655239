import React from 'react'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import cross from '../../../../integration-engine-custom-mapper-api/app/assets/Ic_cross_red_small.png'
import check from '../../../../integration-engine-custom-mapper-api/app/assets/ic_right_green.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    countContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
    },
    icon: {
      height: '25px',
      width: '25px',
    },
    secondaryAction: {
      top: '43% !important',
    },

    listItemStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0',
    },

    primaryTextStyle: {
      fontWeight: 'bold',
      fontSize: '1rem',
      // marginBottom: count === 0 ? '0.35em' : 0,
    },

    secondaryTextStyle: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.875rem',
    },

    countContainerStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
    },

    iconStyle: {
      height: '25px',
      width: '25px',
    },
  })
)

export interface ProfileScoreListItemProps {
  heading: string
  count: number
}

const ProfileScoreListItem = ({
  heading,
  count,
}: ProfileScoreListItemProps) => {
  const classes = useStyles()

  return (
    <div className={classes.listItemStyle}>
      <div>
        <Typography variant="h6">{heading}</Typography>
        {count === 0 && (
          <div
            className={classes.secondaryTextStyle}
          >{`${heading} does not exist`}</div>
        )}
      </div>
      <div className={classes.countContainerStyle}>
        <span>
          Count Of {heading}: {count}
        </span>
        <img
          className={classes.iconStyle}
          src={count > 0 ? check : cross}
          alt=""
        />
      </div>
    </div>
  )
}

export default ProfileScoreListItem
