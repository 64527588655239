import React, { Fragment, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import BounceLoader from 'react-spinners/BounceLoader'
import {
  addCdmEntitySystemName,
  clearValidation,
  clearAllFileMappings,
  useIEDispatch,
  useIESelector,
  clearManualSelections,
  setCustomFileMappings,
  clearRemappingFile,
  clearAllCdmEntitySystemName,
  resetUniqueIds,
  UPLOAD_STATE,
} from '@engine-b/integration-engine/data/state/redux'
import { HELPER_TYPE } from '@engine-b/integration-engine/data/helper-text'
import {
  UploadManager,
  DragDropUploadManager,
} from '@engine-b/integration-engine/features/file-to-cdm-entity'
import { Description } from '@engine-b/integration-engine/ui/form-components'
import { CdmEntity } from '@engine-b/shared/types'
import { EXTRACT_TYPE_LABELS } from './constants'
import { Step } from './Step'
import { TraverseButtons } from './TraverseButtons'
import { trackPageView } from '../../../services/AppInsights'
export interface StepTwoProps {
  handleNext(): void
  handleBack(): void
  source: Object
}

const useStyles = makeStyles((theme) =>
  createStyles({
    StepTwo: {
      color: theme.palette.primary.dark,
      '& h1': {
        width: '100%',
      },
    },
    sideBySide: {
      display: 'flex',
      flexDirection: 'row',
    },
    scheduleDataIngestion: {
      width: '25%',
      '& button': {
        backgroundColor: theme.palette.primary.dark,
        '& hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        padding: '10px 20px',
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    UploadFileBlock: {
      '& input[type="file"]': {
        backgroundColor: theme.palette.primary.light,
        '& hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '& label': {
          content: 'Select some files',
          backgroundColor: theme.palette.primary.light,
        },
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        padding: '10px 20px',
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    fileListRender: {
      listStyleType: 'none',
      marginLeft: '-25px',
      '& li': {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 0',
        '& span': {
          margin: '4px 2px',
        },
      },
    },
    uploadMessage: {
      marginTop: '12px',
    },
    InfoWrapper: {
      paddingTop: '32px',
      marginBottom: '12px',
      rowGap: '26px',
    },
    previewButton: {
      width: '160px',
      height: '44px',
      '& span': {
        font: 'normal normal bold 16px/24px Arial',
      },
    },
  })
)

export function StepTwo(props: StepTwoProps) {
  const classes = useStyles()
  const { handleNext, handleBack, source } = props
  const draftRun = useIESelector((state) => state.runs.draftRun)
  const erps = useIESelector((state) => state.erps)
  const fileMappings = useIESelector((state) => state.fileMappings)
  const customMapper = useIESelector((state) => state.customMapper)
  const dispatch = useIEDispatch()

  useEffect(() => {
    trackPageView({ name: 'Add Data: Step Two' })
  }, [])

  const handleAddCdmEntity = (systemName: CdmEntity['systemName']) => {
    dispatch(addCdmEntitySystemName(systemName))
  }

  const handleBackClick = () => {
    dispatch(clearValidation({}))
    dispatch(clearAllFileMappings())
    dispatch(clearManualSelections())
    dispatch(setCustomFileMappings({}))
    dispatch(clearRemappingFile())
    dispatch(clearAllCdmEntitySystemName())
    dispatch(resetUniqueIds())
    handleBack()
  }

  const handleNextClick = () => {
    !isReviewButtonEnabled(customMapper, fileMappings) && handleNext()
  }

  const isValid = customMapper.files.reduce(
    (valid, item) => valid && !item.error,
    true
  )

  const isReviewButtonEnabled = (customMapper, fileMappings) => {
    return !(
      isValid &&
      customMapper.files.length &&
      customMapper.files.length ===
        (typeof fileMappings === 'object' &&
          Object.keys(fileMappings).length) &&
      typeof fileMappings === 'object' &&
      !!!Object.values(fileMappings).find(
        (item: any) => item.state === UPLOAD_STATE.UNSUPPORTED_FILE_TYPE
      ) &&
      !!!Object.values(fileMappings).find(
        (item: any) => item.state === UPLOAD_STATE.NOT_STARTED
      ) &&
      !!!Object.values(fileMappings).find(
        (item: any) => item.state === UPLOAD_STATE.IN_PROGRESS
      ) &&
      !!!Object.values(fileMappings).find(
        (item: any) => item.state === UPLOAD_STATE.VALIDATING_FILE
      )
    )
  }

  return (
    <Step
      content={
        <Fragment>
          <Typography
            className="step-title"
            variant={'h5'}
            color={'textPrimary'}
          >
            Step 2: Select Data for Ingestion
          </Typography>
          <Grid
            container
            direction={'column'}
            spacing={2}
            className={classes.InfoWrapper}
          >
            <Grid item xs={6}>
              <Description
                inputLabelId={'erpLabel'}
                title={'Client System Name'}
                description={
                  'Selected from which client system the data was exported:'
                }
                helperType={HELPER_TYPE.CLIENT_SYSTEM}
              >
                <Typography variant={'body1'}>
                  {erps && erps[draftRun.erpId]?.name}
                </Typography>
              </Description>
            </Grid>
            <Grid item xs={6}>
              <Description
                inputLabelId={'extractTypeLabel'}
                title={'Data Extract Type'}
                description={'Selected export format of the data files:'}
                helperType={HELPER_TYPE.DATA_EXTRACT_TYPE}
              >
                <Typography variant={'body1'}>
                  {EXTRACT_TYPE_LABELS[draftRun.extractType]}
                </Typography>
              </Description>
            </Grid>
            {draftRun.erpId && draftRun.extractType ? (
              <Grid item xs={12}>
                {process.env.NX_ENABLE_50_GB_FILE_UPLOAD === 'true' ? (
                  <DragDropUploadManager
                    erpId={draftRun.erpId}
                    extractType={draftRun.extractType}
                    handleAddCdmEntity={handleAddCdmEntity}
                    source={source}
                  />
                ) : (
                  <UploadManager
                    erpId={draftRun.erpId}
                    extractType={draftRun.extractType}
                    handleAddCdmEntity={handleAddCdmEntity}
                    source={source}
                  />
                )}
              </Grid>
            ) : (
              <BounceLoader color={'#00A9AB'} size={20} />
            )}
          </Grid>
        </Fragment>
      }
      footer={
        <TraverseButtons
          handleBack={handleBackClick}
          isBackDisabled={false}
          handleNext={handleNextClick}
          isNextDisabled={isReviewButtonEnabled(customMapper, fileMappings)}
        />
      }
    />
  )
}
