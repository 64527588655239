import React, { useEffect, useState } from 'react'
import { Box, Card, makeStyles, Tab, Tabs } from '@material-ui/core'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
import ExtractTypeTab from './ExtractTypeTab'

const useStyles = makeStyles((theme) => ({
  eBanner: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #44697D33',
    borderRadius: '10px',
    height: '100%',
    '& > .custom__erp__tabs': {
      borderBottom: '2px solid #44697D33;',
      '& .MuiTab-root': {
        textTransform: 'capitalize',
      },
      '& span.MuiTab-wrapper': {
        font: 'normal normal bold 18px/21px Arial',
        letterSpacing: '0.18px',
        color: '#44697D',
      },
      '& .Mui-selected': {
        background: '#F8FAFB 0% 0% no-repeat padding-box',
        '& > span.MuiTab-wrapper': {
          color: '#00B2A9',
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    '& > .report__extract_tab': {
      '& .re__info': {
        display: 'flex',
        padding: '16px 30px',
        '& > svg': {
          width: '26px',
          height: '26px',
        },
        '& > div': {
          marginLeft: '15px',
        },
      },
      '& .entity__select_tab': {
        display: 'flex',
        width: '100%',
        borderTop: '2px solid #44697D33;',
        borderBottom: '2px solid #44697D33;',
        [theme.breakpoints.down('lg')]: { flexDirection: 'column' },

        '& > .left': {
          width: '40%',
          [theme.breakpoints.down('lg')]: { width: '100%' },
          display: 'flex',
          alignItems: 'center',
          padding: '15px 30px',
          whiteSpace: 'nowrap',
          '& > label': {
            font: 'normal normal bold 16px/18px Arial',
            letterSpacing: '0.16px',
            color: '#44697D',
            marginRight: '15px',
          },
        },
        '& > .right': {
          display: 'flex',
          alignItems: 'center',
          padding: '0 20px',
          width: '60%',
          [theme.breakpoints.down('lg')]: { width: '100%' },
          background: '#F8FAFB 0% 0% no-repeat padding-box',
          '& > .entity__tabs': {
            '& .MuiTab-wrapper': {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '19px',
              '& > span': {
                textTransform: 'none',
                padding: '26px 19px',
                font: 'normal normal bold 16px/20px Arial',
                letterSpacing: '0.16px',
                color: '#44697D',
              },
            },
            '& .MuiTab-root': {
              padding: 0,
            },
          },
        },
      },
      [theme.breakpoints.down('lg')]: {},
    },
  },
}))

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const ERPInformation = () => {
  const { eBanner } = useStyles()

  const {
    entities,
    client_system: { report, system },
    client_system,
  } = useIESelector((state) => state.customERP)

  const [value, setValue] = useState<number>(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setTimeout(() => {
      setValue(0)
    }, 0)
  }, [report, system])

  return (
    <Card className={eBanner}>
      <Tabs
        value={value}
        onChange={handleChange}
        className="custom__erp__tabs"
        aria-label="Extract Types Tabs"
      >
        {report && <Tab label="Report Extract" />}
        {system && <Tab label="System Extract" />}
      </Tabs>
      {Object.keys(client_system)
        .filter(
          (cs) => typeof client_system[cs] === 'boolean' && client_system[cs]
        )
        .map((extractType, index) => {
          if (extractType !== 'systemName' && client_system[extractType]) {
            return (
              <TabPanel
                className="report__extract_tab"
                value={value}
                index={index}
                key={index}
              >
                <ExtractTypeTab
                  extractType={extractType}
                  TabPanel={TabPanel}
                  entity={entities[extractType]}
                />
              </TabPanel>
            )
          }
        })}
    </Card>
  )
}

export default ERPInformation
