import { ApolloClient } from '@apollo/client'
import { GET_ERPS } from '@engine-b/integration-engine/data/data-ingestion-api'
import { Erp } from '@engine-b/shared/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type ErpCollection = Record<string, Erp>

const INITIAL_STATE: ErpCollection = {}

export const getErps = createAsyncThunk(
  'getErps',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_ERPS,
    })
    return response
  }
)

export const erpsSlice = createErpsSlice(INITIAL_STATE)

export function createErpsSlice(initialState: ErpCollection) {
  return createSlice({
    name: 'erps',
    initialState,
    reducers: {
      clearErps: () => {
        return INITIAL_STATE
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getErps.fulfilled, (state, action) => {
        const { erps } = action.payload.data
        erps.forEach((erp) => {
          state[erp.id] = erp
        })
        return state
      })

      builder.addCase(getErps.rejected, (state) => {
        state = null
      })
    },
  })
}

export const { clearErps } = erpsSlice.actions

export const erpsReducer = erpsSlice.reducer
