import { useApolloClient } from '@apollo/client'
import { useMsal } from '@azure/msal-react'
import { Button, makeStyles } from '@material-ui/core'
import { ReactComponent as LogoutIcon } from './log-out.svg'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  logout: {
    width: '130px',
    height: '40px',
    background: '#D9F4F2 0% 0% no-repeat padding-box',
    borderRadius: '5px',
    textAlign: 'center',
    font: 'normal normal normal 16px/18px Arial',
    letterSpacing: '0px',
    color: '#00B2A9',
    border: 'none',
    marginRight: '20px',
    padding: '13px 27px 11px',
    '& span > svg': {
      marginRight: '12px',
    },
    '&:hover': {
      background: '#00b2a9 0% 0% no-repeat padding-box',
      textAlign: 'center',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0px',
      color: '#FFFFFF',
      '& span > svg path,line': {
        stroke: '#FFFFFF',
      },
    },
  },
}))

export const SignOutButton = () => {
  const { instance } = useMsal()
  const apolloClient = useApolloClient()

  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      className={classes.logout}
      size="small"
      onClick={() => {
        apolloClient.resetStore()
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        })
      }}
    >
      <LogoutIcon />
      Logout
    </Button>
  )
}
