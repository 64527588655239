import * as localizations from './lib/localizations'

export const appStrings = {
  'en-US': localizations.enUS,
  'en-GB': localizations.en,
}

export * from './lib/components/LocalizedRouter'
export * from './lib/components/LocalizedSwitch'

export * from './lib/constants'
export * from './lib/utils'
