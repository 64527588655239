import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReactComponent as IconClose } from './assets/Close.svg'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
import { enGB, enUS } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 'unset',
    width: '880px',
    height: '655px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customInput: {
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
    },
  },
}))

export interface SavedPipelinesListProps {
  open: boolean
  onClose: (flag: boolean, pipelineName: string) => void
}

const SavedPipelinesList = (props: SavedPipelinesListProps) => {
  const { open, onClose } = props
  const { paper, title, customInput } = useStyles()
  const { locale } = useIntl()

  const [selectedFile, setSelectedFile] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [filteredPipelines, setFilteredPipelines] = useState([])
  const { loader, savedPipelines } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )

  const columns: GridColDef[] = [
    {
      field: 'filename',
      headerName: 'Pipeline Name',
      filterable: false,
      hideable: false,
      width: 200,
    },
    {
      field: 'created',
      headerName: 'Created At',
      filterable: false,
      hideable: false,
      width: 200,
      valueGetter: (params) => {
        if (params.value) {
          return format(parseISO(params.value), 'P, h:mm:ss a', {
            locale: locale === 'en-US' ? enUS : enGB,
          })
        } else return null
      },
    },
  ]

  const onRowClickHandler = (params: GridRowParams) => {
    setSelectedFile(params.row.filename?.split('.json')[0])
  }

  useEffect(() => {
    const records = savedPipelines.filter(
      (item: any) =>
        item.filename.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    )
    setFilteredPipelines(records)
  }, [searchValue, savedPipelines])

  useEffect(() => {
    setSelectedFile('')
    setSearchValue('')
  }, [open])

  return (
    <Dialog open={open} classes={{ paper }}>
      <DialogTitle>
        <Box className={title}>
          <Typography variant="h5">Saved Pipelines</Typography>
          <IconButton onClick={() => onClose(false, '')}>
            <IconClose />
          </IconButton>
        </Box>
        <Grid container style={{ margin: '1rem 0' }}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              className={customInput}
              placeholder="Search Pipeline Name..."
              variant="outlined"
              color="secondary"
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          autoHeight={true}
          rows={filteredPipelines}
          columns={columns}
          loading={loader}
          onRowClick={onRowClickHandler}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
        />
      </DialogContent>
      <DialogActions style={{ padding: '24px 24px' }}>
        <Button
          disabled={selectedFile?.length === 0}
          variant="contained"
          color="secondary"
          style={{ height: '48px' }}
          onClick={() => onClose(true, selectedFile)}
        >
          Load
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SavedPipelinesList
