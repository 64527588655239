import { 
    extractionList,
} from "./typedefs";
import { INIT_EXTRACTIONS_STATE } from "./state";
import {
    createSlice,
    SliceCaseReducers,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import { ApolloClient } from '@apollo/client'
import { 
    GET_EXTRACTIONS, 
    GET_CONNECTIONS, 
    GET_CONNECTIONS_BY_CLIENT,
    GET_PRE_AUTH_OPTIONS,
    GET_ERPS_TYPES,
    GET_ERPS_ENTITIES,
    CREATE_ERP_CONNECTION,
    UPDATE_ERP_CONNECTION,
    DELETE_ERP_EXTRACTION,
    CREATE_ERP_EXTRACTION,
    DELETE_ERP_CONNECTION,
    GET_POST_AUTH_OPTIONS,
    UPDATE_POST_AUTH_OPTIONS,
    COMPLETE_ERP_CONNECTION_AUTHORISATION,
    GET_CONNECTION_AUTH_LINK_ID,
    GET_CONNECTION_PRE_AUTH_CONFIG,
    GET_NANGO_INTEGRATION_NAME
} from '@engine-b/integration-engine/data/data-ingestion-api'

export const extractionsStateSlice = createExtractionsStateSlice(INIT_EXTRACTIONS_STATE)

export const getExtractions = createAsyncThunk(
    'getExtractions',
    async (payload: {client: ApolloClient<unknown>}, {rejectWithValue}) => {
        try {
            const response = await payload.client.query({
                query: GET_EXTRACTIONS,
                fetchPolicy: 'no-cache',
            })
            return response
        } catch(err) {
            return rejectWithValue(err.message)
        }
  }
)

export const getERPConnections = createAsyncThunk(
    'getERPConnections',
    async (payload: {client: ApolloClient<unknown>}, {rejectWithValue}) => {
        try {
            const response = await payload.client.query({
                query: GET_CONNECTIONS,
            })
            return response
        } catch(err) {
            return rejectWithValue(err.message)
        }
    }
)

export const getClientConnections = createAsyncThunk(
    'getClientConnections',
      async (
        payload: {
            client: ApolloClient<unknown>; 
            engagement: string
        }, 
        { rejectWithValue }
        ) => { 
        try {
            const response = await payload.client.query({
                query: GET_CONNECTIONS_BY_CLIENT,
                variables: {
                    engagementId: payload.engagement,
                },
                fetchPolicy: 'no-cache',
            })
            const extractions = await payload.client.query({
                query: GET_EXTRACTIONS,
            })
           return {response, extractions}
        } catch(err) {
            return rejectWithValue(err.message)
        }
    }
)

export const getPreAuthOptions = createAsyncThunk(
    'getPreAuthOptions',
      async (payload: {client: ApolloClient<unknown>; connectionERP: string}, {rejectWithValue}) => { 
        try {
            const response = await payload.client.query({
                query: GET_PRE_AUTH_OPTIONS,
                variables: {
                    system: payload.connectionERP,
                },
            })
           return response
        } catch(err) {
            return rejectWithValue(err.message)
        }
    }
)

export const getConnectionPreAuthOptions = createAsyncThunk(
    'getConnectionPreAuthOptions',
    async (payload: {client: ApolloClient<unknown>; connectionERP: string}, {rejectWithValue}) => { 
      try {
          const response = await payload.client.query({
              query: GET_CONNECTION_PRE_AUTH_CONFIG,
              variables: {
                  system: payload.connectionERP,
              },
          })
         return response
      } catch(err) {
          return rejectWithValue(err.message)
      }
  }
)

export const createERPConnection = createAsyncThunk(
    'createERPconnection',
    async (
        payload: { client: ApolloClient<unknown>; variables: any },
        { rejectWithValue }
    ) => {
        const { data, errors } = await payload.client.mutate({
            mutation: CREATE_ERP_CONNECTION,
            variables: payload.variables,
        })

        if (errors) {
            return rejectWithValue(errors)
        }

        return data
    }
)

export const updateERPConnection = createAsyncThunk(
    'updateERPconnection',
    async (
        payload: { client: ApolloClient<unknown>; variables: any },
        { rejectWithValue }
    ) => {
        const formData = {
            id: payload.variables.id,
            ...payload.variables
        }
      
        const { data, errors } = await payload.client.mutate({
            mutation: UPDATE_ERP_CONNECTION,
            variables: formData,
        })

        if (errors) {
            return rejectWithValue(errors)
        }

        return data
    }
)

export const createERPExtraction = createAsyncThunk(
    'createERPextraction',
    async (
        payload: { client: ApolloClient<unknown>; variables: any },
        { rejectWithValue }
    ) => {
        const { data, errors } = await payload.client.mutate({
            mutation: CREATE_ERP_EXTRACTION,
            variables: payload.variables,
        })

        if (errors) {
            return rejectWithValue(errors)
        }

        return data
    }
)

export const deleteERPExtraction = createAsyncThunk(
    'deleteERPextraction',
    async (
        payload: { client: ApolloClient<unknown>; variables: any },
        { rejectWithValue }
    ) => {
        await payload.client.mutate({
            mutation: DELETE_ERP_EXTRACTION,
            variables: {id: payload.variables.id},
        }).then(async ({data, errors}) => {
            if (errors) {
                return rejectWithValue(errors)
            }
        })
    }
)

export const deleteERPConnection = createAsyncThunk(
    'deleteERPConnection',
    async (
        payload: { client: ApolloClient<unknown>; variables: any },
        { rejectWithValue }
    ) => {
        await payload.client.mutate({
            mutation: DELETE_ERP_CONNECTION,
            variables: {id: payload.variables.id},
        }).then(async ({data, errors}) => {
            if (errors) {
                return rejectWithValue(errors)
            }
        })
    }
)

export const getERPtypes = createAsyncThunk(
    'getERPtypes',
      async (payload: { client: ApolloClient<unknown> }) => {
          const response = await payload.client.query({
              query: GET_ERPS_TYPES,
          })

        return response
    }
)

export const getPostAuthOptions = createAsyncThunk(
    'getPostAuthOptions', 
    async (payload: {client: ApolloClient<unknown>, connectionId: string }, {rejectWithValue}) => {
        try {
            const response = await payload.client.query({
                query: GET_POST_AUTH_OPTIONS,
                variables: {
                    erpConnectionId: payload.connectionId
                }
            })
            return response
        } catch(err) {
            return rejectWithValue(err.message)
        }
    }
)

export const updatePostAuthOptions = createAsyncThunk(
    'updatePostAuthOptions', 
    async (payload: {client: ApolloClient<unknown>, id: String, configData: any }) => {
        const response = await payload.client.mutate({
            mutation: UPDATE_POST_AUTH_OPTIONS,
            variables: {
                erpConnectionId: payload.id,
                configData: payload.configData
            }
        })
        return response
    }
)

export const getConnectionWorkflowData = createAsyncThunk(
    'getConnectionWorkflowData',
    async (payload : {client: ApolloClient<unknown>, authorisationId: string}) => {
        const response = await payload.client.query({
            query: GET_CONNECTION_AUTH_LINK_ID,
            variables: {authorisationId: payload.authorisationId},
        })
        return response
    }
)

export const updateConnectionWorkflowAuth = createAsyncThunk(
    'updateConnectionWorkflowAuth',
    async (payload : 
        {client: ApolloClient<unknown>, connectionId: string}, 
        { rejectWithValue }
    ) => {
        const { data, errors }  = await payload.client.mutate({
            mutation: COMPLETE_ERP_CONNECTION_AUTHORISATION,
            variables: {erpConnectionId: payload.connectionId},
        })

        if (errors) {
            return rejectWithValue(errors)
        }
        return data
    }
)

export const getErpEntities = createAsyncThunk(
    'getErpEntities',
      async (payload: { client: ApolloClient<unknown>, system: string }) => {
            const response = await payload.client.query({
                query: GET_ERPS_ENTITIES,
                variables: {
                    system: payload.system
                }
            })
        return response
    }
)

export const getNangoIntegrationName = createAsyncThunk(
    'getNangoIntegrationName',
    async (payload: { client: ApolloClient<unknown>, system: string, connectionId: string}) => {
        const response = await payload.client.query({
            query: GET_NANGO_INTEGRATION_NAME,
            variables: {
                system: payload.system,
                connectionWorkflowId: payload.connectionId
            }
        })
        return response
    }
)

export function createExtractionsStateSlice(initialState) {
    return createSlice<extractionList, SliceCaseReducers<extractionList>>({
        name: 'extractions',
        initialState,
        reducers: {
          setExtractionDetails: (state, { payload: { key, value } }) => {
            state[key] = value
          },
          createERP: (state, { payload }) => {
            state.erpCreate = payload
          },
          createExtraction: (state, { payload }) => {
            state.extractionCreate = payload
          },
          resetCreationForms: (state, { payload }) => {
            state.erpCreate = {name: "", type: ""}
            state.extractionCreate = {name: "", connection: ""}
          },
          resetExtractionSettings: (state) => {
            state.preAuthOptions = {}
            state.erpEntities = []
          },
          resetConnections: (state) => {
            state['clientExtractions'] = []
          },
          resetExtractions: (state) => {
            state['clientConnections'] = []
          },
          resetExtractionState: (state) => {
            return INIT_EXTRACTIONS_STATE
          },
        },
        extraReducers: (builder) => {
            builder.addCase(getExtractions.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(getExtractions.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['loading'] = false;
                state['extractions'] = data.getErpExtractions
                const filteredExtractions = [...data.getErpExtractions.filter((extraction) => state['clientConnections'].some((connection) => connection.id === extraction.erpConnection.id))].reverse()
                const clientExtractions = [...state['clientExtractions'].filter((extraction) => filteredExtractions.some((fltrdExtraction) => fltrdExtraction.id === extraction.id))];
                if (clientExtractions.length > 0) {
                    filteredExtractions.forEach((receivedExtraction) => {
                        const index = clientExtractions.findIndex(ext => ext.id === receivedExtraction.id);
                        if (index !== -1) {
                            if (receivedExtraction.errorConfig === null) {
                                clientExtractions[index] = receivedExtraction
                            }
                        } else {
                            clientExtractions.push(receivedExtraction)
                        }
                    })
                    state['clientExtractions'] = clientExtractions.map((ext) => {
                        ext.deleteInProgress = false
                        return ext
                    })
                } else {
                    state['clientExtractions'] = filteredExtractions
                }
            })
            builder.addCase(getExtractions.rejected, (state, action) => {
                state['loading'] = false;
                state['extractions'] = []
                state['clientExtractions'] = []
                state['message'] = "An Error Has Occurred: Failed To Load Extractions"
            })

            builder.addCase(getERPConnections.pending, (state, action) => {
                state['loading'] = true;
                state['message'] = ""
                return state
            })
            builder.addCase(getERPConnections.fulfilled, (state, action) => {
                state['loading'] = false;
                state.erps = action?.payload?.data?.getErpConnections
                return state
            })
            builder.addCase(getERPConnections.rejected, (state, action) => {
                state['loading'] = false;
                state['message'] = "An Error Has Occurred: Failed To Load Connections"

                return state;
            })

            builder.addCase(getClientConnections.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(getClientConnections.fulfilled, (state, {payload}) => {
                const { response } = payload
                state['loading'] = false;
                const clientConnections = [...state['clientConnections'].filter((connection) => response.data.getErpConnections.some((rcvConnection) => rcvConnection.id === connection.id))]

                if (clientConnections.length > 0) {
                    response.data.getErpConnections.forEach((receivedConnection) => {
                        const index = clientConnections.findIndex(ext => ext.id === receivedConnection.id);
                        if (index !== -1) {
                            if (receivedConnection.errorConfig === null) {
                                clientConnections[index].state = receivedConnection.state
                                clientConnections[index].postAuthConfigValues = receivedConnection.postAuthConfigValues
                            }
                        } else {
                            clientConnections.push(receivedConnection)
                        }
                    })
                    state['clientConnections'] = clientConnections.map((con) => {
                        con.deleteInProgress = false
                        return con
                    })
                } else {
                    state['clientConnections'] = [...response.data.getErpConnections].reverse()
                }
            })
            builder.addCase(getClientConnections.rejected, (state, action) => {
                state['message'] = "An Error Has Occurred: Failed To Load Connections"
                state['loading'] = false;
                state['clientConnections'] = []
                return state;
            })

            builder.addCase(getPreAuthOptions.pending, (state, {payload}) => {
                state['loading'] = true;
            })
            builder.addCase(getPreAuthOptions.fulfilled, (state, {payload}) => {
                state['loading'] = false;
                const { data } = payload
                state['preAuthOptions'] = data?.getPreAuthOptions.preAuthConfigSchema;
            })
            builder.addCase(getPreAuthOptions.rejected, (state, {payload}) => {
                state['loading'] = false;
            })
            
            builder.addCase(getConnectionPreAuthOptions.pending, (state, {payload}) => {
                state['loading'] = true;
            })
            builder.addCase(getConnectionPreAuthOptions.fulfilled, (state, {payload}) => {
                state['loading'] = false;
                const { data } = payload
                state['connectionPreAuthOptions'] = data?.getConnectionPreAuthConfig.preAuthConfigSchema;
            })
            builder.addCase(getConnectionPreAuthOptions.rejected, (state, {payload}) => {
                state['loading'] = false;
            })
            

            builder.addCase(getERPtypes.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(getERPtypes.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['loading'] = false;
                state['erps_types'] = data.getErpIntegrations
            })
            builder.addCase(getERPtypes.rejected, (state) => {
                state['loading'] = false;
                return state;
            })

            builder.addCase(createERPConnection.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(createERPConnection.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['loading'] = false;
                return data;
            })
            builder.addCase(createERPConnection.rejected, (state) => {
                state['loading'] = false;
                return state;
            })

            builder.addCase(updateERPConnection.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(updateERPConnection.fulfilled, (state, {payload}) => {
                state['loading'] = false;
                const { data } = payload
                return data
            })
            builder.addCase(updateERPConnection.rejected, (state, action) => {
                state['loading'] = false;
                return state;
            })

            builder.addCase(createERPExtraction.pending, (state, {payload}) => {
                state['loading'] = true;
                state['extractionCreationInProgress'] = true;
                state['message'] = ""
            })
            builder.addCase(createERPExtraction.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['preAuthOptions'] = {}
                state['loading'] = false;
                state['extractionCreationInProgress'] = false;
                return data
            })
            builder.addCase(createERPExtraction.rejected, (state, action) => {
                state['preAuthOptions'] = {}
                state['loading'] = false;
                state['extractionCreationInProgress'] = false;
                return state;
            })

            builder.addCase(deleteERPExtraction.pending, (state, action) => {
                state['loading'] = true;
                const extractions = state['clientExtractions'].map((ext) => {
                    if(ext.id === action?.meta?.arg?.variables.id) {
                        ext.deleteInProgress = true
                    }
                    return ext
                })
                state['clientExtractions'] = extractions
                state['message'] = ""
            })
            builder.addCase(deleteERPExtraction.fulfilled, (state, {payload}) => {
                state['clientExtractions'] = state['clientExtractions'].filter((con) => con !== null)
                state['loading'] = false;
            })
            builder.addCase(deleteERPExtraction.rejected, (state, action) => {
            })

            builder.addCase(deleteERPConnection.pending, (state, action) => {
                state['loading'] = true;
                const connections = state['clientConnections'].map((con) => {
                    if(con.id === action?.meta?.arg?.variables.id) {
                        con.deleteInProgress = true
                    }
                    return con
                })
                state['clientConnections'] = connections
                state['message'] = ""
            })
            builder.addCase(deleteERPConnection.fulfilled, (state, {payload}) => {
                state['clientConnections'] = state['clientConnections'].filter((con) => con !== null)
                state['loading'] = false;
            })
            
            builder.addCase(getPostAuthOptions.pending, (state, {meta, payload}) => {
                state['loading'] = true;
                state['message'] = ""
            })
            builder.addCase(getPostAuthOptions.fulfilled, (state, {meta, payload}) => {
                const postAuthOptions = {...payload.data.getPostAuthParameters}
                // the '__typename' propery is added by graphQL to the response and causes
                // issues with AJV validation, so we remove it.
                delete postAuthOptions['__typename']
                state['loading'] = false;
                state[`postAuthOptions`][meta.arg.connectionId as string] = postAuthOptions 
            })
            
            builder.addCase(getPostAuthOptions.rejected, (state, action) => {
                state['loading'] = false;
                state['message'] = "An Error Has Occurred: Failed To Load Post Auth Options"
                return state;
            })

            builder.addCase(getConnectionWorkflowData.pending, (state, {payload}) => {
                state['loading'] = true;
                state['message'] = ""
                state['connectionWorkflowId'] = ''
                state['connectionWorkflowPreAuthData'] = {}
                state['connectionWorkflowSystemName'] = ''
            })
            builder.addCase(getConnectionWorkflowData.fulfilled, (state, {payload}) => {
                const { data } = payload
                const curCon = state['clientConnections'].filter((con) => con.id === data.connectionAuthLinkId.id)[0];
                state['loading'] = false;
                state['connectionWorkflowId'] = data.connectionAuthLinkId.id
                state['connectionWorkflowPreAuthData'] = curCon ? curCon['preAuthConfigValues'] : {}
                state['connectionWorkflowSystemName'] = curCon ? curCon['system'] : ''
            })
            builder.addCase(getConnectionWorkflowData.rejected, (state, action) => {
                state['loading'] = false;
                return state;
            })            
            builder.addCase(getNangoIntegrationName.pending, (state, {payload}) => {
                state['loading'] = true;
                state['nangoIntegrationName'] = ''
            })
            builder.addCase(getNangoIntegrationName.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['loading'] = false;
                state['nangoIntegrationName'] = data.getNangoIntegrationName
            })
            builder.addCase(getNangoIntegrationName.rejected, (state, action) => {
                state['loading'] = false;
                return state;
            })          
            

            builder.addCase(getErpEntities.pending, (state, {payload}) => {
                state['loading'] = true;
            })
            builder.addCase(getErpEntities.fulfilled, (state, {payload}) => {
                const { data } = payload
                state['loading'] = false;
                state['erpEntities'] = data.getErpEntities.entities
            })
            builder.addCase(getErpEntities.rejected, (state, action) => {
                state['loading'] = false;
                return state;
            })     
        }
    })
}

export const {
  setExtractionDetails,
  createERP,
  createExtraction,
  resetExtractionSettings,
  resetConnections,
  resetExtractions,
  resetExtractionState,
  resetCreationForms
} = extractionsStateSlice.actions

export const extractionReducer = extractionsStateSlice.reducer
