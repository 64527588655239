
import { 
  rankWith, 
  formatIs, 
  RankedTester,
} from '@jsonforms/core';

export const DatePickerTester: RankedTester = rankWith(
  10,
  formatIs('date')
);