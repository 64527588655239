import { GET_PRE_UPLOADED_FILES_BY_FILE_SYSTEM_NAME } from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApolloClient, gql } from '@apollo/client'
import { preUploadFiles } from '@engine-b/shared/types'

enum LOADING_STATE {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface copyP {
  status: String
  err: boolean
  message: String
  fileName: String
}

interface preUploadFileCollections {
  files: preUploadFiles[]
  isLoading: LOADING_STATE
  copyPreUpload: copyP
  loading: LOADING_STATE
}

const INITIAL_STATE: preUploadFileCollections = {
  files: [],
  isLoading: LOADING_STATE.DONE,
  copyPreUpload: null,
  loading: LOADING_STATE.LOADING,
}

export const getPreUploadedFiles = createAsyncThunk(
  'preUploadedFiles/getPreUploadedFiles',
  async (payload: {client: ApolloClient<unknown>, auditedEntity, engagementId}) => {
    // Get the current auditedEntity 
    // and engagement from redux to pass to the backend
    // so that we can find the right files for the current
    // client and engagement
    const { auditedEntity, engagementId} = payload

    if (!auditedEntity || !engagementId) {
      throw new Error(
        'Error getting pre-uploaded files with unknown audited or engagement name'
      )
    }
    const response = await payload.client.query({
      query: GET_PRE_UPLOADED_FILES_BY_FILE_SYSTEM_NAME,
      variables: {
        clientName: auditedEntity,
        engagement: engagementId,
      },
    })
    return response
  }
)

export const preUploadSlice = createPreUploadSlice(INITIAL_STATE);

export function createPreUploadSlice(initialState: preUploadFileCollections) {
  return createSlice({
    name: 'preUploadedFiles',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getPreUploadedFiles.pending, (state, _) => {
        state.isLoading = LOADING_STATE.LOADING
      })

      builder.addCase(getPreUploadedFiles.fulfilled, (state, action) => {
        const { preUploadedFiles } = action.payload.data
        state.files = preUploadedFiles
        state.isLoading = LOADING_STATE.DONE
        return state
      })

      builder.addCase(getPreUploadedFiles.rejected, (state) => {
        state.isLoading = LOADING_STATE.ERROR
      })
    },
  })
}

export const preUploadReducer = preUploadSlice.reducer
