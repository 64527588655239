import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type SoftReloadState = {
  softReload: boolean
}
export default (callback: () => void) => {
  const { state: locationState } = useLocation() || {}
  const history = useHistory()

  useEffect(() => {
    if ((locationState as SoftReloadState)?.softReload) {
      history.replace()
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState])
}
