import { LanguageStrings } from './base-strings'

export const enUS: LanguageStrings = {
  /** Routes */
  'routes.status': '/status',
  'routes.add-data': '/add-data',
  'routes.add-custom-erp': '/add-custom-erp',
  'routes.support': '/support',
  'routes.custom-grouping': '/custom-grouping',
  'routes.table-joins': '/table-joins',
  'routes.create': '/table-joins/create',
  'routes.extractions': '/extractions',
  'routes.extractions-settings': '/extractions/settings',
  'routes.audit-analytics': '/audit-analytics',
  'routes.audit-analytics.create': '/audit-analytics-create',
  'routes.data-and-analytics': '/data-and-analytics',
  'routes.create-pipeline': '/data-and-analytics/create-pipeline',
}
