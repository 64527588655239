import { ApolloClient } from '@apollo/client'
import {
  ADD_CDM_ENTITIES_TO_DI_AND_TRIGGER_PIPELINE,
  CREATE_DATA_INGESTION,
  UPDATE_DATA_INGESTION,
  GET_DATA_INGESTION,
  GET_DATA_INGESTIONS,
  GET_DATAINGESTION_GROUPINGS,
  ADD_UPLOADED_FILE,
  REMOVE_DI_GROUPER,
  BEGIN_DATA_INGESTION
} from '@engine-b/integration-engine/data/data-ingestion-api'
import { CdmEntity, Query } from '@engine-b/shared/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { mapDItoRun, Run, RunStatus } from '../typedefs'

export type RunUpdate = Pick<Run, 'id'> & Partial<Run>

export type DraftRunUpdate = Partial<Run>

export interface RunsSlice {
  runs: RunsState
  fileMappings: any,
}

export enum LOADING_STATE {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

// The AddData view and its' logic isn't very UI test friendly;
// In order to perform UI tests to these views, we're passing 
// new store variables that we can control through our UI tests
// in order to allow these views to be rendered in a way that allows
// them to be tested (i.e. preventing store data from reseting for
// each time we have to perform a test, controlling the "step" which we
// want to test, et cetera.)
export interface Testing {
  testMode: boolean
  step: number
}

export interface RunsState {
  testing: Testing
  runs: Run[]
  draftRun: Run
  loadingState: LOADING_STATE
  triggerLoadingState: LOADING_STATE
  run: Run
  loaded: boolean
  dataIngestionGroupings: any
  dataIngestionId: string
  total: number
}

export const INITIAL_RUN_STATE: RunsState = {
  testing: {
    testMode: false,
    step: 0
  },
  runs: [],
  draftRun: getInitialRun(),
  loadingState: LOADING_STATE.DONE,
  triggerLoadingState: LOADING_STATE.DONE,
  run: null,
  loaded: false,
  dataIngestionGroupings: [],
  dataIngestionId: '',
  total: 0
}

export function getInitialRun(): Run {
  return {
    id: null,
    dataStartDate: new Date().toDateString(),
    dataEndDate: new Date().toDateString(),
    initiatedAt: null,
    completeAt: null,
    erpId: null,
    groupId: null,
    groupName: null,
    groupVersion: null,
    groupLastUpdatedBy: null,
    extractType: null,
    container: null,
    uiState: RunStatus.INITIALIZED,
    auditedEntityName: '',
    cdmEntitySystemNames: [],
    createdBy: null,
    clientCode: '',
    uploadedFiles: [],
    engagement: null,
    name: null,
    preloaderInfo: null
  }
}

export const triggerPipeline = createAsyncThunk<
  any,
  { client: ApolloClient<unknown>; isCustomMapped: boolean },
  { state: RunsSlice }
>('beginDataIngestion', async (payload, thunkAPI) => {
  const { client, isCustomMapped } = payload
  const state = thunkAPI.getState()
  const {
    id: dataIngestionId,
    extractType,
    cdmEntitySystemNames,
    erpId: cdmEntityErpId,
    container
  } = state.runs.draftRun

  const fileSystemId = container?.fileSystemId;
  const inputPath = container?.inputPath;

  const fileMappings = state.fileMappings

  const uploadFileNameArray: any = [];

  const uploadedFiles = Object.keys(fileMappings).map((key) => {
    uploadFileNameArray.push(fileMappings[key].fileNameByUser);
    return {
      name: fileMappings[key].originalFileName,
      size: `${fileMappings[key].size}`,
      status: fileMappings[key].state,
      fileNameByUser: fileMappings[key].fileNameByUser,
      saveFileInContainer: fileMappings[key].saveFileInContainer,
    }
  })

  const response = await client.mutate({
    mutation: ADD_CDM_ENTITIES_TO_DI_AND_TRIGGER_PIPELINE,
    variables: {
      dataIngestionId,
      extractType,
      cdmEntitySystemNames,
      cdmEntityErpId,
      uploadedFiles,
      isCustomMapped,
      fileSystemId,
      inputPath,
      uploadFileNameArray
    },
  })

  return response
})

export const getDataIngestions = createAsyncThunk(
  'getDataIngestions',
  async (options: { client: ApolloClient<unknown>, page?: number, pageSize?: number, auditedEntityId?: string, engagementId?: string, status?: string, initiatedAt?: string, name?: string }) => {
    const { client, page, pageSize, auditedEntityId, engagementId, status, initiatedAt, name } = options;
    const response = await client.query<{
      dataIngestions: Query['dataIngestions']
    }>({
      query: GET_DATA_INGESTIONS,
      variables: {
        page,
        pageSize,
        auditedEntityId,
        engagementId,
        status,
        initiatedAt,
        name
      },
    })
    return response
  }
)

export const removeDataIngestionGrouper = createAsyncThunk(
  'removeDataIngestionGrouper',
  async (payload: { client: ApolloClient<unknown>; id: string }) => {
    const response = await payload.client.mutate({
      mutation: REMOVE_DI_GROUPER,
      variables: {
        id: payload.id
      }
    })
    return response
  }
)

export const getDataIngestionGroupings = createAsyncThunk(
  'getDataIngestionGroupings',
  async (payload: { client: ApolloClient<unknown>; id: string; type: string }) => {
    let variables = {}
    if (payload.type === 'grouping') {
      variables = {
        dataIngestionGrouperId: payload.id,
      }
    } else {
      variables = {
        dataIngestionId: payload.id,
      }
    }
    const response = await payload.client.query({
      query: GET_DATAINGESTION_GROUPINGS,
      variables,
    })
    return response
  }
)

export const addUploadedFile = createAsyncThunk<
  any,
  ApolloClient<unknown>,
  { state: RunsSlice }
>('addUploadedFile', async (client, thunkAPI) => {
  const state = thunkAPI.getState()
  const draftRun = state.runs.draftRun

  const [uploadedFile] = draftRun.uploadedFiles.slice(-1)

  const response = await client.mutate({
    mutation: ADD_UPLOADED_FILE,
    variables: {
      dataIngestionId: draftRun.id,
      name: uploadedFile.name,
      size: uploadedFile.size,
      status: uploadedFile.status,
      fileNameByUser: uploadedFile.fileNameByUser,
    },
  })

  return response
})

export const createDataIngestion = createAsyncThunk<
  any,
  ApolloClient<unknown>,
  { state: RootState }
>('createDataIngestion', async (client, thunkAPI) => {
  const state = thunkAPI.getState()
  const draftRun = state.runs.draftRun
  const { engagement, auditedEntity } = state.engagement

  const response = await client.mutate({
    mutation: CREATE_DATA_INGESTION,
    variables: {
      dataStartDate: new Date(draftRun.dataStartDate).toISOString(),
      dataEndDate: new Date(draftRun.dataEndDate).toISOString(),
      erpId: draftRun.erpId,
      auditedEntityName: auditedEntity.name,
      ingestionName: draftRun.name,
      extractType: draftRun.extractType,
      clientCode: draftRun.clientCode,
      groupId: draftRun.groupId,
      groupName: draftRun.groupName,
      groupVersion: draftRun.groupVersion,
      groupLastUpdatedBy: draftRun.groupLastUpdatedBy,
      engagementId: engagement.id,
    },
  })

  return response
})

export const updateDataIngestion = createAsyncThunk<
  any,
  ApolloClient<unknown>,
  { state: RootState }
>('updateDataIngestion', async (client, thunkAPI) => {
  const state = thunkAPI.getState()
  const draftRun = state.runs.draftRun
  const fileMappings = state.fileMappings

  try {
    // Gathering additional details
    const additionalDetailValues = {}
    for (const [key, value] of Object.entries(fileMappings)) {
      additionalDetailValues[key] = value.additionalDetail
    }

    const response = await client.mutate({
      mutation: UPDATE_DATA_INGESTION,
      variables: {
        dataIngestionId: draftRun.id,
        status: "INITIALIZED",
        additionalDetailValues
      },
    })

    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})


export const getDataIngestion = createAsyncThunk(
  'getDataIngestion',
  async ({ client, id }: { client: ApolloClient<unknown>; id: string }) => {
    const response = await client.query({
      query: GET_DATA_INGESTION,
      variables: { ingestionId: id },
    })
    return response
  }
)

export const reTriggerPipeline = createAsyncThunk('reTriggerPipeline',
  async (payload: { client: ApolloClient<unknown>; isCustomMapped: boolean, dataIngestionId: string }, { rejectWithValue }) => {
    try {
      const response = await payload?.client.mutate({
        mutation: BEGIN_DATA_INGESTION,
        variables: {
          dataIngestionId: payload?.dataIngestionId,
          isCustomMapped: payload?.isCustomMapped,
        },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  })

export type UpdateFunction = (state) => DraftRunUpdate

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const runsSlice = createRunsSlice(INITIAL_RUN_STATE)

export function createRunsSlice(initialState: RunsState) {
  return createSlice({
    name: 'run',
    initialState,
    reducers: {
      initializeDraftRun: (state) => {
        state.draftRun = getInitialRun()
      },
      updateDraftRun: (
        state,
        action: PayloadAction<DraftRunUpdate | UpdateFunction>
      ) => {
        if (typeof action.payload === 'function') {
          state.draftRun = {
            ...state.draftRun,
            ...action.payload(state.draftRun),
          }
        } else {
          state.draftRun = {
            ...state.draftRun,
            ...action.payload,
          }
        }
      },
      addCdmEntitySystemName: (
        state,
        action: PayloadAction<CdmEntity['systemName']>
      ) => {
        state.draftRun.cdmEntitySystemNames =
          state.draftRun.cdmEntitySystemNames.concat(action.payload)
      },
      deleteCdmEntitySystemName: (
        state,
        action: PayloadAction<CdmEntity['systemName']>
      ) => {
        state.draftRun.cdmEntitySystemNames = state.draftRun.cdmEntitySystemNames.filter((names) => names !== action.payload)
      },
      clearAllCdmEntitySystemName: (
        state
      ) => {
        state.draftRun.cdmEntitySystemNames = [];
      },
      clearAllIngestionGroupings: (state) => {
        state.dataIngestionGroupings = [];
      },
      saveDraftRun: (state) => {
        state.runs = state.runs.concat(state.draftRun)
        state.draftRun = getInitialRun()
      },
      updateLoaded: state => {
        state.loaded = true
      },
      clearRun: (state, { payload }) => {
        state.run = payload
      }
    },
    extraReducers: (builder) => {
      builder.addCase(triggerPipeline.pending, (state, _) => {
        state.triggerLoadingState = LOADING_STATE.LOADING
      })
      builder.addCase(triggerPipeline.fulfilled, (state, action) => {
        const normalised = mapDItoRun(action.payload.data.beginDataIngestion)
        const updatedDI = {
          ...state.draftRun,
          ...normalised,
        }
        state.runs = [updatedDI, ...state.runs]
        state.draftRun = getInitialRun()
        state.triggerLoadingState = LOADING_STATE.DONE
      })

      builder.addCase(triggerPipeline.rejected, (state, action) => {
        console.error('triggerPipeline request was rejected: ', action.error)
        const updatedDI = {
          ...state.draftRun,
          uiState: RunStatus.FAILED,
        }

        state.runs = state.runs.concat([updatedDI])
        state.draftRun = getInitialRun()
      })

      builder.addCase(getDataIngestions.pending, (state, _) => {
        state.loadingState = LOADING_STATE.LOADING
      })

      builder.addCase(getDataIngestions.fulfilled, (state, action) => {
        const dis = action.payload.data.dataIngestions.data
        const normalised = dis.map(mapDItoRun)
        state.runs = normalised
        state.total = action.payload.data.dataIngestions.total
        state.loadingState = LOADING_STATE.DONE
      })

      builder.addCase(getDataIngestions.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.ERROR
        console.error('getDataIngestions request was rejected: ', action.error)
      })

      builder.addCase(removeDataIngestionGrouper.pending, (state, _) => {
        state.loadingState = LOADING_STATE.LOADING
      })

      builder.addCase(removeDataIngestionGrouper.fulfilled, (state, action) => {
        state.loadingState = LOADING_STATE.DONE
        const copy = [...state.dataIngestionGroupings]
        const idToRemove =
          action.payload.data.removeDataIngestionGrouper.grouperId
        state.dataIngestionGroupings = copy.filter(
          (grouping) => grouping.grouperId !== idToRemove
        )
      })

      builder.addCase(removeDataIngestionGrouper.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.ERROR
        console.error('removeDataIngestionGrouper request was rejected: ', action.error)
      })

      builder.addCase(getDataIngestionGroupings.pending, (state, _) => {
        state.loadingState = LOADING_STATE.LOADING
        state.dataIngestionGroupings = []
      })

      builder.addCase(getDataIngestionGroupings.fulfilled, (state, action) => {
        const dIGroupings = action.payload.data.getDataIngestionGroupings
        state.dataIngestionGroupings = dIGroupings
        if (dIGroupings.length > 0) {
          state.dataIngestionId = dIGroupings[0]?.dataIngestionId
        }
        state.loadingState = LOADING_STATE.DONE
      })

      builder.addCase(getDataIngestionGroupings.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.ERROR
        console.error('getDataIngestionGroupings request was rejected: ', action.error)
      })

      builder.addCase(createDataIngestion.fulfilled, (state, action) => {
        const actualDI = {
          ...state.draftRun,
          ...mapDItoRun(action.payload.data.createDataIngestion),
        }
        state.draftRun = actualDI
      })

      builder.addCase(createDataIngestion.rejected, (_, action) => {
        console.error('createDataIngestion request was rejected:', action.error)
      })

      builder.addCase(getDataIngestion.fulfilled, (state, action) => {
        const { erp, status, ...rest } = action.payload.data.dataIngestion
        state.run = {
          ...rest,
          erpId: erp.id,
          uiState: status,
          auditedEntityName: rest.auditedEntity.name,
          cdmEntities: rest.cdmEntities,
          cdmEntitySystemNames: rest.cdmEntities.map(
            (ce) => ce.cdmEntity.systemName
          ),
        }
        return state
      })

      builder.addCase(getDataIngestion.rejected, (state) => { })

      builder.addCase(reTriggerPipeline.pending, (state) => {
        state.loadingState = LOADING_STATE.LOADING
      })
      builder.addCase(reTriggerPipeline.fulfilled, (state, action) => {
        const { id } = action.payload.data.beginDataIngestion
        const runIndex = state.runs.findIndex(run => run.id === id)
        if (runIndex !== -1) {
          state.runs[runIndex].uiState = RunStatus.IN_PROGRESS
        }
        state.loadingState = LOADING_STATE.DONE
      })
      builder.addCase(reTriggerPipeline.rejected, (state) => {
        state.loadingState = LOADING_STATE.ERROR
      })
    },
  })
}

// Action creators are generated for each case reducer function
export const {
  initializeDraftRun,
  updateDraftRun,
  saveDraftRun,
  addCdmEntitySystemName,
  deleteCdmEntitySystemName,
  clearAllCdmEntitySystemName,
  updateLoaded,
  clearAllIngestionGroupings,
  clearRun
} = runsSlice.actions

export const runsReducer = runsSlice.reducer
