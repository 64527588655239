import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import { ReactComponent as IconClose } from '../../../assets/IconClose.svg'

const useStyles = makeStyles(() => ({
  saveMapping: {
    '& .MuiDialog-paper': {
      width: '750px',
      height: 'auto',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '20px',
    },
    '& .MuiDialogTitle-root > h2': {
      textAlign: 'center',
      font: 'normal normal bold 20px/30px Arial',
      letterSpacing: '0.2px',
      color: '#22353F',
      '& > .MuiButtonBase-root': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    '& .MuiDialogContent-root > .MuiAlert-root': {
      marginTop: '15px',
      background: '#FFF4E5 0% 0% no-repeat padding-box',
      borderRadius: '8px',
      width: '510px',
      minHeight: '95px',
      margin: '15px auto auto auto',
      '& > .MuiAlert-message': {
        textAlign: 'left',
        font: 'normal normal bold 14px/24px Arial',
        letterSpacing: '0px',
        color: '#AB6500',
      },
    },
    '& .MuiInputBase-root': {
      width: '510px',
      height: '52px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '44px',
      '& > button': {
        height: '44px',
        minWidth: -0,
      },
      '& > button.no-thanks': {
        background: '#89A0AC 0% 0% no-repeat padding-box',
        color: '#fff',
      },
      '& > button:first-child': {
        width: '132px',
      },
      '& > button:last-child': {
        width: '128px',
        marginLeft: '15px',
      },
    },
  },
}))

const SaveMappingDialog = ({ open, onClose, savedMappings, customMappingFileName, setCustomMappingFileName, setSaveMapDialog, saveType }) => {
  const { saveMapping } = useStyles()

  const [replace, setReplace] = useState(false)

  const isNameAlreadyExist = () =>
    savedMappings
      .map((sm) => sm.filename)
      .includes(customMappingFileName + '.json')

  const onCloseHandler = () => {
    if (savedMappings.length > 0) {
      isNameAlreadyExist()
        ? setReplace(true)
        : onClose(saveType, true, customMappingFileName)
    } else {
      onClose(saveType, true, customMappingFileName)
    }
  }

  const handleChangeFileName = e => {
    const {value} = e.target
    if(!value.includes("/")){
      setCustomMappingFileName(value)
    }
  }

  return (
    <Dialog open={open} className={saveMapping} maxWidth={false}>
      <DialogTitle>
        Would you like to save this mapping?
        <IconButton onClick={() => setSaveMapDialog(false)}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          placeholder="Enter Mapping Name"
          variant="outlined"
          fullWidth
          value={customMappingFileName}
          onChange={handleChangeFileName}
        />
        {replace && isNameAlreadyExist() && (
          <Alert severity="warning">
            A mapping with this name already exists. Please change your chosen
            name to make it unique in order to Save As a new map. Alternatively,
            choose Replace to overwrite the existing saved map and move to the
            next version.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {replace ? (
          <>
            <Button
              disabled={
                customMappingFileName.length === 0 || !isNameAlreadyExist()
              }
              variant="contained"
              color="primary"
              onClick={() =>
                isNameAlreadyExist() &&
                onClose(saveType, true, customMappingFileName)
              }
            >
              Replace
            </Button>
            <Button
              disabled={
                customMappingFileName.length === 0 || isNameAlreadyExist()
              }
              variant="contained"
              color="secondary"
              onClick={() => !isNameAlreadyExist() && onClose(saveType, true)}
            >
              Save As
            </Button>
          </>
        ) : (
          <>
            {saveType === 'apply' && (
              <Button
                variant="contained"
                className="no-thanks"
                onClick={() => onClose(saveType, false)}
              >
                No, thanks
              </Button>
            )}
            <Button
              disabled={customMappingFileName.trim().length === 0}
              variant="contained"
              color="secondary"
              onClick={onCloseHandler}
            >
              Save
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SaveMappingDialog
