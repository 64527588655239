import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useCompletedScreenStyles = makeStyles((theme) =>
  createStyles({
    completedScreenContainer: {
      padding: '15% ​40px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      height: '65vh',
      justifyContent: 'center',
      background: '#F1F9FB',
      '& a': {
        textDecoration: 'none',
        '& button': {
          margin: '20px',
        },
      },
    },
    completedScreenInstructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: '1rem',
    },
    completedScreenButtons: {
      display: 'inline-block',
      flexDirection: 'row',
      justifyContent: 'center',
      '& button': {
        height: '44px',
        '& span': {
          font: 'normal normal bold 16px/24px Arial',
        },
      },
    },
  })
)

interface Props {
  title: string
  subtitle: JSX.Element | string
  firstButton: JSX.Element
  secondButton: JSX.Element
}

export function CompletedScreen({
  title,
  subtitle,
  firstButton,
  secondButton,
}: Readonly<Props>) {
  const classes = useCompletedScreenStyles()

  return (
    <div className={classes.completedScreenContainer}>
      <div className={classes.completedScreenInstructions}>
        <Typography variant="h5">{title}</Typography>
        {subtitle}
      </div>
      <div className={classes.completedScreenButtons}>
        {firstButton}
        {secondButton}
      </div>
    </div>
  )
}
