import { useMsal } from '@azure/msal-react'
import React from 'react'
import { protectedResources } from '../authConfig'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { asyncTokenLookup } from './asyncTokenLookup'

export function ApolloProviderWithAuth({ children }) {
  const { instance, inProgress, accounts } = useMsal()

  const withToken = setContext(async (_, { headers }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Cypress) {
      const token = sessionStorage.getItem('dataIngestionApi')
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
        },
      }
    } else {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
        },
      }
    }
  })

  const httpLink = createHttpLink({
    uri: process.env.NX_API_URL || 'http://localhost:7071/graphql',
    credentials: 'same-origin',
  })

  const client = new ApolloClient({
    link: withToken.concat(httpLink),
    connectToDevTools: true,
    cache: new InMemoryCache(),
  })

  return (
    <React.Fragment>
      {client ? (
        <ApolloProvider client={client}>{children}</ApolloProvider>
      ) : (
        children
      )}
    </React.Fragment>
  )
}
