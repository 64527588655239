import { Grid, TextField } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { EBAutoComplete, Select } from '@engine-b/shared/components'
import {
  useIESelector,
  useIEDispatch,
  JOIN_STATUS_TITLES,
  JOIN_STATUS,
  getAuditedEntitiesByUser,
  resetEngagementState,
  getEngagements,
  getJoins
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => {
  return createStyles({
    textInput: {
      width: '20%',
      height: '60px',
      '& .MuiTextField-root': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    statusFilter: {
      width: '15%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    refreshIcon: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid #CBCBCB',
      height: '40px',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#395C74'
      }
    },
  })
})

const statusItems = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: JOIN_STATUS.COMPLETED,
    label: JOIN_STATUS_TITLES[JOIN_STATUS.COMPLETED],
  },
  {
    value: JOIN_STATUS.IN_PROGRESS,
    label: JOIN_STATUS_TITLES[JOIN_STATUS.IN_PROGRESS],
  },
  {
    value: JOIN_STATUS.INITIALIZED,
    label: JOIN_STATUS_TITLES[JOIN_STATUS.INITIALIZED],
  },
  {
    value: JOIN_STATUS.FAILED,
    label: JOIN_STATUS_TITLES[JOIN_STATUS.FAILED],
  },
  {
    value: JOIN_STATUS.FAILED_TO_PROCESS,
    label: JOIN_STATUS_TITLES[JOIN_STATUS.FAILED_TO_PROCESS],
  },
]

type StatusViewFilterState = {
  name: string
  status: string
  engagement: string
  client: string
}

type StatusViewFilterProps = {
  filterState: StatusViewFilterState
  applyFilter(params: { event: any; value: any; filterName: string }): void
}


export function StatusViewFilters({
  filterState,
  applyFilter,
}: StatusViewFilterProps) {
  const { textInput, statusFilter, refreshIcon } = useStyles()

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { loading } = useIESelector((state) => state.joins)
  const [keyValue, setKeyValue] = useState<number>(0);

  const dispatch = useIEDispatch()
  const client = useApolloClient()


  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getEngagements({ client, id: filterState.client }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.client])


  const onChange = (e: any, optionData: any, filterName: string, reason) => {
    let val = optionData?.id
    if (reason === 'clear') {
      val = ''
    }

    if (filterName === 'client') {
      applyFilter({
        event: e,
        value: '',
        filterName: 'engagement',
      })
    }

    applyFilter({
      event: e,
      value: val,
      filterName,
    })
  }

  return (
    <>
      <Grid item className={textInput}>
        <TextField
          variant="outlined"
          hiddenLabel
          placeholder="Select Name"
          size="small"
          InputProps={{
            style: {
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '8px',
            },
          }}
          onChange={(e) =>
            applyFilter({
              event: e,
              value: e.target.value,
              filterName: 'name',
            })
          }
        />
      </Grid>

      <Grid item className={statusFilter}>
        <Select
          selectProps={{
            defaultValue: 'all',
            value: filterState.status,
            style: {
              height: '40px',
              backgroundColor: 'white',
              borderRadius: '8px',
            },
          }}
          placeholder="Please Select Status"
          value={filterState.status}
          items={statusItems}
          onChangeHandler={(value) =>
            applyFilter({
              event: null,
              value,
              filterName: 'status',
            })
          }
        />
      </Grid>

      <Grid item className={textInput}>
        <EBAutoComplete
          options={auditedEntities}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Client Names'}
          size="small"
          onChange={(e: any, optionData: any, reason) => {
              onChange(e, optionData, 'client', reason)
              setKeyValue((prev) => prev + 1)
            }
          }
          hiddenLabel={true}
          placeholder="Select Client Name"
          color= 'primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item className={textInput}>
        <EBAutoComplete
          key={keyValue}
          options={engagements}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Engagement Names'}
          size="small"
          onChange={(e: any, optionData: any, reason) => onChange(e, optionData, 'engagement', reason)}
          hiddenLabel={true}
          placeholder="Select Engagement Name"
          color= 'primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item>
      <button
        className={refreshIcon}
        disabled={loading}
        onClick={async () => {
          await client.cache.reset();
          dispatch(getJoins(client));
        }}
      >
        <RefreshIcon />
      </button>
      </Grid>
    </>
  )
}

export default StatusViewFilters
