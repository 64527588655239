import { ExtractType } from '@engine-b/shared/types'

export const EXTRACT_TYPE_LABELS: Record<ExtractType, string> = {
  [ExtractType.Report]: 'Report Extract',
  [ExtractType.System]: 'System Extract',
}

export const VALIDATION_MESSAGES = {
  INVALID_CLIENT_NAME_SPECIAL_CHARACTERS:
    'Invalid client name, special characters not allowed.',
  INVALID_CLIENT_NAME_RESERVED_KEYWORD:
    'Invalid client name, client name should not be a reserved keyword.',
  CLIENT_NAME_LENGTH: 'Client Name should be less than 50 characters',
  CLIENT_NAME_REQUIRED: 'Client Name should not be empty',
  INVALID_ENGAGEMENT_NAME_RESERVED_KEYWORD:
    'Invalid engagement name, engagement name should not be a reserved keyword.',
  INVALID_ENGAGEMENT_NAME_SPECIAL_CHARACTERS:
    'Invalid engagement name, special characters not allowed.',
  ENGAGEMENT_NAME_LENGTH: 'Engagement Name should be less than 50 characters',
  ENGAGEMENT_NAME_REQUIRED: 'Engagement Name should not be empty',
  
}