import React, { useState } from 'react'
import { makeStyles, Typography, Theme } from '@material-ui/core'
import { Dialog } from '@engine-b/shared/components'
import { ReactComponent as IconError } from './assets/IconError.svg'
import { VirtualTable } from './VirtualTable'
import {
  deleteTable,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import UploadFileTable from './UploadFileTable'
/* eslint-disable-next-line */

const DIALOG_WIDTH = '600px'
const DIALOG_HEIGHT = '350px'
export interface VirtualTableWrapperProps {
  tablesList: Array<string>
}

type DialogState = {
  isOpen: boolean
  tableName: string | null
}

const useStyles = makeStyles<Theme>((theme) => ({
  dialogDeleteMessage: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '16px',
  },
  dialogDeleteTarget: {
    color: theme.palette.secondary.main,
  },
  innerText: {
    display: 'inline',
    color: `${theme.palette.secondary.main}!important`,
  },
}))

export const VirtualTableWrapper = (props: VirtualTableWrapperProps) => {
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const [dialogData, setDialogData] = useState<DialogState>({
    isOpen: false,
    tableName: null,
  })
  const { tables } = useIESelector((state) => state.joins)
  const handleTableDeleteAction = (tableName: string) => {
    if (props.tablesList.includes(tableName)) {
      setDialogData({
        isOpen: true,
        tableName,
      })
    }
  }

  const onConfirmation = (confirmation: boolean) => {
    //In both cases dialog should case
    if (confirmation) {
      //User responded positively
      //dispatch an action to remove a table from redux state. - use reportType, inputFile,dialogData.tableName
      dispatch(deleteTable({ tableName: dialogData.tableName }))
    }
    setDialogData({
      isOpen: false,
      tableName: null,
    })
    //if user responds negatively - do nothing for now.
    return
  }
  return (
    <>
      <Dialog
        cancelText="No"
        confirmText="Yes"
        width={DIALOG_WIDTH}
        height={DIALOG_HEIGHT}
        open={dialogData.isOpen}
        onClose={(confirmation) => onConfirmation(confirmation)}
      >
        <>
          <IconError />
          <Typography component={'div'} className="dialog-title">
            Are you sure you want to delete
            <Typography className={`dialog-title ${classes['innerText']} `}>
              {` ${dialogData.tableName} `}
            </Typography>
            ?
          </Typography>
        </>
      </Dialog>

      {Object.values(tables).map((table, index) => {
        const fileExtension = Object.values(table.files)[0]?.name.split('.')[1]

        return fileExtension === 'xlsx' || fileExtension === 'xls' ? (
          <UploadFileTable
            key={`UploadFileTable-${table.name}`}
            name={Object.values(table.files)[0]?.name}
            tableName={table.name}
            onDeleteTable={(e) => handleTableDeleteAction(table.name)}
          />
        ) : (
          <VirtualTable
            key={`VirtualTable-${table.name}-${index}`}
            name={table.name}
            tableName={table.name}
            tableId={index}
            onDeleteTable={(e) => handleTableDeleteAction(table.name)}
          />
        )
      })}
    </>
  )
}

export default VirtualTableWrapper
