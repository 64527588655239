import { gql } from '@apollo/client'

export const GET_ATTRIBUTES_BY_ENTITY_NAME = gql`
  query Attributes(
    $entityName: String
  ) {
    attributes(
      entityName: $entityName
    ) {
      name
      entity {
        displayName
        name
      }
      dataType
    }
  }
`
