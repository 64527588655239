import { ApolloClient } from '@apollo/client'
import { GET_UGMS } from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface UGM_INITIAL_STATE {
    loader: boolean
    ugms: Array<any>
    selectedUgm: string | null
}

const INITIAL_STATE: UGM_INITIAL_STATE = {
    loader: false,
    ugms: [],
    selectedUgm: process.env.NX_DEFAULT_UGM_FILENAME,
}

export const getUgms = createAsyncThunk(
    'getUgms',
    async ({ client }: { client: ApolloClient<unknown> }) => {
        const response = await client.query({
            query: GET_UGMS,
        })
        return response
    }
)

export const ugmSlice = createUgmSlice(INITIAL_STATE)

export function createUgmSlice(initialState: UGM_INITIAL_STATE) {
    return createSlice({
        name: 'ugms',
        initialState,
        reducers: {
            setSelectedUgm: (state, action) => {
                state.selectedUgm = action.payload
            },
        },
        extraReducers: (builder) => {
            builder.addCase(getUgms.pending, (state, action) => {
                state.loader = true
            })

            builder.addCase(getUgms.fulfilled, (state, action) => {
                const { getUgms } = action.payload.data
                state.loader = false
                state.ugms = getUgms
            })

            builder.addCase(getUgms.rejected, (state) => {
                state.loader = false
                state.ugms = []
            })
        },
    })
}
export const { setSelectedUgm } = ugmSlice.actions

export const ugmReducer = ugmSlice.reducer
