import { gql } from '@apollo/client'

export const GET_JOINS = gql`
    query GetTablejoins {
        getTablejoins {
            id
            name
            path
            startDate
            endDate
            initiatedAt
            completeAt
            userId
            createdBy
            status
            engagement {
                id
                name
                auditedEntity {
                  id
                  name
                  auditFirm {
                    id
                    name
                    systemName
                  }
                }
                auditedEntityId
              }
        }
    }  
`
