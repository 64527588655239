import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

export const useEBStepIconStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& circle': {
        fill: theme.palette.grey[400],
      },
      '& text': {
        fill: theme.palette.grey[200],
      },
    },
    active: {
      '& circle': {
        fill: theme.palette.secondary.main,
      },
      '& text': {
        color: 'white',
      },
    },
    circle: {
      fill: theme.palette.grey[400],
    },
    text: {
      fill: theme.palette.grey[200],
    },
    completed: {
      '& circle': {
        fill: theme.palette.secondary.main,
      },
      '& text': {
        color: 'white',
      },
    },
  })
)

export function EBStepIcon(props) {
  const classes = useEBStepIconStyles()
  const { active, completed, icon } = props

  return (
    <div style={{ width: '42px', height: '42px', zIndex: 1 }}>
      <svg
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
        viewBox="0 0 24 24"
        aria-hidden={true}
      >
        <circle cx={12} cy={12} r={12} className={classes.circle}></circle>
        <text className={classes.text} x={8} y={16}>
          {icon}
        </text>
      </svg>
    </div>
  )
}
