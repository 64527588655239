import { gql } from '@apollo/client'

export const CREATE_AUDITED_ENTITY_BY_USER = gql`
  mutation createAuditedEntityByUser($name: String!) {
    createAuditedEntityByUser(name: $name) {
      id
      name
      success
      message
    }
  }
`
