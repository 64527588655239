import { gql } from '@apollo/client'

export const ADD_OPERATION_LOG = gql`
  mutation AddOperationLog (
    $id: String
    $name: String!
    $path: String
    $bundle: addBundle
    $dataIngestionId: String!
    $bundleFunctions: [bundleFunctions]
  ) {
    addOperationLog(
        id: $id, name: $name, dataIngestionId: $dataIngestionId ,path: $path, bundle: $bundle, bundleFunctions: $bundleFunctions
    ) {
        id
    }
  }
`
