import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconArrow } from '../../../assets/IconArrow.svg'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
  },
  tooltip: { backgroundColor: '#22353F' },
  tooltipPlacement: {
    width: '430px',
    maxHeight: 'unset',
    maxWidth: 'unset',
  },
  tooltipPopper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 15px',
    '& ..MuiTypography-caption': {
      font: 'normal normal normal 12px/20px Arial',
      letterSpacing: '0.12px',
      color: '#FFFFFF',
    },
  },
  tooltipArrow: {
    color: '#22353F',
  },
}))

const DateMaskTooltip = () => {
  const { tooltip, tooltipPlacement, tooltipPopper, tooltipArrow } = useStyles()

  const tooltipHint = (
    <div className={tooltipPopper}>
      <Typography variant="caption">
        If Date: 20211202 1255 <IconArrow /> Date Mask: yyyyMMdd HHmm
      </Typography>
      <Typography variant="caption" style={{ margin: '10px 0' }}>
        If Date: 02.12.21 12:55 <IconArrow /> Date Mask: dd.MM.yy HH:mm
      </Typography>
      <Typography variant="caption">
        If Date: 12/02/2021 12:55:32 <IconArrow /> Date Mask: MM/dd/yyyy
        HH:mm:ss
      </Typography>
    </div>
  )

  return (
    <Tooltip
      arrow
      interactive
      title={tooltipHint}
      placement="top-end"
      classes={{
        tooltip,
        tooltipPlacementTop: tooltipPlacement,
        arrow: tooltipArrow,
      }}
    >
      <HelpRoundedIcon
        style={{
          fill: '#89A0AC',
          width: '14px',
          height: '14px',
          marginLeft: 'auto',
        }}
      />
    </Tooltip>
  )
}

export default DateMaskTooltip
