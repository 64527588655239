import { GET_PRE_UPLOADED_JOIN_FILES } from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApolloClient, gql } from '@apollo/client'
import { preUploadJoinFiles } from '@engine-b/shared/types'

enum LOADING_STATE {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

interface copyP {
  status: String
  err: boolean
  message: String
  fileName: String
}

interface preUploadJoinFileCollections {
  joinFiles: preUploadJoinFiles[]
  isLoading: LOADING_STATE
  copyPreUpload: copyP
  loading: LOADING_STATE
}

const INITIAL_STATE: preUploadJoinFileCollections = {
  joinFiles: [],
  isLoading: LOADING_STATE.DONE,
  copyPreUpload: null,
  loading: LOADING_STATE.LOADING,
}

export const getPreUploadedJoinFiles = createAsyncThunk(
  'preUploadedFiles/getPreUploadedJoinFiles',
  async (payload: {client: ApolloClient<unknown>, auditedEntity, engagementId}) => {
    // Get the current auditedEntity 
    // and engagement from redux to pass to the backend
    // so that we can find the right files for the current
    // client and engagement
    const {auditedEntity, engagementId} = payload

    if (!auditedEntity || !engagementId) {
      throw new Error(
        'Error getting pre-uploaded files with unknown audited or engagement name'
      )
    }
    const response = await payload.client.query({
      query: GET_PRE_UPLOADED_JOIN_FILES,
      variables: {
        clientName: auditedEntity,
        engagement: engagementId,
      },
    })
    return response
  }
)

export const preUploadJoinSlice = createPreUploadJoinSlice(INITIAL_STATE);

export function createPreUploadJoinSlice(initialState: preUploadJoinFileCollections) {
  return createSlice({
    name: 'preUploadedJoinFiles',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getPreUploadedJoinFiles.pending, (state, _) => {
        state.isLoading = LOADING_STATE.LOADING
      })

      builder.addCase(getPreUploadedJoinFiles.fulfilled, (state, action) => {
        const { preUploadedJoinsFiles } = action.payload.data
        state.joinFiles = preUploadedJoinsFiles
        state.isLoading = LOADING_STATE.DONE
        return state
      })

      builder.addCase(getPreUploadedJoinFiles.rejected, (state) => {
        state.isLoading = LOADING_STATE.ERROR
      })
    },
  })
}

export const preUploadJoinReducer = preUploadJoinSlice.reducer
