import { 
  rankWith, 
  and,
  schemaTypeIs,
  RankedTester, 
} from '@jsonforms/core';

export const TextFieldTester: RankedTester = rankWith(
  5,
  and(schemaTypeIs('string'))
);