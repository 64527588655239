import { gql } from '@apollo/client'

export const GET_ATTRIBUTES = gql`
  query Attributes(
    $standardName: String
    $entityName: String
    $mandatory: Boolean
  ) {
    attributes(
      standardName: $standardName
      entityName: $entityName
      mandatory: $mandatory
    ) {
      name
      mandatory
      dataType
    }
  }
`
