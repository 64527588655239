import React, { useState, useEffect } from 'react'
import { Grid, Typography, makeStyles, FormHelperText, Button } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { EBButton } from '@engine-b/shared/components'
import { useApolloClient } from '@apollo/client'
import Nango from '@nangohq/frontend';
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import {
  getConnectionWorkflowData,
  getNangoIntegrationName,
  updateConnectionWorkflowAuth,
  useIEDispatch,
  useIESelector
} from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: `#F0FBFA 0% 0% no-repeat padding-box`,
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px !important',
      },
      '& .MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
        rowGap: '16px',
        [theme.breakpoints.down('md')]: {
          flexWrap: 'wrap',
        },
        '& > label': { width: '100px' },
      }
  },
}))

const ConnectionAuthorizationView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { formatMessage, locale } = useIntl()
  const location = useLocation()
  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const connectionWorkflowId = useIESelector((state) => state.extractions.connectionWorkflowId)
  const connectionWorkflowData = useIESelector((state) => state.extractions.connectionWorkflowPreAuthData)
  const nangoIntegrationName = useIESelector((state) => state.extractions.nangoIntegrationName)
  const systemName = useIESelector((state) => state.extractions.connectionWorkflowSystemName)
  const engagement = useIESelector((state) => state.extractions.engagement)
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectTimer, setRedirectTimer] = useState(6);
  
  //TODO: change hardcoded data to local state data.connectionId and data.providerConfigKey
  const [authData, setAuthData] = useState({
    entity: '',
    connectionId: '',
    host: process.env.NX_NANGO_URL,
    websocketsPath: process.env.NX_NANGO_WEBSOCKETS_PATH,
    publicKey: process.env.NX_NANGO_PUBLIC_KEY_PROD,
    preAuthData: {}
  })

  const [data, setData] = useState({
    connectionId: '',
    providerConfigKey: '',
    authOk: false,
  })
  let nango = new Nango({
    host: authData.host,
    websocketsPath: authData.websocketsPath,
    publicKey: authData.publicKey,
  }) //TODO: get this data from the backend;
  const initializeAuthorization = () => {
    nango.auth(
      authData.entity, 
      authData.connectionId,
      { params : { ...authData.preAuthData }}
    ).then((result: { providerConfigKey: string; connectionId: string }) => {
        setErrorMessage("")
        setData({
          connectionId: result.connectionId,
          providerConfigKey: result.providerConfigKey,
          authOk: true
        })
        dispatch(updateConnectionWorkflowAuth({client, connectionId: result.connectionId}))
    }).catch((err: { message: string; type: string }) => {
        setData({ connectionId: '', providerConfigKey: '', authOk: false})
        setErrorMessage(err.message)
    });
  }

  useEffect(() => {
    const loc = location.pathname.split('/')
    dispatch(getConnectionWorkflowData({client, authorisationId: loc[loc.length - 1]}))
  }, [])
  
  useEffect(() => {
    dispatch(getNangoIntegrationName({client, system: systemName, connectionId: connectionWorkflowId}))
  }, [systemName, connectionWorkflowId])

  useEffect(() => {
    setAuthData({
      ...authData,
      entity: nangoIntegrationName,
      connectionId: connectionWorkflowId,
      preAuthData: connectionWorkflowData['authorization']
    })
  }, [connectionWorkflowId, nangoIntegrationName, connectionWorkflowData])

  useEffect(() => {
    if (engagement.name === '') returnToSettings()
  }, [systemName])

  useEffect(() => {
    if(data.authOk) {
      redirectTimer > 0 
      ? setTimeout(() => setRedirectTimer(redirectTimer - 1), 1000)
      : returnToSettings()
    }
  }, [data.authOk, redirectTimer])
  
  const returnToSettings = () => {
    history.push(localizeRouteKey(formatMessage, locale, AppRoute.ExtractionsSettings))
  }

  return (
    <section className={classes.root}>
      {data.authOk 
        ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Authentication verified with sucess!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={() => returnToSettings()}
                color="secondary"
                variant="contained"
              >
                Go back
              </Button>
            </Grid>
          </Grid>
        )
        : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <h2>Authorization</h2>
            </Grid>
            <Grid item xs={12}>
                <Typography>Please authorise access to your account on <b>"{systemName}"</b>.</Typography>
            </Grid>
            <Grid item xs={12}>
              {errorMessage.length > 0 && (
                  <FormHelperText error>{errorMessage}</FormHelperText>
              )}
            </Grid> 
            <Grid item xs={12} spacing={2}>
              <EBButton 
                case="default" 
                label="Cancel" 
                minWidth={false} 
                onClick={() => returnToSettings()}
                style={{marginRight: "8px", height: "100%"}}
              />

              <Button 
                style={{
                  minWidth: 'unset',
                  marginRight: '10px',
                  borderRadius: '5px',
                  width: 'unset',
                }}
                color="secondary"
                variant="contained"
                disabled={authData.connectionId.length <= 0 ? true : false } 
                onClick={() => initializeAuthorization()}
              >
                Authorize
              </Button>
            </Grid>
          </Grid>
        )
      }
    </section>
  )
}

export default ConnectionAuthorizationView
