import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles'
import {
  SelectProps,
  Select,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { INPUT_WIDTH, INPUT_WIDTH_SHORT, NEW_UI_INPUT_WIDTH } from './constants'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useSelectInputClasses = makeStyles((theme) =>
  createStyles({
    MuiInput: {
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '0.5px solid ' + theme.palette.secondary.light,
      padding: '2px 10px',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '10px',
      minWidth: `${INPUT_WIDTH}px`,
      height: '44px',
      '& :before': {
        border: 'none',
      },
    },
    MuiInputWidth: {
      width: NEW_UI_INPUT_WIDTH + 'px',
    },
    MuiInputWithDropdown: {
      width: INPUT_WIDTH_SHORT + 'px',
    },
  })
)

export type IESelectProps = SelectProps
export function IESelect(props) {
  const { className, isAdditionalDropdowns, ...rest } = props
  const classes = useSelectInputClasses()
  return (
    <Select
      MenuProps={MenuProps}
      {...rest}
      className={`${clsx(classes.MuiInput, className)} ${isAdditionalDropdowns ? clsx(classes.MuiInputWithDropdown, className) : clsx(classes.MuiInputWidth, className)}`}
    />
  )
}
