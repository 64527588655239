import React, {useState} from 'react'
import DialogComponent from '../MapperView/components/DialogComponent'
import { useIntl } from 'react-intl'
import { AppRoute, localizeRouteKey } from '@engine-b/integration-engine/features/i18n'
import { useHistory, useLocation } from 'react-router-dom'

export function NotFoundView() {
  const { formatMessage, locale } = useIntl()
  const history = useHistory()

  const [open, setOpen] = useState(true)

  const onDialogClose = (value) => {
    if (value) {
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.Status), {
        softReload: true,
      })
    } else {
      setOpen(false)
    }
  }

  return (
    <section>
      <DialogComponent
        open={open}
        onClose={onDialogClose}
        cancelText="Cancel"
        confirmText="OK"
        title="404: Page not found."
        subtitle="Press OK to return to the status page."
      ></DialogComponent>
    </section>
  )
}

export default NotFoundView
