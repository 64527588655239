import { gql } from '@apollo/client'

export const GET_ANALYTICS_PIPELINE = gql`
  query GetAnalyticsPipeline($id: String) {
  getAnalyticsPipeline(id: $id) {
    id
    name
    status
    createdBy
    createdAt
    updatedBy
    updatedAt
    engagement {
      id
      name
      auditedEntity {
        id
        name
      }
    }
  }
}
`
