import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { Fragment } from 'react'
import {
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
} from '@engine-b/integration-engine/ui/layout'
import CustomScrollbars from '../../../components/CustomScrollbars'

const FOOTER_HEIGHT = 72
const TIMELINE_HEIGHT = 120

const useStepStyles = makeStyles((theme) =>
  createStyles({
    content: {
      overflow: 'auto',
      width: '100%',
      height: `calc(100vh - ${
        FOOTER_HEIGHT + TIMELINE_HEIGHT + HEADER_HEIGHT
      }px)`,
      '& .step-title': {
        font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
        color: '#22353F',
      },
    },
    footer: {
      position: 'fixed',
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
      bottom: '40px',
      height: `${FOOTER_HEIGHT}px`,
    },
  })
)

export const disableField = (fields, key) => fields !== undefined && (fields.includes(key) || fields.includes('all'))

export function Step({ content, footer }) {
  const classes = useStepStyles()
  return (
    <Fragment>
      <div className={classes.content}>
        <CustomScrollbars>
          <div style={{ height: 'auto', minHeight: '550px', padding: '30px' }}>
            {content}
          </div>
        </CustomScrollbars>
      </div>
      <div className={classes.footer}>{footer}</div>
    </Fragment>
  )
}
