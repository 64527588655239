import React, { useState } from 'react'

import { Button, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cuid from 'cuid'
import { EBAutoComplete, Switch } from '@engine-b/shared/components'
import AddIcon from '@material-ui/icons/Add'
import {
  updateCustomInput,
  updateExpression,
  useIEDispatch,
  useIESelector,
  removeOperand,
  addMoreOperand,
  addBracket,
  removeBracket,
} from '@engine-b/integration-engine/data/state/redux'
import Flow from './FlowChart'

import { ReactComponent as IconDelete } from '../../../../assets/IconDelete.svg'
import { ReactComponent as IconCalendar } from '../../../../assets/IconCalendar.svg'
import DateFormatDialog from './DateFormatDialog'
import CustomLabel from '../CustomLabel'

const useStyles = makeStyles(() => ({
  customSelect: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    width: '280px',
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      height: '44px',
    },
  },
  dataTypeCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bracketBtn: {
    marginTop: '41px',
    minWidth: 'unset',
    height: '44px',
    marginRight: '1rem',
  },
}))

const operations = [
  {
    type: 'comparison',
    title: 'Greater Than',
    value: '>',
  },
  {
    type: 'comparison',
    title: 'Less Than',
    value: '<',
  },
  {
    type: 'comparison',
    title: 'Equal to',
    value: '==',
  },
  {
    type: 'comparison',
    title: 'Not Equal to',
    value: '!=',
  },
  {
    type: 'logical',
    title: 'AND',
    value: 'and',
  },
  {
    type: 'logical',
    title: 'OR',
    value: 'or',
  },
  {
    type: 'arithmetic',
    title: 'Add',
    value: '+',
  },
  {
    type: 'arithmetic',
    title: 'Subtract',
    value: '-',
  },
  {
    type: 'arithmetic',
    title: 'Multiply',
    value: '*',
  },
  {
    type: 'arithmetic',
    title: 'Division',
    value: '/',
  },
]

const inputDataTypes = [
  { title: 'Boolean', value: 'boolean' },
  { title: 'Currency', value: 'currency' },
  // { title: 'Date', value: 'date' }, It can be used later
  { title: 'Datetime', value: 'datetime' },
  { title: 'Decimal', value: 'decimal' },
  { title: 'Double', value: 'double' },
  { title: 'Integer', value: 'integer' },
  { title: 'Number', value: 'number' },
  { title: 'String', value: 'string' },
  // { title: 'Time', value: 'time' }, It can be used later
]

const dateParams = [
  { title: 'Date', value: 'date' },
  { title: 'Year', value: 'year' },
  { title: 'Month', value: 'month' },
  { title: 'Weekday', value: 'weekday' },
  { title: 'Day', value: 'day' },
  { title: 'Hour', value: 'hour' },
  { title: 'Minute', value: 'minute' },
  { title: 'Second', value: 'second' },
  { title: 'Microsecond', value: 'microsecond' },
]

export default function ConditionalColumn({ localErpOptions }) {
  const { customSelect, dataTypeCell, bracketBtn } = useStyles()
  const dispatch = useIEDispatch()
  const { expressions, activeExpression } = useIESelector(
    (state) => state.conditionalColumn
  )
  const [dateFormatDialogData, setDateFormatDialogData] = useState({
    dateFormat: null,
    display: false,
    index: -1,
  })

  const getValue = (data) =>
    data?.title === 'Custom Input' ? { field_name: data?.title } : data?.value

  const ErpFieldDropDown = ({ index }) => {
    return (
      <Grid item key={index}>
        <Grid container spacing={3}>
          <Grid item>
            <CustomLabel title="Choose ERP Field" />
            <div className={dataTypeCell}>
              {expressions?.[activeExpression]?.brackets?.[index]?.map(
                (x: string) =>
                  x === '(' && (
                    <span
                      key={cuid()}
                      style={{ fontSize: '1.5rem', marginRight: '5px' }}
                    >
                      {x}
                    </span>
                  )
              )}
              <EBAutoComplete
                freeSolo={true}
                forcePopupIcon={true}
                options={[
                  { field_name: 'Custom Input' },
                  ...localErpOptions.filter(
                    (option) => option?.field_name !== 'Calculated Field'
                  ),
                ]}
                getOptionLabel={(option) => option?.field_name || ''}
                renderOption={(option) => (
                  <span title={option.field_name}>{option?.field_name}</span>
                )}
                size="small"
                value={getValue(expressions[activeExpression]?.operands[index])}
                onChange={(_, value) => {
                  dispatch(
                    updateExpression({
                      key: 'operands',
                      value,
                      index,
                    })
                  )
                }}
                borderRadius={10}
                width={280}
                placeholder="Select ERP Field"
                height={44}
              />
              {expressions?.[activeExpression]?.brackets?.[index]?.map(
                (x: string) =>
                  x === ')' && (
                    <span
                      key={cuid()}
                      style={{ fontSize: '1.5rem', marginLeft: '5px' }}
                    >
                      {x}
                    </span>
                  )
              )}

              {['datetime', 'date', 'time'].includes(
                expressions[activeExpression]?.operands[index]?.value?.data_type
              ) && (
                <IconCalendar
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  onClick={() =>
                    handleDateClick(
                      expressions[activeExpression]?.operands[index]
                        ?.dateFormat,
                      index
                    )
                  }
                />
              )}
            </div>
          </Grid>
          {['datetime', 'date', 'time'].includes(
            expressions[activeExpression]?.operands[index]?.value?.data_type
          ) && (
            <Grid item>
              <CustomLabel title="Choose Date Params" />
              <EBAutoComplete
                freeSolo={true}
                forcePopupIcon={true}
                options={dateParams}
                getOptionLabel={(option) => option?.title || ''}
                size="small"
                value={expressions[activeExpression]?.operands[index]?.subInfo}
                onChange={(_, value) => {
                  dispatch(
                    updateExpression({
                      key: 'dateParams',
                      value,
                      index,
                    })
                  )
                }}
                borderRadius={10}
                width={280}
                placeholder="Select Date Params"
                height={44}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  const onAddHandler = () => {
    dispatch(addMoreOperand({}))
  }

  const getInputType = (inputType) => {
    const typeMapping = {
      string: 'text',
      currency: 'text',
      integer: 'number',
      double: 'number',
      number: 'number',
      decimal: 'number',
      date: 'date',
      time: 'time',
      datetime: 'datetime-local',
    }

    return typeMapping[inputType] || 'text'
  }

  const handleDateClick = (format, index) => {
    setDateFormatDialogData({
      display: true,
      dateFormat: format,
      index,
    })
  }

  const onDateFormatClose = (params) => {
    if (params?.flag) {
      dispatch(
        updateExpression({
          key: 'dateFormat',
          value: params?.dateFormat,
          index: params?.index,
        })
      )
    }

    setDateFormatDialogData({ display: false, index: -1, dateFormat: null })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Flow />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <ErpFieldDropDown index={0} key={0} />
          {expressions[activeExpression]?.operands[0]?.title !==
            'Custom Input' && (
            <Grid item>
              {expressions[activeExpression]?.brackets &&
                expressions[activeExpression]?.brackets[0][0] !== ')' && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={bracketBtn}
                    onClick={() =>
                      dispatch(
                        addBracket({
                          value: '(',
                          index: 0,
                        })
                      )
                    }
                  >
                    Add '('
                  </Button>
                )}
              {expressions[activeExpression]?.brackets &&
                expressions[activeExpression]?.brackets[0]?.length > 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={bracketBtn}
                    onClick={() => dispatch(removeBracket(0))}
                  >
                    Remove '{expressions?.[activeExpression]?.brackets[0][0]}'
                  </Button>
                )}
            </Grid>
          )}
          {expressions[activeExpression]?.operands[0]?.title ===
          'Custom Input' ? (
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <CustomLabel title="Input Data Type" />
                  <EBAutoComplete
                    getOptionLabel={({ title }) => title || ''}
                    value={inputDataTypes.find(
                      (x) =>
                        x.value ===
                        expressions[activeExpression].operands[0]?.type
                    )}
                    onChange={(_, v) => {
                      dispatch(
                        updateCustomInput({
                          key: 'type',
                          value: v?.value || '',
                          operandIndex: 0,
                        })
                      )
                    }}
                    options={inputDataTypes}
                    size="small"
                    borderRadius={10}
                    width={280}
                    placeholder="Select Input Data Type"
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item>
                      <CustomLabel title="Input" />
                      <div className={dataTypeCell}>
                        {expressions[activeExpression].operands[0]?.type ===
                        'boolean' ? (
                          <Switch
                            color="secondary"
                            name="conform_dates"
                            checked={
                              expressions[activeExpression].operands[0]?.value
                            }
                            onChange={(e) =>
                              dispatch(
                                updateCustomInput({
                                  key: 'value',
                                  value: e.target.checked,
                                  operandIndex: 0,
                                })
                              )
                            }
                          />
                        ) : (
                          <TextField
                            className={customSelect}
                            style={{ width: '175px' }}
                            variant="outlined"
                            placeholder="Input"
                            type={getInputType(
                              expressions[activeExpression].operands[0]?.type
                            )}
                            value={
                              expressions[activeExpression].operands[0]?.value
                            }
                            onChange={(e) => {
                              dispatch(
                                updateCustomInput({
                                  key: 'value',
                                  value: e.target.value,
                                  operandIndex: 0,
                                })
                              )
                            }}
                          />
                        )}
                      </div>
                    </Grid>
                    {['datetime', 'date', 'time'].includes(
                      expressions[activeExpression]?.operands[0]?.type
                    ) && (
                      <Grid item>
                        <CustomLabel title="Choose Date Params" />
                        <EBAutoComplete
                          freeSolo={true}
                          forcePopupIcon={true}
                          options={dateParams}
                          getOptionLabel={(option) => option?.title || ''}
                          size="small"
                          value={
                            expressions[activeExpression]?.operands[0]?.subInfo
                          }
                          onChange={(_, value) => {
                            dispatch(
                              updateExpression({
                                key: 'dateParams',
                                value,
                                index: 0,
                              })
                            )
                          }}
                          borderRadius={10}
                          width={280}
                          placeholder="Select Date Params"
                          height={44}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  {expressions[activeExpression]?.brackets &&
                    expressions[activeExpression]?.brackets[0][0] !== ')' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={bracketBtn}
                        onClick={() =>
                          dispatch(
                            addBracket({
                              value: '(',
                              index: 0,
                            })
                          )
                        }
                      >
                        Add '('
                      </Button>
                    )}
                  {expressions[activeExpression]?.brackets &&
                    expressions[activeExpression]?.brackets[0]?.length > 0 && (
                      <Button
                        aria-label="delete"
                        variant="contained"
                        className={bracketBtn}
                        color="secondary"
                        onClick={() => dispatch(removeBracket(0))}
                      >
                        Remove '
                        {expressions?.[activeExpression]?.brackets[0][0]}'
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {!['result', 'default'].includes(expressions[activeExpression]?.type) &&
          expressions[activeExpression]?.operations.map((_ele, i) => {
            return (
              <Grid container spacing={4} key={i + 1}>
                {expressions[activeExpression]?.operands?.length - 1 !== i && (
                  <Grid item>
                    <CustomLabel title="Choose Operation" />
                    <EBAutoComplete
                      getOptionLabel={({ title }) => title || ''}
                      value={expressions[activeExpression].operations[i]}
                      onChange={(_, value) => {
                        dispatch(
                          updateExpression({
                            key: 'operations',
                            value,
                            index: i,
                          })
                        )
                      }}
                      options={operations}
                      size="small"
                      borderRadius={10}
                      width={280}
                      placeholder="Select Operation"
                    />
                  </Grid>
                )}
                <ErpFieldDropDown index={i + 1} />
                {expressions[activeExpression]?.operands[i + 1]?.title ===
                'Custom Input' ? (
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <CustomLabel title="Input Data Type" />
                        <EBAutoComplete
                          getOptionLabel={({ title }) => title || ''}
                          value={inputDataTypes.find(
                            (x) =>
                              x.value ===
                              expressions[activeExpression].operands[i + 1].type
                          )}
                          onChange={(_, v) => {
                            dispatch(
                              updateCustomInput({
                                key: 'type',
                                value: v?.value || '',
                                operandIndex: i + 1,
                              })
                            )
                          }}
                          options={inputDataTypes}
                          size="small"
                          borderRadius={10}
                          width={280}
                          placeholder="Select Input Data Type"
                        />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item>
                            <CustomLabel title="Input" />
                            <div className={dataTypeCell}>
                              {expressions[activeExpression].operands[i + 1]
                                .type === 'boolean' ? (
                                <Switch
                                  color="secondary"
                                  name="conform_dates"
                                  checked={
                                    expressions[activeExpression].operands[
                                      i + 1
                                    ]?.value
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      updateCustomInput({
                                        key: 'value',
                                        value: e.target.checked,
                                        operandIndex: i + 1,
                                      })
                                    )
                                  }
                                />
                              ) : (
                                <TextField
                                  className={customSelect}
                                  style={{ width: '175px' }}
                                  variant="outlined"
                                  placeholder="Input"
                                  type={getInputType(
                                    expressions[activeExpression].operands[
                                      i + 1
                                    ].type
                                  )}
                                  value={
                                    expressions[activeExpression].operands[
                                      i + 1
                                    ].value
                                  }
                                  onChange={(e) => {
                                    dispatch(
                                      updateCustomInput({
                                        key: 'value',
                                        value: e.target.value,
                                        operandIndex: i + 1,
                                      })
                                    )
                                  }}
                                />
                              )}
                            </div>
                          </Grid>
                          {['datetime', 'date', 'time'].includes(
                            expressions[activeExpression]?.operands[i + 1]?.type
                          ) && (
                            <Grid item>
                              <CustomLabel title="Choose Date Params" />
                              <EBAutoComplete
                                freeSolo={true}
                                forcePopupIcon={true}
                                options={dateParams}
                                getOptionLabel={(option) => option?.title || ''}
                                size="small"
                                value={
                                  expressions[activeExpression]?.operands[i + 1]
                                    ?.subInfo
                                }
                                onChange={(_, value) => {
                                  dispatch(
                                    updateExpression({
                                      key: 'dateParams',
                                      value,
                                      index: i + 1,
                                    })
                                  )
                                }}
                                borderRadius={10}
                                width={280}
                                placeholder="Select Date Params"
                                height={44}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item>
                  {expressions[activeExpression]?.operands.length > 2 && i > 0 && (
                    <Button
                      aria-label="delete"
                      component="span"
                      className={bracketBtn}
                      onClick={() =>
                        dispatch(
                          removeOperand({
                            operationIndex: i,
                            operandIndex: i + 1,
                          })
                        )
                      }
                    >
                      <IconDelete style={{ height: '44px', width: '44px' }} />
                    </Button>
                  )}
                  {expressions[activeExpression]?.brackets &&
                    expressions[activeExpression]?.brackets[i + 1][0] !==
                      ')' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={bracketBtn}
                        onClick={() =>
                          dispatch(
                            addBracket({
                              value: '(',
                              index: i + 1,
                            })
                          )
                        }
                      >
                        Add '('
                      </Button>
                    )}
                  {expressions[activeExpression]?.brackets &&
                    expressions[activeExpression]?.brackets[i + 1][0] !==
                      '(' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={bracketBtn}
                        onClick={() =>
                          dispatch(
                            addBracket({
                              value: ')',
                              index: i + 1,
                            })
                          )
                        }
                      >
                        Add ')'
                      </Button>
                    )}
                  {expressions[activeExpression]?.brackets &&
                    expressions[activeExpression]?.brackets[i + 1]?.length >
                      0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={bracketBtn}
                        onClick={() => dispatch(removeBracket(i + 1))}
                      >
                        Remove '
                        {expressions?.[activeExpression]?.brackets[i + 1][0]}'
                      </Button>
                    )}
                </Grid>
              </Grid>
            )
          })}
      </Grid>
      {expressions[activeExpression]?.type === 'condition' && (
        <Button
          variant="contained"
          color="secondary"
          className="addMoreButton"
          onClick={onAddHandler}
        >
          <AddIcon style={{ fontWeight: 'bold' }} /> Add More
        </Button>
      )}
      <DateFormatDialog
        data={dateFormatDialogData}
        onClose={onDateFormatClose}
      />
    </Grid>
  )
}
