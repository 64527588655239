import { useMsal } from '@azure/msal-react'
import { HELPER_TYPE } from '@engine-b/integration-engine/data/helper-text'
import { createSupportTicket } from '@engine-b/integration-engine/features/email'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import {
  Description,
  TextInput,
} from '@engine-b/integration-engine/ui/form-components'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { StatusCodes } from 'http-status-codes'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button as IEButton } from '../../components/Button'
import { CompletedScreen } from '../../components/CompletedScreen'

const useStyles = makeStyles((theme) => {
  const HEADER_HEIGHT = '80px'
  return createStyles({
    container: {
      padding: '30px',
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerTitle: {
      color: '#005955',
      fontWeight: 'bold',
      fontSize: '25px',
    },
    headerSubtitle: {
      color: '#22353F',
      fontSize: '17px',
    },
    email: {
      maxWidth: '230px',
      marginBottom: '25px',
    },
    message: {
      width: '50%',
      height: '35vh',
      minHeight: '10vh',
      maxHeight: '45vh',
      minWidth: '10vh',
      maxWidth: '100%',
      padding: '20px 10px',
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'calc(100% - 60px)',
    },
    sendButton: {
      color: '#fff',
      transition: 'background 250ms, border 250ms',
      background: theme.palette.primary.dark,
      '&:disabled': {
        background: '#CCCCCC',
        cursor: 'default',
      },
      '&:hover': {
        background: `${theme.palette.primary.dark}`,
      },
    },
    backButton: {
      color: '#44697d',
      background: 'none',
    },
    headerTitleCompleted: {
      padding: '30px 0 5px 30px',
    },
  })
})

export function HelpEmailView() {
  const { formatMessage, locale } = useIntl()
  const classes = useStyles()
  const msal = useMsal()
  const history = useHistory()

  const email = msal.accounts[0].username
  const [message, setMessage] = useState('')
  const [isEmailSent, setEmailSent] = useState(false)

  const isSendEnabled = email.length && message.length

  const handleChangeMessage = (e) => {
    setMessage(e.target.value || '')
  }

  const handleSend = useCallback(async () => {
    const data = { message }
    await createSupportTicket(data, msal).then((response) => {
      if (response?.status === StatusCodes.CREATED) {
        setEmailSent(true)
      } else {
        console.error('Failed to send support ticket', response)
      }
    })
  }, [msal, message])

  return (
    <div>
      {!isEmailSent ? (
        <div className={classes.container}>
          <div>
            <Typography className={classes.headerTitle}>
              Get in Touch - Email
            </Typography>
            <Typography className={classes.headerSubtitle}>
              Contact our team for help for technical support and general
              customer service inquiries. <br />
              Submit your request and we will get back to you.
            </Typography>
            <div>
              <Description
                title="Your Registered Email Address"
                helperType={HELPER_TYPE.SUPPORT_EMAIL}
              >
                <TextInput
                  id="entityInformation"
                  name="entityInformation"
                  className={classes.email}
                  value={email}
                  required
                  disabled
                  type="text"
                />
              </Description>
              <textarea
                className={classes.message}
                value={message}
                onChange={handleChangeMessage}
                placeholder="Type here"
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() =>
                history.push(
                  localizeRouteKey(formatMessage, locale, AppRoute.Support)
                )
              }
              size="medium"
              variant="outlined"
              color="primary"
            >
              Back
            </Button>

            <Button
              className={clsx(classes.sendButton)}
              disabled={!isSendEnabled}
              onClick={handleSend}
              size="medium"
              variant="outlined"
            >
              Send
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography
            className={clsx(classes.headerTitle, classes.headerTitleCompleted)}
          >
            Email Support
          </Typography>
          <CompletedScreen
            title="Your request has now been submitted"
            subtitle={
              <p>
                We will contact you on your registered email address. <br />
                Click on Add Data to start a new file conversion or select
                Document(s) <br />
                Status if already submitted.
              </p>
            }
            firstButton={
              <IEButton
                text="New Data Ingestion"
                type="link"
                linkTo={localizeRouteKey(
                  formatMessage,
                  locale,
                  AppRoute.AddData
                )}
              />
            }
            secondButton={
              <IEButton
                text="View The Status Page"
                type="link"
                linkTo={localizeRouteKey(
                  formatMessage,
                  locale,
                  AppRoute.Status
                )}
              />
            }
          />
        </>
      )}
    </div>
  )
}
