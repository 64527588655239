/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApolloClient } from '@apollo/client'
import {
  VALIDATE_CUSTOM_ERP,
  GET_ENTITIES,
  GET_ATTRIBUTES,
  SAVE_CUSTOM_ERP,
  GET_CUSTOM_ERPS_FOR_EDIT,
  ARCHIVE_CUSTOM_ERP,
  GET_CDM_VERSION,
} from '@engine-b/integration-engine/data/data-ingestion-api'
import { CdmEntity, Erp } from '@engine-b/shared/types'
import axios from 'axios'

export interface IExRuleColumns {
  column: string
  contains: string
  exact: boolean
  index?: number
}

export interface IExclusionRule {
  aggr: string
  rules: IExRuleColumns[]
}

export interface ITemplate {
  sheet_name: string
  start_row: number
  global_datetime: string
  exclusion: IExclusionRule
}

export enum UPDATE_STATE {
  COMPLETE = 'complete',
  ERROR = 'error',
  UNSUPPORTED_FILE_TYPE = 'unsupported file type',
  IN_PROGRESS = 'upload in progress',
  NOT_STARTED = 'not started',
  VALIDATING_FILE = 'validating the input file',
  VALIDATING_FILE_COMPLETE = 'validating the input file complete',
  VALIDATING_FILE_FAILED = 'validating the input file terminated',
  VALIDATE_FILE_SIZE = 'file size should be less than 100MB',
  VALIDATE_EXCEL_FILE_SIZE = 'excel file size should be less than 100MB',
  ERROR_EXCEL_FILE_CONVERSION = 'Error converting excel file to csv',
  FILE_VALIDATION_FAILED = 'File validation failed',
  EMPTY_FILE_VALIDATION = 'Uploaded file is empty',
  PRE_UPLOADED_INPUT_FILE_NOT_FOUND = 'File does not exist - please select or upload.',
}

//To determine if entity & extract types while editing
export enum EditErpTypes {
  new = 'New',
  existing = 'Existing',
}

export interface Entity {
  entity_name: string
  display_name: string
  suggested_file_name: string
  file_type: string
  encoding_format: string
  blank_replacement: boolean
  header_row: string
  isPreviewConfirmed: boolean
  global_datetime_format: string
  mandatory_fields: Array<{ label: string; count: number }>
  enableFileUpload: boolean
  fileError?: string
  customErpFieldsConfig: Array<ERPFieldsConfig>
  customErpFieldsDefault: Array<ERPFieldsConfig>
  dfData: Array<{}>
  enableClearAll: boolean
  template: ITemplate
  exclusion: IExclusionRule
  entityType: EditErpTypes
  conform_dates: boolean
  size: number
  uploaded: number
  originalFileName: string
  fileNameByUser: string
  inputFileName: string
  state: UPDATE_STATE
}
export interface ClientSystem {
  systemName: string
  report: boolean
  system: boolean
  editErpExtractType: {
    report: EditErpTypes
    system: EditErpTypes
  }
}
export interface ERPFieldsConfig {
  field_name: string
  nullable: boolean
  required: boolean
  cdm_field_names: string[]
  data_type: 'string' | 'currency' | 'number' | 'boolean' | 'datetime'
  date_format?:
    | 'mm/dd/yyyy'
    | 'dd/mm/yyyy'
    | 'dd/mm/yy'
    | 'mm/dd/yy'
    | 'mm-dd-yy'
    | 'dd-mm-yyyy'
    | 'mm-dd-yyyy'
    | 'dd/mm/yyyy HH:MM:SS'
    | 'dd-mm-yyyy HH:MM'
    | 'dd-mm-yyyy HH:MM:SS'
    | 'dd-mm-yyyy HH.MM'
    | 'dd-mm-yyyy HH.MM.SS'
    | 'dd-mon-yy'
    | 'yyyy-mm-dd HH:MM:SS'
    | 'dd/mm/yyyy HH:MM:SS A'
    | 'yyyy-mm-dd HH:MM:SS.ms'
    | 'yyyy-mm-dd HH:MM'
    | 'mm/dd/yyyy HH:MM:SS A'
    | 'yyyy'
    | 'yyyymmdd'
    | 'mm'
    | 'HH:MM:SS'
    | 'yyyy-mm-dd'
    | 'yyyy-mm-ddTHH:MM:SS.ms'
    | 'm-dd-yy'
    | ''
}

export interface StandardEntity {
  name: string
  displayName: string
  standard: {
    name: string
    displayName: string
  }
}
export interface CustomERP {
  customErpType: EditErpTypes
  client_system: ClientSystem
  entities: {
    report: Array<Entity>
    system: Array<Entity>
  }
  loadConfigurationIsRejected: boolean
  validationData: ValidateResult | {}
  isSubmitting: boolean
  dialogData: {
    open: boolean
    text: string
  }
  yaml_file: string
  is_yaml_valid: boolean
  loader: boolean
  extractData: {}
  entitiesListItems: StandardEntity[]
  can_upload_config: boolean
  erpId: string
  auditFirm: string
  customErps: Erp[]
  submitError: boolean
  configError: boolean
  encoding_options: {
    data: Array<string>
    message: string
    status: boolean
  }
  is_legacy: boolean
  cdm_version: string
  cdmEntity: CdmEntity
}

interface CdmEntities {
  systemName: CdmEntity['name']
  extractType: CdmEntity['extractType']
  exists: boolean
}
interface ValidateResult {
  exists: boolean
  cdmEntities: CdmEntities[]
}

export interface ConvertToYmlResponse {
  valid: boolean
  yml_string: string
  erpId: string
  auditFirm: string
}

export const INITIAL_CUSTOM_ERP_STATE: CustomERP = {
  customErpType: EditErpTypes.new,
  client_system: {
    systemName: '',
    report: false,
    system: false,
    editErpExtractType: {
      report: EditErpTypes.new,
      system: EditErpTypes.new,
    },
  },
  loadConfigurationIsRejected: false,

  entities: {
    report: [],
    system: [],
  },
  isSubmitting: false,
  extractData: {},
  validationData: {},
  dialogData: {
    open: false,
    text: '',
  },
  yaml_file: '',
  is_yaml_valid: false,
  loader: false,
  entitiesListItems: [],
  can_upload_config: false,
  erpId: '',
  auditFirm: '',
  customErps: [],
  submitError: false,
  configError: false,
  encoding_options: {
    data: [],
    message: '',
    status: false,
  },
  is_legacy: false,
  cdm_version: '',
  cdmEntity: {
    __typename: 'CdmEntity',
    extractType: null,
    erp: {
      __typename: 'Erp',
      name: '',
      id: '',
      standard: false,
    },
    inputFiles: [],
    name: '',
    systemName: '',
  },
}

const _getDifference = (array1 = [], array2 = []) => {
  return array1.filter((field) => !array2.includes(field))
}

const _composeCdmEntitesParam = (extractTypes = {}) => {
  return Object.keys(extractTypes).reduce((initial, extractType = '') => {
    const output = extractType
      ? extractTypes[extractType].map(({ entity_name }) => ({
          name: entity_name,
          extractType,
        }))
      : []
    return [...initial, ...output]
  }, [])
}

const _composeDialogText = (validationData: ValidateResult): string => {
  const { cdmEntities } = validationData
  return cdmEntities
    .reduce((initial: string[], curr: CdmEntities) => {
      if (curr?.exists) {
        return [...initial, `${curr.systemName} (${curr.extractType}) `]
      }
      return initial
    }, [])
    .join(', ')
}

const validateCustomErp = async (client, { customERP }) => {
  const {
    client_system: { systemName },
    entities,
  } = customERP
  const { data, errors } = await client.query({
    query: VALIDATE_CUSTOM_ERP,
    variables: {
      erpPayload: {
        name: systemName,
        cdmEntities: _composeCdmEntitesParam(entities),
      },
    },
  })
  if (errors) {
    throw errors
  }
  return data.validateCustomErp
}

const saveCustomERP = async (client, payload) => {
  const { data, errors } = await client.mutate({
    mutation: SAVE_CUSTOM_ERP,
    variables: payload,
  })

  if (errors) {
    throw errors
  }
  return data
}

export const getEntites = createAsyncThunk<
  StandardEntity[],
  { client: ApolloClient<unknown> },
  { state: { customERP: CustomERP } }
>('custom-erp/getEntities', async ({ client }, thunkAPI) => {
  const { data, errors } = await client.query({
    query: GET_ENTITIES,
  })
  if (errors) {
    throw errors
  }
  return data.entities
})

export const addEntity = createAsyncThunk<
  StandardEntity[],
  {
    client: ApolloClient<unknown>
    entity_name: string
    display_name: string
    extractType: string
  },
  { state: { customERP: CustomERP } }
>('custom-erp/addEntity', async ({ client, entity_name = '' }, thunkAPI) => {
  const { data, errors } = await client.query({
    query: GET_ATTRIBUTES,
    variables: {
      entityName: entity_name,
      mandatory: true,
    },
  })
  if (errors) {
    throw errors
  }
  return data.attributes
})

export const extractFields = createAsyncThunk<
  {
    data: { fields: ERPFieldsConfig; encoding: string; dfData: [] }
    extractType: string
    entityIndex: number
    fileName: string
  },
  {
    file_path: string
    extractType: string
    entityIndex: number
    token: string
    skiprows: number
  },
  {
    state: { customERP: CustomERP }
    rejectValue: {
      error: { detail: string }
      extractType: string
      entityIndex: number
    }
  }
>(
  'custom-erp/extractFields',
  async (
    { file_path, entityIndex, extractType, token, skiprows },
    { getState, rejectWithValue }
  ) => {
    const { customERP } = getState()
    if (customERP?.entities?.[extractType]?.[entityIndex]) {
      const { entity_name, encoding_format, blank_replacement } =
        customERP.entities[extractType][entityIndex]

      try {
        const CUSTOM_ERP_EXTRACT_FIELDS_URL = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/erp/extract-fields`

        const requestBody = {
          file_path,
          report_type: entity_name,
          encode_format: encoding_format,
          blank_replacement,
          skiprows,
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }

        const response = await axios.post(
          CUSTOM_ERP_EXTRACT_FIELDS_URL,
          JSON.stringify(requestBody),
          config
        )

        return {
          data: response.data,
          extractType,
          entityIndex,
          fileName: file_path,
        }
      } catch (error) {
        const payload = {
          error: error?.response?.data || {},
          extractType,
          entityIndex,
        }
        return rejectWithValue(payload)
      }
    }
  }
)

export const submitCustomErp = createAsyncThunk<
  ValidateResult | ConvertToYmlResponse,
  { client: ApolloClient<unknown>; confirmed?: boolean; headers?: any },
  { state: { customERP: CustomERP }; rejectValue: any }
>(
  'custom-erp/validateCustomErp',
  async (
    { client, confirmed = false, headers = null },
    { getState, rejectWithValue }
  ) => {
    const state = getState()
    let creatConfig = false
    try {
      const CONVERT_CREATE_CONFIG_ENDPOINT = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/erp/create-config`
      const erpId = ''
      const config_file = null
      const payload = createConverToYamlPayload(
        config_file,
        state.customERP,
        erpId
      )
      try {
        const response = await axios.post(
          CONVERT_CREATE_CONFIG_ENDPOINT,
          payload
        )
        if (response) {
          creatConfig = true
        }
      } catch (e) {
        return rejectWithValue(creatConfig)
      }
      const { exists, cdmEntities } = await validateCustomErp(client, state)

      if (confirmed || !exists) {
        const payload = createSaveERPPayload(state.customERP)
        const {
          saveCustomErp: { success, erpId, auditFirm },
        } = await saveCustomERP(client, payload)

        if (success) {
          const config_file = await getExistingConfig(
            {
              erpId,
              auditFirmId: auditFirm,
            },
            headers
          )

          const payload = createConverToYamlPayload(
            config_file,
            state.customERP,
            erpId
          )
          const response = await axios.post(
            CONVERT_CREATE_CONFIG_ENDPOINT,
            payload
          )
          const { valid, yml_string } = response.data
          return { valid, yml_string, erpId, auditFirm }
        }
      }

      if (exists && !confirmed) {
        return rejectWithValue({
          exists,
          cdmEntities,
        })
      }
    } catch (e) {
      return rejectWithValue(creatConfig)
    }
  }
)

export const loadConfiguration = createAsyncThunk(
  'custom-erp/loadConfiguration',
  async (
    {
      erpId,
      auditFirm,
      headers,
    }: {
      erpId: string
      auditFirm: string
      headers?: any
    },
    { getState, rejectWithValue }
  ) => {
    const config_file = await getExistingConfig(
      {
        erpId,
        auditFirmId: auditFirm,
      },
      headers
    )

    if (config_file === null) {
      return rejectWithValue(null)
    } else {
      return config_file
    }
  }
)

export const archiveCustomErp: any = createAsyncThunk(
  'custom-erp/archiveCustomErp',
  async (
    {
      client,
      payload,
    }: {
      client: ApolloClient<unknown>
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: ARCHIVE_CUSTOM_ERP,
        variables: payload,
      })
      if (data) return data
      else return rejectWithValue(errors)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getExistingConfig = async (payload, headers) => {
  let response = null
  if (!headers) return null
  try {
    response = await axios.post(
      `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/get-custom-config-file`,
      payload,
      { headers }
    )
    return response.data.data
  } catch (error) {
    // If this endpoint gives an error perform rollback on save erp functionality
    return response
  }
}

const getFileName = (fileName: string) => {
  let returnFileName = ''
  if (fileName.match(/\.xls(x)?$/)) {
    returnFileName = fileName.split('.').shift() + '.csv'
  } else {
    returnFileName = fileName
  }
  return returnFileName
}

const createSaveERPPayload = (state) => {
  const { entities, client_system } = state
  const payload = {
    erp: {
      name: client_system.systemName,
      cdmEntities: [],
    },
  }

  for (const key in entities) {
    if (client_system[key]) {
      const result = entities[key].map((entity: Entity) => ({
        name: entity.display_name,
        systemName: entity.entity_name,
        extractType: key.toUpperCase(),
        inputFileName: getFileName(entity.suggested_file_name),
      }))
      payload.erp.cdmEntities.push(...result)
    }
  }

  return payload
}

const createColumnExpression = (rules) => {
  const column_expr = {}
  for (const rule of rules) {
    const { index, ...rest } = rule
    column_expr[index + 1] = rest
  }
  return column_expr
}

const mapExclusion = (legacy: boolean, exclusion) => {
  const { aggr, expr, rules, column_expr } = exclusion
  if (legacy) {
    return {
      aggr,
      rules: rules.map((rule: IExRuleColumns) => ({
        ...rule,
        exact: rule.exact ?? false,
      })),
    }
  } else {
    return {
      aggr: expr,
      rules:
        typeof column_expr === 'object'
          ? Object.values(column_expr).map((ce: IExclusionRule, i) => ({
              ...ce,
              index: i,
            }))
          : [],
    }
  }
}

const mapExlusionCreation = (legacy: boolean, exclusion: IExclusionRule) => {
  if (legacy) {
    return { exclusion }
  } else {
    return {
      exclusion: {
        expr: exclusion.aggr,
        column_expr: createColumnExpression(exclusion.rules),
      },
    }
  }
}

const createConverToYamlPayload = (config_file, state, erpId: string) => {
  const { entities, client_system, is_legacy, cdm_version } = state
  const payload = {
    erp_id: erpId,
    name: client_system.systemName,
    cdm_version,
    mappings: [],
  }
  for (const key in entities) {
    if (client_system[key]) {
      payload?.mappings.push({
        extract: key.toUpperCase(),
        reports: entities[key].map((entity: Entity) => ({
          report_type: entity.entity_name,
          input_files: [
            {
              file_name: getFileName(entity.suggested_file_name),
              encoding_format: entity.encoding_format,
              blank_replacement: entity.blank_replacement,
              template: {
                start_row: +entity.header_row,
                preview_confirmed: entity.isPreviewConfirmed,
                sheet_name: '',
                global_datetime: entity.global_datetime_format,
                ...(entity?.template?.exclusion &&
                  mapExlusionCreation(is_legacy, entity?.template?.exclusion)),
              },
              fields: entity.customErpFieldsConfig,
              mandatory_field_check: entity.mandatory_fields.map((mf) => ({
                cdm_field_name: mf.label,
                occurrence_count: mf.count,
              })),
              conform_dates: entity.conform_dates,
            },
          ],
        })),
      })
    }
  }

  const isExtractExist = (pMap, extract) => {
    return pMap.mappings.findIndex((mapping) => mapping.extract === extract)
  }

  const isReportExist = (pMap, index, reportType) => {
    return pMap.mappings[index].reports.findIndex(
      (report) => report.report_type === reportType
    )
  }

  if (config_file != null) {
    // Manipulate payload
    const newMapping = {
      ...config_file,
      ...(!config_file.name && {
        name: client_system.systemName,
      }),
      mappings: config_file?.mappings?.map((mapping) => {
        return {
          ...mapping,
          reports: mapping?.reports?.filter((report) => {
            const extractIndex = isExtractExist(payload, mapping?.extract)
            const reportIndex = payload?.mappings[
              extractIndex
            ]?.reports?.findIndex(
              (itm) => itm?.report_type === report?.report_type
            )
            return reportIndex > -1
          }),
        }
      }),
    }

    payload.mappings.forEach((mapping) => {
      // If extract exists check report type else add it to mapping
      const extractIndex = isExtractExist(newMapping, mapping.extract)
      if (extractIndex > -1) {
        mapping.reports.forEach((report) => {
          // If report type exists then replace current with existing one else add new one
          const reportIndex = isReportExist(
            newMapping,
            extractIndex,
            report.report_type
          )
          if (reportIndex > -1) {
            newMapping.mappings[extractIndex].reports[reportIndex] = report
          } else {
            newMapping.mappings[extractIndex].reports.push(report)
          }
        })
      } else {
        newMapping.mappings.push(mapping)
      }
    })

    return newMapping
  } else {
    return payload
  }
}
export const getCustomErpsForEdit = createAsyncThunk<
  Erp[],
  { client: ApolloClient<unknown> },
  { state: { customERP: CustomERP } }
>('custom-erp/getCustomErpsForEdit', async ({ client }) => {
  const { data, errors } = await client.query({
    query: GET_CUSTOM_ERPS_FOR_EDIT,
  })
  if (errors) {
    throw errors
  }
  return data.getCustomErpsForEdit
})

export const getCDMVersion = createAsyncThunk(
  'custom-erp/getCdmVersion',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_CDM_VERSION,
    })
    return response
  }
)

export const createCustomERPSlice = (_initialState: CustomERP) => {
  return createSlice({
    name: 'custom-erp',
    initialState: INITIAL_CUSTOM_ERP_STATE,
    reducers: {
      updateClientSystemReportType: (state, { payload }) => {
        const { extractType, checked, type } = payload
        const client_system = state.client_system
        client_system[extractType] = checked
        client_system.editErpExtractType[extractType] = type
      },
      updateEntityPreviewConfirm: (state, { payload }) => {
        const { action, entityIndex, extractType } = payload
        state.entities[extractType][entityIndex].isPreviewConfirmed = action
      },
      updateClientSystemName: (
        state,
        { payload: { systemName, type, erpId } }
      ) => {
        state.client_system.systemName = systemName
        state.customErpType = type
        state.erpId = erpId
      },
      updateEntity: (state, { payload }) => {
        // update entity with new values
        const { data, entityIndex, extractType } = payload
        const entity = { ...state.entities[extractType][entityIndex], ...data }

        entity.fileUploadDisabled =
          !entity.entity_name ||
          !entity.header_row ||
          entity.encoding_format === '-1' ||
          entity.file_type === '-1'

        if (entity?.isPreviewConfirmed) {
          entity.mandatory_fields = entity.mandatory_fields.map((field) => ({
            ...field,
            count: 0,
          }))
        }

        state.entities[extractType][entityIndex] = entity
      },
      updateExclusionRules: (state, action) => {
        const { exRules, entityIndex, extractType } = action.payload
        if (exRules?.rules?.length > 0) {
          state.entities[extractType][entityIndex].template = {
            ...state.entities[extractType][entityIndex].template,
            exclusion: {
              ...exRules,
            },
          }
        } else {
          delete state.entities[extractType][entityIndex].template.exclusion
        }
      },
      updateLegacyExclusionRules: (state, action) => {
        const { exRules, entityIndex, extractType } = action.payload
        state.entities[extractType][entityIndex].template = {
          ...state.entities[extractType][entityIndex].template,
          exclusion: {
            ...exRules,
          },
        }
      },
      removeEntity: (state, { payload: { entityIndex, extractType } }) => {
        // remove entity from entities array
        const copy = [...state.entities[extractType]]
        copy.splice(entityIndex, 1)
        state.entities[extractType] = copy
      },
      resetCustomErpFieldConfig: (
        state,
        { payload: { entityIndex, extractType = '' } }
      ) => {
        if (state.entities?.[extractType]?.[entityIndex]) {
          const entity = state.entities[extractType][entityIndex]
          const tableRowsDefault = entity?.customErpFieldsDefault || []
          entity.customErpFieldsConfig = tableRowsDefault
          entity.mandatory_fields = entity.mandatory_fields.map((field) => ({
            ...field,
            count: 0,
          }))
          entity.enableClearAll = false
        }
      },
      updateCustomErpFieldConfig: (
        state,
        { payload: { rowIndex, fieldData, entityIndex, extractType = '' } }
      ) => {
        if (state.entities?.[extractType]?.[entityIndex]) {
          const entity = state.entities[extractType][entityIndex]
          const tableRows = entity?.customErpFieldsConfig || []
          const currentFieldData = tableRows[rowIndex]

          tableRows[rowIndex] = {
            ...currentFieldData,
            ...fieldData,
          }

          /* Update Count for Chips  */

          if (fieldData['cdm_field_names']) {
            const removed_fields = _getDifference(
              currentFieldData.cdm_field_names,
              fieldData['cdm_field_names']
            )
            const added_fields = _getDifference(
              fieldData['cdm_field_names'],
              currentFieldData.cdm_field_names
            )

            const mandatory_fields_count = [...entity.mandatory_fields]
            mandatory_fields_count.forEach(({ label }, i) => {
              if (removed_fields.includes(label)) {
                entity.mandatory_fields[i].count -= 1
              }

              if (added_fields.includes(label)) {
                entity.mandatory_fields[i].count += 1
              }
              if(entity.mandatory_fields[i].count < 0){
                entity.mandatory_fields[i].count = 0
              }
            })
          }
          /* Update Count for Chips End */
          entity.enableClearAll = true
        }
      },
      updateCustomErpDateFieldConfig: (
        state,
        { payload: { rowIndex, fieldData, entityIndex, extractType = '' } }
      ) => {
        if (state.entities?.[extractType]?.[entityIndex]) {
          const entity = state.entities[extractType][entityIndex]
          const tableRowsData = entity?.customErpFieldsConfig || []
          rowIndex?.forEach((row) => {
            const currentField = tableRowsData[row]
            tableRowsData[row] = {
              ...currentField,
              ...fieldData,
            }
          })

          /* Update Count for Chips End */
          entity.enableClearAll = true
        }
      },
      setDialogData: (state, { payload }) => {
        state.dialogData = { ...state.dialogData, ...payload }
      },
      resetCustomErpState: (state) => {
        const { customErps, entitiesListItems, cdm_version } = state
        state = {
          ...INITIAL_CUSTOM_ERP_STATE,
          customErps,
          entitiesListItems,
          cdm_version,
        }
        return state
      },
      setEncodingOptions: (state, { payload: { data, message, status } }) => {
        state.encoding_options.data = data
        state.encoding_options.message = message
        state.encoding_options.status = status
      },
      updateSubmitError: (state, { payload }) => {
        state.submitError = payload
      },
      updateConfigError: (state, { payload }) => {
        state.configError = payload
      },
      importCustomErpData: (state, { payload }) => {
        state = { ...state, ...payload }
        return state
      },
      updateCdmEntities: (state, { payload }) => {
        state.cdmEntity = payload
        return state
      },
    },
    extraReducers: (builder) => {
      builder.addCase(submitCustomErp.pending, (state) => {
        state.isSubmitting = true
        state.dialogData.open = false
        state.dialogData.text = ''
        state.validationData = {}
        state.loader = true
      })

      builder.addCase(submitCustomErp.fulfilled, (state, { payload }) => {
        state.isSubmitting = false
        const { valid, yml_string, erpId, auditFirm } =
          payload as ConvertToYmlResponse
        state.yaml_file = yml_string
        state.can_upload_config = valid
        state.erpId = erpId
        state.auditFirm = auditFirm
      })
      builder.addCase(submitCustomErp.rejected, (state, { payload }) => {
        state.validationData = payload
        state.isSubmitting = false
        if (payload?.exists) {
          state.dialogData.open = true
          state.dialogData.text = _composeDialogText(payload)
        } else {
          state.configError = !payload
          state.submitError = payload
        }
        state.loader = false
      })

      builder.addCase(extractFields.pending, (state) => {
        state.loader = true
      })

      builder.addCase(extractFields.fulfilled, (state, { payload, meta }) => {
        const { data } = payload
        const {
          arg: { extractType, entityIndex },
        } = meta
        state.extractData = data.fields
        const entity = state.entities[extractType][entityIndex]
        entity.dfData = data?.dfData || []
        entity.fileError = ''
        entity.suggested_file_name = `${entity.entity_name}.${entity.file_type}`
        if (state.entities[extractType][entityIndex]?.isPreviewConfirmed) {
          entity.encoding_format = 'utf-8'
          entity.customErpFieldsDefault = data?.fields || []
          entity.customErpFieldsConfig = data?.fields || []
          entity.mandatory_fields = entity.mandatory_fields.map((field) => ({
            ...field,
            count: 0,
          }))
          delete entity.exclusion
        }
        state.loader = false
      })

      builder.addCase(extractFields.rejected, (state, { payload, meta }) => {
        const { error } = payload
        const {
          arg: { extractType, entityIndex },
        } = meta
        state.extractData = []
        state.entities[extractType][entityIndex].dfData = []
        state.entities[extractType][entityIndex].customErpFieldsDefault = []
        state.entities[extractType][entityIndex].customErpFieldsConfig = []
        state.entities[extractType][entityIndex].fileError =
          typeof error?.detail === 'string'
            ? error?.detail
            : 'Error While Processing The File.'
        state.loader = false
      })
      builder.addCase(getEntites.pending, (state) => {
        state.entitiesListItems = []
      })
      builder.addCase(getEntites.fulfilled, (state, { payload }) => {
        state.entitiesListItems = payload
      })
      builder.addCase(getEntites.rejected, (state) => {
        state.entitiesListItems = []
      })
      builder.addCase(getCustomErpsForEdit.pending, (state) => {
        state.customErps = []
      })
      builder.addCase(getCustomErpsForEdit.fulfilled, (state, { payload }) => {
        state.customErps = payload
      })
      builder.addCase(getCustomErpsForEdit.rejected, (state) => {
        state.customErps = []
      })
      builder.addCase(addEntity.fulfilled, (state, { payload = [], meta }) => {
        const {
          arg: { entity_name, display_name, extractType },
        } = meta
        const mandatory_fields = payload.map(({ name }) => ({
          label: name,
          value: name,
          count: 0,
        }))
        state.entities[extractType] = [
          ...state.entities[extractType],
          {
            entity_name,
            display_name,
            suggested_file_name: '',
            file_type: 'csv',
            encoding_format: '',
            blank_replacement: false,
            header_row: '',
            isPreviewConfirmed: false,
            global_datetime_format: '',
            fileUploadDisabled: true,
            mandatory_fields,
            customErpFieldsConfig: [],
            dfData: [],
            customErpFieldsDefault: [],
            enableClearAll: false,
            entityType: EditErpTypes.new,
            template: {},
            conform_dates: true,
            size: 0,
            uploaded: 0,
            originalFileName: '',
            fileNameByUser: '',
            inputFileName: entity_name + '.csv',
            state: UPDATE_STATE.NOT_STARTED,
          },
        ]
      })
      builder.addCase(loadConfiguration.pending, (state) => {
        state.loader = true
      })
      builder.addCase(loadConfiguration.fulfilled, (state, { payload }) => {
        // Sets is_legacy depending on whether or not a column property exists.
        // if no column property is available in the loaded ERP, this means it's not legacy
        // A better solution should be implemented here.
        const { exclusion } =
          payload.mappings[0].reports[0].input_files[0]?.template || {}

        if (exclusion?.aggr) {
          state.is_legacy = true
        }

        state.client_system.report = false
        state.client_system.system = false
        state.client_system.editErpExtractType['report'] = EditErpTypes.new
        state.client_system.editErpExtractType['system'] = EditErpTypes.new
        state.entities['report'] = []
        state.entities['system'] = []

        payload.mappings.forEach((mapping) => {
          const extract = mapping.extract.toLowerCase()
          state.client_system[extract] = true
          state.client_system.editErpExtractType[extract] =
            EditErpTypes.existing
          mapping.reports.forEach((report) => {
            const file = report.input_files[0]

            state.entities[extract].push({
              entityType: EditErpTypes.existing,
              entity_name: report.report_type,
              display_name: state.entitiesListItems.find(
                (item) => item.name === report.report_type
              )?.displayName,
              suggested_file_name: file.file_name,
              file_type: file.file_name.split('.').at(-1),
              encoding_format: file.encoding_format,
              blank_replacement: file.blank_replacement,
              header_row: file.template.start_row,
              isPreviewConfirmed: file.template.preview_confirmed,
              global_datetime_format: file.template.global_datetime,
              fileUploadDisabled: false,
              template: {
                ...file.template,
                ...(!!file.template.exclusion && {
                  exclusion: mapExclusion(
                    state.is_legacy,
                    file.template.exclusion
                  ),
                }),
              },
              mandatory_fields: file.mandatory_field_check.map((field) => ({
                label: field.cdm_field_name,
                value: field.cdm_field_name,
                count: field.occurrence_count,
              })),
              customErpFieldsConfig: file.fields,
              customErpFieldsDefault: file.fields,
              enableClearAll: false,
              conform_dates: file?.conform_dates,
              state: file.state,
            })
          })
        })
        state.loader = false
      })
      builder.addCase(loadConfiguration.rejected, (state) => {
        state.loadConfigurationIsRejected = true
        state.loader = false
      })
      builder.addCase(archiveCustomErp.pending, (state) => {
        state.loader = true
      })
      builder.addCase(archiveCustomErp.fulfilled, (state, { payload }) => {
        if (payload?.archiveCustomErp?.success) {
          const { customErps, entitiesListItems } = state
          state = { ...INITIAL_CUSTOM_ERP_STATE, customErps, entitiesListItems }
          state.customErps = customErps.map((ce) => {
            return ce.id === payload?.archiveCustomErp?.erpId
              ? {
                  ...ce,
                  isActive: false,
                }
              : ce
          })
          return state
        }
        state.loader = false
      })
      builder.addCase(archiveCustomErp.rejected, (state) => {
        state.loader = false
      })
      // Get CDM Version
      builder.addCase(getCDMVersion.fulfilled, (state, { payload }) => {
        state.cdm_version = payload.data.cdmVersion
      })
      builder.addCase(getCDMVersion.rejected, (state) => {
        state.cdm_version = ''
      })
    },
  })
}

export const customERPSlice = createCustomERPSlice(INITIAL_CUSTOM_ERP_STATE)

// Action creators are generated for each case reducer function
export const {
  updateClientSystemReportType,
  updateClientSystemName,
  updateEntity,
  updateExclusionRules,
  updateLegacyExclusionRules,
  removeEntity,
  updateCustomErpFieldConfig,
  updateCustomErpDateFieldConfig,
  resetCustomErpFieldConfig,
  setDialogData,
  updateEntityPreviewConfirm,
  resetCustomErpState,
  setEncodingOptions,
  updateSubmitError,
  updateConfigError,
  importCustomErpData,
  updateCdmEntities,
} = customERPSlice.actions

export const customERPReducer = customERPSlice.reducer
