import { DaaOperationComponent } from '@engine-b/shared/components'
import { Grid, InputLabel, TextField, makeStyles } from '@material-ui/core'
import React from 'react'

interface DaaLoadProps {
  file_name: string
  loadFileNameError?: boolean
  onChange: (key: string, value: any) => void
}

const useStyles = makeStyles(() => ({
  customInput: {
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
    },
  },
}))

const DaaLoad = (props: DaaLoadProps) => {
  const { customInput } = useStyles()
  const { file_name, loadFileNameError, onChange } = props

  return (
    <DaaOperationComponent display_name="Output">
      <Grid item>
        <InputLabel style={{ marginBottom: '8px' }}>New File Name</InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          placeholder=""
          color="secondary"
          className={customInput}
          value={file_name}
          onChange={(e: any) => onChange('file_name', e.target.value)}
        />
        {loadFileNameError && (
          <span
            style={{ color: '#CC3333', textAlign: 'left', marginTop: '.25rem' }}
          >
            The output file name is already assigned to another node. Please try
            a different name.
          </span>
        )}
      </Grid>
    </DaaOperationComponent>
  )
}

export default DaaLoad
