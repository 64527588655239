import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core'
import BounceLoader from 'react-spinners/BounceLoader'
import { IESelect } from './IESelect'

export interface Options {
  id: string
  label: string
  isDisabled?: boolean
}

interface Props {
  label: string
  onChange: (optionId: string) => void
  options: Options[]
  placeholderLabel: string
  value?: string | number
  disabled?: boolean
  isAdditionalDropdowns?: boolean
}

export function DropDown({
  label,
  options,
  placeholderLabel,
  value = -1,
  onChange,
  disabled,
  isAdditionalDropdowns,
}: Props) {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  const menuItems =
    options &&
    [
      <MenuItem key={-1} value={-1} disabled={true}>
        {placeholderLabel}
      </MenuItem>,
    ].concat(
      options.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          disabled={option.isDisabled}
        >
          {option.label}
        </MenuItem>
      ))
    )

  return (
    <FormControl>
      <InputLabel id="addRowLabel">
        <Typography variant={'button'}>{label}</Typography>
      </InputLabel>
      {menuItems ? (
        <IESelect
          labelId="addRowLabel"
          id="select"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          isAdditionalDropdowns={isAdditionalDropdowns}
          data-testid="step-3-report-type-dropdown"
        >
          {menuItems}
        </IESelect>
      ) : (
        <BounceLoader color={'#00A9AB'} size={20} />
      )}
    </FormControl>
  )
}
