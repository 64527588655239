import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Description,
  INPUT_WIDTH,
  INPUT_WIDTH_SHORT,
} from '@engine-b/integration-engine/ui/form-components'
import { HELPER_TYPE } from '@engine-b/integration-engine/data/helper-text'
import { Grid, Box } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DatePicker from 'react-date-picker'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  useIEDispatch,
  useIESelector,
  deleteFileValidation,
  CdmEntityCollection,
  addFileMapping,
  updateFileMappingAdditionalDetail,
  removeManualSelections,
  deleteCdmEntitySystemName,
} from '@engine-b/integration-engine/data/state/redux'
import { FileMappingEntry } from '../FileMappingEntry'
import { DropDown } from '@engine-b/integration-engine/ui/form-components'
import IcRemove from '../assets/ic_remove.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      background: '#fff',
      padding: '0.5em 1em',
      borderRadius: '4px',
      marginBottom: '1em',
    },
    reportHeader: {
      position: 'relative',
      paddingBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      flexWrap: 'wrap',
      '&::after': {
        content: '""',
        width: 'calc(100% + 2.8rem)',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 'calc(-1.4rem)',
        borderBottom: '2px solid #44697D33',
      },
    },
    deleteContainer: {
      width: 120,
      position: 'relative',
      height: 40,
      borderRadius: 5,
      background: '#DE4D4D33 0% 0% no-repeat padding-box',
      color: '#DE4D4D',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&::before': {
        content: '""',
        backgroundImage: `url(${IcRemove})`,
        height: '16px',
        width: '16px',
        display: 'block',
        position: 'relative',
        marginRight: 10,
      },
    },
    deleteIcon: {
      width: '22px',
      height: '20px',
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translateY(-50%)',
      backgroundImage: `url(${IcRemove})`,
      backgroundSize: 'cover',
    },
    DateIcon: {
      position: 'absolute',
      right: '2.5px',
      top: '8.5px',
      cursor: 'pointer',
      fill: theme.palette.secondary.main,
    },
    DatePicker: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '8px',
      cursor: 'pointer',
      border: '0.5px solid ' + theme.palette.secondary.light,
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      width: INPUT_WIDTH_SHORT + 'px',
      height: '44px',

      '& .react-date-picker--open': {
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },

      '& .react-date-picker__inputGroup': {
        cursor: 'pointer',
        minWidth: `${INPUT_WIDTH}px`,
      },

      '& .react-date-picker__calendar': {
        width: '230px',
        '& .react-calendar__month-view__days': {
          height: '210px',
        },
      },
      '& .react-date-picker__wrapper': {
        border: 'none',
        padding: '10.5px 14px',
      },
      '& .react-date-picker__inputGroup__input:focus-visible': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:focus': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:active': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:invalid': {
        backgroundColor: 'transparent',
      },
      '& .react-calendar__tile': {
        borderRadius: '20px',
      },
      '& .react-calendar__tile--active': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .react-calendar__tile--active:enabled:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .react-calendar__tile--now': {
        backgroundColor: theme.background.secondary.active,
      },
      '& .react-calendar__tile--now:enabled:hover': {
        backgroundColor: theme.background.secondary.active,
      },
      '& .react-date-picker__inputGroup__input::focus': {
        border: 'none',
      },
    },
    singleRowItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end'
    },
    rowItem: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '10px',
      rowGap: '26px',
    },
  })
)

interface Props {
  preloadFileObj?: any
  cdmEntityCollection: CdmEntityCollection
  selectedCdmEntity: string
  onRemoveRow: (x: string) => void
  source: Object
  name
  index
}

export function FileMappingRow(props: Props) {
  const { locale } = useIntl()
  const { draftRun } = useIESelector((state) => state.runs)
  const {
    preloadFileObj,
    cdmEntityCollection,
    selectedCdmEntity,
    onRemoveRow,
    source,
    name,
    index,
  } = props

  const classes = useStyles()

  const extractType = useIESelector((state) => state.runs.draftRun?.extractType)
  const fileMappings = useIESelector((state) => state.fileMappings)
  const dispatch = useIEDispatch()

  const cdmEntityOptions = Object.values(cdmEntityCollection).map((e) => ({
    id: e.systemName,
    label: e.name,
  }))
  const [isAdditionalDropdowns, setIsAdditionalDropdowns] = useState(false)

  // In case of using the preloader created with additionalDetail
  let additionalDropdowns = null
  additionalDropdowns = draftRun.preloaderInfo?.additionalDetailOptions

  useEffect(() => {
    setIsAdditionalDropdowns(true)
  }, [additionalDropdowns])

  const handleChangeEntity = (cdmEntityId) => {
    const fileNames = cdmEntityCollection[cdmEntityId].inputFiles.map(
      (inputFile) => inputFile.name
    )
    dispatch(addFileMapping(fileNames))
  }

  const updateAdditionalDetail = (key, value) => {
    const originalFileName = cdmEntityCollection[
      selectedCdmEntity
    ].inputFiles.map((inputFile) => inputFile.name)
    dispatch(
      updateFileMappingAdditionalDetail({
        originalFileName,
        reportType: selectedCdmEntity,
        additionalDetail: {
          GeneralLedgerDetail: {
            [key]: value,
          },
        },
      })
    )
  }

  const getSelectedValues = (key) => {
    const originalFileName = cdmEntityCollection[
      selectedCdmEntity
    ].inputFiles.map((inputFile) => inputFile.name)

    return fileMappings[`${selectedCdmEntity}_${originalFileName}`]
      ?.additionalDetail?.GeneralLedgerDetail?.[key]
  }

  const handleDeleteEntity = () => {
    onRemoveRow(selectedCdmEntity)
    dispatch(
      deleteFileValidation({
        extractType: extractType,
        reportType: selectedCdmEntity,
      })
    )
    dispatch(deleteCdmEntitySystemName(selectedCdmEntity))
    dispatch(removeManualSelections(selectedCdmEntity))
  }
  return (
    <div className={classes.row}>
      <div className={classes.reportHeader}>
        <DropDown
          label={'Report type'}
          onChange={handleChangeEntity}
          options={cdmEntityOptions}
          placeholderLabel="Select Report Type"
          value={selectedCdmEntity}
          disabled={true}
        />
        {additionalDropdowns && (
          <Box>
            <div className={classes.singleRowItem}>
              <div className={classes.singleRowItem}>
                <DropDown
                  label={'Period'}
                  onChange={(value) =>
                    updateAdditionalDetail('GeneralLedgerPeriod', value)
                  }
                  options={additionalDropdowns['Period'].map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  placeholderLabel=""
                  value={getSelectedValues('GeneralLedgerPeriod')}
                  disabled={false}
                  isAdditionalDropdowns={isAdditionalDropdowns}
                />
                <DropDown
                  label={'General Ledger Type'}
                  onChange={(value) =>
                    updateAdditionalDetail('GeneralLedgerType', value)
                  }
                  options={additionalDropdowns[
                    'ReportTypes'
                  ]?.GeneralLedgerType.map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  placeholderLabel=""
                  value={getSelectedValues('GeneralLedgerType')}
                  disabled={false}
                  isAdditionalDropdowns={isAdditionalDropdowns}
                />
              </div>
              <Description
                title={'Accounting Dates'}
                description={'Please enter the dates of the audited entity:'}
                helperType={HELPER_TYPE.ACCOUNTING_DATES}
                isAdditionalDropdowns={isAdditionalDropdowns}
              >
                <div className={classes.rowItem}>
                  <Grid item>
                    <div className={'description'}>Start Date:</div>
                    <DatePicker
                      onChange={(value) =>
                        updateAdditionalDetail('GeneralLedgerStartDate', value)
                      }
                      value={
                        getSelectedValues('GeneralLedgerStartDate')
                          ? new Date(getSelectedValues('GeneralLedgerStartDate'))
                          : new Date(draftRun.dataStartDate)
                      }
                      calendarIcon={
                        <DateRangeIcon className={classes.DateIcon} />
                      }
                      clearIcon={null}
                      dayPlaceholder=""
                      monthPlaceholder=""
                      yearPlaceholder=""
                      format={locale === 'en-US' ? 'MM/dd/y' : 'dd/MM/y'}
                      required
                      className={classes.DatePicker}
                    />
                  </Grid>
                  <Grid item>
                    <div className={'description'}>End Date:</div>
                    <DatePicker
                      onChange={(value) =>
                        updateAdditionalDetail('GeneralLedgerEndDate', value)
                      }
                      value={
                        getSelectedValues('GeneralLedgerEndDate')
                          ? new Date(getSelectedValues('GeneralLedgerEndDate'))
                          : new Date(draftRun.dataEndDate)
                      }
                      calendarIcon={
                        <DateRangeIcon className={classes.DateIcon} />
                      }
                      clearIcon={null}
                      dayPlaceholder=""
                      monthPlaceholder=""
                      yearPlaceholder=""
                      format={locale === 'en-US' ? 'MM/dd/y' : 'dd/MM/y'}
                      required
                      className={classes.DatePicker}
                    />
                  </Grid>
                </div>
              </Description>
            </div>
          </Box>
        )}
        <div className={classes.deleteContainer} onClick={handleDeleteEntity}>
          <p style={{ padding: 0 }}>Remove</p>
        </div>
      </div>
      <div>
        {cdmEntityCollection && cdmEntityCollection[selectedCdmEntity]
          ? cdmEntityCollection[selectedCdmEntity].inputFiles.map(
            (inputFile, i) => (
              <FileMappingEntry
                index={index}
                reportIndex={i}
                key={inputFile.name}
                selectedCdmEntity={selectedCdmEntity}
                cdmFileName={inputFile.name}
                reportDetail={cdmEntityCollection[selectedCdmEntity]}
                handleDeleteEntity={handleDeleteEntity}
                source={source}
                preloadFileObj={preloadFileObj}
              />
            )
          )
          : null}
      </div>
    </div>
  )
}
