export const SUPPORTED_FILE_EXTENSIONS = ['.csv', '.CSV', '.json']
export const SUPPORTED_FILE_EXTENSIONS_WITH_EXCEL = ['.csv', '.CSV', '.json', '.xls', '.xlsx']

export const SUPPORTED_MIME_TYPES = [
  '.csv',
  '.json',
  'text/csv',
  'application/json',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export function isValidFileType(file) {
  return (
    hasValidFileExtension(file.name) && SUPPORTED_MIME_TYPES.includes(file.type)
  )
}

function hasValidFileExtension(fileName) {
  const parts = fileName.split('.')
  const extension = `.${parts[parts.length - 1]}`
  return process.env.NX_ENABLE_UPLOAD_EXCEL_FILE === 'true' ? SUPPORTED_FILE_EXTENSIONS_WITH_EXCEL.includes(extension) : SUPPORTED_FILE_EXTENSIONS.includes(extension)
}
