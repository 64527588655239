import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { store } from '@engine-b/integration-engine/data/state/redux'
import { msalConfig } from '@engine-b/integration-engine/features/auth'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App'
import {
  LocalizedRouter,
  appStrings,
  AppLanguage,
} from '@engine-b/integration-engine/features/i18n'

async function main() {
  const msalInstance = new PublicClientApplication(msalConfig)

  ReactDOM.render(
    <React.StrictMode>
      <LocalizedRouter
        RouterComponent={BrowserRouter}
        languages={AppLanguage}
        appStrings={appStrings}
      >
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <App />
          </Provider>
        </MsalProvider>
      </LocalizedRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

main()
