import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    groupingsTitle: {
        fontFamily: 'Arial, sans-serif',
        fontSize: '25px',
        fontWeight: 'bold',
        padding: '20px',
        margin: "20px 0px",
    },
  })
)

interface ReportTitleProps {
    text: string;
    hasLeftBorder?: boolean;
    leftBorderColor?: string;
    background: string;
    color: string;
}

const ReportTitle = ({ text, hasLeftBorder = false, leftBorderColor , background, color }: ReportTitleProps) => {
    const classes = useStyles();

    return (
        <Typography variant="h5" className={classes.groupingsTitle} style={{ 
            color,
            backgroundColor: background,  
            ...(hasLeftBorder ? { borderLeft: `5px solid ${leftBorderColor}` } : {})
        }}>{text}</Typography>
    )
};

export default ReportTitle;