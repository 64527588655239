/**
 *
 * @param path can be string, undefined or string array
 * @param formatMessage from useIntl hook
 * @param locale from useIntl hook
 * @returns Localized string path or path array
 */
export function localizeRoutePath(formatMessage, locale, path?: any) {
  switch (typeof path) {
    case 'undefined':
      return undefined
    case 'object':
      return path.map((key) => `/${locale}` + formatMessage({ id: key }))
    default:
      // eslint-disable-next-line no-case-declarations
      const isFallbackRoute = path === '*'
      return isFallbackRoute ? path : `/${locale}` + formatMessage({ id: path })
  }
}
