import { gql } from '@apollo/client'

export const BEGIN_TABLE_JOINS = gql`
  mutation beginTableJoins(
    $id: String!
  ) {
    beginTableJoins(
        id: $id
    ) {
      id
    }
  }
`
