import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Dialog, EBAutoComplete, EBInput } from '@engine-b/shared/components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { NEW_UI_INPUT_WIDTH } from '@engine-b/integration-engine/ui/form-components'
import { disableField } from './Step'
import {
  EBAutocompleteReason,
  createAuditedEntity,
  createEngagement,
  getEngagements,
  resetEngagementError,
  setIds,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { VALIDATION_MESSAGES } from './constants'

interface CreateClientEngagementProps {
  newClientEngagement: boolean
  setNewClientEngagement: (flag: boolean) => void
  clientName: string
  setClientName: (name: string) => void
  containsInvalidOrDirectoryChars: (name: string) => boolean
  isReservedName: (name: string) => boolean
  disableFields: any[]
  setClients: (value: any) => void
  setEngagementsList: (value: any) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    InputsBlock: {
      width: '100%',
      paddingTop: theme.spacing(4),
      textAlign: 'left',
      '& .combo-box': {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0.16px',
          color: '#44697D',
          verticalAlign: 'center',
          marginBottom: '8px',
          '& > span': {
            fontSize: '20px',
            color: '#DE4D4D',
          },
        },
        '& .MuiTextField-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'hsla(205, 34%, 34%, 1)',
          },
          width: NEW_UI_INPUT_WIDTH + 'px',
          borderRadius: '8px',
          '& .MuiInputBase-root': {
            height: '44px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid ' + theme.palette.secondary.light,
            borderRadius: '8px',
          },
        },
      },
    },
    rowItem: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '32px',
      rowGap: '26px',
    },
  })
)
const CreateClientEngagement = (props: CreateClientEngagementProps) => {
  const {
    newClientEngagement,
    setNewClientEngagement,
    clientName,
    setClientName,
    containsInvalidOrDirectoryChars,
    isReservedName,
    disableFields,
    setClients,
    setEngagementsList,
  } = props
  const { auditedEntities, auditedEntity, engagementError } = useIESelector(
    (state) => state.engagement
  )

  const dispatch = useIEDispatch()
  const classes = useStyles()
  const client = useApolloClient()
  const [engagementName, setEngagementName] = useState('')
  const [newEngagementName, setNewEngagementName] = useState('')
  const [validationErrors, setValidationErrors] = useState({
    createClientError: '',
    createEngagementError: '',
  })
  const addOnAuditedEntityChange = (e: any, value: any, reason: string) => {
    const result =
      reason === EBAutocompleteReason.CLEAR || typeof value === 'string'
        ? null
        : value
    dispatch(setIds({ key: 'auditedEntity', value: result }))
  }

  const resetField = (key: string) => {
    if (key === 'client') setClientName('')
    if (key === 'engagement') setEngagementName('')
  }

  useEffect(() => {
    setNewEngagementName('')
    setValidationErrors({ createClientError: '', createEngagementError: '' })
  }, [auditedEntity, newClientEngagement])

  useEffect(() => {
    if (!newEngagementName) dispatch(resetEngagementError())
  }, [newEngagementName])

  const addNewClientEngagement = async (flag: boolean) => {
    if (!flag) {
      dispatch(setIds({ key: 'auditedEntity', value: null }))
      dispatch(setIds({ key: 'engagement', value: null }))
      setNewClientEngagement(flag)
    } else {
      try {
        await dispatch(createAuditedEntity({ client, name: clientName }))
          .unwrap()
          .then((updateAuditedEntity) => {
            const newAuditedEntityId =
              auditedEntity?.name !== clientName
                ? updateAuditedEntity?.data?.createAuditedEntityByUser.id
                : auditedEntity?.id
            return dispatch(
              createEngagement({
                client,
                name: engagementName,
                auditedEntityId: newAuditedEntityId,
              })
            )
              .unwrap()
              .then((res) => {
                client.cache.reset()
                dispatch(
                  getEngagements({ client, id: newAuditedEntityId })
                ).then(() => {
                  if (
                    updateAuditedEntity.data.createAuditedEntityByUser.success
                  ) {
                    setClients(
                      updateAuditedEntity.data.createAuditedEntityByUser
                    )
                  } else {
                    setClients(auditedEntity)
                  }
                  setEngagementsList(res.createEngagement)
                  setNewClientEngagement(false)
                })
              })
          })
      } catch (error) {
        console.error(error)
      }
    }
  }
  
  const validateClientName=async (value:string) => {
                
    if (containsInvalidOrDirectoryChars(value)) {
      validationErrors.createClientError =
      VALIDATION_MESSAGES.INVALID_CLIENT_NAME_SPECIAL_CHARACTERS
      setValidationErrors({ ...validationErrors })
      resetField('client')
    } else if (isReservedName(value)) {
      validationErrors.createClientError =
      VALIDATION_MESSAGES.INVALID_CLIENT_NAME_RESERVED_KEYWORD
      setValidationErrors({ ...validationErrors })
      resetField('client')
    } else if (value?.trim()?.length > 50) {
      validationErrors.createClientError =
      VALIDATION_MESSAGES.CLIENT_NAME_LENGTH
      setValidationErrors({ ...validationErrors })
      resetField('client')
    } else if (value?.length>0 && value?.trim()?.length===0) {
      validationErrors.createClientError =
      VALIDATION_MESSAGES.CLIENT_NAME_REQUIRED
      setValidationErrors({ ...validationErrors })
      resetField('client')
    } else {
      validationErrors.createClientError = ''
      setValidationErrors({ ...validationErrors })
      setClientName(value)
    }
  }

  const validateEngagementName=async (value:string) => {
              
    if (containsInvalidOrDirectoryChars(value)) {
      validationErrors.createEngagementError =
      VALIDATION_MESSAGES.INVALID_ENGAGEMENT_NAME_SPECIAL_CHARACTERS
      setValidationErrors({ ...validationErrors })
      resetField('engagement')
    } else if (isReservedName(value)) {
      validationErrors.createEngagementError =
      VALIDATION_MESSAGES.INVALID_ENGAGEMENT_NAME_RESERVED_KEYWORD
      setValidationErrors({ ...validationErrors })
      resetField('engagement')
    } else if (value?.trim()?.length > 50) {
      validationErrors.createEngagementError =
      VALIDATION_MESSAGES.ENGAGEMENT_NAME_LENGTH
      setValidationErrors({ ...validationErrors })
      resetField('engagement')
    } else if (value?.length>0 && value?.trim()?.length===0) {
      validationErrors.createEngagementError =
      VALIDATION_MESSAGES.ENGAGEMENT_NAME_REQUIRED
      setValidationErrors({ ...validationErrors })
      resetField('engagement')
    } else {
      validationErrors.createEngagementError = ''
      setValidationErrors({ ...validationErrors })
      setEngagementName(value)
    }
    setNewEngagementName(value)
  }
  


  return (
    <Dialog
      title="Create New Client & Engagement"
      cancelText="Cancel"
      confirmText="Create"
      width="900px"
      height="350px"
      open={newClientEngagement}
      onClose={(flag) => addNewClientEngagement(flag)}
      disabledApply={
        !(clientName.trim()) ||
        !(newEngagementName.trim()) ||
        !!validationErrors.createEngagementError
      }
    >
      <Grid
        container
        direction={'column'}
        spacing={2}
        className={classes.InputsBlock}
      >
        <Grid item xl={12} className={classes.rowItem}>
          <div className="combo-box">
            <label htmlFor="client-name">
              Client Name <span>*</span>
            </label>
            <EBAutoComplete
              freeSolo={true}
              forcePopupIcon={true}
              id="client-name"
              options={auditedEntities}
              getOptionLabel={(option) => option.name || ''}
              value={auditedEntity}
              onChange={addOnAuditedEntityChange}
              onInputChange={(e, value) => validateClientName(value)}
              disabled={disableField(disableFields, 'clientName')}
              size="small"
              fullWidth={false}
              placeholder="Select Client Name"
              error={validationErrors.createClientError !== ''}
              helperText={validationErrors.createClientError}
            />
          </div>
          <div className="combo-box">
            <label htmlFor="engagement-name">
              Engagement Name <span>*</span>
            </label>
            <EBInput
              value={newEngagementName}
              onChange={(e) => {
                const value = e.target.value
                validateEngagementName(value)
                }}
              fullWidth={true}
              height="unset"
              placeholder="Enter Engagement Name"
              error={
                !!validationErrors.createEngagementError || !!engagementError
              }
              helperText={
                validationErrors.createEngagementError || engagementError || ''
              }
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default CreateClientEngagement
