import React, { memo } from 'react'
import { Position } from 'reactflow'
import InputHandle from '../Handles/InputHandle'

const ResultNode = ({ data, selected }) => {
  return (
    <div
      style={{
        background: '#395c74',
        padding: 25,
        border: `2px solid ${selected ? '#00b2a9' : '#395c74'}`,
        borderRadius: `50%`,
        color: '#fff',
        boxShadow: `rgba(219, 219, 219, 0.98) 7px 7px 5px 0`,
      }}
    >
      <InputHandle type="target" position={Position.Left} isConnectable={1} />
      <div>{data?.label}</div>
    </div>
  )
}

export default memo(ResultNode)
