import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ReactComponent as IconInfo } from '../../assets/IconInfo.svg'

const useStyles = makeStyles((theme) => ({
  infoCard: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #44697D33',
    borderRadius: '10px',
    margin: '20px 0 30px',
    display: 'flex',
    padding: '20px',
    '& .info': {
      font: 'normal normal normal 14px/22px Roboto,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",-apple-system',
      letterSpacing: '0.14px',
      color: '#22353F',
      marginLeft: '12px',
    },
  },
}))

const InfoCard = (props) => {
  const { infoCard } = useStyles()

  return (
    <div className={infoCard}>
      <div>
        <IconInfo />
      </div>
      <Typography className="info">
        To create a new ERP map, we need to know whether the files you wish to
        map will be provided as front-end reports (Report extract) or database
        back up files (System extract) or a combination of the two. In each tab,
        select which data comes from which source, for example if your TB is a
        report from the front end and your General Ledger detail report is a
        database back-up, choose 'Trial Balance' in Report Extract and 'GL
        Details' in System Extract.
      </Typography>
    </div>
  )
}

export default InfoCard
