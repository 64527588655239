import React, { ReactNode } from 'react'
import { Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

type Variant = 'contained' | 'outlined' | 'text'
type Case = 'primary' | 'secondary' | 'error' | 'default'

/* eslint-disable-next-line */
export interface EBButtonProps {
  case?: Case
  label?: string
  variant?: Variant
  style?: any
  fullWidth?: boolean
  minWidth?: boolean
  children?: ReactNode
  onClick?: () => void
}

const StyledButton = withStyles({
  root: {
    minWidth: (props: EBButtonProps) => (props.minWidth ? '140px' : 'unset'),
  },
})(Button)

const PrimaryButton = withStyles({
  root: {
    minWidth: (props: EBButtonProps) => (props.minWidth ? '140px' : 'unset'),
    '&:hover': {
      backgroundColor: 'hsla(205,34%,28%,1)',
    }
  },
  outlined: {
    background: 'none',
    border: '1px solid hsla(205,34%,34%,1)',
    color: 'hsla(205,34%,34%,1)',
    '&:hover': {
      backgroundColor: 'hsla(205,34%,34%,.04)',
      boxShadow: 'none',
    },
  },
  text: {
    background: 'none',
    color: 'hsla(205,34%,34%,1)',
    '&:hover': {
      backgroundColor: 'hsla(205,34%,34%,.04)',
      boxShadow: 'none',
    },
  }
})(Button)


const DefaultButton = withStyles({
  root: {
    background: 'hsla(201,17%,61%,1) 0% 0% no-repeat padding-box',
    color: '#fff',
    minWidth: (props: EBButtonProps) => (props.minWidth ? '140px' : 'unset'),
    '&:hover': {
      backgroundColor: 'hsla(201,17%,47%,1)',
    },
  },
  outlined: {
    background: 'none',
    border: '1px solid hsla(201,17%,61%,1)',
    color: 'hsla(201,17%,61%,1)',
    '&:hover': {
      backgroundColor: 'hsla(201,17%,61%, .04)',
      boxShadow: 'none',
    },
  },
  text: {
    background: 'none',
    color: 'hsla(201,17%,61%,1)',
    '&:hover': {
      backgroundColor: 'hsla(201,17%,61%, .04)',
      boxShadow: 'none',
    },
  }
})(Button);

const ErrorButton = withStyles({
  root: {
    background: 'hsla(0,69%,59%,1)',
    border: 0,
    color: 'white',
    boxShadow: 'none',
    minWidth: (props: EBButtonProps) => (props.minWidth ? '140px' : 'unset'),
    '&:hover': {
      backgroundColor: 'hsla(0,69%,45%,1)',
      borderColor: 'hsla(0,69%,59%,1)',
      boxShadow: '0 3px 5px 2px rgba(222, 77, 77, .3)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'hsla(0,69%,59%,1)',
      borderColor: 'rgb(210, 77, 77)',
    },
  },
  text: {
    background: 'none',
    color: 'hsla(0,69%,59%,1)',
    '&:hover': {
      backgroundColor: 'hsla(0,69%,59%, .04)',
      boxShadow: 'none',
    },
  },
  outlined: {
    background: 'none',
    border: '1px solid hsla(0,69%,59%,1)',
    color: 'hsla(0,69%,59%,1)',
    '&:hover': {
      backgroundColor: 'hsla(0,69%,59%, .04)',
      boxShadow: 'none',
    },
  },
})(Button)

export function EBButton(props: EBButtonProps) {
  switch (props.case) {
    case 'primary':
      return (
        <PrimaryButton data-testid="eb-button" color="primary" {...props}>
          {props.label || props.children}
        </PrimaryButton>
      )
      break
    case 'secondary':
      return (
        <StyledButton data-testid="eb-button" color="secondary" {...props}>
          {props.label || props.children}
        </StyledButton>
      )
      break
    case 'error':
      return <ErrorButton {...props}>{props.label || props.children}</ErrorButton>
      break
    default:
      return (
        <DefaultButton data-testid="eb-button" {...props}>
          {props.label || props.children}
        </DefaultButton>
      )
      break
  }
}

EBButton.propTypes = {
  label: PropTypes.string,
  case: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.any,
  fullWidth: PropTypes.bool,
  minWidth: PropTypes.bool,
  onClick: PropTypes.func,
}

EBButton.defaultProps = {
  case: '',
  variant: 'contained',
  style: {
    height: '44px',
  },
  fullWidth: false,
  minWidth: true,
}

export default EBButton
