import {
  DataLakeServiceClient,
  FileReadOptions,
} from '@azure/storage-file-datalake'
import { saveAs } from 'file-saver'

type OnProgressArgs = {
  loadedBytes: number
  totalBytes: number
  percentCompletion: number
}
type OverloadedFileReadOptions = Omit<FileReadOptions, 'onProgress'> & {
  onProgress?: ({
    loadedBytes,
    totalBytes,
    percentCompletion,
  }: OnProgressArgs) => void
}

interface DownloadFileFromAzureContainerProps {
  azureClient: DataLakeServiceClient
  fileSystemId: string
  filePath: string
  fileName?: string
  contentType?: string
  downloadOptions?: OverloadedFileReadOptions
}

export enum BlobContentTypes {
  CSV = 'text/csv',
}

export async function _progressHandler({ loadedBytes, totalBytes, callback }) {
  return callback({
    loadedBytes,
    totalBytes,
    percentCompletion: (loadedBytes / totalBytes) * 100,
  })
}

export async function downloadFileFromAzureContainer(
  azureProps: DownloadFileFromAzureContainerProps
): Promise<boolean> {
  const {
    azureClient,
    fileSystemId,
    filePath,
    fileName = '',
    contentType = '',
    downloadOptions = {},
  } = azureProps
  try {
    const containerClient = azureClient.getFileSystemClient(fileSystemId)
    const fileClient = containerClient.getFileClient(filePath)
    const pathExists = await fileClient.exists()
    if (!pathExists) {
      return false
    }
    const { contentType: blobContentType, contentLength } =
      await fileClient.getProperties()

    let config = downloadOptions
    if (downloadOptions.onProgress) {
      config = {
        ...config,
        onProgress: (progress) => {
          _progressHandler({
            loadedBytes: progress.loadedBytes,
            totalBytes: contentLength,
            callback: downloadOptions.onProgress,
          })
        },
      }
    }
    const downloadResponse = await fileClient.read(null, null, config)
    const downloadBlob = await downloadResponse.contentAsBlob
    const blob = new Blob([downloadBlob], {
      type: contentType || blobContentType,
    })
    const outputFileName = fileName || filePath.split('/').pop()
    saveAs(blob, outputFileName)
    return true
  } catch (error) {
    return false
  }
}
