import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { TextField, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  header:{
    background:'hsla(205,34%,34%,1)',
    color: '#fff',
    display:'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 'large'
  },
  cell:{
    display:'flex',
    justifyContent:'center',
    color: 'hsla(205,34%,34%,1)',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  sortIcon:{
    color:'#fff',
    fontWeight: 'bold',
    fontSize: 'large'
  },
}))

export type EBDataGridProps = {
  data?: any[]
  columns?: { field: string; headerName: string }[]
}

type GridSortModel = {
  field: string
  sort: 'asc' | 'desc'
}[]

export function EBDataGrid(props: EBDataGridProps) {
  const { data = [], columns = [] } = props
  const [sortModel, setSortModel] = useState([] as GridSortModel)
  const [filterText, setFilterText] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [currentPage, setCurrentPage] = useState(1)
  const classes = useStyles()

  useEffect(() => {
    filterAndSortData()
  }, [data, sortModel, filterText, currentPage])

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilterText = event.target.value
    setFilterText(newFilterText)
  }

  const filterAndSortData = () => {
    let filtered = [...data]

    sortModel.forEach((sortItem) => {
      const { field, sort } = sortItem
      filtered.sort((a, b) => {
        if (sort === 'asc') {
          return a[field].toString().localeCompare(b[field].toString())
        } else {
          return b[field].toString().localeCompare(a[field].toString())
        }
      })
    })

    filtered = filtered.filter((row) =>
      columns.some((column) =>
        row[column.field]
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
      )
    )
    setFilteredData(filtered)
  }

  const customColumns: GridColDef[] = columns.map((column) => ({
    field: column.field,
    headerName: column.headerName,
    minWidth: 200,
    minHeight:40,
  }))

  return (
    <div style={{height:'700px'}}>
      <TextField
        type="text"
        placeholder="Filter..."
        value={filterText}
        variant="outlined"
        size="small"
        style={{ margin: '9px' }}
        onChange={handleFilterChange}
      />
      <div style={{ height: 652, width: '100%' }}>
        <DataGrid
          rows={filteredData}
          columns={customColumns.filter((column)=> column.field != 'id')}
          sortModel={sortModel as GridSortModel}
          onSortModelChange={(model) =>
            handleSortModelChange(model as GridSortModel)
          }
          disableColumnFilter={true}
          disableRowSelectionOnClick={true}
          classes={{
            columnHeader: classes.header,
            cell:classes.cell,
            sortIcon:classes.sortIcon,
          }}
          autoPageSize
        />
      </div>
    </div>
  )
}

EBDataGrid.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
    })
  ),
}

EBDataGrid.defaultProps = {
  data: [],
  columns: [],
}

export default EBDataGrid
