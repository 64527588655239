import React, { useEffect } from 'react'
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core'
import {
  Edge,
  Node,
  Option,
  getCdmOutputColumns,
  getOutputColumns,
  handleColumnChange,
  resetNode,
  toggleConfigPanel,
  useIEDispatch,
  useIESelector,
  compareColumns,
  modifyNodeData,
} from '@engine-b/integration-engine/data/state/redux'
import DaaTransform from './DaaTransform'
import DaaExtract from './DaaExtract'
import DaaLoad from './DaaLoad'
import { ReactComponent as MenuIcon } from './assets/Menu.svg'
import { ReactComponent as BackIcon } from './assets/BackButton.svg'
import { useApolloClient } from '@apollo/client'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import {
  AGGR_OPTIONS,
  COMPARE,
  COUNT,
  DUPLICATE_ROW,
  EXTRACT,
  LOAD,
  MULTIPLE_CALCULATION,
  OVERLAP,
} from './constants'
import DataAndAnalyticsDetails from './DataAndAnalyticsDetails'
import { DaaOperationComponent } from '@engine-b/shared/components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'inherit',
    height: '100%',
    borderRadius: '8px',
    padding: '0 1rem 2rem 1rem',
    '&.closed': {
      padding: 0,
    },
    '& .MuiIconButton-root.back svg': {
      width: '20px',
      height: '20px',
      '& path': { fill: '#395B73' },
    },
  },
  header: {
    marginBottom: '10px',
    display: 'block',
    '&.closed': {
      display: 'none',
    },
    overflow: 'auto',
    height: '802px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#eceff1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#395B73',
      borderRadius: '8px',
      height: '300px',
    },
  },
  title: {
    font: 'normal normal 600 14px/24px Arial',
    color: '#222222',
    letterSpacing: '-0.02em',
    display: 'block',
    '&.closed': {
      display: 'none',
    },
  },
  description: {
    font: 'normal normal 400 14px/24px Arial',
    color: '#636363',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    margin: '1rem 0 2rem',
  },
  errors: {
    color: '#37AB3F',
    background: 'rgba(55, 171, 63, .2)',
    padding: '.1rem .7rem',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
  },
}))

// Utility functions to get corresponding source node ids and target node ids
export const getTargetNodeIds = (source: string, edges: Edge[]) => {
  return edges
    .filter((edge) => edge.source === source)
    .map((edge) => edge.target)
}

export const getSourceNodeIds = (target: string, edges: Edge[]) => {
  return edges
    .filter((edge) => edge.target === target)
    .map((edge) => edge.source)
}

const ConfigurationPanel = () => {
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const { instance, inProgress, accounts } = useMsal()

  const {
    nodes,
    edges,
    activeNodeIndx,
    configPanel,
    dataIngestions,
    zeroConfigNodeIndex,
  } = useIESelector((state) => state.digitalAuditAnalytics)
  const { systemName } = useIESelector((state) => state.user)

  const activeNode = nodes[activeNodeIndx]

  const handleCompareNode = (
    id: string,
    columns: Array<Option>,
    targetNode: Node
  ) => {
    const sources = edges.filter((x: Edge) => x.target === id)
    if (sources.length === 2) {
      const n1Columns = nodes.find((n: Node) => n.id === sources[0].source).data
        ?.output_columns
      if (n1Columns?.length) {
        const comp = compareColumns(n1Columns, columns)
        if (comp) {
          dispatch(
            handleColumnChange({
              id,
              key: 'input_columns',
              value: columns,
            })
          )
        } else {
          dispatch(
            modifyNodeData({
              id,
              data: {
                ...targetNode.data,
                colError: 'Columns does not match.',
              },
            })
          )
        }
      }
    }
  }

  /**
   * 1) Reset All descendant nodes
   * 2) Update input columns of childs present on first layer
   * @param activeNodeId
   * @param edges
   * @param columns
   */
  const updateDescendants = (
    activeNodeId: any,
    edges: Edge[],
    columns: Array<Option>
  ) => {
    const targetNodes = getTargetNodeIds(activeNodeId, edges)
    targetNodes.forEach((id) => {
      resetAllDescendantNodes(id)
      const targetNode = nodes.find((n: Node) => n.id === id)
      if ([COMPARE, OVERLAP].includes(targetNode.data.operation_name)) {
        handleCompareNode(id, columns, targetNode)
      } else {
        dispatch(
          handleColumnChange({
            id,
            key: 'input_columns',
            value: columns,
          })
        )
      }
    })
  }

  /**
   * Reset all descendants of given source id
   * Used recursion to achieve this scenario
   * @param id
   */
  const resetAllDescendantNodes = (id: any): any => {
    dispatch(resetNode(id))
    const targetNodes = getTargetNodeIds(id, edges)
    if (targetNodes.length === 0) {
      return 1
    }
    return targetNodes.forEach((id) => resetAllDescendantNodes(id))
  }

  // Node with type 'extract' onChangeHandler function
  const onExtractNodeChange = (key: string, value: any) => {
    dispatch(
      handleColumnChange({
        id: activeNode?.id,
        key,
        value: value,
      })
    )

    if (key === 'ingestion' || value === null) {
      // Reset Descendant nodes
      updateDescendants(activeNode.id, edges, [])
      return
    }
    if (key === 'cdm_file') {
      dispatch(
        getCdmOutputColumns({
          client,
          entityName: value.systemName,
          auditFirm: systemName,
        })
      )
        .unwrap()
        .then((response) => {
          const output_columns = response.data.attributes.map((x: any) => ({
            title: x.name,
            value: x.name,
            data_type: x.dataType,
          }))
          updateDescendants(activeNode.id, edges, output_columns)
        })
    }
  }

  // Node with type 'transform' onChangeHandler function
  const onTransformNodeChange = (key: string, value: any) => {
    dispatch(
      handleColumnChange({
        id: activeNode?.id,
        key,
        value: value,
      })
    )

    // Only generate output columns when all required details of node filled
    getCurrentNodeOutputColumns(key, value)
  }

  const isValidFileName = (filename: string) => {
    // Regular expression to match letters, numbers, underscores, hyphens, and whitespace
    const validFilenameRegex = /^[a-zA-Z0-9_\s-]+$/
    return validFilenameRegex.test(filename)
  }

  // Node with type 'load' onChangeHandler function
  const onLoadNodeChange = (key: string, value: any) => {
    if (isValidFileName(value) || value === '') {
      dispatch(
        handleColumnChange({
          id: activeNode?.id,
          key,
          value: value,
        })
      )
    }
  }

  const getDataSources = (edges: Edge[], nodeId: string) => {
    return edges
      .filter((n: Edge) => n.target === nodeId)
      .sort((a: any, b: any) => {
        if (a?.targetHandle > b?.targetHandle) {
          return 1
        } else if (b?.targetHandle > a?.targetHandle) {
          return -1
        } else {
          return 0
        }
      })
      .map((s: Edge) => s.source)
  }

  const getCurrentNodeOutputColumns = async (key: string, column: any) => {
    /**
     * perform dry-run to get output columns of current operation
     */
    try {
      const sourceNode = nodes.find((node: any) => node.id === activeNode.id)
      if (sourceNode !== undefined) {
        const payload = {
          id: activeNode?.id,
          data: {
            ...activeNode.data,
            ...(key === 'bkd_column' && {
              is_bkd_custom: column?.title === 'Custom Input',
            }),
            ...(column !== null && {
              [key]: column,
            }),
          },
        }

        if (payload?.data?.operation_name === 'compare') {
          payload.data.datasource = getDataSources(edges, activeNode.id)
        }

        if (key === 'aggregation_type') {
          if (column.value === COUNT) payload.data.unique = false
          else delete payload.data?.unique
        }

        const { token } = await asyncTokenLookup({
          instance,
          inProgress,
          accounts,
          tokenRequest: protectedResources.dataIngestionApi,
        })
        dispatch(getOutputColumns({ data: payload, token }))
          .unwrap()
          .then((response) => {
            const output_columns = response.data?.output_columns

            if (
              activeNode?.data?.operation_name === MULTIPLE_CALCULATION &&
              key === 'data'
            ) {
              dispatch(
                handleColumnChange({
                  id: activeNode?.id,
                  key: 'expressionError',
                  value: '',
                })
              )
            }
            updateDescendants(activeNode.id, edges, output_columns)
          })
          .catch((error) => {
            /**
             * Remove Output columns of current node if dry-run fails
             * */
            dispatch(
              handleColumnChange({
                id: activeNode?.id,
                key: 'output_columns',
                value: null,
              })
            )
            // Reset Descendant nodes
            updateDescendants(activeNode.id, edges, [])

            const expressionError = error?.response?.data?.detail[0]?.msg
            if (
              activeNode?.data?.operation_name === MULTIPLE_CALCULATION &&
              key === 'data'
            ) {
              dispatch(
                handleColumnChange({
                  id: activeNode?.id,
                  key: 'expressionError',
                  value: expressionError,
                })
              )
            }
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getOutputColumnsOfZeroConfigOperation = async (index: number) => {
    try {
      const currentNode = nodes[index]
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const data = {
        ...currentNode,
        data: {
          ...currentNode.data,
          input_columns: currentNode.data?.input_columns,
        },
      }
      if (data?.data?.operation_name === OVERLAP) {
        data.data.datasource = getDataSources(edges, data.id)
      }
      dispatch(getOutputColumns({ data, token }))
        .unwrap()
        .then((response) => {
          const output_columns = response.data?.output_columns
          updateDescendants(currentNode.id, edges, output_columns)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (zeroConfigNodeIndex > -1)
      getOutputColumnsOfZeroConfigOperation(zeroConfigNodeIndex)
  }, [zeroConfigNodeIndex])

  /**
   * Reset all extract nodes and their corresponding nodes on dataIngestions change
   */
  useEffect(() => {
    const extractNodes = nodes.filter(
      (node: Node) => node.data.type === EXTRACT
    )
    extractNodes.forEach((node: Node) => {
      dispatch(resetNode(node.id))
      updateDescendants(node.id, edges, [])
    })
  }, [dataIngestions])

  return (
    <Grid item className={`${classes.root} ${configPanel ? ' closed' : ''}`}>
      <Grid item>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <h3 className={`${classes.title} ${configPanel ? ' closed' : ''}`}>
            <span>Settings</span>
          </h3>
          <IconButton
            className={configPanel ? '' : 'back'}
            onClick={() => dispatch(toggleConfigPanel(1))}
          >
            {configPanel ? <MenuIcon /> : <BackIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid
        item
        className={`${classes.header} ${configPanel ? ' closed' : ''}`}
      >
        <DaaOperationComponent display_name="">
          <DataAndAnalyticsDetails />
        </DaaOperationComponent>

        {/* When Active node is extract then this block is visible */}
        {activeNode?.data?.type === EXTRACT && (
          <DaaExtract
            key={activeNode?.id}
            {...activeNode?.data}
            ingestions={dataIngestions}
            onChange={onExtractNodeChange}
          />
        )}

        {/* When Active node is transform then this block is visible */}
        {activeNode?.data?.type === 'transform' &&
          ![DUPLICATE_ROW, OVERLAP].includes(
            activeNode?.data?.operation_name
          ) && (
            <DaaTransform
              key={activeNode?.id}
              {...activeNode?.data}
              options={activeNode?.data?.input_columns || []}
              aggr_options={AGGR_OPTIONS}
              onChange={onTransformNodeChange}
            />
          )}

        {/* When Active node is transform then this block is visible */}
        {activeNode?.data?.type === LOAD && (
          <DaaLoad
            key={activeNode.id}
            {...activeNode.data}
            onChange={onLoadNodeChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ConfigurationPanel
