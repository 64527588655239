import React, { useContext, useState, useEffect, useMemo } from 'react'
import { makeStyles, Theme, Grid, Button } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { SelectedFile, FileUploader } from '@engine-b/shared/components'
import { ReactComponent as IconDelete } from './assets/ic_delete.svg'
import { deleteFileFromAzureContainer } from '@engine-b/integration-engine/data/azure-data-factory'
import {
  getTablesData,
  addFileToTable,
  useIESelector,
  JOINS_UPLOAD_STATE,
  uploadFileToContainer,
  useIEDispatch,
  fetchColumns,
  updateFileUploadState,
  getValidationMessageArr,
  deleteFile,
  resetOperations,
  basicDetailsSelector,
  setVirtualTableColumns,
  FILE_TYPE,
  file as FileInterface,
  toggleTableDelete,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { useMsal } from '@azure/msal-react'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useIntl } from 'react-intl'

/* eslint-disable-next-line */
export interface file {
  fileName: string
  uploadState: JOINS_UPLOAD_STATE
  progress: number
  onDelete: (e: any) => void
}

export interface VirtualTableProps {
  tableName: string
  name: string
  tableId: number
  onDeleteTable: (e: any) => void
}

const useStyles = makeStyles((theme) => ({
  virtualTable: {
    fontFamily: theme.typography.fontFamily,
    height: 'auto',
    marginBottom: 16,
    alignItems: 'center',
  },
  virtualTableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  virtualTableName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    marginTop: '12px',
    fontSize: '1rem',
  },
  deleteTableButton: {
    backgroundColor: red[100],
    color: theme.palette.error.main,
    marginTop: '12px',
    height: '36px',
    marginLeft: 'auto',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: red[100],
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      marginTop: 12,
    },
  },
  deleteTableButtonIcon: {
    width: '40px',
  },
  virtualTableFiles: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
    borderRadius: 8,
    padding: '0 0 0 12px',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  virtualTableItem: {
    paddingRight: 12,
    marginBottom: 12,
    marginTop: 12,
  },
}))

export const VirtualTable = (props: VirtualTableProps) => {
  const azureClient = useContext(AzureClientContext)
  const { instance, inProgress, accounts } = useMsal()
  const classes = useStyles()
  const dispatch = useIEDispatch()

  const [concatError, setConcatError] = useState(false)
  const [prevUploadFileDialog, setPrevUploadFileDialog] = useState(false)
  const [showOnDragMessage, setShowOnDragMessage] = useState(false)
  const [filesQueue, setFilesQueue] = useState<any[]>([])
  const [preUploadedFilePath, setPreUploadedFilePath] = useState<string>('')
  const [disableUpload, setDisableUpload] = useState(false)

  const apolloClient = useApolloClient()
  const { locale } = useIntl()
  const { preUpFiles, isLoading, tables } = useIESelector(
    (state) => state.joins
  )

  const {
    files: virtualTableFilesObj,
    isDeleteDisabled,
    virtual_table_columns,
  } = useIESelector(getTablesData(props.tableName))

  const { engagement } = useIESelector((state) => state.joins)

  const validationMessageArr = useIESelector(
    getValidationMessageArr(props.tableName)
  )

  const { auditedEntity: selectedClientDetails, filename } = useIESelector(
    (state) => basicDetailsSelector(state)
  )

  const virtualTableFiles = Object.values(virtualTableFilesObj)

  const firstFile = virtualTableFiles[0] || ({} as FileInterface)

  const handleFileDelete = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    tableId: number,
    fileId: string
  ) => {
    const fileObj = virtualTableFilesObj[fileId]
    if (fileObj) {
      dispatch(deleteFile({ fileId, tableId, tableName: props.tableName }))
      dispatch(resetOperations(1))
      if (fileObj.fileType === FILE_TYPE.PREUPLOADED) {
        setPreUploadedFilePath(fileObj.path || '')
      }
      if (fileObj.fileType === FILE_TYPE.UPLOADED) {
        const filePath = fileObj?.path || ''
        if (filePath) {
          deleteFileFromAzureContainer({
            azureClient,
            fileSystemId: selectedClientDetails.auditFirm?.systemName || '',
            filePath,
          })
        }
      }
    }
  }

  const runValidation = (tableName: string, fileId: string) => {
    const fileColumns = virtualTableFilesObj[fileId].columns
    const files = tables[props.tableName].files
    const missing_columns = virtual_table_columns.filter(
      (x) => !fileColumns.includes(x)
    )
    const extra_columns = fileColumns.filter(
      (x) => !virtual_table_columns.includes(x)
    )

    let uploadState = JOINS_UPLOAD_STATE.VALIDATING_FILE_COMPLETE
    let fileValidationMessage = ''
    if (missing_columns.length || extra_columns.length) {
      const filtered_missing_columns = missing_columns.filter(
        (x) => x.trim() !== ''
      )
      const filtered_extra_columns = extra_columns.filter(
        (x) => x.trim() !== ''
      )
      if (filtered_missing_columns.length) {
        uploadState = JOINS_UPLOAD_STATE.VALIDATING_FILE_FAILED
        fileValidationMessage = `${filtered_missing_columns
          .map((col) => `${col}`)
          .join(`, `)} ${
          filtered_missing_columns.length > 1 ? 'are missing ' : 'is missing '
        }`
        if (filtered_extra_columns.length) {
          fileValidationMessage += 'and '
        }
      }
      if (filtered_extra_columns.length) {
        uploadState = JOINS_UPLOAD_STATE.VALIDATING_FILE_FAILED
        fileValidationMessage += `${filtered_extra_columns
          .map((col) => `${col}`)
          .join(`, `)} ${
          filtered_extra_columns.length > 1
            ? 'columns are extra'
            : 'column is extra'
        }`
      }
    }
    dispatch(
      updateFileUploadState({
        tableName: tableName,
        fileId,
        uploadState,
        fileValidationMessage: fileValidationMessage,
      })
    )
  }

  const uploadFile = (files: any[], tableName?: string) => {
    let queue: any[] = []
    // No file in Queue, Initiate queue;
    queue = [...Array.from(files)].filter((file) => file.type === 'text/csv')
    if (!disableUpload && queue.length > 0) {
      setFilesQueue([...queue])
      setDisableUpload(true)

      dispatch(
        uploadFileToContainer({
          file: queue[0],
          azureClient,
          tableName,
        })
      )

      queue.splice(0, 1)
      setFilesQueue([...queue])
    }

    setShowOnDragMessage(false)
  }

  const reduceQueueUploadFile = (tableName: string) => {
    let queue: any[] = [...Array.from(filesQueue)]
    if (queue.length > 0) {
      dispatch(
        uploadFileToContainer({
          file: queue[0],
          azureClient,
          tableName,
        })
      )
      queue.splice(0, 1)
      setFilesQueue([...queue])
    } else {
      setDisableUpload(false)
    }
  }

  const triggerFetchColumns = async (tableName: string, fileId: string) => {
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })

    dispatch(fetchColumns({ tableName, fileId, token }))
  }

  const onStateUpdate = (uploadState: JOINS_UPLOAD_STATE, fileId: string) => {
    switch (uploadState) {
      case JOINS_UPLOAD_STATE.IN_PROGRESS:
        dispatch(toggleTableDelete({ tableName: props.tableName, flag: true }))
        break
      case JOINS_UPLOAD_STATE.ERROR:
        {
          const inProgressFiles = virtualTableFiles.findIndex(
            (file) => file.uploadState === JOINS_UPLOAD_STATE.IN_PROGRESS
          )
          if (inProgressFiles < 0) {
            dispatch(
              toggleTableDelete({ tableName: props.tableName, flag: false })
            )
            //even if the current file failed to be uploaded, we should still upload the rest of the queue
            reduceQueueUploadFile(props.tableName)
          }
        }
        break
      case JOINS_UPLOAD_STATE.FETCHING_COLUMNS:
        {
          const inProgressFiles = virtualTableFiles.findIndex(
            (file) => file.uploadState === JOINS_UPLOAD_STATE.IN_PROGRESS
          )
          if (inProgressFiles < 0) {
            dispatch(
              toggleTableDelete({ tableName: props.tableName, flag: false })
            )
            reduceQueueUploadFile(props.tableName)
          }
          triggerFetchColumns(props.tableName, fileId)
        }
        break
      case JOINS_UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE: {
        if (!virtual_table_columns.length) break
        dispatch(
          updateFileUploadState({
            tableName: props.tableName,
            fileId,
            uploadState: JOINS_UPLOAD_STATE.VALIDATING_FILE,
          })
        )
        break
      }
      case JOINS_UPLOAD_STATE.VALIDATING_FILE: {
        runValidation(props.tableName, fileId)
        break
      }
      default:
        break
    }
  }

  const selectFiles = (flag: boolean, files: any) => {
    if (flag) {
      files.map((file: any) => {
        const afileName = file.name.split('/')
        const fileName =
          afileName.length > 0 ? afileName[afileName.length - 1] : afileName[0]
        const aFile = {
          requestId: fileName,
          name: fileName,
          columns: [],
          path: file.name,
          uploadState: JOINS_UPLOAD_STATE.FETCHING_COLUMNS,
          fileType: FILE_TYPE.PREUPLOADED,
          fileSize: file.contentLength,
          uploaded: file.contentLength,
        }
        dispatch(
          addFileToTable({
            tableName: props.tableName,
            file: aFile,
            fileId: aFile.name,
          })
        )
        setPrevUploadFileDialog(false)
      })
    } else {
      setPrevUploadFileDialog(false)
    }
  }

  useEffect(() => {
    if (
      firstFile.uploadState === JOINS_UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE
    ) {
      dispatch(
        setVirtualTableColumns({
          tableName: props.tableName,
          columns: firstFile.columns,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstFile.uploadState])

  useEffect(() => {
    dispatch(
      setVirtualTableColumns({
        tableName: props.tableName,
        columns: firstFile.columns,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstFile.requestId])

  //Whenever Base columns change validation for files needs to triggered again
  useEffect(() => {
    virtualTableFiles.forEach((file) => {
      if (
        file.uploadState === JOINS_UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE ||
        file.uploadState === JOINS_UPLOAD_STATE.VALIDATING_FILE_COMPLETE ||
        file.uploadState === JOINS_UPLOAD_STATE.VALIDATING_FILE_FAILED
      )
        runValidation(props.tableName, file.requestId)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtual_table_columns])

  return (
    <>
      <Grid container className={classes['virtualTable']}>
        <Grid
          item
          xs={12}
          lg={8}
          xl={10}
          className={classes['virtualTableHeader']}
        >
          <span className={classes['virtualTableName']}>
            {props.name} Virtual Table
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          xl={2}
          className={classes['virtualTableHeader']}
        >
          <Button
            variant="contained"
            className={classes['deleteTableButton']}
            onClick={(e) => props.onDeleteTable(e)}
            disabled={isDeleteDisabled}
          >
            <IconDelete className={classes['deleteTableButtonIcon']} />
            Delete Virtual Table
          </Button>
        </Grid>
      </Grid>

      <Grid container className={classes['virtualTableFiles']}>
        {validationMessageArr.length ? (
          <Grid item xs={12} className={classes['virtualTableItem']}>
            <Alert severity="error">
              <AlertTitle>Error - Columns Mismatch</AlertTitle>
              {validationMessageArr.map((message, i) => {
                return <li key={`columns${props.tableName}${i}`}>{message}</li>
              })}
            </Alert>
          </Grid>
        ) : null}

        <Grid item xs={12} className={classes['virtualTableItem']}>
          <FileUploader
            isUploadInfo={false}
            isPreUploadFile={true}
            isUploadedFile={true}
            isDisplayVertical={false}
            enableDragAndDrop={true}
            client={apolloClient}
            locale={locale}
            uploadLabel="Drag & drop your files here or"
            disableUpload={engagement.name === '' || disableUpload}
            onDragOver={(e) => setShowOnDragMessage(true)}
            showOnDragMessage={showOnDragMessage}
            uploadFile={(files, tableName) => uploadFile(files, tableName)}
            handleButtonClick={() => setPrevUploadFileDialog(true)}
            id={props.tableName}
            style={{}}
            open={prevUploadFileDialog}
            onClose={selectFiles}
            dialogTitle={'Previously Uploaded Files'}
            allowMultiple={true}
            preUploadedFilePath={preUploadedFilePath}
            auditedEntity={selectedClientDetails.name}
            engagementId={engagement.name}
            disableExtraction={false}
            disableJoin={false}
            disablePreUpload={false}
          />
          <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
            {virtualTableFiles?.map((file) => (
              <Grid
                item
                xs={12}
                md={4}
                className={classes['virtualTableItem']}
                key={`SelectedFile${file.requestId}`}
              >
                <SelectedFile
                  fileName={file.name}
                  uploadState={file.uploadState}
                  progress={(file.uploaded / file.fileSize) * 100}
                  onDelete={(e) =>
                    handleFileDelete(e, props.tableId, file.requestId)
                  }
                  onStateUpdate={(upload_state) => {
                    onStateUpdate(upload_state, file.requestId)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default VirtualTable
