import { useApolloClient } from '@apollo/client'
import { Path, DataLakeServiceClient } from '@azure/storage-file-datalake'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import JSZip from 'jszip'
import { ReactComponent as IconError } from '../../assets/IconError.svg'
import { Dialog } from '@engine-b/shared/components'
import {
  getDataIngestion,
  getDataIngestionGroupings,
  getErps,
  Run,
  RunStatus,
  useIEDispatch,
  useIESelector,
  STATUS_TITLES,
  STATUS_COLORS,
  removeDataIngestionGrouper,
  clearAllIngestionGroupings,
  beginDataIngestionGroup,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  createStyles,
  makeStyles,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Tooltip,
  LinearProgress,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  GetAppOutlined as GetAppOutlinedIcon,
} from '@material-ui/icons'
import { saveAs } from 'file-saver'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import BounceLoader from 'react-spinners/BounceLoader'
import { useIntl } from 'react-intl'
import { enUS, enGB } from 'date-fns/locale'
import { format, parseISO, differenceInSeconds } from 'date-fns'
import { ReactComponent as IconDownload } from '../../assets/IconDownload.svg'
import { ReactComponent as IconScorecard } from '../../assets/IconScorecard.svg'
import EditSharpIcon from '@material-ui/icons/EditSharp'
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import RetryIcon from '@material-ui/icons/Refresh'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import cuid from 'cuid'

const useStyles = makeStyles((theme) =>
  createStyles({
    formGroup: {
      display: "flex", 
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: "flex-start"
    },
    cardContainer: {
      flex: 1,
      marginTop: '35px',
      '& .quick-grouper': {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      },
    },
    cardStyles: {
      padding: '40px 50px',
      borderRadius: '8px',
      flex: 1,
    },
    fontStyle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.3px',
    },
    valueText: {
      fontFamily: theme.typography.fontFamily,
      '& span': {
        color: '#44697D',
      },
    },
    PipelineRunReport: {
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: '#F0FBFA 0% 0% no-repeat padding-box',
    },
    dataContainer: {
      marginBottom: '18px',
    },
    reportDataContainer: {
      padding: '16px 0 0 16px',
    },
    uploadFileDataContainer: {
      paddingLeft: '16px',
    },
    dataHeaders: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      marginTop: 10,
      letterSpacing: '0.18px',
      marginBottom: '12px',
      color: '#44697D',
    },
    dataValues: {
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.22px',
      color: '#22353F',
      '& > li': {
        padding: 0,
      },
    },
    backButtonStyles: {
      minWidth: 'unset',
      marginRight: '10px',
      width: 'unset',
    },
    importDateContainer: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
      },
    },
    dataIngestionID: {
      [theme.breakpoints.up('md')]: {
        marginTop: '10px',
      },
    },
    summaryActions: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    actionButtons: {
      margin: '0 10px 10px 0',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      minWidth: '135px',
      height: '34px',
      borderRadius: '5px',
    },
    groupingActionButtons: {
      margin: '0 10px 10px 0',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      minWidth: '60px',
      height: '34px',
      borderRadius: '5px',
    },
    originalFileButton: {
      marginBottom: '10px',
      '& button': {
        marginBottom: 0,
      },
    },
    tableHead: {
      backgroundColor: '#afd683',
      justifyContent: 'center',
    },
    table: {
      '& .MuiTableCell-root': {
        textAlign: 'center',
      },
    },
    refreshIcon: {
      marginLeft: 'auto',
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid #CBCBCB',
      height: '40px',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#395C74',
      },
    },

  })
)

const XS_GRID_SIZE = 12
const MD_GRID_SIZE = 6
const LG_GRID_SIZE = 3
const DOWNLOAD_DQS_ENDPOINT = `${process.env.NX_CUSTOM_MAPPER_API_URL}/dqs/scorecard`

const getFileName = (path: string): string => {
  const pathParts = path.split('/')
  return pathParts[pathParts.length - 1]
}

export const PipelineRunReport = () => {
  const classes = useStyles()
  const { locale, formatMessage } = useIntl()
  const urlParams = useParams<{ runId: string }>()
  const azureClient = useContext(AzureClientContext)
  const client = useApolloClient()
  const dispatch = useIEDispatch()
  const history = useHistory()
  const erps = useIESelector((state) => state.erps)
  const { runs, run, loadingState, dataIngestionGroupings } = useIESelector(
    (state) => state.runs
  )
  const { groupingLoadingState } = useIESelector((state) => state.customGrouper)
  const [errorFilePaths, setErrorFilePaths] = useState<Path[]>([])
  const [isDeleteGroupingModelOpen, setIsDeleteGroupingModalOpen] =
    useState<boolean>(false)
  const [groupinIdToDelete, setGroupingIdToDelete] = useState<string | null>(
    null
  )
  const [fileList, setFileList] = useState<Path[]>([])
  const [loading, setLoading] = useState({
    output_file: false,
    original_file: false,
    scorecard: false,
    grouping_output: {},
    erpConfig_file: false,
    mapping_file: false,
  })
  const [tooltipMsg, setTooltipMsg] = useState('')
  const [isOriginalFileClick, setIsOriginalFileClick] = useState<boolean>(false)

  useEffect(() => {
    if (urlParams.runId) {
      dispatch(getDataIngestion({ client, id: urlParams.runId }))
    }
    dispatch(
      getDataIngestionGroupings({
        client: client,
        id: urlParams.runId,
        type: 'ingestion',
      })
    )
  }, [client, dispatch, runs])

  useEffect(() => {
    // TODO Make this a thunk
    async function ListFilesAtInputPath(container: Run['container']) {
      const fileSystemClient = azureClient.getFileSystemClient(
        container.fileSystemId
      )
      const filesAtInput = fileSystemClient.listPaths({
        path: container.inputPath,
      })
      const filesUploaded = []
      for await (const uploadedFile of filesAtInput) {
        const segmentStr = uploadedFile.name
        const segmentArray = segmentStr.split('_')
        const fileName = segmentArray.pop()
        filesUploaded.push(fileName)
      }
      setFileList(filesUploaded)
    }

    run && ListFilesAtInputPath(run.container)
  }, [azureClient, run])

  const isInErrorState = (status: RunStatus): boolean =>
    status === RunStatus.ERROR ||
    status === RunStatus.COMPLETED_WITH_ERRORS ||
    status === RunStatus.FAILED

  useEffect(() => {
    // TODO Make this a thunk
    async function ListFilesInErrorDirectory(container: Run['container']) {
      const fileSystemClient = azureClient.getFileSystemClient(
        container.fileSystemId
      )

      const errorPaths = fileSystemClient.listPaths({
        path: container.errorsPath,
        recursive: true,
      })
      for await (const path of errorPaths) {
        if (!path.isDirectory) {
          setErrorFilePaths([path])
        }
      }
    }

    run &&
      isInErrorState(run.uiState) &&
      ListFilesInErrorDirectory(run.container)
  }, [run, azureClient])

  useEffect(() => {
    if (!erps || Object.keys(erps).length === 0) {
      dispatch(getErps(client))
    }
  }, [erps, dispatch, client])

  const downloadErrorFile = useCallback(
    async function downloadErrorFile(filePath: string, fileName: string) {
      const containerClient = azureClient.getFileSystemClient(
        run.container.fileSystemId
      )
      const fileClient = containerClient.getFileClient(filePath)
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      const blob = new Blob([downloadBlob], { type: 'application/text' })

      saveAs(blob, fileName)
    },
    [run, azureClient]
  )

  const removeGrouping = (id: string) => {
    dispatch(removeDataIngestionGrouper({ client: client, id }))
    refreshGroupings()
  }

  const downloadOutPutFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    ingestionId: string
  ) => {
    setLoading((prev) => ({ ...prev, output_file: true }))
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const FILE_NAME = 'cdm.zip'
    const fileClient = containerClient.getFileClient(
      `${diContainer.sharePath}/zip/${FILE_NAME}`
    )
    const downloadResponse = await fileClient.read()
    const downloadBlob = await downloadResponse.contentAsBlob
    const blob = new Blob([downloadBlob], { type: 'application/zip' })
    saveAs(blob, `cdm_for_${ingestionId}.zip`)
    setLoading((prev) => ({ ...prev, output_file: false }))
  }

  const downloadOriginalFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    ingestionId: string
  ) => {
    setLoading((prev) => ({ ...prev, original_file: true }))
    try {
      const containerClient = azureClient.getFileSystemClient(
        diContainer.fileSystemId
      )
      const iter = containerClient.listPaths({
        path: `${diContainer.inputPath}`,
      })
      const zip = new JSZip()

      for await (const pathItem of iter) {
        if (
          !pathItem.isDirectory &&
          pathItem.name.split('.').pop().toLowerCase() === 'csv'
        ) {
          const fileClient = containerClient.getFileClient(pathItem.name)
          const downloadResponse = await fileClient.read()
          const downloadBlob = await downloadResponse.contentAsBlob
          zip.file(pathItem.name.split('_').pop(), downloadBlob)
        }
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' })

      isOriginalFileClick && saveAs(zipBlob, `original_for_${ingestionId}.zip`)
    } catch (error) {
      setTooltipMsg('Original file has been moved or removed')
    }

    setLoading((prev) => ({ ...prev, original_file: false }))
  }

  const downloadERPConfigFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    ingestionId: string
  ) => {
    setLoading((prev) => ({ ...prev, erpConfig_file: true }))
    const erp = erps[run.erpId]
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const EXTENSION_NAME = '.yml'
    const fileClient = containerClient.getFileClient(
      `${diContainer.inputPath}/${run.erpId}${EXTENSION_NAME}`
    )
    const downloadResponse = await fileClient.read()
    const downloadBlob = await downloadResponse.contentAsBlob

    saveAs(downloadBlob, `config_${ingestionId}.yml`)
    setLoading((prev) => ({ ...prev, erpConfig_file: false }))
  }

  const downloadMappingFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    ingestionId: string
  ) => {
    setLoading((prev) => ({ ...prev, mapping_file: true }))
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const FILE_NAME = 'mapping_file.json'
    const fileClient = containerClient.getFileClient(
      `${diContainer.inputPath}/${FILE_NAME}`
    )
    const downloadResponse = await fileClient.read()
    const downloadBlob = await downloadResponse.contentAsBlob

    saveAs(downloadBlob, `mapping_${ingestionId}.json`)
    setLoading((prev) => ({ ...prev, mapping_file: false }))
  }

  useEffect(() => {
    if (run?.container) {
      setIsOriginalFileClick(true)
    }
  }, [run])

  const downloadPdf = async () => {
    setLoading((prev) => ({ ...prev, scorecard: true }))
    const requestBody = {
      entity_name: run.auditedEntityName,
      erp_name: erps[run.erpId]?.name,
      extract_type: run.cdmEntities[0].cdmEntity.extractType,
      import_date: run.initiatedAt,
      // Undefined - Maybe missing because ingestion is still in-process
      container_path: `${run.container.fileSystemId}/${run.container.sharePath}`,
      entities: [
        ...run.cdmEntities.map((cdm, index) => ({
          entity_type: cdm.cdmEntity.systemName,
          entity_name: cdm.cdmEntity.name,
          files_uploaded: run.uploadedFiles
            .map((fileName) => fileName?.fileNameByUser)
            .filter((_, i) => index === i),
        })),
      ],
    }
    try {
      const response = await axios.post(DOWNLOAD_DQS_ENDPOINT, requestBody, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.status === StatusCodes.OK) {
        const FILE_NAME = `${run.name}_DQS.zip`
        const downloadBlob = response.data
        const blob = new Blob([downloadBlob], {
          type: 'application/octet-stream',
        })
        saveAs(blob, FILE_NAME)
        setLoading((prev) => ({ ...prev, scorecard: false }))
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, scorecard: false }))
    }
  }

  const downloadGroupingOutput = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    groupingId: string
  ) => {
    setLoading((prev) => ({
      ...prev,
      grouping_output: { ...prev.grouping_output, [groupingId]: true },
    }))
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    try {
      const zip = new JSZip()
      const iter = containerClient.listPaths({
        path: `${diContainer.sharePath}/grouper/${groupingId}`,
      })
      for await (const pathItem of iter) {
        if (
          !pathItem.isDirectory &&
          pathItem.name.split('.').pop().toLowerCase() === 'csv'
        ) {
          const fileClient = containerClient.getFileClient(pathItem.name)
          const downloadResponse = await fileClient.read()
          const downloadBlob = await downloadResponse.contentAsBlob
          zip.file(pathItem.name.split("/").pop(), downloadBlob)
        }
      }
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      saveAs(zipBlob, `grouper_${groupingId}.zip`)
    }
    catch (error) {
      const FILE_NAME = `trialBalance_groupped_${groupingId.trim()}.csv`	    
      const fileClient = containerClient.getFileClient(	     
        `${diContainer.sharePath}/grouper/${FILE_NAME}`	  
      )	   
      const downloadResponse = await fileClient.read()	
      const downloadBlob = await downloadResponse.contentAsBlob	   
      saveAs(downloadBlob, `${groupingId.trim()}.csv`)	
    }
    
    setLoading((prev) => ({
      ...prev,
      grouping_output: { ...prev.grouping_output, [groupingId]: false },
    }))
  }

  const handleGroupingDelete = (v) => {
    if (v) {
      if (groupinIdToDelete) {
        removeGrouping(groupinIdToDelete)
      }
    }
    setIsDeleteGroupingModalOpen(false)
    setGroupingIdToDelete(null)
  }

  const refreshGroupings = () => {
    client.cache.reset()
    dispatch(clearAllIngestionGroupings())
    dispatch(
      getDataIngestionGroupings({
        client: client,
        id: urlParams.runId,
        type: 'ingestion',
      })
    )
  }

  const transformFileName = (name: string) => {
    const nameArr = name?.match(/\d{6,8}_\d{2,4}_(.*)/)
    return nameArr?.length > 1 ? nameArr[1] : name
  }

  const calculateRunTime = (start: string, end: string) => {
    try {
      const seconds = differenceInSeconds(parseISO(end), parseISO(start))
      return `${Math.floor(seconds / 60)}m ${seconds % 60}s`
    } catch (e) {
      return 'N/A'
    }
  }

  const reTriggerGrouping = (grouperId: string) => {
    dispatch(
      beginDataIngestionGroup({ client, dataIngestionGrouperId: grouperId })
    )
      .unwrap()
      .then(() => {
        refreshGroupings()
      })
  }

  const handleRunGrouping = () => () => {
    history.push(`/${locale}/custom-grouping/ingestion/${run.id}`)
  }

  return run ? (
    <section className={classes.PipelineRunReport}>
      <Grid container direction="column">
        <Grid item container>
          <Grid item>
            <Button
              onClick={() =>
                history.push(
                  localizeRouteKey(formatMessage, locale, AppRoute.Status)
                )
              }
              size="medium"
              color="primary"
              startIcon={<ArrowBackOutlinedIcon />}
              className={classes.backButtonStyles}
            ></Button>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography
                  className={classes.fontStyle}
                  style={{ marginRight: '5px', display: 'inline-block' }}
                  variant="h5"
                >
                  {run.auditedEntityName}
                </Typography>
                <Typography
                  className={classes.fontStyle}
                  variant="h5"
                  style={{ display: 'inline-block', marginRight: '5px' }}
                >
                  -
                </Typography>
                <Typography
                  className={classes.fontStyle}
                  color="secondary"
                  variant="h5"
                  style={{ display: 'inline-block' }}
                >
                  Data Ingestion
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.summaryActions}>
                {RunStatus.COMPLETED === run.uiState && (
                  <>
                    {run?.groupId && (
                      <Button
                        className={classes.actionButtons}
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/${locale}/grouping/${run?.id}`}
                      >
                        Groupings Data Score Card
                      </Button>
                    )}

                    <Button
                      disabled={loading['scorecard']}
                      className={classes.actionButtons}
                      startIcon={<IconScorecard />}
                      color="primary"
                      variant="contained"
                      onClick={() => downloadPdf()}
                    >
                      Scorecard
                    </Button>
                    <Button
                      className={classes.actionButtons}
                      color="secondary"
                      variant="contained"
                      startIcon={<IconDownload />}
                      onClick={() =>
                        downloadOutPutFile(azureClient, run.container, run.id)
                      }
                      disabled={loading['output_file']}
                    >
                      Output File
                    </Button>
                  </>
                )}
                <Tooltip placement="bottom-end" title={tooltipMsg}>
                  <div className={classes.originalFileButton}>
                    <Button
                      className={classes.actionButtons}
                      color="secondary"
                      variant="contained"
                      startIcon={<IconDownload />}
                      onClick={() =>
                        downloadOriginalFile(azureClient, run.container, run.id)
                      }
                      disabled={loading['original_file'] || !!tooltipMsg}
                    >
                      {run.uploadedFiles.length > 1
                        ? 'Original Files'
                        : 'Original File'}
                    </Button>
                  </div>
                </Tooltip>
                <Button
                  className={classes.actionButtons}
                  color="secondary"
                  variant="contained"
                  startIcon={<IconDownload />}
                  onClick={() =>
                    downloadERPConfigFile(azureClient, run.container, run.id)
                  }
                  disabled={loading['erpConfig_file']}
                >
                  Config file
                </Button>
                <Button
                  className={classes.actionButtons}
                  color="secondary"
                  variant="contained"
                  startIcon={<IconDownload />}
                  onClick={() =>
                    downloadMappingFile(azureClient, run.container, run.id)
                  }
                  disabled={loading['mapping_file']}
                >
                  Mapping file
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginTop: '10px' }}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.valueText}>
                  Status :{' '}
                  <span style={{ color: STATUS_COLORS[run.uiState] }}>
                    {STATUS_TITLES[run.uiState]}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.importDateContainer}>
                <Typography className={classes.valueText}>
                  Date &amp; Time of Import :
                  <span style={{ marginLeft: '10px' }}>
                    {run.initiatedAt
                      ? format(parseISO(run.initiatedAt), 'P, h:mm:ss a', {
                          locale: locale === 'en-US' ? enUS : enGB,
                        })
                      : '--'}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.dataIngestionID}>
                <Typography className={classes.valueText}>
                  Data Ingestion ID : <span>{run.id}</span>
                </Typography>
              </Grid>
              {run.completeAt && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.importDateContainer}
                >
                  <Typography className={classes.valueText}>
                    Run Time:{' '}
                    {calculateRunTime(run.initiatedAt, run.completeAt)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className={classes.cardContainer}>
          <Card variant="outlined" elevation={0} className={classes.cardStyles}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Client Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run?.auditedEntityName || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Engagement Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run?.engagement?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Ingestion Name :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  ERP System :
                </Typography>
                <Typography className={classes.dataValues}>
                  {erps[run.erpId]?.name || '--'}
                </Typography>
              </Grid>
              <Grid
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.reportDataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Report Type and Mapping :
                </Typography>
              </Grid>
              <Grid
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={9}
                className={classes.reportDataContainer}
              >
                {run.uploadedFiles.length > 1 ? (
                  <Typography className={classes.dataHeaders}>
                    Files Upload :
                  </Typography>
                ) : (
                  <Typography className={classes.dataHeaders}>
                    File Upload :
                  </Typography>
                )}
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={12}
                className={classes.dataContainer}
                style={{ paddingBottom: '16px' }}
              >
                {run.cdmEntities
                  ? run.cdmEntities
                      .map((item) => ({ ...item, id: cuid() }))
                      .map((entry, i) => (
                        <ListItem
                          key={entry.id}
                          style={{
                            display: 'flex',
                            alignItems: 'start',
                            padding: '0px',
                            lineHeight: 1.5,
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            md={6}
                            lg={3}
                            className={classes.uploadFileDataContainer}
                          >
                            <Typography>{entry?.cdmEntity?.name}</Typography>
                          </Grid>
                          {run.uploadedFiles.length > 0 ? (
                            <Grid
                              item
                              xs={6}
                              md={6}
                              lg={9}
                              className={classes.uploadFileDataContainer}
                            >
                              <Typography>
                                {transformFileName(
                                  run.uploadedFiles[i]?.fileNameByUser
                                    .split(`_${urlParams.runId}_`)
                                    .pop()
                                )}
                              </Typography>
                            </Grid>
                          ) : (
                            '--'
                          )}
                        </ListItem>
                      ))
                  : '--'}
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  Start Date :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run.dataStartDate
                    ? format(parseISO(run.dataStartDate), 'P', {
                      locale: locale === 'en-US' ? enUS : enGB,
                    })
                    : '--'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={XS_GRID_SIZE}
                md={MD_GRID_SIZE}
                lg={LG_GRID_SIZE}
                className={classes.dataContainer}
              >
                <Typography className={classes.dataHeaders}>
                  End Date :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run.dataEndDate
                    ? format(parseISO(run.dataEndDate), 'P', {
                      locale: locale === 'en-US' ? enUS : enGB,
                    })
                    : '--'}
                </Typography>
              </Grid>
              <Grid item xs={XS_GRID_SIZE} md={MD_GRID_SIZE} lg={LG_GRID_SIZE}>
                <Typography className={classes.dataHeaders}>
                  Created By :
                </Typography>
                <Typography className={classes.dataValues}>
                  {run.createdBy}
                </Typography>
              </Grid>
              {
              process.env.NX_ENABLE_QUICK_GROUPING === 'true' &&
                (
                  run.cdmEntitySystemNames.includes('trialBalance') ||
                  run.cdmEntitySystemNames.includes('glDetail')
                ) 
                &&
                !isInErrorState(run.uiState) && (
                  <Grid item container>
                    <div className="quick-grouper">
                      <Typography
                        className={classes.dataHeaders}
                        style={{ fontSize: 'x-large', marginRight: '10px' }}
                      >
                        Grouping Information :
                      </Typography>
                      <Button
                        className={classes.actionButtons}
                        color="primary"
                        variant="contained"
                        // component={Link}
                        onClick={
                          handleRunGrouping()
                        }
                      >
                        Run Grouping
                      </Button>
                      <button
                        className={classes.refreshIcon}
                        onClick={async () => {
                          refreshGroupings()
                        }}
                      >
                        <RefreshIcon />
                      </button>
                    </div>
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell>Grouping ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(loadingState === 'loading' ||
                            groupingLoadingState) && (
                            <TableRow>
                              <TableCell
                                colSpan={12}
                                style={{ padding: '0px' }}
                              >
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          )}
                          {!groupingLoadingState &&
                            dataIngestionGroupings.map((x) => (
                              <TableRow>
                                <TableCell>{x.id}</TableCell>
                                <TableCell>{x.name}</TableCell>
                                <TableCell>
                                  {x.startDate
                                    ? format(
                                        parseISO(x.startDate),
                                        'P, h:mm:ss a',
                                        {
                                          locale:
                                            locale === 'en-US' ? enUS : enGB,
                                        }
                                      )
                                    : '--'}
                                </TableCell>
                                <TableCell>
                                  {x.endDate
                                    ? format(
                                        parseISO(x.endDate),
                                        'P, h:mm:ss a',
                                        {
                                          locale:
                                            locale === 'en-US' ? enUS : enGB,
                                        }
                                      )
                                    : '--'}
                                </TableCell>
                                <TableCell>{x.createdBy}</TableCell>
                                <TableCell>
                                  <span
                                    style={{ color: STATUS_COLORS[x.status] }}
                                  >
                                    {STATUS_TITLES[x.status]}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <Tooltip title="Edit Grouping">
                                    <Button
                                      className={classes.groupingActionButtons}
                                      color="primary"
                                      variant="contained"
                                      component={Link}
                                      to={`/${locale}/custom-grouping/grouping/${x.id}`}
                                    >
                                      <EditSharpIcon />
                                    </Button>
                                  </Tooltip>
                                  {x.status === 'COMPLETED' && (
                                    <>
                                      <Tooltip title="Download Output">
                                        <Button
                                          className={
                                            classes.groupingActionButtons
                                          }
                                          color="secondary"
                                          variant="contained"
                                          onClick={() =>
                                            downloadGroupingOutput(
                                              azureClient,
                                              run.container,
                                              x.id
                                            )
                                          }
                                          disabled={
                                            loading.grouping_output[x.id]
                                          }
                                        >
                                          <IconDownload />
                                        </Button>
                                      </Tooltip>
                                      <Tooltip title="View Data Quality Scorecard">
                                        <Button
                                          disabled={loading['scorecard']}
                                          className={
                                            classes.groupingActionButtons
                                          }
                                          color="primary"
                                          variant="contained"
                                          onClick={() =>
                                            history.push(
                                              `/${locale}/grouping/${x.id}`
                                            )
                                          }
                                        >
                                          <IconScorecard />
                                        </Button>
                                      </Tooltip>
                                    </>
                                  )}
                                  {x?.status === 'FAILED' && (
                                    <Tooltip title="Retry Grouping">
                                      <Button
                                        className={
                                          classes.groupingActionButtons
                                        }
                                        variant="contained"
                                        style={{
                                          backgroundColor: '#ecc83c',
                                          color: 'white',
                                        }}
                                        onClick={() => reTriggerGrouping(x.id)}
                                        disabled={loading.grouping_output[x.id]}
                                      >
                                        <RetryIcon />
                                      </Button>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Delete Grouping">
                                    <Button
                                      className={classes.groupingActionButtons}
                                      onClick={() => {
                                        // Setting Id of Grouping in state so that if user confirm from popup we will trigger Delete Grouping Action
                                        setGroupingIdToDelete(x.id)
                                        setIsDeleteGroupingModalOpen(true)
                                      }}
                                      color="secondary"
                                      variant="contained"
                                    >
                                      <DeleteSharpIcon />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              {errorFilePaths.length >= 1 && (
                <Grid item xs={XS_GRID_SIZE} md={12} lg={12}>
                  <Grid item>
                    <Typography
                      className={classes.dataHeaders}
                      style={{ fontSize: 'x-large', marginBottom: '20px' }}
                    >
                      Steps To Resolve Error :
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      1) Check file for errors
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      2) Re-upload file for ingestion
                    </Typography>
                    <Typography className={classes.dataHeaders}>
                      Download Error files: {errorFilePaths.length} file with
                      errors
                    </Typography>
                    <Grid
                      item
                      xs={XS_GRID_SIZE}
                      md={MD_GRID_SIZE}
                      lg={LG_GRID_SIZE}
                    >
                      <List>
                        {errorFilePaths.map((path: Path, i) => {
                          // TODO make this a component
                          const errorFileName = getFileName(path.name)
                          return (
                            <ListItem key={i}>
                              <ListItemText
                                primary={errorFileName}
                                className={classes.dataValues}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  onClick={() =>
                                    downloadErrorFile(path.name, errorFileName)
                                  }
                                  color="primary"
                                >
                                  <GetAppOutlinedIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        <Dialog
          title=""
          cancelText="No"
          confirmText="Yes"
          open={isDeleteGroupingModelOpen}
          onClose={handleGroupingDelete}
          width="674px"
          height="350px"
        >
          <>
            <IconError />
            <br />
            <Typography className="dialog-title">
              Are you sure You want to Delete this Group?
            </Typography>
          </>
        </Dialog>
      </Grid>
    </section>
  ) : (
    <BounceLoader color={'#EC6408'} loading={runs.length === 0} size={20} />
  )
}

export default PipelineRunReport
