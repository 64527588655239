import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import { TraverseButtonsLayout } from './TraverseButtonsLayout'

interface TraverseButtonsProps {
  handleBack?: () => void
  isBackDisabled?: boolean
  handleNext?: () => void
  isNextDisabled?: boolean
}

export function TraverseButtons(props: TraverseButtonsProps) {
  const { handleBack, isBackDisabled, handleNext, isNextDisabled } = props
  return (
    <TraverseButtonsLayout>
      {!!handleBack && (
        <Grid item xs={2}>
          <BackButton handleBack={handleBack} disabled={isBackDisabled} />
        </Grid>
      )}
      <Grid item xs={8}>
        &nbsp;
      </Grid>
      {!!handleNext && (
        <Grid item xs={2}>
          <NextButton handleNext={handleNext} disabled={isNextDisabled} />
        </Grid>
      )}
    </TraverseButtonsLayout>
  )
}

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      color: '#fff',
    },
    width: '160px',
    height: '44px',
    '& span': {
      font: 'normal normal bold 16px/24px Arial',
    },
  },
})(MuiButton)

export function BackButton({ handleBack, disabled = false }) {
  return (
    <Button
      variant="outlined"
      color="primary"
      size="medium"
      onClick={handleBack}
      disabled={disabled}
    >
      Back
    </Button>
  )
}

export function NextButton({ handleNext, disabled = false }) {
  return (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={handleNext}
      data-testid="nextButton"
      disabled={disabled}
    >
      Next
    </Button>
  )
}
