import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReactComponent as IconClose } from './assets/Close.svg'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  saveMapping: {
    '& .MuiDialog-paper': {
      width: '750px',
      height: 'auto',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '20px',
    },
    '& .MuiDialogTitle-root > h2': {
      textAlign: 'center',
      font: 'normal normal bold 20px/30px Arial',
      letterSpacing: '0.2px',
      color: '#22353F',
      '& > .MuiButtonBase-root': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    '& .MuiDialogContent-root > .MuiAlert-root': {
      marginTop: '15px',
      background: '#FFF4E5 0% 0% no-repeat padding-box',
      borderRadius: '8px',
      width: '510px',
      minHeight: '95px',
      margin: '15px auto auto auto',
      '& > .MuiAlert-message': {
        textAlign: 'left',
        font: 'normal normal bold 14px/24px Arial',
        letterSpacing: '0px',
        color: '#AB6500',
      },
    },
    '& .MuiInputBase-root': {
      width: '510px',
      height: '52px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '44px',
      '& > button': {
        height: '48px',
        minWidth: -0,
      },
      '& > button.no-thanks': {
        background: '#89A0AC 0% 0% no-repeat padding-box',
        color: '#fff',
      },
      '& > button:first-child': {
        width: '132px',
      },
      '& > button:last-child': {
        width: '128px',
        marginLeft: '15px',
      },
    },
  },
}))

export interface SavePipelineDialogProps {
  open: boolean
  onClose: (flag: boolean, pipelineName: string) => void
}

const SavePipelineDialog = (props: SavePipelineDialogProps) => {
  const { open, onClose } = props
  const { saveMapping } = useStyles()

  const { savedPipelines } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )

  const [pipelineName, setPipelineName] = useState('')
  const [isExist, setIsExist] = useState(false)

  useEffect(() => {
    const index = savedPipelines.findIndex(
      (item: any) => item.filename.split('.json')[0] === pipelineName
    )
    setIsExist(index > -1)
  }, [pipelineName])

  useEffect(() => {
    setPipelineName('')
  }, [open])

  return (
    <Dialog open={open} className={saveMapping} maxWidth={false}>
      <DialogTitle>
        Would you like to save this pipeline map?
        <IconButton onClick={() => onClose(false, '')}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          placeholder="Enter Pipeline Map Name"
          variant="outlined"
          fullWidth
          value={pipelineName}
          onChange={(e) => setPipelineName(e.target.value)}
        />
        {isExist && (
          <Alert severity="warning">
            A pipeline map with this name already exists. Please change your
            chosen name to make it unique in order to save a new pipeline map.
            Alternatively, choose Replace to overwrite the existing saved
            pipeline map and move to the next version.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isExist}
          variant="contained"
          color="primary"
          onClick={() => onClose(true, pipelineName)}
        >
          Replace
        </Button>
        <Button
          disabled={pipelineName.trim().length === 0 || isExist}
          variant="contained"
          color="secondary"
          onClick={() => onClose(true, pipelineName)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SavePipelineDialog
