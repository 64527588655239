import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { EBAutoComplete } from '@engine-b/shared/components'

import {
  useIESelector,
  useIEDispatch,
  getAuditedEntitiesByUser,
  resetEngagementState,
  getEngagements,
  setExtractionDetails,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'

const useStyles = makeStyles((theme) => {
  return createStyles({
    textInput: {
      width: '20%',
      '& .MuiTextField-root': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      '& .combo-box': {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0.16px',
          color: '#44697D',
          verticalAlign: 'center',
          marginBottom: '8px',
          '& > span': {
            fontSize: '20px',
            color: '#DE4D4D',
          },
        },
      },
    },
    statusFilter: {
      width: '15%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
  })
})

export function ExtractionsFilters() {
  const { textInput } = useStyles()

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const extractions = useIESelector((state) => state.extractions)

  const dispatch = useIEDispatch()
  const client = useApolloClient()

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(
      getEngagements({ client, id: extractions?.auditedEntity?.id || '' })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractions?.auditedEntity])

  const handleClientChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      dispatch(
        setExtractionDetails({
          key: 'auditedEntity',
          value: {
            name: '',
            id: null,
            auditFirm: { name: '', id: '', systemName: '' },
          },
        })
      )
      dispatch(
        setExtractionDetails({
          key: 'engagement',
          value: { name: '', id: null },
        })
      )
      return
    }
    dispatch(
      setExtractionDetails({
        key: 'auditedEntity',
        value: data,
      })
    )
  }

  const handleEngagementChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      dispatch(
        setExtractionDetails({
          key: 'engagement',
          value: { name: '', id: null },
        })
      )
      return
    }
    dispatch(
      setExtractionDetails({
        key: 'engagement',
        value: { name: data.name, id: data.id },
      })
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item className={textInput}>
          <div className="combo-box">
            <label htmlFor="client-name">
              Client Name <span>*</span>
            </label>
            <EBAutoComplete
              options={auditedEntities}
              getOptionLabel={(option) => option?.name || ''}
              noOptionsText={'No Client Names'}
              size="small"
              onChange={(e: any, optionData: any, reason) => {
                handleClientChange(optionData, reason)
              }}
              hiddenLabel={true}
              placeholder="Select Client Name"
              value={extractions?.auditedEntity}
              color="primary"
              backgroundColor="#fff"
            />
          </div>
        </Grid>

        <Grid item className={textInput}>
          <div className="combo-box">
            <label htmlFor="client-name">
              Engagement Name <span>*</span>
            </label>
            <EBAutoComplete
              options={engagements}
              getOptionLabel={(option) => option?.name || ''}
              noOptionsText={'No Engagement Names'}
              size="small"
              onChange={(e: any, optionData: any, reason) => {
                handleEngagementChange(optionData, reason)
              }}
              hiddenLabel={true}
              placeholder="Select Engagement Name"
              value={extractions?.engagement}
              color="primary"
              backgroundColor="#fff"
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}
