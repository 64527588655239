import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReactComponent as BackButton } from './assets/BackButton.svg'
import MainFlow from './MainFlow'
import { Status } from '@engine-b/shared/components'
import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import {
  getAnalyticsPipeline,
  getAnalyticsPipelineSummary,
  getDataIngestionsDNA,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import PreviewOutputNode from './PreviewOutputNode'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 120px)',
    padding: '40px',
    background: 'rgba(57, 91, 115, 0.05)',
  },
  title: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '54px',
    color: '#222222',
    marginBottom: '17px',
  },
  filterPanel: {
    width: '100%',
    flexGrow: 1,
    margin: '1rem 0 2rem',
    gap: '1rem',
  },
  BackButtonIcon: {
    width: 'max-content',
    minWidth: 'unset',
    cursor: 'pointer',
  },
  ButtonText: {
    marginLeft: '8px',
    font: `normal normal 700 14px/17px ${theme.typography.fontFamily}`,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#00B2A9',
  },
  ButtonText1: {
    marginLeft: '23px',
    color: '#8C8D8D',
    font: `normal normal 400 14px/17px ${theme.typography.fontFamily}`,
    '& > span': {
      color: '#222',
    },
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '10px',
  },
  caption: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#222',
  },
}))

export const AnalyticsPipelineSummary = () => {
  const {
    root,
    title,
    BackButtonIcon,
    ButtonText,
    ButtonText1,
    subtitle,
    caption,
  } = useStyles()
  const { pipelineId } = useParams()
  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const { analyticsPipeline, loader, outputs } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )
  const { systemName: auditFirm } = useIESelector((state) => state.user)
  const { instance, inProgress, accounts } = useMsal()

  const [open, setOpen] = useState(false)

  // Make an api call to get pipeline summary to preview
  const fetchAnalyticsPipelineSummary = async (pipeline: any) => {
    const { engagement } = pipeline
    const requestJSON = {
      audit_firm: auditFirm,
      client: engagement.auditedEntity.name,
      engagement: engagement.name,
      pipeline_id: pipeline.id,
      load_pipeline_map: true,
    }
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    dispatch(getAnalyticsPipelineSummary({ data: requestJSON, token }))
  }

  useEffect(() => {
    dispatch(getAnalyticsPipeline({ id: pipelineId, client }))
      .unwrap()
      .then((response) => {
        dispatch(
          getDataIngestionsDNA({
            client,
            page: 1,
            pageSize: 10000,
            auditedEntityId:
              response.data.getAnalyticsPipeline?.engagement.auditedEntity?.id,
            engagementId: response.data.getAnalyticsPipeline?.engagement?.id,
          })
        )
          .unwrap()
          .then(() => {
            fetchAnalyticsPipelineSummary(response.data.getAnalyticsPipeline)
          })
      })
  }, [])

  return (
    <div className={root}>
      <Typography className={title}>Data & Analytics</Typography>
      <Grid container justify="space-between" style={{ margin: '1rem 0' }}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            className={BackButtonIcon}
            onClick={() => window.history.go(-1)}
          >
            <BackButton />
            <Typography className={ButtonText}>Back</Typography>
          </Button>
          <Typography className={ButtonText1}>
            Data & Analytics / <span>Pipeline Summary</span>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            style={{ height: '48px' }}
            color="primary"
            variant="contained"
            disabled={outputs.length === 0}
            onClick={() => setOpen(true)}
          >
            Preview Output
          </Button>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Grid container style={{ rowGap: '1rem' }}>
            <Grid item xs={12} md={4} lg={3} xl>
              <Typography className={subtitle}>Pipeline Name</Typography>
              <Typography className={caption}>
                {analyticsPipeline?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl>
              <Typography className={subtitle}>Client Name</Typography>
              <Typography className={caption}>
                {analyticsPipeline?.engagement?.auditedEntity?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl>
              <Typography className={subtitle}>Engagement Name</Typography>
              <Typography className={caption}>
                {analyticsPipeline?.engagement?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl>
              <Typography className={subtitle}>Created By</Typography>
              <Typography className={caption}>
                {analyticsPipeline?.createdBy}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl>
              <Typography className={subtitle}>Status</Typography>
              <Status label={analyticsPipeline?.status} color="success" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container>
        <Grid item xs>
          <MainFlow
            hideOperations={true}
            styles={{
              height: 'calc(100vh - 420px)',
            }}
          />
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 1100 }} open={loader}>
        <Box width="20%" mr={1}>
          <LinearProgress color="secondary" />
        </Box>
      </Backdrop>
      <PreviewOutputNode open={open} onClose={() => setOpen(false)} />
    </div>
  )
}

export default AnalyticsPipelineSummary
