import React from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Drawer as MuiDrawer,
  Tooltip,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import { ReactComponent as Logo } from './icons/logo.svg'
import { ReactComponent as Logout } from './icons/logout.svg'
import { ReactComponent as DocumetnsStatus } from './icons/document_status.svg'
import { ReactComponent as AddData } from './icons/add_data.svg'
import { ReactComponent as CustomERP } from './icons/custom_erp.svg'
import { ReactComponent as CustomGrouping } from './icons/custom_grouping.svg'
import { ReactComponent as DigitalAuditAnalytics } from './icons/digital_audit_analysis.svg'
import { ReactComponent as SupportIcon } from './icons/support_icon.svg'
import { ReactComponent as ArrowIcon } from './icons/arrowIcon.svg'
import { ReactComponent as MainIcon } from './icons/main_icon.svg'

/* eslint-disable-next-line */
interface Icons {
  id: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  text: string
  path: string
  onClick?: () => void
  isActive?: boolean
}

export interface SidebarProps {
  icons?: Icons[]
  logo: React.ComponentType<React.SVGProps<SVGSVGElement>>
  mainLogo: React.ComponentType<React.SVGProps<SVGSVGElement>>
  arrowIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  open?: boolean
  setOpen: (open: boolean) => void
  logOut: Icons
  support: Icons
}

interface IconProps {
  fill: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const CustomIcon: React.FC<IconProps> = ({ fill, icon: Icon }) => (
  <Icon style={{ fill }} />
)

const useStyles = makeStyles((theme: Theme) => ({
  sidebarBoxStyles: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '4px 0px 18px 0px #395B7321',
    position: 'relative',
  },
  arrowIcon: {
    position: 'absolute',
    top: '40px',
    left: '122px',
    zIndex: 1250,
    transition: '0.2s',
  },
  shiftArrowIcon: {
    left: '262px',
    transform: 'rotate(180deg)',
  },
  rotateArrowIcon: {
    transform: 'rotate(0deg)',
  },
  sidebarContainer: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '24px',
    borderBottom: '1px solid #E5E5E5',
  },
  logout: {
    width: (props: any) => (props.open ? '100%' : '74px'),
    padding: '30px 0',
    borderBottom: '1px solid #E5E5E5',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: (props: any) => (props.open ? 'start' : 'center'),
    alignItems: 'center',
  },
  icons: {
    width: '100%',
    height: '100%',
    marginTop: '24px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  icon: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: (props: any) => (props.open ? 'start' : 'center'),
    alignItems: 'center',
    background: 'transparent !important',
  },
  itemIcon: {
    minWidth: '32px',
    fill: '#AFAFAF',
  },
  iconText: {
    paddingLeft: '16px',
    fontSize: '16px',
  },
  iconSelect: {
    background: 'none !important',
    color: '#00B2A9',
  },
  iconColor: {
    minWidth: '32px',
    fill: '#00B2A9',
  },
}))

const closedMixin = (theme: Theme): CSSObject => ({
  background: '#fff',
  overflowY: 'visible',
  border: 'none',
  [theme.breakpoints.up('sm')]: {
    width: 140,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: 280,
  whiteSpace: 'nowrap',
  background: '#fff',
  '& .MuiDrawer-paper': {
    background: '#fff',
    color: '#afafaf',
    width: 280,
    transition: '0.2s',
    border: 'none',
    overflowY: 'visible',
  },
  ...(!open && {
    ...closedMixin(theme),
    transition: '0.2s',
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export function Sidebar(props: Readonly<SidebarProps>) {
  const { icons, logo, mainLogo, arrowIcon, open, setOpen, logOut, support } =
    props
  const classes = useStyles({ open })
  const handleDrawerToggle = () => {
    if (setOpen) {
      setOpen(!open)
    }
  }
  return (
    <Drawer variant="permanent" open={open}>
      <Box className={classes.sidebarBoxStyles}>
        <Grid
          className={`${classes.arrowIcon} ${
            open ? classes.shiftArrowIcon : classes.rotateArrowIcon
          }`}
          onClick={handleDrawerToggle}
        >
          <CustomIcon fill={'none'} icon={arrowIcon} />
        </Grid>
        <Grid className={classes.sidebarContainer}>
          <Grid className={classes.logo}>
            {open ? (
              <CustomIcon fill={'none'} icon={mainLogo} />
            ) : (
              <CustomIcon fill={'none'} icon={logo} />
            )}
          </Grid>
          <Grid className={classes.logout} onClick={logOut.onClick}>
            <Tooltip title={`${!open ? 'LogOut' : ''}`}>
              <ListItemIcon className={classes.itemIcon}>
                <CustomIcon
                  fill={logOut.isActive ? '#00B2A9' : '#AFAFAF'}
                  icon={logOut.icon}
                />
              </ListItemIcon>
            </Tooltip>
            {open && (
              <Typography
                className={classes.iconText}
                style={{ color: '#00B2A9' }}
                variant="body2"
              >
                {logOut.text}
              </Typography>
            )}
          </Grid>
          <Grid className={classes.icons}>
            <List style={{ padding: 0 }}>
              {icons?.map((icon) => (
                <ListItem
                  key={icon.id}
                  button
                  className={`${classes.icon} ${
                    icon.isActive ? classes.iconSelect : ''
                  }`}
                  onClick={icon.onClick}
                >
                  <Tooltip
                    title={`${!open ? icon.text : ''}`}
                    placement="right"
                  >
                    <ListItemIcon
                      className={
                        icon.isActive ? classes.iconColor : classes.itemIcon
                      }
                    >
                      <CustomIcon
                        fill={icon.isActive ? '#00B2A9' : '#AFAFAF'}
                        icon={icon.icon}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <Typography className={classes.iconText} variant="body2">
                      {icon.text}
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
            <List style={{ padding: 0 }}>
              <ListItem
                style={{ padding: 0 }}
                button
                data-testid="support"
                className={`${classes.icon} ${
                  support?.isActive ? classes.iconSelect : ''
                }`}
                onClick={support?.onClick}
              >
                <Tooltip title={`${!open ? 'Support' : ''}`} placement="right">
                  <ListItemIcon
                    className={
                      support?.isActive ? classes.iconColor : classes.itemIcon
                    }
                  >
                    <CustomIcon
                      fill={support.isActive ? '#00B2A9' : '#AFAFAF'}
                      icon={support.icon}
                    />
                  </ListItemIcon>
                </Tooltip>
                {open && (
                  <Typography className={classes.iconText} variant="body2">
                    {support?.text}
                  </Typography>
                )}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  )
}

Sidebar.propTypes = {
  icons: PropTypes.array,
  logo: PropTypes.elementType,
  mainLogo: PropTypes.elementType,
  arrowIcon: PropTypes.elementType,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  logOut: PropTypes.object,
  support: PropTypes.object,
}

Sidebar.defaultProps = {
  icons: [
    {
      id: 'status',
      icon: DocumetnsStatus,
      text: 'Ingestions',
      path: '',
      onClick: () => console.log('test'),
      isActive: true,
    },
    {
      id: 'addData',
      icon: AddData,
      text: 'Add Data',
      path: '',
      onClick: () => console.log('test'),
      isActive: false,
    },
    {
      id: 'customERP',
      icon: CustomERP,
      text: 'Custom ERP',
      path: '',
      onClick: () => console.log('test'),
      isActive: false,
    },
    {
      id: 'customGrouping',
      icon: CustomGrouping,
      text: 'Custom Grouping',
      path: '',
      onClick: () => console.log('test'),
      isActive: false,
    },
    {
      id: 'digitalAuditAnalytics',
      icon: DigitalAuditAnalytics,
      text: 'Digital Audit Analytics',
      path: '',
      onClick: () => console.log('test'),
      isActive: false,
    },
  ],
  logo: Logo,
  mainLogo: MainIcon,
  arrowIcon: ArrowIcon,
  open: false,
  setOpen: () => {},
  logOut: {
    id: 'logout',
    icon: Logout,
    text: 'LogOut',
    path: '',
    onClick: () => console.log('test'),
    isActive: false,
  },
  support: {
    id: 'support',
    icon: SupportIcon,
    text: 'Support',
    path: '',
    onClick: () => console.log('test'),
    isActive: false,
  },
}

export default Sidebar
