import { withJsonFormsControlProps, TranslateProps } from '@jsonforms/react';
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ControlProps, OwnPropsOfEnum } from '@jsonforms/core';
import { EBInput } from '@engine-b/shared/components'
import { NEW_UI_INPUT_WIDTH } from '@engine-b/integration-engine/ui/form-components'

const useStyles = makeStyles((theme) =>
  createStyles({
    TextField: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '44px',
      marginBottom: '16px',
    },
    TextFieldContainer: {
      marginBottom: '16px'
    },
    labelContainer: {
      marginBottom: '16px',
    },
    label: {
      font: 'normal normal bold 16px/18px Arial',
      color: '#44697D',
      letterSpacing: '0.16px',
      verticalAlign: 'center'
    },
    description: {
      maxWidth: NEW_UI_INPUT_WIDTH + 'px',
      marginBottom: '16px',
      minWidth: '400px',
      fontSize: '14px',
    },
    errorLabel: {
      color: theme.palette.error.main,
      marginBottom: '16px',
      marginTop: '9px',
      minWidth: '200px',
    }
  })
)

const TextFieldElement = (props: ControlProps & OwnPropsOfEnum & TranslateProps) => {
  const classes = useStyles()
  return (
    <Grid container>
      {props.label && (
        <Grid item xs={12} className={classes.labelContainer}>
          <Typography className={classes.label}>{props.label}</Typography>
          {props.required && <Typography style={{color: 'red'}}> *</Typography>}
        </Grid>
      )}
      {props.description && (
        <Grid item xs={12}>
          <Typography className={classes.description}>{props.description}</Typography>
        </Grid>
      )}
      
      <Grid item xs={12} className={classes.TextFieldContainer}>
        <EBInput
          width={NEW_UI_INPUT_WIDTH + 'px'}
          color="secondary" 
          placeholder={props.label}
          onChange={(e) => {
            props.handleChange(props.path, e.target.value)
          }}
          type={props.schema?.['writeOnly'] ? 'password' : 'text'} 
        />
      </Grid>
        <Grid item xs={12}>
          <label className={classes.errorLabel}>{props.errors}</label>
        </Grid>
    </Grid>
  )
}

export const TextFieldControl = withJsonFormsControlProps(TextFieldElement);