import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles((theme) => ({
  PreviewPannel: {
    width: '100%',
    background: 'white',
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: '72px',
  },
  blackText: {
    color: 'black',
  },
  warningText: {
    color: '#F7931E',
  },
  errorText: {
    color: '#DE4D4D',
  },
  successText: {
    color: '#37AB3F',
  },
  darkSuccess: {
    color: '#155724',
  },
  summaryCard: {
    padding: '20px 30px',
    marginBottom: '15px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #CCCCCC',
    borderRadius: '10px',
    opacity: 1,
  },
  floatRight: { textAlign: 'right' },
  mappedText: {
    display: 'inline-block',
    color: '#00B2A9',
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: '#D9F4F2',
    borderRadius: '12px',
    padding: '4px 8px'  
  }
}))

export interface Summary {
  name: string
  message: string
  valid: boolean
}

const ImperfectFiles = ({ data, validate }) => {
  const classes = useStyles()

  const userMappedOrNot = (valid, message) => {
    if (!valid && validate) {
      return (
        <Typography className={classes.mappedText}>
          User Mapped
        </Typography>
      )
    } else {
      return message
    }
  }

  return (
    <Box className={classes.PreviewPannel}>
      <Grid container direction="column">
        <Grid item>
          {data.map(({ name, message, valid }) => (
            <Box className={classes.summaryCard} key={name}>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography variant="h5" className={classes.blackText}>
                    {name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: '12px' }}
                    className={
                      valid || validate
                        ? classes.successText
                        : classes.errorText
                    }
                  >
                    {userMappedOrNot(valid, message)}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.floatRight}>
                  {valid || validate ? (
                    <CheckCircleIcon
                      fontSize="large"
                      className={classes.darkSuccess}
                    />
                  ) : (
                    <CancelIcon
                      fontSize="large"
                      className={classes.errorText}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ImperfectFiles
