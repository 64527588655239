import React from 'react'
import {
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

const exampleValues = [
  {
    value: '1001 Cash at Bank',
  }, {
    value: '',
  }, {
    value: '',
  },
  {
    value: '1002 Petty Cash',
  }
]
const exampleValues1 = [
  {
    value: '1001 Cash at Bank',
  }, {
    value: '1001 Cash at Bank',
  }, {
    value: '1001 Cash at Bank',
  },
  {
    value: '1002 Petty Cash',
  }
]

const FillDownExample = ({
  field_name,
  cdm_field,
  example_class,
  align_vertical
}) => {
  return (
    <>
      <Typography variant="subtitle1">
        {cdm_field} = {field_name}
      </Typography>
      <Divider style={{ margin: '20px 0' }} />
      <Typography className={example_class}>Example :</Typography>
      <Typography className={example_class}>Input :</Typography>
      <div className={align_vertical}>
        <Table stickyHeader={true} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{field_name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exampleValues.map(e => (
              <TableRow>
                <TableCell>{e.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Divider style={{ margin: '20px 0' }} />
      <Typography className={example_class}>Output :</Typography>
      <div className={align_vertical}>
        <Table stickyHeader={true} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{field_name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exampleValues1.map(e => (
              <TableRow>
                <TableCell>{e.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export default FillDownExample
