import React, {
  Fragment,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Card,
  Divider,
  Button,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'
import { AddTable } from './AddTable'
import VirtualTableWrapper from './VirtualTableWrapper'
import { JointComponent } from '@engine-b/shared/components'

import {
  useIEDispatch,
  useIESelector,
  tableNameListSelector,
  addTableJoin,
  createAndUploadOperationsFile,
  beginTableJoins,
  resetJoins,
  OperationType,
  getInvalidVirtualTables,
  JOINS_UPLOAD_STATE,
} from '@engine-b/integration-engine/data/state/redux'

import BasicDetails from './BasicDetails'
import { useApolloClient } from '@apollo/client'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) =>
  createStyles({
    TableJoinsContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: '#F0FBFA 0% 0% no-repeat padding-box',
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    Title: {
      display: 'flex',
      alignItems: 'center',
      font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
    },
    TableJoinsContent: {
      maxWidth: '100%',
      flex: 1,
      flexDirection: 'column',
      border: '1px solid #44697D33',
      borderRadius: '10px',
      marginTop: '30px',
      '& > .MuiGrid-item': {
        padding: theme.spacing(3),
      },
      '& .MuiAlert-message': {
        '& li': {
          wordBreak: 'break-word',
        },
      },
    },
    addButton: {
      borderRadius: '8px',
      minWidth: 'unset',
      width: '200px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)
/* eslint-disable-next-line */
export interface TableJoinsViewProps { }
export interface joinProcessInfoInterface {
  type?: 'error' | 'success'
  message?: string
}
export function TableJoinsView(props: TableJoinsViewProps) {
  const classes = useStyles()
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [launchingJoinProcess, setLaunchJoinProcess] = useState(false)
  const [joinProcessInfo, setJoinProcessInfo] =
    useState<joinProcessInfoInterface>({})

  const tableNameList = useIESelector((state) => tableNameListSelector(state))
  const invalidVirtualTables = useIESelector((state) =>
    getInvalidVirtualTables(state)
  )
  const {
    name,
    validName,
    engagement,
    joinId,
    operations,
    loading,
    operationType,
    tables,
  } = useIESelector((state) => state.joins)

  const dispatch = useIEDispatch()
  const azureClient = useContext(AzureClientContext)

  const validateAndlaunchJoiningProcess = async () => {
    try {
      setLaunchJoinProcess(true)
      const addTableJoinRes = await dispatch(addTableJoin({ client }))
      if (addTableJoinRes.meta.requestStatus === 'rejected')
        throw 'Error occurred while adding table join'
      const createAndUploadOperationsFileRes = await dispatch(
        createAndUploadOperationsFile({ azureClient })
      )
      if (createAndUploadOperationsFileRes.meta.requestStatus === 'rejected')
        throw 'Error occurred while uploading operations file'
      const beginTableJoinsRes = await dispatch(beginTableJoins({ client }))
      if (beginTableJoinsRes.meta.requestStatus === 'rejected')
        throw 'Error occurred while initializing table join'

      setJoinProcessInfo({
        type: 'success',
        message: 'Join process initialized successfully',
      })
      setLaunchJoinProcess(false)
    } catch (error) {
      console.log(error)
      setLaunchJoinProcess(false)
      setJoinProcessInfo({
        type: 'error',
        message: error as string,
      })
    }
  }

  const shouldDisableCombineAndValidate = useMemo(() => {
    /**
     * disable apply button if only one file is uploaded for One table.
     */
    const totalUploadedFiles = Object.keys(tables).reduce((acc, curr) => {
      const uploadState = Object.keys(tables[curr].files).filter(
        (file) =>
          tables[curr].files[file].uploadState ===
          JOINS_UPLOAD_STATE.VALIDATING_FILE_COMPLETE
      );
      return acc + uploadState.length;
    }, 0);
    if (totalUploadedFiles <= 1) return true;

    const virtualTables = Object.values(tables)
    for (let i = 0; i < operations.length; i++) {
      const { direction, joinTo, rules } = operations[i]

      if (!direction || !joinTo || rules.length === 0) return true

      for (let r = 0; r < rules.length; r++) {
        const { origin, target } = rules[r]

        const { table: originTable, column: originCol } = origin
        const { table: targetTable, column: targetCol } = target

        if (!originTable || !originCol || !targetTable || !targetCol)
          return true
      }
    }

    return (
      !validName ||
      name === '' ||
      engagement.id === null ||
      joinId !== '' ||
      virtualTables.length === 0 ||
      invalidVirtualTables.length > 0 ||
      (operationType === OperationType.COMBINE && operations.length > 0) ||
      (operationType === OperationType.COMBINEANDJOIN && operations.length < 1)
    )
  }, [name, validName, joinId, engagement, operations, tables, invalidVirtualTables])

  const history = useHistory()
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (joinProcessInfo.type === 'success') {
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.TableJoins))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinProcessInfo.type])

  const currJoin = useIESelector((state) => state.joins.name)
  const currEngagement = useIESelector((state) => state.joins.engagement.id)

  const resetJoinsOp = () => {
    if (currEngagement !== null || currJoin.length > 0) {
      dispatch(resetJoins(1))
    }
  }

  useEffect(() => {
    resetJoinsOp()
    return () => {
      dispatch(resetJoins(1))
    }
  }, [])

  return (
    <Fragment>
      <section></section>
      <section className={classes.TableJoinsContainer}>
        <Typography className={classes.Title} color="primary">
          Virtual Table Joins
        </Typography>

        <Grid
          container
          className={classes.TableJoinsContent}
          component={Card}
          elevation={0}
        >
          {joinProcessInfo.type && (
            <Grid item>
              <Alert
                severity={joinProcessInfo.type}
                onClose={() => setJoinProcessInfo({})}
              >
                {joinProcessInfo.message}
              </Alert>
            </Grid>
          )}
          <Grid item>
            <BasicDetails />
          </Grid>
          <Divider />
          <Grid item>
            <AddTable  isLoading={isLoading} setIsLoading={setIsLoading} />
          </Grid>
          <Divider />
          <Grid item>
            <VirtualTableWrapper tablesList={tableNameList} />
          </Grid>
          <Divider />
          {tableNameList.length > 1 && (
            <Grid item>
              <JointComponent />
            </Grid>
          )}
          <Grid
            item
            style={{
              textAlign: 'end',
              marginRight: '2%',
              marginBottom: '2%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              className={classes.addButton}
              onClick={validateAndlaunchJoiningProcess}
              disabled={shouldDisableCombineAndValidate}
            >
              Combine Files & Validate
            </Button>
          </Grid>
          <Backdrop
            className={classes.backdrop}
            open={loading || launchingJoinProcess}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </section>
    </Fragment>
  )
}

export default TableJoinsView
