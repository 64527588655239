import React from 'react'
import {
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import { ReactComponent as IconDebit } from '../../../assets/IconDebit.svg'
import { ReactComponent as IconCredit } from '../../../assets/IconCredit.svg'

export interface RangeBasedProps {
  cdm_field: string
}

const useStyles = makeStyles(() => ({
  rangeBasedClass: {
    '& span': {
      margin: '0 5px',
    },
    '& .MuiTableCell-head': {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0px',
      color: '#00B2A9',
      padding: '16px 16px 16px 0',
    },
  },
  output: {
    display: 'flex',
    alignItems: 'center',
  },
  credit: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #1A95BB',
    borderRadius: '8px',
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0.35px',
    color: '#1A95BB',
    width: '86px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  debit: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EE8D24',
    borderRadius: '8px',
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0.35px',
    color: '#EE8D24',
    width: '86px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  posAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      color: 'inherit !important',
      fontSize: 'inherit !important',
    },
  },
}))

const RangeBasedExample = (props: RangeBasedProps) => {
  const { rangeBasedClass, credit, debit, output, posAmount } = useStyles()

  const data = [
    {
      acc_no: 1200,
      start: 10000,
      end: 19999,
      positive_amount: 4500,
      icon: (
        <div className={debit}>
          <IconDebit /> Debit
        </div>
      ),
    },
    {
      acc_no: 23444,
      start: 20000,
      end: 29999,
      positive_amount: 3200,
      icon: (
        <div className={credit}>
          <IconCredit /> Credit
        </div>
      ),
    },
  ]

  return (
    <div className={rangeBasedClass}>
      <div className={output}>
        {props.cdm_field} <span>=</span> Start Range <span>-</span> End Range
        <span>=</span> Positive Amount <span>=</span> {data[0].icon}
      </div>
      <Divider style={{ margin: '15px 0' }} />
      <Typography style={{ font: 'normal normal bold 16px/18px Arial' }}>
        Example :
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{props.cdm_field}</TableCell>
            <TableCell>Start Range</TableCell>
            <TableCell>End Range</TableCell>
            <TableCell>Positive Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((record, i) => (
            <TableRow key={i}>
              <TableCell>{record.acc_no}</TableCell>
              <TableCell>{record.start}</TableCell>
              <TableCell>{record.end}</TableCell>
              <TableCell>
                <div className={posAmount}>
                  <span>{record.positive_amount}</span>
                  <span>{record.icon}</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default RangeBasedExample
