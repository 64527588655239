import { Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { SIDEBAR_WIDTH } from './constants'

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      position: 'fixed',
      bottom: '0',
      right: '0',
      backgroundColor: '#FFFFFF',
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
      minHeight: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
      zIndex: 10
    },
  })
)

export function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Typography>Copyright © {new Date().getFullYear()}. All rights reserved by Engine B Limited. {process.env.NX_APP_VERSION}</Typography>
    </div>
  )
}

export default Footer
