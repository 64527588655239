import React, { useEffect, useState, useContext, useMemo } from 'react'
import {
  Grid,
  createStyles,
  Typography,
  makeStyles,
  TextField,
  Divider,
  Button,
  FormLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {
  useIESelector,
  getAuditedEntitiesByUser,
  useIEDispatch,
  getEngagements,
  setBasicDetails,
  resetJoinsTableFiles,
  FILE_TYPE,
  getDataIngestions,
  getDataAttributes,
  clearAllFunctions, addFunctions, getBundles, getFunctions,
  handleBasicForm,
  initFunctionSetter, resetAuditAnalytics, clearFilePath
} from '@engine-b/integration-engine/data/state/redux'

import { useApolloClient } from '@apollo/client'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import { ReactComponent as IconError } from './assets/IconError.svg'
import { ReactComponent as IconClose } from './assets/IconClose.svg'
import { deleteFileFromAzureContainer, AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import DaaFunction from './DaaFunction'


const useStyles = makeStyles((theme) =>
  createStyles({
    clientNameFilter: {},
    engagementNameFilter: {},

    dividerMargin: {
      margin: "20px 0"
    },
    JoinInputStyle: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
        paddingRight: '8px',
      },
    },
    moreFunction: {
      width: '164px',
      height: '40px',
      background: '#D9F4F2 0% 0% no-repeat padding-box',
      borderRadius: '5px',
      textAlign: 'center',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0px',
      color: '#00B2A9',
      border: 'none',
      padding: '13px 7px 11px',
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      '& span > svg': {
        marginRight: '12px',
      },
      '&:hover': {
        background: '#00b2a9 0% 0% no-repeat padding-box',
        textAlign: 'center',
        font: 'normal normal normal 16px/18px Arial',
        letterSpacing: '0px',
        color: '#FFFFFF',
        '& span > svg path,line': {
          stroke: '#FFFFFF',
        },
      },
    },
    deleteTableButtonIcon: {
      width: '40px',
      display: "block",
      cursor: 'pointer',
      marginLeft: 'auto'
    },
    stepNumber: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      marginBottom: '12px',
      fontSize: '1rem',
    },
    textCloseIcon: {
      padding: '4px'
    },
  })
)

/* eslint-disable-next-line */
export interface BasicDetailsProps {
  editMode: boolean
}
export function BasicDetails({ editMode }: BasicDetailsProps) {
  const DIALOG_WIDTH = '600px'
  const DIALOG_HEIGHT = '450px'
  const client = useApolloClient()
  const classes = useStyles()
  const dispatch = useIEDispatch()

  interface DialogState {
    isOpen: boolean
  }

  const azureClient = useContext(AzureClientContext)

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { tables } = useIESelector((state) => state.joins)
  const {
    name, selectedClientDetails, selectedEngagementDetails,
    selectedIngestionDetails, selectedFilePath, selectedBundleDetails,
    functions, functionsList, bundlesList
  } = useIESelector((state) => state.auditAnalytics);
  const { runs } = useIESelector((state) => state.runs);
  const { attributes } = useIESelector((state) => state.attributes);
  const [filePaths, setFilePaths] = useState([]);
  const [dataIngestions, setDataIngestions] = useState<any>([])
  const [validationMessage, setValidationMessage] = useState('');
  const [auditedEntityDialogData, setauditedEntityDialogData] =
    useState<DialogState>({
      isOpen: false,
    })
  const [currentEngagementInfo, setCurrentEngagementInfo] = useState({
    client: { id: '', name: '' },
    engagement: { id: '', name: '' },
  })

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    // Getting functions
    dispatch(getFunctions(client))
    // Getting Bundles
    dispatch(getBundles(client))

    return () => {
      dispatch(resetAuditAnalytics({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredIngestions = runs.filter(
      (run) =>
        run.uiState === 'COMPLETED' &&
        run.engagement.name === selectedEngagementDetails.name &&
        run.auditedEntityName === selectedClientDetails.name
    )
    setDataIngestions(filteredIngestions)
  }, [runs])


  useEffect(() => {
    if (!selectedClientDetails.id) {
      dispatch(handleBasicForm({ name: "selectedEngagementDetails", value: "" }))
      dispatch(handleBasicForm({ name: "selectedIngestionDetails", value: "" }))
      dispatch(getEngagements({ client, id: '' }))
    } else {
      dispatch(getEngagements({ client, id: selectedClientDetails.id || '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientDetails.id]);

  // Getting ingestions whenever engagements changed
  useEffect(() => {
    if (!selectedEngagementDetails.id) {
      dispatch(handleBasicForm({ name: "selectedIngestionDetails", value: "" }))
    } else {
      dispatch(getDataIngestions({ client }))
    }
  }, [selectedEngagementDetails.id])

  // Getiting Attributes Whenever File Path changed  
  useEffect(() => {
    if (selectedFilePath.id) {
      dispatch(getDataAttributes({ client, entityName: selectedFilePath.id }))
    }
  }, [selectedFilePath])

  // Getting File Path from ingestions Data whenever Ingestion Data changed
  useEffect(() => {
    // @ts-ignore
    const modifiedData = selectedIngestionDetails?.cdmEntities?.map(el => {
      return {
        id: el?.cdmEntity?.systemName,
        name: el?.cdmEntity?.name,
      }
    });

    setFilePaths(modifiedData);
  }, [selectedIngestionDetails])

  // Filtering Bundle when File path changes 
  const fiteredBundles = useMemo(() => {
    if (selectedFilePath.id && bundlesList.length) {
      return bundlesList.filter(bundle => bundle.entity.name === selectedFilePath.id)
    }
    return []
  }, [selectedFilePath, bundlesList])

  const handleOnBlur = () => {
    if (name.trim()?.length < 3) {
      setValidationMessage('Operation Name must be at least 3 characters long')
    } else if (name.trim()?.length > 50) {
      setValidationMessage('Operation Name cannot exceed 50 characters')
    } else {
      setValidationMessage('')
    }
  }
  const handleClientChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
      dispatch(
        setBasicDetails({
          key: 'auditedEntity',
          value: {
            name: '',
            id: null,
            auditFirm: { name: '', id: '', systemName: '' },
          },
        })
      )
      dispatch(
        setBasicDetails({ key: 'engagement', value: { name: '', id: null } })
      )
      return
    }
    dispatch(
      setBasicDetails({
        key: 'auditedEntity',
        value: data,
      })
    )
  }

  const handleEngagementChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
      dispatch(
        setBasicDetails({ key: 'engagement', value: { name: '', id: null } })
      )
      return
    }
    dispatch(
      setBasicDetails({
        key: 'engagement',
        value: { name: data.name, id: data.id },
      })
    )
  }


  const handleClientChangeDialog = (
    type: string,
    optionData: any,
    reason: string,
  ) => {
    if (["selectedClientDetails", "selectedEngagementDetails", "selectedIngestionDetails"].includes(type)) {
      dispatch(clearAllFunctions({}))
      dispatch(clearFilePath({}))
    }

    if (type === "selectedFilePath") {
      dispatch(clearAllFunctions({}));
    }

    if (reason === "clear") {
      dispatch(handleBasicForm({ name: type, value: "" }))
      dispatch(clearAllFunctions({}));
    } else {
      if (type === "selectedBundleDetails") {
        dispatch(clearAllFunctions({}));
        dispatch(initFunctionSetter(optionData))
      }
      dispatch(handleBasicForm({ name: type, value: optionData }))
    }
  }

  const handleChangeDialogResponse = (resp: boolean) => {
    if (resp) {
      if (currentEngagementInfo.client.id !== '') {
        handleClientChange(currentEngagementInfo.client, '')
        setCurrentEngagementInfo({
          client: { ...currentEngagementInfo.client },
          engagement: { id: '', name: '' },
        })
        handleEngagementChange(currentEngagementInfo.engagement, 'clear')
      } else if (currentEngagementInfo.engagement.id !== '') {
        handleEngagementChange(currentEngagementInfo.engagement, '')
      }

      Object.keys(tables).forEach((table) => {
        dispatch(resetJoinsTableFiles({ tableName: table }))
        const files = tables[table].files
        Object.keys(files).forEach((f) => {
          const file = files[f]
          if (file.fileType === FILE_TYPE.UPLOADED) {
            const filePath = file?.path || ''
            if (filePath) {
              deleteFileFromAzureContainer({
                azureClient,
                fileSystemId: selectedClientDetails.auditFirm?.systemName || '',
                filePath,
              })
            }
          }
        })
      })

      setauditedEntityDialogData({ isOpen: false })
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
    } else {
      setauditedEntityDialogData({ isOpen: false })
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
    }
  }

  return (
    <>
      <Grid container spacing={3} alignItems="flex-start">
        <Dialog
          cancelText="Cancel"
          confirmText="Yes"
          width={DIALOG_WIDTH}
          height={DIALOG_HEIGHT}
          open={auditedEntityDialogData.isOpen}
          onClose={(confirmation: boolean) =>
            handleChangeDialogResponse(confirmation)
          }
        >
          <>
            <IconError />
            <Typography component={'div'} className="dialog-title">
              You’ve changed the name of the client or engagement you’re adding
              data to - this will clear all of the information (including uploaded
              files) you’ve provided so far. Are you sure you want to continue?
            </Typography>
          </>
        </Dialog>

        <Grid item xs={12} md={4} >
          <TextField
            name="inputValue"
            size="small"
            variant="outlined"
            color="primary"
            label="Operation Name"
            value={name}
            className={classes.JoinInputStyle}
            onChange={(e) => {
              dispatch(handleBasicForm({ name: "name", value: e.target.value }))
            }}
            onBlur={handleOnBlur}
            InputProps={{
              endAdornment: (
                <Tooltip title="Clear">
                <IconButton
                  className={classes.textCloseIcon}
                  style={{ visibility: name ? "visible" : "hidden" }}
                  onClick={(e) => {
                    dispatch(handleBasicForm({ name: "name", value: "" }));
                  }}
                >
                  <IconClose />
                </IconButton>
                </Tooltip>
              ),
            }}
            disabled={editMode}
            placeholder={'Enter Operation Name'}
            type="text"
            fullWidth={true}
          />
          {validationMessage && (
            <FormLabel
              error
              style={{ textAlign: 'left', marginTop: '8px', color: 'red' }}
            >
              {validationMessage}
            </FormLabel>
          )}
        </Grid>
        <Grid xs={12} md={4} item className={classes.clientNameFilter}>
          <EBAutoComplete
            forcePopupIcon={true}
            options={auditedEntities}
            getOptionLabel={(option) => option?.name || ''}
            noOptionsText={'No Client Names'}
            size="small"
            value={selectedClientDetails}
            onChange={(e: any, optionData, reason) => {
              handleClientChangeDialog('selectedClientDetails', optionData, reason);
            }}
            disabled={editMode}
            color="primary"
            placeholder="Select Client Name"
            label="Client Name"
            backgroundColor='#fff'
          />
        </Grid>
        <Grid xs={12} md={4} item className={classes.engagementNameFilter}>
          <EBAutoComplete
            forcePopupIcon={true}
            options={engagements}
            getOptionLabel={(option) => option.name || ''}
            noOptionsText={'No Engagement Names Available'}
            size="small"
            value={selectedEngagementDetails}
            onChange={(e: any, optionData, reason) => {
              handleClientChangeDialog('selectedEngagementDetails', optionData, reason);
            }}
            disabled={editMode}
            hiddenLabel={true}
            color='primary'
            placeholder="Select Engagement Name"
            label="Engagement Name"
            backgroundColor='#fff'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <EBAutoComplete
            forcePopupIcon={true}
            options={selectedEngagementDetails ? dataIngestions : []}
            getOptionLabel={(option) => option.name || ''}
            noOptionsText={'No Ingestion Names Available'}
            size="small"
            value={selectedIngestionDetails}
            onChange={(e: any, optionData, reason) => {
              handleClientChangeDialog('selectedIngestionDetails', optionData, reason);
            }}
            disabled={editMode}
            hiddenLabel={true}
            color='primary'
            placeholder="Select Ingestion Name"
            label="Ingestion Name"
            backgroundColor='#fff'
          />
        </Grid>
      </Grid>
      <Divider className={classes.dividerMargin} />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <EBAutoComplete
            forcePopupIcon={true}
            options={selectedIngestionDetails ? filePaths : []}
            getOptionLabel={(option) => option.name || ''}
            noOptionsText={'No File Names Available'}
            size="small"
            value={selectedFilePath}
            onChange={(e: any, optionData, reason) => {
              handleClientChangeDialog('selectedFilePath', optionData, reason);
            }}
            disabled={editMode}
            hiddenLabel={true}
            color='primary'
            placeholder="File"
            label="File Name"
            backgroundColor='#fff'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <EBAutoComplete
            forcePopupIcon={true}
            options={fiteredBundles}
            getOptionLabel={(option) => option.name || ''}
            noOptionsText={'No Bundle Names Available'}
            size="small"
            value={selectedBundleDetails}
            onChange={(e: any, optionData, reason) => {
              handleClientChangeDialog('selectedBundleDetails', optionData, reason);
            }}
            disabled={editMode}
            hiddenLabel={true}
            color='primary'
            placeholder="Bundle"
            label="Bundle Name"
            backgroundColor='#fff'
          />
        </Grid>
        {!editMode ?
          <Grid item xs={12} md={4}>
            <Button
              variant="outlined"
              className={classes.moreFunction}
              size="small"
              onClick={() => dispatch(clearAllFunctions({}))}
            >
              Clear All
            </Button>
          </Grid>
          : null
        }
      </Grid >
      <Divider className={classes.dividerMargin} />
      {
        functions.map((el, functionIndex) => {
          return (
            <>
              {functionIndex !== 0 ? <Divider className={classes.dividerMargin} /> : null}
              <DaaFunction
                index={functionIndex}
                editMode={editMode}
                functionsList={functionsList}
                row={el}
                stepOptions={el.stepOptions}
                functions={functions}
                attributes={attributes}
              />
            </>
          )
        })
      }
      {
        !editMode ?
          <Grid container justify='flex-end' style={{ marginTop: "20px" }}>
            <Button
              variant="outlined"
              className={classes.moreFunction}
              size="small"
              onClick={() => dispatch(addFunctions({}))}
            >
              + Add more function
            </Button>
          </Grid>
          : null
      }
    </>
  )
}

export default BasicDetails