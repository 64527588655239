import { gql } from '@apollo/client'

export const GET_DATA_AND_ANALYTICS_PIPELINES = gql`
  query AnalyticsPipelines(
    $page: Int!
    $results: Int!
    $pipelineName: String
    $auditedEntityId: String
    $engagementId: String
    $status: String
  ) {
    analyticsPipelines(
      page: $page
      results: $results
      pipelineName: $pipelineName
      auditedEntityId: $auditedEntityId
      engagementId: $engagementId
      status: $status
    ) {
      pipelines {
        id
        name
        engagement {
          auditedEntity {
            id
            name
            auditFirm {
              systemName
              id
            }
          }
          id
          name
        }
        status
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      total
    }
  }
`
