import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import MuiButton from '@material-ui/core/Button'

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
    button: {
      boxShadow: 'none',
    },
    whiteButton: {
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
)

type Color = 'primary' | 'secondary' | 'white'

interface ButtonProps {
  text: string
  type: 'button' | 'link'
  color?: Color
  onClick?: any
  linkTo?: string
}

const StyledButton = (props: { text: string; color: Color; onClick? }) => {
  const classes = useStyles()
  const { text, color, onClick } = props
  return (
    <MuiButton
      onClick={onClick}
      className={clsx({
        [classes.button]: true,
        [classes.whiteButton]: color === 'white',
      })}
      color={color !== 'white' ? color : undefined}
      variant="contained"
      size="small"
    >
      {text}
    </MuiButton>
  )
}

export function Button({
  type,
  text,
  color = 'primary',
  ...props
}: ButtonProps) {
  const classes = useStyles()
  return (
    <>
      {type === 'link' ? (
        <Link to={props.linkTo} className={classes.link}>
          <StyledButton text={text} color={color} />
        </Link>
      ) : (
        <StyledButton text={text} color={color} onClick={props.onClick} />
      )}
    </>
  )
}
