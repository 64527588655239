import StepConnector from '@material-ui/core/StepConnector'
import { withStyles } from '@material-ui/core/styles'

export const EBConnector = withStyles((theme) => ({
  root: {
    zIndex: 0,
    padding: `0 ${theme.spacing(1)}`,
  },
  alternativeLabel: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    '& span': {
      borderColor: theme.palette.grey[400],
      margin: '10px 0 0 0',
    },
  },
  active: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector)
