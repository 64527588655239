import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    dataContainer: {
      marginBottom: '18px',
    },
    dataHeaders: {
      font: 'normal normal bold 18px/21px Arial',
      letterSpacing: '0.18px',
      marginBottom: '12px',
      color: '#44697D',
    },
    dataValues: {
      font: 'normal normal normal 22px/26px Arial',
      letterSpacing: '0.22px',
      overflowWrap: 'break-word',
      color: '#22353F',
      '& > li': {
        padding: 0,
      },
    },
  })
)

const GroupingCardDataItem = ({ xs, md, lg, heading, value }) => {
  const classes = useStyles()

  return (
    <Grid item xs={xs} md={md} lg={lg} className={classes.dataContainer}>
      <Typography className={classes.dataHeaders}>{heading}</Typography>
      <Typography className={classes.dataValues}>{value}</Typography>
    </Grid>
  )
}

export default GroupingCardDataItem
