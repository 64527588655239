import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { FileUploader } from '@engine-b/shared/components'
import { useApolloClient } from '@apollo/client'
import {
  UPLOAD_STATE,
  fetchStart,
  useIEDispatch,
} from '@engine-b/integration-engine/data/state/redux'
import { useIntl } from 'react-intl'

const DragAndDropFileUpload = ({
  uploadFile,
  fileMapping,
  copyFileToDI,
  index,
  selectedReportDetail,
  selectedCdmEntity,
  reportIndex,
  auditedEntity,
  engagementId,
  allowMultiple,
  value,
  setValue,
  loading,
  headerOptions,
  uploaded,
  handleInputChange,
}) => {
  const client = useApolloClient()
  const { locale } = useIntl()
  const dispatch = useIEDispatch()
  const [showOnDragMessage, setShowOnDragMessage] = useState(false)
  const [openPreviousFile, setOpenPreviousFile] = useState(false)

  const onPrevMappingSelect = async (result: boolean, file: string) => {
    setOpenPreviousFile(false)
    if (result) {
      dispatch(fetchStart({}))
      copyFileToDI(file)
    }
  }

  return (
    <Grid>
      <FileUploader
        isUploadInfo={true}
        isPreUploadFile={true}
        isUploadedFile={true}
        isDisplayVertical={false}
        enableDragAndDrop={true}
        client={client}
        locale={locale}
        uploadLabel="Drag & drop your files here or"
        disableUpload={
          fileMapping?.state === UPLOAD_STATE.IN_PROGRESS ||
          fileMapping?.state === UPLOAD_STATE.VALIDATING_FILE
        }
        onDragOver={(e) => setShowOnDragMessage(true)}
        showOnDragMessage={showOnDragMessage}
        uploadFile={uploadFile}
        handleButtonClick={() => setOpenPreviousFile(true)}
        id={`file_${index}_${reportIndex}`}
        open={openPreviousFile}
        onClose={onPrevMappingSelect}
        dialogTitle="Previously Uploaded Files"
        allowMultiple={allowMultiple}
        auditedEntity={auditedEntity}
        engagementId={engagementId}
        fileMappingData={fileMapping}
        selectedReportDetail={selectedReportDetail}
        selectedCdmEntity={selectedCdmEntity}
        index={index}
        disableExtraction={false}
        disableJoin={false}
        disablePreUpload={false}
        value={value}
        setValue={setValue}
        loading={loading}
        options={headerOptions}
        uploaded={uploaded}
        handleInputChange={handleInputChange}
      />
    </Grid>
  )
}

export default DragAndDropFileUpload