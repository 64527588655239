import React, { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import {
  useIEDispatch,
  useIESelector,
  fetchStart,
  fetchStop,
  saveFileInfo,
  updateFileInfo,
  updateFileMapping,
  UPLOAD_STATE,
  resetManualSelections,
  CDMMapping,
  updateDeleteFlag,
  clearSingleFileManualSelection,
  setCustomFileMappings,
} from '@engine-b/integration-engine/data/state/redux'
import axios from 'axios'
import { binaryToBase64 } from '@engine-b/integration-engine/utils/file-converter'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { FileUploadWidget } from './FileUploadWidget'
import { isValidFileType } from './is-valid-file-type'
import { StatusCodes } from 'http-status-codes'
import { FILE_HELPER } from '@engine-b/integration-engine/data/helper-text'
import {
  uploadFileToAzureContainer,
  AzureClientContext,
} from '@engine-b/integration-engine/data/azure-data-factory'
import { ReactComponent as IconInfo } from '../../../../../../apps/integration-engine/src/assets/IconInfo.svg'

const CUSTOM_MAPPER_CHECK_FILE_PATH = `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/check-path`

const useStyles = makeStyles((theme) =>
  createStyles({
    Row: {
      marginTop: '8px',
      marginBottom: '8px',
      minHeight: 170,
    },
    FileWidgetWrapper: {
      margin: '4px',
    },
    Icon: {
      marginLeft: 'auto',
      marginRight: 'auto',
      cursor: 'pointer',
    },
    inputFile: {
      wordWrap: 'break-word',
    },
    fileCheckbox: {
      display: 'flex',
      alignItems: 'center',
    },
    toolTip: {
      fontSize: '12px',
    },
  })
)

interface Props {
  cdmFileName
  reportDetail
  source
  handleDeleteEntity
  index?: any
  selectedCdmEntity?: any
  reportIndex?: any
  preloadFileObj?: any
}

export function FileMappingEntry({
  cdmFileName,
  reportDetail,
  source,
  handleDeleteEntity,
  index,
  selectedCdmEntity,
  reportIndex,
  preloadFileObj,
}: Props) {
  const { instance, inProgress, accounts } = useMsal()
  const reportType = reportDetail.systemName
  const reportName = reportDetail.name
  const classes = useStyles()
  const fileMapping = useIESelector(
    (state) =>
      state.fileMappings &&
      state.fileMappings[`${reportType}_${cdmFileName}`] !== undefined &&
      state.fileMappings[`${reportType}_${cdmFileName}`]
  )
  const draftRun = useIESelector((state) => state.runs.draftRun)
  const cMapper = useIESelector((state) => state.customMapper)
  const { engagement } = useIESelector((state) => state.engagement)
  const { auditedEntity } = useIESelector((state) => state.engagement)
  const erps = useIESelector((state) => state.erps)
  const { files: preUploadedFiles } = useIESelector(
    (state) => state.preUploadedFiles
  )

  const azureClient = useContext(AzureClientContext)
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )
  const dispatch = useIEDispatch()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [headerOptions, setHeaderOptions] = useState([
    { header_row: null, probability: null },
  ])
  const [currentFile, setCurrentFile] = useState(null)
  const [currentFilePath, setCurrentFilePath] = useState('')
  const [preUploadFile, setPreUploadFile] = useState(false)

  useEffect(() => {
    if (preloadFileObj && preUploadedFiles.length > 0) {
      const file = preUploadedFiles.find((file) => {
        const diEngagementName = draftRun.engagement.name
        const diClientName = draftRun.auditedEntity.name

        const clientName = file.name.split('/')[1]
        const engagementName = file.name.split('/')[2]

        const matchedFile = file.name
          ?.split('/')
          ?.pop()
          ?.match(/\d{6,8}_\d{2,4}_(.*)/)
        if (
          matchedFile !== null &&
          matchedFile[1] === preloadFileObj?.fileName &&
          engagementName === diEngagementName &&
          clientName === diClientName
        ) {
          return true
        }
      })
      if (file) {
        copyPreUploadFileToDI(file)
      } else {
        dispatch(
          updateFileMapping({
            originalFileName: cdmFileName,
            reportType,
            state: UPLOAD_STATE.PRE_UPLOADED_INPUT_FILE_NOT_FOUND,
          })
        )
      }
    }
  }, [preUploadedFiles])

  const fileInfo = {
    erpId: draftRun.erpId,
    extractType: draftRun.extractType,
    reportType,
    reportName,
    cdmFileName,
    mandatoryFieldsData: [],
    mandatoryFieldsValid: false,
    imperfectFilesSummary: [],
    imperfectFilesFields: [],
    imperfectFilesValid: false,
    cdmDetails: [],
  }

  /**
   * 1) Load mapping when user uploads new file with premapped data
   * 2) This function will run for each file upload
   * 3) Update the manualSelection in custom mapper slice
   */
  const updateReduxStatePreMapping = ({ mapping, fields }, fileName) => {
    const defaultMapping = mapping.map((item: CDMMapping) => ({
      map_type: item.map_type,
      cdm_field: item.name,
      erp_fields: item.erp_fields,
      file_name: fields[0]?.file_name,
      report_type: item.report_type,
      data_type: item.data_type,
      original_filename: fileName,
      mandatory: item.mandatory,
      erp_Id: draftRun.erpId,
      extract_type: draftRun.extractType,
    }))

    dispatch(resetManualSelections(defaultMapping))
  }

  const handleInputChange = (event, newInputValue) => {
    const sanitizedInput = newInputValue.replace(/\D/g, '')
    const numericValue =
      sanitizedInput === '' ? '' : parseInt(sanitizedInput, 10)
    const limitedValue =
      numericValue === '' || numericValue <= 50 ? numericValue : 50

    setValue(limitedValue.toString())
  }

  useEffect(() => {
    if (value !== '') {
      dispatch(
        updateFileMapping({
          header_row: parseInt(value),
          originalFileName: cdmFileName,
          reportType,
        })
      )
      dispatch(fetchStart({}))
      const currentFiles = cMapper.files.find(
        (item) =>
          item.cdmFileName === cdmFileName && item.reportType === reportType
      )
      if (currentFiles) {
        const payload = {
          ...currentFiles,
          header_row: parseInt(value),
        }
        dispatch(updateFileInfo(payload))
      }
      dispatch(fetchStop({}))

      if (fileMapping?.header_row) {
        const customFileMappingsEntries = Object.entries(
          cMapper?.customFileMappings || {}
        )

        const filteredCustomFileMappings = Object.fromEntries(
          customFileMappingsEntries?.filter(
            ([key, mapping]) =>
              mapping?.file_name !== `${reportType}_${cdmFileName}`
          )
        )

        dispatch(setCustomFileMappings(filteredCustomFileMappings))

        const filteredManualSelections = cMapper?.manualSelections?.filter(
          (selection) => selection?.file_name !== `${reportType}_${cdmFileName}`
        )

        dispatch(clearSingleFileManualSelection(filteredManualSelections))
      }

      checkFilePath(currentFile, currentFilePath, preUploadFile)
    }
  }, [value])

  useEffect(() => {
    let highestProbability = -1
    let headerRowWithHighestProbability = null

    for (const item of headerOptions) {
      if (item.probability > highestProbability) {
        highestProbability = item.probability
        headerRowWithHighestProbability = item.header_row
      }
    }
    if (headerRowWithHighestProbability !== null) {
      setValue(headerRowWithHighestProbability.toString())
    }
  }, [headerOptions])

  const handleIdentifyHeader = async (file, incomingPath) => {
    setLoading(true)
    setCurrentFile(file)
    setCurrentFilePath(incomingPath)
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    const requestJSON: any = {}
    requestJSON['filePath'] = `${azureContainer.fileSystemId}/${incomingPath}`
    const backendResponse = await axios.post(
      `${process.env.NX_CUSTOM_MAPPER_API_URL}/erp/identify-header`,
      requestJSON,
      { headers, cancelToken: source.token }
    )
    if (backendResponse.status === 200) {
      setUploaded(true)
      const result = backendResponse.data
      setHeaderOptions(result.probableHeaders)
      dispatch(
        updateFileMapping({
          originalFileName: cdmFileName,
          reportType,
          headerOptions: result.probableHeaders,
        })
      )
      setLoading(false)
    } else {
      console.error('Backend function request failed')
    }
  }

  const createRequestJSON = (file, incomingPath) => {
    const requestJSON: any = {}
    const filePath = incomingPath || fileMapping.fileNameByUser
    requestJSON['erp_Id'] = draftRun.erpId
    requestJSON['extract'] = draftRun.extractType
    requestJSON['report'] = reportType
    requestJSON['file_name'] = cdmFileName
    requestJSON['file_path'] = azureContainer.fileSystemId + '/' + filePath
    requestJSON['audit_firm_id'] =
      erps[draftRun.erpId]?.auditFirm?.systemName || null
    requestJSON['header_row_number'] = parseInt(value)
    return requestJSON
  }

  const handleResponse = async (response, file, fromPreUpload) => {
    const fileName = fileMapping?.fileNameByUser.split(draftRun.id + '_').pop()
    const payload = {
      ...fileInfo,
      inputFileName: file?.name || fileName,
      mandatoryFieldsData: response.data.check_result.mfc.data,
      mandatoryFieldsValid: response.data.check_result.mfc.valid,
      imperfectFilesSummary: response.data.check_result.ifc.summary,
      imperfectFilesFields: response.data.check_result.ifc.fields,
      imperfectFilesValid: response.data.check_result.ifc.valid,
      cdmDetails: response.data.cdm,
      records: response.data.records,
      header_row: parseInt(value),
    }
    if (fromPreUpload === false) {
      if (file) {
        payload['file'] = await binaryToBase64(file)
      } else {
        payload['file'] = cMapper.files.find(
          (item) =>
            item.cdmFileName === cdmFileName && item.reportType === reportType
        )?.file
      }
    }
    cMapper.files.findIndex(
      (item) =>
        item.cdmFileName === cdmFileName && item.reportType === reportType
    ) > -1
      ? dispatch(updateFileInfo(payload))
      : dispatch(saveFileInfo(payload))

    dispatch(
      updateFileMapping({
        originalFileName: cdmFileName,
        reportType,
        records: response.data.records,
        state:
          response.data.check_result.ifc.valid &&
          response.data.check_result.mfc.valid
            ? UPLOAD_STATE.VALIDATING_FILE_COMPLETE
            : UPLOAD_STATE.FILE_VALIDATION_FAILED,
      })
    )
    updateReduxStatePreMapping(response.data.cdm, file?.name || fileName)
    dispatch(fetchStop({}))
  }

  const checkFilePath = async (file, incomingPath, fromPreUpload = false) => {
    const requestJSON = createRequestJSON(file, incomingPath)

    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    dispatch(fetchStart({}))
    dispatch(
      updateFileMapping({
        originalFileName: cdmFileName,
        reportType,
        state: UPLOAD_STATE.VALIDATING_FILE,
        size: file?.size || file?.contentLength || fileMapping.size,
        fileNameByUser: incomingPath || fileMapping.fileNameByUser,
      })
    )
    try {
      const response = await axios.post(
        CUSTOM_MAPPER_CHECK_FILE_PATH,
        requestJSON,
        { headers, cancelToken: source.token }
      )
      if (response.status === StatusCodes.OK) {
        await handleResponse(response, file, fromPreUpload)
      }
    } catch (err) {
      dispatch(
        updateFileMapping({
          originalFileName: cdmFileName,
          reportType,
          state: UPLOAD_STATE.ERROR,
          size: file?.size || fileMapping.size,
          fileNameByUser: file?.name || fileMapping.fileNameByUser,
        })
      )
      dispatch(fetchStop({}))
    }
  }

  const handleFileUploadProgress = (progress) =>
    dispatch(
      updateFileMapping({
        originalFileName: cdmFileName,
        reportType,
        uploaded: progress.loadedBytes,
      })
    )

  const handleExcelFileUploadProgress = (progress) =>
    dispatch(
      updateFileMapping({
        originalFileName: cdmFileName,
        reportType,
        uploaded: progress.loadedBytes / 2,
      })
    )
  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  let activeStatus = { value: true }

  const handleConvertExcelToCsvProgress = (progress: number) => {
    return async (dispatch) => {
      let i = 2.0
      while (i >= 1.0 && activeStatus.value) {
        await dispatch(
          updateFileMapping({
            originalFileName: cdmFileName,
            reportType,
            uploaded: progress / i,
          })
        )
        i -= 0.01
        await delay(100)
      }
    }
  }

  const uploadFile = async (e, ref = null) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore

    const file: File = Array.from(e)[0]

    if (isValidFileType(file)) {
      let allowed_file_size: number
      let activeUpload: string

      if (process.env.NX_ENABLE_50_GB_FILE_UPLOAD === 'true') {
        allowed_file_size = FILE_HELPER.FILE_SIZE_50_GIGABYTES
        activeUpload = '50GB'
      } else if (process.env.NX_ALLOW_1GB_FILES === 'true') {
        allowed_file_size = FILE_HELPER.FILE_SIZE_ONE_GIGABYTES
        activeUpload = '1GB'
      } else {
        allowed_file_size = FILE_HELPER.FILE_SIZE_TEN_MEGABYTES
      }

      if (file.size > allowed_file_size) {
        dispatch(fetchStart({}))
        const payload = {
          ...fileInfo,
          inputFileName: file.name,
        }

        let errorMessage: string = 'File size should be less than '

        if (activeUpload === '50GB') {
          payload['error'] = errorMessage + '50 GB'
        } else if (activeUpload === '1GB') {
          payload['error'] = errorMessage + '1 Gb'
        } else {
          payload['error'] = errorMessage + '10 MB'
        }

        cMapper.files.findIndex(
          (item) =>
            item.cdmFileName === cdmFileName && item.reportType === reportType
        ) > -1
          ? dispatch(updateFileInfo(payload))
          : dispatch(saveFileInfo(payload))

        dispatch(
          updateFileMapping({
            originalFileName: cdmFileName,
            reportType,
            state: UPLOAD_STATE.VALIDATE_FILE_SIZE,
          })
        )
        return
      }

      const today = new Date()
      const dataAndTime = `${today.getFullYear()}${
        today.getMonth() + 1
      }${today.getDate()}_${today.getHours()}${today.getMinutes()}${today.getSeconds()}${today.getMilliseconds()}`

      try {
        dispatch(
          updateFileMapping({
            originalFileName: cdmFileName,
            reportType,
            size: file.size,
            uploaded: 0,
            header_row: '',
            state: UPLOAD_STATE.IN_PROGRESS,
          })
        )
        setValue('')
        const name = file?.name
        if (name.includes('.xlsx') || name.includes('.xls')) {
          if (!file) {
            console.error('No file selected')
            return
          }
          if (file.size <= FILE_HELPER.FILE_SIZE_100_MEGABYTES) {
            const uploadPath = `${draftRun.container.incomingPath}/upload_${dataAndTime}_${draftRun.id}_${name}`
            const response = await uploadFileToAzureContainer({
              azureClient,
              containerId: azureContainer.fileSystemId,
              file,
              inputPath: uploadPath,
              uploadOptions: {
                onProgress: handleExcelFileUploadProgress,
              },
            })
            if (response) {
              const { token } = await asyncTokenLookup({
                instance,
                inProgress,
                accounts,
                tokenRequest: protectedResources.dataIngestionApi,
              })
              const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
              const requestJSON: any = {}
              requestJSON[
                'file_path'
              ] = `${azureContainer.fileSystemId}/${draftRun.container.incomingPath}/upload_${dataAndTime}_${draftRun.id}_${file.name}`
              dispatch(handleConvertExcelToCsvProgress(file.size))
              try {
                const uploadFileResponse = await axios.post(
                  `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/convert-excel-to-csv`,
                  requestJSON,
                  { headers, cancelToken: source.token }
                )
                console.log("Response from convert-excel-to-csv")
                console.log(uploadFileResponse)
                const result = uploadFileResponse.data.csv_file_path
                if (uploadFileResponse.status === 200) {
                  activeStatus.value = false
                  dispatch(
                    updateFileMapping({
                      originalFileName: cdmFileName,
                      reportType,
                      uploaded: file.size,
                    })
                  )
                  setTimeout(() => {
                    dispatch(
                      updateFileMapping({
                        originalFileName: cdmFileName,
                        reportType,
                        state: UPLOAD_STATE.VALIDATING_FILE,
                      })
                    )
                    handleIdentifyHeader(file, result)
                  }, 1000)
                }else{
                  console.log("Error occured from convert-excel-to-csv ")
                  console.log(e)
                  activeStatus.value = false
                  dispatch(
                    updateFileMapping({
                      originalFileName: cdmFileName,
                      reportType,
                      state: UPLOAD_STATE.ERROR_EXCEL_FILE_CONVERSION,
                    })
                  )
                }
              } catch (e) {
                console.log("Error occured from convert-excel-to-csv ")
                console.log(e)
                activeStatus.value = false
                dispatch(
                  updateFileMapping({
                    originalFileName: cdmFileName,
                    reportType,
                    state: UPLOAD_STATE.ERROR_EXCEL_FILE_CONVERSION,
                  })
                )
              }
            }
          } else {
            dispatch(
              updateFileMapping({
                originalFileName: cdmFileName,
                reportType,
                state: UPLOAD_STATE.VALIDATE_EXCEL_FILE_SIZE,
              })
            )
          }
        } else {
          //incoming/<client>/<engagement>/upload_<dataandtime>_<uploadedfilename>.<ext>
          const incomingPath = `${draftRun.container.incomingPath}/upload_${dataAndTime}_${draftRun.id}_${file.name}`
          await uploadFileToAzureContainer({
            azureClient,
            containerId: azureContainer.fileSystemId,
            file,
            inputPath: incomingPath,
            uploadOptions: {
              onProgress: handleFileUploadProgress,
            },
          })
          dispatch(
            updateFileMapping({
              originalFileName: cdmFileName,
              reportType,
              state: UPLOAD_STATE.VALIDATING_FILE,
            })
          )
          await handleIdentifyHeader(file, incomingPath)
        }
      } catch (e) {
        dispatch(
          updateFileMapping({
            originalFileName: cdmFileName,
            reportType,
            state: UPLOAD_STATE.ERROR,
          })
        )
      }
    } else {
      dispatch(
        updateFileMapping({
          originalFileName: cdmFileName,
          reportType,
          state: UPLOAD_STATE.UNSUPPORTED_FILE_TYPE,
        })
      )
    }

    //Clear Input Value
    if (ref?.current) ref.current.value = ''
  }

  const copyPreUploadFileToDI = async (file) => {
    const fileName = file?.name
    setPreUploadFile(true)
    setValue('')
    await handleIdentifyHeader(file, fileName)
  }

  const changeDeleteFlag = (e) => {
    dispatch(
      updateDeleteFlag({
        key: `${reportType}_${cdmFileName}`,
        value: e.target.checked,
      })
    )
  }

  return (
    <Grid
      container
      direction={'row'}
      className={classes.Row}
      alignItems={'center'}
    >
      <Grid item xs={12}>
        <FileUploadWidget
          index={index}
          selectedReportDetail={reportDetail}
          selectedCdmEntity={selectedCdmEntity}
          reportIndex={reportIndex}
          fileMapping={fileMapping}
          uploadFile={uploadFile}
          copyFileToDI={copyPreUploadFileToDI}
          handleDeleteEntity={handleDeleteEntity}
          auditedEntity={auditedEntity.name}
          engagementId={engagement.name}
          allowMultiple={false}
          value={fileMapping.header_row.toString()}
          setValue={setValue}
          loading={loading}
          headerOptions={fileMapping.headerOptions}
          uploaded={uploaded}
          handleInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} className={classes.fileCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={preloadFileObj?.keepCopy}
              onChange={changeDeleteFlag}
            />
          }
          label="Keep copy of file in original location"
        />
        <Tooltip
          title={
            <Typography className={classes.toolTip}>
              Keep this ticked if you want to be able to access this data again
              from here in future. Untick it if you are happy to have the data
              cleared
            </Typography>
          }
        >
          <IconInfo />
        </Tooltip>
      </Grid>
    </Grid>
  )
}
