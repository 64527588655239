import { TokenCredential, AccessToken } from '@azure/core-auth'
import { asyncTokenLookup } from './asyncTokenLookup'
export class StaticTokenCredential implements TokenCredential {
  constructor(private conf: any) {}
  async getToken(): Promise<AccessToken> {
    const token = await asyncTokenLookup({
      instance: this.conf.instance,
      inProgress: this.conf.inProgress,
      accounts: this.conf.accounts,
      tokenRequest: this.conf.tokenRequest,
    })
    return token
  }
}
