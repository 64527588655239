import React, { useRef, useEffect } from 'react'
import {
  Divider,
  makeStyles,
  FormLabel,
  Grid,
  TextField,
  FormHelperText,
} from '@material-ui/core'

export interface MathsWithBracketProps {
  count: number
  expression: string
  setExpression: (value: string) => void
  invalidExpressions: boolean
  setInvalidExpressions: (value: boolean) => void
}

const useStyles = makeStyles(() => ({
  expressionClass: {
    '& span': {
      margin: '0 5px',
    },
    '& .MuiTableCell-head': {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0px',
      color: '#00B2A9',
      padding: '16px 16px 16px 0',
    },
  },
}))

const MathsWithBracket = (props: MathsWithBracketProps) => {
  const { expressionClass } = useStyles()
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    const result = validateAggregation(props.expression)
    props.setInvalidExpressions(!result)
  }, [props.count])

  const arrChecker = (arr, target) => target.every((v) => arr.includes(v))

  function extractNumbers(expression: string) {
    if (expression.length === 0) return []
    // Regular expression to match numbers, including negative numbers
    const regex = /-?\d+/g
    // Find all matches
    const matches = expression.match(regex)
    // Convert matches to absolute numbers
    const numbers = matches.map((num) => Math.abs(Number(num)))
    return numbers
  }

  const validateAggregation = (input: string) => {
    let expression = input.trim()

    // find max number from expression
    const numsInExpression = extractNumbers(expression)
    const maxInExpression = Math.max.apply(Math, numsInExpression)
    const numOfElements = Array.from({ length: props.count }, (_, i) => i + 1)

    const isUsingAllElements = arrChecker(numsInExpression, numOfElements)

    if (!isUsingAllElements) return false

    if (maxInExpression > props.count) return false

    // Check if expression contains zero
    if (expression.includes('0')) return false

    // Check for balanced parentheses
    let openBrackets = 0
    for (let char of expression) {
      if (char === '(') openBrackets++
      if (char === ')') openBrackets--
      if (openBrackets < 0) return false // closing parenthesis before opening parenthesis
    }
    if (openBrackets !== 0) return false // unbalanced parentheses

    // Check for consecutive operators
    const consecutiveOpsRegex = /[+\-*/]{2,}/
    if (consecutiveOpsRegex.test(expression)) return false

    const startRegex = /^[+/*]/ // if the expression ends with an operator
    const endRegex = /[+\-*/]$/ // if the expression ends with an operator
    if (startRegex.test(expression)) return false
    if (endRegex.test(expression)) return false

    expression = expression.replace(/\s/g, '') // remove all spaces from expression
    expression = expression.replace(/\d/g, '') // remove all numbers from expression
    expression = expression.replace(/[\+\-\*\/\(\)]/g, '') // remove all operators from expression
    expression = expression.replace(/[\[\]]/g, '') // remove all brackets from expression

    // check if expression is empty
    if (expression.length === 0) return true

    // check if expression contains any other character
    if (expression.length > 0) return false
  }

  const handleAggregationChange = async (e) => {
    const value = e.target.value
    props.setExpression(value)
    const result = validateAggregation(value)
    props.setInvalidExpressions(!result)
  }

  return (
    <div className={expressionClass}>
      <Divider style={{ margin: '15px 0' }} />
      <Grid container item className="combo-box d-row" direction="row">
        <Grid container item className="combo-box" direction="column">
          <label>
            Expression <span>*</span>
          </label>
          <TextField
            error={props.invalidExpressions}
            size="small"
            style={{ width: '540px' }}
            placeholder="Create MCB Expression"
            value={props.expression}
            onChange={handleAggregationChange}
          />
          <Grid container item direction="column">
            {props.invalidExpressions && (
              <FormLabel error style={{ textAlign: 'left', marginTop: '8px' }}>
                There may be something wrong with this expression
              </FormLabel>
            )}
            <FormHelperText
              style={{
                width: '540px',
              }}
            >
              When crafting BODMAS expressions, adhere to the order of
              operations: prioritize operations within brackets, handle
              exponents, perform division and multiplication from left to right,
              and finally, execute addition and subtraction in the same manner.
              For clarity, utilize parentheses liberally.The best practice is to
              rewrite the expression to ensure it is accurate Doing this will
              enable to 'Apply' button
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default MathsWithBracket
