import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { NEW_UI_INPUT_WIDTH } from '@engine-b/integration-engine/ui/form-components'
import {
  basicDetailsSelector,
  getAuditedEntitiesByUser,
  getEngagements,
  resetEngagementState,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'

interface ClientEngagementDialogProps {
  dialogOpen?: boolean
  selectClientEngagement?: (e: any) => void
  onAuditedEntityChange?: (e: any, value: any, reason: string) => void
  onEngagementChange?: (e: any, value: any, reason: string) => void
  selectedClientDetails?: object
  selectedEngagementDetails?: object
  client: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    InputsBlock: {
      width: '100%',
      paddingTop: theme.spacing(4),
      textAlign: 'left',
      '& .combo-box': {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0.16px',
          color: '#44697D',
          verticalAlign: 'center',
          marginBottom: '8px',
          '& > span': {
            fontSize: '20px',
            color: '#DE4D4D',
          },
        },
        '& .MuiTextField-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'hsla(205, 34%, 34%, 1)',
          },
          width: NEW_UI_INPUT_WIDTH + 'px',
          borderRadius: '8px',
          '& .MuiInputBase-root': {
            height: '44px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid ' + theme.palette.secondary.light,
            borderRadius: '8px',
          },
        },
      },
    },
    rowItem: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '32px',
      rowGap: '26px',
    },
  })
)
const ClientEngagementDialog = (props: ClientEngagementDialogProps) => {
  const dispatch = useIEDispatch()
  const classes = useStyles()
  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { auditedEntity: selectedClientDetails } = useIESelector((state) =>
    basicDetailsSelector(state)
  )

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(props.client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(
      getEngagements({
        client: props.client,
        id: selectedClientDetails?.id || '',
      })
    )
  }, [selectedClientDetails?.id])

  return (
    <Dialog
      title="Select Client & Engagement"
      cancelText="Cancel"
      confirmText="Ok"
      width="900px"
      height="350px"
      open={props.dialogOpen}
      onClose={(e) =>
        props.selectClientEngagement && props.selectClientEngagement(e)
      }
    >
      <Grid
        container
        direction={'column'}
        spacing={2}
        className={classes.InputsBlock}
      >
        <Grid item xl={12} className={classes.rowItem}>
          <div className="combo-box">
            <label htmlFor="client-name">
              Client Name <span>*</span>
            </label>
            <EBAutoComplete
              freeSolo={true}
              forcePopupIcon={true}
              options={auditedEntities}
              getOptionLabel={(option) => option?.name || ''}
              size="small"
              value={props.selectedClientDetails}
              onChange={props.onAuditedEntityChange}
              color="primary"
              placeholder="Select Client Name"
              backgroundColor="#fff"
              required={true}
            />
          </div>
          <div className="combo-box">
            <label htmlFor="engagement-name">
              Engagement Name <span>*</span>
            </label>
            <EBAutoComplete
              freeSolo={true}
              forcePopupIcon={true}
              options={engagements}
              getOptionLabel={(option) => option.name}
              size="small"
              value={props.selectedEngagementDetails}
              onChange={props.onEngagementChange}
              hiddenLabel={true}
              color="primary"
              placeholder="Select Engagement Name"
              backgroundColor="#fff"
              required={true}
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ClientEngagementDialog