import React, { useContext, useEffect, useRef, useState } from 'react'
import { jsPDF } from 'jspdf'
import {
  Button,
  Card,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  List,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ReportFooter from '../../components/reports/ReportFooter'
import html2canvas from 'html2canvas';
import ReportTitle from '../../components/reports/ReportTitle'
import ReportCard from '../../components/reports/ReportCard'
import ReportHeader from '../../components/reports/ReportHeader'
import GroupingCardDataItem from './GroupingCardDataItem'
import ProfileScoreListItem from './ProfileScoreListItem'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { useParams, useHistory } from 'react-router-dom'
import {
  getDataIngestion,
  getDataIngestionGroupings,
  getErps,
  Run,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { ArrowBackOutlined } from '@material-ui/icons'
import { enUS, enGB } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'

const XS_GRID_SIZE = 12
const MD_GRID_SIZE = 6
const LG_GRID_SIZE = 3

const groupings_fields = [
  { heading: 'Extract Type', propertyName: '_extractType' },
  { heading: 'ERP System', propertyName: '_erpSystemName' },
  { heading: 'Engagement Name', propertyName: '_engagementName' },
  { heading: 'Version', propertyName: 'groupVersion' },
  // { heading: 'Grouping Map Name', propertyName: '' },
  { heading: 'Date & Time of Grouping', propertyName: '_completeAt' },
  { heading: 'Last Updated By', propertyName: 'groupLastUpdatedBy' },
  { heading: 'Grouping Name', propertyName: 'groupName' },
  { heading: 'Uploaded Files', propertyName: '_uploadedFilesStr' },
]

const stats_fields = [
  { heading: 'Account Codes in raw CoA', propertyName: 'accountCodesInRaw' },
  {
    heading: 'Account codes mapped using grouper',
    propertyName: 'accountCodesMappedUsingGrouper',
  },
  { heading: 'Value of Assets', propertyName: 'assets' },
  { heading: 'Value of Liabilities', propertyName: 'liabilities' },
  { heading: 'Value of Equity', propertyName: 'equity' },
  { heading: 'Value of Expenditures', propertyName: 'expenditures' },
  { heading: 'Value of Income', propertyName: 'income' },
]

const scores_fields = [
  { heading: 'Assets', propertyName: 'assets' },
  { heading: 'Liabilities', propertyName: 'liabilities' },
  { heading: 'Equity', propertyName: 'equity' },
  { heading: 'Income & Support', propertyName: 'income' },
  { heading: 'Expenditures', propertyName: 'expenditures' },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {},
    cardContainer: { flex: 1, marginBottom: '25px' },
    contentContainer: { padding: '55px !important' },
    dataContainer: {
      marginBottom: '18px',
    },
    dataHeaders: {
      font: 'normal normal bold 18px/21px Arial',
      letterSpacing: '0.18px',
      marginBottom: '12px',
      color: '#44697D',
    },
    dataValues: {
      font: 'normal normal normal 22px/26px Arial',
      letterSpacing: '0.22px',
      color: '#22353F',
      '& > li': {
        padding: 0,
      },
    },

    positionRelative: {
      position: 'relative',

      '& h4': {
        fontFamily: 'Arial, sans-serif',
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#333333',
      },
    },

    scorecardTitle: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '20px',
      color: '#333333',
    },

    downloadBtn: {
      position: 'fixed',
      top: '24px',
      right: '210px',
      zIndex: 1111,
    },
    loader: {
      height: '75vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    profileScoreCard: {
      border: '1px solid #E4E4E4',
    },
    divider: {
      backgroundColor: '#E4E4E4',
    },
    backButtonStyles: {
      minWidth: 'unset',
      marginRight: '10px',
      width: 'unset',
    },
  })
)

const GroupingsDataScoreCard = () => {
  const [loading, setLoading] = useState(true)
  const [grouping, setGrouping] = useState(false)
  const classes = useStyles()
  const azureClient = useContext(AzureClientContext)
  const urlParams = useParams<{ id: string }>()
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [data, setData] = useState<any>({
    scores: {},
  })

  const { locale, formatMessage } = useIntl()

  const client = useApolloClient()

  const history = useHistory()

  const erps = useIESelector((state) => state.erps)
  let ingestion: any = useIESelector<Run>((state) => state.runs.run)
  const dataIngestionGroupings = useIESelector<Run[]>(
    (state) => state.runs.dataIngestionGroupings
  )
  const dataIngestionId = useIESelector((state) => state.runs.dataIngestionId)
  const ingestionId = urlParams.id

  const dataIngestionGrouping: any = dataIngestionGroupings.find(
    (v) => v.id === ingestionId
  )
  const quick_groupings: any = dataIngestionGroupings?.length > 0 && dataIngestionGroupings[dataIngestionGroupings.length - 1]

  if (grouping) {
    ingestion = dataIngestionGrouping.dataIngestion
  }

  const filterFileUpload = (string, array, index) => {
    if (
      string.toLowerCase().includes('tb') ||
      string.toLowerCase().includes('trial')
    ) {
      const fileName = string.split(`_${dataIngestionId}_`).pop()
      return fileName
    } else {
      return ''
    }
  }

  if (ingestion) {
    ingestion = {
      ...ingestion,
      _extractType: ingestion?.cdmEntities[0]?.cdmEntity?.extractType,
      _engagementName: ingestion.engagement.name,
      _erpSystemName: erps[ingestion.erpId]?.name,
      _uploadedFilesStr: ingestion.uploadedFiles.reduce(
        (resultStr, file, currentIndex) =>
          resultStr +
          filterFileUpload(
            file.fileNameByUser,
            ingestion.uploadedFiles,
            currentIndex
          ),
        ''
      ),
      _completeAt: format(parseISO(quick_groupings?.endDate ? quick_groupings.endDate : ingestion.completeAt), 'P, h:mm:ss a', {
        locale: locale === 'en-US' ? enUS : enGB,
      }),
      ...(grouping ? {
        groupName: dataIngestionGrouping?.name,
        _erpSystemName: erps[ingestion?.erp?.id]?.name,
        erpId: ingestion?.erp?.id,
        groupLastUpdatedBy: dataIngestionGrouping?.createdBy,
        groupVersion: dataIngestionGrouping?.grouper?.version,
      } : quick_groupings && {
        groupName:quick_groupings?.name,
        erpId: ingestion?.erp?.id,
        groupLastUpdatedBy: quick_groupings?.createdBy,
        groupVersion: quick_groupings?.grouper?.version,
      }),
    }
  }

  const dispatch = useIEDispatch()

  const reportTemplateRef = useRef(null)
  const downloadBtnRef = useRef(null)

  async function downloadPdf() {
    try {
      setDownloadingPdf(true)

      const element = reportTemplateRef.current
      const componentWidth = element.offsetWidth
      const componentHeight = element.offsetHeight
      const orientation = componentWidth >= componentHeight ? 'l' : 'p'
      const doc = new jsPDF({
        orientation,
        unit: 'px',
        putOnlyUsedFonts: true,
        compress: true,
        format: [componentWidth + 20, componentHeight + 22],
      })

      const canvas = await html2canvas(element, {
        useCORS: true,
        backgroundColor: 'white',
        scale: 3,
      })

      const imgData = canvas.toDataURL('image/jpeg')
      doc.addImage(imgData, 'JPEG', 10, 10, componentWidth, componentHeight)
      doc.save('document')
      setDownloadingPdf(false)
    } catch (error) {
      setDownloadingPdf(false)
    }
  }

  async function getGroupingData() {
    try {
      setLoading(true)
      if (!ingestion) {
        console.log('No ingestion found')
        return
      }
      let fileSystemClient = azureClient.getFileSystemClient(
        ingestion?.container?.fileSystemId
      )

      let fileClient = fileSystemClient.getFileClient(
        `data-ingestions/${ingestionId}/share/gdqs/grouping.json`
      )
      if (dataIngestionGrouping?.id) {
        fileSystemClient = azureClient.getFileSystemClient(
          dataIngestionGrouping?.dataIngestion?.container?.fileSystemId
        )
        try {
          const iter = fileSystemClient.listPaths({
            path: `data-ingestions/${dataIngestionGrouping?.dataIngestionId}/share/grouper/${dataIngestionGrouping.id}`,
          })
          for await (const pathItem of iter) {
            if ((pathItem.name.split(".").pop() === 'json') && (pathItem.name.split("/").pop() !== 'logs.json'))
              fileClient = fileSystemClient.getFileClient(pathItem.name)
          }
        } catch (error) {
          fileClient = fileSystemClient.getFileClient(
            `data-ingestions/${dataIngestionGrouping?.dataIngestionId}/share/grouper/gdqs_${ingestionId}.json`
          )
        }
      }
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      const blob = new Blob([downloadBlob], { type: 'application/json' })
      const fileData = JSON.parse(await blob.text())

      const {
        quality: { account_detail, total_codes, mapped_codes },
      } = fileData

      setData({
        ...data,
        stats: {
          accountCodesInRaw: total_codes,
          accountCodesMappedUsingGrouper: mapped_codes,
          assets: account_detail.assets.total_value,
          liabilities: account_detail.liabilities.total_value,
          equity: account_detail.equity.total_value,
          income: account_detail.income.total_value,
          expenditures: account_detail?.expenditure?.total_value,
        },
        scores: {
          assets: account_detail?.assets?.mapped,
          liabilities: account_detail?.liabilities?.mapped,
          equity: account_detail?.equity?.mapped,
          income: account_detail?.income?.mapped,
          expenditures: account_detail?.expenditure?.mapped,
        },
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(getDataIngestion({ client: client, id: dataIngestionId }))
      .unwrap()
      .then((value) => {})
      .catch((err) => {
        dispatch(
          getDataIngestionGroupings({
            client: client,
            id: ingestionId,
            type: 'grouping',
          })
        )
          .unwrap()
          .then(() => {
            setGrouping(true)
          })
      })
  }, [client, dispatch])

  useEffect(() => {
    if (!erps || Object.keys(erps).length === 0) {
      dispatch(getErps(client))
    }
  }, [erps, dispatch, client])

  useEffect(() => {
    if (ingestion?.id || dataIngestionGrouping?.id) getGroupingData()
  }, [ingestion?.id, dataIngestionGrouping?.id])

  const { stats, scores } = data

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <Button
        id="downloadBtn"
        variant="outlined"
        ref={downloadBtnRef}
        onClick={downloadPdf}
        className={classes.downloadBtn}
        disabled={downloadingPdf}
      >
        Download Pdf
      </Button>
      <div ref={reportTemplateRef}>
        <ReportHeader />
        <Grid container className={classes.wrapper}>
          <Grid
            item
            container
            direction="column"
            className={classes.contentContainer}
          >
            <Grid item container>
              <Grid item>
                <Button
                  onClick={() =>
                    history.push(
                      localizeRouteKey(formatMessage, locale, `/status/${ingestion?.id}`),
                      {
                        softReload: true,
                      }
                    )
                  }
                  size="medium"
                  color="primary"
                  startIcon={<ArrowBackOutlined />}
                  className={classes.backButtonStyles}
                ></Button>
              </Grid>
              <Grid item className={classes.positionRelative}>
                <Typography className={classes.scorecardTitle}>
                  Grouping Data Quality Scorecard
                </Typography>
                <Typography variant="h4">{ingestion?.name}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <ReportTitle
                hasLeftBorder={false}
                text="Trial Balance -  Groupings"
                background="#00b2a9"
                color="white"
              />
            </Grid>
            <Grid item container className={classes.cardContainer}>
              <ReportCard>
                <Grid container spacing={4}>
                  {groupings_fields.map(({ heading, propertyName }) => (
                    <GroupingCardDataItem
                      xs={XS_GRID_SIZE}
                      md={MD_GRID_SIZE}
                      lg={LG_GRID_SIZE}
                      heading={heading}
                      value={ingestion ? ingestion[propertyName] : '---'}
                    />
                  ))}
                </Grid>
              </ReportCard>
            </Grid>

            <Grid item>
              <ReportTitle
                text="Statistical Score"
                background="#f2f5f6 "
                hasLeftBorder={true}
                leftBorderColor="#05b2a9"
                color="black"
              />
            </Grid>
            <Grid item container className={classes.cardContainer}>
              <ReportCard>
                <Grid container spacing={4}>
                  {stats_fields.map(({ heading, propertyName }) => (
                    <GroupingCardDataItem
                      xs={XS_GRID_SIZE}
                      md={MD_GRID_SIZE}
                      lg={LG_GRID_SIZE}
                      heading={heading}
                      value={
                        stats && stats[propertyName]
                          ? parseFloat(stats[propertyName]).toFixed(5)
                          : '---'
                      }
                    />
                  ))}
                </Grid>
              </ReportCard>
            </Grid>

            <Grid item>
              <ReportTitle
                text="Profile Score"
                background="#f2f5f6 "
                hasLeftBorder={true}
                leftBorderColor="#05b2a9"
                color="black"
              />
            </Grid>
            <Grid item>
              <Card elevation={0} className={classes.profileScoreCard}>
                <div
                  style={{
                    padding: '16px',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                  }}
                >
                  {scores_fields.map((score, index) => {
                    let scoreWithCount = {
                      ...score,
                      count: scores[score?.propertyName] || 0,
                    }
                    return (
                      <React.Fragment key={`profile-score-list-item-${index}`}>
                        <ProfileScoreListItem {...scoreWithCount} />
                        {index < scores_fields.length - 1 && (
                          <hr
                            style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <ReportFooter />
      </div>
    </>
  )
}

export default GroupingsDataScoreCard
