import { gql } from '@apollo/client'

export const GET_GROUPINGS = gql`
  query GetGroupings {
    groupings {
      id
      name
      version
      lastUpdatedBy
      auditFirmId
      complete
    }
  }
`

export const GET_GROUPING = gql`
  query GetGrouping($groupId: String) {
    grouping(groupId: $groupId) {
      id
      name
      version
      lastUpdatedBy
      auditFirmId
      complete
      groupingCodes {
        nominalCode
        glAccountName
        grouperId
        accountNameId
        mapped
        accountName
        fsCaption
        accountSubType
        accountType
        glMapNumber
      }
    }
  }
`

export const GET_GROUPING_OPTIONS = gql`
  query GetGroupingOptions($fileName: String) {
    groupingOptions(fileName: $fileName) {
      accountTypes {
        id
        accountType
      }
      accountSubTypes {
        id
        accountSubType
        accountTypeId
      }
      fsCaptions {
        id
        fsCaption
        accountSubTypeId
      }
      accountNames {
        fsCaptionId
        id
        accountName
        glMapNumber
      }
    }
  }
`

export const GET_GROUPING_RECOMMENDATIONS = gql`
  query GetGrouperRecommendations($items: [GrouperRecommendationInput!]) {
    getGrouperRecommendations(items: $items) {
      items {
        recommendations
        glAccountName
      }
    }
  }
`

export const GROUPINGS_SEARCH = gql`
  query GroupingsSearch($user: String! $page: Int! $results: Int! $clientName: String $engagementName: String) {
    groupingsSearch(user: $user page: $page results: $results clientName: $clientName engagementName: $engagementName) {
      success
      message
      page
      total
      groupings {
        id
        name
        version
        lastUpdatedBy
        auditFirmId
        complete
      }
    }
  }
`

export const GET_UGMS = gql`
  query GetUgms {
    getUgms {
      lastModified
      fileName
    }
  }
`
