import { createSlice } from '@reduxjs/toolkit'
import cuid from 'cuid'

export interface Operation {
  title: string
  value: string
  type: string
}

export interface Operands {
  title: string
  dateFormat: string
  value: any
  type: any
  subInfo: any
}

export interface InitialNode {
  id: string
  type: string
  position: any
  data: any
  deletable: boolean
}

interface Position {
  x: number
  y: number
}

export interface Expression {
  id: string
  parentNode: string
  error: string
  type: string
  conditionLabel: string
  isValid: boolean
  operations: Operation[]
  operands: Operands[]
  position: Position
  brackets: any
}

export interface ConditionalColumn {
  expressions: Expression[]
  activeExpression: number
  initialNodes: InitialNode[]
  initialEdges: any
  erpFields: any
}

const initialNodeId = cuid()

const INITIAL_STATE: ConditionalColumn = {
  expressions: [
    {
      id: initialNodeId,
      parentNode: '',
      error: '',
      type: 'condition',
      conditionLabel: 'Condition',
      isValid: false,
      operations: [{ title: '', value: '', type: '' }],
      operands: [
        { title: '', value: '', type: '', dateFormat: '', subInfo: '' },
        { title: '', value: '', type: '', dateFormat: '', subInfo: '' },
      ],
      position: { x: 50, y: 100 },
      brackets: [[], []],
    },
  ],
  activeExpression: 0,
  initialNodes: [
    {
      id: initialNodeId,
      type: 'customInput',
      position: { x: 50, y: 100 },
      data: { label: 'Condition' },
      deletable: false,
    },
  ],
  initialEdges: [],
  erpFields: [],
}

export const conditionalColumnSlice =
  createConditionalColumnSlice(INITIAL_STATE)

const expressionTypes = {
  condition: 'Condition',
  default: 'Default',
  result: 'Result',
}

/**
 * Generate label chunk based on operands, brackets, and index.
 * @param {Array} operands - Array of operands.
 * @param {Array} brackets - Array of brackets.
 * @param {number} i - Index.
 * @returns {string} - Generated label chunk.
 */
const labelChunk = (operands, brackets, i) => {
  let result = ''

  if (operands[i + 1]?.title === 'Custom Input') {
    if (['date', 'time', 'datetime'].includes(operands[i + 1]?.type)) {
      result = `${operands[i + 1]?.subInfo?.title || ''}(${new Date(
        operands[i + 1]?.value
      ).toLocaleString()})`
    } else if (typeof operands[i + 1]?.value === 'boolean') {
      result = operands[i + 1]?.value?.toString()
    } else {
      result = operands[i + 1]?.value
      if (brackets && brackets[i + 1].length > 0) {
        if (brackets[i + 1][0] === ')') {
          result += brackets[i + 1].join('')
        } else {
          result = brackets[i + 1].join('') + result
        }
      }
    }
  } else if (['date', 'time', 'datetime'].includes(operands[i + 1]?.type)) {
    result = `${operands[i + 1]?.subInfo?.title || ''}(${
      operands[i + 1]?.title
    })`
  } else {
    result = operands[i + 1]?.title
    if (brackets[i + 1].length > 0) {
      if (brackets[i + 1][0] === ')') {
        result += brackets[i + 1].join('')
      } else {
        result = brackets[i + 1].join('') + result
      }
    }
  }

  return result
}

/**
 * Build condition label based on operations, operands, and brackets.
 * This is used for each condition, result and default result.
 * @param {Array} operations - Array of operations.
 * @param {Array} operands - Array of operands.
 * @param {Array} brackets - Array of brackets.
 * @returns {string} - Generated condition label.
 */
const buildConditionLabel = (operations, operands, brackets) => {
  let initiValue = ''

  if (operands[0]?.title === 'Custom Input') {
    if (['date', 'time', 'datetime'].includes(operands[0]?.type)) {
      initiValue = `${operands[0]?.subInfo?.title || ''}(${new Date(
        operands[0]?.value
      ).toLocaleString()})`
    } else if (typeof operands[0]?.value === 'boolean') {
      initiValue = operands[0]?.value?.toString()
    } else {
      if (brackets && brackets[0].length > 0) {
        initiValue = brackets[0].join('')
      }
      initiValue += operands[0]?.value
    }
  } else if (['date', 'time', 'datetime'].includes(operands[0]?.type)) {
    initiValue = `${operands[0]?.subInfo?.title || ''}(${operands[0]?.title})`
  } else {
    if (brackets && brackets[0].length > 0) {
      initiValue = brackets[0].join('')
    }
    initiValue += operands[0]?.title
  }

  return operations.reduce(
    (name, operation, i) =>
      name + ' ' + operation?.value + ' ' + labelChunk(operands, brackets, i),
    initiValue
  )
}

function extractFields(expressions) {
  // Initialize an array to store the field names
  const fields = []

  // Iterate through each expression
  expressions.forEach((expression) => {
    // Check if the expression has operands
    if (expression?.operands) {
      // Iterate through operands and extract fields
      expression?.operands.forEach((operand) => {
        if (
          operand?.title !== 'Custom Input' &&
          operand?.value &&
          fields.findIndex((x) => x?.field_name === operand?.title) === -1
        ) {
          fields.push(operand?.value)
        }
      })
    }
  })

  return fields
}

export function createConditionalColumnSlice(initialState: ConditionalColumn) {
  return createSlice({
    name: 'conditional-column',
    initialState,
    reducers: {
      addMoreExpression: (state, { payload }) => {
        state.expressions = [
          ...state.expressions,
          {
            id: payload.id,
            parentNode: payload.parentNode,
            type: payload.expressionType,
            error: '',
            position: payload?.position,
            conditionLabel: expressionTypes[payload.expressionType],
            isValid: false,
            ...(payload.expressionType === 'result' ||
            payload.expressionType === 'default'
              ? { operations: [] }
              : { operations: [{ title: '', value: '', type: '' }] }),
            ...(payload.expressionType === 'result' ||
            payload.expressionType === 'default'
              ? {
                  operands: [
                    {
                      title: '',
                      value: '',
                      type: '',
                      dateFormat: '',
                      subInfo: '',
                    },
                  ],
                }
              : {
                  operands: [
                    {
                      title: '',
                      value: '',
                      type: '',
                      dateFormat: '',
                      subInfo: '',
                    },
                    {
                      title: '',
                      value: '',
                      type: '',
                      dateFormat: '',
                      subInfo: '',
                    },
                  ],
                }),
            ...(payload.expressionType === 'condition' && {
              brackets: [[], []],
            }),
          },
        ]

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !['', null, undefined].includes(item?.value) &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
      },

      updateExpression: (state, { payload }) => {
        if (payload.key === 'operands') {
          state.expressions[state.activeExpression][payload.key][
            payload.index
          ] = {
            ...state.expressions[state.activeExpression][payload.key][
              payload.index
            ],
            title: payload.value?.field_name || '',
            value:
              payload.value?.field_name === 'Custom Input' ? '' : payload.value,
            type:
              payload.value?.field_name !== 'Custom Input' &&
              payload?.value?.data_type,
          }
        } else if (payload.key === 'dateParams') {
          state.expressions[state.activeExpression]['operands'][payload.index] =
            {
              ...state.expressions[state.activeExpression]['operands'][
                payload.index
              ],
              subInfo: payload.value,
            }
        } else if (payload.key === 'dateFormat') {
          state.expressions[state.activeExpression]['operands'][payload.index] =
            {
              ...state.expressions[state.activeExpression]['operands'][
                payload.index
              ],
              dateFormat: payload.value,
            }
        } else {
          state.expressions[state.activeExpression][payload.key][
            payload.index
          ] = payload.value
        }

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !['', null, undefined].includes(item?.value) &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
        const { operands, operations, brackets } =
          state.expressions[state.activeExpression]

        state.expressions[state.activeExpression].conditionLabel =
          buildConditionLabel(operations, operands, brackets)

        state.erpFields = extractFields(state.expressions)
      },

      removeExpression: (state, { payload }) => {
        const { nodeIds, deletedNode } = payload
        state.activeExpression = state.expressions.length - nodeIds.length - 1

        state.expressions = state.expressions
          .filter((exp) => !nodeIds.includes(exp.id))
          .map((exp) => {
            if (
              exp?.type === 'condition' &&
              exp?.parentNode === deletedNode?.id
            ) {
              return {
                ...exp,
                parentNode: deletedNode.parentNode,
              }
            }
            return {
              ...exp,
            }
          })
          .filter((exp) => exp?.parentNode !== deletedNode?.id)

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !!(item?.value !== undefined && item?.value !== '') &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
        state.activeExpression = state.expressions.findIndex(
          (x) => x.id === deletedNode?.parentNode
        )

        state.erpFields = extractFields(state.expressions)
      },

      updateCustomInput: (state, { payload }) => {
        if (payload.key === 'type') {
          state.expressions[state.activeExpression].operands[
            payload.operandIndex
          ].value = ''

          if (payload.value === 'datetime') {
            state.expressions[state.activeExpression].operands[
              payload.operandIndex
            ].dateFormat = 'yyyy-MM-ddTHH:mm'
          } else {
            delete state.expressions[state.activeExpression].operands[
              payload.operandIndex
            ]?.dateFormat
          }
        }
        state.expressions[state.activeExpression].operands[
          payload.operandIndex
        ][payload.key] = payload.value

        const { operands, operations, brackets } =
          state.expressions[state.activeExpression]

        state.expressions[state.activeExpression].conditionLabel =
          buildConditionLabel(operations, operands, brackets)

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !['', null, undefined].includes(item?.value) &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
      },

      addMoreOperand: (state, { payload }) => {
        state.expressions[state.activeExpression].operands.push({
          title: '',
          value: '',
          type: '',
          dateFormat: '',
          subInfo: '',
        })
        state.expressions[state.activeExpression].operations.push({
          title: '',
          value: '',
          type: '',
        })
        state.expressions[state.activeExpression].brackets.push([])

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !['', null, undefined].includes(item?.value) &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
      },

      removeOperand: (state, { payload }) => {
        state.expressions[state.activeExpression].operands.splice(
          payload.operandIndex,
          1
        )
        state.expressions[state.activeExpression].operations.splice(
          payload.operationIndex,
          1
        )
        state.expressions[state.activeExpression].brackets.splice(
          payload.operandIndex,
          1
        )

        const { operands, operations, brackets } =
          state.expressions[state.activeExpression]

        state.expressions[state.activeExpression].conditionLabel =
          buildConditionLabel(operations, operands, brackets)

        state.erpFields = extractFields(state.expressions)

        state.expressions = state.expressions.map((expr) => {
          return {
            ...expr,
            isValid:
              expr?.operands?.reduce(
                (acc, item) =>
                  acc &&
                  !['', null, undefined].includes(item?.value) &&
                  !!(['date', 'time', 'datetime'].includes(item?.type)
                    ? item?.subInfo && item?.dateFormat
                    : true),
                true
              ) &&
              expr?.operations?.reduce(
                (acc, item) =>
                  acc && !['', null, undefined].includes(item?.value),
                true
              ),
          }
        })
      },

      updateActiveExpression: (state, { payload }) => {
        state.activeExpression = payload.index
      },

      updateExpressionError: (state, { payload }) => {
        const expressions = state.expressions.map((expr) => {
          const err = payload.find((x) => x.id === expr.id)
          if (err) {
            return {
              ...expr,
              error: err?.error,
            }
          } else {
            return {
              ...expr,
              error: '',
            }
          }
        })
        state.expressions = expressions
      },

      changeExpressions: (state, { payload }) => {
        state.expressions = []
        state.initialNodes = []

        state.expressions = payload.expressions.map((expr) => ({
          ...expr,
          condtionLabel: buildConditionLabel(
            expr?.operations,
            expr?.operands,
            expr?.brackets
          ),
        }))

        state.initialNodes = state.expressions.map((expression, i) => {
          const res = {
            id: expression.id,
            position: expression?.position,
            data: {
              label: expression.conditionLabel,
            },
            ...(i === 0 && {
              deletable: false,
            }),
            type: '',
          }
          if (i === 0) {
            res.type = 'customInput'
          } else if (expression.type === 'condition') {
            res.type = 'customOutput'
          } else if (expression.type === 'result') {
            res.type = 'customResult'
          } else {
            res.type = 'defaultResult'
          }

          return res
        })

        state.initialEdges = state.expressions.map((exp) => ({
          id: exp.id,
          source: exp.parentNode,
          target: exp.id,
          type: 'smoothstep',

          label: exp.type === 'result' ? 'True' : 'False',
          labelStyle: { fill: exp.type === 'result' ? '#3cb2aa' : '#FF0072' },
          style: {
            stroke: exp.type === 'result' ? '#3cb2aa' : '#FF0072',
          },
          animated: true,
        }))

        state.erpFields = extractFields(state.expressions)
      },
      addBracket: (state, { payload }) => {
        state.expressions[state.activeExpression].brackets[payload.index].push(
          payload.value
        )
        const { operands, operations, brackets } =
          state.expressions[state.activeExpression]

        state.expressions[state.activeExpression].conditionLabel =
          buildConditionLabel(operations, operands, brackets)
      },
      removeBracket: (state, { payload }) => {
        state.expressions[state.activeExpression].brackets[payload].pop()

        const { operands, operations, brackets } =
          state.expressions[state.activeExpression]

        state.expressions[state.activeExpression].conditionLabel =
          buildConditionLabel(operations, operands, brackets)
      },
      clearExpressions: (state) => {
        state.expressions = INITIAL_STATE.expressions
        state.activeExpression = 0
        state.initialEdges = INITIAL_STATE.initialEdges
        state.initialNodes = INITIAL_STATE.initialNodes
        state.erpFields = INITIAL_STATE.erpFields
      },

      updateExpressionPosition: (state, { payload }) => {
        state.expressions[state.activeExpression].position = payload?.position
      },
    },
  })
}

export const {
  updateExpression,
  updateCustomInput,
  addMoreOperand,
  removeOperand,
  updateActiveExpression,
  addMoreExpression,
  removeExpression,
  changeExpressions,
  clearExpressions,
  updateExpressionError,
  updateExpressionPosition,
  addBracket,
  removeBracket,
} = conditionalColumnSlice.actions

export const conditionalColumnReducer = conditionalColumnSlice.reducer
