import { AuditFirm, IBundlesType, IFunctionsType, IOperationLogsType } from '@engine-b/shared/types'

export enum OPERATION_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIALIZED = 'INITIALIZED',
  FAILED_TO_PROCESS = 'FAILED_TO_PROCESS',
}

export type operationStatusTitleMap = Record<OPERATION_STATUS, string>

export const OPERATION_STATUS_COLORS: operationStatusTitleMap = {
  [OPERATION_STATUS.COMPLETED]: '#37AB3F',
  [OPERATION_STATUS.IN_PROGRESS]: '#37AB3F',
  [OPERATION_STATUS.INITIALIZED]: '',
  [OPERATION_STATUS.FAILED]: '#DE4D4D',
  [OPERATION_STATUS.FAILED_TO_PROCESS]: '#DE4D4D',
}

export enum LOADING {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface IFunctionsFilters {
  filterColumn: {
    id: string,
    name: string
  },
  filterCondition: {
    id: string,
    name: string
  },
  input: string
}

export interface Step {
  id: number,
  name: number
  value?: string
}

export interface IFunctions {
  id: any
  value: {
    id: string,
    name: string
  },
  step: {
    id: string,
    name: string
  },
  stepOptions: Array<Step>,
  status: OPERATION_STATUS,
  filters: Array<IFunctionsFilters>
}

export interface IClientDetails {
  id: string
  name: string
  auditFirm: AuditFirm | null
}

export interface IAuditAnalytics {
  name: string,
  selectedClientDetails: IClientDetails,
  selectedEngagementDetails: {
    id: string
    name: string
  },
  selectedIngestionDetails: {
    id: string
    name: string,
  }
  selectedFilePath: {
    id: string
    name: string
  }
  selectedBundleDetails: {
    id: string
    name: string
  }
  functions: Array<IFunctions>
  isLoading: LOADING
  operationID: string
  loading: boolean
  operationLogs: Array<IOperationLogsType>
  functionsList: Array<IFunctionsType>
  bundlesList: Array<IBundlesType>
}