import { DataLakeServiceClient } from '@azure/storage-file-datalake'
interface DeleteFileFromAzureContainerProps {
  azureClient: DataLakeServiceClient
  fileSystemId: string
  filePath: string
}

export async function deleteFileFromAzureContainer(
  azureProps: DeleteFileFromAzureContainerProps
) {
  const { azureClient, fileSystemId, filePath } = azureProps
  const containerClient = azureClient.getFileSystemClient(fileSystemId)
  const fileClient = containerClient.getFileClient(filePath)
  if (fileClient) {
    await fileClient.deleteIfExists()
  }
}
