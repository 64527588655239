import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Input, InputProps } from '@material-ui/core'
import { INPUT_WIDTH_LONG_TEXT } from './constants'

const useTextInputClasses = makeStyles((theme) =>
  createStyles({
    MuiInput: {
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '0.5px solid ' + theme.palette.secondary.light,
      padding: '2px 10px',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '12px',
      width: `${INPUT_WIDTH_LONG_TEXT}px`,

      '&:before': {
        border: 'none',
      },
      '& .MuiInput-underline:not(.Mui-disabled):before': {
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },

      '&:hover:not(.Mui-disabled):before': {
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },
    },
  })
)

export type TextInputProps = InputProps & { testId?: string }
export function TextInput(props: TextInputProps) {
  const { className, testId, ...rest } = props
  const classes = useTextInputClasses()
  return (
    <Input
      {...rest}
      inputProps={{ 'data-testid': testId }}
      className={clsx(classes.MuiInput, className)}
    />
  )
}
