import { Grid } from '@material-ui/core'
import React from 'react'
import headerImg from '../../../../integration-engine-custom-mapper-api/app/assets/PDF-Header.png'

const ReportHeader = () => {
  return (
    <Grid container>
      <Grid item>
        <div>
          <img
            src={headerImg}
            alt=""
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default ReportHeader
