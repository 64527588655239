import styles from './selected-file.module.css'
import {
  makeStyles,
  Theme,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import icValide from './assets/ic_check_validate.svg'
import icNotValide from './assets/ic_cross_not_validate.svg'
import icWarning from './assets/ic_cross_not_validate.svg'
import icValidatingFile from './assets/ic_info.svg'
import icFile from './assets/ic_file.svg'
import Alert from '@material-ui/lab/Alert'
import { ReactComponent as IconDeleteDisabled } from './assets/ic_delete_disabled.svg'
import { ReactComponent as IconDeleteEnabled } from './assets/ic_delete_enabled.svg'
import { JOINS_UPLOAD_STATE as UPLOAD_STATE } from '@engine-b/integration-engine/data/state/redux'
import { InfoOutlined } from '@material-ui/icons'

/* eslint-disable-next-line */

export interface SelectedFileProps {
  fileName: string
  uploadState: UPLOAD_STATE
  progress: number
  onDelete: (e: any) => void
  onStateUpdate: (state: UPLOAD_STATE) => void
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  fileContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    display: 'flex',
    padding: 12,
    width: '100%',
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'space-between',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  itemInfo: {
    display: 'flex',
    flex: 1,
  },
  itemInfoContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75%',
  },
  itemName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  itemValidationSuccess: {
    color: theme.palette.success.main,
    fontSize: 10,
  },
  itemValidationFail: {
    color: theme.palette.error.main,
    fontSize: 10,
  },
  itemFileIcon: {
    display: 'inline-block',
    backgroundImage: `url(${icFile})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: '35px',
    width: '28px',
    marginRight: 10,
  },
  deleteItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  deleteItemIcon: {
    display: 'inline-block',
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  },
  Alert: {
    width: '100%',
    '& .MuiAlert-icon': {
      cursor: 'pointer',
      color: '#0288d1',
    },
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  LoadingText: {
    paddingLeft: '12px',
  },
  stateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    backgroundImage: `url(${icWarning})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginRight: 5,
  },
  validIcon: {
    backgroundImage: `url(${icValide})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginRight: 5,
  },
  notValidIcon: {
    backgroundImage: `url(${icNotValide})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginRight: 5,
  },
  validatingIcon: {
    backgroundImage: `url(${icValidatingFile})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    cursor: 'pointer',
    height: 12,
    width: 12,
    marginRight: 5,
  },
  valideState: {
    textAlign: 'left',
    font: theme.typography.fontFamily,
    display: 'inline-block',
    letterSpacing: 0.14,
    color: '#37AB3F',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    '@media (max-width:1300px)': {
      fontSize: 9,
    },
  },
  invalideState: {
    textAlign: 'left',
    font: theme.typography.fontFamily,
    display: 'inline-block',
    letterSpacing: 0.14,
    color: '#DE4D4D',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    '@media (max-width:1300px)': {
      fontSize: 9,
    },
  },
  warningState: {
    textAlign: 'left',
    font: theme.typography.fontFamily,
    display: 'inline-block',
    letterSpacing: 0.14,
    color: '#F7931E',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    '@media (max-width:1300px)': {
      fontSize: 9,
    },
  },
}))

export function SelectedFile(props: SelectedFileProps) {
  const classes = useStyles({})
  useEffect(() => {
    props.onStateUpdate(props.uploadState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uploadState])
  const ConfirmCard = ({ icon, state, text }: any) => (
    <Grid container className={classes['fileContainer']}>
      <Grid item xs={10} className={classes['itemInfo']}>
        <div className={classes['itemIcon']}>
          <span className={classes['itemFileIcon']}></span>
        </div>
        {props.uploadState === UPLOAD_STATE.IN_PROGRESS ? (
          <Alert
            severity={'info'}
            className={classes['Alert']}
            icon={
              <Tooltip
                title={props.uploadState}
                arrow
                placement="bottom-start"
              >
                <InfoOutlined />
              </Tooltip>
            }
          >
            <Grid container direction="row" alignItems={'center'}>
              <Grid item xs={8}>
                <LinearProgress
                  value={props.progress}
                  color={'secondary'}
                  variant={'determinate'}
                />
              </Grid>
              <Grid item xs={4} className={classes['LoadingText']}>
                {props.progress.toFixed(0)}%
              </Grid>
            </Grid>
          </Alert>
        ) : (
          <div className={classes['itemInfoContent']}>
            <span className={classes['itemName']}>{props.fileName}</span>
            <span className={classes['stateWrapper']}>
              <span className={icon} />
              <Typography className={state}>{text}</Typography>
            </span>
          </div>
        )}
      </Grid>
      {props.uploadState !== UPLOAD_STATE.IN_PROGRESS ? (
        <Grid item xs={2} className={classes['deleteItem']}>
          {props.uploadState === UPLOAD_STATE.NOT_STARTED ? (
            <IconDeleteDisabled className={classes['deleteItemIcon']} />
          ) : (
            <IconButton onClick={(e) => props.onDelete(e)} component="span">
              <IconDeleteEnabled className={classes['deleteItemIcon']} />
            </IconButton>
          )}
        </Grid>
      ) : null}
    </Grid>
  )

  const Message: { [key: string]: any } = {
    [UPLOAD_STATE.COMPLETE]: (
      <ConfirmCard
        icon={classes['validIcon']}
        state={classes['valideState']}
        text={'Upload complete!'}
      />
    ),
    [UPLOAD_STATE.ERROR]: (
      <ConfirmCard
        icon={classes['notValidIcon']}
        state={classes['invalideState']}
        text={`Unable to upload files, please try again!`}
      />
    ),
    [UPLOAD_STATE.UNSUPPORTED_FILE_TYPE]: (
      <ConfirmCard
        icon={classes['notValidIcon']}
        state={classes['invalideState']}
        text={`Unsupported file format!`}
      />
    ),
    [UPLOAD_STATE.NOT_STARTED]: (
      <ConfirmCard
        icon={classes['warningIcon']}
        state={classes['warningState']}
        text={'Please upload a file to proceed.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE]: (
      <ConfirmCard
        icon={classes['validatingIcon']}
        state={classes['warningState']}
        text={'Validating the File.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE_COMPLETE]: (
      <ConfirmCard
        icon={classes['validIcon']}
        state={classes['valideState']}
        text={'File validated successfully.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE_FAILED]: (
      <ConfirmCard
        icon={classes['notValidIcon']}
        state={classes['invalideState']}
        text={'File Validation Terminated.'}
      />
    ),
    [UPLOAD_STATE.FETCHING_COLUMNS]: (
      <ConfirmCard
        icon={classes['validatingIcon']}
        state={classes['warningState']}
        text={UPLOAD_STATE.FETCHING_COLUMNS}
      />
    ),
    [UPLOAD_STATE.FETCHING_COLUMNS_FAILED]: (
      <ConfirmCard
        icon={classes['notValidIcon']}
        state={classes['invalideState']}
        text={UPLOAD_STATE.FETCHING_COLUMNS_FAILED}
      />
    ),
    [UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE]: (
      <ConfirmCard
        icon={classes['validatingIcon']}
        state={classes['warningState']}
        text={UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE}
      />
    ),
  }

  return Message[props?.uploadState] || Message[UPLOAD_STATE.NOT_STARTED]
}

export default SelectedFile
