import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  eBanner: {
    height: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #44697D33',
    borderRadius: '10px',
    '& .MuiCardContent-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    display: 'flex',
    flex: 1,
  },
  bannerText: {
    font: 'normal normal bold 36px/42px Arial',
    letterSpacing: '0.36px',
    color: '#89A0AC',
    opacity: '0.4',
  },
}))

const EmptyBanner = ({ text = '' }) => {
  const { eBanner, bannerText } = useStyles()

  return (
    <Card className={eBanner}>
      <CardContent>
        <Typography className={bannerText}>{text}</Typography>
      </CardContent>
    </Card>
  )
}

export default EmptyBanner
