import { gql } from '@apollo/client'

export const GET_ENTITIES = gql`
  query Entities {
    entities {
      name
      displayName
      standard {
        name
        displayName
      }
    }
  }
`
