import { AuditFirm } from '@engine-b/shared/types'

export enum erp_status {
  STARTING = 'STARTING',
  INITIALISING_AUTH = 'INITIALISING_AUTH',
  AWAITING_AUTH = 'AWAITING_AUTH',
  AUTH_OK = 'AUTH_OK',
  GETTING_POST_AUTH_OPTIONS = 'GETTING_POST_AUTH_OPTIONS',
  AWAITING_POST_AUTH_CONFIG = 'AWAITING_POST_AUTH_CONFIG',
  VALIDATING_POST_AUTH_CONFIG = 'VALIDATING_POST_AUTH_CONFIG',
  CONNECTED = 'CONNECTED',
  EXITING = 'EXITING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export type erpStatusTitleMap = Record<erp_status, string>

export const ERP_STATUS_LABELS: erpStatusTitleMap = {
  [erp_status.STARTING]: 'Starting Authorization',
  [erp_status.INITIALISING_AUTH]: 'Initializing Authorization',
  [erp_status.AWAITING_AUTH]: 'Awaiting Authorization',
  [erp_status.AUTH_OK]: 'Authorization Accepted',
  [erp_status.GETTING_POST_AUTH_OPTIONS]: 'Getting Post Auth Options',
  [erp_status.AWAITING_POST_AUTH_CONFIG]: 'Awaiting Post Auth Configuration',
  [erp_status.VALIDATING_POST_AUTH_CONFIG]:
    'Validating Post Auth Configuration',
  [erp_status.CONNECTED]: 'Connected',
  [erp_status.EXITING]: 'Exiting',
  [erp_status.FAILED]: 'Failed',
  [erp_status.CANCELLED]: 'Cancelled',
  [erp_status.DONE]: 'Done',
}

export enum extraction_status {
  STARTING = 'STARTING',
  AWAITING_AUTH = 'AWAITING_AUTH',
  CHECKING_AUTH = 'CHECKING_AUTH',
  STARTING_EXTRACT = 'STARTING_EXTRACT',
  EXTRACTING = 'EXTRACTING',
  RATE_LIMITED = 'RATE_LIMITED',
  FINISHING_EXTRACT = 'FINISHING_EXTRACT',
  EXITING = 'EXITING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export type extractionsStatusMap = Record<extraction_status, string>

export const EXTRACTION_STATUS_LABELS: extractionsStatusMap = {
  [extraction_status.STARTING]: 'Starting',
  [extraction_status.AWAITING_AUTH]: 'Awaiting Authorization',
  [extraction_status.CHECKING_AUTH]: 'Checking Authorization',
  [extraction_status.STARTING_EXTRACT]: 'Starting Extraction',
  [extraction_status.EXTRACTING]: 'Extracting',
  [extraction_status.RATE_LIMITED]: 'Rate Limited',
  [extraction_status.FINISHING_EXTRACT]: 'Finishing Extraction',
  [extraction_status.EXITING]: 'Exiting',
  [extraction_status.FAILED]: 'Failed',
  [extraction_status.CANCELLED]: 'Cancelled',
  [extraction_status.DONE]: 'Completed',
}

export interface auditedEntity {
  id: string
  name: string
  auditFirm: AuditFirm | null
}

export interface erp {
  id: string
  system: string
  name: string
  state: erp_status
  login_validation: string
  expirationDateTime: string
  authorisationUrl: string
  authorisationId: string
  preAuthConfigValues: Record<string, any>
  postAuthConfigValues: Record<string, any>
  engagement: {
    id: string
    name: string
    auditedEntity: auditedEntity
  }
  deleteInProgress: boolean
}

export interface extractionItem {
  id: string
  name: string
  status: extraction_status
  progress: number
  failureReason: string
  endDateTime: string
  startDateTime: string
  erpConnection: erp
  deleteInProgress: boolean
}

export interface extractionList {
  loading: boolean
  extractionItems: extractionItem[]
  engagement: {
    id: string
    name: string
  }
  auditedEntity: {
    id: string
    name: string
    auditFirm: AuditFirm | null
  }
  erpCreate: {
    name: string
    type: string
  }
  extractionCreate: {
    name: string
    connection: string
  }
  erpEntities: string[]
  clientConnections: erp[]
  clientExtractions: extractionItem[]
  erps_types: string[]
  erps: erp[]
  postAuthOptions: any
  preAuthOptions: Record<string, any>
  connectionPreAuthOptions: Record<string, any>
  extractions: extractionItem[]
  connectionWorkflowId: string
  connectionWorkflowPreAuthData: Record<string, any>
  connectionWorkflowSystemName: string
  nangoIntegrationName: string
  message: string
  extractionCreationInProgress: boolean
}
