import React, { useState, useEffect, useRef, useContext } from 'react'
import cuid from 'cuid'
import {
  Button,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { ReactComponent as CollapseUpIcon } from '../../assets/IconCollapseUp.svg'
import { ReactComponent as CollapseDownIcon } from '../../assets/IconCollapseDown.svg'
import {
  updateEntityPreviewConfirm,
  useIEDispatch,
  useIESelector,
  updateEntity,
  extractFields,
  setEncodingOptions,
  updateCustomErpDateFieldConfig,
  UPDATE_STATE,
  updateCdmEntities,
  EBAutocompleteReason,
  setIds,
  getEngagements,
} from '@engine-b/integration-engine/data/state/redux'
/* eslint-disable-next-line */
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import { CustomERPDialog } from './CustomERPDialog'
import axios from 'axios'
import {
  Switch,
  EBAutoComplete,
  FileUploader,
} from '@engine-b/shared/components'
import { Autocomplete } from '@material-ui/lab'
import {
  AzureClientContext,
  uploadFileToAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
import ERPDialog from './ERPDialog'
import { FILE_HELPER } from '@engine-b/integration-engine/data/helper-text'
import { isValidFileType } from 'libs/integration-engine/features/file-to-cdm-entity/src/lib/is-valid-file-type'
import { useApolloClient } from '@apollo/client'
import { useIntl } from 'react-intl'

export interface FileConfigRowProps {
  index: number
  type: string
}

const useStyles = makeStyles((theme) => ({
  fcrRoot: {
    width: '100%',
    padding: '23px',
    '& .fcr__head': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > span': {
        textAlign: 'left',
        font: 'normal normal bold 20px/23px Arial',
        letterSpacing: '0.2px',
        color: '#22353F',
      },
      '& > svg': {
        cursor: 'pointer',
      },
    },
    '& .fcr__body': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginTop: '23px',
      gap: '25px',
      '& .fcr__input__group': {
        display: 'flex',
        flexDirection: 'column',
        width: '265px',
        '& > label': {
          textAlign: 'left',
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0.16px',
        },
        '& > span': {
          font: 'normal normal normal 14px/20px Arial',
          letterSpacing: '0.14px',
          color: '#44697D',
          margin: '10px 0',
        },
        '& > .MuiInputBase-root, .MuiOutlinedInput-root': {
          height: '44px',
        },
      },
    },
    '& .fcr__body.close': {
      display: 'none',
    },
    '& .fcr__footer': {
      '& > .fcr__mandatory__fields': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '23px',
        gap: '12px',
        flexWrap: 'wrap',
        '& > span': {
          font: 'normal normal bold 14px/24px Arial',
          letterSpacing: '0px',
          color: '#22353F',
        },
        '& .fcr__pills': {
          height: '30px',
          background: '#44697D26 0% 0% no-repeat padding-box',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px 15px',
          marginLeft: '12px',
          '& > span:last-child': {
            width: '22px',
            height: '22px',
            background: '#89A0AC 0% 0% no-repeat padding-box',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '9px',
          },
        },
      },
      '& > .fcr__actions': {
        marginTop: '20px',
      },
    },
    '& .MuiInputBase-input .encodingDesc': {
      display: 'none',
    },
  },
  btn_row: {
    display: 'flex',
    gap: '28px',
  },
  encodingList: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .encodingDesc': {
      fontSize: '14px',
      color: '#395c74',
      opacity: 0.5,
    },
  },
  errorMessage: {
    textAlign: 'left',
    font: 'normal normal 15px/10px Arial',
    color: 'red',
    marginTop: '10px',
  },
}))

const dateFormatItem = [
  { label: 'mm/dd/yyyy', value: 'mm/dd/yyyy', example: '09/01/2022' },
  { label: 'dd/mm/yyyy', value: 'dd/mm/yyyy', example: '01/09/2022' },
  { label: 'dd/mm/yy', value: 'dd/mm/yy', example: '01/09/22' },
  { label: 'mm/dd/yy', value: 'mm/dd/yy', example: '09/01/22' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy', example: '01-09-2022' },
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy', example: '09-14-2022' },
  { label: 'mm-dd-yy', value: 'mm-dd-yy', example: '09-14-22' },
  {
    label: 'dd/mm/yyyy HH:MM:SS',
    value: 'dd/mm/yyyy HH:MM:SS',
    example: '01/09/2022 13:08:22',
  },
  {
    label: 'dd-mm-yyyy HH:MM',
    value: 'dd-mm-yyyy HH:MM',
    example: '01-09-2022 13:08',
  },
  {
    label: 'dd-mm-yyyy HH:MM:SS',
    value: 'dd-mm-yyyy HH:MM:SS',
    example: '01-09-2022 13:08:22',
  },
  {
    label: 'dd-mm-yyyy HH.MM',
    value: 'dd-mm-yyyy HH.MM',
    example: '01-09-2022 13.08',
  },
  {
    label: 'dd-mm-yyyy HH.MM.SS',
    value: 'dd-mm-yyyy HH.MM.SS',
    example: '01-09-2022 13.08.22',
  },
  { label: 'dd-mon-yy', value: 'dd-mon-yy', example: '01-Sep-22' },
  {
    label: 'yyyy-mm-dd HH:MM:SS',
    value: 'yyyy-mm-dd HH:MM:SS',
    example: '2022-09-01 13:08:22',
  },
  {
    label: 'dd/mm/yyyy HH:MM:SS A',
    value: 'dd/mm/yyyy HH:MM:SS A',
    example: '01/09/2022 01:08:22 PM',
  },
  {
    label: 'yyyy-mm-dd HH:MM:SS.ms',
    value: 'yyyy-mm-dd HH:MM:SS.ms',
    example: '2022-09-01 13:08:22.163997',
  },
  {
    label: 'yyyy-mm-dd HH:MM',
    value: 'yyyy-mm-dd HH:MM',
    example: '2022/09/01 01:08',
  },
  {
    label: 'mm/dd/yyyy HH:MM:SS A',
    value: 'mm/dd/yyyy HH:MM:SS A',
    example: '09/01/2022 01:08:22 PM',
  },
  { label: 'yyyy', value: 'yyyy', example: '2022' },
  { label: 'yyyymmdd', value: 'yyyymmdd', example: '20220901' },
  { label: 'yyyy-mm-dd', value: 'yyyy-mm-dd', example: '2022-09-01' },
  { label: 'mm', value: 'mm', example: '09' },
  { label: 'HH:MM:SS', value: 'HH:MM:SS', example: '13:08:22' },
  { label: 'mm-dd-yy', value: 'mm-dd-yy', example: '09-14-22' },
  {
    label: 'yyyy-mm-ddTHH:MM:SS.ms',
    value: 'yyyy-mm-ddTHH:MM:SS.ms',
    example: '2018-09-28T13:19:53.62',
  },
  {
    label: 'dd-mon-yyyy',
    value: 'dd-mon-yyyy',
    example: '12-JAN-2023',
  },
  {
    label: 'yyyy/mm/dd',
    value: 'yyyy/mm/dd',
    example: '2023/01/12',
  },
  {
    label: 'dd-mm-yy',
    value: 'dd-mm-yy',
    example: '12-01-23',
  },
  {
    label: 'dd mon yyyy',
    value: 'dd mon yyyy',
    example: '12 JAN 2033',
  },
  {
    label: 'yyyy-mm-ddTHH:MM:SS.msTZ',
    value: 'yyyy-mm-ddTHH:MM:SS.msTZ',
    example: '2024-01-03T09:30:00.000Z',
  },
  {
    label: 'yyyy-mm-ddTHH:MM:SSTZ',
    value: 'yyyy-mm-ddTHH:MM:SSTZ',
    example: '2024-01-03T09:30:00Z',
  },
]
export const FileConfigRow = ({ type, index }: FileConfigRowProps) => {
  const CUSTOM_MAPPER_FILE_ERP_ENCONDING_LIST_URL = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/files/get-encoding-list`
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const user = useIESelector((state) => state.user)
  const customERP = useIESelector((state) => state.customERP)
  const testingMode = useIESelector((state) => state.runs.testing.testMode)
  const azureClient = useContext(AzureClientContext)
  const client = useApolloClient()
  const { locale } = useIntl()
  const [open, setOpen] = useState(true)
  const [uploaded, setUploaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cuId, setCuId] = useState('')
  const [fileName, setFileName] = useState('')
  const [clients, setClients] = useState('')
  const [engagements, setEngagements] = useState('')
  const [openPreviousFile, setOpenPreviousFile] = useState(false)
  const [clientEngagementDialog, setClientEngagementDialog] = useState(false)
  const [showOnDragMessage, setShowOnDragMessage] = useState(false)
  const [headerOptions, setHeaderOptions] = useState([
    { header_row: null, probability: null },
  ])
  const [openDialog, setOpenDialog] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDisable, setIsDisable] = useState(true)
  const [previousValue, setPreviousValue] = useState(null)
  const [value, setValue] = useState('')
  const [extractField, setExtractField] = useState({})
  const [csvFilePath, setCsvFilePath] = useState('')
  const { entities } = useIESelector((state) => state.customERP)
  const { cdmEntity } = useIESelector((state) => state.customERP)
  const { auditedEntity, engagement } = useIESelector(
    (state) => state.engagement
  )
  const { instance, inProgress, accounts } = useMsal()
  let source = axios.CancelToken.source()

  const onPrevMappingSelect = async (result: boolean, file: any) => {
    setOpenPreviousFile(false)
    if (result) {
      const url = user.systemName + '/' + file.name
      setCsvFilePath(file.name)
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      handleIdentifyHeader(file.name, token, url)
      setOpenPreviousFile(false)
    } else {
      setOpenPreviousFile(false)
    }
  }

  const uploadInfo = () => {
    if (
      entities[type][index]?.state === undefined ||
      entities[type][index]?.state === UPDATE_STATE.NOT_STARTED
    ) {
      return false
    } else {
      return true
    }
  }

  const handleButtonClickPreFile = (e) => {
    setOpenPreviousFile(true)
    setClientEngagementDialog(true)
  }

  const selectClientEngagement = (result: boolean) => {
    setOpenPreviousFile(false)
    if (result) {
      setClients(auditedEntity.name)
      setEngagements(engagement.name)
      setClientEngagementDialog(false)
      setOpenPreviousFile(true)
    } else {
      setClientEngagementDialog(false)
    }
  }

  const onAuditedEntityChange = (e: any, value: any, reason: string) => {
    const result =
      reason === EBAutocompleteReason.CLEAR || typeof value === 'string'
        ? null
        : value
    dispatch(setIds({ key: 'auditedEntity', value: result }))
    if (result) {
      dispatch(getEngagements({ client, id: result.id }))
    }
  }

  const onEngagementChange = (e: any, value: any, reason: string) => {
    const result =
      reason === EBAutocompleteReason.CLEAR || typeof value === 'string'
        ? null
        : value
    dispatch(setIds({ key: 'engagement', value: result }))
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isInputValid =
      e.target.type === 'number' &&
      !/^[1-9]\d*$/.test(e.target.value) &&
      e.target.value != ''

    dispatch(
      updateEntity({
        data: {
          [e.target.name]: isInputValid
            ? entities[type][index]?.header_row
            : e.target.type === 'checkbox'
            ? e.target.checked
            : e.target.value,
        },
        entityIndex: index,
        extractType: type,
      })
    )
  }
  const url = `${user.systemName}/temp/${cuId}_${fileName}`

  const cuid_generator = () => {
    return cuid()
  }

  useEffect(() => {
    if (value !== '') {
      dispatch(
        updateEntityPreviewConfirm({
          action: false,
          entityIndex: index,
          extractType: type,
        })
      )
      dispatch(
        updateEntity({
          data: { header_row: parseInt(value), encoding_format: 'utf-8' },
          entityIndex: index,
          extractType: type,
        })
      )
      ;(async () => {
        await handlePreviewFile(
          csvFilePath
            ? `${user.systemName}/${csvFilePath}`
            : `${user.systemName}/temp/${cuId}_${fileName}`,
          parseInt(value)
        )
        setPreviousValue(value)
        openDaialog()
      })()
    }
  }, [value])

  useEffect(() => {
    setExtractField(customERP.extractData)
  }, [customERP])

  useEffect(() => {
    const entity = entities[type][index]
    if (entity) {
      const fileName = entity.entity_name + '.' + entity.file_type
      if (entity.originalFileName !== fileName) {
        dispatch(
          updateEntity({
            data: { originalFileName: fileName },
            entityIndex: index,
            extractType: type,
          })
        )
      }
      const payload = {
        __typename: 'CdmEntity',
        extractType: type.toUpperCase(),
        erp: {
          __typename: 'Erp',
          name: customERP.client_system.systemName,
          id: customERP?.erpId,
          standard: false,
        },
        inputFiles: [
          {
            __typename: 'InputFile',
            name: fileName,
          },
        ],
        name: entity.display_name,
        systemName: entity.entity_name,
      }
      dispatch(updateCdmEntities(payload))
    }
  }, [entities, type, index])

  const handleFileUploadProgress = (progress) =>
    dispatch(
      updateEntity({
        data: { uploaded: progress.loadedBytes },
        entityIndex: index,
        extractType: type,
      })
    )

  const handleExcelFileUploadProgress = (progress) =>
    dispatch(
      updateEntity({
        data: { uploaded: progress.loadedBytes / 2 },
        entityIndex: index,
        extractType: type,
      })
    )

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  let activeStatus = { value: true }

  const handleConvertExcelToCsvProgress = (progress: number) => {
    return async (dispatch) => {
      let i = 2.0
      while (i >= 1.0 && activeStatus.value) {
        await dispatch(
          updateEntity({
            data: { uploaded: progress / i },
            entityIndex: index,
            extractType: type,
          })
        )
        i -= 0.01
        await delay(100)
      }
    }
  }

  const handleInputChange = (event, newInputValue) => {
    const sanitizedInput = newInputValue.replace(/[^0-9]/g, '')
    const numericValue =
      sanitizedInput === '' ? '' : parseInt(sanitizedInput, 10)
    const limitedValue =
      numericValue === '' || numericValue <= 50 ? numericValue : 50

    setValue(limitedValue.toString())
  }

  const handleIdentifyHeader = async (name, token, url) => {
    dispatch(
      updateEntity({
        data: { state: UPDATE_STATE.COMPLETE, fileNameByUser: name },
        entityIndex: index,
        extractType: type,
      })
    )
    setLoading(true)

    const backendResponse = await fetch(
      `${process.env.NX_CUSTOM_MAPPER_API_URL}/erp/identify-header`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filePath: url,
        }),
      }
    )

    if (backendResponse.ok) {
      setUploaded(true)
      const result = await backendResponse.json()
      setHeaderOptions(result.probableHeaders)
      setLoading(false)
    } else {
      console.error('Backend function request failed')
    }
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileUpload = async (files: any) => {
    setUploaded(false)
    resetEntityPreviewConfirm()
    setValue('')
    const file = files?.[0]
    if (isValidFileType(file)) {
      try {
        const name = file.name
        const cuId = cuid_generator()
        if (!file) {
          console.error('No file selected')
          return
        }
        const isXLSXorXLS = checkFileType(name)
        let allowedfile = checkFileSize(isXLSXorXLS, file.size)
        if (allowedfile) {
          await processFileUpload(file, name, cuId, isXLSXorXLS)
        } else {
          dispatchUpdateEntity(UPDATE_STATE.VALIDATE_EXCEL_FILE_SIZE)
        }
      } catch (error) {
        dispatchUpdateEntity(UPDATE_STATE.ERROR)
        console.error('An error occurred during file upload:', error)
        setLoading(false)
      }
    } else {
      dispatchUpdateEntity(UPDATE_STATE.UNSUPPORTED_FILE_TYPE)
    }
  }

  const resetEntityPreviewConfirm = () => {
    dispatch(
      updateEntityPreviewConfirm({
        action: false,
        entityIndex: index,
        extractType: type,
      })
    )
  }

  const checkFileType = (name: string) => {
    return name.includes('.xlsx') || name.includes('.xls')
  }

  const checkFileSize = (isXLSXorXLS: boolean, size: number) => {
    if (isXLSXorXLS && size >= FILE_HELPER.FILE_SIZE_100_MEGABYTES) {
      return false
    }
    return true
  }

  const dispatchUpdateEntity = (state: UPDATE_STATE) => {
    dispatch(
      updateEntity({
        data: { state },
        entityIndex: index,
        extractType: type,
      })
    )
  }

  const processFileUpload = async (
    file: any,
    name: string,
    cuId: string,
    isXLSXorXLS: boolean
  ) => {
    const uploadPath = `temp/${cuId}_${name}`
    dispatch(
      updateEntity({
        data: {
          state: UPDATE_STATE.IN_PROGRESS,
          size: file.size,
          uploaded: 0,
          fileError: '',
        },
        entityIndex: index,
        extractType: type,
      })
    )
    const response = await uploadFileToAzureContainer({
      azureClient,
      containerId: user.systemName,
      file,
      inputPath: uploadPath,
      uploadOptions: {
        onProgress: isXLSXorXLS
          ? handleExcelFileUploadProgress
          : handleFileUploadProgress,
      },
    })
    dispatch(
      updateEntity({
        data: { file_type: name.split('.').pop() },
        entityIndex: index,
        extractType: type,
      })
    )
    if (response) {
      setFileName(name)
      setCuId(cuId)
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      if (isXLSXorXLS) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
        const requestJSON: any = {}
        requestJSON['file_path'] = `${user.systemName}/temp/${cuId}_${name}`
        dispatch(handleConvertExcelToCsvProgress(file.size))
        try {
          const uploadFileResponse = await axios.post(
            `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/convert-excel-to-csv`,
            requestJSON,
            { headers, cancelToken: source.token }
          )
          console.log('Response from convert-excel-to-csv')
          console.log(uploadFileResponse)
          if (uploadFileResponse.status === 200) {
            activeStatus.value = false
            dispatch(
              updateEntity({
                data: {
                  uploaded: file.size,
                },
                entityIndex: index,
                extractType: type,
              })
            )
            const result = uploadFileResponse.data.csv_file_path
            setCsvFilePath(result)
            setTimeout(() => {
              const url = `${user.systemName}/${result}`
              handleIdentifyHeader(name, token, url)
            }, 1000)
          } else {
            activeStatus.value = false
            dispatchUpdateEntity(UPDATE_STATE.ERROR_EXCEL_FILE_CONVERSION)
          }
        } catch (error) {
          console.log('Error occured from convert-excel-to-csv ')
          console.log(error)
          activeStatus.value = false
          dispatchUpdateEntity(UPDATE_STATE.ERROR_EXCEL_FILE_CONVERSION)
          setLoading(false)
          setUploaded(false)
          console.error('File upload failed')
          return false
        }
      } else {
        const url = `${user.systemName}/temp/${cuId}_${file.name}`
        handleIdentifyHeader(name, token, url)
      }
    } else {
      dispatchUpdateEntity(UPDATE_STATE.ERROR)
      setLoading(false)
      console.error('File upload failed')
    }
  }

  const handlePreviewFile = async (file_path: string, skiprows: number) => {
    try {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })

      await dispatch(
        extractFields({
          file_path,
          skiprows,
          entityIndex: index,
          extractType: type,
          token,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const openDaialog = () => {
    setIsDialogOpen(true)
  }

  const closeDialog = (confirmed: boolean) => {
    if (confirmed) {
      dispatch(
        updateEntityPreviewConfirm({
          action: true,
          entityIndex: index,
          extractType: type,
        })
      )
      setIsDialogOpen(false)
      if (Array.isArray(extractField))
        dispatch(
          updateEntity({
            data: {
              customErpFieldsConfig: extractField,
              customErpFieldsDefault: extractField,
            },
            entityIndex: index,
            extractType: type,
          })
        )
      return true
    } else {
      setIsDialogOpen(false)
      return false
    }
  }

  const fetchErpEncoding = async () => {
    if (!testingMode) {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }

      const response = await axios.get(
        CUSTOM_MAPPER_FILE_ERP_ENCONDING_LIST_URL,
        { headers }
      )

      dispatch(setEncodingOptions(response.data))
    }
  }

  useEffect(() => {
    let highestProbability = -1
    let headerRowWithHighestProbability = null

    for (const item of headerOptions) {
      if (item.probability > highestProbability) {
        highestProbability = item.probability
        headerRowWithHighestProbability = item.header_row
      }
    }

    if (headerRowWithHighestProbability !== null) {
      setValue(headerRowWithHighestProbability.toString())
    }
  }, [headerOptions])

  const handleDialog = (flag: boolean) => {
    setIsDisable(flag)
    setOpenDialog(flag)
  }

  const onDateFormatChange = (value) => {
    let rowIndex = []
    entities[type][index].customErpFieldsConfig?.forEach((row, idx) => {
      if (row.data_type === 'datetime') {
        rowIndex.push(idx)
      }
    })
    dispatch(
      updateEntity({
        data: { global_datetime_format: value.label },
        entityIndex: index,
        extractType: type,
      })
    )
    dispatch(
      updateCustomErpDateFieldConfig({
        rowIndex: rowIndex,
        fieldData: { date_format: value.label },
        entityIndex: index,
        extractType: type,
      })
    )
  }

  useEffect(() => {
    fetchErpEncoding()
  }, [])
  return (
    <>
      <CustomERPDialog
        visibility={openDialog}
        type={type}
        index={index}
        handleDialogClose={(flag: boolean) => handleDialog(flag)}
        isDisable={isDisable}
        setIsDisable={setIsDisable}
      />
      <div className={classes.fcrRoot}>
        <div className="fcr__head">
          <span>{entities[type][index]?.display_name}</span>
          {open ? (
            <CollapseUpIcon onClick={() => setOpen(!open)} />
          ) : (
            <CollapseDownIcon onClick={() => setOpen(!open)} />
          )}
        </div>
        <div className={`fcr__body ${open ? '' : 'close'}`}>
          <div className="fcr__input__group">
            <label htmlFor="suggested-file-name">Suggested File Name</label>
            <span>
              The usual name of <br /> this file
            </span>
            <TextField
              type="text"
              variant="outlined"
              size="small"
              name="suggested_file_name"
              placeholder="Enter Suggested File Name"
              value={entities[type][index]?.suggested_file_name}
              onChange={onChangeHandler}
              disabled
            />
          </div>
          <div className="fcr__input__group">
            <label htmlFor="blank_replacement">Global Datetime Format</label>
            <span>
              A common format for all the fields which will have datetime data
            </span>
            <EBAutoComplete
              value={{
                label: entities[type][index]?.global_datetime_format || '',
              }}
              onChange={(e, value) => onDateFormatChange(value)}
              options={dateFormatItem}
              getOptionLabel={(option) => option.label}
              height={44}
              size="small"
              disableClearable={true}
              width={265}
              forcePopupIcon={true}
              groupBy={() => 'Please Select Mandatory Fields'}
              placeholder="Select Global Datetime Format"
            />
          </div>
          {isDialogOpen && (
            <ERPDialog
              data={
                customERP?.entities?.[type]?.[index]?.fileError === 'No data' ||
                customERP?.entities?.[type]?.[index]?.header_row === 0
                  ? 'No data'
                  : customERP?.entities?.[type][index]?.dfData
              }
              header={'Please confirm predicted ERP header row & field names:'}
              open={isDialogOpen}
              onClose={closeDialog}
              isConfirmed={entities[type][index]?.isPreviewConfirmed}
            />
          )}

          <div className="fcr__input__group">
            <label htmlFor="suggested-file-name">Conform Dates</label>
            <span>
              Enabling this will convert all dates to CDM format
              (YYYY-MM-DDTHH:mm:SS)
            </span>
            <Switch
              color="secondary"
              name="conform_dates"
              checked={entities[type][index]?.conform_dates}
              onChange={onChangeHandler}
            />
          </div>

          <div className="fcr__input__group">
            <label htmlFor="blank_replacement">Blank Replacement</label>
            <span>
              Replace “-” fields in a decimal/integer/currency columns with '0'
            </span>
            <Switch
              color="secondary"
              name="blank_replacement"
              checked={entities[type][index]['blank_replacement'] || false}
              onChange={onChangeHandler}
            />
          </div>
          <div style={{ width: '100%' }}>
            <FileUploader
              isUploadInfo={uploadInfo()}
              isPreUploadFile={true}
              isUploadedFile={true}
              isDisplayVertical={true}
              enableDragAndDrop={true}
              client={client}
              locale={locale}
              uploadLabel="Drag & drop your files here or"
              disableUpload={
                entities[type][index]?.state === UPDATE_STATE.IN_PROGRESS ||
                entities[type][index]?.state === UPDATE_STATE.VALIDATING_FILE
              }
              onDragOver={(e) => setShowOnDragMessage(true)}
              showOnDragMessage={showOnDragMessage}
              uploadFile={handleFileUpload}
              handleButtonClick={handleButtonClickPreFile}
              id={`file_${type}_${index}`}
              open={openPreviousFile}
              onClose={onPrevMappingSelect}
              dialogOpen={clientEngagementDialog}
              selectClientEngagement={selectClientEngagement}
              onAuditedEntityChange={onAuditedEntityChange}
              onEngagementChange={onEngagementChange}
              dialogTitle="Previously Uploaded Files"
              auditedEntity={clients}
              engagementId={engagements}
              allowMultiple={false}
              fileMappingData={entities[type][index]}
              selectedReportDetail={cdmEntity}
              selectedCdmEntity={entities[type][index].entity_name}
              type={type}
              index={index}
              disableExtraction={true}
              disableJoin={true}
              value={value}
              setValue={setValue}
              loading={loading}
              uploaded={uploaded}
              handleInputChange={(event, newInputValue) =>
                handleInputChange(event, newInputValue)
              }
              options={headerOptions}
            />
          </div>
        </div>
        <div className="fcr__footer">
          <div className="fcr__mandatory__fields">
            <span>Mandatory Fields</span>
            {entities[type][index]?.mandatory_fields.map(({ label, count }) => {
              return (
                <div key={label} className="fcr__pills">
                  <span>{label}</span>
                  <span>{count}</span>
                </div>
              )
            })}
          </div>
          <div className={`fcr__actions ${classes.btn_row}`}>
            <FormControl>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleDialog(true)}
                disabled={
                  entities[type][index].entityType === 'Existing'
                    ? false
                    : entities[type][index]?.fileError === undefined ||
                      !!entities[type][index]?.fileError
                }
              >
                Remove Rows
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileConfigRow
