import { Option } from '@engine-b/integration-engine/data/state/redux'

export const EXTRACT = 'extract'
export const TRANSFORM = 'transform'
export const LOAD = 'load'
export const AVG = 'avg'
export const MAX = 'max'
export const MIN = 'min'
export const SUM = 'sum'
export const NET = 'net'
export const COUNT = 'count'
export const DELETE_COLUMN = 'delete_column'
export const GROUP_BY = 'group_by'
export const FILTER = 'filter'
export const COMPARE = 'compare'
export const DUPLICATE_ROW = 'duplicate'
export const NON_WORKING_DAYS = 'nwd'
export const OUTSIDE_WORKING_HOURS = 'owh'
export const BACKDATING = 'bkd'
export const MULTIPLE_CALCULATION = 'multiple_calculation'
export const OVERLAP = 'overlap'
export const DECIMAL = 'decimal'
export const DATETIME = 'datetime'
export const STRING = 'string'

export const AGGR_OPTIONS: Option[] = [
  { title: 'AVG', value: AVG, data_types: [DECIMAL] },
  { title: 'COUNT', value: COUNT, data_types: [DECIMAL, STRING, DATETIME] },
  { title: 'MAX', value: MAX, data_types: [DECIMAL] },
  { title: 'MIN', value: MIN, data_types: [DECIMAL] },
  { title: 'SUM', value: SUM, data_types: [DECIMAL] },
]

export const DATA_TYPE_OPTIONS: string[] = ['string', 'decimal', 'datetime']

export const STATUS_OPTIONS: Option[] = [
  { title: 'All', value: 'All' },
  { title: 'Completed', value: 'COMPLETED' },
  { title: 'Failed', value: 'FAILED' },
  { title: 'Failed to Process', value: 'FAILED_TO_PROCESS' },
  { title: 'In Progress', value: 'IN_PROGRESS' },
  { title: 'Initialized', value: 'INITIALIZED' },
]

export const WEEKDAYS = [
  {
    id: 0,
    name: 'M',
    checked: false,
    label: 'Monday',
  },
  {
    id: 1,
    name: 'T',
    checked: false,
    label: 'Tuesday',
  },
  {
    id: 2,
    name: 'W',
    checked: false,
    label: 'Wednesday',
  },
  {
    id: 3,
    name: 'T',
    checked: false,
    label: 'Thursday',
  },
  {
    id: 4,
    name: 'F',
    checked: false,
    label: 'Friday',
  },
  {
    id: 5,
    name: 'S',
    checked: false,
    label: 'Saturday',
  },
  {
    id: 6,
    name: 'S',
    checked: false,
    label: 'Sunday',
  },
]

export interface DataTypeSupport {
  [key: string]: string[]
}

export const DATA_TYPE_SUPPORT: DataTypeSupport = {
  [AVG]: [DECIMAL],
  [MAX]: [DECIMAL],
  [MIN]: [DECIMAL],
  [SUM]: [DECIMAL],
  [NET]: [DECIMAL],
  [COUNT]: [DECIMAL, STRING, DATETIME],
  [DELETE_COLUMN]: [DECIMAL, STRING, DATETIME],
  [GROUP_BY]: [DECIMAL, STRING, DATETIME],
  [FILTER]: [DECIMAL, STRING, DATETIME],
  [COMPARE]: [],
  [NON_WORKING_DAYS]: [DATETIME],
  [OUTSIDE_WORKING_HOURS]: [DATETIME],
  [BACKDATING]: [DATETIME],
  [MULTIPLE_CALCULATION]: [DECIMAL],
}

export const FILTER_OPERATORS: Option[] = [
  { title: 'Greater than', value: '>', data_types: [DATETIME, DECIMAL] },
  { title: 'Less than', value: '<', data_types: [DATETIME, DECIMAL] },
  { title: 'Equal to', value: '==', data_types: [DATETIME, DECIMAL, STRING] },
  {
    title: 'Not equal',
    value: '!=',
    data_types: [DATETIME, DECIMAL, STRING],
  },
  {
    title: 'Greater than equal',
    value: '>=',
    data_types: [DATETIME, DECIMAL],
  },
  { title: 'Less than equal', value: '<=', data_types: [DATETIME, DECIMAL] },
]
