import { withJsonFormsControlProps, TranslateProps } from '@jsonforms/react';
import React from 'react'
import { 
  InputLabel, 
  FormControl, 
  Select, 
  MenuItem, 
  Grid, 
  Typography 
} from '@material-ui/core';
import { 
  ControlProps,
  JsonSchema
} from '@jsonforms/core';
import { NEW_UI_INPUT_WIDTH } from '@engine-b/integration-engine/ui/form-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'

type JsonSchemaWithLabel = JsonSchema & { enumDescriptions: string[] };
const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      maxWidth: NEW_UI_INPUT_WIDTH + 'px',
      marginBottom: '16px',
      minWidth: '400px',
      fontSize: '14px',
    },
    errorLabel: {
      color: theme.palette.error.main,
      marginBottom: '16px',
      marginTop: '9px',
      minWidth: '200px',
    },
    labelContainer: {
      marginBottom: '16px',
    },
    label: {
      font: 'normal normal bold 16px/18px Arial',
      color: '#44697D',
      letterSpacing: '0.16px',
      verticalAlign: 'center'
    },
  })
)
const Dropdown = (props: ControlProps & TranslateProps) => {
  const schema = props.schema as JsonSchemaWithLabel;
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} className={classes.labelContainer}>
        <Typography className={classes.label}>{props.label}</Typography>
        {props.required && <Typography style={{color: 'red'}}> *</Typography>}
      </Grid>

      {props.description && (
        <Grid item xs={12}>
          <Typography className={classes.description}>{props.description}</Typography>
        </Grid>
      )}
      <FormControl>
        <InputLabel id="props-label">Select from Dropdown</InputLabel>
        <Select
          style={{width: "350px", marginBottom: "16px"}}
          onChange={(e: any) => {
              props.handleChange(props.path, e.target.value)
          }}
          inputProps={{
            "data-testid": "select-control"
          }}
          labelId='props-label'
          value={props.data}
        >
          {schema.oneOf.map((option, index) => {
            return option && <MenuItem 
                value={option['const']} 
                data-testid={`select-control-option-${index}`}
                key={option['const']}
              >
                {option['title']}
              </MenuItem>
          })}
        </Select>
        <Grid item xs={12}>
          <label className={classes.errorLabel}>{props.errors}</label>
        </Grid>
      </FormControl>
    </>
  )
}


export const DropdownControl = withJsonFormsControlProps(Dropdown);