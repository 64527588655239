
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
const rp = new ReactPlugin();
 const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
    customDataPrefix: "",
    parentDataTag: "",
    dntDataTag: "ai-dnt",
    captureAllMetaDataContent:false,
    useDefaultContentNameOrId: true,
    autoCapture: true,
    dataTags: { useDefaultContentNameOrId: true }
}; 

console.log(`App Insights Enabled: ${process.env.NX_ENABLE_APP_INSIGHTS}`)

const ai = new ApplicationInsights({ config: {
    connectionString: process.env.NX_APPLICATIONINSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [clickPluginInstance as any],
    extensionConfig: {
    [clickPluginInstance.identifier]: clickPluginConfig
    }}});


if(process.env.NX_ENABLE_APP_INSIGHTS === 'true') {
    ai.loadAppInsights()
}


export function trackPageView(pageView: IPageViewTelemetry){
    
    if(process.env.NX_ENABLE_APP_INSIGHTS === 'true') {
        ai.appInsights.trackPageView(pageView)
    }
    

}
