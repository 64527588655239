import React from 'react'
import { RouteProps } from 'react-router'
import { Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { localizeRoutePath } from '../utils/localize-route-path'

export const LocalizedSwitch: React.FC = ({ children }) => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { formatMessage, locale } = useIntl()

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) => {
        return React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(formatMessage, locale, child.props.path),
            })
          : child
      })}
    </Switch>
  )
}
