import { FileDetails } from '@engine-b/integration-engine/data/state/redux'

const compileRecords = (search: string, mandatory: boolean, selectedFiles) => {
  let records = []

  const getAvailableOptions = (
    reportType: string,
    name: string,
    description: string
  ) => {
    const file = selectedFiles.find(
      (file: FileDetails) => file.reportType === reportType
    )

    return file.cdmDetails.mapping.find(
      (item) => item.name === name && item.description === description
    ).available_options
  }

  const getERPOptions = (reportType: string) => {
    const file = selectedFiles.find(
      (file: FileDetails) => file.reportType === reportType
    )

    return file.cdmDetails.fields.map((value) => ({
      ...value,
      originalFileName: file.inputFileName,
      groupByFileName: file.inputFileName,
    }))
  }

  selectedFiles.forEach((file: FileDetails) => {
    const finalMappedRecords = file.cdmDetails.mapping
      .map((item) => {
        return {
          ...item,
          // Render options in the dropdown
          erp_options: [
            { field_name: 'Calculated Field' },
            ...getERPOptions(file.reportType),
          ],
          available_options: [
            { field_name: 'Calculated Field' },
            ...getAvailableOptions(
              file.reportType,
              item.name,
              item.description
            ),
          ],
        }
      })
      .map((record) => ({
        /**
         * Required properties are coming in two different objects (mapping, fields)
         * Hence merging erp_options properties with available_options properties
         * if available_options.field_id === fields.id 
        */
        ...record,
        available_options: record.available_options
          .map((option) => ({
            ...option,
            ...record.erp_options.find((item) => item.id === option.field_id),
          })) /** sort options by confidence score in descending order  */
          .sort((a, b) => b.confidence_score - a.confidence_score),
      }))

    records = [...records, ...finalMappedRecords]
  })

  // if search has any value
  if (search) {
    records = records.filter(
      (item) =>
        item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        item.description.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
  }
  
  // if mandatory checkbox checked
  if (mandatory) records = records.filter((record) => record.mandatory)

  // start with mandatory fields at the top and then go alphabetically from there
  const mandateRecords = []
  const nonMandateRecords = []
  records.forEach((record) => {
    record.mandatory
      ? mandateRecords.push(record)
      : nonMandateRecords.push(record)
  })

  records = [
    ...mandateRecords,
    ...nonMandateRecords.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    ),
  ]

  return records
}

export default compileRecords
