import { gql } from '@apollo/client'

export const UPDATE_ERP_CONNECTION = gql`
  mutation updateErpConnection($id: String! $name: String!) {
    updateErpConnection(id: $id name: $name) {
      id
      name
    }
  }
`
