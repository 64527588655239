import React, { useState, useContext, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import {
  clearValidation,
  clearAllFileMappings,
  useIESelector,
  useIEDispatch,
  triggerPipeline,
  fetchStart,
  fetchStop,
  setCurrentStep,
  CustomMapperState,
  resetEngagementState,
  FileDetails,
  updateDataIngestion,
  CDMDetails,
  addUniqueIdColumn,
  removeUniqueIdColumn,
  getCdmVersion,
  resetUniqueIds,
} from '@engine-b/integration-engine/data/state/redux'
import { base64ToBinary } from '@engine-b/integration-engine/utils/file-converter'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import FileDownload from 'js-file-download'

import {
  AzureClientContext,
  uploadCustomMappingFileToAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'

import { useApolloClient } from '@apollo/client'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { ReactComponent as IconExport } from '../../../assets/export_icon.svg'

import {
  INPUT_WIDTH,
  INPUT_WIDTH_LONG,
} from '@engine-b/integration-engine/ui/form-components'
import { Step } from './Step'
import { BackButton } from './TraverseButtons'
import { TraverseButtonsLayout } from './TraverseButtonsLayout'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { EXTRACT_TYPE_LABELS } from './constants'
import TabPanel from './step-three/TabPanel'
import MandatoryFields from './step-three/MandatoryFields'
import ImperfectFiles from './step-three/ImperfectFiles'
import CustomMapperButton from '../../MapperView/components/CustomMapperButton'
import { ReactComponent as IconInfo } from '../../../assets/IconInfo.svg'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import ERPDialog from '../../AddCustomERP/ERPDialog'
import compileRecords from '../../MapperView/utils/compileRecords'
import { onPrevMappingSelect } from '../../MapperView/MapperView'
import { trackPageView } from '../../../services/AppInsights'

const CUSTOM_MAPPER_REPORT_EXPORT_URL = `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/report-export/xlsx`

const useStyles = makeStyles((theme) => ({
  MuiInput: {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '0.5px solid ' + theme.palette.secondary.light,
    padding: '2px 10px',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    marginRight: '10px',
    minWidth: `${INPUT_WIDTH}px`,
    width: `${INPUT_WIDTH_LONG}px`,
    '& :before': {
      border: 'none',
    },

    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid ' + theme.palette.secondary.light,
    },
  },
  summary: {
    display: 'flex',
  },
  rootTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    background: '#F2F4F5 0% 0% no-repeat padding-box',
    color: '#22353F',
  },
  selectedTab: {
    background: '#00B2A9 0% 0% no-repeat padding-box',
    color: '#FFFFFF',
  },
  selectLabel: {
    marginRight: '1rem',
  },
  buttonWrapper: {
    overflow: 'hidden',
    display: 'inline-flex',
    position: 'relative',
    float: 'right',
    '& .MuiButtonGroup-root': {
      marginRight: '25px',
      '& > button:first-child': {
        borderRadius: '4px 0 0 4px',
        background: '#22353F',
        '& svg path': {
          fill: '#37AB3F',
        },
      },
      '& > button:last-child': {
        height: '44px',
        borderRadius: '0 4px 4px 0',
        fontWeight: 'bold',
        background: '#fff',
        '& span': {
          font: 'normal normal bold 16px/24px Arial',
        },
      },
    },
  },
  SPRButton: {
    height: '44px',
    padding: '8.5px 24.5px',
    '& span': {
      font: 'normal normal bold 16px/24px Arial',
    },
  },
  customErpWarn: {
    display: 'inline-flex',
    marginRight: '30px',
    '& > p': {
      marginLeft: '14px',
    },
  },
  tooltip: {
    fontSize: 14,
  },
}))

/* eslint-disable-next-line */
export interface StepThreeProps {
  handleNext: () => void
  handleBack: () => void
}

export function StepThree({ handleNext, handleBack }: StepThreeProps) {
  const testingMode = useIESelector((state) => state.runs.testing.testMode)
  let instance_global
  let inProgress_global
  let accounts_global

  if (!testingMode) {
    const { instance, inProgress, accounts } = useMsal()
    instance_global = instance
    inProgress_global = inProgress
    accounts_global = accounts
  }

  const classes = useStyles()
  const dispatch = useIEDispatch()

  const erps = useIESelector((state) => state.erps)
  const draftRun = useIESelector((state) => state.runs.draftRun)
  const customMapper: CustomMapperState = useIESelector(
    (state) => state.customMapper
  )
  const customFileMappings = useIESelector(
    (state) => state.customMapper.customFileMappings
  )
  const erpCollection = useIESelector((state) => state.erps)

  const isCustomErp = erpCollection[draftRun.erpId]?.auditFirm?.id

  let availableReportTypes = customMapper.files.map((fileDetail) => ({
    reportType: fileDetail.reportType,
    reportName: fileDetail.reportName,
  }))
  availableReportTypes = availableReportTypes.filter(
    (report, idx) =>
      availableReportTypes.findIndex(
        (item) => item.reportType === report.reportType
      ) === idx
  )

  const [selectedRT, setSelectedRT] = useState(
    availableReportTypes[0]?.reportType
  )
  const [selectedFile, setSelectedFile] = useState<FileDetails | null>(null)
  const [mFieldData, setMFieldData] = useState<any[]>([])
  const [iFilesData, setIFilesData] = useState<any[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [records, setRecords] = useState([])
  const [header, setHeader] = useState('')
  const [filteredRecords, setFilteredRecords] = useState([])
  const [ERPValue, setERPValue] = useState({})

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: 250,
      },
    },
  }
  const allFilesValid = customMapper.files.reduce(
    (valid, fl) => valid && fl.mandatoryFieldsValid && fl.imperfectFilesValid,
    true
  )
  const uniqueIdType =
    customMapper.unique_ids.find((item) => item.report_type === selectedRT)
      ?.gen_unique_id_col || 'None'

  const client = useApolloClient()
  const azureClient = useContext(AzureClientContext)
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )

  /**
   * Generate json file which consist unique id calculation column information
   */
  const generateUniqueIdColMappingJSON = async () => {
    try {
      let header_rows = []
      customMapper.files?.forEach((cMapper) => {
        if (cMapper.header_row) {
          header_rows.push({
            file_name: cMapper?.cdmDetails?.fields[0]?.file_name,
            header_row_number: cMapper.header_row,
            report_type: cMapper.reportType,
            data_type: 'string',
            mandatory: false,
            erp_Id: cMapper?.erpId,
            extract_type: cMapper?.extractType,
            original_filename: cMapper?.inputFileName,
          })
        }
      })
      const { cdmVersion, manualSelections, unique_ids } = customMapper
      // bring token for protected resource
      const { token } = await asyncTokenLookup({
        instance: instance_global,
        inProgress: inProgress_global,
        accounts: accounts_global,
        tokenRequest: protectedResources.dataIngestionApi,
      })

      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios.post(
        `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/create-as-json`,
        {
          cdm_version: cdmVersion,
          unique_id: unique_ids,
          header_row: header_rows,
          manualSelections,
        },
        { responseType: 'blob', headers }
      )
      return response.data
    } catch (error) {
      return null
    }
  }

  /**
   * Run the Pipeline if everything is going well
   */
  const handleScheduleRun = async () => {
    dispatch(fetchStart({}))
    let isCustomMapped = false
    let promises = []

    const selectedErp = erps[draftRun.erpId]
    const isAdf = selectedErp.pipelineId !== null
    const standard = selectedErp.standard

    if (!isAdf && standard) isCustomMapped = true

    /**
     * Upload all selected files and get array of promises
     */
    const headerRows = customMapper.files.map((file) => {
      if (file.header_row) return file
    })
    if (
      customMapper.remappingFile ||
      customMapper.unique_ids.length > 0 ||
      headerRows.length > 0
    ) {
      isCustomMapped = true
      const mappingFile =
        customMapper.unique_ids.length > 0 || headerRows.length > 0
          ? await generateUniqueIdColMappingJSON()
          : await base64ToBinary(
              customMapper.remappingFile,
              'mapping_file.json'
            )

      const resp1 = await uploadCustomMappingFileToAzureContainer(
        azureClient,
        azureContainer.fileSystemId,
        mappingFile,
        azureContainer.inputPath,
        'mapping_file.json'
      )
      promises = [...promises, resp1]
    }
    /**
     * Try to resolve all promises
     * If any one of the promise gets failed to resolve report error
     */
    try {
      await Promise.all(promises)
      dispatch(updateDataIngestion(client))
      dispatch(triggerPipeline({ client, isCustomMapped }))
        .unwrap()
        .then(() => {
          handleNext()
          dispatch(clearValidation({}))
          dispatch(clearAllFileMappings())
          dispatch(fetchStop({}))
          dispatch(setCurrentStep(0))
          dispatch(resetEngagementState())
          dispatch(resetUniqueIds())
        })
    } catch (e) {
      dispatch(clearValidation({}))
      dispatch(clearAllFileMappings())
      dispatch(resetEngagementState())
      dispatch(resetUniqueIds())
      dispatch(fetchStop({}))
    }
  }

  /**
   * Switch tabs between "Mandatory Fields" and "ImperfectFiles"
   */
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const exportReport = async () => {
    const payload = {
      meta: {
        erpId: selectedFile?.erpId,
        extractType: selectedFile?.extractType,
        reportType: selectedFile?.reportType,
        reportName: selectedFile?.reportName,
        cdmFileName: selectedFile?.cdmFileName,
        inputFileName: selectedFile?.inputFileName,
      },
      mfc: selectedFile?.mandatoryFieldsData,
      ifc: selectedFile?.imperfectFilesFields,
    }
    if (!testingMode) {
      const { token } = await asyncTokenLookup({
        instance: instance_global,
        inProgress: inProgress_global,
        accounts: accounts_global,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios({
        url: CUSTOM_MAPPER_REPORT_EXPORT_URL,
        method: 'POST',
        data: payload,
        responseType: 'blob',
        headers,
      })
      FileDownload(response.data, 'engineb-error-export.xlsx')
    }
  }

  const onReportTypeChange = (e) => {
    const file = customMapper.files.find(
      (fl) => fl.reportType === e.target.value
    )
    setSelectedFile(file)
    setSelectedRT(e.target.value.toString())
  }

  const closeDialog = (confirmed: boolean) => {
    if (confirmed) {
      setIsOpen(false)
      return true
    } else {
      setIsOpen(false)
      return false
    }
  }

  /**
   * On Report Type change
   * 1) update mandatory fields information
   * 2) update imperfect fields information
   * 3) count the errors to display on top of table
   */
  useEffect(() => {
    if (selectedRT && selectedFile != null) {
      const fieldInfo = selectedFile?.mandatoryFieldsData.map((mf) => {
        return {
          ...mf,
          uploadedFile: selectedFile?.inputFileName,
          report_type: selectedFile?.reportType,
        }
      })
      setMFieldData([...fieldInfo])
      setIFilesData([...selectedFile?.imperfectFilesSummary])
    }
    setRecords(selectedFile?.records)
    setHeader(`Uploaded file for ${selectedFile?.reportName}`)
  }, [selectedRT, selectedFile])

  /**
   * On component mount
   * 1) Load first file mandatory fields information
   * 2) Load first file imperfect fields information
   */
  useEffect(() => {
    const file = customMapper.files.find(
      (fl) => fl.reportType === availableReportTypes[0].reportType
    )
    setSelectedFile(file)
  }, [customFileMappings])

  useEffect(() => {
    if (selectedFile) {
      getFilteredRecords()
    }
  }, [selectedFile])

  const getFilteredRecords = () => {
    // files dropdown change operation
    let selectedFiles: Array<FileDetails> = customMapper.files.filter(
      (file) => selectedFile.reportName === file.reportName
    )
    const records: Array<CDMDetails> = compileRecords('', true, selectedFiles)

    setFilteredRecords(records)
  }

  const applyStandardMap = async () => {
    if (!customMapper.remappingFile) {
      if (!testingMode) {
        if (!['CUSTOM', 'INACTIVE'].includes(erps[draftRun.erpId].erpType)) {
          const file_path = `engineb-standard-mappings/${draftRun.erpId}.json`
          await onPrevMappingSelect(
            true,
            file_path,
            draftRun,
            customMapper,
            dispatch,
            instance_global,
            inProgress_global,
            accounts_global,
            null
          )
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getCdmVersion(client))
    applyStandardMap()
    trackPageView({name: 'Add Data: Step Three'});
  }, [])

  useEffect(() => {
    if (!testingMode) {
      if (Object.keys(customFileMappings).length === 0) setERPValue({})
      else {
        setERPValue(customFileMappings)
      }
    }
  }, [customFileMappings])

  const isValid = (value, record) => {
    const { mandatory } = record

    const res =
      value?.consider_valid === false ||
      value?.valid ||
      (!mandatory &&
        ((typeof value === 'object' && Object.keys(value).length === 0) ||
          value === undefined))

    return res
  }

  const getError = (value, record) => {
    const res = isValid(value, record)
    return res
  }

  const allErrorArr = filteredRecords.map((record) =>
    getError(
      ERPValue[record?.id] ? ERPValue[record?.id] : record.erp_fields[0],
      record
    )
  )

  const errorCount = allErrorArr.filter((v) => !v)?.length

  const onUniqueIdTypeChange = (value: string) => {
    if (value !== 'None') {
      dispatch(
        addUniqueIdColumn({
          file_name: selectedFile?.cdmDetails?.fields[0]?.file_name,
          cdm_field: '_id',
          gen_unique_id_col: value,
          map_type: 'id',
          report_type: selectedRT,
          data_type: 'string',
          mandatory: false,
          erp_Id: selectedFile?.erpId,
          extract_type: selectedFile?.extractType,
          original_filename: selectedFile?.inputFileName,
        })
      )
    } else {
      dispatch(removeUniqueIdColumn(selectedRT))
    }
  }

  return (
    <Step
      content={
        <>
          <Grid container style={{ marginBottom: '2rem' }}>
            <Grid item>
              <Typography
                className="step-title"
                variant={'h5'}
                color={'textPrimary'}
              >
                Step 3: Preview
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md>
              <Typography variant={'h6'} gutterBottom color={'textPrimary'}>
                Client System Name
              </Typography>
              <Typography variant={'body1'} gutterBottom>
                Selected from which client system the data was exported:
              </Typography>
              <Typography
                variant={'body1'}
                gutterBottom
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                {erps && erps[draftRun.erpId]?.name}
              </Typography>
              <div style={{ marginTop: '30px' }}>
                <Typography
                  className={classes.selectLabel}
                  variant={'body1'}
                  gutterBottom
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  Report Type
                </Typography>
                <Select
                  labelId="erpLabel"
                  id="select"
                  value={selectedRT}
                  onChange={onReportTypeChange}
                  className={classes.MuiInput}
                  MenuProps={MenuProps}
                >
                  {availableReportTypes.map(
                    ({ reportType, reportName }, idx) => (
                      <MenuItem key={`${reportType}=${idx}`} value={reportType}>
                        {reportName}
                      </MenuItem>
                    )
                  )}
                </Select>
                <Tooltip title="Preview uploaded file">
                  <IconButton
                    onClick={() => setIsOpen(true)}
                    color="secondary"
                    component="span"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                {isOpen && (
                  <ERPDialog
                    data={records}
                    header={header}
                    open={isOpen}
                    onClose={closeDialog}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md>
              <Typography variant={'h6'} gutterBottom color={'textPrimary'}>
                Data Extract Type
              </Typography>
              <Typography variant={'body1'} gutterBottom>
                Selected export format of the data files:
              </Typography>
              <Typography
                variant={'body1'}
                gutterBottom
                color={'textPrimary'}
                style={{ fontWeight: 'bold' }}
              >
                {EXTRACT_TYPE_LABELS[draftRun.extractType]}
              </Typography>
              {/* Unique ID Calculation  */}
              <div style={{ marginTop: '30px' }}>
                <Typography
                  className={classes.selectLabel}
                  variant={'body1'}
                  gutterBottom
                  color={'textPrimary'}
                  style={{ fontWeight: 'bold' }}
                >
                  Unique ID Selection
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    className={classes.MuiInput}
                    value={uniqueIdType}
                    MenuProps={MenuProps}
                    onChange={(e: any) => onUniqueIdTypeChange(e.target.value)}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="NUMID">NUM-ID</MenuItem>
                    <MenuItem value="UUID">U-ID</MenuItem>
                  </Select>
                  <Tooltip
                    title={
                      <List>
                        <ListItem>
                          <ListItemText primary="NUM-ID: Starting at 1, a number is added sequentially to each row in the output file" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="U-ID: Generates a 32 character unique identifying code for each row in the output file e.g. 6F9619FF-8B86-D011-B42D-00C04FC964FF" />
                        </ListItem>
                      </List>
                    }
                    placement="right"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconInfo />
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '40px' }}>
            {/* Switch tabs between "Mandatory Fields" and "ImperfectFiles" */}
            <Grid item xs={12}>
              <div className={classes.buttonWrapper}>
                <ButtonGroup variant="contained" color="primary">
                  <Button size="small" variant="outlined">
                    <IconExport />
                  </Button>
                  <Button variant="outlined" onClick={() => exportReport()}>
                    Export Error Report
                  </Button>
                </ButtonGroup>
                <CustomMapperButton />
              </div>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label="Mandatory Fields"
                  classes={{
                    root: classes.rootTab,
                    selected: classes.selectedTab,
                  }}
                />
                <Tab
                  label="Imperfect Files"
                  classes={{
                    root: classes.rootTab,
                    selected: classes.selectedTab,
                  }}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <MandatoryFields
                  data={mFieldData}
                  errorCount={errorCount}
                  validate={
                    !(
                      !(allFilesValid || customMapper.remappingFile) ||
                      (isCustomErp && !customMapper.remappingFile)
                    )
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ImperfectFiles
                  data={iFilesData}
                  validate={
                    !(
                      !(allFilesValid || customMapper.remappingFile) ||
                      (isCustomErp && !customMapper.remappingFile)
                    )
                  }
                />
              </TabPanel>
            </Grid>
          </Grid>
        </>
      }
      footer={
        <TraverseButtonsLayout>
          <Grid item>
            <BackButton handleBack={handleBack} />
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            {isCustomErp && !customMapper.remappingFile && (
              <div className={classes.customErpWarn}>
                <IconInfo />
                <Typography>
                  Please use the Custom Mapper and complete the <br /> data map
                  process before scheduling the pipeline.
                </Typography>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.SPRButton}
              onClick={() =>
                (allFilesValid || customMapper.remappingFile) &&
                handleScheduleRun()
              }
              disabled={
                !(allFilesValid || customMapper.remappingFile) ||
                (isCustomErp && !customMapper.remappingFile)
              }
            >
              Schedule Pipeline Run
            </Button>
          </Grid>
        </TraverseButtonsLayout>
      }
    />
  )
}

export default StepThree
