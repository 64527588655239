import { gql } from '@apollo/client'

export const GET_CDM_ENTITIES = gql`
  query GetCdmEntities($erpId: String!, $extractType: ExtractType!) {
    cdmEntities(erpId: $erpId, extractType: $extractType) {
      name
      systemName
      extractType
      erp {
        id
        name
      }
      inputFiles {
        name
      }
    }
  }
`
