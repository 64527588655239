export function transformExpressions(inputPayload) {
  const output = { conditions: [] }

  inputPayload.expressions.forEach((expression) => {
    if (expression.type === 'condition') {
      const transformedCondition = transformCondition(expression)
      output.conditions.push(transformedCondition)
    } else if (expression.type === 'result') {
      const index = output.conditions.findIndex(
        (x) => x.id === expression.parentNode
      )
      output.conditions[index] = {
        ...output.conditions[index],
        result: {
          ...(expression.operands[0].title === 'Custom Input'
            ? {
                value: expression.operands[0].value,
              }
            : {
                variable: expression.operands[0].title,
              }),
          type: expression.operands[0].type,
          ...(['date', 'time', 'datetime'].includes(
            expression.operands[0].type
          ) && {
            date_format: expression.operands[0]?.dateFormat,
            date_sub_info: expression.operands[0]?.subInfo?.value,
          }),
        },
      }
    }
  })

  const defaultExpresion = inputPayload.expressions.find(
    (x) => x.type === 'default'
  )

  if (defaultExpresion)
    output?.conditions.push({
      result: {
        ...(defaultExpresion?.operands[0].title === 'Custom Input'
          ? {
              value: defaultExpresion.operands[0].value,
            }
          : {
              variable: defaultExpresion.operands[0].title,
            }),
        type: defaultExpresion.operands[0].type,
        ...(['date', 'time', 'datetime'].includes(
          defaultExpresion.operands[0].type
        ) && {
          date_format: defaultExpresion.operands[0]?.dateFormat,
          date_sub_info: defaultExpresion.operands[0]?.subInfo?.value,
        }),
      },
    })

  return output.conditions
}

function transformCondition(condition) {
  const logicalOperatorIndex = findOperatorIndex(condition, 'logical')
  const comparisonOperatorIndex = findOperatorIndex(condition, 'comparison')
  const arithmeticOperatorIndex = findOperatorIndex(condition, 'arithmetic')

  if (logicalOperatorIndex !== -1) {
    // Recursive case: Logical operation with multiple operands
    const logicalOperator = condition.operations[logicalOperatorIndex].value
    const leftOperand = transformCondition({
      type: 'condition',
      id: condition.id,
      operations: condition.operations.slice(0, logicalOperatorIndex),
      operands: condition.operands.slice(0, logicalOperatorIndex + 1),
      ...(condition.brackets && { brackets: condition.brackets }),
    })
    const rightOperand = transformCondition({
      type: 'condition',
      id: condition.id,
      operations: condition.operations.slice(logicalOperatorIndex + 1),
      operands: condition.operands.slice(logicalOperatorIndex + 1),
      ...(condition.brackets && { brackets: condition.brackets }),
    })

    return {
      type: 'logical',
      id: condition.id,
      operator: logicalOperator,
      operands: [leftOperand, rightOperand],
    }
  } else if (comparisonOperatorIndex !== -1) {
    const leftOperand = transformCondition({
      type: 'condition',
      id: condition.id,
      operations: condition.operations.slice(0, comparisonOperatorIndex),
      operands: condition.operands.slice(0, comparisonOperatorIndex + 1),
      ...(condition.brackets && { brackets: condition.brackets }),
    })
    const rightOperand = transformCondition({
      type: 'condition',
      id: condition.id,
      operations: condition.operations.slice(comparisonOperatorIndex + 1),
      operands: condition.operands.slice(comparisonOperatorIndex + 1),
      ...(condition.brackets && { brackets: condition.brackets }),
    })
    return {
      type: 'comparison',
      id: condition.id,
      operator: condition.operations[comparisonOperatorIndex].value,
      operands: [leftOperand, rightOperand],
    }
  } else if (arithmeticOperatorIndex !== -1) {
    // Recursive case: Arithmetic operation with multiple operands
    const operator = condition.operations[arithmeticOperatorIndex].value
    const operands = []

    condition.operands.forEach((operand, index) => {
      if (condition?.operations?.[index - 1]?.value) {
        operands.push({
          symbol: condition.operations[index - 1].value,
          value: '',
        })
      }

      if (
        condition?.brackets?.[index]?.length &&
        condition.brackets[index][0] === '('
      ) {
        condition.brackets[index].forEach((x) =>
          operands.push({ symbol: x, value: '' })
        )
      }

      if (
        index < arithmeticOperatorIndex ||
        index === arithmeticOperatorIndex
      ) {
        operands.push({
          ...(operand.title === 'Custom Input'
            ? {
                value: operand.value,
              }
            : {
                variable: operand.title,
              }),
          type: operand.type,
          ...(['date', 'time', 'datetime'].includes(operand.type) && {
            date_format: operand?.dateFormat,
            date_sub_info: operand?.subInfo?.value,
          }),
        })
      } else {
        operands.push({
          ...(operand.title === 'Custom Input'
            ? {
                value: operand.value,
                type: operand.type,
              }
            : {
                variable: operand.title,
                type: operand.type,
                ...(['date', 'time', 'datetime'].includes(operand.type) && {
                  date_format: operand?.dateFormat,
                  date_sub_info: operand?.subInfo?.value,
                }),
              }),
        })
      }
      if (
        condition?.brackets?.[index]?.length &&
        condition.brackets[index][0] === ')'
      ) {
        condition.brackets[index].forEach((x) =>
          operands.push({ symbol: x, value: '' })
        )
      }
    })

    return {
      type: 'arithmetic',
      id: condition.id,
      ...(!condition.brackets && { operator: operator }),
      operands: operands,
    }
  }

  // Default case: If no logical or arithmetic or comparison operator found
  return {
    ...(condition.operands[0].title === 'Custom Input'
      ? {
          value: condition.operands[0].value,
        }
      : {
          variable: condition.operands[0].title,
        }),
    type: condition.operands[0].type,
    ...(['date', 'time', 'datetime'].includes(condition.operands[0].type) && {
      date_format: condition.operands[0]?.dateFormat,
      date_sub_info: condition.operands[0]?.subInfo?.value,
    }),
  }
}

function findOperatorIndex(condition, operatorType) {
  return condition.operations.findIndex(
    (operation) => operation.type === operatorType
  )
}
