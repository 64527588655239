import { withJsonFormsControlProps, TranslateProps } from '@jsonforms/react';
import React, {useEffect, useState} from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ControlProps, OwnPropsOfEnum } from '@jsonforms/core';
import { Description } from '@engine-b/integration-engine/ui/form-components'

const useStyles = makeStyles((theme) =>
  createStyles({
    DatePicker: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '44px',
      width: '184px !important',
    },
    labelContainer: {
      marginBottom: '16px',
    },
    label: {
      font: 'normal normal bold 16px/18px Arial',
      color: '#44697D',
      letterSpacing: '0.16px',
      verticalAlign: 'center'
    },
    TimePicker: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '44px',
      width: '150px !important',
      marginLeft: '16px',
      marginBottom: '32px',
    },
    errorLabel: {
      color: theme.palette.error.main,
      marginBottom: '16px',
      minWidth: '200px',
    },
    description: {
      minWidth: '400px',
      fontSize: '14px',
    },
    inputLabel: {
      marginTop: '16px',
      with: 'auto'
    }
  })
)

const DateTimePicker = (props: ControlProps & OwnPropsOfEnum & TranslateProps) => {
  const [dateValue, setDateValue] = useState("")
  const [timeValue, setTimeValue] = useState("")
  const classes = useStyles()

  function formatTime(date) {
    let d = new Date(date)
    let hour = ("0" + d.getHours()).slice(-2);
    let minutes = ("0" + d.getMinutes()).slice(-2);
    let seconds = ("0" + d.getSeconds()).slice(-2);

    return [hour, minutes, seconds].join(':');
}

  useEffect(() => {
    if(dateValue || timeValue) {
      const formattedDate = new Date(`${dateValue}T${timeValue}`)
      if(formattedDate.toString() === "Invalid Date") { 
        props.handleChange(props.path, "Error")
      } else {
        const formattedDateTime = formattedDate.toISOString()
        props.handleChange(props.path, formattedDateTime)
      }
    }
  }, [dateValue, timeValue])

  return (
    <Grid container>
      <Grid item xs={12} className={classes.labelContainer}>
        <Typography className={classes.label}>{props.label}</Typography>
        {props.required && <Typography style={{color: 'red'}}> *</Typography>}
      </Grid>
      <Grid item xs={12}>
        {props.description && (
          <Grid item xs={12}>
            <Typography className={classes.description}>{props.description}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.DatePicker}
          type="date"
          inputProps={{
            "step": 1,
            "data-testid": "datetime-picker-date"
          }}
          value={dateValue}
          required={props.required}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            if(e.target.value === '') {
              setTimeValue('')
              setDateValue('')
            } else {
              setTimeValue(formatTime(Date.now()))
              setDateValue(e.target.value)
            }
          }}
        />
        <TextField
          label={" "}
          className={classes.TimePicker}
          type="time"
          inputProps={{
            "step": 1,
            "data-testid": "datetime-picker-time"
          }}
          required={props.required}
          InputLabelProps={{
            shrink: true,
          }}
          value={timeValue}
          onChange={(e) => {
            setTimeValue(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <label className={classes.errorLabel} data-testid="date-time-picker-error-msg">{props.errors}</label>
      </Grid>
    </Grid>
  )
}

export const DateTimePickerControl = withJsonFormsControlProps(DateTimePicker);