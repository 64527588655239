import React, {
    Fragment, useCallback, useContext, useEffect, useState
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    Grid,
    Typography,
    createStyles,
    makeStyles,
    Card,
    CircularProgress,
    Backdrop,
    Button,
    TextField
} from '@material-ui/core'
import BasicDetails from './BasicDetails'
import {
    AppRoute,
    localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { Dialog } from '@engine-b/shared/components'
import {
    useIEDispatch, useIESelector, CreateOperationLog, createAndUploadOperationsLogFile,
    resetAuditAnalytics, beginOperationLog,
    handleBasicForm,
    IFunctions,
    IFunctionsFilters,
    getOperationLog
} from '@engine-b/integration-engine/data/state/redux';
import { useApolloClient } from '@apollo/client';
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory';
import { useIntl } from 'react-intl'
import {
    Description,
} from '@engine-b/integration-engine/ui/form-components'

const useStyles = makeStyles((theme) =>
    createStyles({
        TableJoinsContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: '#F0FBFA 0% 0% no-repeat padding-box',
            minHeight: 'calc(100vh - 80px)',
            maxWidth: 'calc(100vw - 280px)',
            padding: '30px',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        Title: {
            display: 'flex',
            alignItems: 'center',
            font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
            letterSpacing: '0.3px',
            color: '#22353F',
            opacity: 1,
        },
        TableJoinsContent: {
            maxWidth: '100%',
            flex: 1,
            flexDirection: 'column',
            border: '1px solid #44697D33',
            borderRadius: '10px',
            marginTop: '30px',
            '& > .MuiGrid-item': {
                padding: theme.spacing(3),
            },
            '& .MuiAlert-message': {
                '& li': {
                    wordBreak: 'break-word',
                },
            },
        },
        addButton: {
            borderRadius: '8px',
            minWidth: 'unset',
            width: '200px',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        saveButton: {
            borderRadius: '8px',
            minWidth: 'unset',
            width: '164px',
            height: '40px',
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        },
        BundleInputStyle: {
            '& .MuiInputBase-root': {
                borderRadius: '8px',
            },
        },
    })
)
/* eslint-disable-next-line */
export interface AuditAnalyticsViewProps { }

export function AuditAnalyticsView(props: AuditAnalyticsViewProps) {
    const { operationId } = useParams<{ operationId: string }>()
    const history = useHistory();
    const { formatMessage, locale } = useIntl()
    const classes = useStyles();
    const dispatch = useIEDispatch();
    const client = useApolloClient();
    const azureClient = useContext(AzureClientContext);
    const [launchingOperationProcess, setLaunchingOperationProcess] = useState(false)
    const [saveBundle, setSaveBundle] = useState(false)
    const [disableRunButton, setDisableRunButton] = useState(false)
    const {
        loading,
        selectedBundleDetails,
        functions,
        selectedFilePath,
        name
    } = useIESelector((state) => state.auditAnalytics)

    const processHandler = useCallback(async (type: "save" | "run") => {
        setLaunchingOperationProcess(true)
        await dispatch(CreateOperationLog({ client })).then(res => {
            if (res.meta.requestStatus === 'rejected')
                throw 'Error occurred while adding Operation Log'
            dispatch(createAndUploadOperationsLogFile({ azureClient })).then((createAndUploadOperationsFileRes) => {
                if (createAndUploadOperationsFileRes.meta.requestStatus === 'rejected')
                    throw 'Error occurred while uploading operations file'
                dispatch(beginOperationLog({ client })).then((beginOperationLogRes) => {
                    if (beginOperationLogRes.meta.requestStatus === 'rejected')
                        throw 'Error occurred while initializing table join'
                    dispatch(resetAuditAnalytics({}))
                    history.push(localizeRouteKey(formatMessage, locale, AppRoute.AuditAnalytics))
                }).finally(() => {
                    setLaunchingOperationProcess(false)
                })
            }).catch(err => {
                console.log("An error Occured While Uploading File ", err)
                setLaunchingOperationProcess(false)
            })
        }).catch(err => {
            console.log("An error Occured while Creating Operation log ", err.message)
            setLaunchingOperationProcess(false)
        })
    }, [])

    useEffect(() => {
        const isFunctionValid = (func: IFunctions) => {
            if (!func?.value || !selectedFilePath) {
                return false;
            }

            if (func.value.name === "Sum" || func.value.name === "Count") {
                return !!func.filters?.every((filter: IFunctionsFilters) => !!filter?.filterColumn?.id);
            }

            return !!func.filters?.every(
                (filter: IFunctionsFilters) =>
                    !!filter?.input &&
                    !!filter?.filterCondition?.id &&
                    !!filter?.filterColumn?.id
            );
        };
        let isNameValid
        if (name.trim()?.length < 3 || name.trim()?.length > 50) {
            isNameValid = false
        } else {
            isNameValid = true
        }
        
        const isRequiredFieldsValid = functions.every(isFunctionValid) && isNameValid;

        setDisableRunButton(!isRequiredFieldsValid);
    }, [functions, selectedFilePath, name]);

    const handleClientChangeDialog = (
        type: string,
        optionData: any,
    ) => {
        dispatch(handleBasicForm({ name: type, value: optionData }))
    }

    useEffect(() => {
        if (operationId) dispatch(getOperationLog({ id: operationId, client }))
    }, [operationId])

    return (
        <Fragment>
            <section></section>
            <section className={classes.TableJoinsContainer}>
                <Typography className={classes.Title} color="primary">
                    Digital Audit Analytics
                </Typography>
                <Grid
                    container
                    className={classes.TableJoinsContent}
                    component={Card}
                    elevation={0}
                >
                    <Grid item>
                        <BasicDetails editMode={operationId ? true : false} />
                    </Grid>

                    <Grid container spacing={3} justify="flex-end" style={{ padding: "24px", paddingTop: "0" }}>
                        <Grid item>
                            {
                                !operationId ?
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        className={classes.saveButton}
                                        onClick={() => setSaveBundle(true)}
                                        disabled={disableRunButton}
                                    >
                                        Save & Run Process
                                    </Button>
                                    : null
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="contained"
                                className={classes.saveButton}
                                onClick={() => processHandler('run')}
                                disabled={disableRunButton}
                            >
                                Run Process
                            </Button>
                        </Grid>
                    </Grid>
                    <Backdrop
                        className={classes.backdrop}
                        open={loading || launchingOperationProcess}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Grid>
                <Dialog
                    cancelText="Cancel"
                    confirmText="Save"
                    width="674px"
                    height="400px"
                    open={saveBundle}
                    onClose={() => {
                        setSaveBundle(false)
                        processHandler('run')
                    }}
                >
                    <>
                        <Grid item xs={12}>
                            <Description
                                inputLabelId={'bundleLabel'}
                                title={'Bundle Name'}
                                description={'Please enter a name for the Bundle'}
                            >
                                <TextField
                                    name="inputValue"
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    label="Input"
                                    value={selectedBundleDetails.name}
                                    className={classes.BundleInputStyle}
                                    onChange={(e) => {
                                        handleClientChangeDialog('selectedBundleDetails', { id: "", name: e.target.value });
                                    }}
                                    placeholder={'Enter Input Value'}
                                    type="text"
                                    fullWidth={true}
                                />
                            </Description>
                        </Grid>
                    </>
                </Dialog>
            </section>
        </Fragment>
    )
}

export default AuditAnalyticsView
