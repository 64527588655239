import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Theme } from '@engine-b/integration-engine/ui/theme'

const useStyles = makeStyles((theme: Theme) => {
  const HEADER_HEIGHT = '80px'
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '280px',
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      background: '#44697D 0% 0% no-repeat padding-box',
      borderLeft: 'none',
      position: 'fixed',
      boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.1)',
      '& h2': {
        width: '90%',
        padding: '0 20px',
        color: '#fff',
      },
      '& ul': {
        width: '100%',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        padding: '0',
        '& li:hover': {
          backgroundColor: theme.background.primary.main,
        },
        '& li': {
          width: '100%',
          margin: '0 0 0 0',
          display: 'flex',
          '& .selected': {
            background: '#00B2A9 0% 0% no-repeat padding-box',
            borderLeft: '4px solid ' + theme.palette.secondary.light,
            font: 'normal normal bold 18px/21px Arial',
          },
          '& a': {
            textDecoration: 'none',
            padding: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            font: 'normal normal normal 18px/21px Arial',
            fontSize: '18px',
            color: '#fff',
            alignItems: 'center',
          },
        },
      },
    },
  })
})

export function SideBar({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root} role="navigation">
      {children}
    </div>
  )
}
