import { gql } from '@apollo/client'

export const ADD_UPLOADED_FILE = gql`
  mutation addUploadedFile(
    $dataIngestionId: String!
    $name: String!
    $size: String!
    $status: DataIngestionStatus!
    $fileNameByUser: String!
  ) {
    addUploadedFile(
      dataIngestionId: $dataIngestionId
      name: $name
      size: $size
      status: $status
      fileNameByUser: $fileNameByUser
    ) {
      name
      size
      status
      fileNameByUser
    }
  }
`
