import { useApolloClient } from '@apollo/client'
import {
  getGroupings,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { ReactComponent as IconClose } from '../../assets/IconClose.svg'
import { ReactComponent as IconInfo } from '../../assets/IconInfo.svg'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  existingMapping: {
    '& .MuiDialog-paper': {
      width: '880px',
      height: '655px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '30px 30px 30px 35px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      '& > h2': {
        display: 'flex',
        justifyContent: 'space-between',
        font: 'normal normal bold 24px/40px Arial',
        letterSpacing: '0.24px',
        color: '#22353F',
      },
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginTop: '12px',
    },
    '& .MuiInputBase-root': {
      width: '510px',
      height: '52px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiDialogActions-root': {
      flexDirection: 'column',
      '& > .action-btns': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& > button': {
          height: '44px',
          minWidth: 0,
        },
        '& > button:first-child': {
          width: '132px',
          background: '#89A0AC 0% 0% no-repeat padding-box',
          color: '#fff',
        },
        '& > button:last-child': {
          width: '128px',
          marginLeft: '15px',
        },
      },
    },
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  makeCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    background: '#f8fafb',
    width: '780px',
    height: 'auto',
    padding: '15px',
    borderRadius: '8px',
    borderLeft: '8px solid #344B5F',
    margin: '24px 0 60px',
    '& .fn-right': {
      color: '#22353F',
      marginLeft: '10px',
      '& span': {
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0px',
      },
    },
  },
  inputSearch: {
    width: '324px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    '& .MuiOutlinedInput-root': {
      height: '44px',
    },
  },
}))

const ExistingGroupingDialog = ({ open, onClose, onLoad, loading }) => {
  const { existingMapping, loader, makeCenter, info, inputSearch } = useStyles()

  const dispatch = useIEDispatch()
  const groupings = useIESelector((state) => state.groupings)
  const client = useApolloClient()

  const [selectedValue, setSelectedValue] = useState('')
  const [groupingsData, setGroupingsData] = useState([])

  useEffect(() => {
    setSelectedValue('')
    if (open) {
      dispatch(getGroupings(client))
    }
  }, [open])

  useEffect(() => {
    if (groupings?.length) {
      setGroupingsData(groupings)
    }
  }, [groupings])

  const onSearchHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setGroupingsData(
      groupings.filter((item) =>
        item.name.toUpperCase().includes(e.target.value.toUpperCase())
      )
    )
  }

  return (
    <Dialog open={open} className={existingMapping} maxWidth={false}>
      <DialogTitle>
        <span>Existing Groupings</span>
        <IconButton onClick={() => onClose(false)}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <TextField
        variant="outlined"
        placeholder="Search"
        classes={{ root: inputSearch }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onSearchHandler}
      />
      <DialogContent>
        {!loading ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Last Update</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupingsData.map((group) => (
                  <TableRow key={group.id}>
                    <TableCell size="small" style={{ width: '25%' }}>
                      {group.lastUpdatedBy}
                    </TableCell>
                    <TableCell size="small" style={{ width: '25%' }}>
                      {group.name}
                    </TableCell>
                    <TableCell size="small" className={makeCenter}>
                      <span>{group.version}</span>
                      <Radio
                        checked={selectedValue === group.id}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        value={group.id}
                        name="mapped-files"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className={loader}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div className={info}>
          <div className="fn-left">
            <IconInfo />
          </div>
          <div className="fn-right">
            <Typography color="inherit" style={{ whiteSpace: 'pre-wrap' }}>
              On apply, this will replace the current custom groups with the
              saved groups. Select Cancel to return to Custom groups, no changes
              applied
            </Typography>
          </div>
        </div>
        <div className="action-btns">
          <Button variant="contained" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={selectedValue.length === 0}
            onClick={() => {
              onLoad(selectedValue)
              onClose(true, selectedValue)
            }}
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingGroupingDialog
