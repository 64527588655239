import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import { Route, Redirect } from 'react-router-dom'
import { AppLanguage } from '../constants'

import { LanguageStrings } from '../localizations'

interface Props {
  RouterComponent: React.ComponentClass<any>
  languages: { [k: number]: string }
  appStrings: { [prop: string]: LanguageStrings }
  defaultLanguage?: AppLanguage
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage = AppLanguage.English,
}) => {
  return (
    <RouterComponent>
      <Route path="/:lang([a-zA-Z]{2}|[a-zA-Z]{2}-[a-zA-Z]{2})">
        {({ match }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const lang = match?.params?.lang || defaultLanguage

          /**
           * If language is not in route path, redirect to language root
           */
          const locales: string[] = Object.values(AppLanguage)

          if (!lang || !locales.includes(lang)) {
            return <Redirect to={`/${defaultLanguage}/`} />
          }

          /**
           * Return Intl provider with default language set
           */
          return (
            <IntlProvider
              locale={lang}
              messages={appStrings[lang]}
              onError={() => null}
            >
              {children}
            </IntlProvider>
          )
        }}
      </Route>
    </RouterComponent>
  )
}
