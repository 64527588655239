import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    addDataIcon: {
      width: '32px',
    },
  })
)

export function AddDataIcon() {
  const classes = useStyles()

  return (
    <div className={classes.addDataIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="28"
        viewBox="0 0 24 28"
      >
        <path
          id="add-data"
          d="M157,63a3,3,0,0,0,3-3V59h1a3,3,0,0,0,3-3V42a1,1,0,0,0-.29-.71l-6-6A1,1,0,0,0,157,35H147a3,3,0,0,0-3,3v1h-1a3,3,0,0,0-3,3V60a3,3,0,0,0,3,3ZM144,41V56a3,3,0,0,0,3,3h11v1a1,1,0,0,1-1,1H143a1,1,0,0,1-1-1V42a1,1,0,0,1,1-1Zm16.59,0H159a1,1,0,0,1-1-1V38.4Zm1.117,15.707A1,1,0,0,1,161,57H147a1,1,0,0,1-1-1V38a1,1,0,0,1,1-1h9v3a3,3,0,0,0,3,3h3V56A1,1,0,0,1,161.707,56.707Z"
          transform="translate(-140 -35)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
