import { gql } from '@apollo/client';

export const GET_PRE_UPLOADED_FILES_BY_FILE_SYSTEM_NAME = gql`
    query preUploadedFiles($clientName: String!, $engagement: String!) {
        preUploadedFiles(clientName: $clientName, engagement: $engagement) {
            name
            contentLength
            lastModified
            }
        }
    `
