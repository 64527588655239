import { ApolloClient } from '@apollo/client'
import {
  CREATE_AUDITED_ENTITY_BY_USER,
  GET_AUDITED_ENTITIES_BY_USER,
  GET_ENGAGEMENTS_BY_USER_AND_AUDITED_ENTITY,
  CREATE_ENGAGEMENT,
} from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface RECORD {
  id: string
  name: string
}

export interface ID_PAYLOAD {
  key: string
  value: string
}

export interface ENGAGEMENT_VIEW {
  loader: boolean
  auditedEntities: RECORD[]
  engagements: RECORD[]
  auditedEntity: RECORD
  engagement: RECORD
  engagementError: string
}

const INITIAL_STATE: ENGAGEMENT_VIEW = {
  loader: false,
  auditedEntities: [],
  engagements: [],
  auditedEntity: null,
  engagement: null,
  engagementError: '',
}

export const getAuditedEntitiesByUser = createAsyncThunk(
  'engagement/getAuditedEntitiesByUser',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_AUDITED_ENTITIES_BY_USER,
    })
    return response
  }
)

export const getEngagements = createAsyncThunk(
  'engagement/getEngagements',
  async (payload: { client: ApolloClient<unknown>; id: string }) => {
    const response = await payload.client.query({
      query: GET_ENGAGEMENTS_BY_USER_AND_AUDITED_ENTITY,
      variables: {
        auditedEntityId: payload.id,
      },
    })
    return response
  }
)

export const createAuditedEntity = createAsyncThunk(
  'engagement/createAuditedEntity',
  async (payload: { client: ApolloClient<unknown>; name: string }) => {
    const { client, name } = payload
    return await client.mutate({
      mutation: CREATE_AUDITED_ENTITY_BY_USER,
      variables: {
        name,
      },
    })
  }
)

export const createEngagement = createAsyncThunk(
  'engagement/createEngagement',
  async (
    payload: {
      client: ApolloClient<unknown>
      name: string
      auditedEntityId: string
    },
    { rejectWithValue }
  ) => {
    const { client, name, auditedEntityId } = payload
    const { data, errors } = await client.mutate({
      mutation: CREATE_ENGAGEMENT,
      variables: {
        engagement: {
          name,
          auditedEntityId,
        },
      },
    })
    return data ? data : rejectWithValue(errors)
  }
)

export const engagementSlice = createEngagementSlice(INITIAL_STATE)

export function createEngagementSlice(initialState: ENGAGEMENT_VIEW) {
  return createSlice({
    name: 'engagement',
    initialState,
    reducers: {
      setIds: (state, { payload }) => {
        const { key, value }: ID_PAYLOAD = payload
        state[key] = value
        state.engagementError = ''
        if (key === 'auditedEntity') {
          state.engagement = null
          state.engagements = []
        }
      },
      resetEngagementState: (state) => {
        return INITIAL_STATE
      },
      resetEngagements: (state) => {
        state.engagements = []
      },
      resetEngagementError: (state) => {
        state.engagementError = ''
      },
    },
    extraReducers: (builder) => {
      // get all audited entities
      builder.addCase(getAuditedEntitiesByUser.pending, (state, action) => {
        state.loader = true
      })
      builder.addCase(getAuditedEntitiesByUser.fulfilled, (state, action) => {
        state.loader = false
        state.auditedEntities = action.payload.data.auditedEntitiesByUser
      })
      builder.addCase(getAuditedEntitiesByUser.rejected, (state, action) => {
        state.loader = false
      })
      // get all engagements
      builder.addCase(getEngagements.pending, (state, action) => {
        state.loader = true
      })
      builder.addCase(getEngagements.fulfilled, (state, action) => {
        state.loader = false
        state.engagements =
          action.payload.data.engagementsByUserAndAuditedEntity
      })
      builder.addCase(getEngagements.rejected, (state, action) => {
        state.loader = false
      })
      // Create Audited Entity
      builder.addCase(createAuditedEntity.pending, (state, action) => {
        state.loader = true
      })
      builder.addCase(createAuditedEntity.fulfilled, (state, action) => {
        const { id, name, success, message } =
          action.payload.data.createAuditedEntityByUser
        state.loader = false
        if (success) {
          state.auditedEntities.push({ id, name })
          state.auditedEntity = { id, name }
        }
      })
      builder.addCase(createAuditedEntity.rejected, (state, action) => {
        state.loader = false
      })
      // create engagement
      builder.addCase(createEngagement.pending, (state, action) => {
        state.loader = true
        state.engagementError = ''
      })
      builder.addCase(createEngagement.fulfilled, (state, action) => {
        state.loader = false
        state.engagements.push(action.payload.createEngagement)
        state.engagement = action.payload.createEngagement
        state.engagementError = ''
      })
      builder.addCase(createEngagement.rejected, (state, action) => {
        state.loader = false
        state.engagementError = action.error.message
      })
    },
  })
}

export const {
  setIds,
  resetEngagementState,
  resetEngagements,
  resetEngagementError,
} = engagementSlice.actions

export const engagementReducer = engagementSlice.reducer
