import {
  file,
  join,
  JOINS_UPLOAD_STATE,
  FILE_TYPE,
  PRE_UP_LOADING_STATE,
  JOIN_DIRECTION,
  OperationType,
} from './typedefs'

export const INIT_FILE_STATE: file = {
  name: '',
  path: '',
  columns: [],
  fileSize: 0,
  uploaded: 0,
  uploadState: JOINS_UPLOAD_STATE.NOT_STARTED,
  requestId: null,
  fileType: FILE_TYPE.UPLOADED,
}

export const INIT_JOINS_STATE: join = {
  name: '',
  validName: false,
  joinId: '',
  engagement: {
    id: null,
    name: '',
  },
  auditedEntity: {
    id: null,
    name: '',
    auditFirm: {
      id: null,
      name: '',
      systemName: '',
    },
  },
  preUpFiles: [],
  isLoading: PRE_UP_LOADING_STATE.LOADING,
  startsWith: '',
  tables: {},
  operations: [],
  joinListing: [],
  loading: false,
  operationType: OperationType.COMBINE,
}

export const INIT_OPERATIONS_STATE = {
  direction: JOIN_DIRECTION.LEFT,
  joinTo: '',
  rules: [
    {
      origin: {
        table: '',
        column: '',
      },
      target: {
        table: '',
        column: '',
      },
    },
  ],
}
