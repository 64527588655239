import React from 'react'
import { Switch as MuiSwitch, makeStyles, Theme } from '@material-ui/core'

import PropTypes from 'prop-types'

type Color = 'primary' | 'secondary'

/* eslint-disable-next-line */
export interface SwitchProps {
  color: Color
  onChange: any
  name?: string
  checked: boolean
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: 49,
      height: 22,
      padding: 0,
      display: 'flex',
      overflow: 'unset',
    },
    switchBase: {
      padding: '0px',
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(27px)', //width of root - width of thumb
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: '0px 1px 3px #0000001A',
      border: '1px 1px solid #DEDFDF',
    },
    track: {
      borderRadius: '20px',
      opacity: 1,
      backgroundColor: '#89A0AC',
    },
    checked: {},
    focusVisible: {},
  }
})

export function Switch({
  color,
  onChange,
  name,
  checked: isChecked,
}: SwitchProps) {
  const { root, switchBase, thumb, track, checked } = useStyles()
  return (
    <MuiSwitch
      checked={isChecked}
      name={name}
      color={color}
      onChange={onChange}
      classes={{
        root,
        switchBase,
        thumb,
        track,
        checked,
      }}
    />
  )
}

Switch.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  // onChange: PropTypes.func,
  checked: PropTypes.bool,
}

Switch.defaultProps = {
  color: 'secondary',
  name: 'mySwitch',
  onChange: () => console.log('changed'),
  checked: false,
}

export default Switch
