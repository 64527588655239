import React, { useEffect } from 'react'
import {
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { EBAutoComplete } from '@engine-b/shared/components'
import {
  getAuditedEntitiesByUser,
  getDataIngestionsDNA,
  getEngagements,
  getSavedPipelinesPath,
  handleBasicFormDetails,
  resetDigitalAuditAnalytics,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { useMsal } from '@azure/msal-react'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { ReactComponent as IconInfo } from './assets/IconInfo.svg'

const useStyles = makeStyles(() =>
  createStyles({
    customInput: {
      '& .MuiInputBase-root': {
        height: '48px',
        borderRadius: '8px',
      },
    },
    label: { color: '#222222' },
    requiredField: {
      color: '#222222',
      fontSize: '14px',
      paddingLeft: '12px',
    },
    tooltip: {
      fontSize: 13,
    },
  })
)

const DataAndAnalyticsDetails = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const dispatch = useIEDispatch()
  const { accounts, instance, inProgress } = useMsal()

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { name, clientDetails, engagementDetails, pipelineNameError } =
    useIESelector((state) => state.digitalAuditAnalytics)

  const getSavedPipelines = async () => {
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const data = {
      audit_firm: clientDetails.auditFirm?.systemName,
      client: clientDetails.name,
      engagement: engagementDetails.name,
      is_saved: true,
    }
    dispatch(getSavedPipelinesPath({ data, token }))
  }

  useEffect(() => {
    if (clientDetails !== null && engagementDetails !== null) {
      dispatch(
        getDataIngestionsDNA({
          client,
          page: 1,
          pageSize: 10000,
          auditedEntityId: clientDetails?.id,
          engagementId: engagementDetails?.id,
        })
      )
        .unwrap()
        .then(() => getSavedPipelines())
    }
  }, [clientDetails, engagementDetails])

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))

    return () => {
      dispatch(resetDigitalAuditAnalytics({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getEngagements({ client, id: clientDetails?.id || '' }))
    dispatch(handleBasicFormDetails({ name: 'engagementDetails', value: null }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetails?.id])

  return (
    <Grid
      container
      spacing={3}
      alignItems="flex-start"
      style={{ height: '100%' }}
    >
      <Grid item xs={12}>
        <InputLabel
          classes={{ root: classes.label }}
          style={{ marginBottom: '8px' }}
        >
          Pipeline Name<span>*</span>
        </InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          color="secondary"
          placeholder={'Enter New Pipeline name'}
          classes={{ root: classes.customInput }}
          value={name}
          onChange={(e) =>
            dispatch(
              handleBasicFormDetails({ name: 'name', value: e.target.value })
            )
          }
        />
        {pipelineNameError && (
          <span
            style={{ color: '#CC3333', textAlign: 'left', marginTop: '.25rem' }}
          >
            Pipeline name already exists.
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          classes={{ root: classes.label }}
          style={{ marginBottom: '8px' }}
        >
          Client Name<span>*</span> &nbsp;
          <Tooltip
            title={
              'Select the relevant Client Name and Engagement Name as shown in the "Ingestions" section.'
            }
            placement="right"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconInfo />
          </Tooltip>
        </InputLabel>
        <EBAutoComplete
          options={auditedEntities}
          getOptionLabel={(option) => option.name || ''}
          forcePopupIcon={true}
          placeholder="Select client name"
          height={48}
          size="small"
          value={clientDetails}
          onChange={(e: any, optionData) => {
            dispatch(
              handleBasicFormDetails({
                name: 'clientDetails',
                value: optionData,
              })
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          classes={{ root: classes.label }}
          style={{ marginBottom: '8px' }}
        >
          Engagement Name<span>*</span> &nbsp;
          <Tooltip
            title={
              'Select the relevant Client Name and Engagement Name as shown in the "Ingestions" section.'
            }
            placement="right"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconInfo />
          </Tooltip>
        </InputLabel>
        <EBAutoComplete
          options={engagements}
          getOptionLabel={(option) => option.name || ''}
          forcePopupIcon={true}
          height={48}
          placeholder="Select engagement name"
          size="small"
          value={engagementDetails}
          onChange={(e: any, optionData) => {
            dispatch(
              handleBasicFormDetails({
                name: 'engagementDetails',
                value: optionData,
              })
            )
          }}
        />
      </Grid>
      <span className={classes.requiredField}>* required fields</span>
    </Grid>
  )
}

export default DataAndAnalyticsDetails
