import React, { useEffect, useState } from 'react'
import { ReactComponent as IconInfo } from '../../assets/IconInfo.svg'
import { ReactComponent as IconCloseTab } from '../../assets/close-tab.svg'
import { ReactComponent as IconError } from '../../assets/IconError.svg'
import { Tab, Tabs, styled, Popper, Typography } from '@material-ui/core'
import { FileConfigRow } from './file-config-row'
import {
  addEntity,
  Entity,
  removeEntity,
  useIEDispatch,
  useIESelector,
  EditErpTypes,
} from '@engine-b/integration-engine/data/state/redux'
import CustomErpTable from './tableComponents/CustomErpTable'
import { useApolloClient } from '@apollo/client'
import { EBAutoComplete, Dialog } from '@engine-b/shared/components'

export interface ExtractTypeTabProps {
  TabPanel: any
  extractType: string
  entity: Array<Entity>
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    fontWeight: 'bold',
    fontSize: 'large',
  },
}))

const ExtractTypeTab = ({
  TabPanel,
  entity,
  extractType,
}: ExtractTypeTabProps) => {
  const dispatch = useIEDispatch()
  const { entities } = useIESelector((state) => state.customERP)
  // state variables
  const [tabValue, setTabValue] = useState<number>(0)
  const [options, setOptions] = useState<any>([])
  const [selectedEntity, setSelectedEntity] = useState<string>('-1')
  const client = useApolloClient()
  const [customERPs, setCustomERPs] = useState<any>({ ...entities })
  const [isDeleteCustomErpModalOpen, setIsDeleteCustomErpModalOpen] =
    useState<boolean>(false)
  const [customErpIdToDelete, setCustomErpIdToDelete] = useState<object | null>(
    null
  )

  const handleTabChange = (event, newValue) => {
    if (event.target.localName === 'span') {
      setTabValue(newValue)
    }
  }

  useEffect(() => {
    setCustomERPs((prevCustomERPs) => ({
      ...prevCustomERPs,
      [extractType]: entities[extractType].reduce((acc, x) => {
        if (!acc.some((erp) => erp.entity_name === x.entity_name)) {
          return [...acc, x]
        }
        return acc
      }, prevCustomERPs[extractType] || []),
    }))
  }, [entities])

  const onAddEntity = (e: any, { value }: any) => {
    const option = availableOptions.find((v) => v.value === value)
    setSelectedEntity(value)
    setOptions([...options, option])
    dispatch(
      addEntity({
        client,
        entity_name: value,
        display_name: option.title,
        extractType,
      })
    )
  }

  const onRemoveSelectErp = (index, extractType) => {
    setIsDeleteCustomErpModalOpen(true)
    setCustomErpIdToDelete({ entityIndex: index, extractType: extractType })
  }

  const handleCustomErpDelete = (v: boolean) => {
    if (v) {
      if (customErpIdToDelete) {
        onRemoveEntity(customErpIdToDelete)
      }
    }
    setIsDeleteCustomErpModalOpen(false)
    setCustomErpIdToDelete(null)
  }

  const onRemoveEntity = (customErpIdToDelete) => {
    tabValue > 0 ? setTabValue(tabValue - 1) : setTabValue(0)

    setTimeout(() => {
      const copy = [...options]
      copy.splice(customErpIdToDelete.i, 1)
      setOptions([...copy])
      dispatch(removeEntity(customErpIdToDelete))
    }, 0)
    const remainERPs = [...customERPs[extractType]]
    remainERPs.splice(customErpIdToDelete.entityIndex, 1)
    setCustomERPs({
      ...customERPs,
      [extractType]: [...remainERPs],
    })
  }
  const entitiesListItems = useIESelector(({ customERP }) => {
    return customERP.entitiesListItems
  })
  const availableOptions = entitiesListItems.map(
    ({ displayName, name, standard }) => ({
      title: displayName,
      value: name,
      standardName: standard?.displayName || '',
    })
  )

  const isSelected = (value: string) =>
    entity.findIndex((v) => v.entity_name === value) > -1

  useEffect(() => {
    setSelectedEntity('-1')
  }, [options])

  return (
    <>
      <div className="re__info">
        <IconInfo />
        <div>
          Use this screen to set the Integration Engine's expectation for what a
          file from this system looks like. For each of the Field Names
          (columns) in your system, please indicate whether the field is
          required, what it is required for, and whether it is allowed to
          include any blanks.
        </div>
      </div>
      <div className="entity__select_tab">
        <div className="left">
          <label htmlFor="entity-list">Report Type and Mapping</label>
          <EBAutoComplete
            PopperComponent={StyledPopper}
            options={availableOptions}
            groupBy={(option) => option.standardName}
            getOptionLabel={(option) => option.title}
            getOptionDisabled={(option) => isSelected(option.value)}
            width={250}
            size="small"
            onChange={(e: any, value, reason) =>
              reason === 'select-option' && onAddEntity(e, value)
            }
            hiddenLabel={true}
            placeholder="Select Report Type"
          />
        </div>
        <div className="right">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className="entity__tabs"
            aria-label="Entity Types Tabs"
            variant="scrollable"
            scrollButtons={entity.length > 4 ? 'auto' : 'off'}
          >
            {entity.map((option, i) => (
              <Tab
                key={option.entity_name}
                data-testid="entity-tabs"
                label={
                  <>
                    <span>{option.display_name}</span>
                    <IconCloseTab
                      onClick={() => onRemoveSelectErp(i, extractType)}
                    />
                  </>
                }
              />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="file__config__row">
        {entity.map((value, i) => (
          <TabPanel value={tabValue} index={i} key={i}>
            <FileConfigRow type={extractType} index={i} />
            <CustomErpTable extractType={extractType} entityIndex={i} />
          </TabPanel>
        ))}
      </div>
      <Dialog
        title=""
        cancelText="No"
        confirmText="Yes"
        open={isDeleteCustomErpModalOpen}
        onClose={handleCustomErpDelete}
        width="650px"
        height="450px"
      >
        <>
          <IconError />
          <br />
          <Typography component={'div'} className="dialog-title">
            The ERP progress will be lost. Save the change before leaving this
            ERP.
          </Typography>
          <Typography component={'div'} className="dialog-subtitle">
            Are you sure your want to lose this ERP?
          </Typography>
        </>
      </Dialog>
    </>
  )
}

export default ExtractTypeTab
