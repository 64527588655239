import React, { memo } from 'react'
import { Position } from 'reactflow'
import { Tooltip } from '@mui/material'
import InputHandle from '../Handles/InputHandle'

const OutputNode = ({ data, selected }) => {
  const isError = data?.error
  const isSelected = selected

  let borderStyle
  if (isError) {
    borderStyle = '2px solid red'
  } else if (isSelected) {
    borderStyle = '2px solid #00b2a9'
  } else {
    borderStyle = ''
  }

  return (
    <Tooltip title={isError || ''}>
      <div
        style={{
          background: 'white',
          padding: 16,
          border: borderStyle,
          borderRadius: '5px',
          boxShadow: `rgba(219, 219, 219, 0.98) 7px 7px 5px 0`,
        }}
      >
        <InputHandle type="target" position={Position.Left} isConnectable={1} />
        <InputHandle
          type="source"
          position={Position.Right}
          isConnectable={3}
        />
        <div>{data?.label}</div>
      </div>
    </Tooltip>
  )
}

export default memo(OutputNode)
