import {
  InteractionStatus,
  IPublicClientApplication,
  SilentRequest,
  AccountInfo,
} from '@azure/msal-browser'
import { InteractionRequiredAuthError } from '@azure/msal-common'
import { AccessToken } from '@azure/core-http'

export async function asyncTokenLookup(conf: {
  instance: IPublicClientApplication
  inProgress: InteractionStatus
  accounts: AccountInfo[]
  tokenRequest: SilentRequest
}): Promise<AccessToken> {
  const { instance, inProgress, accounts, tokenRequest } = conf
  const account = accounts[0]

  const accessTokenRequest = {
    ...tokenRequest,
    account: account,
  }

  if (inProgress === InteractionStatus.None) {
    if (account) {
      return instance
        .acquireTokenSilent(accessTokenRequest)
        .then((authResult) => {
          return {
            token: authResult.accessToken,
            expiresOnTimestamp: authResult.expiresOn.getTime(),
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
            return null
          } else {
            console.error('Unable to acquire token!', error)
            return null
          }
        })
    } else {
      instance.acquireTokenRedirect(accessTokenRequest)
      console.info('No account set, redirecting...')
    }
  } else {
    return null
  }
}
