import { useIsAuthenticated } from '@azure/msal-react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Logo from './Logo'
import { SignInButton } from './SignInButton'
import { SignOutButton } from './SignOutButton'
const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: '#FFFFFF',
      width: '100%',
      minHeight: '80px',
      display: 'flex',
      padding: '10px 10px 10px 24px',
      position: 'fixed',
      top: '0',
      zIndex: theme.zIndex.appBar,
      filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12))',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
  })
)

export function Header() {
  const isAuthenticated = useIsAuthenticated()
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <Logo />
      {isAuthenticated ? <SignOutButton /> : <SignInButton />}
    </div>
  )
}

export default Header
