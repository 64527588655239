import React, { useState, useEffect, useMemo } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import DoneIcon from '@material-ui/icons/Done'
import { ReactComponent as IconError } from '../../../assets/IconError.svg'
import { ReactComponent as IconTagWarning } from '../../../assets/IconTagWarning.svg'
import CalculatedFieldDialog, { postMapValidate } from './CalculatedFieldDialog'
import compileRecords from '../utils/compileRecords'
import { makeStyles } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'
import {
  useIESelector,
  useIEDispatch,
  CDMDetails,
  setCustomFileMappings,
  FileDetails,
  CustomMapperState,
  RemappingUnit,
  updateSingleManualSelection,
  CDMMapping,
  CDMFields,
} from '@engine-b/integration-engine/data/state/redux'
import CustomScrollbars from 'apps/integration-engine/src/components/CustomScrollbars'
import Alert from '@material-ui/lab/Alert'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { EBAutoComplete } from '@engine-b/shared/components'
import { useMsal } from '@azure/msal-react'
import cuid from 'cuid'

const useStyles = makeStyles((theme) => ({
  customSelect: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    width: '280px',
    height: '44px',
  },
  customPagination: {
    '& .pager': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        height: '44px',
        margin: '0 5px',
      },
    },
    padding: '30px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiPagination-ul': {
      borderRadius: '8px',
      border: '1px solid #D7DAE2',

      '& li:not(:first-child)': {
        borderLeft: '1px solid #D7DAE2',
      },
    },
    '& .MuiPaginationItem-root': {
      margin: 0,
      borderRadius: 0,
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: '#00B2A9 0% 0% no-repeat padding-box',
    },
  },
  mandate: {
    color: '#37AB3F',
    fontSize: '36px',
    marginLeft: '8px',
    fontWeight: 'bold',
  },
  iconWrapper: {
    minWidth: '40px',
    minHeight: '40px',
    width: '40px !important',
    height: '40px !important',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '3rem',
  },
  errorIcon: {
    background: '#DE4D4D 0% 0% no-repeat padding-box',
    opacity: 1,
    '& span': {
      fontSize: '1.7rem',
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  reportIcon: {
    background: '#F7931E 0% 0% no-repeat padding-box',
    opacity: 1,
    '& svg path': { fill: '#fff' },
  },
  doneIcon: {
    background: '#37AB3F 0% 0% no-repeat padding-box',
    opacity: 1,
    '& svg path': { fill: '#fff' },
  },
  tableHeaders: {
    letterSpacing: '0.16px',
    color: '#44697D',
    '& .MuiTableCell-head': {
      background: '#fff',
      font: 'normal normal bold 16px/18px Arial',
      whiteSpace: 'nowrap',
    },
  },
  erpOptions: {
    display: 'flex',
    width: '100%',
    '& > span:first-child': {
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  tooltip: {
    backgroundColor: '#22353F',
    font: 'normal normal normal 12px/20px Arial',
    letterSpacing: '0.12px',
  },
  tooltipArrow: {
    color: '#22353F',
  },
  errStatus: {
    textAlign: 'center',
  },
  errCount: {
    marginRight: '4px',
  },
  successText: {
    color: '#155724',
  },
  errorText: {
    color: '#DE4D4D',
  },
  groupLabel: {
    whiteSpace: 'nowrap',
    textWeight: 'bold',
    fontSize: 'large',
    padding: '2px',
    marginTop: '2px',
    marginBottom: '2px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  paper: {
    minWidth: '280px',
    width: 'fit-content',
  },
}))

const TableComponent = ({ search, mandatory, files }) => {
  const classes = useStyles()
  const { instance, inProgress, accounts } = useMsal()
  //   local state variables
  const [eRPValue, setERPValue] = useState({})
  const [filteredRecords, setFilteredRecords] = useState([])
  const [paginatedRecords, setPaginatedRecords] = useState([])
  const [numPages, setNumPages] = useState(5)
  const [page, setPage] = useState(1)
  const [calcDialog, setCalcDialog] = useState(false)
  const [cdmField, setCdmField] = useState({})
  const erps = useIESelector((state) => state.erps)

  // redux state variables
  const dispatch = useIEDispatch()
  const cMapper: CustomMapperState = useIESelector(
    (state) => state.customMapper
  )
  const customFileMappings = useIESelector(
    (state) => state.customMapper.customFileMappings
  )
  const draftRun = useIESelector((state) => state.runs.draftRun)

  /**
   * This function to select calculation function for journalLineNumber cdm field
   * When user select direct map for journalId cdm field
   */
  const autoMapJournalLineNumber = async (record, value) => {
    const tableRecords = compileRecords('', false, cMapper.files)
    const tableRow = tableRecords.find(
      ({ name }) => name === 'journalIdLineNumber'
    )

    const { erp_fields, report_type, data_type, mandatory, erp_options } =
      tableRow
    delete value?.confidence_score
    const fileResp = {
      erp_fields: [
        {
          field_id: value?.id,
          order: 1,
          ...value,
        },
      ],
      cdm_field: 'journalIdLineNumber',
      map_type: 'calculated',
      file_name: erp_fields[0]?.file_name || erp_options[1]?.file_name,
      report_type,
      data_type,
      mandatory,
      erp_Id: draftRun.erpId,
      extract_type: draftRun.extractType,
      operation: 'Journal Line Number',
    }
    const erpResp = {
      [tableRow?.id]: {
        data_type: erp_fields[0]?.data_type || erp_options[1]?.data_type,
        field_name: 'Calculated Field',
        file_name: erp_fields[0]?.file_name || erp_options[1]?.file_name,
        null_check_comment: [erp_fields[0]?.null_check_comment],
        null_check_data: '',
        null_check_valid: true,
        present: true,
        type_check_comment: [erp_fields[0]?.type_check_comment],
        type_check_data: [],
        type_check_valid: true,
        consider_valid: true,
        valid: true,
        mandatory: true,
        post_validate_data: '',
      },
    }

    const { status, post_validate_data } = await getStatusAndValidationMsg(
      fileResp,
      report_type
    )

    fileResp['valid'] = status

    erpResp['valid'] = status
    erpResp['post_validate_data'] = post_validate_data

    setERPValue((prevValue) => ({ ...prevValue, ...erpResp }))

    dispatch(setCustomFileMappings({ ...eRPValue, ...erpResp }))
    dispatch(updateSingleManualSelection(fileResp))
  }

  const onChangeHandler = (record: CDMMapping) => async (e, value, reason) => {
    const { name, id, mandatory, report_type, data_type, erp_fields } = record

    if (reason === 'clear' && Object.keys(eRPValue).length > 0) {
      let copy = { ...eRPValue }
      const payload: RemappingUnit = {
        cdm_field: name,
        original_filename: copy[id].originalFileName,
        erp_fields,
        map_type: 'dummy',
        file_name: copy[id].file_name,
        report_type,
        data_type,
        mandatory,
        erp_Id: draftRun.erpId,
        extract_type: draftRun.extractType,
      }

      delete copy[record.id]
      setERPValue(copy)
      dispatch(setCustomFileMappings(copy))
      dispatch(updateSingleManualSelection(payload))
    } else {
      const { field_name } = value
      if (field_name === 'Calculated Field') {
        setCdmField(record)
        setCalcDialog(true)
      } else {
        if (record?.name === 'journalId')
          await autoMapJournalLineNumber(record, value)

        await handleDirectMapping(record, value)
      }
    }
  }

  async function handleDirectMapping(record: any, value: any) {
    const { field_name, file_name } = value
    const { name, id, mandatory, report_type, data_type } = record
    const erpValueCopy = { ...value }
    /**
     * If field is not present in config file set it as valid always
     * Reason is if its not present in config then validation will not perform on it
     */
    if ('valid' in erpValueCopy === false) erpValueCopy.valid = true

    if ('data_type' in erpValueCopy) {
      const validDataType = isValidDataType(data_type, erpValueCopy)
      if (validDataType || name === 'amount') {
        await validateDirectMapping(record, field_name, erpValueCopy)
      } else {
        erpValueCopy.valid = false
        erpValueCopy.post_validate_data = `Data type of ${name} does not match with data type of ${field_name}`
      }
    }
    setERPValue({ ...eRPValue, [id]: { ...erpValueCopy, mandatory } })
    dispatch(
      setCustomFileMappings({
        ...eRPValue,
        [id]: { ...erpValueCopy, mandatory },
      })
    )
    const payload: RemappingUnit = {
      cdm_field: name,
      original_filename: erpValueCopy.originalFileName,
      erp_fields: [erpValueCopy],
      map_type: 'direct',
      file_name: file_name,
      report_type,
      data_type,
      mandatory,
      erp_Id: draftRun.erpId,
      extract_type: draftRun.extractType,
    }
    dispatch(updateSingleManualSelection(payload))
  }
  function isValidDataType(data_type: any, erpValueCopy: any) {
    if (data_type === 'string') {
      return true
    } else if (data_type === 'decimal') {
      return (
        ['decimal', 'number', 'currency', 'integer'].indexOf(
          erpValueCopy['data_type']
        ) > -1
      )
    } else {
      return data_type === erpValueCopy['data_type']
    }
  }

  async function validateDirectMapping(
    record: any,
    field_name: any,
    erpValueCopy: any
  ) {
    const { name, report_type, data_type } = record
    const filename_withExtractType = erpValueCopy?.file_name
    const filename = filename_withExtractType.slice(report_type.length + 1)
    const erp_info = {
      erp_id: draftRun.erpId,
      audit_firm_id: erps[draftRun.erpId]?.auditFirm?.systemName,
      extract: draftRun.extractType,
      report: report_type,
      file_name: filename,
    }
    if (
      name === 'amount' ||
      name === 'amountCreditDebitIndicator' ||
      data_type === 'datetime'
    ) {
      const file = cMapper.files.find((file) => file.reportType === report_type)
      const records = file.records.map((record) => record[field_name])
      const { status, data } = await postMapValidate(
        {
          map_type: 'direct',
          erp_fields: [],
          cdm_field_name: name,
          erp_field_name: field_name,
          cdm_field_data_type: data_type,
          records: records,
          erp_info,
        },
        dispatch,
        instance,
        inProgress,
        accounts
      )
      erpValueCopy.valid = status
      if (status) {
        erpValueCopy.post_validate_data = ''
      } else if (typeof data === 'string') {
        erpValueCopy.post_validate_data = data
      } else {
        erpValueCopy.post_validate_data = data[0].error
      }
    }
  }

  const paginate = () => {
    const result = filteredRecords.slice(numPages * (page - 1), numPages * page)
    setPaginatedRecords(result)
  }
  const onPageChangeHandler = (e) => {
    setPage(1)
    setNumPages(+e.target.value)
  }

  const getFilteredRecords = () => {
    setPage(1)

    // files dropdown change operation
    let selectedFiles: Array<FileDetails> =
      files.length > 0 && !files.includes('All')
        ? cMapper.files.filter((file) => files.includes(file.reportName))
        : cMapper.files

    const records: Array<CDMDetails> = compileRecords(
      search,
      mandatory,
      selectedFiles
    )

    setFilteredRecords(records)
  }

  /**
   * Validate data before applying mapping to the another field
   * retrieve validation status and message
   */
  const getStatusAndValidationMsg = async (
    mapping,
    report_type
  ): Promise<any> => {
    const response = {}
    const file = cMapper.files.find((file) => file.reportType === report_type)
    const { status, data } = await postMapValidate(
      {
        ...mapping,
        records: file?.records,
      },
      dispatch,
      instance,
      inProgress,
      accounts
    )
    response['status'] = status

    if (status) {
      response['post_validate_data'] = ''
    } else if (typeof data === 'string') {
      response['post_validate_data'] = data
    } else {
      response['post_validate_data'] = data[0].error
    }

    return response
  }

  const onCalcFieldClose = async (
    fileResp: RemappingUnit,
    erpResp: CDMFields
  ) => {
    if (fileResp !== null) {
      /**
       * find the record from available records using given_field
       * check whether it present or not
       * copy mapping to table and manual selection
       * @param given_field cdm field name
       */
      const copyMap = async (given_field: string) => {
        const ops = {
          ABS: 'DCI1',
          DC: 'DCI2',
          DCI1: 'ABS',
          DCI2: 'DC',
          RBA: 'DCI3',
          DCI3: 'RBA',
        }
        const tableRecords = compileRecords('', false, cMapper.files)
        const tableRow = tableRecords.find(({ name }) => name === given_field)
        if (tableRow !== undefined) {
          const { id, name, mandatory, data_type, report_type } = tableRow

          erpResp[id] = { ...Object.values(erpResp)[0] }
          const otherMapping = {
            ...fileResp,
            mandatory,
            data_type,
            cdm_field: name,
            operation: ops[fileResp?.operation],
          }

          /**
           * Validate data before applying mapping to the another field
           */
          const { status, post_validate_data } =
            await getStatusAndValidationMsg(otherMapping, report_type)

          otherMapping.erp_fields = otherMapping.erp_fields.map((field) => ({
            ...field,
            valid: status,
          }))
          erpResp[id] = { ...erpResp[id], valid: status, post_validate_data }

          dispatch(updateSingleManualSelection(otherMapping))
        }
      }

      /**
       * When a user selects the 'Amount Calculation' function for amount cdm field
       * Then the tool automatically applies the 'Debit-Credit Indicator'
       * to amountDebitCreditIndicator cdm field
       * And VICE VERSA
       */
      const { cdm_field, operation, extra, split_side } = fileResp

      if (['ABS', 'DC', 'RBA'].indexOf(operation) > -1) {
        if (cdm_field === 'amount') {
          await copyMap('amountCreditDebitIndicator')
        }
      }

      if (['DCI1', 'DCI2', 'DCI3'].indexOf(operation) > -1) {
        if (cdm_field === 'amountCreditDebitIndicator') {
          await copyMap('amount')
        }
      }

      /**
       * When user selects the 'Journal Line Number' function for journalLineNumber cdm field
       * Then the tool automatically apllies the erpField used for map to journalId cdm field
       * VICE VERSA
       */
      if (operation === 'Journal Line Number') {
        const record = paginatedRecords?.find(
          (x) =>
            x?.name === 'journalId' && x?.report_type === fileResp?.report_type
        )
        if (record) {
          const value = record?.available_options?.find(
            (option) => option?.id === fileResp?.erp_fields[0]?.id
          )
          handleDirectMapping(record, value)
        }
      }

      /**
       * When Split function is used and remainder is selected then only this logic will execute
       * The cdm_field is selected as remainder will get mapped automatically with split function with opposite side of current split
       * VICE VERSA
       */
      if (operation === 'SPLIT' && extra) {
        const tableRecords = compileRecords('', false, cMapper.files)
        const tableRow = tableRecords.find(({ name }) => name === extra)

        if (tableRow) {
          const { id, mandatory, name, data_type, report_type } = tableRow

          erpResp[id] = { ...Object.values(erpResp)[0] }
          const remainderMapping = {
            ...fileResp,
            cdm_field: name,
            data_type: data_type,
            mandatory: mandatory,
            operation: 'SPLIT',
            characters: '4',
            extra: cdm_field,
            split_side: split_side === 'Right' ? 'Left' : 'Right',
          }

          /**
           * Validate data before applying mapping to the another field
           */
          const { status, post_validate_data } =
            await getStatusAndValidationMsg(remainderMapping, report_type)

          remainderMapping.erp_fields = remainderMapping.erp_fields.map(
            (field) => ({
              ...field,
              valid: status,
            })
          )
          erpResp[id] = { ...erpResp[id], valid: status, post_validate_data }

          dispatch(updateSingleManualSelection(remainderMapping))
        }
      }

      setERPValue({ ...eRPValue, ...erpResp })
      dispatch(setCustomFileMappings({ ...eRPValue, ...erpResp }))
      dispatch(updateSingleManualSelection(fileResp))
    }
    setCalcDialog(false)
  }

  const pageOption = useMemo(() => {
    const result = [5, 10, 25, 50]
      .filter((item) => item < filteredRecords.length)
      .concat(
        filteredRecords.length > 0 && filteredRecords.length <= 50
          ? [filteredRecords.length]
          : []
      )
    return result
  }, [filteredRecords.length])

  useEffect(() => {
    if (Object.keys(customFileMappings).length === 0) setERPValue({})
  }, [customFileMappings])

  useEffect(() => {
    paginate()
  }, [filteredRecords, page, numPages])

  useEffect(() => {
    getFilteredRecords()
  }, [search, mandatory, files])

  useEffect(() => {
    setERPValue(customFileMappings)
  }, [customFileMappings])

  useEffect(() => {
    if (pageOption?.length && !pageOption.includes(numPages)) {
      setNumPages(pageOption[0])
    }
  }, [pageOption])

  const getRespectiveIcon = (value) => {
    return value ? (
      <div className={`${classes.iconWrapper} ${classes.errorIcon}`}>
        <IconError />
      </div>
    ) : (
      <div className={`${classes.iconWrapper} ${classes.reportIcon}`}>
        <ReportProblemOutlinedIcon />
      </div>
    )
  }

  const getDefaultValue = (fields) => {
    return fields.length > 1 ? { field_name: 'Calculated Fields' } : fields[0]
  }

  const isValid = (value, record) => {
    const { mandatory } = record

    const res =
      value?.consider_valid === false ||
      value?.valid ||
      (!mandatory &&
        ((typeof value === 'object' && Object.keys(value).length === 0) ||
          value === undefined))

    return res
  }

  const customTooltip = (value, record) => {
    const { mandatory } = record

    const res = isValid(value, record)

    return (
      <Tooltip
        title={
          value?.post_validate_data ||
          (!value?.null_check_valid && value?.null_check_comment) ||
          (!value?.type_check_valid && value?.type_check_comment) ||
          ''
        }
        arrow
        placement="top-end"
      >
        {res ? (
          <div className={`${classes.iconWrapper} ${classes.doneIcon}`}>
            <DoneIcon />
          </div>
        ) : (
          getRespectiveIcon(mandatory)
        )}
      </Tooltip>
    )
  }

  const getConfidenceScore = (score: number) => {
    let color
    if (score >= 90) {
      color = '#37AB3F'
    } else if (score >= 60 && score < 90) {
      color = '#F7931E'
    } else {
      color = '#DE4D4D'
    }

    if (score === undefined) return
    if (score !== 0) {
      return (
        <span
          style={{
            color,
          }}
        >
          {score}%
        </span>
      )
    }
  }

  const getError = (value, record) => {
    const res = isValid(value, record)
    return res
  }

  const allErrorArr = filteredRecords.map((record) =>
    getError(
      eRPValue[record?.id] ? eRPValue[record?.id] : record.erp_fields[0],
      record
    )
  )
  const allErrorCount = allErrorArr.filter((v) => !v)?.length

  let chunkArr = []
  while (allErrorArr.length > 0) {
    const chunk = allErrorArr.splice(0, numPages)
    chunkArr.push(chunk)
  }

  let ids = []
  chunkArr.map((v, i) => {
    if (v.includes(false)) {
      ids.push(i)
    }
    return ids
  })

  return (
    <>
      {allErrorCount ? (
        <Alert
          icon={<CancelIcon />}
          severity="error"
          style={{ justifyContent: 'center', marginBottom: 10 }}
        >
          <Typography
            className={classes.errorText}
            style={{ textAlign: 'center' }}
          >
            <strong>{allErrorCount} Errors found.</strong> Page Number:{' '}
            {ids.map((v, id) => {
              return (
                <span key={cuid()} className={classes.errCount}>
                  {v + 1}
                  {id === ids.length - 1 ? '' : ','}
                </span>
              )
            })}
          </Typography>
        </Alert>
      ) : (
        <Alert
          icon={<CheckCircleIcon />}
          severity="success"
          style={{
            justifyContent: 'center',
            marginBottom: 10,
            backgroundColor: '#EBFFF4',
          }}
        >
          <Typography className={classes.successText}>
            <strong>{allErrorCount} Error found.</strong> All mandatory fields
            are present.
          </Typography>
        </Alert>
      )}
      <TableContainer
        style={{ maxHeight: '460px', borderRadius: '4px 4px 0 0' }}
        component={Paper}
      >
        <CustomScrollbars autoHide autoHeight autoHeightMin={460}>
          <Table stickyHeader={true} aria-label="simple table">
            <TableHead className={classes.tableHeaders}>
              <TableRow>
                <TableCell>CDM Fields</TableCell>
                <TableCell>ERP Fields</TableCell>
                <TableCell>Data Type</TableCell>
                <TableCell>CDM Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRecords.map((record) => (
                <TableRow key={record.id}>
                  <TableCell
                    style={{
                      width: '267px',
                    }}
                  >
                    <span
                      style={{
                        font: `normal normal ${
                          record.mandatory && 'bold'
                        } 16px/18px Arial`,
                        color: '#22353F',
                      }}
                    >
                      {record.name}
                    </span>
                    {record.mandatory && (
                      <span className={classes.mandate}>
                        <sub>*</sub>
                      </span>
                    )}
                    {record.name.toLowerCase() === 'amount' && (
                      <Tooltip
                        title={
                          "WARNING: Amounts should be absolute. If you have tried to map something which is not an absolute number you will see an error in the validation field on the right-hand side, with details available in hover-over. Use the 'Amount Calculation' calculated field to map the correct information. This will map the amountDebitCreditIndicator automatically too."
                        }
                        arrow
                        interactive
                        placement="top-start"
                        classes={{
                          tooltip: classes.tooltip,
                          arrow: classes.tooltipArrow,
                        }}
                      >
                        <IconTagWarning
                          style={{
                            width: '100px',
                            height: '28px',
                            marginLeft: '25px',
                            verticalAlign: 'middle',
                          }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <EBAutoComplete
                      options={record.available_options}
                      size="small"
                      groupBy={(option) => option.groupByFileName}
                      renderGroup={(params) => (
                        <>
                          <h4
                            title={params.group}
                            className={classes.groupLabel}
                          >
                            {params.group}
                          </h4>
                          {params.children}
                        </>
                      )}
                      renderOption={(option) => (
                        <div
                          title={option.field_name}
                          className={classes.erpOptions}
                        >
                          <span>{option.field_name}</span>
                          {getConfidenceScore(option.confidence_score)}
                        </div>
                      )}
                      value={
                        eRPValue[record.id] ||
                        getDefaultValue(record.erp_fields) ||
                        null
                      }
                      onChange={onChangeHandler(record)}
                      getOptionLabel={(option) => option.field_name}
                      width={280}
                      classes={{ paper: classes.paper }}
                      placeholder="Choose ERP Field"
                      getOptionSelected={(option, value) =>
                        option?.field_name === value.field_name
                      }
                    />
                  </TableCell>
                  <TableCell>{record.data_type}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '643px',
                      }}
                    >
                      <span style={{ width: '90%' }}>{record.description}</span>
                      {eRPValue[record.id]
                        ? customTooltip(eRPValue[record.id], record)
                        : customTooltip(record.erp_fields[0], record)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CustomScrollbars>
      </TableContainer>
      <Paper style={{ borderRadius: '0 0 4px 4px' }}>
        <div className={classes.customPagination}>
          <div className="pager">
            <Typography>Showing</Typography>
            <Select
              variant="outlined"
              value={pageOption?.length ? numPages : 0}
              onChange={onPageChangeHandler}
            >
              {pageOption?.length ? (
                pageOption.map((option, i) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>0</MenuItem>
              )}
            </Select>
            <Typography>of {filteredRecords.length} Records</Typography>
          </div>
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={Math.ceil(filteredRecords.length / numPages) || 1}
            variant="text"
            shape="rounded"
            color="primary"
          />
        </div>
      </Paper>
      <CalculatedFieldDialog
        open={calcDialog}
        onClose={onCalcFieldClose}
        record={cdmField}
      />
    </>
  )
}

export default TableComponent
