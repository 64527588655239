import {
  Option,
  RECORD,
  getAnalyticsPipelines,
  getAuditedEntitiesByUser,
  getEngagements,
  resetDigitalAuditAnalytics,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { EBAutoComplete, Status } from '@engine-b/shared/components'
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from './assets/download.svg'
import { ReactComponent as EyeIcon } from './assets/eye.svg'
import { ReactComponent as SummaryIcon } from './assets/IconSummary.svg'
import { useIntl } from 'react-intl'
import { useApolloClient } from '@apollo/client'
import { format } from 'date-fns'
import { enGB, enUS } from 'date-fns/locale'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { STATUS_OPTIONS } from './constants'
import RefreshIcon from '@material-ui/icons/Refresh'
import { debounce } from 'lodash'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 120px)',
    padding: '40px',
    background: 'rgba(57, 91, 115, 0.05)',
    overflow: 'auto',
  },
  title: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '54px',
    color: '#222222',
  },
  headerSubtitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#222222',
    marginBottom: '17px',
  },
  filterPanel: {
    width: '100%',
    flexGrow: 1,
    margin: '1rem 0 2rem',
    gap: '1rem',
  },
  customInput: {
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
    },
  },
}))

export const DataAndAnalyticsView = () => {
  const { locale } = useIntl()
  const client = useApolloClient()
  const dispatch = useIEDispatch()
  const history = useHistory()
  const { root, title, headerSubtitle, filterPanel, customInput } = useStyles()

  const { auditedEntities, engagements } = useIESelector(
    (state) => state.engagement
  )
  const { analyticsPipelines, loader, totalPipelines } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )
  const azureClient = useContext(AzureClientContext)

  const [auditedEntity, setAuditedEntity] = useState<RECORD | null>(null)
  const [engagement, setEngagement] = useState<RECORD | null>(null)
  const [status, setStatus] = useState<Option | null>(null)
  const [pipelineName, setPipelineName] = useState('')
  const [recordsPerPage, setRecordsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)

  const getStatusColor = {
    COMPLETED: 'success',
    IN_PROGRESS: 'warning',
    INITIALIZED: 'secondary',
    FAILED: 'error',
    FAILED_TO_PROCESS: 'error',
  }

  /**
   * Download the zip of all output files generated after successful analytics pipeline run
   * @param pipeline
   */
  const downloadPipelineOutputZip = async (pipeline: any) => {
    try {
      const {
        id,
        engagement: {
          name: engagementName,
          auditedEntity: {
            name: clientName,
            auditFirm: { systemName },
          },
        },
      } = pipeline
      const containerClient = azureClient.getFileSystemClient(systemName)

      const iter = containerClient.listPaths({
        path: `/analytics/${clientName}/${engagementName}/pipelines/${id}/output/`,
      })

      const zip = new JSZip()
      for await (const pathItem of iter) {
        if (!pathItem.isDirectory && pathItem.name) {
          const fileClient = containerClient.getFileClient(pathItem.name)
          const downloadResponse = await fileClient.read()
          const downloadBlob = await downloadResponse.contentAsBlob
          const file_name = pathItem.name.split('/').pop()
          if (file_name && downloadBlob) zip.file(file_name, downloadBlob)
        }
      }
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      saveAs(zipBlob, `pipeline_output_${id}.zip`)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Download the log file generated after successful analytics pipeline run
   * @param pipeline
   */
  const downloadPipelineLogFile = async (pipeline: any) => {
    try {
      const {
        id,
        engagement: {
          name: engagementName,
          auditedEntity: {
            name: clientName,
            auditFirm: { systemName },
          },
        },
      } = pipeline
      const containerClient = azureClient.getFileSystemClient(systemName)
      const fileClient = containerClient.getFileClient(
        `analytics/${clientName}/${engagementName}/pipelines/${id}/logs/audit_trail.pdf`
      )
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      saveAs(downloadBlob, `audit_trail_${id}.pdf`)
    } catch (error) {
      console.log(error)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'auditedEntity',
      headerName: 'Client Name',
      filterable: false,
      hideable: false,
      width: 200,
    },
    {
      field: 'engagement',
      headerName: 'Engagement Name',
      filterable: false,
      hideable: false,
      width: 200,
      valueGetter: (params) => {
        if (params.value) {
          return params.value?.name
        }
      },
    },
    {
      field: 'name',
      headerName: 'Pipeline Name',
      filterable: false,
      hideable: false,
      width: 200,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      filterable: false,
      hideable: false,
      width: 220,
    },
    {
      field: 'createdAt',
      headerName: 'Initiated Date-Time',
      filterable: false,
      hideable: false,
      width: 200,
      renderCell: (params) => {
        if (params.value) {
          const date = new Date(+params.value)

          return format(date, 'P, h:mm:ss a', {
            locale: locale === 'en-US' ? enUS : enGB,
          })
        } else return null
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Status label={params.value} color={getStatusColor[params.value]} />
          )
        } else return ''
      },
      width: 180,
    },
    {
      field: 'id',
      headerName: 'Action',
      width: 220,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return ['COMPLETED', 'FAILED'].includes(params?.row?.status) ? (
          <>
            <Tooltip placement="bottom" title="Download Log File">
              <Button
                style={{
                  width: '2rem',
                  height: '2rem',
                  minWidth: 'unset',
                  padding: 0,
                  marginRight: '1rem',
                }}
                variant="contained"
                color="primary"
                onClick={() => downloadPipelineLogFile(params.row)}
              >
                <SummaryIcon />
              </Button>
            </Tooltip>
            {params?.row?.status === 'COMPLETED' && (
              <>
                <Tooltip placement="bottom" title="Download Output File">
                  <Button
                    style={{
                      width: '2rem',
                      height: '2rem',
                      minWidth: 'unset',
                      padding: 0,
                      marginRight: '1rem',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => downloadPipelineOutputZip(params.row)}
                  >
                    <DownloadIcon />
                  </Button>
                </Tooltip>
                <Button
                  style={{
                    minWidth: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  color="secondary"
                  onClick={() =>
                    history.push(
                      `/${locale}/data-and-analytics/${params.value}`
                    )
                  }
                >
                  <EyeIcon />
                  <span style={{ marginLeft: '.4rem' }}>Summary</span>
                </Button>
              </>
            )}
          </>
        ) : null
      },
    },
  ]

  const getPipelines = (page: number, results: number) => {
    dispatch(
      getAnalyticsPipelines({
        client,
        page,
        results,
        ...(auditedEntity && { auditedEntityId: auditedEntity.id }),
        ...(engagement && { engagementId: engagement.id }),
        ...(status && { status: status.value }),
        ...(pipelineName && { pipelineName }),
      })
    )
  }

  const onPaginationChange = (model: GridPaginationModel) => {
    setRecordsPerPage(model.pageSize)
    setCurrentPage(model.page + 1)
    getPipelines(model.page + 1, model.pageSize)
  }

  const debouncedSearch = debounce(() => {
    getPipelines(currentPage, recordsPerPage)
  }, 500)

  useEffect(() => {
    debouncedSearch()
    return () => {
      debouncedSearch.cancel()
    }
  }, [auditedEntity, engagement, status, pipelineName])

  useEffect(() => {
    dispatch(getEngagements({ client, id: auditedEntity?.id || '' }))
    setEngagement(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditedEntity?.id])

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))

    return () => {
      dispatch(resetDigitalAuditAnalytics({}))
    }
  }, [])

  return (
    <div className={root}>
      <Typography className={title}>Data & Analytics</Typography>
      <Typography className={headerSubtitle}>Status</Typography>
      <Card style={{ height: 'auto' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs style={{ textAlign: 'end' }}>
              <Button
                color="secondary"
                variant="contained"
                style={{ height: '48px' }}
                onClick={() =>
                  history.push('data-and-analytics/create-pipeline')
                }
              >
                + Create Analytics Pipeline
              </Button>
            </Grid>
          </Grid>
          <Grid container className={filterPanel}>
            <Grid item xs={12} sm={12} md={5} lg>
              <InputLabel style={{ marginBottom: '8px' }}>
                Client Name
              </InputLabel>
              <EBAutoComplete
                options={auditedEntities}
                getOptionLabel={(option) => option.name || ''}
                forcePopupIcon={true}
                height={48}
                size="small"
                value={auditedEntity}
                onChange={(e, value) => setAuditedEntity(value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg>
              <InputLabel style={{ marginBottom: '8px' }}>
                Engagement Name
              </InputLabel>
              <EBAutoComplete
                options={engagements}
                getOptionLabel={(option) => option.name || ''}
                forcePopupIcon={true}
                height={48}
                size="small"
                fullWidth
                value={engagement}
                onChange={(e, value) => setEngagement(value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg>
              <InputLabel style={{ marginBottom: '8px' }}>
                Pipeline Name
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                placeholder=""
                color="secondary"
                className={customInput}
                value={pipelineName}
                onChange={(e: any) => setPipelineName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg>
              <InputLabel style={{ marginBottom: '8px' }}>Status</InputLabel>
              <EBAutoComplete
                options={STATUS_OPTIONS}
                getOptionLabel={(option) => option.title || ''}
                value={status}
                onChange={(e, value) => setStatus(value)}
                forcePopupIcon={true}
                height={48}
                size="small"
              />
            </Grid>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <Button
                style={{ minWidth: 'unset', height: '48px' }}
                variant="outlined"
                color="primary"
                onClick={() => getPipelines(1, recordsPerPage)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <DataGrid
                autoHeight={true}
                rows={analyticsPipelines}
                columns={columns}
                rowCount={totalPipelines}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                paginationMode="server"
                loading={loader}
                disableRowSelectionOnClick={true}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                onPaginationModelChange={onPaginationChange}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default DataAndAnalyticsView
