import styles from './draggable-list.module.css'
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import React, { ReactNode } from 'react'

/* eslint-disable-next-line */
export interface DraggableListProps {
  onDragEnd: OnDragEndResponder
  droppableId: string
  children?: ReactNode
  content?: Array<any>
}

export function DraggableList(props: DraggableListProps) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId={props.droppableId}>
        { provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
