// import { UPLOAD_STATE } from '@engine-b/integration-engine/data/state/redux'
import {
  updateFileMapping,
  UPLOAD_STATE,
  useIEDispatch,
  useIESelector,
  deleteFileValidation,
  removeManualSelections,
  setCustomFileMappings,
  clearRemappingFile,
  setCurrentStep,
  resetFileNameByUser,
  updateEntity,
  updateEntityPreviewConfirm,
} from '@engine-b/integration-engine/data/state/redux'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Grid, IconButton, LinearProgress, Tooltip } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import icFile from './assets/ic_file.svg'
import { ReactComponent as IconDeleteDisabled } from './assets/ic_delete_disabled.svg'
import { ReactComponent as IconDeleteEnabled } from './assets/ic_delete_enabled.svg'
import icValide from './assets/ic_check_validate.svg'
import icNotValide from './assets/ic_cross_not_validate.svg'
import icWarning from './assets/ic_warning.svg'
import {
  AzureClientContext,
  deleteFileFromAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import ERPDialog from '../../../../../../apps/integration-engine/src/views/AddCustomERP/ERPDialog'

/* eslint-disable-next-line */
export interface UploadStateMessageProps {
  cdmFileName: string
  reportType: string
  size: number
  uploaded: number
  state: UPLOAD_STATE
  originalFileName: string
  fileNameByUser: string
  dfData: any
  isPreviewConfirmed: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    MessageContainer: {
      width: '90%',
      alignItems: 'center',
      backgroundColor: '#E8F4FD',
      padding: '13px 0',
      flexWrap: 'nowrap',
    },
    Alert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    IcAlert: {
      cursor: 'pointer',
      color: '#0288d1',
    },
    LoadingText: {
      paddingLeft: '12px',
    },
    stateContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '2px solid #D5DCE1',
      borderRadius: 10,
      height: '100%',
      width: '100%',
      padding: '28px 30px',
    },
    valideState: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0.14,
      color: '#37AB3F',
      fontSize: 11,
      padding: 0,
      margin: 0,
      '@media (max-width:1300px)': {
        fontSize: 9,
      },
    },
    invalideState: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0.14,
      color: '#DE4D4D',
      fontSize: 11,
      padding: 0,
      margin: 0,
      '@media (max-width:1300px)': {
        fontSize: 9,
      },
    },
    warningState: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0.14,
      color: '#F7931E',
      fontSize: 11,
      padding: 0,
      margin: 0,
      '@media (max-width:1300px)': {
        fontSize: 9,
      },
    },
    fileName: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0,
      color: '#22353F',
      fontSize: 14,
      padding: 0,
      margin: 0,
      '@media (max-width:1300px)': {
        fontSize: 10,
      },
    },
    documentIcon: {
      minWidth: 38,
      height: 44,
      backgroundImage: `url(${icFile})`,
      backgroundSize: 'contain',
      marginRight: 20,
      backgroundRepeat: 'no-repeat',
      '@media (max-width:1300px)': {
        width: 33,
        height: 37,
      },
    },
    deleteIcon: {
      textAlign: 'right',
      width: 24,
      height: 27,
      backgroundSize: 'cover',
      cursor: 'pointer',
      margin: '5px 0px 5px 0px',
    },
    messageWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '3%',
      justifyContent: 'space-between',
      width: '100%',
      overflowWrap: 'anywhere',
    },
    fileNameWrapper: {
      width: '90%',
    },
    warningIcon: {
      backgroundImage: `url(${icWarning})`,
      backgroundSize: 'cover',
      cursor: 'pointer',
      height: 12,
      width: 12,
      marginRight: 5,
    },
    validIcon: {
      backgroundImage: `url(${icValide})`,
      backgroundSize: 'cover',
      cursor: 'pointer',
      height: 12,
      width: 12,
      marginRight: 5,
    },
    notValidIcon: {
      backgroundImage: `url(${icNotValide})`,
      backgroundSize: 'cover',
      cursor: 'pointer',
      height: 12,
      width: 12,
      marginRight: 5,
    },
    stateWrapper: {
      display: 'flex',
      marginTop: 8,
    },
  })
)

export function UploadStateMessage({
  fileMappingData,
  selectedReportDetail,
  selectedCdmEntity,
  type,
  index,
}: {
  fileMappingData: UploadStateMessageProps
  selectedReportDetail: any
  selectedCdmEntity: any
  type?: string
  index?: number
}) {
  const SUPPORTED_FILE_EXTENSIONS = ['.csv', '.json']
  const SUPPORTED_FILE_EXTENSIONS_WITH_EXCEL = ['.csv', '.json', '.xls', '.xlsx']
  const classes = useStyles()

  const dispatch = useIEDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const azureClient = useContext(AzureClientContext)
  const fileMappingState = useIESelector((state) => state.fileMappings)
  const extractType = useIESelector((state) => state.runs.draftRun?.extractType)
  const customERP = useIESelector((state) => state.customERP)
  const { entities } = useIESelector((state) => state.customERP)
  const [records, setRecords] = useState([])
  const [header, setHeader] = useState('')
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )
  const fileMappingId = useIESelector((state) => state.runs.draftRun?.id)

  const closeDialog = (confirmed: boolean) => {
    if (confirmed) {
      dispatch(
        updateEntityPreviewConfirm({
          action: true,
          entityIndex: index,
          extractType: type,
        })
      )
      setIsOpen(false)
      if (Array.isArray(customERP?.extractData))
        dispatch(
          updateEntity({
            data: {
              customErpFieldsConfig: customERP?.extractData,
              customErpFieldsDefault: customERP?.extractData,
            },
            entityIndex: index,
            extractType: type,
          })
        )
      return true
    } else {
      setIsOpen(false)
      return false
    }
  }

  const deleteFileMapping = (
    fMapping: UploadStateMessageProps,
    selectedReportDetail: any
  ) => {
    if (fileMappingData?.dfData) {
      dispatch(
        updateEntity({
          data: {
            originalFileName: fMapping.originalFileName,
            fileNameByUser: '',
            header_row: '',
            size: 0,
            uploaded: 0,
            dfData: [],
            customErpFieldsConfig: [],
            customErpFieldsDefault: [],
            isPreviewConfirmed: false,
            suggested_file_name: '',
            encoding_format: '',
            global_datetime_format: '',
            fileUploadDisabled: true,
            state: UPLOAD_STATE.NOT_STARTED,
          },
          entityIndex: index,
          extractType: type,
        })
      )
    } else {
      dispatch(
        updateFileMapping({
          originalFileName: fMapping.originalFileName,
          reportType: fMapping.reportType,
          size: 0,
          uploaded: 0,
          header_row: '',
          state: UPLOAD_STATE.NOT_STARTED,
        })
      )
      dispatch(setCustomFileMappings({}))
      dispatch(clearRemappingFile())
      dispatch(setCurrentStep(2))
      dispatch(
        resetFileNameByUser({
          fileNameByUser: fMapping.originalFileName,
          reportType: fMapping.reportType,
        })
      )
      const inputFiles = selectedReportDetail.inputFiles
      const reportType = selectedReportDetail.systemName

      inputFiles.forEach((file: any) => {
        const fileMappObj = fileMappingState[`${reportType}_${file.name}`]
        const newInputPath = fileMappObj?.fileNameByUser || ''
        const uploaded: number = fileMappObj?.uploaded
        if (uploaded) {
          deleteFileFromAzureContainer({
            azureClient,
            fileSystemId: azureContainer?.fileSystemId || '',
            filePath: newInputPath,
          })
        }
      })
      dispatch(
        deleteFileValidation({
          extractType: extractType,
          reportType: selectedCdmEntity,
        })
      )
      dispatch(removeManualSelections(selectedCdmEntity))
    }
  }

  useEffect(() => {
    const inputFiles = selectedReportDetail.inputFiles
    const reportType = selectedReportDetail.systemName
    inputFiles.forEach((file: any) => {
      const fileMappObj = fileMappingState[`${reportType}_${file.name}`]
      const records = fileMappObj?.records || []
      if (fileMappingData?.dfData) {
        setRecords(fileMappingData.dfData)
        setHeader('Please confirm predicted ERP header row & field names:')
      } else {
        setRecords(records)
        setHeader(`Uploaded file for ${fileMappObj?.reportType}`)
      }
    })
  }, [fileMappingState, entities])

  const ConfirmCard = ({ icon, state, text }: any) => {
    const uploadedFileName = fileMappingData?.fileNameByUser
      ?.split(`_${fileMappingId}_`)
      ?.pop()
    return (
      <div className={classes.stateContainer}>
        <div className={classes.documentIcon} />
        <div className={classes.messageWrapper}>
          <div>
            <p className={classes.fileName}>
              {uploadedFileName
                ? uploadedFileName
                : fileMappingData.originalFileName}
            </p>
            <div className={classes.stateWrapper}>
              <Tooltip
                title={fileMappingData?.state}
                arrow
                placement="bottom-start"
              >
                <div className={icon} />
              </Tooltip>
              <p className={state}>{text}</p>
            </div>
          </div>
          <div>
            <Tooltip title="Preview uploaded file">
              <IconButton
                disabled={
                  fileMappingData.state === UPLOAD_STATE.NOT_STARTED ||
                  fileMappingData.state === UPLOAD_STATE.ERROR ||
                  records?.length === 0
                }
                onClick={() => setIsOpen(true)}
                color="secondary"
                component="span"
                data-testid="preview-file"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <IconButton
              disabled={fileMappingData.state === UPLOAD_STATE.NOT_STARTED}
              onClick={() =>
                deleteFileMapping(fileMappingData, selectedReportDetail)
              }
              component="span"
            >
              {fileMappingData.state === UPLOAD_STATE.NOT_STARTED ? (
                <IconDeleteDisabled />
              ) : (
                <IconDeleteEnabled />
              )}
            </IconButton>
            {isOpen && (
              <ERPDialog
                data={records}
                header={header}
                open={isOpen}
                onClose={closeDialog}
                isConfirmed={fileMappingData?.isPreviewConfirmed}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  const Message = {
    [UPLOAD_STATE.IN_PROGRESS]: (
      <Grid container direction="row" className={classes.MessageContainer}>
        <Grid xs={1} className={classes.Alert}>
          <Tooltip
            title={fileMappingData?.state}
            arrow
            placement="bottom-start"
          >
            <InfoOutlined className={classes.IcAlert} />
          </Tooltip>
        </Grid>
        <Grid xs={9}>
          <LinearProgress
            value={(fileMappingData?.uploaded / fileMappingData?.size) * 100}
            color={'secondary'}
            variant={'determinate'}
          />
        </Grid>
        <Grid xs={2} className={classes.LoadingText}>
          {Math.floor(
            (fileMappingData?.uploaded / fileMappingData?.size) * 100
          )}
          %
        </Grid>
      </Grid>
    ),
    [UPLOAD_STATE.COMPLETE]: (
      <ConfirmCard
        icon={classes.validIcon}
        state={classes.valideState}
        text={'Upload complete!'}
      />
    ),
    [UPLOAD_STATE.ERROR]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={`Unable to upload files, please try again!`}
      />
    ),
    [UPLOAD_STATE.UNSUPPORTED_FILE_TYPE]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={`Unsupported file format! Accepted formats are:{' '}${process.env['NX_ENABLE_UPLOAD_EXCEL_FILE'] === 'true' ? SUPPORTED_FILE_EXTENSIONS_WITH_EXCEL : SUPPORTED_FILE_EXTENSIONS.join(
          ', '
        )}.`}
      />
    ),
    [UPLOAD_STATE.NOT_STARTED]: (
      <ConfirmCard
        icon={classes.warningIcon}
        state={classes.warningState}
        text={'Please upload a file to proceed.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE]: (
      <ConfirmCard
        icon={classes.warningIcon}
        state={classes.warningState}
        text={'Validating the File.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE_COMPLETE]: (
      <ConfirmCard
        icon={classes.validIcon}
        state={classes.valideState}
        text={'File validated successfully.'}
      />
    ),
    [UPLOAD_STATE.VALIDATING_FILE_FAILED]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={'File Validation Terminated.'}
      />
    ),
    [UPLOAD_STATE.VALIDATE_FILE_SIZE]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={'File Validation Terminated.'}
      />
    ),
    [UPLOAD_STATE.VALIDATE_EXCEL_FILE_SIZE]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={'Excel file should not be larger than 100 mb'}
        />
    ),
    [UPLOAD_STATE.ERROR_EXCEL_FILE_CONVERSION]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={'Please Provide Valid Input File.'}
      />
    ),
    [UPLOAD_STATE.FILE_VALIDATION_FAILED]: (
      <ConfirmCard
        icon={classes.warningIcon}
        state={classes.warningState}
        text={
          'Minor issues with file, proceed to next page to use Custom Mapper.'
        }
      />
    ),
    [UPLOAD_STATE.EMPTY_FILE_VALIDATION]: (
      <ConfirmCard
        icon={classes.notValidIcon}
        state={classes.invalideState}
        text={'Uploaded file is empty.'}
      />
    ),
    [UPLOAD_STATE.PRE_UPLOADED_INPUT_FILE_NOT_FOUND]: (
      <ConfirmCard
        icon={classes.warningIcon}
        state={classes.warningState}
        text={UPLOAD_STATE.PRE_UPLOADED_INPUT_FILE_NOT_FOUND}
      />
    ),
  }

  return Message[fileMappingData?.state] || Message[UPLOAD_STATE.NOT_STARTED]
}

export default UploadStateMessage