import React, { ReactElement } from 'react'
import styles from './daa-layout.module.css'
import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@mui/material'

/* eslint-disable-next-line */
export interface DaaLayoutProps {
  children: ReactElement[]
  configPanel: boolean
}

const useStyles = makeStyles({
  daaLayout: {
    display: 'flex',
    gap: '16px',
    background: '#F5F7F8',
  },
  daaLeft: {
    width: '354px',
    height: '886px',
    background: '#ECEFF1',
    borderRadius: '12px',
    transition: 'width 0.5s ease-in-out',
    '&.closed': {
      width: '3rem',
    },
  },
  daaRight: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  daaRightTop: {
    width: '100%',
    height: '150px',
    background: '#fff',
    borderRadius: '12px',
  },
  daaRightContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    gap: '12px',
  },
  daaRightBottom: {
    width: '100%',
    height: '100%',
    background: '#fff',
    borderRadius: '12px',
  },
})
export function DaaLayout({ children, configPanel }: Readonly<DaaLayoutProps>) {
  const classes = useStyles()
  const [left, rightTop, rightBottom] = React.Children.toArray(children)

  return (
    <Grid item xs={12} className={classes.daaLayout}>
      <Grid className={` ${classes.daaLeft} ${configPanel ? ' closed' : ''}`}>
        {left}
      </Grid>
      <Grid className={classes.daaRight}>
        {/* <Grid className={classes.daaRightTop}>{rightTop}</Grid> */}
        <Grid className={classes.daaRightBottom}>
          <Box className={` ${classes.daaRightContent}`}>{rightBottom}</Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DaaLayout
