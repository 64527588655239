import { gql } from '@apollo/client'

export const ADD_CDM_ENTITIES_TO_DI_AND_TRIGGER_PIPELINE = gql`
  mutation addCdmEntitiesToDIAndTriggeripeline(
    $dataIngestionId: String!
    $extractType: ExtractType!
    $cdmEntitySystemNames: [String!]!
    $cdmEntityErpId: String!
    $uploadedFiles: [UploadedFileInput!]!
    $isCustomMapped: Boolean!
    $fileSystemId: String!
    $inputPath: String!
  ) {

    copyFileToDI(
      inputPath: $inputPath, 
      fileSystemId: $fileSystemId,
      uploadedFiles: $uploadedFiles,
      cdmEntitySystemNames: $cdmEntitySystemNames
    ) {
      status
      err
      message
    }

    updateDataIngestionCdmEntities(
      dataIngestionId: $dataIngestionId
      extractType: $extractType
      cdmEntitySystemNames: $cdmEntitySystemNames
      cdmEntityErpId: $cdmEntityErpId
    ) {
      id
      cdmEntities {
        cdmEntity {
          systemName
        }
      }
    }

    updateDataIngestionUploadedFiles(
      dataIngestionId: $dataIngestionId
      uploadedFiles: $uploadedFiles
    ) {
      id
    }

    beginDataIngestion(
      dataIngestionId: $dataIngestionId
      isCustomMapped: $isCustomMapped
    ) {
      id
      initiatedAt
      completeAt
      adfPipelineId
      status
    }
  }
`
