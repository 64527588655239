import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      width: '190px',
    },
  })
)

export function Logo() {
  const classes = useStyles()

  return (
    <div className={classes.logo}>
      <svg
        width="190"
        height="55"
        viewBox="0 0 190 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.07016 48.4405L10.7953 39.7121C11.2688 39.2382 11.2688 38.4701 10.7953 37.9965L2.07016 29.2678C1.3064 28.5037 0 29.045 0 30.1259V47.5829C0 48.6636 1.3064 49.2046 2.07016 48.4405Z"
          fill="#00A9AB"
        />
        <path
          d="M188.964 14.3096L179.093 4.43468C178.606 3.94772 178.333 3.28666 178.333 2.59784C178.333 2.57378 178.324 2.55 178.319 2.52621C178.28 1.12537 177.139 0 175.729 0H10.1207C8.68649 0 7.52393 1.16303 7.52393 2.59784V25.3043C7.52393 25.9934 7.79722 26.6542 8.28429 27.1412L18.1553 37.0161C19.1698 38.0305 19.1698 39.6757 18.1553 40.6901L8.28429 50.565C7.79722 51.052 7.52393 51.7128 7.52393 52.4019C7.52393 53.8367 8.68649 55 10.1207 55H175.729C177.139 55 178.28 53.8743 178.319 52.4738C178.324 52.4497 178.333 52.4259 178.333 52.4019V29.6957C178.333 29.0066 178.606 28.3458 179.093 27.8586L188.964 17.9839C189.979 16.9692 189.979 15.3243 188.964 14.3096Z"
          fill="#395B73"
        />
        <path
          d="M172.879 6.5561L181.604 15.2845C182.077 15.7584 182.077 16.5265 181.604 17.0001L172.879 25.7288C172.115 26.4929 170.809 25.9516 170.809 24.8707V7.41365C170.809 6.33301 172.115 5.7917 172.879 6.5561Z"
          fill="#EC6408"
        />
        <path
          d="M27.5996 18.2759C27.5996 17.9848 27.8331 17.7227 28.153 17.7227H40.2027C40.5226 17.7227 40.7561 17.9848 40.7561 18.2759V21.4496C40.7561 21.7406 40.5226 22.0028 40.2027 22.0028H32.1404V25.6133H38.7762C39.0675 25.6133 39.3296 25.8754 39.3296 26.1665V29.3402C39.3296 29.6607 39.0675 29.8934 38.7762 29.8934H32.1404V33.8241H40.2027C40.5226 33.8241 40.7561 34.0866 40.7561 34.3773V37.5513C40.7561 37.8421 40.5226 38.1042 40.2027 38.1042H28.153C27.8331 38.1042 27.5996 37.8421 27.5996 37.5513V18.2759Z"
          fill="white"
        />
        <path
          d="M44.8594 17.9449C44.8594 17.6539 45.1214 17.4209 45.4119 17.4209H45.849C46.1395 17.4209 46.3437 17.625 46.5764 17.8577L57.753 28.6019H57.7823V18.2654C57.7823 17.9744 58.0153 17.7122 58.3351 17.7122H61.8279C62.1192 17.7122 62.381 17.9744 62.381 18.2654V37.8611C62.381 38.2104 62.1192 38.3848 61.8279 38.3848H61.5365C61.246 38.3848 61.0416 38.2979 60.8089 38.0643L49.4868 26.6801H49.4583V37.5409C49.4583 37.8316 49.2253 38.0938 48.9049 38.0938H45.4412C45.1499 38.0938 44.8881 37.8316 44.8881 37.5409L44.8594 17.9449Z"
          fill="white"
        />
        <path
          d="M76.6237 17.4551C79.0684 17.4551 81.7458 18.4743 83.7257 20.1919C83.9869 20.3961 84.0162 20.7451 83.7835 20.9784L81.4844 23.4239C81.2511 23.6281 80.931 23.6281 80.6983 23.4534C79.5925 22.4339 78.3114 22.0554 76.8857 22.0554C73.7128 22.0554 71.1519 24.7342 71.1519 27.9373C71.1519 31.1107 73.7422 33.7312 76.9142 33.7312C77.7589 33.7312 78.6321 33.6149 79.5053 33.2658V31.5767H77.6712C77.3514 31.5767 77.1184 31.344 77.1184 31.0524V28.1703C77.1184 27.8498 77.3514 27.6171 77.6712 27.6171H83.5215C83.8129 27.6171 84.0455 27.879 84.0455 28.1703L84.0746 36.0026C84.0746 36.2645 83.9584 36.3811 83.8419 36.4683C83.8419 36.4683 80.8441 38.3612 76.6237 38.3612C70.8312 38.3612 66.1748 33.7607 66.1748 27.9373C66.1748 22.1137 70.8312 17.4551 76.6237 17.4551Z"
          fill="white"
        />
        <path
          d="M88.5444 18.2759C88.5444 17.9848 88.8064 17.7227 89.0978 17.7227H92.5905C92.8819 17.7227 93.1433 17.9848 93.1433 18.2759V37.5513C93.1433 37.8421 92.8819 38.1042 92.5905 38.1042H89.0978C88.8064 38.1042 88.5444 37.8421 88.5444 37.5513V18.2759Z"
          fill="white"
        />
        <path
          d="M98.1001 17.9449C98.1001 17.6539 98.3621 17.4209 98.6526 17.4209H99.0898C99.3803 17.4209 99.5845 17.625 99.8171 17.8577L110.994 28.6019H111.023V18.2654C111.023 17.9744 111.256 17.7122 111.576 17.7122H115.069C115.36 17.7122 115.622 17.9744 115.622 18.2654V37.8611C115.622 38.2104 115.36 38.3848 115.069 38.3848H114.777C114.487 38.3848 114.282 38.2979 114.05 38.0643L102.727 26.6801H102.699V37.5409C102.699 37.8316 102.466 38.0938 102.146 38.0938H98.6819C98.3906 38.0938 98.1289 37.8316 98.1289 37.5409L98.1001 17.9449Z"
          fill="white"
        />
        <path
          d="M120.644 18.2759C120.644 17.9848 120.877 17.7227 121.197 17.7227H133.247C133.567 17.7227 133.8 17.9848 133.8 18.2759V21.4496C133.8 21.7406 133.567 22.0028 133.247 22.0028H125.185V25.6133H131.82C132.111 25.6133 132.373 25.8754 132.373 26.1665V29.3402C132.373 29.6607 132.111 29.8934 131.82 29.8934H125.185V33.8241H133.247C133.567 33.8241 133.8 34.0866 133.8 34.3773V37.5513C133.8 37.8421 133.567 38.1042 133.247 38.1042H121.197C120.877 38.1042 120.644 37.8421 120.644 37.5513V18.2759Z"
          fill="white"
        />
        <path
          d="M152.106 33.9407C153.561 33.9407 154.376 33.2129 154.376 31.8443C154.376 30.6796 153.59 29.777 152.164 29.777H149.632V33.9407H152.106ZM152.047 25.9918C153.154 25.9918 153.939 25.0601 153.939 23.8956C153.939 22.7309 153.154 21.9156 152.047 21.9156H149.632V25.9918H152.047ZM155.366 27.7389C157.141 28.3213 159.208 29.6607 159.208 32.4844C159.208 35.9206 156.326 38.1042 152.862 38.1042H145.644C145.324 38.1042 145.091 37.842 145.091 37.5513V18.2759C145.091 17.9848 145.324 17.7227 145.644 17.7227H152.425C155.86 17.7227 158.684 20.081 158.684 23.1383C158.684 25.4675 157.024 27.0399 155.366 27.7094V27.7389Z"
          fill="white"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M82.846 47.5417L82.876 47.5117C82.8776 47.5101 82.8776 47.5075 82.876 47.5058L82.846 47.4758C82.8434 47.4732 82.8389 47.4751 82.8389 47.4788V47.5388C82.8389 47.5425 82.8434 47.5444 82.846 47.5417Z"
            fill="#00A9AB"
          />
          <path
            d="M83.4883 47.4242L83.4543 47.3902C83.4527 47.3886 83.4517 47.3863 83.4517 47.3839C83.4517 47.3838 83.4517 47.3838 83.4517 47.3837C83.4515 47.3789 83.4476 47.375 83.4428 47.375H82.8737C82.8687 47.375 82.8647 47.379 82.8647 47.3839V47.462C82.8647 47.4643 82.8657 47.4666 82.8674 47.4683L82.9013 47.5022C82.9048 47.5057 82.9048 47.5113 82.9013 47.5148L82.8674 47.5488C82.8657 47.5504 82.8647 47.5527 82.8647 47.5551C82.8647 47.56 82.8687 47.564 82.8737 47.564H83.4428C83.4476 47.564 83.4515 47.5601 83.4517 47.5553C83.4517 47.5552 83.4517 47.5552 83.4517 47.5551V47.477C83.4517 47.4747 83.4527 47.4724 83.4543 47.4707L83.4883 47.4368C83.4917 47.4333 83.4917 47.4277 83.4883 47.4242Z"
            fill="#395B73"
          />
          <path
            d="M83.4329 47.3977L83.4629 47.4277C83.4645 47.4293 83.4645 47.432 83.4629 47.4336L83.4329 47.4636C83.4303 47.4662 83.4258 47.4644 83.4258 47.4607V47.4007C83.4258 47.3969 83.4303 47.3951 83.4329 47.3977Z"
            fill="#EC6408"
          />
          <path
            d="M82.9336 47.4384C82.9336 47.4374 82.9344 47.4365 82.9355 47.4365H82.9769C82.978 47.4365 82.9788 47.4374 82.9788 47.4384V47.4493C82.9788 47.4503 82.978 47.4512 82.9769 47.4512H82.9492V47.4636H82.972C82.973 47.4636 82.9739 47.4645 82.9739 47.4655V47.4764C82.9739 47.4775 82.973 47.4783 82.972 47.4783H82.9492V47.4919H82.9769C82.978 47.4919 82.9788 47.4928 82.9788 47.4938V47.5047C82.9788 47.5057 82.978 47.5066 82.9769 47.5066H82.9355C82.9344 47.5066 82.9336 47.5057 82.9336 47.5047V47.4384Z"
            fill="white"
          />
          <path
            d="M82.9932 47.4373C82.9932 47.4363 82.9941 47.4355 82.9951 47.4355H82.9966C82.9976 47.4355 82.9983 47.4362 82.9991 47.437L83.0375 47.474H83.0376V47.4384C83.0376 47.4374 83.0384 47.4365 83.0395 47.4365H83.0515C83.0525 47.4365 83.0534 47.4374 83.0534 47.4384V47.5058C83.0534 47.507 83.0525 47.5076 83.0515 47.5076H83.0505C83.0495 47.5076 83.0488 47.5073 83.048 47.5065L83.0091 47.4674H83.009V47.5047C83.009 47.5057 83.0082 47.5066 83.0071 47.5066H82.9952C82.9942 47.5066 82.9933 47.5057 82.9933 47.5047L82.9932 47.4373Z"
            fill="white"
          />
          <path
            d="M83.1023 47.4355C83.1107 47.4355 83.1199 47.439 83.1267 47.445C83.1276 47.4457 83.1277 47.4469 83.1269 47.4477L83.119 47.4561C83.1182 47.4568 83.1171 47.4568 83.1163 47.4562C83.1125 47.4527 83.1081 47.4514 83.1032 47.4514C83.0923 47.4514 83.0835 47.4606 83.0835 47.4716C83.0835 47.4825 83.0924 47.4915 83.1033 47.4915C83.1062 47.4915 83.1092 47.4911 83.1122 47.4899V47.4841H83.1059C83.1048 47.4841 83.104 47.4833 83.104 47.4823V47.4724C83.104 47.4713 83.1048 47.4705 83.1059 47.4705H83.126C83.127 47.4705 83.1278 47.4714 83.1278 47.4724L83.1279 47.4993C83.1279 47.5002 83.1275 47.5006 83.1271 47.5009C83.1271 47.5009 83.1168 47.5074 83.1023 47.5074C83.0824 47.5074 83.0664 47.4916 83.0664 47.4716C83.0664 47.4516 83.0824 47.4355 83.1023 47.4355Z"
            fill="white"
          />
          <path
            d="M83.1431 47.4384C83.1431 47.4374 83.144 47.4365 83.145 47.4365H83.157C83.158 47.4365 83.1589 47.4374 83.1589 47.4384V47.5047C83.1589 47.5057 83.158 47.5066 83.157 47.5066H83.145C83.144 47.5066 83.1431 47.5057 83.1431 47.5047V47.4384Z"
            fill="white"
          />
          <path
            d="M83.1758 47.4373C83.1758 47.4363 83.1767 47.4355 83.1777 47.4355H83.1792C83.1802 47.4355 83.1809 47.4362 83.1817 47.437L83.2201 47.474H83.2202V47.4384C83.2202 47.4374 83.221 47.4365 83.2221 47.4365H83.2341C83.2351 47.4365 83.236 47.4374 83.236 47.4384V47.5058C83.236 47.507 83.2351 47.5076 83.2341 47.5076H83.2331C83.2321 47.5076 83.2314 47.5073 83.2306 47.5065L83.1917 47.4674H83.1916V47.5047C83.1916 47.5057 83.1908 47.5066 83.1897 47.5066H83.1778C83.1768 47.5066 83.1759 47.5057 83.1759 47.5047L83.1758 47.4373Z"
            fill="white"
          />
          <path
            d="M83.2534 47.4384C83.2534 47.4374 83.2542 47.4365 83.2553 47.4365H83.2967C83.2978 47.4365 83.2986 47.4374 83.2986 47.4384V47.4493C83.2986 47.4503 83.2978 47.4512 83.2967 47.4512H83.269V47.4636H83.2918C83.2928 47.4636 83.2937 47.4645 83.2937 47.4655V47.4764C83.2937 47.4775 83.2928 47.4783 83.2918 47.4783H83.269V47.4919H83.2967C83.2978 47.4919 83.2986 47.4928 83.2986 47.4938V47.5047C83.2986 47.5057 83.2978 47.5066 83.2967 47.5066H83.2553C83.2542 47.5066 83.2534 47.5057 83.2534 47.5047V47.4384Z"
            fill="white"
          />
          <path
            d="M83.3615 47.4923C83.3665 47.4923 83.3693 47.4898 83.3693 47.4851C83.3693 47.481 83.3666 47.4779 83.3617 47.4779H83.353V47.4923H83.3615ZM83.3613 47.4649C83.3651 47.4649 83.3678 47.4617 83.3678 47.4577C83.3678 47.4537 83.3651 47.4509 83.3613 47.4509H83.353V47.4649H83.3613ZM83.3727 47.4709C83.3788 47.4729 83.3859 47.4775 83.3859 47.4873C83.3859 47.4991 83.376 47.5066 83.3641 47.5066H83.3393C83.3382 47.5066 83.3374 47.5057 83.3374 47.5047V47.4384C83.3374 47.4374 83.3382 47.4365 83.3393 47.4365H83.3626C83.3744 47.4365 83.3841 47.4446 83.3841 47.4551C83.3841 47.4631 83.3784 47.4685 83.3727 47.4708V47.4709Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="0.652277"
              height="0.654764"
              fill="white"
              transform="translate(82.8384 47.1426)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Logo
