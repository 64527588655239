import { Card, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardStyles: {
        padding: '35px 25px',
        border: '1px solid #E4E4E4',
        borderRadius: '8px',
        flex: 1,
      },
  })
)

const ReportCard = ({ children }) => {
    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.cardStyles}>
            {children}
        </Card>
    )
};

export default ReportCard;