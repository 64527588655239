import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  useIESelector,
  useIEDispatch,
  toggleCustomMapperVisibility,
} from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles(() => ({
  CMButton: {
    height: '44px',
    '& span': {
      font: 'normal normal bold 16px/24px Arial',
    },
  },
}))

const CustomMapperButton = () => {
  const dispatch = useIEDispatch()

  const { CMButton } = useStyles()

  // Temporary fix : disabled Custom Mapper Button for QuickBooks
  const draftRun = useIESelector((state) => state.runs.draftRun)

  return (
    <Button
      variant="contained"
      color="primary"
      className={CMButton}
      onClick={() => dispatch(toggleCustomMapperVisibility(true))}
    >
      Custom Mapper
    </Button>
  )
}

export default CustomMapperButton
