import { makeStyles } from '@material-ui/core'
export const useStyle = makeStyles((theme) => ({
  root: {
    gap: '25px',
    marginBottom: '70px',
    '& .info': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '2px solid #44697D33',
      borderLeft: '6px solid #44697D',
      borderRadius: '10px',
      margin: '20px 0 30px',
      padding: '20px',
      '& .desc': {
        font: 'normal normal normal 14px/22px Roboto,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",-apple-system',
        letterSpacing: '0.14px',
        color: '#22353F',
        marginLeft: '12px',
        textAlign: 'left',
      },
    },
    '& .example': {
      display:'flex',
      justifyContent:'space-around',
      width: '860px',
      height: 'auto',
      minHeight: '93px',
      background: '#F0FBFA 0% 0% no-repeat padding-box',
      borderRadius: '8px',
      padding: '10px 10px',
      '& p:first-child': {
        font: 'normal normal bold 18px/20px Arial',
        letterSpacing: '0px',
        color: '#00B2A9',
        marginBottom: '13px',
      },
      '& .input-label': {
        fontWeight: 'bold',
        marginRight: '8px'
      },
      '& .child-div':{
        display: 'flex',
        alignItems: 'center'
      }
    },
    '& .column-number': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '52px',
    },
    '& .combo-box': {
      '&.d-row': { flexDirection: 'row', alignItems: 'center', gap: '25px' },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > label': {
        font: 'normal normal normal 14px/20px Arial',
        letterSpacing: '0.14px',
        color: '#44697D',
        verticalAlign: 'center',
        marginBottom: '8px',
        '& > span': {
          fontSize: '20px',
          color: '#DE4D4D',
        },
      },
      '& .MuiTextField-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'hsla(205, 34%, 34%, 1)',
        },
        width: '230px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        '& .MuiInputBase-root': {
          height: '44px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0.5px solid #D5DCE1',
          borderRadius: '8px',
        },
      },
    },
  },
}))
