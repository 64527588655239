import { gql } from '@apollo/client'

export const SAVE_CUSTOM_ERP = gql`
  mutation saveCustomErp($erp: SaveCustomErpInput!) {
    saveCustomErp(erp: $erp) {
      erpId
      auditFirm
      success
      message
    }
  }
`

export const ARCHIVE_CUSTOM_ERP = gql`
  mutation archiveCustomErp($erpId: String!) {
    archiveCustomErp(erpId: $erpId) {
      erpId
      success
    }
  }
`
