import { createSlice } from '@reduxjs/toolkit'
import {
  HELPER_INFO,
  HELPER_TYPE,
} from '@engine-b/integration-engine/data/helper-text'

export interface HelperState {
  isOpen: boolean
  type: HELPER_TYPE
  title: string
  body: string
}

const INITIAL_STATE: HelperState = {
  isOpen: false,
  type: null,
  title: '',
  body: '',
}

export const helperSlice = createHelperSlice(INITIAL_STATE)

const getHelperBody = (helperType: HELPER_TYPE, isClientEngagementCreateEnabled: boolean) => {
  if (helperType === HELPER_TYPE.CLIENT_ENGAGEMENT_CREATE && !isClientEngagementCreateEnabled) {
    return HELPER_INFO[helperType].body1
  }
  return HELPER_INFO[helperType].body
}

export function createHelperSlice(initialState: HelperState) {
  return createSlice({
    name: 'helper',
    initialState,
    reducers: {
      openHelper: (state, { payload }) => {
        const { helperType } = payload
        state.isOpen = true
        state.type = helperType
        state.title = HELPER_INFO[helperType].title
        state.body = getHelperBody(helperType, process.env.NX_ALLOW_CLIENT_ENGAGEMENT_CREATE === 'true')
      },
      closeHelper: (state) => {
        state.isOpen = INITIAL_STATE['isOpen']
        state.type = INITIAL_STATE['type']
        state.title = INITIAL_STATE['title']
        state.body = INITIAL_STATE['body']
      },
    },
  })
}

export const { openHelper, closeHelper } = helperSlice.actions

export const helperReducer = helperSlice.reducer
