import React from 'react'
import { makeStyles, Grid, Button } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { ReactComponent as IconDelete } from './assets/ic_delete.svg'
import icFile from './assets/ic_file.svg'
import { Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface UploadFileTableProps {
  name: string
  tableName: string
  onDeleteTable: (e: any) => void
}

const useStyles = makeStyles((theme) => ({
  uploadFileTable: {
    fontFamily: theme.typography.fontFamily,
    height: 'auto',
    marginBottom: 16,
    alignItems: 'center',
  },
  uploadFileTableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  uploadFileTableName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    marginTop: '12px',
    fontSize: '1rem',
  },
  deleteTableButton: {
    backgroundColor: red[100],
    color: theme.palette.error.main,
    marginTop: '12px',
    height: '36px',
    marginLeft: 'auto',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: red[100],
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      marginTop: 12,
    },
  },
  deleteTableButtonIcon: {
    width: '40px',
  },
  uploadFileTableFiles: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
    borderRadius: 8,
    padding: '0 0 0 12px',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  uploadTableItem: {
    border: '2px dashed #CCD8DE',
    borderRadius: 10,
    padding: 12,
    color: '#00abad',
    textAlign: 'center',
  },
  uploadFileTableItem: {
    paddingRight: 12,
    marginBottom: 12,
    marginTop: 12,
    minWidth: '20%',
    width: 'fit-content',
  },
  fileContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    display: 'flex',
    padding: 12,
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'space-between',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  itemInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  itemFileIcon: {
    display: 'inline-block',
    backgroundImage: `url(${icFile})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: '35px',
    width: '28px',
    marginRight: 10,
  },
  itemInfoContent: {
    maxWidth: '75%',
  },
  itemName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
}))

const UploadFileTable = (props: UploadFileTableProps) => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes['uploadFileTable']}>
        <Grid
          item
          xs={12}
          lg={8}
          xl={10}
          className={classes['uploadFileTableHeader']}
        >
          <span className={classes['uploadFileTableName']}>
            {props.tableName} Table
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          xl={2}
          className={classes['uploadFileTableHeader']}
        >
          <Button
            variant="contained"
            className={classes['deleteTableButton']}
            onClick={(e) => props.onDeleteTable(e)}
          >
            <IconDelete className={classes['deleteTableButtonIcon']} />
            Delete Table
          </Button>
        </Grid>
      </Grid>

      <Grid container className={classes['uploadFileTableFiles']}>
        <Grid item xs={12} className={classes['uploadFileTableItem']}>
          <>
            <Grid item xs={12} md={12} className={classes['uploadTableItem']}>
              <Typography>
                Table Generate from Upload File {props.name}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes['uploadFileTableItem']}>
              <Grid container className={classes['fileContainer']}>
                <Grid item xs={10} className={classes['itemInfo']}>
                  <div className={classes['itemIcon']}>
                    <span className={classes['itemFileIcon']}></span>
                  </div>
                  <div className={classes['itemInfoContent']}>
                    <span className={classes['itemName']}>
                      {props.tableName}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    </>
  )
}

export default UploadFileTable
