export enum FAQ_TYPE {
  ABOUT_ENGINE_B = 'ABOUT_ENGINE_B',
  OUR_PRODUCTS = 'OUR_PRODUCTS',
  ERPS = 'ERPS',
  DATA_TYPE_AND_STANDARDS = 'DATA_TYPE_AND_STANDARDS',
  USABILITY = 'USABILITY',
  SECURITY_RISK_AND_COMPLIANCE = 'SECURITY_RISK_AND_COMPLIANCE',
  PRODUCT_ROADMAP = 'PRODUCT_ROADMAP',
}

export const FAQ_INFO = {
  [FAQ_TYPE.ABOUT_ENGINE_B]: {
    title: 'ABOUT ENGINE B',
    faqs: [
      {
        question: 'WHO IS ENGINE B?',
        answer:
          'Backed by industry, Engine B is a digital technology company that combats a major problem for Professional Services firms everywhere - access to quality data. Working in tandem with firms, regulators, and fellow industry changemakers, our collaborative approach  to  the  development  of  our  technology  aims  to  increase  openness  in  the marketplace and create ground-breaking intellectual property for the sector. Our data ingestion and Knowledge Graph-based tools help organisations to ingest, standardise and analyse client data to inform their decision-making process, leading to better value add for the end client and elevating the quality of services industry-wide.',
      },
      {
        question: 'WHEN WAS ENGINE B FOUNDED?',
        answer: 'Engine B was founded in 2019 by Donne Burrows and Shamus Rae.',
      },
      {
        question: 'WHERE ARE YOU LOCATED?',
        answer:
          'We are a UK based company with staff also located in India and Bulgaria.',
      },
      {
        question: 'WHAT SECTOR DO YOU SERVICE?',
        answer:
          'We serve the Professional Services (Audit, Legal and Tax) sector and are focused on tackling the audit use case first.',
      },
    ],
  },
  [FAQ_TYPE.OUR_PRODUCTS]: {
    title: 'OUR PRODUCTS',
    faqs: [
      {
        question: 'WHAT ARE YOUR CORE PRODUCTS?',
        answer:
          'Engine B’s core products include the data ingestion and standardisation tool, <b>EB Integration Engine</b>, and our forthcoming knowledge graph-based tool, <b>EB Graph Insights Engine</b>. Underpinning both products is the open-source Audit Common Data Model, developed with Microsoft, thirteen of the UK’s leading audit firms, academic institutions, regulatory bodies and the Institute of Chartered Accountants for England and Wales (ICAEW).',
      },
      {
        question: 'WHAT IS THE EB INTEGRATION ENGINE?',
        answer:
          'Our core data product (released end of February 2022) is the <b>EB Integration Engine</b> – a tool that supports the auditor in improving the quality of an audit and reduces the time it takes to understand client data. The Integration Engine allows audit firms to ingest, transform and standardise data from a wide range of client systems into an easy-to-use standard format - the Audit Common Data Model. Users can quickly and securely convert files by connecting to their client’s system in three different ways: <br /> <ul> <li> Through standard reports run by the client finance team. </li> <li>Via a download from the client’s system back-end.</li> <li>By a direct connection to their system through an API.</li> </ul> <br />The Integration Engine has an easy-to-use engagement management dashboard so that you can access your prepared data for each client and engagement, whenever needed.',
      },
      {
        question: 'WHAT IS THE EBGRAPH INSIGHTS ENGINE?',
        answer:
          'The Graph Insights Engine uses knowledge-graph technology to link all the data from your audit together, automatically providing reconciliation and evidence confirmation. The graph enables detailed risk assessment as well as automated substantive and controls audit through fast, simple queries. Rather than simply presenting data, the Graph Insights Engine provides the context behind the evidence, reducing the number of false positive findings, and enabling auditors to drill down on potential risks and examine exceptions. Like all Engine B technology, the Graph Insights Engine is designed to plug-and-play with technology like Microsoft’s PowerBI and with any other analytics tools set up to use the Audit Common Data Model format provided by the Integration Engine.',
      },
      {
        question: 'WHAT IS THE AUDIT COMMON DATA MODEL (CDM)?',
        answer:
          'Engine B’s Audit Common Data Model (CDM) has been developed by auditors, for auditors. Put simply, it is a complex mapping table that supports the conversion of financial data to a common set of fields. Converting your client’s data to the CDM format with the Integration Engine means that you see data in the same format time after time, increasing efficiency and giving you the ability to upload audit data to analytics platforms in the same way, regardless of source finance system.',
      },
      {
        question: 'WHY SHOULD I USE ENGINE B PRODUCTS?',
        answer:
          '<ol> <li><b>Backed by industry</b> - Engine B is backed by the accounting industry and works together with regulators, firms and industry changemakers to collaboratively develop ground-breaking intellectual property for the sector. The accounting membership body, the <b>ICAEW</b>, is our largest shareholder and ensures we represent the entire industry.</li> <li><b>Save valuable time and money</b> - The audit process is expensive due to a reliance on manual data wrangling to tidy and reconcile evidence. Our products are focused on reducing the burdens associated with data volume and complexity for auditors, saving both you and your client significant time and money.</li> <li><b>Improve audit quality</b> - Our Integration Engine improves audit quality by providing consistent standardised data, regardless of source system. We take as much data as possible and transform it to give the auditor all the information they need.</li><li><b>Purpose first</b> - Engine B exists to serve and support the industry and will not compromise for the sake of sales. We are working with existing audit analytics providers to integrate our data extraction and standardisation tools with their analytical tools, giving the market as much choice as possible.</li></ol>',
      },
    ],
  },
  [FAQ_TYPE.ERPS]: {
    title: 'ERPs',
    faqs: [
      {
        question: 'HOW ARE YOU BUILDING YOUR ERP MAPPING TOOLS?',
        answer:
          'We use the extract from the ERPs and map it to the CDM which is then integrated into the Integration Engine for processing. In future, we will provide clients with the ability to add ERP mappings via our Custom Mapper tool.',
      },
      {
        question:
          'WE ARE A MID-TIER AUDIT FIRM - WILL YOU SUPPORT SMALLER ERPS?',
        answer:
          'UseYes – the whole point of Engine B is to make the marketplace more competitive and to create assets usable by all firms, regardless of size. We are building data mapping tools for as many ERP systems as possible. We will also be launching a Custom Mapper tool later in 2022 which enables you to support the upload yourself.',
      },
      {
        question: 'WHICH ERPS ARE A PRIORITY FOR YOU?',
        answer:
          'Initially our focus is Xero, Sage 50, Sage 200, Sage Intacct, Microsoft Dynamics 365, QuickBooks, Microsoft Navision, Oracle E-Business Suite for different regions (USA, UK & Global), utilising different connector types.',
      },
    ],
  },
  [FAQ_TYPE.DATA_TYPE_AND_STANDARDS]: {
    title: 'DATA TYPES AND STANDARDS',
    faqs: [
      {
        question:
          'WHAT TYPES OF DATA FROM FINANCIAL SYSTEMS ARE YOU ADDING TO YOUR COMMON DATA MODEL?',
        answer:
          'All – not just the General Ledger but also Accounts Payable and Receivable, Fixed Assets, Operational Data, Stock and other forms of data. If you use it in your audit our ambition is to ingest it.',
      },
      {
        question:
          'THE AICPA AND XBRLUS ARE WORKING ON SOME OPEN STANDARDS FOR ACCESSING THESE DATA MAPPING TOOLS - ARE YOU INVOLVED?',
        answer:
          'Yes – we are working with them to help create open standards for rest APIs and graphQLs. We are passionate about open sourcing our IP wherever possible, and we have done this with our Audit Common Data Model.',
      },
    ],
  },
  [FAQ_TYPE.USABILITY]: {
    title: 'USABILITY',
    faqs: [
      {
        question:
          'IF WE USE YOUR TOOLS DOES THAT REQUIRE US TO USE YOUR CLOUD?',
        answer:
          'No, we believe the data belongs to the audit entity or tax client and is shared with you and not us. We will therefore take the client’s data, convert into the common data model, and then provide you with the data in a standardised format. We never use client data for our own gain, so we don’t anonymise data and re-use, nor do we hold the data on Engine B systems beyond the agreed timescale with our client (standard is 14 days and delete).',
      },
      {
        question:
          'OUR AUDITORS NEED TO UNDERSTAND WHETHER THIS DATA IS FROM THE CLIENT’S CORE SYSTEMS.HOW WILL YOU HELP AUDITORS UNDERSTAND THIS?',
        answer:
          'Engine B delivers the data to you with lineage attached. This provides (when required) a clear view of where the data came from and what happened to it as it passed through Engine B.',
      },
      {
        question: 'ARE YOU CLOUD BASED?',
        answer:
          'Yes, our key cloud design principles include:<br /> <ul><li><b>Hybrid cloud workspaces (multi-tenant across clouds)</b> – Cloud and on-premises systems and applications are integrated seamlessly to work as a cohesive unit.</li><li><b>Infrastructure as code</b> – The process of managing and provisioning infrastructure through definition files, rather than physical hardware configuration or interactive configuration tools. This gives the ability to treat infrastructure elements like code, which increases speed of deployment and reduces risk.</li><li><b>Open platforms and standards</b> – Engine B use systems that are based on fully documented and open standards.</li><li><b>Scale out infrastructure</b> – Infrastructure that can be replicated to work in parallel to create increased capacity.</li></ul>',
      },
    ],
  },
  [FAQ_TYPE.SECURITY_RISK_AND_COMPLIANCE]: {
    title: 'SECURITY RISK AND COMPLIANCE',
    faqs: [
      {
        question: 'HOW ARE YOU MANAGING SECURITY, RISK AND COMPLIANCE?',
        answer:
          'Our key security, risk and compliance principles include:<br /><ul><li><b>Contextual risk and security management</b> – Contextual risk covers events and factors occurring in the environment that are beyond the control of Engine B. Our planning and mitigation strategies must take these aspects into consideration.</li><li><b>Model driven security</b> – Integrating security into the information systems design during the design phase of the software development process to ensure security is enshrined in the platform from an early stage.</li></ul>We are currently undergoing ISO27001 accreditation.',
      },
    ],
  },
  [FAQ_TYPE.PRODUCT_ROADMAP]: {
    title: 'PRODUCT ROADMAP',
    faqs: [
      {
        question: 'HOW CAN I FIND OUT MORE ABOUT YOUR PRODUCT ROADMAP?',
        answer:
          'We have a 12-month product roadmap to help our clients understand Engine B’s development plans for 2022, including the upcoming custom mapper functionality for the Integration Engine, ERP priorities, and knowledge graph tool, EB Insights Engine. To find out more, please request our product roadmap document by contacting one of our sales team.<br /><br /> <b>Sales team:</b><br /> Andy Newnham - <a href="mailto:andy@engineb.com" style="color:#ffffff;">andy@engineb.com</a><br /> Jaume Mora Pedros -  <a href="mailto:jaume@engineb.com" style="color:#ffffff;">jaume@engineb.com</a>',
      },
    ],
  },
}
