import React, { useCallback, useEffect } from 'react'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Backdrop from '@material-ui/core/Backdrop'
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { StepperContent } from './components/StepperContent'
import { EBConnector } from './components/EBConnector'
import { EBStepIcon } from './components/EBStepIcon'
import {
  initializeDraftRun,
  useIEDispatch,
  useIESelector,
  closeHelper,
  clearValidation,
  clearAllFileMappings,
  clearManualSelections,
  clearRemappingFile,
  setCustomFileMappings,
  toggleCustomMapperVisibility,
  setCurrentStep,
  setUserLeaveDialog,
  resetEngagementState,
  clearErps,
  resetUniqueIds,
  clearRun,
} from '@engine-b/integration-engine/data/state/redux'

import useSoftReload from '../../hooks/useSoftReload'

import MapperView from '../MapperView/MapperView'
import { Prompt } from 'react-router-dom'
import CustomScrollbars from '../../components/CustomScrollbars'
import axios from 'axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.8em',
        height: '0.8em',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '*::-webkit-scrollbar-thumb': {
        cursor: 'pointer',
        borderRadius: '3px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        transform: 'translateX(0px)',
      },
    },
    StepperRow: {
      '& .MuiStepLabel-labelContainer': {
        maxWidth: '180px',
      },
      backgroundColor: theme.background.secondary.main,
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff',
    },
    title: {
      textAlign: 'left',
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
      fontWeight: 'bold',
      padding: '31px 0 0 28px',
      background: '#fff',
    },
    linearProgress: {
      width: '20%',
    },
    line: {
      height: '12px',
      borderRadius: '12px',
      border: '1px solid rgba(255, 255, 255, 0.5)',
    },
  })
)

const STEPS = [
  'Define audited entity and data source',
  'Upload data',
  'Preview',
  'Convert to CDM',
]

export function AddDataView() {
  const classes = useStyles()
  const testingStep = useIESelector((state) => state.runs.testing.step)
  const testingMode = useIESelector((state) => state.runs.testing.testMode)
  // In order to test AddDataView we need to be allowed to mock the right 'page' to test
  // if testingMode is turned to false, the activeStep is initially set to 0.
  const [activeStep, setActiveStep] = React.useState(testingStep || 0)
  let source = axios.CancelToken.source()
  const customMapper = useIESelector((state) => state.customMapper)
  const [_, setCompleted] = React.useState<{ [k: number]: boolean }>({})
  const dispatch = useIEDispatch()
  const clearReduxState = () => {
    dispatch(closeHelper())
    dispatch(initializeDraftRun())
    dispatch(clearValidation({}))
    dispatch(clearAllFileMappings())
    dispatch(setCustomFileMappings({}))
    dispatch(clearManualSelections())
    dispatch(clearRemappingFile())
    dispatch(toggleCustomMapperVisibility(false))
    dispatch(setCurrentStep(0))
    dispatch(setUserLeaveDialog(false))
    dispatch(resetEngagementState())
    dispatch(clearErps())
    dispatch(resetUniqueIds())
    dispatch(clearRun(null))
  }

  useEffect(() => {
    // These functions will reset the AddDataView regardless of stored data.
    // This prevents us from running UI tests on these views unless we set
    // testingMode to 'true' and prevent the reset to occur at the start of
    // the render
    if (!testingMode) {
      dispatch(initializeDraftRun())
      dispatch(clearValidation({}))
      return () => {
        clearReduxState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSoftReload(() => {
    setActiveStep(0)
    setCompleted({})
    clearReduxState()
  })

  const handleNext = (currentStepIndex) => {
    setCompleted((completed) => ({ ...completed, [currentStepIndex]: true }))
    const isLastStep = currentStepIndex === STEPS.length - 1
    setActiveStep(isLastStep ? currentStepIndex : currentStepIndex + 1)
    dispatch(
      setCurrentStep(isLastStep ? currentStepIndex : currentStepIndex + 1)
    )
  }

  const handleBack = useCallback(() => {
    setCompleted((completed) => {
      const update = Object.assign({}, completed)
      delete update[activeStep]
      return update
    })
    setActiveStep((current) => (current > 0 ? current - 1 : 0))
  }, [activeStep])

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
    dispatch(setCustomFileMappings({}))
    dispatch(clearManualSelections())
    dispatch(clearRemappingFile())
  }

  useEffect(() => {
    dispatch(closeHelper())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (source) {
        source.cancel()
      }
    }
  }, [activeStep])

  return (
    <>
      <Prompt
        when={
          customMapper.currentStep > 0 &&
          customMapper.currentStep < 5 &&
          !customMapper.userLeaveDialog
        }
        message="By leaving the page you will lose the mapping and also abandon the file conversion to the CDM."
      />
      {customMapper.customMapperVisible ? (
        <CustomScrollbars style={{ height: 'calc(100vh - 80px)' }}>
          <MapperView />
        </CustomScrollbars>
      ) : (
        <div className={classes.StepperRow}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<EBConnector />}
          >
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={EBStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <StepperContent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
            source={source}
          />
        </div>
      )}
      <Backdrop className={classes.backdrop} open={customMapper.fetching}>
        <div className={classes.linearProgress}>
          <LinearProgressWithLabel
            className={classes.line}
            value={activeStep === 2 ? 'Validating...' : 'Loading...'}
          />
        </div>
      </Backdrop>
    </>
  )
}
