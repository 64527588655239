import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as IconDocumentStatus } from '../../assets/IconDocumentStatus.svg'
import { ReactComponent as IconSupport } from '../../assets/IconSupport.svg'
import { ReactComponent as IconAddNote } from '../../assets/IconAddNote.svg'
import { ReactComponent as IconAddFolder } from '../../assets/IconAddFolder.svg'
import { ReactComponent as IconTableJoins } from '../../assets/IconTableJoins.svg'
import { ReactComponent as IconExtractions } from '../../assets/IconExtractions.svg'
import { ReactComponent as IconAnalytics } from '../../assets/IconAnalytics.svg'
import { AddDataIcon } from './AddDataIcon'
import { ReactComponent as DataAnalyticsIcon } from '../../assets/DataAndAnalytics.svg'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import {
  clearAllFileMappings,
  useIEDispatch,
  useIESelector,
  setCurrentStep,
  setUserLeaveDialog,
  basicDetailsSelector,
  resetGrouping,
  resetJoins,
  resetCustomErpState,
  clearRun,
  resetExtractionState,
} from '@engine-b/integration-engine/data/state/redux'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import DialogComponent from './UserLeaveDialog'
import {
  ADD_DATA_FLOW_TITLE,
  ADD_DATA_FLOW_SUBTITLE,
  CUSTOM_GROUPING_TITLE,
  TABLE_JOINS_TITLE,
  CUSTOM_ERP_TITLE,
  EXTRACTIONS_TITLE,
  DAA_TITLE,
  DNA_TITLE,
} from '../constants'

const useStyles = makeStyles((theme) =>
  createStyles({
    navContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    navTitle: {
      '& span': {
        color: '#fff',
        fontSize: '18px',
      },
    },
    navIcon: {
      width: '32px',
      height: '32px',
    },
    selected: {
      background: '#00B2A9 0% 0% no-repeat padding-box !important',
      borderLeft: '4px solid ' + theme.palette.secondary.light,
      '& span': {
        fontWeight: 'bold',
        fontSize: '18px',
      },
    },
  })
)

export function SideBarNav() {
  const { formatMessage, locale } = useIntl()
  const history = useHistory()
  const location = useLocation()

  const [featureMenu, setFeatureMenu] = useState('')
  const [tempMenu, setTempMenu] = useState('')
  const [currentLocation, setCurrentLocation] = useState('')
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState(null)
  const [subTitle, setSubTitle] = useState(null)
  const currentStep = useIESelector((state) => state.customMapper.currentStep)
  const { groupingDetails } = useIESelector((state) => state.customGrouper)
  const { auditedEntity: selectedClientDetails } = useIESelector((state) =>
    basicDetailsSelector(state)
  )
  const extractionSelection = useIESelector((state) => state.extractions)
  const customERPEntities = useIESelector((state) => state.customERP.entities)
  const functions = useIESelector((state) => state.auditAnalytics.functions)
  const daaUserLeaveDialog = functions.some(
    (fn) =>
      fn?.value?.id ||
      fn?.filters[0]?.filterColumn?.id ||
      fn?.filters[0]?.filterCondition?.id ||
      fn?.filters[0]?.input
  )
  const {
    name: dnaName,
    clientDetails: dnaClientDetails,
    nodes: dnaNodes,
    analyticsPipeline,
  } = useIESelector((state) => state.digitalAuditAnalytics)
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const handleScheduleRunReset = () => {
    dispatch(clearAllFileMappings())
  }

  const navigateRoute = (menu) => {
    if (menu === AppRoute.Status) handleScheduleRunReset()
    // If the user clicked on menu item having same current path
    if (currentLocation === menu) {
      //run useEffect for softReload=true in respective component.
      history.push(localizeRouteKey(formatMessage, locale, menu), {
        softReload: true,
      })
      return
    }
    history.push(localizeRouteKey(formatMessage, locale, menu))
  }

  const onDialogClose = (value) => {
    if (value) {
      dispatch(setCurrentStep(0))
      dispatch(resetCustomErpState())
      dispatch(resetGrouping())
      dispatch(resetJoins(1))
      dispatch(resetExtractionState(1))
      navigateRoute(tempMenu)
    }
    setOpen(false)
    dispatch(setUserLeaveDialog(false))
  }

  const onClickHandler = (menu) => {
    setTempMenu(menu)
    if (
      menu === AppRoute.AddData &&
      currentLocation === AppRoute.AddData &&
      currentStep === 0
    ) {
      null
    } else if (currentStep > 0 && currentStep < 5) {
      setTitle(ADD_DATA_FLOW_TITLE)
      setSubTitle(ADD_DATA_FLOW_SUBTITLE)
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (groupingDetails !== null) {
      setTitle(CUSTOM_GROUPING_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (selectedClientDetails.id !== null) {
      setTitle(TABLE_JOINS_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (
      customERPEntities.report.length > 0 ||
      customERPEntities.system.length > 0
    ) {
      setTitle(CUSTOM_ERP_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (
      extractionSelection.erpCreate.name !== '' ||
      extractionSelection.erpCreate.type !== '' ||
      extractionSelection.extractionCreate.name !== '' ||
      extractionSelection.extractionCreate.connection !== ''
    ) {
      setTitle(EXTRACTIONS_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (daaUserLeaveDialog) {
      setTitle(DAA_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else if (
      analyticsPipeline === null &&
      (dnaName.length > 0 || dnaClientDetails || dnaNodes.length > 0)
    ) {
      setTitle(DNA_TITLE)
      setSubTitle('')
      dispatch(setUserLeaveDialog(true))
      setOpen(true)
    } else {
      dispatch(clearRun(null))
      navigateRoute(menu)
    }
  }

  useEffect(() => {
    const loc = location.pathname.split('/')
    setCurrentLocation(`routes.${loc[loc.length - 1]}`)
    setFeatureMenu(`routes.${loc[2]}`)
  }, [location])

  return (
    <div className={classes.navContainer}>
      <div>
        <h2 style={{ fontSize: '20px' }}>EB Integration Engine &trade;</h2>
        <List component="nav">
          <ListItem
            button
            selected={currentLocation === AppRoute.Status}
            onClick={() => onClickHandler(AppRoute.Status)}
            classes={{ selected: classes.selected }}
            data-testid="status"
          >
            <ListItemIcon>
              <IconDocumentStatus className={classes.navIcon} />
            </ListItemIcon>
            <ListItemText className={classes.navTitle} primary="Ingestions" />
          </ListItem>
          <ListItem
            button
            selected={currentLocation === AppRoute.AddData}
            onClick={() => onClickHandler(AppRoute.AddData)}
            classes={{ selected: classes.selected }}
            data-testid="add-data"
          >
            <ListItemIcon>
              <AddDataIcon />
            </ListItemIcon>
            <ListItemText className={classes.navTitle} primary="Add Data" />
          </ListItem>
          {process.env.NX_ENABLE_CUSTOM_MAPS === 'true' && (
            <ListItem
              button
              selected={currentLocation === AppRoute.AddCustomERP}
              onClick={() => onClickHandler(AppRoute.AddCustomERP)}
              classes={{ selected: classes.selected }}
              data-testid="custom-erp"
            >
              <ListItemIcon>
                <IconAddNote />
              </ListItemIcon>
              <ListItemText className={classes.navTitle} primary="Custom ERP" />
            </ListItem>
          )}
          {process.env.NX_ENABLE_GROUPINGS === 'true' && (
            <ListItem
              button
              selected={
                currentLocation === AppRoute.CustomGrouping ||
                ['custom-grouping', 'ingestion'].every((i) =>
                  location.pathname.split('/').includes(i)
                )
              }
              onClick={() => onClickHandler(AppRoute.CustomGrouping)}
              classes={{ selected: classes.selected }}
              data-testid="custom-grouping"
            >
              <ListItemIcon>
                <IconAddFolder />
              </ListItemIcon>
              <ListItemText
                className={classes.navTitle}
                primary="Custom Grouping"
              />
            </ListItem>
          )}
          {/* {process.env.NX_ENABLE_JOINS_TABLE === 'true' && ( */}
          <ListItem
            button
            selected={
              AppRoute.TableJoins === currentLocation ||
              AppRoute.TableJoinsCreate === currentLocation
            }
            onClick={() => onClickHandler(AppRoute.TableJoins)}
            classes={{ selected: classes.selected }}
            data-testid="table-joins"
          >
            <ListItemIcon>
              <IconTableJoins className={classes.navIcon} />
            </ListItemIcon>
            <ListItemText className={classes.navTitle} primary="Table Joins" />
          </ListItem>
          {/* )} */}

          {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
            <ListItem
              button
              selected={location.pathname.includes('/extractions')}
              onClick={() => onClickHandler(AppRoute.Extractions)}
              classes={{ selected: classes.selected }}
              data-testid="extractions"
            >
              <ListItemIcon>
                <IconExtractions className={classes.navIcon} />
              </ListItemIcon>
              <ListItemText
                className={classes.navTitle}
                primary="Extractions"
              />
            </ListItem>
          )}

          {process.env.NX_ENABLE_DAA === 'true' && (
            <ListItem
              button
              selected={
                AppRoute.AuditAnalytics === currentLocation ||
                AppRoute.AuditAnalyticsCreate === currentLocation
              }
              onClick={() => onClickHandler(AppRoute.AuditAnalytics)}
              classes={{ selected: classes.selected }}
              data-testid="audit-analytics"
            >
              <ListItemIcon>
                <IconAnalytics className={classes.navIcon} />
              </ListItemIcon>
              <ListItemText
                className={classes.navTitle}
                primary="Digital Audit Analytics"
              />
            </ListItem>
          )}

          {process.env.NX_ENABLE_DNA === 'true' && (
            <ListItem
              button
              selected={
                AppRoute.DataAndAnalitics === currentLocation ||
                AppRoute.CreatePipeline === currentLocation ||
                featureMenu.includes(AppRoute.DataAndAnalitics)
              }
              onClick={() => onClickHandler(AppRoute.DataAndAnalitics)}
              classes={{ selected: classes.selected }}
              data-testid="data-and-analytics"
            >
              <ListItemIcon>
                <DataAnalyticsIcon className={classes.navIcon} />
              </ListItemIcon>
              <ListItemText
                className={classes.navTitle}
                primary="Data & Analytics"
              />
            </ListItem>
          )}
        </List>
      </div>
      {process.env.NX_ENABLE_SUPPORT_PAGE === 'true' && (
        <div>
          <ListItem
            button
            selected={currentLocation === AppRoute.Support}
            onClick={() => onClickHandler(AppRoute.Support)}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon>
              <IconSupport className={classes.navIcon} />
            </ListItemIcon>
            <ListItemText className={classes.navTitle} primary="Support" />
          </ListItem>
        </div>
      )}
      <DialogComponent
        open={open}
        onClose={onDialogClose}
        cancelText="No"
        confirmText="Yes"
        title={title}
        subtitle={subTitle}
      />
    </div>
  )
}
