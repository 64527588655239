import React, {useState, useEffect} from 'react'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { TextField, Chip, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
/* eslint-disable-next-line */
export interface Option {
  label: string,
  value: string,
  group?: string,
  groupID?: string
}

export interface AutoSuggestFieldProps {
  options: Array<any>,
  id: number,
  disabled: boolean,
  textFieldLabel: string,
  textFieldPlaceholder: string,
  limitTags: number,
  maxWidth: string,
  key: string,
  placeholderText: string,
  valuesToDisplay: Array<string>,
  values: Array<string>,
  categoryFilter: number,
  invalid: boolean,
  autoAssign: boolean,
  inputRef: any,
  groupingSuggestions: any,
  alteredGroupingOptions? : any,
  onBackspace: (e: React.KeyboardEvent<{}>, selected: number) => void,
  onChange: (e: React.ChangeEvent<{}>, option:string, reason:string) => void,
  removeOption: (e: React.ChangeEvent<{}>, option: number) => void,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    currSelection: {
        color: theme.palette.secondary.main,
        fontWeight: 900,
    },
    bottomMessage: {
        width: '100%', 
        padding: '8px 16px', 
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.common.black,
        fontSize: "0.8rem",
    },
    recommendationPill: {
      height: "32px",
      width: "140px",
      fontSize: "0.8rem",
      borderRadius: "16px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    chipPrev: {
        height: "32px",
        fontSize: "0.8rem",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    chipCurr: {
        height: "32px",
        fontSize: "0.8rem",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    chipSelected: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    chipUnselected: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
  })
)

export function AutoSuggestField({...props}: AutoSuggestFieldProps) {
  const classes = useStyles()
  const [textInput, setTextInput] = useState<string>("");
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [valueToDisplay, setValuetoDisplay] = useState<string[]>([]);

  const filterOptions = (currSelection: string) => {
    const optionSelected = currSelection;
    if (optionSelected) {
      const availableOptions: Option[] = [
        ...props.alteredGroupingOptions.accountSubTypes[optionSelected] === undefined ? [] : props.alteredGroupingOptions.accountSubTypes[optionSelected],
        ...props.alteredGroupingOptions.fsCaptions[optionSelected] === undefined ? [] : props.alteredGroupingOptions.fsCaptions[optionSelected],
        ...props.alteredGroupingOptions.accountNames[optionSelected] === undefined ? [] : props.alteredGroupingOptions.accountNames[optionSelected],
    ]
      setDropdownOptions(availableOptions.filter((option) => (option != undefined)))
    } else {
      const cats = ['accountTypes', 'accountSubTypes', 'fsCaptions', 'accountNames'];
      const key = cats[props.categoryFilter];

      if (key) {
        let dropdowns = [...props.options];
        dropdowns = dropdowns.filter((opt) => (opt.group === key));
        // Turning off recommendations when filter is active to prevent situations where
        // recommendation will only show up when accountNames are selected.
        dropdowns = dropdowns.filter((opt) => (opt.isRecommended === false));
        setDropdownOptions(dropdowns)
      } else {
        setDropdownOptions([...props.options])
      }
    }
  }

  const changeValue = (e: any, option: any, reason:string) => {
    if(reason != 'remove-option') {
      if (reason != 'clear') {
          if(option[option.length-1]) {
            filterOptions(option[option.length-1]['value'])
            props.onChange(e, option, reason)
            setTextInput("");
          }
      } else {
          setTextInput("");
          props.onChange(e, option, reason)
      }
    }
  }

  const keyDownRemoveChip = (event: React.KeyboardEvent<{}>) => {
    event.preventDefault();
    event.stopPropagation();

    const valTodisplay = valueToDisplay.slice(0, valueToDisplay.length-1);
    setValuetoDisplay([...valTodisplay]);

    props.onBackspace(event, valTodisplay.length);
  }

  const handleKeyDown = (event: React.KeyboardEvent<{}>) => {
    if (event.code == "Delete") {
      if (textInput.length === 0) {
          keyDownRemoveChip(event)
        }
      }
     if (event.code == "Backspace") {
      if (textInput.length === 0) {
        keyDownRemoveChip(event)
      }
     }
  }

  useEffect(() => {
    setDropdownOptions(props.options)
  }, [props.options])

  useEffect(() => {
    setValuetoDisplay([...props.valuesToDisplay].filter((option) => (option != '-1' && option != null)));
    filterOptions(props.values[props.values.length - 1]);
  }, [props.valuesToDisplay])
  
  const OPTIONS_LIMIT = 500;
  const topOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
  });

  return (
    <Autocomplete
      filterOptions={topOptions}
      key={props.key}
      multiple
      ListboxComponent={(props) => (
        <div {...props}>
          {props.children} 
          {React.Children.count(props.children) >= OPTIONS_LIMIT && 
            <Box 
              className={classes.bottomMessage}
            >
              - Display limit reached. Type a query to narrow your search -
            </Box> 
          }
        </div>
      )}
      limitTags={props.limitTags}
      style={{ maxWidth: props.maxWidth }}
      options={!dropdownOptions ? [] : dropdownOptions }
      getOptionLabel={(option) => option.label}
      noOptionsText={"No options left"}
      autoComplete={true}
      renderTags={(tagValue, getTagProps) => {
        return (
          <>
          {
            valueToDisplay.map((option, index) => (
              <Chip 
                variant={index+1 === valueToDisplay.filter((option) => (option != "-1")).length ? 'default' : 'outlined'}
                color="secondary"
                key={`valueToDisplay-${index}`}
                {...getTagProps({ index })} 
                label={option ? option : ""}
                onDelete={(e) => props.removeOption(e, index)}  
                data-testid="grouping-chip"
              />
            ))
          }
          </>
        )
      }}
      renderOption={(props) => (
        <span style={{'background': props.isRecommended ? "#F0FBFA" : "white", 'display': 'block', 'height':'100%', 'width':'100%'}}>
            {props.isRecommended 
            ? <span className={classes.recommendationPill}>Recommendation {props.recommendationNo}</span>
            : <></> 
            }
            {props.completeHistory.map((cOp:string, index:number) => {
                return (
                    index < props.completeHistory.length-1
                        ? <span key={`${cOp}-${index}`} style={{whiteSpace: "pre", fontSize: "12px"}}> { cOp + " / " } </span> 
                        : <span key={`${cOp}-${index}`} className={classes.currSelection}
                        style={{whiteSpace: "pre"}}>{cOp}</span>
                    )    
                }
            )}
        </span>
      )}
      disableCloseOnSelect={
        valueToDisplay.filter((option) => (option != "-1")).length+1 < props.limitTags 
            ? true 
            : false
      }
      disabled={props.disabled}
      value={valueToDisplay}
      inputValue={textInput}
      onChange={changeValue}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              error={props.invalid}
              variant="outlined"
              placeholder={props.placeholderText ? props.placeholderText : ""}
              label={props.textFieldLabel}
              inputRef={props.inputRef}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => setTextInput(e.target.value)}
              InputProps={{
                ...params.InputProps,
                style: {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                },
              }}
            />
          </>
        )
      }}
    />
  )
}

AutoSuggestField.propTypes = {
  options: PropTypes.object,
  limitTags: PropTypes.number,
  textFieldLabel: PropTypes.string,
  textFieldPlaceholder: PropTypes.string,
  maxWidth: PropTypes.string
}

AutoSuggestField.defaultProps = {
  options: {},
  limitTags: 4,
  textFieldLabel: "Select",
  textFieldPlaceholder: "Select Groupings",
  maxWidth: "100%"
}

export default AutoSuggestField
