import React, { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import {
  getExtractions,
  getERPConnections,
  useIESelector,
  useIEDispatch,
  erp_status,
  extraction_status,
  setExtractionDetails,
  resetConnections,
  resetExtractions,
  resetCreationForms
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import {
    Grid,
    Button,
    Typography,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconSettings } from '../../assets/IconSettings.svg'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { format, parseISO } from 'date-fns'
import { enUS, enGB } from 'date-fns/locale'
import { green } from '@material-ui/core/colors'
import EBTable from 'libs/shared/components/src/lib/eb-table/eb-table'
import { NotFoundView } from '../../views/NotFoundView/NotFoundView'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'

const useStyles = makeStyles((theme) => {
    return createStyles({
      container: {
        display: 'flex',
        '& td': {
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
      clickable: {
        cursor: 'pointer'
      },
      createExtractionContainer: {
        display: 'flex',
        gap: 15,
        padding: '25px 0px 25px 0px',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
          padding: '0px 0px 25px 0px',
          justifyContent: 'flex-end',
        },
      },
      sectionStyles: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 80px)',
        maxWidth: 'calc(100vw - 280px)',
        padding: '30px',
        background: `#F0FBFA 0% 0% no-repeat padding-box`,
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px !important',
        },
      },
      header: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '30px',
        lineHeight: '37px',
        letterSpacing: '0.3px',
        color: '#22353F',
        opacity: 1,
      },
      createExtractionButton: {
        fontSize: '1rem',
        fontFamily:
          'Roboto, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      textCenter: {
        textAlign: 'center'
      },
      textLeft: {
        textAlign: "left",
        minWidth: "auto"
      },
      boldText: {
        fontWeight: 500,
        lineHeight: '16px'
      },
      erpConnectionSettingButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        width: '24px',
        minWidth: '24px',
      },
    })
  })

export function ExtractionsView() {
    const { 
      container, 
      header, 
      sectionStyles,
      textCenter,
      textLeft,
      boldText, 
      clickable,
      erpConnectionSettingButton, 
      createExtractionContainer, 
      createExtractionButton 
    } = useStyles()
    const dispatch = useIEDispatch()
    const {extractions, loading, message} = useIESelector((state) => state.extractions)
    const erpConnections = useIESelector((state) => state.extractions.erps)
    const [connectionStatus, setConnectionStatus] = useState([])
    let history = useHistory();
    const { formatMessage, locale } = useIntl()
    const client = useApolloClient()

    useEffect(() => {
      dispatch(getExtractions({client}))
      dispatch(getERPConnections({client}))
      dispatch(resetCreationForms({client}))
    }, [])

    useEffect(() => {
      let engagement = {}
      erpConnections.forEach((erp) => {
        const engagementItems = extractions.filter((x) => (x.erpConnection.engagement.id === erp?.engagement?.id))
        engagement[erp?.engagement?.id] = {
          client: erp?.engagement?.auditedEntity,
          engagement: erp?.engagement,
          erpTotal: erpConnections.filter((x) => (x.engagement.id === erp.engagement.id)).length,
          erpConnected: erpConnections.filter((x) => (x.engagement.id === erp.engagement.id && x.state === erp_status.CONNECTED)).length,
          extractionstotal: engagementItems.length,
          extractionsNo: engagementItems.filter((y) => (y.status === extraction_status.DONE)).length,
          name: erp.name,
        }
      })
      setConnectionStatus(Object.values(engagement))
    }, [erpConnections, extractions])

    const onSettingsClick = (auditedEntity, engagement) => {
      dispatch(resetConnections({client}))
      dispatch(resetExtractions({client}))
      dispatch(setExtractionDetails({ key: 'engagement', value: { name: engagement.name, id: engagement.id }}));
      dispatch(setExtractionDetails({ key: 'auditedEntity', value: auditedEntity }));
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.ExtractionsSettings))
    }

    const tableHeadersData = useMemo(() => {
        return [
          {
            title: 'Client',
            value: 'client',
            width: '100px',
            renderer: function (params) {
              const {
                row: { client, engagement },
              } = params
              return (
                <Typography 
                  onClick={() => onSettingsClick(client, engagement)}
                  className={`${boldText} ${clickable}`}
                >
                  { client.name }
                </Typography>
                )
            },
          },
          {
            title: 'Engagement',
            value: 'engagement',
            width: '100px',
            renderer: function (params) {
              const {
                   row: { engagement, client },
              } = params
              return (
                <Typography 
                  onClick={() => onSettingsClick(client, engagement)}
                  className={`${boldText} ${clickable}`}

                >
                  { engagement.name }
                </Typography>
              )
            },
          },
          {
            title: 'ERPS',
            value: 'erps',
            width: '100px',
            renderer: function (params) {
              const {
                row: { erpTotal, erpConnected, client, engagement },
              } = params
                return (
                <Typography 
                  onClick={() => onSettingsClick(client, engagement)}
                  className={`${clickable} ${textCenter}`}
                >
                  {erpConnected === erpTotal && 
                    <CheckCircleIcon style={{ height:'15px', width:'15px', fill: green[500]}} />
                  }
                  {erpConnected}/{erpTotal}
                </Typography>
                )
            },
          },
          {
            title: 'Extractions',
            value: 'extractions',
            width: '100px',
            renderer: function (params) {
              const {
                row: { extractionstotal, extractionsNo, client, engagement },
              } = params
                return ( 
                  <Typography 
                    onClick={() => onSettingsClick(client, engagement)}
                    className={`${clickable} ${textCenter}`}
                  >
                    {extractionstotal > 0 && extractionsNo === extractionstotal && 
                      <CheckCircleIcon style={{ height:'15px', width:'15px', fill: green[500]}} />
                    }
                    {extractionsNo}/{extractionstotal}
                  </Typography>
                )
            },
          },
          {
            title: 'Actions',
            value: 'actions',
            width: '50px',
            renderer: function (params) {
              const {
                row: { client, engagement },
              } = params
              if(client && engagement) {
                return <Button 
                  onClick={() => onSettingsClick(client, engagement)}
                  className={`${clickable} ${textLeft}`}
                >
                  <IconSettings className={erpConnectionSettingButton}/>
                </Button>
              }
            },
          },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extractions])

  const handleCreateExtraction = () => {
    dispatch(
      setExtractionDetails({
        key: 'auditedEntity',
        value: {
          name: '',
          id: null,
          auditFirm: { name: '', id: '', systemName: '' },
        },
      })
    )
    dispatch(
      setExtractionDetails({ key: 'engagement', value: { name: '', id: null } })
    )
    history.push('extractions/settings')
  }

    return (
      process.env.NX_ENABLE_API_EXTRACTIONS === 'true' 
      ? (
        <section className={sectionStyles}>
          <Grid container className={container} spacing={4}>
              <Grid item xs={12} md={8}>
              <Typography className={header}>Extractions</Typography>
              </Grid>
              <Grid item xs={12} md={4} className={createExtractionContainer}>
              <Button
                  variant='contained'
                  color='secondary'
                  className={createExtractionButton}
                  onClick={handleCreateExtraction}
              >
                <Typography>Create Extraction</Typography>
              </Button>
              </Grid>
              <Grid container item xs={12} direction="column" style={{ flexGrow: 1 }}>
                <EBTable
                  loading={loading}
                  headers={tableHeadersData}
                  rows={connectionStatus}
                  recordsPerPage={5}
                  footerContent={null}
                  styles={{
                    tableBoxStyles: {
                      borderRadius: '8px',
                    },
                  }}  
                  emptyLabel={message || 'No Data To Display'}
                />
              </Grid>
          </Grid>
        </section>) 
      : (
        <NotFoundView/>
      )
    )
}
