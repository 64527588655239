import { gql } from '@apollo/client'

export const UPDATE_DATA_INGESTION = gql`
  mutation updateDataIngestionStatus(
    $dataIngestionId: String
    $status: DataIngestionStatus!
    $additionalDetailValues: JSON
  ) {
    updateDataIngestionStatus(
      dataIngestionId: $dataIngestionId
      status: $status
      additionalDetailValues: $additionalDetailValues
    ) {
      id
      name
      status
    }
  }
`
