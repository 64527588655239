import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'default'
  | 'success'
  | 'warning'

/* eslint-disable-next-line */
export interface StatusProps {
  label?: string
  color?: Color
}

const useStyles = (props: StatusProps) =>
  makeStyles(() => {
    return {
      customStatus: {
        font: 'normal normal normal 16px/18px Arial',
        color: props.color,
        border: `1px solid ${props.color}`,
        display: 'inline',
        padding: '7px 9px 8px 8px',
        lineHeight: '18.4px',
        whiteSpace: 'nowrap',
        borderRadius: '3px',
        letterSpacing: '0.16px',
      },
      primaryClass: {
        color: 'hsla(205,34%,34%,1)',
        border: `1px solid hsla(205,34%,34%,1)`,
      },
      secondaryClass: {
        color: 'hsla(181, 100%, 34%, 1)',
        border: `1px solid hsla(181, 100%, 34%, 1)`,
      },
      successClass: {
        color: '#37AB3F',
        border: `1px solid #37AB3F`,
      },
      warningClass: {
        color: '#E0A218',
        border: `1px solid #E0A218`,
      },
      errorClass: {
        color: 'hsla(0,69%,59%,1)',
        border: `1px solid hsla(0,69%,59%,1)`,
      },
      defaultClass: {
        color: 'hsla(201,17%,61%,1)',
        border: `1px solid hsla(201,17%,61%,1)`,
      },
    }
  })

export function Status(props: StatusProps) {
  const {
    customStatus,
    primaryClass,
    secondaryClass,
    errorClass,
    defaultClass,
    successClass,
    warningClass,
  } = useStyles(props)()
  switch (props.color) {
    case 'primary':
      return (
        <Typography className={`${customStatus} ${primaryClass}`}>
          {props.label || 'N/A'}
        </Typography>
      )
    case 'secondary':
      return (
        <Typography className={`${customStatus} ${secondaryClass}`}>
          {props.label || 'N/A'}
        </Typography>
      )
    case 'error':
      return (
        <Typography className={`${customStatus} ${errorClass}`}>
          {props.label || 'N/A'}
        </Typography>
      )
    case 'success':
      return (
        <Typography className={`${customStatus} ${successClass}`}>
          {props.label || 'N/A'}
        </Typography>
      )
    case 'warning':
      return (
        <Typography className={`${customStatus} ${warningClass}`}>
          {props.label || 'N/A'}
        </Typography>
      )
    default:
      return (
        <Typography
          className={
            props.color === 'default'
              ? `${customStatus} ${defaultClass}`
              : customStatus
          }
        >
          {props.label || 'N/A'}
        </Typography>
      )
  }
}

Status.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
}

Status.defaultProps = {
  label: 'Completed',
  color: 'primary',
}

export default Status
