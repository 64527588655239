import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

const CustomScrollbars = ({ children, ...props }) => {
  return (
    <Scrollbars
      {...props}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            width: '16px',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 'inherit',
          }}
        />
      )}
      renderTrackVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.01)',
            background: 'rgba(0,0,0,0.1)',
            position: 'absolute',
            width: '16px',
            right: '2px',
            bottom: '2px',
            top: '2px',
            borderRadius: '3px',
          }}
        />
      )}
    >
      {children}
    </Scrollbars>
  )
}

export default CustomScrollbars
