import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, {
  Fragment,
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
} from 'react'
import { HEADER_HEIGHT, SIDEBAR_WIDTH, FOOTER_HEIGHT } from './constants'
import Header from './Header'
import { SideBar } from './SideBar'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HelperTextBox } from '@engine-b/integration-engine/features/helper-text-box'
import Footer from './Footer'

const useStyles = makeStyles(() =>
  createStyles({
    headerWrapper: {
      height: `${HEADER_HEIGHT}px`,
    },
    sideBarWrapper: {
      width: `${SIDEBAR_WIDTH}px`,
      background: '#44697D 0% 0% no-repeat padding-box',
    },
    contentWrapper: {
      marginLeft: `${SIDEBAR_WIDTH}px`,
    },
    footerWrapper:{
      height: `${FOOTER_HEIGHT}px`,
    }
  })
)

export interface SideBarLayoutProps {
  header?: ReactNode
  sideBarContent: ReactNode
  children: ReactChildren | ReactChild[] | ReactElement
}

export function SideBarLayout(props: SideBarLayoutProps) {
  const classes = useStyles()
  const { header, sideBarContent, children } = props
  const helperState = useIESelector((state) => state.helper)
  return (
    <Fragment>
      <div className={classes.headerWrapper}>
        {header ? header : <Header />}
      </div>
      <div className={classes.sideBarWrapper}>
        <SideBar>{sideBarContent}</SideBar>
      </div>
      <div className={classes.contentWrapper}>
        {helperState.isOpen ? <HelperTextBox {...helperState} /> : null}
        {children}
      </div>
      <div className={classes.footerWrapper}>
        <Footer />
      </div>
    </Fragment>
  )
}
