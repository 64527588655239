import React from 'react'
import {
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

const exampleValues = [
  {
    field: '10001',
    line: '1',
  },
  {
    field: '10001',
    line: '2',
  },
  {
    field: '10001',
    line: '3',
  },
]

const JLNExample = ({
  field_name,
  cdm_field,
  example_class,
  align_vertical,
}) => {
  return (
    <>
      <Typography variant="subtitle1">
        {field_name} = {exampleValues[0].field}
      </Typography>
      <Divider style={{ margin: '20px 0' }} />
      <Typography className={example_class}>Calculation :</Typography>
      <Typography>
        {' '}
        {field_name} = {exampleValues[0].field}
      </Typography>
      <Typography>
        {' '}
        {cdm_field} = Count of {field_name}
      </Typography>
      <Divider style={{ margin: '20px 0' }} />
      <Typography className={example_class}>Example :</Typography>
      <div className={align_vertical}>
        <Table stickyHeader={true} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{field_name}</TableCell>
              <TableCell>Journal Line Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exampleValues.map((e) => (
              <TableRow>
                <TableCell>{e.field}</TableCell>
                <TableCell>{e.line}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export default JLNExample
