import React, { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ReactComponent as IconClose } from './assets/Close.svg'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  getAnalyticsPipelineNodeOutput,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import cuid from 'cuid'
import { ReactComponent as FileIcon } from './assets/fileOp.svg'

export interface PreviewOutputNodeProps {
  open: boolean
  onClose: (flag: boolean, pipelineName: string) => void
}

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const PreviewOutputNode = (props: PreviewOutputNodeProps) => {
  const { title } = useStyles()
  const { open, onClose } = props
  const dispatch = useIEDispatch()
  const { instance, inProgress, accounts } = useMsal()
  const { loader, outputs, analyticsPipeline } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )

  const [value, setValue] = React.useState(0)
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [rows, setRows] = useState([])

  // Get first 50 rows of output file
  const fetchAnalyticsPipelineNodeOutput = async (file_path: string) => {
    setColumns([])
    setRows([])
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    dispatch(getAnalyticsPipelineNodeOutput({ file_path, token }))
      .unwrap()
      .then((response) => {
        const mappedColumns = Object.keys(response[0]).map((key: string) => {
          return {
            field: key,
            headerName: key,
            // filterable: false,
            hideable: false,
            width: 180,
          }
        })
        const mappedRows = response.map((record: any) => ({
          id: cuid(),
          ...record,
        }))
        setColumns(mappedColumns)
        setRows(mappedRows)
      })
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    if (outputs.length > 0 && open)
      fetchAnalyticsPipelineNodeOutput(outputs[value].filepath)
  }, [value, outputs, open])

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>
        <Box className={title}>
          <Typography variant="h5">
            Preview Output ({analyticsPipeline?.name})
          </Typography>
          <IconButton onClick={() => onClose(false, '')}>
            <IconClose />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {outputs.length > 0 && (
          <>
            <Tabs
              value={value}
              onChange={(_, value) => setValue(value)}
              aria-label="Output files tabs"
              style={{ marginBottom: '1rem' }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {outputs.map((output, index) => (
                <Tab
                  key={output.filename}
                  label={output.filename}
                  icon={<FileIcon />}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <DataGrid
              autoHeight={true}
              rows={rows}
              columns={columns}
              loading={loader}
              disableRowSelectionOnClick={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PreviewOutputNode
