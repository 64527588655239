import React, { useEffect, useState, useContext } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  CdmEntityCollection,
  getCdmEntities,
  addFileMapping,
  useIEDispatch,
  useIESelector,
  deleteFileMapping,
} from '@engine-b/integration-engine/data/state/redux'
import { CdmEntity, Erp, ExtractType } from '@engine-b/shared/types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FileMappingRow } from './FileMappingRow'
import { DropDown } from '@engine-b/integration-engine/ui/form-components'
import {
  AzureClientContext,
  deleteFileFromAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
const useStyles = makeStyles((theme) =>
  createStyles({
    system: {
      padding: '0.5em',
      border: '2px solid #44697D33',
      borderRadius: 10,
      marginTop: 30,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
    },
    container: {
      marginTop: 30,
      border: '2px solid #44697D33',
      borderRadius: 10,
      paddingLeft: '0.45em',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
    },
    header: {
      padding: '0.5em 1em',
      width: '100%',
      height: 120,
      borderRadius: 10,
      '&::after': {
        content: '""',
        width: 'calc(100% - 3.8rem)',
        display: 'block',
        position: 'absolute',
        left: 'calc(1.9rem)',
        borderBottom: '2px solid #44697D33',
        marginTop: 30,
      },
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 188,
      borderRadius: 10,
      font: 'normal normal bold 32px/50px Arial',
      letterSpacing: 0.32,
      color: '#89A0AC',
      opacity: 0.7,
    },
  })
)

interface UploaderProps {
  erpId: Erp['id']
  extractType: ExtractType
  handleAddCdmEntity: (systemName: CdmEntity['systemName']) => void
  source: Object
}

export function UploadManager(props: UploaderProps) {
  const { erpId, extractType, handleAddCdmEntity, source } = props
  const classes = useStyles()
  const client = useApolloClient()
  const dispatch = useIEDispatch()

  const [preloadFileObjs, setPreloadFileObjs] = useState([])

  const cdmEntityCollection = useIESelector<CdmEntityCollection>(
    (state) => state.cdmEntities
  )
  const azureClient = useContext(AzureClientContext)
  const fileMapping = useIESelector((state) => state.fileMappings)
  const draftRun = useIESelector((state) => state.runs.draftRun)
  const { cdmEntitySystemNames, container: azureContainer } = draftRun
  // In case of using the preloader created with inputData
  const inputData = draftRun.preloaderInfo?.inputData

  const initRows = []
  cdmEntitySystemNames.map((v) => {
    cdmEntityCollection[v] && initRows.push(cdmEntityCollection[v])
  })

  const [rows, setRows] = useState(initRows)

  const options = React.useMemo(() => {
    const inputOptions = Object.assign({}, cdmEntityCollection)
    if (rows.length) {
      rows.forEach(({ systemName }) => {
        delete inputOptions[systemName]
      })
    }

    return Object.values(inputOptions)
    .map((e) => ({
      id: e.systemName,
      label: e.name,
    }))
  }, [rows, cdmEntityCollection])

  useEffect(() => {
    dispatch(getCdmEntities({ client, erpId, extractType }))
  }, [client, dispatch, erpId, extractType])

  useEffect(() => {
    if (inputData) {
      inputData.forEach((input) => {
        for (const key in cdmEntityCollection) {
          if (cdmEntityCollection[key].name === input.reportType) {
            setPreloadFileObjs((prevState) => [...prevState, input])
            handleAddRow(cdmEntityCollection[key].systemName)
          }
        }
      })
    }
  }, [inputData, cdmEntityCollection])

  const handleAddRow = (cdmEntitySystemName) => {
    if (cdmEntityCollection[cdmEntitySystemName]) {
      const fileNames = cdmEntityCollection[cdmEntitySystemName].inputFiles.map(
        (inputFile) => inputFile.name
      )
      dispatch(
        addFileMapping({
          fileNames,
          reportType: cdmEntityCollection[cdmEntitySystemName].systemName,
        })
      )
      setRows((state) => state.concat(cdmEntityCollection[cdmEntitySystemName]))
      handleAddCdmEntity(cdmEntitySystemName)
    }
  }

  const handleRemoveRow = (cdmEntity) => {
    setRows(rows.filter(({ systemName }) => systemName !== cdmEntity))
    const inputFiles = cdmEntityCollection[cdmEntity].inputFiles
    const reportType = cdmEntityCollection[cdmEntity].systemName
    inputFiles.forEach((file) => {
      const fileMappObj = fileMapping[`${reportType}_${file.name}`]
      const newInputPath = fileMappObj?.fileNameByUser
      const uploaded: number = fileMappObj?.uploaded
      if (uploaded) {
        deleteFileFromAzureContainer({
          azureClient,
          fileSystemId: azureContainer?.fileSystemId || '',
          filePath: newInputPath,
        })
      }
    })
    dispatch(
      deleteFileMapping({
        cdmFileNames: inputFiles.map((file) => file.name),
        reportType,
      })
    )
  }

  return (
    <div>
      {rows?.map((row, i) => {
        const { systemName, name, ...rest } = row
        return (
          <div className={classes.system} key={systemName}>
            <FileMappingRow
              preloadFileObj={preloadFileObjs[i]}
              selectedCdmEntity={systemName}
              cdmEntityCollection={cdmEntityCollection}
              onRemoveRow={handleRemoveRow}
              source={source}
              name={name}
              index={i}
              {...rest}
            />
          </div>
        )
      })}
      {Object.keys(options).length ? (
        <div className={classes.container}>
          <div className={classes.header}>
            <DropDown
              label={'Report type'}
              onChange={handleAddRow}
              options={options}
              placeholderLabel="Select Report Type"
              value={-1}
            />
          </div>
          <div className={classes.message}>Please Select Report Type</div>
        </div>
      ) : null}
    </div>
  )
}
