export const SINGLE_COLUMN_AMOUNT = 'Single Column Amount'
export const DEBIT_CREDIT = 'Debit/Credit'
export const DEBIT_CREDIT_INDICATOR = 'Debit-Credit Indicator'
export const ADD = 'Add'
export const SUBTRACT = 'Subtract'
export const MULTIPLY = 'Multiply'
export const DIVIDE = 'Divide'
export const CONCATENATE = 'Concatenate'
export const CONCATENATE_DATE_TIME = 'Concatenate Date Time'
export const SPLIT = 'Split'
export const DATE_MASK = 'Date Mask'
export const JOURNAL_LINE_NUMBER = 'Journal Line Number'
export const MULTIPLE_CALCULATIONS = 'Multiple Calculations'
export const EXTRACT_TO_SPLIT = 'Extract and Split'
export const CONDITIONAL_SELECT = 'Conditional Select'
export const FREE_TEXT = 'Free Text'
export const AMOUNT_CALCULATION = 'Amount Calculation'
export const FILL_DOWN = 'Fill Down'
export const MATHS_WITH_BRACKET = 'Maths with Bracket'
export const CONDITIONAL_COLUMN = 'Conditional Column'

export default [
  {
    title: 'Addition',
    name: ADD,
    value: 'SUM',
    desc: 'Calculate the value of the field by adding the numeric values in two or more fields. Only fields with number values can be added in the drop-downs.',
    elements: [1, 2],
    addMore: true,
    symbol: '+',
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Amount Calculation',
    name: AMOUNT_CALCULATION,
    value: 'AC',
    desc: 'Calculate the value of the field by adding the numeric values in two or more fields. Only fields with number values can be added in the drop-downs.',
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Concatenate',
    name: CONCATENATE,
    value: 'CONCAT',
    desc: [
      'Concatenate multiple fields into a single field. Values will be separated with a ',
    ],
    elements: [1, 2],
    addMore: true,
    symbol: '-',
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Concatenate Date Time',
    name: CONCATENATE_DATE_TIME,
    value: 'DCONCAT',
    desc: [
      `The field you have mapped to this date field is in a format the tool doesn't immediately recognise. Please choose from the below date/time options to let us know which format your dates are in. Formats use the following notation:
    Dates: 'y' = year, 'M' = month, 'd' = day; Times: 'H' = hour, 'm' = minute, 's' = second, 'ms' = milliseconds.`,
    ],
    elements: [1, 2],
    addMore: true,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Conditional Column',
    name: CONDITIONAL_COLUMN,
    value: 'CC',
    desc: `This functionality allows a user to make comparisons between a value and a specific criteria with set conditions. The function will result in a single output defined by the user as a specified result or a user defined default result.\nMultiple operations can be used at once within each condition, for example AND, OR, Equal to etc. The 'Choose ERP Field' option of 'Custom Input' can be used to define a specific criteria such as a date, amount or specific string of text. To input details of the initial condition, select the required ERP input field from the "Choose ERP Field" dropdown box, then select the required operations and additional ERP fields. As the initial condition may not be met the user can apply multiple conditions by again selecting the 'Add Condition' function.\nEach condition needs to have a 'Result' and either another 'Condition' or a 'Default Result'. The final condition will always have a 'Result' and a 'Default result'.\n\nWhen adding further conditions and required results please select each of the relevant headers of "Add Condition", "Add Result" and "Add default result" before completing the drop-down boxes below. Once selected you can manually maneuverer the box/circle on the dotted grid to your required location, this is for visual purposes only.\n\nExample calculation:\nThe function can be used to find values in an 'Amount' ERP Field which are 'Greater Than' 100,000. A specified result can be defined as the 'Amount' ERP Field, and the 'Default Result' can be a Custom Input of 'N/A'.\n\nIf when detailing the calculation one condition is no longer required then this can be selected and deleted using the delete or backspace keys. Please be aware that any subsequent linked results and default results will also be deleted.`,
    elements: [1, 2],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Conditional Select',
    name: CONDITIONAL_SELECT,
    value: 'CS',
    desc: `This functionality allows a user to select the 1st field by default for population into the CDM field. However, should the 1st field be empty, the contents of the 2nd field will be added to the CDM field. This functionality is similar to IsNull in SQL.`,
    elements: [1, 2],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Date Mask',
    name: DATE_MASK,
    value: 'Date Mask',
    desc: `The field you have mapped to this date field is in a format the tool doesn't immediately recognise. Please choose from the below date/time options to let us know which format your dates are in. Formats use the following notation:
Dates: 'y' = year, 'M' = month, 'd' = day; Times: 'H' = hour, 'm' = minute, 's' = second, 'ms' = milliseconds, 'XXX' = timezone. Optionally, use 'T' as a separator between date and time.`,
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Debit-Credit Indicator',
    name: DEBIT_CREDIT_INDICATOR,
    value: 'DCI',
    desc: [
      'Choose the field from your upload file which contains amounts in your functional currency, that is the currency used for audit. Positive numbers will be read in as debits, and negative numbers as credits.',
      'To be used if your input data includes a debit column and a credit column. In the first box select the field from your upload which contains Debits. In the second choose the field from your upload which contains Credits.',
      'Identifies debits and credits based on the sign (positive or negative) of the amount value of the transaction, based on which type of account the transaction affects. Account types are identified by ranges. In the drop-down below select the field containing glAccount number, enter the ranges of your account code in the free text boxes, and then select whether this range gives positive numbers as Debits or Credits. Select the amount column in the final dropdown.',
    ],
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Division',
    name: DIVIDE,
    value: 'DIV',
    desc: 'Calculate the value of the field by dividing numeric values. Only fields with number values can be divided and chosen in the drop-downs.',
    elements: [1, 1],
    addMore: false,
    symbol: '÷',
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Extract and Split',
    name: EXTRACT_TO_SPLIT,
    value: 'SGLAN',
    desc: `This calculated field will let you extract characters from within a longer string or group of characters, either splitting on a specific character or by taking the values stored between an opening and closing character.`,
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Fill Down',
    name: FILL_DOWN,
    value: 'FILLDOWN',
    desc: 'Takes data in a specific field which acts as a header and copies it down into subsequent rows to ensure all rows have the information. Often used where e.g. transactions are listed under an account name or number or a date, but the account or date is not then repeated in each individual row. The fill will take place before rows are removed, meaning that you can fill down from header and subheader rows which are subsequently removed from the data to give a clean data set.',
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Free Text',
    name: FREE_TEXT,
    value: 'FT',
    desc: `Choose this option to add the same free text data to every row in a chosen CDM field.  For example, if the fiscalYear field in the TB data should be populated with that year, but it is not present in the raw data, then it could be entered here manually e.g. “2022”`,
    elements: [],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Journal Line Number',
    name: JOURNAL_LINE_NUMBER,
    value: 'Journal Line Number',
    desc: 'Provides line numbers within a journal posting where these are not provided in the system. Counts up in order from 1 within individual journal IDs. Select the journal ID in the dropdown box.\nIf a different ERP field is selected in the dropdown box than that already selected in the journalId CDM field, then the journalId CDM field will be updated automatically with the same ERP input field selected in the below dropdown.',
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Maths with Bracket',
    name: MATHS_WITH_BRACKET,
    value: 'MCB',
    desc: `Calculate the value of the field by choosing the individual ERP fields required for the calculation from the dropdown boxes below. The mathematical functions required between the ERP fields need to be completed in the Expression box below where it states 'Create MCB Expression'. When writing the expression, the ERP fields should be referred to as per the character associated with them. For example, the first 3 ERP input fields would be '1', '2' and '3', and an example calculation that can be input in the Expression box is (1 + 2) * 3.\nOnly fields with number values can perform mathematical functions in the dropdown.\nCalculations will be performed using the normal mathematical order of functions: divide, multiply, add, subtract`,
    elements: [1, 2],
    addMore: true,
    symbol: '-',
    allowedDataTypes: ['integer', 'decimal'],
  },
  {
    title: 'Multiple Calculations',
    name: MULTIPLE_CALCULATIONS,
    value: 'MC',
    desc: `Calculate the value of the field by choosing ERP fields and basic mathematical functions between them to perform calculations. Only fields with number values can perform mathematical functions in the dropdown.\nCalculations will be performed using the normal mathematical order of functions: divide, multiply, add, subtract. This means if you choose 'x + y * z' your calculation will be 'x + (y * z)' and if you choose 'x-y / z' your calculation will be 'x - (y/z)'.`,
    elements: [1, 2],
    addMore: true,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Multiplication',
    name: MULTIPLY,
    value: 'PROD',
    desc: 'Calculate the value of the field by multiplying values by each other. Only fields with number values can be multiplied and chosen in the drop-downs.',
    elements: [1, 2],
    addMore: true,
    symbol: '*',
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Split',
    name: SPLIT,
    value: 'SPLIT',
    desc: "Choose this option where you wish to select certain data in an input column to be shown in the output. This could be select data from the left hand or right hand side of the input column.\n\nIn the first drop-down box, of 'Choose ERP Field', select the column you want to split. The next drop-down box gives the option of which output field in the data model that you wish for the remainder of the characters to go. This box does not have to be completed and can be left blank. The following 2 boxes should be used to select how many characters you want to include in this field and whether the character split should be from the first character from the left or right of the data string. The column values will be split after the number of characters that is given.\n\nIf a remainder column has been selected this will auto populate in the CDM data and be visible once 'Apply' has been selected. Once applied, if one column with this split functionality is deleted the other remainder column will not automatically be deleted, this will have to be done manually. ",
    elements: [1],
    addMore: false,
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
  {
    title: 'Subtraction',
    name: SUBTRACT,
    value: 'DIFF',
    desc: 'Calculate the value of the field by subtracting numeric values from each other. Only fields with number values can be subtracted and chosen in the drop-downs.',
    elements: [1, 2],
    addMore: true,
    symbol: '-',
    allowedDataTypes: ['integer', 'decimal', 'string', 'datetime'],
  },
]
