import { gql } from '@apollo/client'

export const GET_CONNECTIONS = gql`
    query getErpConnections {
        getErpConnections {
            id
            name
            state
            authorisationId
            expirationDateTime
            authorisationUrl
            engagement {
            id
            name
            auditedEntity {
                id
                name
                auditFirm {
                id
                name
                systemName
                }
            }
            auditedEntityId
            }
            system
            preAuthConfigValues
            errorConfig
        }
    }
`

export const GET_EXTRACTIONS = gql`
    query getErpExtractions {
        getErpExtractions {
            id
            name
            startDateTime
            endDateTime
            status
            progress
            failureReason
            errorConfig
            erpConnection {
            id
            name
            state
            authorisationId
            expirationDateTime
            authorisationUrl
            engagement {
                id
                name
                auditedEntity {
                id
                name
                auditFirm {
                    id
                    name
                    systemName
                }
                }
                auditedEntityId
            }
            system
            }
        }
    }
`

export const GET_CONNECTIONS_BY_CLIENT = gql`
    query getErpConnections($engagementId: String!)  {
        getErpConnections(engagementId: $engagementId) {
            id
            name
            state
            authorisationId
            expirationDateTime
            authorisationUrl
            engagement {
            id
            name
            auditedEntity {
                id
                name
                auditFirm {
                id
                name
                systemName
                }
            }
            auditedEntityId
            }
            system
            preAuthConfigValues
            postAuthConfigValues
            errorConfig
        }
    }
`

export const GET_ERPS_TYPES = gql`
    query getErpIntegrations {
        getErpIntegrations
    }
`

export const GET_ERPS_ENTITIES = gql`
    query GetErpEntities($system: String!) {
        getErpEntities(system: $system) {
            entities
        }
    }
`

export const GET_POST_AUTH_OPTIONS = gql`
    query GetPostAuthParameters($erpConnectionId: String!) {
        getPostAuthParameters(erpConnectionId: $erpConnectionId) {
            type
            properties
            required
            additionalProperties
        }
    }
`

export const GET_PRE_AUTH_OPTIONS = gql`
    query getPreAuthOptions($system: String!)  {
        getPreAuthOptions(system: $system) {
            preAuthConfigSchema
        }
    }
`

export const GET_CONNECTION_WORKFLOW = gql`
    query getConnectionWorkflow {
        id
    }
`
