import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  useIEDispatch,
  closeHelper,
  HelperState,
} from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '400px',
      position: 'absolute',
      top: '267px',
      right: '50px',
      padding: '50px 30px 30px 50px',
      background: '#E9EEF1',
      overflow: 'hidden',
      zIndex: 10,
      boxShadow: '3px 3px 8px 2px #bebebe',
    },
    closeContainer: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '35px',
      height: '35px',
      cursor: 'pointer',
    },
    closeCorner: {
      position: 'absolute',
      width: '70px',
      height: '70px',
      right: '-35px',
      top: '-35px',
      transform: 'rotate(45deg)',
      background: '#44697D',
    },
    closeIcon: {
      color: '#fff',
      height: '21px',
      width: '21px',
      position: 'relative',
      left: '10px',
      top: '4px',
    },
    title: {
      marginBottom: '1em',
    },
  })
)

export const HelperTextBox = ({ title, body }: Partial<HelperState>) => {
  const classes = useStyles()
  const dispatch = useIEDispatch()

  const handleClose = () => {
    dispatch(closeHelper())
  }

  return (
    <div className={classes.container} role="dialog" data-testid="helper-box">
      <div className={classes.closeContainer}>
        <div onClick={handleClose}>
        <div className={classes.closeCorner} />
        <CloseIcon
          className={classes.closeIcon}
          role="button"
          data-testid="close-helper"
        />
      </div>
      </div>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <Typography>{body}</Typography>
    </div>
  )
}
