import React from 'react'

export const ADD_DATA_FLOW_TITLE = (
  <>
    Please note that you haven't
    <br /> completed your mapping and ingestion process.
  </>
)

export const ADD_DATA_FLOW_SUBTITLE = (
  <>
    By leaving the page you will lose the mapping and also abandon
    <br /> the file conversion to the CDM.
  </>
)

export const CUSTOM_GROUPING_TITLE = (
  <>
    The grouping progress will be lost, save the partial grouping before leaving
    this page.
    <br /> Partial groupings will not be available to use in the “Add Data” Flow
    until they are complete.
    <br /> Are you sure your want to clear all groupings?
  </>
)

export const TABLE_JOINS_TITLE = (
  <>
    The Table Joins progress will be lost.
    <br /> Are you sure you want to lose your progress?
  </>
)

export const CUSTOM_ERP_TITLE = (
  <>
    The Custom ERP data will be lost.
    <br /> Are you sure you want to lose your data?
  </>
)

export const EXTRACTIONS_TITLE = (
  <>
    The Extractions progress will be lost.
    <br /> Are you sure you want to lose your progress?
  </>
)

export const DAA_TITLE = (
  <>
    Your Digital Audit Analytics process
    <br /> will be lost if you leave this page.
    <br /> Would you like to proceed?
  </>
)

export const DNA_TITLE = (
  <>
    Your Pipeline progress will be lost if you leave this page.
    <br /> Would you like to proceed?
  </>
)

export const COMPLETED_INGESTION = (
  <>
    This ingestion is already in COMPLETED state.
    <br /> Please try preloading with correct ingestion ID.
  </>
)
