import React, { useState, useEffect } from 'react'
import { makeStyles, Typography, Box } from '@material-ui/core'
import { ReactComponent as IconArrow } from '../../../assets/IconArrow.svg'
import { ReactComponent as IconInfo } from '../../../assets/IconInfo.svg'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import {
  updateCustomErpFieldConfig,
  useIEDispatch,
} from '@engine-b/integration-engine/data/state/redux'
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Arial',
    '& .MuiInputBase-root': {
      border: '1px solid #D5DCE1',
    },
  },
  info: {
    display: 'flex',
    background: '#f8fafb',
    width: '635px',
    maxHeight: '200px',
    overflowY: 'auto',
    padding: '10px',
    borderRadius: '8px',
    borderLeft: '2px solid #344B5F',
    '& .fn-right': {
      marginLeft: '10px',
      color: '#22353F',
      '& span': {
        letterSpacing: '0.14px',
      },
    },
  },
}))


const dateFormatItems = [
  { label: 'mm/dd/yyyy', value: 'mm/dd/yyyy', example: '09/01/2022' },
  { label: 'dd/mm/yyyy', value: 'dd/mm/yyyy', example: '01/09/2022' },
  { label: 'dd/mm/yy', value: 'dd/mm/yy', example: '01/09/22' },
  { label: 'mm/dd/yy', value: 'mm/dd/yy', example: '09/01/22' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy', example: '01-09-2022' },
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy', example: '09-14-2022' },
  { label: 'mm-dd-yy', value: 'mm-dd-yy', example: '09-14-22' },
  {
    label: 'dd/mm/yyyy HH:MM:SS',
    value: 'dd/mm/yyyy HH:MM:SS',
    example: '01/09/2022 13:08:22',
  },
  {
    label: 'dd-mm-yyyy HH:MM',
    value: 'dd-mm-yyyy HH:MM',
    example: '01-09-2022 13:08',
  },
  {
    label: 'dd-mm-yyyy HH:MM:SS',
    value: 'dd-mm-yyyy HH:MM:SS',
    example: '01-09-2022 13:08:22',
  },
  {
    label: 'dd-mm-yyyy HH.MM',
    value: 'dd-mm-yyyy HH.MM',
    example: '01-09-2022 13.08',
  },
  {
    label: 'dd-mm-yyyy HH.MM.SS',
    value: 'dd-mm-yyyy HH.MM.SS',
    example: '01-09-2022 13.08.22',
  },
  { label: 'dd-mon-yy', value: 'dd-mon-yy', example: '01-Sep-22' },
  {
    label: 'yyyy-mm-dd HH:MM:SS',
    value: 'yyyy-mm-dd HH:MM:SS',
    example: '2022-09-01 13:08:22',
  },
  {
    label: 'dd/mm/yyyy HH:MM:SS A',
    value: 'dd/mm/yyyy HH:MM:SS A',
    example: '01/09/2022 01:08:22 PM',
  },
  {
    label: 'yyyy-mm-dd HH:MM:SS.ms',
    value: 'yyyy-mm-dd HH:MM:SS.ms',
    example: '2022-09-01 13:08:22.163997',
  },
  {
    label: 'yyyy-mm-dd HH:MM',
    value: 'yyyy-mm-dd HH:MM',
    example: '2022/09/01 01:08',
  },
  {
    label: 'mm/dd/yyyy HH:MM:SS A',
    value: 'mm/dd/yyyy HH:MM:SS A',
    example: '09/01/2022 01:08:22 PM',
  },
  { label: 'yyyy', value: 'yyyy', example: '2022' },
  { label: 'yyyymmdd', value: 'yyyymmdd', example: '20220901' },
  { label: 'yyyy-mm-dd', value: 'yyyy-mm-dd', example: '2022-09-01' },
  { label: 'mm', value: 'mm', example: '09' },
  { label: 'HH:MM:SS', value: 'HH:MM:SS', example: '13:08:22' },
  { label: 'mm-dd-yy', value: 'mm-dd-yy', example: '09-14-22' },
  { label: 'yyyy-mm-ddTHH:MM:SS.ms', value:'yyyy-mm-ddTHH:MM:SS.ms',example:'2018-09-28T13:19:53.62' },
  {
    label: 'dd-mon-yyyy',
    value:'dd-mon-yyyy',
    example:'12-JAN-2023'
  },
  {
    label: 'yyyy/mm/dd',
    value:'yyyy/mm/dd',
    example:'2023/01/12'
  },
  {
    label: 'dd-mm-yy',
    value: 'dd-mm-yy',
    example: '12-01-23',
  },
  {
    label: 'yyyy-mm-ddTHH:MM:SS.msTZ',
    value: 'yyyy-mm-ddTHH:MM:SS.msTZ',
    example: '2024-01-03T09:30:00.000Z',
  },
  {
    label: 'yyyy-mm-ddTHH:MM:SSTZ',
    value: 'yyyy-mm-ddTHH:MM:SSTZ',
    example: '2024-01-03T09:30:00Z',
  },
  {
    label: 'dd MMM yyyy',
    value: 'dd MMM yyyy',
    example: '12 Jan 2023',
  },
  {
    label: 'dd.mm.yyyy',
    value: 'dd.mm.yyyy',
    example: '15.04.2024',
  },
  {
    label: 'mm.dd.yyyy',
    value: 'mm.dd.yyyy',
    example: '04.15.2024',
  },
  {
    label: 'yyyy.mm.dd',
    value: 'yyyy.mm.dd',
    example: '2024.04.15',
  }
]

const ERROR_MESSAGE = 'Please Select Valid Date Format'

const DateFormatDialog = ({
  data: { display, params },
  setData,
  extractType,
  entityIndex,
}) => {
  useEffect(() => {
    setError(false)
  }, [params])
  const { info, dialogContent } = useStyles()
  const [error, setError] = useState(false)
  const dispatch = useIEDispatch()
  const onDateFormatChange = ({ value }) => {
    setData({
      display,
      params: {
        ...params,
        row: {
          ...params.row,
          date_format: value,
        },
      },
    })
    setError(false)
  }
  const onCloseHandler = (flag) => {
    if (flag) {
      if (!params.row.date_format) {
        setError(true);
        return
      }
      dispatch(
        updateCustomErpFieldConfig({
          rowIndex: params.rowIndex,
          fieldData: { date_format: params.row.date_format },
          entityIndex,
          extractType,
        })
      )
    }
    return setData({ params: null, display: false })
  }
  return (
    <Dialog
      cancelText="Cancel"
      confirmText="Add"
      header="Add Date Format"
      open={display}
      width="705px"
      height="516px"
      onClose={onCloseHandler}
    >
      <Box className={dialogContent}>
        <div className={info}>
          <div className="fn-left">
            <IconInfo />
          </div>
          <div className="fn-right">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              {dateFormatItems.map(({ label, example }) => {
                return (
                  <Typography
                    key={label}
                    variant="caption"
                    style={{ fontSize: '1rem' }}
                  >
                    If Date: {example} <IconArrow /> Date Mask: {label}
                  </Typography>
                )
              })}
            </div>
          </div>
        </div>
        <br />
        <Typography>Date Format</Typography>
        <EBAutoComplete
          value={{label: params?.row.date_format || ''}}
          onChange={(e, value) => onDateFormatChange(value)}
          options={dateFormatItems}
          getOptionLabel={(option) => option.label}
          helperText={error ? ERROR_MESSAGE : ''}
          error={error}
          height={44}
          size="small"
          onBlur={() => !params?.row.date_format && setError(true)}
          disableClearable={true}
          width={350}
          forcePopupIcon={true}
          groupBy={() => "Please Select Mandatory Fields"}
        />
      </Box>
    </Dialog>
  )
}

export default DateFormatDialog
