import { gql } from '@apollo/client'

export const SAVE_GROUPING = gql`
  mutation saveGrouping($grouping: SaveGroupingInput! $dataIngestionId: String) {
    saveGrouping(grouping: $grouping, dataIngestionId: $dataIngestionId) {
      id
      dataIngestionGrouperId
      success
      message
    }
  }
`