import { gql } from '@apollo/client'

export const BEGIN_DATA_INGESTION = gql`
  mutation beginDataIngestion($dataIngestionId: String! $isCustomMapped: Boolean!) {
    beginDataIngestion(dataIngestionId: $dataIngestionId isCustomMapped: $isCustomMapped) {
      id
      initiatedAt
      completeAt
      adfPipelineId
      status
    }
  }
`
