/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { ReactComponent as IconClose } from '../../../assets/IconClose.svg'
import { ReactComponent as IconWarn } from '../../../assets/IconWarn.svg'
import { ReactComponent as IconInfo } from '../../../assets/IconInfo.svg'
import { ReactComponent as IconCredit } from '../../../assets/IconCredit.svg'
import { ReactComponent as IconDebit } from '../../../assets/IconDebit.svg'
import { ReactComponent as IconDelete } from '../../../assets/IconDelete.svg'
import calculationFunctions, {
  ADD,
  CONCATENATE,
  CONCATENATE_DATE_TIME,
  DATE_MASK,
  DEBIT_CREDIT_INDICATOR,
  DIVIDE,
  MULTIPLY,
  SPLIT,
  SUBTRACT,
  JOURNAL_LINE_NUMBER,
  MULTIPLE_CALCULATIONS,
  EXTRACT_TO_SPLIT,
  FREE_TEXT,
  AMOUNT_CALCULATION,
  FILL_DOWN,
  CONDITIONAL_SELECT,
  CONDITIONAL_COLUMN,
  MATHS_WITH_BRACKET,
} from '../utils/calculationFunctions'
import {
  changeExpressions,
  clearExpressions,
  fetchStart,
  fetchStop,
  updateExpressionError,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import DateMaskTooltip from './DateMaskTooltip'
import { format } from 'date-fns'
import CustomScrollbars from 'apps/integration-engine/src/components/CustomScrollbars'
import JLNExample from './JLNExample'
import RangeBasedExample from './RangeBasedExample'
import { useMsal } from '@azure/msal-react'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import axios from 'axios'
import { EBAutoComplete } from '@engine-b/shared/components'
import {
  dateMaskValidation,
  dateValidation,
  timeMaskValidation,
} from '../utils/dateMaskValidation'
import FillDownExample from './FillDownExample'
import ConditionalColumn from './ConditionalColumn'
import MathsWithBracket from './MathsWithBracket'
import { transformExpressions } from '../utils/transformExpression'
import CustomLabel from './CustomLabel'

const useStyles = makeStyles((theme) => ({
  calculatedFieldFull: {
    '& .function-alert': {
      display: 'flex',
      background: '#f8fafb',
      width: '100%',
      height: 'auto',
      padding: '15px',
      borderRadius: '8px',
      borderLeft: '8px solid #344B5F',
      margin: '25px 0',
      '& .fn-right': {
        color: '#22353F',
        marginLeft: '10px',
        '& span': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0px',
        },
      },
    },
    '& .function-alert-warning': {
      display: 'flex',
      background: '#f8fafb',
      width: '100%',
      height: 'auto',
      padding: '15px',
      borderRadius: '8px',
      borderLeft: '8px solid #ffd54e',
      margin: '25px 0',
      '& .fn-right': {
        color: '#22353F',
        marginLeft: '10px',
        '& span': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0px',
        },
      },
    },
    '& .MuiDialog-paper': {
      height: '-webkit-fill-available',
      minHeight: '350px',
      borderRadius: '10px',
      transition: 'width .5s, height .5s',
      '& .MuiDialogTitle-root': {
        padding: '30px 35px',
        '& span': {
          font: 'normal normal bold 24px/28px Arial',
          letterSpacing: '0.24px',
          color: '#22353F',
        },
        '& h2.MuiTypography-root.MuiTypography-h6': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
      '& .MuiButton': {
        marginLeft: '30px',
        width: '140px',
        height: '44px',
        marginTop: '52px',
      },
      '& .addMoreButton': {
        width: '140px',
        height: '44px',
        marginTop: '52px',
      },
      '& .MuiDialogContent-root': {
        padding: '0',
        overflowY: 'auto',
        '& .content-wrapper': {
          '& .MuiFormGroup-root.debit-credit-indicator, .amount-calculation': {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-start',
            '& .MuiFormControlLabel-label': {
              fontWeight: 'bold',
              color: '#22353F',
            },
          },
          '& .MuiFormGroup-root.concatenate-date-time': {
            flexDirection: 'row',
            '& .MuiFormControlLabel-label': {
              fontWeight: 'bold',
              color: '#22353F',
            },
          },
          padding: '0 35px 0 35px',
        },
        '& .content-wrapper:last-child': {
          marginTop: '23px',
          '& .erp-field': {
            width: '100%',
            display: 'flex',
            alignItems: 'end',
          },
          '& .cdm-output': {
            width: '100%',
            height: 'auto',
            minHeight: '93px',
            background: '#F0FBFA 0% 0% no-repeat padding-box',
            borderRadius: '8px',
            padding: '20px 26px',
            margin: '30px 0 22px',
            '& p:first-child': {
              font: 'normal normal bold 18px/20px Arial',
              letterSpacing: '0px',
              color: '#00B2A9',
              marginBottom: '13px',
            },
            '& label, p:not:first-child': {
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              font: 'normal normal normal 20px/20px Arial',
            },
            '& span': {
              color: '#00B2A9',
              fontSize: '19px',
              margin: '0 5px',
            },
            '& .MuiTypography-subtitle1': {
              textTransform: 'capitalize',
            },
          },
          '& .separator .MuiOutlinedInput-root': {
            width: '280px',
            height: '44px',
            borderRadius: '10px',
          },
        },
      },
    },
    '& .calculated-field-select': {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0',
    },
    '& .MuiDialogActions-root': {
      padding: '8px 35px 30px 8px',
    },
    '& .ErrMsg': {
      position: 'absolute',
      fontSize: '14px',
      color: 'red',
      paddingTop: '4px',
    },
  },
  calculatedField: {
    '& .function-alert': {
      display: 'flex',
      background: '#f8fafb',
      width: '100%',
      height: 'auto',
      padding: '15px',
      borderRadius: '8px',
      borderLeft: '8px solid #344B5F',
      margin: '25px 0',
      '& .fn-right': {
        color: '#22353F',
        marginLeft: '10px',
        '& span': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0px',
        },
      },
    },
    '& .function-alert-warning': {
      display: 'flex',
      background: '#f8fafb',
      width: '100%',
      height: 'auto',
      padding: '15px',
      borderRadius: '8px',
      borderLeft: '8px solid #ffd54e',
      margin: '25px 0',
      '& .fn-right': {
        color: '#22353F',
        marginLeft: '10px',
        '& span': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0px',
        },
      },
    },
    '& .MuiDialog-paper': {
      width: '900px',
      height: '-webkit-fill-available',
      minHeight: '350px',
      maxHeight: '755px',
      borderRadius: '10px',
      transition: 'width .5s, height .5s',
      '& .MuiDialogTitle-root': {
        padding: '30px 35px',
        '& span': {
          font: 'normal normal bold 24px/28px Arial',
          letterSpacing: '0.24px',
          color: '#22353F',
        },
        '& h2.MuiTypography-root.MuiTypography-h6': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
      '& .MuiButton': {
        marginLeft: '30px',
        width: '140px',
        height: '44px',
        marginTop: '52px',
      },
      '& .MuiDialogContent-root': {
        padding: '0',
        overflowY: 'auto',
        '& .content-wrapper': {
          '& .MuiFormGroup-root.debit-credit-indicator, .amount-calculation': {
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-start',
            '& .MuiFormControlLabel-label': {
              fontWeight: 'bold',
              color: '#22353F',
            },
          },
          '& .MuiFormGroup-root.concatenate-date-time': {
            flexDirection: 'row',
            '& .MuiFormControlLabel-label': {
              fontWeight: 'bold',
              color: '#22353F',
            },
          },
          padding: '0 35px 0 35px',
        },
        '& .content-wrapper:last-child': {
          marginTop: '23px',
          '& .erp-field': {
            width: '100%',
            display: 'flex',
            alignItems: 'end',
          },
          '& .cdm-output': {
            width: '100%',
            height: 'auto',
            minHeight: '93px',
            background: '#F0FBFA 0% 0% no-repeat padding-box',
            borderRadius: '8px',
            padding: '20px 26px',
            margin: '30px 0 22px',
            '& p:first-child': {
              font: 'normal normal bold 18px/20px Arial',
              letterSpacing: '0px',
              color: '#00B2A9',
              marginBottom: '13px',
            },
            '& label, p:not:first-child': {
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              font: 'normal normal normal 20px/20px Arial',
            },
            '& span': {
              color: '#00B2A9',
              fontSize: '19px',
              margin: '0 5px',
            },
            '& .MuiTypography-subtitle1': {
              textTransform: 'capitalize',
            },
          },
          '& .separator .MuiOutlinedInput-root': {
            width: '280px',
            height: '44px',
            borderRadius: '10px',
          },
        },
      },
    },
    '& .calculated-field-select': {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0',
    },
    '& .MuiDialogActions-root': {
      padding: '8px 35px 30px 8px',
    },
    '& .ErrMsg': {
      position: 'absolute',
      fontSize: '14px',
      color: 'red',
      paddingTop: '4px',
    },
  },
  fnSelect: {
    width: '280px',
    height: '44px',
    borderRadius: '10px',
  },
  selectLabel: {
    font: 'normal normal bold 16px/18px Arial',
    letterSpacing: '0px',
    color: '#344B5F',
    marginRight: '15px',
  },
  customSelect: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    width: '280px',
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      height: '44px',
    },
  },
  erpLabel: {
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0px',
    color: '#344B5F',
    marginBottom: '9px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '33px',
  },
  mandate: {
    color: '#37AB3F',
    fontSize: '36px',
    marginLeft: '8px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  example: {
    font: 'normal normal bold 18px/20px Arial',
    letterSpacing: '0px',
    color: '#344B5F',
    marginBottom: '13px',
  },
  credit: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #1A95BB',
    borderRadius: '8px',
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0.35px',
    color: '#1A95BB',
    width: '86px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: '40px',
  },
  debit: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EE8D24',
    borderRadius: '8px',
    font: 'normal normal bold 14px/20px Arial',
    letterSpacing: '0.35px',
    color: '#EE8D24',
    width: '86px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: '40px',
  },
  debitCredit: {
    display: 'flex',
    alignItems: 'end',
    '& .MuiTypography-subtitle1': {
      display: 'flex',
      alignItems: 'end',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: '0 15px',
    },
    '& ul > li:first-child': {
      font: 'normal normal normal 14px/20px Arial',
      letterSpacing: '0px',
      color: '#89A0AC',
    },
  },
  alignVertical: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTable-root': {
      width: '50%',
      '& .MuiTableCell-head': {
        font: 'normal normal normal 16px/18px Arial',
        color: '#00B2A9',
        padding: '16px 16px 16px 0',
      },
      '& .MuiTableCell-body': {
        padding: '16px 16px 16px 35px',
      },
    },
  },
  debitCreditHelper: {
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Arial',
    letterSpacing: '0px',
    color: '#89A0AC',
    marginLeft: '6px',
  },
  dci1: {
    display: 'flex',
    alignItems: 'center',
  },
  dci2: {
    display: 'flex',
  },
  rangeUI: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
    width: '100%',
    '& .MuiInputBase-root': {
      width: '228px',
      height: '44px',
      borderRadius: '10px',
    },
    '& .deleteIcon > svg': {
      width: '40px',
      marginTop: '55px',
      cursor: 'pointer',
    },
  },
  cdmOutputLabel: {
    display: 'inline-block',
  },
  sglan: {
    display: 'flex',
    '& .sglanwrapper': {
      display: 'flex',
      columnGap: '40px',
      marginLeft: '40px',
      textAlign: 'center',
      '& .glinput, .glname, .glaccno': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  options: {
    display: 'flex',
    width: '100%',
    '& > span:first-child': {
      width: '90%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}))

interface CalculationFunction {
  title: string
  name: string
  value: string
  desc: string | Array<string>
  elements: Array<number>
  addMore: boolean
  symbol?: string
}

//  ***** This function is also used in MapperView component *****
export const postMapValidate = async (
  payload,
  dispatch,
  instance,
  inProgress,
  accounts
) => {
  dispatch(fetchStart({}))

  try {
    // bring token for protected resource
    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    const response = await axios.post(
      `${process.env.NX_CUSTOM_MAPPER_API_URL}/post_mapping/validate`,
      payload,
      { headers }
    )
    dispatch(fetchStop({}))
    return {
      status: true,
      data: response.data,
    }
  } catch (error) {
    dispatch(fetchStop({}))
    return {
      status: false,
      data: error?.response?.data?.detail,
    }
  }
}

const CalculatedFieldDialog = ({ record, open, onClose }) => {
  const {
    fnSelect,
    calculatedField,
    calculatedFieldFull,
    selectLabel,
    customSelect,
    erpLabel,
    mandate,
    example,
    credit,
    debit,
    debitCredit,
    alignVertical,
    debitCreditHelper,
    dci1,
    dci2,
    rangeUI,
    cdmOutputLabel,
    sglan,
    options,
  } = useStyles()

  const { id, name, erp_options, mandatory, report_type, data_type } = record
  const cdm_field = name

  // redux state variable
  const customMapper = useIESelector((state) => state.customMapper)
  const { expressions, erpFields: conditionalFields } = useIESelector(
    (state) => state.conditionalColumn
  )
  const draftRun = useIESelector((state) => state.runs.draftRun)

  // local state variable
  const [calculationFn, setCalculationFn] = useState<string>('-1')
  const [selectedCalcFn, setSelectedCalcFn] =
    useState<CalculationFunction>(null)
  const [elementList, setElementList] = useState<Array<number>>([])
  const [erpFields, setErpFields] = useState([])
  const [signFields, setSignFields] = useState([])
  const [expression, setExpression] = useState('')
  const [separator, setSeparator] = useState<string>('')
  const [timeSeparator, setTimeSeparator] = useState<string>('')
  const [characters, setCharacters] = useState<string>('')
  const [extra, setExtra] = useState('')
  const [localErpOptions, setLocalErpOptions] = useState([])
  const [isValidFormat, setIsValidFormat] = useState(true)
  const [isValidTimeFormat, setIsValidTimeFormat] = useState(true)
  const [DCIValue, setDCIValue] = useState('DCI1')
  const [cdmFieldList, setCdmFieldList] = useState<Array<string>>([])
  const [ranges, setRanges] = useState([])
  const { instance, inProgress, accounts } = useMsal()
  const [openingChar, setOpeningChar] = useState<string>('')
  const [closingChar, setClosingChar] = useState<string>('')
  const [openingCharPosition, setOpeningCharPosition] = useState<number>(0)
  const [closingCharPosition, setClosingCharPosition] = useState<number>(0)
  const [insideOutside, setInsideOutside] = useState<string>('')
  const [delimeter, setDelimeter] = useState<string>('-')
  const [freeText, setFreeText] = useState<string>('')
  const [amountCalcValue, setAmountCalcValue] = useState('ABS')
  const [mcbExpression, setMcbExpression] = useState<string>('')
  const [invalidExpressions, setInvalidExpressions] = useState<boolean>(false)
  const [moveDropDown, setMoveDropDown] = useState<boolean>(false)
  const [splitSide, setSplitSide] = useState<string>('Left')

  const dispatch = useIEDispatch()

  const isValidDateFormat = () => {
    if (selectedCalcFn?.name === DATE_MASK) {
      const validFormat = dateMaskValidation(separator)
      setIsValidFormat(validFormat)
      return validFormat
    } else if (selectedCalcFn?.name === CONCATENATE_DATE_TIME) {
      const validFormat = dateValidation(separator)
      setIsValidFormat(validFormat)
      return validFormat
    } else if (selectedCalcFn !== undefined) {
      setIsValidFormat(false)
      return true
    }
  }

  const disableApply = () => {
    /**
     * disable apply button in below cases
     * - if date format is invalid
     * - if selected function is date-mask and format field is empty
     */
    if (!dateMaskValidation(separator)) return true

    if (selectedCalcFn?.name === DATE_MASK && separator.length === 0)
      return true

    /**
     * Disable apply button if date-time concat is enabled and date format is invalid
     */
    if (
      selectedCalcFn?.name === CONCATENATE_DATE_TIME &&
      (!dateMaskValidation(separator) ||
        (separator.length === 0 && !timeMaskValidation(timeSeparator)) ||
        timeSeparator.length === 0)
    )
      return true

    /**
     * if ERP field dropdowns has empty selection disable apply button
     */
    const isAllValidFields =
      erpFields.length > 0
        ? erpFields.reduce((acc, item) => acc && !!item, true)
        : false

    if (
      ![FREE_TEXT, CONDITIONAL_COLUMN].includes(selectedCalcFn?.name) &&
      (erpFields?.length !== elementList?.length || !isAllValidFields)
    )
      return true

    /**
     * if Sign field dropdowns has empty selection disable apply button
     */
    const isAllValidSignFields =
      signFields?.length > 0
        ? signFields.reduce((acc, item) => acc && !!item, true)
        : false

    if (
      selectedCalcFn?.name === MULTIPLE_CALCULATIONS &&
      (signFields?.length !== elementList?.length - 1 || !isAllValidSignFields)
    )
      return true

    /**
     * if selected function is SPLIT and characters are not entered disable apply button
     */
    if (selectedCalcFn?.name === SPLIT && characters.length < 1) return true

    /**
     * if selected function is EXTRACT_TO_SPLIT and all fields are not entered disable apply button
     */
    if (
      selectedCalcFn?.name === EXTRACT_TO_SPLIT &&
      (openingChar?.length === 0 || insideOutside?.length === 0)
    )
      return true

    /**
     * If ranges are not filled disable apply button
     */
    const result = ranges.reduce(
      (acc, range) => range.start && range.end && range.negatives,
      [true]
    )
    if (selectedCalcFn?.name === MULTIPLE_CALCULATIONS && expression === '')
      return true

    if (!result) return true

    /**
     * If operation type is concatenate and delimeter is empty then disable apply button
     */
    if (selectedCalcFn?.name === CONCATENATE && !delimeter) return true

    /**
     * If operation is Free Text and freeText field is empty disable apply button
     */
    if (selectedCalcFn?.name === FREE_TEXT && freeText === '') return true

    /**
     * If operation is MATHS_WITH_BRACKET and expression is invalid disable apply button
     */
    if (
      selectedCalcFn?.name === MATHS_WITH_BRACKET &&
      (invalidExpressions || mcbExpression === '')
    )
      return true

    /**
     * If operation is CONDITIONAL_COLUMN and expression has invalid values then disable apply button
     *  */
    if (
      selectedCalcFn?.name === CONDITIONAL_COLUMN &&
      (expressions.reduce((acc, exp) => acc || !exp.isValid, false) ||
        !validateBinaryTree(expressions))
    )
      return true

    return false
  }

  const validateBinaryTree = (data) => {
    let result = true
    data.forEach((x, i, records) => {
      if (x.type === 'condition') {
        const count = records.filter((y) => x.id === y.parentNode)?.length
        result = count === 2 && result
      }
    })
    return result
  }

  useEffect(() => {
    const data = calculationFunctions.find(
      (item) => item.value === calculationFn
    )
    setElementList(data?.elements)
    setSelectedCalcFn(data)
    setLocalErpOptions(erp_options)
  }, [calculationFn])

  const availableOptions = [
    { title: 'Add', value: '+' },
    { title: 'Subtract', value: '-' },
    { title: 'Multiply', value: '*' },
    { title: 'Divide', value: '/' },
  ]

  const onOperationChange = (e: any, value: any, reason: string) => {
    if (reason === 'clear') {
      setTimeout(() => {
        setMoveDropDown(!moveDropDown)
      }, 500)
    }
    setCalculationFn(value ? value.value.toString() : '')
    // Reset all selections on operation change
    setErpFields([])
    setSignFields([])
    setSeparator('')
    setTimeSeparator('')
    setCharacters('')
    setDCIValue('DCI1')
    setAmountCalcValue('ABS')
    setExtra('')
    setOpeningChar('')
    setClosingChar('')
    setOpeningCharPosition(0)
    setClosingCharPosition(0)
    setInsideOutside('')
    setDelimeter('-')
    dispatch(clearExpressions())
  }

  const onAddHandler = () => {
    setElementList([...elementList, 1])
  }

  // Runs for MC and MCB
  const onDeletehandler = (index) => {
    const copy = [...erpFields]
    copy.splice(index, 1)
    setErpFields(copy)
    const updatedElementList = elementList.filter((_, i) => i !== index)
    setElementList(updatedElementList)
  }

  const onErpChangeHandler = (index: number) => (e: any, value, reason) => {
    let copy = [...erpFields]
    if (reason === 'clear') {
      copy = copy.map((item, i) => (index !== i ? item : undefined))
      setErpFields(copy)
    } else {
      copy[index] = { order: index + 1, ...value }
      setErpFields([...copy])
    }
  }

  const onSignChangeHandler = (index: number) => (e: any, value, reason) => {
    let copy = [...signFields]
    if (reason === 'clear') {
      copy = copy.map((item, i) => (index !== i ? item : undefined))
      setSignFields(copy)
    } else {
      copy[index] = { order: index + 1, ...value }
      setSignFields([...copy])
    }
  }

  const dateSampleDisplay = (userInput: string) => {
    try {
      if (userInput === '') return ''
      const regex = /T/
      userInput = regex.exec(userInput)
        ? userInput.replace('T', "'T'")
        : userInput
      const result = format(new Date(), userInput || 'MM/dd/yyyy, HH:mm:ss')
      return result
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const onCloseHandler = async (value: boolean) => {
    if (value) {
      const wrappedNum = mcbExpression.match(/\d+/g)?.map((n) => `<${n}>`)
      const wrappedStr = mcbExpression.replace(/\d+/g, () => wrappedNum.shift())
      const fileResp = {
        erp_fields: erpFields,
        cdm_field,
        map_type: calculationFn === DATE_MASK ? 'date-mask' : 'calculated',
        file_name: erpFields[0]?.file_name || erp_options[1]?.file_name,
        report_type,
        data_type,
        mandatory,
        erp_Id: draftRun.erpId,
        extract_type: draftRun.extractType,
        ...(selectedCalcFn?.name === MULTIPLE_CALCULATIONS && {
          sign_fields: signFields,
          expression: expression,
        }),
        ...(selectedCalcFn?.name === MATHS_WITH_BRACKET && {
          expression: wrappedStr,
        }),
        ...(selectedCalcFn?.name === CONCATENATE && { separator: delimeter }),
      }

      if (calculationFn.match('DCI')) {
        fileResp['operation'] = DCIValue
      } else if (calculationFn === 'AC') {
        fileResp['operation'] = amountCalcValue
      } else {
        fileResp['operation'] = calculationFn
      }

      const erpResp = {
        [id]: {
          data_type: erpFields[0]?.data_type || erp_options[1]?.data_type,
          field_name: 'Calculated Field',
          file_name: erpFields[0]?.file_name || erp_options[1]?.file_name,
          null_check_comment: erpFields
            .filter((item) => !item.valid)
            .map((rec) => rec.null_check_comment),
          null_check_data: '',
          null_check_valid: true,
          present: true,
          type_check_comment: erpFields
            .filter((item) => !item.valid)
            .map((rec) => rec.type_check_comment),
          type_check_data: [],
          type_check_valid: true,
          consider_valid: true,
          valid: true,
          mandatory,
          post_validate_data: '',
        },
      }
      if (separator.length > 0) fileResp['date_format'] = separator.trim()
      if (separator.length > 0) fileResp['date_format_text'] = separator.trim()
      if (timeSeparator.length > 0)
        fileResp['time_format'] = timeSeparator.trim()
      if (timeSeparator.length > 0)
        fileResp['time_format_text'] = timeSeparator.trim()
      if (characters.length > 0) fileResp['characters'] = characters
      if (extra) fileResp['extra'] = extra
      if (ranges.length > 0) fileResp['rangeDetails'] = ranges
      if (selectedCalcFn.name === MULTIPLE_CALCULATIONS)
        fileResp['sign_fields'] = signFields
      if (selectedCalcFn.name === EXTRACT_TO_SPLIT)
        fileResp['extract_split_fields'] = {
          opening_char: openingChar,
          opening_char_number: openingCharPosition,
          closing_char: closingChar,
          closing_char_number: closingCharPosition,
          inside_outside: insideOutside,
        }
      if (selectedCalcFn.name === FREE_TEXT) fileResp['free_text'] = freeText
      if (selectedCalcFn.name === CONDITIONAL_COLUMN) {
        const expressionInfo = expressions.map((exp) => ({ ...exp, error: '' }))
        fileResp['expressions'] = expressionInfo
        fileResp['erp_fields'] = conditionalFields
        fileResp['conditions'] = transformExpressions({ expressions })
      }
      if (selectedCalcFn.name === SPLIT) {
        fileResp['split_side'] = splitSide
      }

      const file = customMapper.files.find(
        (file) => file.reportType === report_type
      )
      const { status, data } = await postMapValidate(
        {
          ...fileResp,
          records: file?.records,
        },
        dispatch,
        instance,
        inProgress,
        accounts
      )

      if (selectedCalcFn.name === CONDITIONAL_COLUMN) {
        dispatch(updateExpressionError(data))
        if (!status) return false
      }

      const updatedErpFields = fileResp.erp_fields.map((field) => ({
        ...field,
        valid: status,
      }))

      fileResp.erp_fields = updatedErpFields
      erpResp[id]['valid'] = status
      erpResp[id]['post_validate_data'] = status
        ? ''
        : typeof data === 'string'
        ? data
        : data[0].error
      onClose(fileResp, erpResp)
    } else {
      onClose(null, null)
    }
    setErpFields([])
    setSignFields([])
    setSeparator('')
    setTimeSeparator('')
    setCharacters('')
    setDCIValue('DCI1')
    setExtra('')
    setCalculationFn('-1')
    setDelimeter('-')
    setFreeText('')
    setAmountCalcValue('ABS')
    setMcbExpression('')
    setInvalidExpressions(false)
    setSplitSide('Left')
    dispatch(clearExpressions())
  }

  const onSplitCharactersChange = (e) => {
    e.target.value === '' || +e.target.value > 0
      ? setCharacters(e.target.value)
      : setCharacters('')
  }

  const handleOperationChange = (e) => {
    const { name, value } = e.target

    name === 'amount-calculation'
      ? setAmountCalcValue(value)
      : setDCIValue(value)
    if (['DCI1', 'ABS'].indexOf(value) > -1) {
      setElementList([1])
    } else {
      setElementList([1, 2])
    }
    setErpFields([])
  }

  const onRemainderChange = (e: any, value, reason: string) => {
    if (reason === 'clear') {
      setExtra('')
    } else {
      setExtra(value)
    }
  }

  useEffect(() => {
    if (timeSeparator) {
      const validTimeFormat = timeMaskValidation(timeSeparator)
      setIsValidTimeFormat(validTimeFormat)
    } else {
      setIsValidTimeFormat(true)
    }
  }, [timeSeparator])

  useEffect(() => {
    if (separator) isValidDateFormat()
    else setIsValidFormat(true)
  }, [separator])

  useEffect(() => {
    if (selectedCalcFn?.name === MULTIPLE_CALCULATIONS) {
      const data = erpFields.map((v, i) => {
        return v
          ? getFieldName(v?.order, i) +
              ' ' +
              (signFields[i] ? signFields[i]?.value : '')
          : ''
      })
      const finalData = data.join(' ').trim()
      const wrappedNum = finalData.match(/\d+/g)?.map((n) => `<${n}>`)
      const wrappedStr = finalData.replace(/\d+/g, () => wrappedNum.shift())
      setExpression(wrappedStr)
    }
  }, [erpFields, signFields])

  useEffect(() => {
    const record = customMapper.manualSelections.find(
      (cs) => cs.cdm_field === cdm_field && cs.report_type === report_type
    )
    if (
      record !== undefined &&
      open &&
      (record.map_type === 'calculated' || record.map_type === 'date-mask')
    ) {
      if (record.operation !== 'CC') {
        setErpFields(record.erp_fields)
      }
      setSignFields(record?.sign_fields)
      if (record.operation.match('DCI')) {
        setCalculationFn('DCI')
        setDCIValue(record.operation)
      } else if (['ABS', 'DC', 'RBA'].indexOf(record.operation) > -1) {
        setCalculationFn('AC')
        setAmountCalcValue(record.operation)
      } else {
        setCalculationFn(record.operation)
      }

      if (record.extra) setExtra(record.extra)
      if (record.characters) setCharacters(record.characters)
      if (record.operation === DATE_MASK) setSeparator(record.date_format_text)
      if (record.operation === 'SGLAN') {
        setOpeningChar(record?.extract_split_fields?.opening_char)
        setClosingChar(record?.extract_split_fields?.closing_char)
        setOpeningCharPosition(record?.extract_split_fields?.opening_char_number)
        setClosingCharPosition(record?.extract_split_fields?.closing_char_number)
        setInsideOutside(record?.extract_split_fields?.inside_outside)
      }
      if (record.operation === 'CONCAT') setDelimeter(record.separator)
      if (record.operation === 'FT') setFreeText(record.free_text)
      if (record.operation === 'MC') setExpression(record.expression)
      if (record.operation === 'MCB')
        setMcbExpression(record.expression.replace(/<|>/g, ''))
      if (record.operation === 'DCONCAT') {
        setSeparator(record.date_format_text)
        setTimeSeparator(record.time_format_text)
      }

      if (record.operation === 'SPLIT') {
        setSplitSide(record.split_side)
      }

      if (record.operation === 'CC') {
        dispatch(changeExpressions(record))
      }
      // This timeout method prevents erp options to be used more than once except Conditional column
      setTimeout(() => {
        setElementList([...record.erp_fields.map((_ef, i) => i)])
        if (record.operation !== 'CC') {
          const leo = erp_options.filter(
            (item) =>
              record.erp_fields.findIndex(
                (ef) => ef?.field_name === item?.field_name
              ) === -1
          )
          setLocalErpOptions(leo)
        }

        if (record.rangeDetails) setRanges([...record.rangeDetails])
      }, 0)
    }

    if (cdm_field) {
      const cdm_field_list = customMapper.files
        .find((file) => file.reportType === report_type)
        .cdmDetails.mapping.map((item) => item.name)
        .filter((item) => item !== cdm_field)

      setCdmFieldList(cdm_field_list)
    }
  }, [open])

  useEffect(() => {
    if (erpFields.length > 0) {
      // avoid reselection of erp fields and remove option once it is being selected
      const copy1 = erp_options.filter((item) => {
        return (
          erpFields.findIndex((leo) => leo?.field_name === item?.field_name) ===
          -1
        )
      })

      setLocalErpOptions(copy1)
    } else {
      setLocalErpOptions(erp_options)
    }
  }, [erpFields, extra])

  const SplittingFn = () => (
    <>
      <Grid item>
        <InputLabel
          className={erpLabel}
          style={{ margin: '2px 0 11px 0', lineHeight: '28px' }}
        >
          Remainder
        </InputLabel>
        <EBAutoComplete
          options={cdmFieldList}
          // getOptionLabel={(option) => option?.field_name}
          renderOption={(option) => <span title={option}>{option}</span>}
          size="small"
          value={extra}
          onChange={onRemainderChange}
          placeholder="Select CDM Field"
          width={280}
          height={44}
          borderRadius={10}
        />
      </Grid>
      <Grid item container style={{ columnGap: '1.5rem' }}>
        <Grid item>
          <CustomLabel title="Split Character" />
          <TextField
            className={customSelect}
            style={{ width: '136px' }}
            variant="outlined"
            placeholder="Split Character"
            type="text"
            value={characters}
            onChange={onSplitCharactersChange}
          />
        </Grid>
        <Grid item>
          <CustomLabel title="Select Split Side" />
          <Select
            variant="outlined"
            name="negatives"
            style={{ width: '120px' }}
            className={fnSelect}
            value={splitSide}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSplitSide(e.target.value)
            }
          >
            <MenuItem value="Left">Left</MenuItem>
            <MenuItem value="Right">Right</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  )

  const DateMaskFn = () => (
    <Grid item>
      <InputLabel className={erpLabel}>
        Enter Date Mask
        <span className={mandate}>
          <sub>*</sub>
        </span>
        <DateMaskTooltip />
      </InputLabel>
      <TextField
        className={customSelect}
        style={{ width: '200px' }}
        variant="outlined"
        value={separator}
        onChange={(e) => setSeparator(e.target.value)}
        error={!isValidFormat}
        helperText={!isValidFormat && 'Invalid Date Format'}
      />
    </Grid>
  )

  const ConcatenateDateTime = () => (
    <>
      <Grid item>
        <InputLabel className={erpLabel}>
          Enter Date Mask
          <span className={mandate}>
            <sub>*</sub>
          </span>
          <DateMaskTooltip />
        </InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '200px' }}
          variant="outlined"
          value={separator}
          onChange={(e) => setSeparator(e.target.value)}
          error={!isValidFormat}
          helperText={!isValidFormat && 'Invalid Date Format'}
        />
      </Grid>
      <Grid item>
        <InputLabel className={erpLabel}>
          Enter Time Mask
          <span className={mandate}>
            <sub>*</sub>
          </span>
          <DateMaskTooltip />
        </InputLabel>
        <TextField
          className={customSelect}
          onChange={(e) => setTimeSeparator(e.target.value)}
          style={{ width: '200px' }}
          variant="outlined"
          value={timeSeparator}
          error={!isValidTimeFormat}
          helperText={!isValidTimeFormat && 'Invalid time Format'}
        />
      </Grid>
    </>
  )

  const MultiCalFn = () => {
    return (
      <>
        {elementList?.map((_ele, i) => {
          return (
            <>
              <ErpFieldDropDown index={i} key={i} />
              {elementList?.length - 1 !== i && (
                <Grid item>
                  <InputLabel className={erpLabel}>
                    Choose Mathematical Function
                    <span className={mandate}>
                      <sub>*</sub>
                    </span>
                  </InputLabel>
                  <EBAutoComplete
                    options={availableOptions}
                    getOptionLabel={({ title }) => title || ''}
                    size="small"
                    value={signFields[i] || null}
                    onChange={onSignChangeHandler(i)}
                    borderRadius={10}
                    width={280}
                    placeholder="Select Mathematical Function"
                  />
                </Grid>
              )}
              {elementList.length > 2 && (
                <Grid item>
                  <Button
                    aria-label="delete"
                    component="span"
                    style={{
                      marginTop: '35px',
                      display: 'flex',
                      width: '44px',
                      justifyContent: 'center',
                    }}
                    onClick={() => onDeletehandler(i)}
                  >
                    <IconDelete style={{ height: '44px', width: '44px' }} />
                  </Button>
                </Grid>
              )}
            </>
          )
        })}
      </>
    )
  }

  const MathsWithBracketFn = () => {
    return (
      <>
        {elementList?.map((_ele, i) => {
          return (
            <Grid container>
              <Grid item>
                <ErpFieldDropDown index={i} key={i} />
              </Grid>
              {elementList.length > 2 && (
                <Grid item>
                  <Button
                    key={`btn-${i}`}
                    aria-label="delete"
                    component="span"
                    style={{
                      marginTop: '35px',
                      display: 'flex',
                      width: '44px',
                      justifyContent: 'center',
                    }}
                    onClick={() => onDeletehandler(i)}
                  >
                    <IconDelete style={{ height: '44px', width: '44px' }} />
                  </Button>
                </Grid>
              )}
            </Grid>
          )
        })}
      </>
    )
  }

  const MathsWithBracketExample = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {cdm_field} = {getParsedMcb()}
        </Typography>
        <Divider style={{ margin: '20px 0' }} />
        <div>
          <Typography className={example}>Example :</Typography>
          <Typography>
            (<strong>1</strong>) Debit = 10
          </Typography>
          <Typography>
            (<strong>2</strong>) Credit = 20
          </Typography>
          <Typography>
            (<strong>3</strong>) Opening Balance = 30
          </Typography>
          <Typography>
            Expression: <strong>2 * (3 - 1)</strong>
          </Typography>
          <Typography variant="subtitle1">
            {cdm_field}: <strong>400</strong>
          </Typography>
        </div>
      </>
    )
  }

  const AddMoreButton = () => (
    <Button
      variant="contained"
      color="secondary"
      className="MuiButton"
      onClick={onAddHandler}
    >
      <AddIcon style={{ fontWeight: 'bold' }} /> Add More
    </Button>
  )

  useEffect(() => {
    if (DCIValue === 'DCI3' || amountCalcValue === 'RBA') {
      setRanges([
        {
          start: '',
          end: '',
          negatives: '',
        },
      ])
    } else {
      setRanges([])
    }
  }, [DCIValue, amountCalcValue])

  const addRangeHandler = () => {
    const copy = [...ranges]
    copy.push({ start: '', end: '', negatives: '' })
    setRanges([...copy])
  }

  const onRangeDeleteHandler = (index: number) => {
    const copy = [...ranges]
    copy.splice(index, 1)
    setRanges([...copy])
  }

  const onRangeHandler = (e: any, index: number) => {
    const newRanges = ranges.map((range) => ({ ...range }))
    newRanges[index][e.target.name] =
      e.target.type === 'number' ? Math.abs(e.target.value) : e.target.value
    setRanges([...newRanges])
  }

  const AddRangeButton = () => (
    <Button
      variant="contained"
      color="secondary"
      className="MuiButton"
      onClick={addRangeHandler}
    >
      <AddIcon style={{ fontWeight: 'bold' }} /> Add Range
    </Button>
  )

  const RangesUI = (index) => (
    <Grid container spacing={3} className={rangeUI} key={index}>
      <Grid item className="input_group">
        <InputLabel className={erpLabel}>
          Start Range
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <TextField
          variant="outlined"
          type="number"
          name="start"
          value={ranges[index].start}
          onChange={(e) => onRangeHandler(e, index)}
        />
      </Grid>
      <Grid item className="input_group">
        <InputLabel className={erpLabel}>
          End Range
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <TextField
          variant="outlined"
          type="number"
          name="end"
          value={ranges[index].end}
          onChange={(e) => onRangeHandler(e, index)}
        />
      </Grid>
      <Grid item className="input_group">
        <InputLabel className={erpLabel}>
          Negative Value
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <Select
          variant="outlined"
          name="negatives"
          className={fnSelect}
          value={ranges[index].negatives}
          onChange={(e) => onRangeHandler(e, index)}
        >
          <MenuItem disabled={true} value={''}>
            Select Calculation Function
          </MenuItem>
          <MenuItem value="Credit">Credit</MenuItem>
          <MenuItem value="Debit">Debit</MenuItem>
        </Select>
      </Grid>
      {ranges.length > 1 && (
        <div className="deleteIcon">
          <IconDelete onClick={() => onRangeDeleteHandler(index)} />
        </div>
      )}
    </Grid>
  )

  const getCommon = (arr1, arr2) => {
    const common = []
    for (let i = 0; i < arr1.length; ++i) {
      for (let j = 0; j < arr2.length; ++j) {
        if (arr1[i] == arr2[j]) {
          common.push(arr1[i])
        }
      }
    }
    return common
  }

  const getFieldName = (ef, i) => {
    const index = signFields
      ?.map((v) => v?.value)
      ?.reduce((acc, cur, i) => {
        ;(cur === '*' || cur === '/') && acc.push(i)
        return acc
      }, [])

    const commonElement = getCommon(
      index,
      index.map((v) => v + 1)
    )

    let startVal, endVal
    if (
      commonElement.includes(i) ||
      signFields?.map((v) => v?.value)?.every((v) => v === '*' || v === '/')
    ) {
      startVal = ef
    } else if (index.includes(i) && !index.map((v) => v + 1).includes(i)) {
      startVal = '(' + ef
    } else if (!index.includes(i) && !index.map((v) => v + 1).includes(i)) {
      startVal = ef
    } else {
      startVal = ''
    }

    if (index.map((v) => v + 1).includes(i)) {
      endVal = ef + ')'
    } else {
      endVal = ''
    }
    return startVal.toString() || endVal.toString()
  }

  const getParsedMcb = () => {
    return mcbExpression.replace(/(\d+)/g, function (match) {
      const index = parseInt(match) - 1
      return erpFields[index] ? erpFields[index].field_name : match
    })
  }

  const Output = () => (
    <div className="cdm-output">
      {selectedCalcFn?.name === JOURNAL_LINE_NUMBER ? (
        <Typography>Input :</Typography>
      ) : (
        <Typography>CDM Output :</Typography>
      )}
      {((selectedCalcFn?.name === AMOUNT_CALCULATION &&
        amountCalcValue === 'ABS') ||
        (selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
          DCIValue === 'DCI1')) && (
        <>
          {selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR ? (
            <Typography variant="subtitle1" className={dci1}>
              {cdm_field} = <span>-</span> {erpFields[0]?.field_name} =
              <div className={credit} style={{ marginLeft: '6px' }}>
                <IconCredit /> Credit
              </div>
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              {cdm_field} = <span>-</span> {erpFields[0]?.field_name} =
              <span>+</span>
              {erpFields[0]?.field_name}
            </Typography>
          )}

          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <div className={alignVertical}>
            <Typography variant="subtitle1">
              {cdm_field} = <span>-</span>5888 = <span>+</span>5888
            </Typography>
            <div className={credit}>
              <IconCredit /> Credit
            </div>
          </div>
        </>
      )}
      {[ADD, SUBTRACT, MULTIPLY, DIVIDE, CONCATENATE].includes(
        selectedCalcFn?.name
      ) && (
        <>
          <Typography>
            {cdm_field} =
            <>
              {erpFields.map((ef, i) => {
                return (
                  <label key={i}>
                    {erpFields.length - 1 === i ? (
                      ef?.field_name
                    ) : (
                      <>
                        {ef ? (
                          <>
                            {ef?.field_name}
                            <span>
                              {selectedCalcFn?.name === CONCATENATE ? (
                                <span style={{ fontSize: '20px' }}>
                                  {delimeter}
                                </span>
                              ) : (
                                selectedCalcFn.symbol
                              )}
                            </span>
                          </>
                        ) : null}
                      </>
                    )}
                  </label>
                )
              })}
            </>
          </Typography>
          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <Typography>
            {cdm_field} = 125
            <span>
              {selectedCalcFn?.name === CONCATENATE ? (
                <MinimizeIcon />
              ) : (
                selectedCalcFn.symbol
              )}
            </span>
            13
          </Typography>
        </>
      )}
      {selectedCalcFn?.name === SPLIT && (
        <>
          <Typography>
            <label>
              {cdm_field} :
              {`${erpFields[0]?.field_name} = ${characters} Character`}
            </label>
            <label></label>
          </Typography>

          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <div className={debitCredit}>
            <Typography variant="subtitle1">
              GL Account Number :
              <ul>
                <li>Code</li>
                <li>10010-44532-USP</li>
              </ul>
              <ul>
                <li>glAccount</li>
                <li>10010</li>
              </ul>
            </Typography>
          </div>
        </>
      )}
      {selectedCalcFn?.name === DATE_MASK && (
        <>
          <Typography variant="subtitle1">
            {cdm_field} = {dateSampleDisplay(separator)}
          </Typography>

          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <div className={alignVertical}>
            <Typography variant="subtitle1">
              Effective Date = 15/12/2021; 12:55:32.153
            </Typography>
          </div>
        </>
      )}
      {selectedCalcFn?.name === CONCATENATE_DATE_TIME && (
        <>
          <Typography variant="subtitle1">
            {cdm_field} = {dateSampleDisplay(separator)}{' '}
            {dateSampleDisplay(timeSeparator)}
          </Typography>

          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <div className={alignVertical}>
            <Typography variant="subtitle1">
              Effective Date = 15/12/2021; 12:55:32.153
            </Typography>
          </div>
        </>
      )}
      {((selectedCalcFn?.name === AMOUNT_CALCULATION &&
        amountCalcValue === 'DC') ||
        (selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
          DCIValue === 'DCI2')) && (
        <>
          {selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR ? (
            <Typography variant="subtitle1" className={dci2}>
              {cdm_field} =
              <div>
                <div className={alignVertical}>
                  <div className={debit} style={{ margin: 'auto 10px' }}>
                    <IconDebit /> Debit
                  </div>
                  if 'Debit' field has value
                </div>
                <div className={alignVertical} style={{ marginTop: '14px' }}>
                  <div className={credit} style={{ margin: 'auto 10px' }}>
                    <IconCredit /> Credit
                  </div>
                  if 'Credit' field has value
                </div>
              </div>
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              {cdm_field} = {erpFields[0]?.field_name}
              <span>{erpFields[0] && '+'}</span> {erpFields[1]?.field_name}
            </Typography>
          )}

          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <div className={debitCredit}>
            <Typography variant="subtitle1">
              {cdm_field} =
              <ul>
                <li>Debit</li>
                <li>5888</li>
              </ul>
              <span>+</span>
              <ul>
                <li>Credit</li>
                <li>0</li>
              </ul>
            </Typography>
            <div className={debit}>
              <IconDebit /> Debit
            </div>
          </div>
        </>
      )}
      {selectedCalcFn?.name === JOURNAL_LINE_NUMBER && (
        <JLNExample
          field_name={erpFields[0]?.field_name}
          cdm_field={cdm_field}
          example_class={example}
          align_vertical={alignVertical}
        />
      )}
      {selectedCalcFn?.name === FILL_DOWN && (
        <FillDownExample
          field_name={erpFields[0]?.field_name}
          cdm_field={cdm_field}
          example_class={example}
          align_vertical={alignVertical}
        />
      )}
      {selectedCalcFn?.name === MULTIPLE_CALCULATIONS && (
        <>
          <Typography>
            {cdm_field} =
            <>
              {erpFields.map((ef, i) => {
                return (
                  <label key={i} className={cdmOutputLabel}>
                    {
                      <>
                        {ef ? (
                          <>
                            {getFieldName(ef?.field_name, i)}
                            <span>{signFields[i]?.value}</span>
                          </>
                        ) : null}
                      </>
                    }
                  </label>
                )
              })}
            </>
          </Typography>
          <Divider style={{ margin: '20px 0' }} />
          <Typography className={example}>Example :</Typography>
          <Typography>
            {cdm_field} =
            <>
              {erpFields.map((ef, i) => {
                const exampleVal = [125, 13, 69, 103]
                return (
                  <label key={i}>
                    {
                      <>
                        {ef ? (
                          <>
                            {/* {exampleVal[i]} */}
                            {getFieldName(exampleVal[i % 4], i)}
                            <span>{signFields[i]?.value}</span>
                          </>
                        ) : null}
                      </>
                    }
                  </label>
                )
              })}
            </>
          </Typography>
        </>
      )}
      {selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
        DCIValue === 'DCI3' && <RangeBasedExample cdm_field={cdm_field} />}
      {selectedCalcFn?.name === EXTRACT_TO_SPLIT && (
        <>
          <Typography variant="subtitle1">
            {cdm_field} = {erpFields[0]?.field_name}
          </Typography>
          <Typography>Opening character = {openingChar}</Typography>
          <Typography>Opening character = {closingChar}</Typography>

          <Divider style={{ margin: '20px 0' }} />
          <div className={sglan}>
            <Typography className={example}>Example :</Typography>
            <div className="sglanwrapper">
              <div className="glinput">
                <span>Input ERP field</span>
                <strong>
                  Account Paybles {openingChar} 800 {closingChar}
                </strong>
              </div>
              <div className="glname">
                <span>GlAccountName</span>
                <strong>Account Paybles (outside)</strong>
              </div>
              <div className="glaccno">
                <span>GlAccountNumber</span>
                <strong>800 (inside)</strong>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedCalcFn?.name === MATHS_WITH_BRACKET && MathsWithBracketExample()}
    </div>
  )

  const ErpFieldDropDown = ({ index }) => {
    return (
      <Grid item key={index}>
        <InputLabel className={erpLabel}>
          {selectedCalcFn?.name === MATHS_WITH_BRACKET && `(${index + 1})`}{' '}
          Choose ERP Field
          {DCIValue === 'DCI3' || amountCalcValue === 'RBA'
            ? index === 0
              ? ' (Account No.)'
              : ' (Amount)'
            : ' '}
          {selectedCalcFn?.name === CONCATENATE_DATE_TIME
            ? index === 0
              ? ' (Date)'
              : ' (Time)'
            : ' '}
          <span className={mandate}>
            <sub>*</sub>
          </span>
          {(amountCalcValue === 'DC' || DCIValue === 'DCI2') && (
            <span className={debitCreditHelper}>
              {index === 0 ? '(Debit)' : '(Credit)'}
            </span>
          )}
        </InputLabel>

        <EBAutoComplete
          freeSolo={true}
          forcePopupIcon={true}
          options={[
            ...localErpOptions.filter(
              (option) => option?.field_name !== 'Calculated Field'
            ),
          ]}
          getOptionLabel={(option) => option?.field_name}
          renderOption={(option) => (
            <span title={option.field_name}>{option?.field_name}</span>
          )}
          size="small"
          value={erpFields[index] || null}
          onChange={onErpChangeHandler(index)}
          borderRadius={10}
          width={280}
          placeholder="Select ERP Field"
          height={44}
        />
      </Grid>
    )
  }

  const FuncInfo = () => (
    <div className="function-alert">
      <div className="fn-left">
        <IconInfo />
      </div>
      <div className="fn-right">
        <span>{selectedCalcFn?.title}</span>
        <Typography color="inherit" style={{ whiteSpace: 'pre-wrap' }}>
          {selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR
            ? DCIValue === 'DCI1'
              ? selectedCalcFn?.desc[0]
              : DCIValue === 'DCI2'
              ? selectedCalcFn?.desc[1]
              : selectedCalcFn?.desc[2]
            : selectedCalcFn?.name === CONCATENATE
            ? selectedCalcFn?.desc[0]
            : selectedCalcFn?.desc}
          {selectedCalcFn?.name === CONCATENATE && (
            <span style={{ fontSize: '20px' }}>'{delimeter}'</span>
          )}
        </Typography>
      </div>
    </div>
  )

  const FuncWarn = () => (
    <div className="function-alert-warning">
      <div className="fn-left">
        <IconWarn />
      </div>
      <div className="fn-right">
        <span>Warning</span>
        <Typography color="inherit" style={{ whiteSpace: 'pre-wrap' }}>
          Post Mapping Validation not available for Multiple Calculation
        </Typography>
      </div>
    </div>
  )

  const DebitCreditIndicator = () => (
    <div>
      <RadioGroup
        aria-label="debit-credit-indicator"
        name="debit-credit-indicator"
        className="debit-credit-indicator"
        value={DCIValue}
        onChange={handleOperationChange}
      >
        <FormControlLabel
          value="DCI1"
          control={<Radio color="secondary" />}
          label="Single Column"
        />
        <FormControlLabel
          value="DCI2"
          control={<Radio color="secondary" />}
          label="Double Column"
        />
        <FormControlLabel
          value="DCI3"
          control={<Radio color="secondary" />}
          label="Range Based"
        />
      </RadioGroup>
    </div>
  )

  /**
   * Amount calculation
   * Which includes ABS, DC and RBA
   */
  const AmountCalculationOptions = () => (
    <div>
      <RadioGroup
        aria-label="amount-calculation"
        name="amount-calculation"
        className="amount-calculation"
        value={amountCalcValue}
        onChange={handleOperationChange}
      >
        <FormControlLabel
          value="ABS"
          control={<Radio color="secondary" />}
          label="Single Column"
        />
        <FormControlLabel
          value="DC"
          control={<Radio color="secondary" />}
          label="Double Column"
        />
        <FormControlLabel
          value="RBA"
          control={<Radio color="secondary" />}
          label="Range Based Amount"
        />
      </RadioGroup>
    </div>
  )

  const ConcatenateDateFields = () => (
    <div>
      <Grid item>
        <InputLabel className={erpLabel}>
          Separator
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '175px' }}
          variant="outlined"
          placeholder="Separator"
          type="text"
          value={delimeter}
          onChange={(e) => setDelimeter(e.target.value)}
        />
      </Grid>
    </div>
  )

  const OpeningCharPositionHandler = (e) => {
    const value =
      e.target.type === 'number' ? Math.abs(e.target.value) : e.target.value
    setOpeningCharPosition(value)
  }

  const ClosingCharPositionHandler = (e) => {
    const value =
      e.target.type === 'number' ? Math.abs(e.target.value) : e.target.value
    setClosingCharPosition(value)
  }

  useEffect(() => {
    if (openingChar.length > 0 && openingCharPosition === 0)
      setOpeningCharPosition(1)
  }, [openingChar])

  useEffect(() => {
    if (closingChar.length > 0 && closingCharPosition === 0) {
      if (closingChar === openingChar) setClosingCharPosition(2)
      else setClosingCharPosition(1)
    }
  }, [closingChar])

  const SplitToExtractFn = () => (
    <>
      <Grid item>
        <InputLabel className={erpLabel}>
          Start Split Character
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '175px' }}
          variant="outlined"
          placeholder="Opening Character"
          type="text"
          value={openingChar}
          onChange={(e) => setOpeningChar(e.target.value)}
        />

        <InputLabel className={erpLabel}>Occurence</InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '75px' }}
          variant="outlined"
          placeholder="Starting Occurence"
          type="number"
          value={openingCharPosition}
          onChange={(e) => OpeningCharPositionHandler(e)}
        />
      </Grid>
      <Grid item>
        <InputLabel className={erpLabel}>End Split Character</InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '175px' }}
          variant="outlined"
          placeholder="Closing Character"
          type="text"
          value={closingChar}
          onChange={(e) => setClosingChar(e.target.value)}
        />
        <InputLabel className={erpLabel}>Occurence</InputLabel>
        <TextField
          className={customSelect}
          style={{ width: '75px' }}
          variant="outlined"
          placeholder="Closing Occurence"
          type="number"
          value={closingCharPosition}
          onChange={(e) => ClosingCharPositionHandler(e)}
        />
      </Grid>
      <Grid item>
        <InputLabel className={erpLabel}>
          Inside or Outside?
          <span className={mandate}>
            <sub>*</sub>
          </span>
        </InputLabel>
        <Select
          variant="outlined"
          className={fnSelect}
          value={insideOutside}
          onChange={(e) => setInsideOutside(e.target.value.toString())}
        >
          <MenuItem value="-1" disabled>
            Select Option
          </MenuItem>
          <MenuItem value="inside">Inside</MenuItem>
          <MenuItem value="outside">Outside</MenuItem>
        </Select>
      </Grid>
    </>
  )

  const FreeTextFn = () => (
    <Grid item>
      <InputLabel htmlFor="free-text" className={erpLabel}>
        Free Text
        <span className={mandate}>
          <sub>*</sub>
        </span>
      </InputLabel>
      <TextField
        id="free-text"
        className={customSelect}
        style={{ width: '280px' }}
        variant="outlined"
        placeholder="Enter free text"
        type={
          data_type === 'datetime'
            ? 'datetime-local'
            : data_type === 'string'
            ? 'text'
            : 'number'
        }
        value={freeText}
        onChange={(e) => setFreeText(e.target.value)}
      />
    </Grid>
  )

  const processOptions = () => {
    const options = [...calculationFunctions]

    if (cdm_field === 'amountCreditDebitIndicator') {
      return options.filter((cf) => cf.value === 'DCI')
    } else {
      return options.filter((cf) => cf.allowedDataTypes.includes(data_type))
    }
  }

  const isFullScreen = useMediaQuery('(max-width:600px)')
  const isLargeScreen = useMediaQuery('(min-width:960px)')

  const determineScreenSize = () => {
    // Check if the selected calculation function is CONDITIONAL_COLUMN
    if (selectedCalcFn?.name === CONDITIONAL_COLUMN) {
      // If it is CONDITIONAL_COLUMN, determine screen size based on conditions
      if (isFullScreen) {
        return 'xs' // Full screen
      } else if (isLargeScreen) {
        return 'lg' // Large screen
      } else {
        return false // Neither full screen nor large screen
      }
    } else {
      // If the selected calculation function is not CONDITIONAL_COLUMN
      return false
    }
  }

  return (
    <Dialog
      maxWidth={determineScreenSize()}
      open={open}
      className={
        selectedCalcFn?.name === CONDITIONAL_COLUMN
          ? calculatedFieldFull
          : calculatedField
      }
      fullScreen={selectedCalcFn?.name === CONDITIONAL_COLUMN}
    >
      <DialogTitle>
        <span>
          Calculated Fields
          <strong style={{ color: '#00B2A9', textTransform: 'capitalize' }}>
            {` ${cdm_field}`}
          </strong>
        </span>
        <IconClose onClick={() => onCloseHandler(false)} />
      </DialogTitle>
      <DialogContent
        style={
          selectedCalcFn?.name === CONDITIONAL_COLUMN
            ? { position: 'relative' }
            : {}
        }
      >
        <CustomScrollbars
          autoHeight={selectedCalcFn?.name !== CONDITIONAL_COLUMN}
          autoHeightMin={392}
          autoHeightMax={600}
          style={
            selectedCalcFn?.name === CONDITIONAL_COLUMN
              ? {
                  position: 'absolute',
                }
              : {}
          }
        >
          <div className="content-wrapper">
            <div className="calculated-field-select">
              <InputLabel className={selectLabel}>
                Choose Calculation Function
              </InputLabel>
              <EBAutoComplete
                options={processOptions()}
                getOptionLabel={(option) => option.name}
                value={
                  calculationFunctions.find(
                    (option) => option.value === calculationFn
                  ) || null
                }
                size="small"
                placeholder="Select Calculation Function"
                width={280}
                forcePopupIcon={true}
                onChange={(event, value, reason) => {
                  onOperationChange(event, value, reason)
                }}
                renderOption={(option) => (
                  <div className={options} title={option.name}>
                    <span>{option.name}</span>
                  </div>
                )}
              />
            </div>
            {selectedCalcFn?.name === MULTIPLE_CALCULATIONS && FuncWarn()}
            {selectedCalcFn && FuncInfo()}
          </div>
          {selectedCalcFn && <Divider />}
          <div
            className="content-wrapper"
            style={{ marginBottom: erpFields.length === 0 && '127.65px' }}
          >
            {selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
              DebitCreditIndicator()}
            {selectedCalcFn?.name === AMOUNT_CALCULATION &&
              AmountCalculationOptions()}
            {selectedCalcFn?.name === CONCATENATE && ConcatenateDateFields()}
            <div className="erp-field">
              <Grid container spacing={3}>
                <>
                  {![
                    MULTIPLE_CALCULATIONS,
                    MATHS_WITH_BRACKET,
                    CONDITIONAL_COLUMN,
                  ].includes(selectedCalcFn?.name) &&
                    elementList?.map((_ele, i) => (
                      <ErpFieldDropDown index={i} key={i} />
                    ))}
                  {selectedCalcFn?.name === SPLIT && SplittingFn()}
                  {selectedCalcFn?.name === DATE_MASK && DateMaskFn()}
                  {selectedCalcFn?.name === CONCATENATE_DATE_TIME &&
                    ConcatenateDateTime()}
                  {selectedCalcFn?.name === MULTIPLE_CALCULATIONS &&
                    MultiCalFn()}
                  {selectedCalcFn?.name === MATHS_WITH_BRACKET &&
                    MathsWithBracketFn()}
                  {selectedCalcFn?.name === EXTRACT_TO_SPLIT &&
                    SplitToExtractFn()}
                  {selectedCalcFn?.name === FREE_TEXT && FreeTextFn()}
                  {selectedCalcFn?.name === CONDITIONAL_COLUMN && (
                    <ConditionalColumn localErpOptions={localErpOptions} />
                  )}
                </>

                {selectedCalcFn?.addMore &&
                  selectedCalcFn?.name !== CONCATENATE_DATE_TIME &&
                  AddMoreButton()}
                {((selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
                  DCIValue === 'DCI3') ||
                  (selectedCalcFn?.name === AMOUNT_CALCULATION &&
                    amountCalcValue === 'RBA')) &&
                  AddRangeButton()}
              </Grid>
            </div>
            {((selectedCalcFn?.name === DEBIT_CREDIT_INDICATOR &&
              DCIValue === 'DCI3') ||
              (selectedCalcFn?.name === AMOUNT_CALCULATION &&
                amountCalcValue === 'RBA')) && (
              <>{ranges.map((range, index) => RangesUI(index))}</>
            )}
            {selectedCalcFn?.name === MATHS_WITH_BRACKET && (
              <MathsWithBracket
                count={elementList.length}
                expression={mcbExpression}
                setExpression={setMcbExpression}
                invalidExpressions={invalidExpressions}
                setInvalidExpressions={setInvalidExpressions}
              />
            )}

            {erpFields.length > 0 &&
              ![CONDITIONAL_SELECT, CONDITIONAL_COLUMN].includes(
                selectedCalcFn?.name
              ) &&
              Output()}
          </div>
        </CustomScrollbars>
      </DialogContent>
      {calculationFn !== '-1' && (
        <DialogActions>
          <Button
            variant="contained"
            style={{
              background: '#89A0AC 0% 0% no-repeat padding-box',
              color: '#fff',
            }}
            onClick={() => onCloseHandler(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => isValidDateFormat() && onCloseHandler(true)}
            disabled={disableApply()}
          >
            Apply
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default CalculatedFieldDialog
