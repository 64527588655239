export enum AppRoute {
  Status = 'routes.status',
  AddData = 'routes.add-data',
  AddCustomERP = 'routes.add-custom-erp',
  Support = 'routes.support',
  CustomGrouping = 'routes.custom-grouping',
  TableJoins = 'routes.table-joins',
  TableJoinsCreate = 'routes.create',
  Extractions = 'routes.extractions',
  ExtractionsSettings = 'routes.extractions-settings',
  AuditAnalytics = 'routes.audit-analytics',
  AuditAnalyticsCreate = 'routes.audit-analytics-create',
  DataAndAnalitics = 'routes.data-and-analytics',
  CreatePipeline = 'routes.create-pipeline',
}

export const AppRouteTitles = new Map([
  [AppRoute.Status, 'status.title'],
  [AppRoute.AddData, 'add-data.title'],
  [AppRoute.AddCustomERP, 'add-custom-erp.title'],
  [AppRoute.Support, 'support.title'],
  [AppRoute.CustomGrouping, 'custom-grouping.title'],
  [AppRoute.TableJoins, 'table-joins.title'],
  [AppRoute.TableJoinsCreate, 'table-joins-create.title'],
  [AppRoute.Extractions, 'extractions.title'],
  [AppRoute.ExtractionsSettings, 'extractions-settings.title'],
  [AppRoute.AuditAnalytics, 'audit-analytics.title'],
  [AppRoute.AuditAnalyticsCreate, 'audit-analytics-create.title'],
  [AppRoute.DataAndAnalitics, 'data-and-analytics.title'],
  [AppRoute.CreatePipeline, 'create-pipeline.title'],
])
