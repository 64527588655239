import React, { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  getExtractions,
  getClientConnections,
  useIESelector,
  useIEDispatch,
  deleteERPExtraction,
  deleteERPConnection,
  EXTRACTION_STATUS_LABELS,
  ERP_STATUS_LABELS
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import {
    Grid,
    Typography,
    Box,
    CircularProgress,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Tooltip
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconSettings } from '../../assets/IconSettings.svg'
import { ExtractionsFilters } from "./ExtractionsFilters"
import EBTable from 'libs/shared/components/src/lib/eb-table/eb-table'
import { NotFoundView } from '../../views/NotFoundView/NotFoundView'
import { ReactComponent as IconDelete } from '../../assets/IconDelete2.svg'
import { ReactComponent as IconClose } from '../../assets/IconClose.svg'
import { ReactComponent as IconError } from '../../assets/IconError.svg'

const useStyles = makeStyles((theme) => {
    return createStyles({
      container: {
        display: 'flex',
        '& td': {
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '16px',
          lineHeight: '16px',
        },
      },  
      customDialog: {
        '& .MuiDialog-paper': {
          width: '780px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center',
        },
        '& .close-icon': {
          width: '100%',
          paddingTop: '30px',
          paddingRight: '30px',
          paddingLeft: '30px',
          textAlign: 'end',
          '& svg:hover': {
            cursor: 'pointer',
          },
        },
      },
      textCenter: {
        textAlign: 'center'
      },
      textLeft: {
        textAlign: "left",
        minWidth: "auto"
      },
      createExtractionContainer: {
        display: 'flex',
        gap: 15,
        padding: '25px 0px 25px 0px',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
          padding: '0px 0px 25px 0px',
          justifyContent: 'flex-end',
        },
      },
      TableButtonIcon: {
        display: "flex",
        cursor: 'pointer',
      },
      erpConnectionSettingButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        width: '24px',
        minWidth: '24px',
        height: '60px',
      },
      erpConnectionDeleteButton: { 
        minWidth: '65px',
        marginLeft: '8px'
      },
      boldText: {
        fontWeight: 500,
        lineHeight: '16px'
      },
      sectionStyles: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 80px)',
        maxWidth: 'calc(100vw - 280px)',
        padding: '30px',
        background: `#F0FBFA 0% 0% no-repeat padding-box`,
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px !important',
        },
      },
      header: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '30px',
        lineHeight: '37px',
        letterSpacing: '0.3px',
        color: '#22353F',
        opacity: 1,
      },
      subtitle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '30px',
        lineHeight: '37px',
        color: '#22353F',
        opacity: 1,
        letterSpacing: '0.2px'
      },
      truncateText: {
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        '& .dialog-title': {
          margin: '20px auto 20px auto',
          font: 'normal normal bold 26px/40px Arial',
          letterSpacing: '0.26px',
          color: '#44697D',
          opacity: 1,
        },
        '& .dialog-subtitle': {
          font: 'normal normal bold 22px/26px Arial',
          letterSpacing: '0.22px',
          color: '#DE4D4D',
          lineHeight: '40px',
        },
      },
      actions: {
        width: '100%',
        padding: '0 30px 30px 0',
        '& button': {
          textTransform: 'none',
          borderRadius: '5px',
          height: '44px',
          color: '#fff',
        },
      },
      noBtn: {
        background: '#89A0AC 0% 0% no-repeat padding-box',
        '&.MuiButton-contained:hover': {
          background: '#89A0AC 0% 0% no-repeat padding-box',
        },
      },
      yesBtn: {
        background: '#00B2A9 0% 0% no-repeat padding-box',
        '&.MuiButton-contained:hover': {
          background: '#00B2A9 0% 0% no-repeat padding-box',
        },
      },
      buttonDisplay: {
        display: "flex"
      }
    })
})

export function ExtractionsSettingsView() {
    const { 
      container, 
      header, 
      subtitle,
      sectionStyles, 
      truncateText, 
      boldText, 
      TableButtonIcon, 
      erpConnectionDeleteButton,
      erpConnectionSettingButton, 
      buttonDisplay, 
      actions, 
      textCenter,
      textLeft,
      yesBtn, 
      noBtn,
      dialogContent, 
      customDialog 
    } = useStyles()
    const { locale } = useIntl()
    const dispatch = useIEDispatch()
    const client = useApolloClient()
    const extractions = useIESelector((state) => state.extractions)
    const engagement = useIESelector((state) => state.extractions.engagement.id)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [extractionToDelete, setExtractionforDeletion] = useState({
      id: "",
      name: "",
      erpConnectionId: "",
      dialogOpen: false
    })
    const [connectionforDeletion, setConnectionforDeletion] = useState({
      id: "",
      name: "",
      dialogOpen: false
    })

    const calculateDayDifference = (date) => {
      const date1 = new Date(date);
      const date2 = new Date();
      const difference = date1.getTime() - date2.getTime();
      const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      const TotalHours = Math.ceil(difference / (60 * 60 * 1000));
      if (TotalDays > 1) {
        return `${TotalDays} days`;
      } else if (TotalHours > 0) {
        return `${TotalHours} hours`;
      } else {
        return `Expired`
      }
    }

    useEffect(() => {
      if(extractions.engagement.id) {
        dispatch(getExtractions({client}))
        dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
      }
    }, [extractions.engagement])

    useEffect(() => {
      if(extractions.engagement.id) {
        const interval = setInterval(() => {
          dispatch(getExtractions({client}))
          dispatch(getClientConnections({client, engagement}))
        }, 2000);
        return () => {
          clearInterval(interval);
        };
      }
    }, [
      extractions.extractions, 
      extractions.engagement, 
    ]);

    const setERPToDelete = (name, id, erpConnectionId) => {
      setExtractionforDeletion({
        name: name,
        id: id,
        erpConnectionId: erpConnectionId,
        dialogOpen: true
      })
    }

    const setConnectionToDelete = (name, id) => {
      setConnectionforDeletion({
        name: name,
        id: id,
        dialogOpen: true
      })
    }

    const deleteExtraction = () => {
      dispatch(
        deleteERPExtraction({
          client, 
          variables: {
            "id": extractionToDelete.id, 
            "erpConnectionId": extractionToDelete.erpConnectionId
          }
        }
      ));
      setExtractionforDeletion({
        id: "",
        name: "",
        erpConnectionId: "",
        dialogOpen: false
      });
    }

    const deleteConnection = () => {
      dispatch(deleteERPConnection({
        client, 
        variables: {
          "id": connectionforDeletion.id,
          "engagement": extractions.engagement.id
        }
      }));
      setConnectionforDeletion({
        id: "",
        name: "",
        dialogOpen: false
      });
    }

    const history = useHistory()
    const handleAddNewExtraction = () => {
      history.push(`settings/manage-extractions`)
    }

    const handleAddNewERP = () => {
      history.push(`settings/manage-erp`)
    }
    
    const erpTableHeadersData = useMemo(() => {
      return [
        {
          title: 'System',
          value: 'system',
          width: '100px',
        },
        {
          title: 'Name',
          value: 'name',
          width: '100px',
        },
        {
          title: 'Status',
          value: 'status',
          width: '100px',
          renderer: function (params) {
            const {
              row: {state, id},
            } = params
            return (
              <Typography data-testid="extraction-settings-connection-state">
                {(state !== "UNKNOWN" 
                    ? state === 'AWAITING_POST_AUTH_CONFIG' ? 
                      <Link
                        to={`settings/manage-erp/${id}`}
                      >
                        <Typography>{ERP_STATUS_LABELS[state]}</Typography>
                      </Link> 
                    : <Typography>{ERP_STATUS_LABELS[state]}</Typography>
                  : <Typography>An Error Has Occurred</Typography>
                )}
              </Typography>
            )
          },
        },
        {
          title: 'Login Validation',
          value: 'login_validation',
          width: '100px',
          renderer: function (params) {
            const {
              row: { authorisationId, state},
            } = params
            return (
              (ERP_STATUS_LABELS[state] === "Awaiting Authorization" && (
                <Link to={`settings/authorize/${authorisationId}`}>
                  <Typography className={truncateText}>settings/authorize/{authorisationId}</Typography>
                </Link>
                )
              )
            )
          },
        },
        {
          title: 'Expires in',
          value: 'expires',
          width: '50px',
          renderer: function (params) {
            const {
              row: { expirationDateTime, state},
            } = params
            return (
              (ERP_STATUS_LABELS[state] === "Awaiting Authorization" && (
                <Typography 
                  className={textCenter} 
                  color={
                    calculateDayDifference(expirationDateTime) === 'expired' 
                    ? 'error' 
                    : 'initial' 
                  }
                >
                  {calculateDayDifference(expirationDateTime)}
                </Typography>
              )
            ))
          },
        },
        {
          title: 'Actions',
          value: 'actions',
          width: '120px',
          renderer: function (params) {
            const {
              row: { id, name, deleteInProgress },
            } = params
            return (
              deleteInProgress 
                ? <Typography>Deleting...</Typography>
                : <Grid className={buttonDisplay}>
                    <Link
                      className={erpConnectionSettingButton}
                      to={`settings/manage-erp/${id}`}
                    >
                      <IconSettings/>
                    </Link>
                    <Button 
                      className={`${erpConnectionDeleteButton} ${textLeft}`} 
                      data-testid='delete-connection-btn'
                      onClick={() => setConnectionToDelete(name, id)}
                    >
                      <IconDelete />
                    </Button>
                  </Grid>
            )
          },
        },
      ]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extractions.clientConnections])

    const extractionsTableHeadersData = useMemo(() => {
      return [
        {
          title: 'ERP',
          value: 'erp_name',
          width: '100px',
          renderer: function (params) {
            const {
              row: { erpConnection },
            } = params
            return <Typography className={boldText}>{erpConnection?.name}</Typography>
          },
        },
        {
          title: 'Name',
          value: 'name',
          width: '100px',
        },
        {
          title: 'Status',
          value: 'status',
          width: '100px',
          renderer: function (params) {
            const {
              row: { status, failureReason },
            } = params
            return (
              <Grid container>
                <Typography>{EXTRACTION_STATUS_LABELS[status]}</Typography>
                {(failureReason && failureReason.length > 0) && 
                  <Tooltip title={failureReason} placement="top">
                    <div style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      width: "25px",
                      height: "25px",
                      marginLeft: "10px",
                    }}>
                      <IconError />
                    </div>
                  </Tooltip>
                }
              </Grid>
            )
          },
        },
        {
          title: 'Progress',
          value: 'progress',
          width: '100px',
          renderer: function (params) {
            const {
              row : { progress },
            } = params
            return (
              <Box style={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress 
                  variant="determinate" 
                  value={progress} 
                  color={progress < 100 ? 'primary' : 'secondary'}
                />
                <Box
                  style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" className={textCenter}>
                    {`${progress ? Math.round(progress) : 0 }%`}
                  </Typography>
                </Box>
               </Box>
            )
          }
        },
        {
          title: 'Actions',
          value: 'actions',
          width: '50px',
          renderer: function (params) {
            const {
              row: { name, id, erpConnection, deleteInProgress },
            } = params
            return (
              deleteInProgress 
                ? <Typography>Deleting...</Typography>
                : <Button 
                className={`${TableButtonIcon} ${textLeft}`} 
                data-testid='delete-extraction-btn' 
                onClick={() => setERPToDelete(name, id, erpConnection.id)}
              >
                <IconDelete />
              </Button>
            )
          },
        },
      ]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extractions.extractions])

    return (
      process.env.NX_ENABLE_API_EXTRACTIONS === 'true' 
      ? (
        <>
          <Dialog 
            className={customDialog} 
            open={extractionToDelete.dialogOpen} 
            onClose={() => setExtractionforDeletion({id: "", name:"", erpConnectionId: "", dialogOpen: false})}
            data-testid="delete-extractions-popup"
          >
            <div className="close-icon">
              <IconClose onClick={() => setExtractionforDeletion({id: "", name:"", erpConnectionId: "", dialogOpen: false})} />
            </div>

            <DialogContent className={dialogContent}>
            <IconError />
              <Typography className="dialog-title">This action will delete extraction {extractionToDelete.name}. Do you wish to proceed?</Typography>
            </DialogContent>

            <DialogActions className={actions}>
              <Button
                variant="contained"
                className={noBtn}
                onClick={() => setExtractionforDeletion({ id: "", name:"", erpConnectionId: "", dialogOpen: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={yesBtn}
                onClick={() => deleteExtraction()}
                data-testid="confirm-delete-extraction-btn"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog 
            className={customDialog} 
            open={connectionforDeletion.dialogOpen} 
            onClose={() => setConnectionforDeletion({id: "", name:"", dialogOpen: false})}
            data-testid="delete-connections-popup"  
          >
            <div className="close-icon">
              <IconClose onClick={() => setConnectionforDeletion({id: "", name:"", dialogOpen: false})} />
            </div>

            <DialogContent className={dialogContent}>
            <IconError />
              <Typography className="dialog-title">This action will delete the {connectionforDeletion.name} connection. Do you wish to proceed?</Typography>
            </DialogContent>

            <DialogActions className={actions}>
              <Button
                variant="contained"
                className={noBtn}
                onClick={() => setConnectionforDeletion({ id: "", name:"", dialogOpen: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={yesBtn}
                onClick={() => deleteConnection()}
                data-testid="confirm-delete-connection-btn"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <section className={sectionStyles}>
            <Grid container className={container} spacing={4}>
                <Grid item xs={12} md={8}>
                <Typography className={header}>Configure Extractions</Typography>
                </Grid>
                <Grid container item xs={12}>
                    <ExtractionsFilters />
                </Grid>
                {extractions?.engagement?.id && 
                  <>
                    <Grid container item xs={12}>
                        <Typography className={subtitle} style={{display: 'inline-block', marginRight: 'auto'}}>ERPs</Typography>
                        <Button
                          onClick={handleAddNewERP}
                          variant='contained'
                          color='secondary'
                        >
                          Add New
                        </Button>
                    </Grid>
                    <Grid container item xs={12} direction="column" style={{ flexGrow: 1 }}>
                      <EBTable
                        headers={erpTableHeadersData}
                        rows={extractions.clientConnections}
                        recordsPerPage={
                          extractions.clientConnections.length > 5 
                            ? 5 
                            : extractions.clientConnections.length
                        }
                        footerContent={null}
                        styles={{
                        tableBoxStyles: {
                            borderRadius: '8px',
                            flexBasis: 'auto'
                          },
                        }}
                        emptyLabel={extractions.message || 'No Data To Display'}
                      />
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Typography className={subtitle} style={{display: 'inline-block', marginRight: 'auto'}}>Extractions</Typography>
                        <Button
                          onClick={handleAddNewExtraction}
                          variant='contained'
                          color='secondary'
                        >
                          Add New
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} direction="column" style={{ flexGrow: 1 }}>
                      <EBTable
                          headers={extractionsTableHeadersData}
                          rows={extractions.clientExtractions}
                          recordsPerPage={
                            extractions.clientExtractions.length > 5 
                              ? 5 
                              : extractions.clientExtractions.length
                          }
                          footerContent={null}
                          styles={{
                          tableBoxStyles: {
                              borderRadius: '8px',
                              flexBasis: 'auto'
                            },
                          }}
                          emptyLabel={extractions.message || 'No Data To Display'}
                      />
                    </Grid>
                  </>
                }
            </Grid>
          </section>
        </>
      ) : (
        <NotFoundView/>
      )
    )
}