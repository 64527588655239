import { withJsonFormsControlProps, TranslateProps } from '@jsonforms/react';
import React from 'react'
import { Grid, TextField, InputLabel, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ControlProps, OwnPropsOfEnum } from '@jsonforms/core';
import { Description, NEW_UI_INPUT_WIDTH } from '@engine-b/integration-engine/ui/form-components'

const useStyles = makeStyles((theme) =>
  createStyles({
    DatePicker: {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '44px',
      marginBottom: '16px'
    },
    labelContainer: {
      marginBottom: '16px',
    },
    label: {
      font: 'normal normal bold 16px/18px Arial',
      color: '#44697D',
      letterSpacing: '0.16px',
      verticalAlign: 'center'
    },
    errorLabel: {
      color: theme.palette.error.main,
      marginBottom: '16px',
      minWidth: '200px',
    },
    description: {
      maxWidth: NEW_UI_INPUT_WIDTH + 'px',
      minWidth: '400px',
      fontSize: '14px',
    },
    inputLabel: {
      marginTop: '16px',
    }
  })
)

const DatePicker = (props: ControlProps & OwnPropsOfEnum & TranslateProps) => {
  const classes = useStyles()
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.labelContainer}>
        <Typography className={classes.label}>{props.label}</Typography>
        {props.required && <Typography style={{color: 'red'}}> *</Typography>}
      </Grid>
      <Grid item xs={12}>
        {props.description && (
          <Grid item xs={12}>
            <Typography className={classes.description}>{props.description}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          inputProps={{ "data-testid": "date-picker" }}
          className={classes.DatePicker}
          type="date"
          id={props.id}
          required={props.required}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={''}
          value={props.data ? props.data : ''}
          onChange={(e) => {
            props.handleChange(props.path, e.target.value)
          }}
        />
      </Grid>
      {props.errors && (
        <Grid item xs={12}>
          <label className={classes.errorLabel}>{props.errors}</label>
        </Grid>
      )}
    </Grid>
  )
}

export const DatePickerControl = withJsonFormsControlProps(DatePicker);