import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { file, JOINS_UPLOAD_STATE, table } from './typedefs'

export const tableNameListSelector = createSelector(
  [(state: RootState) => state.joins.tables],
  (tables) => {
    return Object.values(tables).map((table) => {
      return table.name
    })
  }
)

export const getTablesData =
  (tableName: string) =>
  (state: RootState): table => {
    if (tableName && state.joins.tables[tableName]) {
      return state.joins.tables[tableName]
    }
    return null
  }

export const basicDetailsSelector = createSelector(
  [
    (state: RootState) => state.joins.name,
    (state: RootState) => state.joins.auditedEntity,
    (state: RootState) => state.joins.engagement,
    (state: RootState) => state.joins.validName
  ],
  (filename, auditedEntity, engagement, validName) => {
    return {
      filename,
      auditedEntity,
      engagement,
      validName
    }
  }
)

export const getValidationMessageArr =
  (tableName: string) =>
  (state: RootState): string[] => {
    if (tableName && state.joins.tables[tableName].files) {
      return Object.values(state.joins.tables[tableName].files).reduce(
        (prev, curr) => {
          if (
            curr.uploadState === JOINS_UPLOAD_STATE.VALIDATING_FILE_FAILED &&
            curr.fileValidationMessage !== ''
          ) {
            const message = `${curr.fileValidationMessage} in ${curr.name}`
            prev.push(message)
          }
          return prev
        },
        []
      )
    }
    return []
  }

export const getInvalidVirtualTables = (state: RootState) => {
  return Object.values(state.joins.tables).reduce((initial, table, index) => {
    const vtFiles = Object.values(table.files)
    if (vtFiles.length === 0) {
      initial.push(table.name)
      return initial
    }
    const invalidFileIndex = Object.values(table.files).findIndex(
      (file) => file.uploadState !== JOINS_UPLOAD_STATE.VALIDATING_FILE_COMPLETE
    )
    if (invalidFileIndex >= 0) {
      initial.push(table.name)
    }
    return initial
  }, [])
}
