import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

type Color = 'primary' | 'secondary'
type Size = 'small' | 'medium'
type Variant = 'standard' | 'filled' | 'outlined'

/* eslint-disable-next-line */
export interface EBAutoCompleteProps {
  color?: Color
  size?: Size
  variant?: Variant
  borderRadius?: number
  backgroundColor?: string
  width?: number
  height?: number
  fullWidth?: boolean
  placeholder?: string
  error?: boolean
  helperText?: string
  hiddenLabel?: boolean
  label?: string
  freeSolo?: boolean
  forcePopupIcon?: boolean
  options: any[]
  value?: any
  defaultValue?: any
  onChange?: (e: React.ChangeEvent<{}>, value: any, reason: string) => void
  onKeyDown?: (e: React.ChangeEvent<{}>) => void
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined
  onInputChange?: (
    e: React.ChangeEvent<{}>,
    value: string,
    reason: string
  ) => void
  getOptionLabel?: (option: any) => string
  filterOptions?: (options: any, params: any) => any[]
  disabled?: boolean
  noOptionsText?: string
  getOptionSelected?: (option: any, value: any) => boolean
  renderOption?: (option: any, state: any) => React.ReactNode
  renderGroup?: (params: any) => React.ReactNode
  autoSelect?: boolean
  getOptionDisabled?: (opton: any) => boolean
  groupBy?: (option: any) => string
  ref?: React.Ref<unknown>
  PopperComponent?: any
  id?: string
  disableClearable?: boolean
  classes?: any
  required?: boolean
  multiple?: boolean
  disableCloseOnSelect?: boolean
}

const useStyles = (props: any) =>
  makeStyles(() => {
    return {
      customSelect: {
        '& .MuiInputBase-root': {
          borderRadius: props.borderRadius,
          background: props.backgroundColor,
          minHeight: props.height,
        },
      },
      groupLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textWeight: 'bold',
        fontSize: 'large',
      },
    }
  })

export function EBAutoComplete(props: Readonly<EBAutoCompleteProps>) {
  const { customSelect, groupLabel } = useStyles(props)()
  return (
    <Autocomplete
      freeSolo={props.freeSolo}
      forcePopupIcon={props.forcePopupIcon}
      options={props.options}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
      onInputChange={props.onInputChange}
      getOptionLabel={props.getOptionLabel}
      filterOptions={props.filterOptions}
      disabled={props.disabled}
      noOptionsText={props.noOptionsText}
      getOptionSelected={props.getOptionSelected}
      renderOption={props.renderOption}
      renderGroup={props.renderGroup}
      autoSelect={props.autoSelect}
      getOptionDisabled={props.getOptionDisabled}
      groupBy={props.groupBy}
      ref={props.ref}
      PopperComponent={props.PopperComponent}
      size={props.size}
      id={props.id}
      style={{ width: props.width }}
      classes={{ groupLabel, ...props.classes }}
      disableClearable={props.disableClearable}
      multiple={props.multiple}
      disableCloseOnSelect={props.disableCloseOnSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          color={props.color}
          size={props.size}
          variant={props.variant}
          fullWidth={props.fullWidth}
          placeholder={props.placeholder}
          error={props.error}
          helperText={props.helperText}
          hiddenLabel={props.hiddenLabel}
          label={props.label}
          className={customSelect}
          disabled={props.disabled}
          required={props.required}
        />
      )}
    />
  )
}

EBAutoComplete.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  borderRadius: PropTypes.number,
  backgroundColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
  forcePopupIcon: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
  disabled: PropTypes.bool,
  noOptionsText: PropTypes.string,
  getOptionSelected: PropTypes.func,
  renderOption: PropTypes.func,
  renderGroup: PropTypes.func,
  autoSelect: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  groupBy: PropTypes.func,
  ref: PropTypes.object,
  PopperComponent: PropTypes.any,
  id: PropTypes.string,
  disableClearable: PropTypes.bool,
  required: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
}

EBAutoComplete.defaultProps = {
  color: 'secondary',
  size: 'medium',
  variant: 'outlined',
  borderRadius: 8,
  fullWidth: true,
  placeholder: '',
  error: false,
  helperText: '',
  hiddenLabel: false,
  label: '',
  freeSolo: false,
  forcePopupIcon: false,
  options: [],
  disabled: false,
  noOptionsText: 'No options',
  autoSelect: false,
  id: '',
  required: false,
  disableCloseOnSelect: false,
}

export default EBAutoComplete
