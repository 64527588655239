import { useApolloClient } from '@apollo/client'
import {
  getGroupings,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { ReactComponent as IconClose } from '../../assets/IconClose.svg'

const useStyles = makeStyles(() => ({
  saveMapping: {
    '& .MuiDialog-paper': {
      width: '600px',
      height: 'auto',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '20px',
    },
    '& .MuiDialogTitle-root > h2': {
      textAlign: 'center',
      font: 'normal normal bold 20px/30px Arial',
      letterSpacing: '0.2px',
      color: '#22353F',
      '& > .MuiButtonBase-root': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    '& .MuiDialogContent-root': {
      '& > .MuiAlert-root': {
        marginTop: '15px',
        background: '#FFF4E5 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        width: '510px',
        minHeight: '95px',
        margin: '15px auto auto auto',
        '& > .MuiAlert-message': {
          textAlign: 'left',
          font: 'normal normal bold 14px/24px Arial',
          letterSpacing: '0px',
          color: '#AB6500',
        },
      },
    },
    '& .MuiInputBase-root': {
      width: '100%',
      height: '44px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '24px',
      '& > button': {
        height: '44px',
        minWidth: -0,
      },
      '& > button:first-child': {
        width: '132px',
      },
      '& > button:last-child': {
        width: '128px',
        marginLeft: '25px',
      },
    },
  },
}))

const SaveGroupingDialog = ({ open, onClose, existingFileName }) => {
  const { saveMapping } = useStyles()
  const dispatch = useIEDispatch()

  const [replace, setReplace] = useState(false)
  const [customGroupingFileName, setCustomGroupingFileName] = useState('')

  const grouping = useIESelector((state) => state.groupings).find(
    (grouping) => grouping.name === customGroupingFileName?.trim()
  )

  const isNameAlreadyExist = () => grouping !== undefined

  const client = useApolloClient()

  useEffect(() => {
    setCustomGroupingFileName('')
    setReplace(false)
    if (open) {
      dispatch(getGroupings(client))
      setCustomGroupingFileName(existingFileName)
    }
  }, [open])

  useEffect(() => {
    setReplace(isNameAlreadyExist)
  }, [isNameAlreadyExist])

  return (
    <Dialog open={open} className={saveMapping} maxWidth={false}>
      <DialogTitle>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Save Groupings
        </Typography>
        <IconButton onClick={() => onClose(false)}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Grouping Name</Typography>
        <Typography variant="subtitle1">
          Please enter the name of the new group name
        </Typography>
        <TextField
          type="text"
          variant="outlined"
          fullWidth
          value={customGroupingFileName}
          onChange={(e) => setCustomGroupingFileName(e.target.value)}
        />
        {replace && isNameAlreadyExist() && (
          <Alert severity="warning">
            A grouping with this name already exists. Please update grouping name and select Save to 
            create a new saved groupings or select Replace to overwrite the 
            existing saved groupings.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <>
          {replace ? (
            <Button
              disabled={
                customGroupingFileName.length === 0 || !isNameAlreadyExist()
              }
              variant="contained"
              color="primary"
              onClick={() =>
                isNameAlreadyExist() &&
                onClose(true, customGroupingFileName, grouping.id)
              }
            >
              Replace
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose(false)}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={
              customGroupingFileName?.length === 0 ||
              isNameAlreadyExist() ||
              customGroupingFileName.trim() === ''
            }
            variant="contained"
            color="secondary"
            onClick={() =>
              !isNameAlreadyExist() && onClose(true, customGroupingFileName, '')
            }
          >
            Save
          </Button>
        </>
      </DialogActions>
    </Dialog>
  )
}

export default SaveGroupingDialog
