import { gql } from '@apollo/client'

export const VALIDATE_CUSTOM_ERP = gql`
  query ValidateCustomErp($erpPayload: ValidateCustomErpInput) {
    validateCustomErp(erpPayload: $erpPayload) {
      exists
      cdmEntities {
        systemName
        extractType
        exists
      }
    }
  }
`
