import { gql } from '@apollo/client'

export const GET_OPERATION_LOGS = gql`
  query operationLogs ($auditAnalyticsId: String) {
    getOperationLogs (
      auditAnalyticsId: $auditAnalyticsId
    ) {     
      completeAt
      createdBy
      dataIngestion {
        auditedEntity {
          name
          id
        }
        id
        name
        engagement {
          name
          id
        }
        container {
          incomingPath
          inputPath
          storageAccountURL
          fileSystemId
          sharePath
          errorsPath
        }
      }
      dataIngestionId
      name
      path
      status
      userId
      id
      initiatedAt
      bundle {
        name
        id
        entity {
          name
          displayName
        }
        bundleFunctions {
          id
          function {
            id
            name
          }
          params
          source
          status
          order
        }
      }
      bundleId
    }
  }
`
