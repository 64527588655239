import { EBAutoComplete } from '@engine-b/shared/components'
import React, { useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Avatar, Button, Chip, Grid, TextField } from '@material-ui/core'
import {
  MultipleCalculationData,
  Option,
} from '@engine-b/integration-engine/data/state/redux'

const generateLetterSequence = (num: number) => {
  const alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let sequence: string = ''

  while (num >= 0) {
    sequence = alphabet[num % 26] + sequence
    num = Math.floor(num / 26) - 1
  }

  return sequence
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      maxHeight: 200,
      overflowY: 'auto',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
)

interface MultipleCalculationInputProps {
  options: Option[]
  data: MultipleCalculationData | undefined
  error?: string
  onChange: (data: MultipleCalculationData) => void
}

const MultipleCalculationInput = (props: MultipleCalculationInputProps) => {
  const {
    options,
    onChange,
    data = { expression: '', column: {} },
    error,
  } = props
  const [selectedColumn, setSelectedColumn] = useState<Option | null>(null)

  const classes = useStyles()

  const columns = Object.values(data.column)
  const expression = data.expression
  const showExpressionError: boolean =
    error?.toLowerCase().includes('expression') || false

  const handleExpressionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const exp = e.target.value
    prepareData(exp, columns)
  }

  const addColumn = (column: string) => {
    if (columns.includes(column) || column === '') {
      return
    }
    prepareData(expression, [...columns, column])
    setSelectedColumn(null)
  }

  const deleteColumn = (column: string) => {
    const newColumns = columns.filter((c) => c !== column)
    prepareData(expression, newColumns)
  }

  const prepareData = (expression: string, currentColumns: string[]) => {
    let column_object: any = {}
    for (let i = 0; i < currentColumns.length; i++) {
      column_object[generateLetterSequence(i)] = currentColumns[i]
    }
    const data = {
      expression: expression,
      column: column_object,
    }

    onChange(data)
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          type="text"
          placeholder="Type the equation here..."
          fullWidth
          value={expression}
          onChange={handleExpressionChange}
          error={showExpressionError}
          helperText={showExpressionError ? error : ''}
        />
      </Grid>

      <Grid item>
        <EBAutoComplete
          fullWidth
          options={options}
          getOptionLabel={(option) => option.title || ''}
          forcePopupIcon={true}
          height={48}
          size="small"
          value={selectedColumn}
          getOptionDisabled={(option) => columns.includes(option.title)}
          onChange={(e, value) => setSelectedColumn(value)}
        />
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          onClick={(_) => addColumn(selectedColumn?.title || '')}
        >
          Add Column
        </Button>
      </Grid>
      <Grid item className={classes.root}>
        {columns.map((column, index) => (
          <Chip
            avatar={<Avatar>{generateLetterSequence(index)}</Avatar>}
            key={column}
            variant="outlined"
            size="small"
            label={column}
            onDelete={(_) => deleteColumn(column)}
            color="primary"
          />
        ))}
      </Grid>
    </Grid>
  )
}
export default MultipleCalculationInput
