import React, { useEffect, useState } from 'react'
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import EmptyBanner from './EmptyBanner'
import { EBDataGrid } from '@engine-b/shared/components'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '93vh',
  },
  addDataDialog: {
    height: '90vh',
  },
  dialogContent: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  tableContainer: {
    height: 712,
    width: '100%',
    overflowX: 'auto',
  },
  CancelIcon: {
    cursor: 'pointer',
    marginRight: 30,
    color: '#89A0AC',
  },
}))

interface ERPDialogProps {
  data: any
  open: boolean
  header: any
  isConfirmed?: boolean
  onClose: any
}

function ERPDialog(props: Readonly<ERPDialogProps>) {
  const { data, open, onClose, header, isConfirmed } = props
  const classes = useStyles()
  const customERP = useIESelector((state) => state.customERP)

  const handleClose = (confirmed) => {
    onClose(confirmed)
  }

  const dataWithIds =
    data !== 'No data'
      ? data?.map((row, index) => {
          const newRow = { id: index + 1 }
          for (const key of Object.keys(row)) {
            newRow[key] = row[key] === '' ? '-' : row[key]
          }
          return newRow
        })
      : 'No Data'

  const fileData = dataWithIds?.length ? dataWithIds[0] : {}
  const columns = Object.keys(fileData).map((key) => ({
    field: key,
    headerName: key,
  }))

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        fullWidth
        disableBackdropClick={true}
        maxWidth="md"
        classes={{
          paper:
            customERP?.client_system?.report || customERP?.client_system?.system
              ? classes.dialog
              : classes.addDataDialog,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <DialogTitle>{header}</DialogTitle>
          <Grid className="dialogHeaderCloseBtn">
            <CancelIcon
              className={classes.CancelIcon}
              onClick={() => onClose(false)}
            />
          </Grid>
        </div>
        <div className={classes.dialogContent}>
          <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Paper style={{height:'700px'}}>
              {data === 'No data' ? (
                <EmptyBanner text={data} />
              ) : (
                <div className={classes.tableContainer}>
                  <EBDataGrid data={dataWithIds} columns={columns} />
                </div>
              )}
            </Paper>
          </DialogContent>
          {(customERP.client_system.report ||
            customERP.client_system.system) && (
            <DialogActions style={{ marginTop: '8px' }}>
              <Button
                onClick={() => handleClose(false)}
                variant="contained"
                style={{
                  background: '#89A0AC',
                  color: '#fff',
                  width: '100px',
                  height: '44px',
                }}
              >
                Cancel
              </Button>
              { !isConfirmed 
                ? <Button
                  onClick={() => handleClose(true)}
                  color="secondary"
                  style={{ width: '100px', height: '44px' }}
                  variant="contained"
                  disabled={data === 'No data'}
                >
                  Confirm
                </Button>
                : <Button
                    onClick={() => handleClose(false)}
                    color="secondary"
                    style={{ width: '100px', height: '44px' }}
                    variant="contained"
                  >
                    Close
                  </Button>
              }
            </DialogActions>
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default ERPDialog