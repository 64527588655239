import { createSlice } from '@reduxjs/toolkit'

interface USER_INITIAL_STATE {
    auditFirmId: string
    systemName: string
    uid: string
}

const INITIAL_STATE = {
    auditFirmId: "",
    systemName: "",
    uid: ""
}

export const userStateSlice = createUserStateSlice(INITIAL_STATE);

export function createUserStateSlice(initialState: USER_INITIAL_STATE) {
    return createSlice({
        name: 'user',
        initialState,
        reducers: {
            setUserDetails: (state, {payload}) => {
                state = payload
                return state
            }
        },
    })
}

export const { setUserDetails } = userStateSlice.actions

export const userReducer = userStateSlice.reducer
