import { ApolloClient } from '@apollo/client'
import {
    GET_ATTRIBUTES_BY_ENTITY_NAME,
} from '@engine-b/integration-engine/data/data-ingestion-api'
import { CdmEntity, Query, UploadedFile } from '@engine-b/shared/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

enum LOADING_STATE {
    DONE = 'done',
    LOADING = 'loading',
    ERROR = 'error',
}

export interface IAttributes {
    attributes: Array<any>
    loadingState: LOADING_STATE
}

export const INITIAL_ATTRIBUTES_STATE: IAttributes = {
    attributes: [],
    loadingState: LOADING_STATE.DONE,
}

interface IGetDataAttributes {
    client: ApolloClient<unknown>,
    entityName: string
}

export const getDataAttributes = createAsyncThunk(
    'getDataAttributes',
    async ({ client, ...params }: IGetDataAttributes) => {
        const response = await client.query<{
            attributes: Query['attributes']
        }>({
            query: GET_ATTRIBUTES_BY_ENTITY_NAME,
            variables: {
                entityName: params.entityName
            }
        })
        return response
    }
)

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const AttributeSlice = createAttributeSlice(INITIAL_ATTRIBUTES_STATE)

export function createAttributeSlice(initialState: IAttributes) {
    return createSlice({
        name: 'attributes',
        initialState,
        reducers: {
            resetAttributes: (state, { payload }) => {
                state.attributes = []
                return state
            },
        },
        extraReducers: (builder) => {
            builder.addCase(getDataAttributes.pending, (state, _) => {
                state.loadingState = LOADING_STATE.LOADING
            })

            builder.addCase(getDataAttributes.fulfilled, (state, action) => {
                const formatedAttributes = action.payload.data.attributes.map((attribute) => ({
                    id: attribute.name,
                    name: attribute.name,
                    dataType: attribute.dataType,
                }))
                state.attributes = formatedAttributes;
                state.loadingState = LOADING_STATE.DONE
            })

            builder.addCase(getDataAttributes.rejected, (state, action) => {
                state.loadingState = LOADING_STATE.ERROR
                console.error('getDataIngestions request was rejected: ', action.error)
            })
        }
    })
}

// Action creators are generated for each case reducer function
export const { resetAttributes } = AttributeSlice.actions

export const attributeReducer = AttributeSlice.reducer
