import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import axios from 'axios'
import { binaryToBase64 } from '@engine-b/integration-engine/utils/file-converter'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import Filter from './components/FilterComponent'
import DialogComponent from './components/DialogComponent'
import SaveMappingDialog from './components/SaveMappingDialog'
import ExistingMappingDialog from './components/ExistingMappingDialog'
import {
  useIESelector,
  useIEDispatch,
  toggleCustomMapperVisibility,
  setCustomFileMappings,
  clearManualSelections,
  setRemappingFile,
  clearRemappingFile,
  CDMMapping,
  renameCdmFileName,
  fetchStart,
  fetchStop,
  updateManualSelections,
  resetManualSelections,
  clearExpressions,
} from '@engine-b/integration-engine/data/state/redux'
import { JsDateFormat, SAVED_MAPPINGS_PATH } from './utils/constants'
import {
  AzureClientContext,
  uploadCustomMappingFileToAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
import { postMapValidate } from './components/CalculatedFieldDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    mapperView: {
      backgroundColor: theme.background.secondary.main,
      padding: '2rem',
      '& .reset-title': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .action-btn > button': {
          height: '44px',
          minWidth: 0,
          marginLeft: '15px',
        },
        '& .action-btn > label': {
          height: '44px',
          minWidth: 0,
          marginLeft: '15px',
        },
      },
    },
    title: {
      textAlign: 'left',
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
      fontWeight: 'bold',
    },
    applyButton: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'space-between',
      '& button': {
        width: '160px',
        height: '44px',
      },
      '& .back-btn': {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #44697D',
        opacity: 1,
        width: '155px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)

//  ***** This function is also used in StepThree component *****
// replace previously mapped erpField data with the currently uploaded file erpField data
export const isAllFieldsPresent = async (
  cdm_fields,
  cMapper,
  draftRun,
  dispatch,
  instance,
  inProgress,
  accounts
) => {
  /**
   * Make a copy of following keys
   * 1) manualMapping
   * 2) customFielMapping
   */
  const manualSelectionCopy = [...cMapper.manualSelections]
  const customFileMappingsCopy = { ...cMapper.customFileMappings }

  /**
   * Retrieve all mapping records of all report types
   */
  const records = cMapper.files.reduce(
    (initialState, file) => [...initialState, ...file.cdmDetails.mapping],
    []
  )

  /**
   * Loop through all incoming mapping
   * 1) Find whether all incoming file mapping are present in current mapping
   * 2) If present then map the fields according to downloaded mapping file
   */
  for (const current of cdm_fields) {
    const index = cMapper.manualSelections.findIndex(
      (cs) =>
        cs.cdm_field === current.cdm_field &&
        cs.report_type === current.report_type &&
        cs.extract_type === current.extract_type &&
        cs.mandatory === current.mandatory
    )

    if (index > -1) {
      /**
       * Field Check logic starts here
       * Check whether previously mapped fields are available in current uploaded input file if not remove it from
       * if not present remove from mapped fields
       * if there is no mapped field then set 'map_type' as 'dummy'
       */
      const inputFileFieldNames = cMapper.files
        ?.find((file) => file.reportType === current.report_type)
        ?.cdmDetails?.fields?.map(({ field_name }) => field_name)

      current.erp_fields = current.erp_fields.filter(
        ({ field_name }) => inputFileFieldNames.indexOf(field_name) > -1
      )

      if (
        current.erp_fields.length === 0 &&
        ['CC', 'FT'].indexOf(current.operation) === -1
      ) {
        current.map_type = 'dummy'
      }
      // Field check logic ends here

      // update the current manual selection object with the incoming
      manualSelectionCopy[index] = {
        ...current,
        erp_Id: draftRun.erpId,
        map_type:
          current.erp_fields.length === 1 && current.map_type === 'dummy'
            ? 'direct'
            : current.map_type,
        original_filename: manualSelectionCopy[index].original_filename,
      }

      const { map_type, erp_fields, cdm_field, report_type } =
        manualSelectionCopy[index]

      const record = records.find((rc) => {
        return rc.name === cdm_field && rc.report_type === report_type
      })

      // If map_type is calculated change field name to 'Calculated Field'
      if (map_type === 'calculated' || map_type === 'date-mask') {
        const file = cMapper.files.find(
          (file) => file.reportType === report_type
        )

        const pmRequestBody = { ...manualSelectionCopy[index] }

        if (map_type === 'calculated' && pmRequestBody.operation === 'SGLAN') {
          const extractSplit = pmRequestBody.extract_split_fields
          if (extractSplit.opening_char.length > 0) {
            extractSplit.opening_char_number = 1
          }

          if (extractSplit.closing_char) {
            if (extractSplit.closing_char === extractSplit.opening_char) {
              extractSplit.closing_char_number = 2
            } else {
              extractSplit.closing_char_number = 1
            }
          } else {
            extractSplit.closing_char_number = 0
          }

          pmRequestBody.extract_split_fields = { ...extractSplit }
        }

        const { status, data } = await postMapValidate(
          { ...pmRequestBody, records: file?.records },
          dispatch,
          instance,
          inProgress,
          accounts
        )

        customFileMappingsCopy[record.id] = {
          ...erp_fields[0],
          field_name: 'Calculated Field',
          valid: status,
        }
        if (status) {
          customFileMappingsCopy[record.id]['post_validate_data'] = ''
        } else {
          customFileMappingsCopy[record.id]['post_validate_data'] =
            typeof data === 'string' ? data : data[0].error
        }
        manualSelectionCopy[index].erp_fields.forEach(
          (field) => (field.valid = status)
        )
      } else if (erp_fields[0]) {
        customFileMappingsCopy[record.id] = erp_fields[0]
      } else {
        customFileMappingsCopy[record.id] = null
      }
    }
  }
  !cMapper.is_header_row_changed && dispatch(updateManualSelections(manualSelectionCopy))
  !cMapper.is_header_row_changed && dispatch(setCustomFileMappings(customFileMappingsCopy))
}

export const updateFieldDateFormat = (field) => {
  if (field.operation === 'Date Mask' && field.date_format in JsDateFormat) {
    return {
      ...field,
      date_format: JsDateFormat[field.date_format],
      date_format_text: JsDateFormat[field.date_format],
    }
  } else if (field.operation === 'Calculated' && field.map_type === 'DCONCAT') {
    const date_format_text =
      field.date_format in JsDateFormat
        ? JsDateFormat[field.date_format]
        : field.date_format
    const time_format_text =
      field.time_format in JsDateFormat
        ? JsDateFormat[field.time_format]
        : field.time_format
    return {
      ...field,
      date_format: date_format_text,
      date_format_text: date_format_text,
      time_format: time_format_text,
      time_format_text: time_format_text,
    }
  } else {
    return field
  }
}

export const onPrevMappingSelect = async (
  result: boolean,
  file_path: string,
  draftRun,
  cMapper,
  dispatch,
  instance,
  inProgress,
  accounts,
  setPrevMapDialog
) => {
  if (cMapper?.customMapperVisible) {
    setPrevMapDialog(false)
  }
  if (result) {
    dispatch(fetchStart({}))
    try {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios.post(
        `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/read-saved-mappings`,
        {
          file_path,
        },
        {
          headers,
        }
      )

      // If data is not null
      if (response.data?.data) {
        const { cdm_fields } = response.data.data
        /**
         * Replace date formats from python date_format to js date_format while select previous mapping
         */
        const new_cdm_fields = cdm_fields.map(updateFieldDateFormat)
        isAllFieldsPresent(
          new_cdm_fields,
          cMapper,
          draftRun,
          dispatch,
          instance,
          inProgress,
          accounts
        )
        dispatch(fetchStop({}))
      } else {
        dispatch(fetchStop({}))
        // TODO: Handle error if mapping file is empty
      }
    } catch (error) {
      dispatch(fetchStop({}))
    }
  } else {
    dispatch(fetchStop({}))
  }
}

const MapperView = () => {
  const { instance, inProgress, accounts } = useMsal()
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const cMapper = useIESelector((state) => state.customMapper)
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )
  const [open, setOpen] = useState(false)
  const [saveMapDialog, setSaveMapDialog] = useState(false)
  const [prevMapDialog, setPrevMapDialog] = useState(false)
  const [mappingList, setMappingList] = useState([])
  const [loading, setLoading] = useState(false)
  const [customMappingFileName, setCustomMappingFileName] = useState('')
  const [saveType, setSaveType] = useState('')
  const [isSaved, setIsSaved] = useState(false);

  const azureClient = useContext(AzureClientContext)
  const customFileMappings = useIESelector(
    (state) => state.customMapper.customFileMappings
  )
  const draftRun = useIESelector((state) => state.runs.draftRun)

  const manualSelections = useIESelector(
    (state) => state.customMapper.manualSelections
  )

  useEffect(() => {
    setIsSaved(false)
  }, [manualSelections, customFileMappings, prevMapDialog])

  // In order to preform UI testing we need to set testingMode
  // to true, otherwise the customMapper will not render
  const testingMode = useIESelector((state) => state.runs.testing.testMode)
  /**
   * 1) Load mapping when user uploads new file with premapped data
   * 2) This function will run for each file upload
   * 3) Update the manualSelection in custom mapper slice
   */
  const updateReduxStatePreMapping = ({ mapping, fields }) => {
    const defaultMapping = mapping.map((item: CDMMapping) => ({
      map_type: item.map_type,
      cdm_field: item.name,
      erp_fields: item.erp_fields,
      file_name: fields[0]?.file_name,
      report_type: item.report_type,
      original_filename: cMapper.files[0]?.inputFileName,
      data_type: item.data_type,
      mandatory: item.mandatory,
      erp_Id: draftRun.erpId,
      extract_type: draftRun.extractType,
    }))

    dispatch(resetManualSelections(defaultMapping))
  }

  const onCloseHandler = (value) => {
    if (value) {
      dispatch(setCustomFileMappings({}))
      dispatch(clearManualSelections())
      dispatch(clearRemappingFile())
      dispatch(clearExpressions())
      cMapper.files.forEach((item) =>
        updateReduxStatePreMapping(item.cdmDetails)
      )
    }
    setOpen(false)
  }

  const applyRemapping = async (sType: string, result: boolean, fileName = '') => {
    setSaveMapDialog(false)
    dispatch(fetchStart({}))

    try {
      // bring token for protected resource
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      //CDM VERSION
      const cdm_version = cMapper.cdmVersion

      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios.post(
        `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/create-as-json`,
        { manualSelections, cdm_version },
        {
          responseType: 'blob',
          headers,
        }
      )
      
      if (sType === 'apply') {
        const remappingFile = await binaryToBase64(response.data)
        dispatch(setRemappingFile(remappingFile))
        setIsSaved(true)    
        dispatch(toggleCustomMapperVisibility(false))
      }

      /**
       * Store custom mapping file into other container when user clicks save button
       * This file will be available for future use
       */
      if (result && fileName) {
        const resp = await uploadCustomMappingFileToAzureContainer(
          azureClient,
          azureContainer.fileSystemId,
          response.data,
          'custom-mappings',
          fileName + '.json'
        )
        if (resp === 200) {
          getSavedMappings()
          setIsSaved(true)
          dispatch(fetchStop({}))
        }
      } else {
        dispatch(fetchStop({}))
        setIsSaved(false)
      }
    } catch (error) {
      dispatch(fetchStop({}))
      setIsSaved(false)
    }

    // Modify fileMappings
    dispatch(renameCdmFileName())
    setCustomMappingFileName('')
  }

  const disabledApplyExit = () => {
    // Find All fields count which mapping_required: true
    const cdmMappings = cMapper.files.reduce(
      (acc, file) => [...acc, ...new Set(file.cdmDetails.mapping)],
      []
    )
    // check whether all mapped fields are valid?
    const isAllMappedFieldsValid: any = manualSelections.reduce(
      (acc, currentValue: any) => {
        /**
         * Check if opeartion is Free Text follow below steps
         * Find cdmMapping which matches currentValue name & report_type
         * Then check that mapping is valid or not
         */
        if (currentValue.operation === 'FT') {
          const record = cdmMappings.find(
            (cdmMap) =>
              cdmMap.name === currentValue.cdm_field &&
              cdmMap.report_type === currentValue.report_type
          )

          return customFileMappings[record?.id]?.valid && acc
        }

        /**
         * If any mapped field has invalid data it will throw an error
         */
        if (currentValue?.erp_fields?.length > 0) {
          return (
            acc &&
            currentValue.erp_fields.reduce((initVal, item: any) => {
              return item.valid && initVal
            }, true)
          )
        } else {
          /**
           * If field is not mapped and it is non-mandatory then consider it as valid
           * If field is mandatory then disable button
           */
          return acc && !currentValue?.mandatory
        }
      },
      true
    )

    const isAllRequiredFieldMapped = cdmMappings
      .filter((item) => item.mandatory)
      .reduce((acc, item: CDMMapping) => {
        const record = manualSelections.find(
          (val) =>
            val.cdm_field === item.name && val.report_type === item.report_type
        )
        return (
          acc &&
          record &&
          (record.erp_fields.length > 0 || record?.operation == 'FT')
        )
      }, true)
    return !(isAllRequiredFieldMapped && isAllMappedFieldsValid)
  }

  const getSavedMappings = async () => {
    if (!testingMode) {
      setLoading(true)
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios.post(
        `${process.env.NX_CUSTOM_MAPPER_API_URL}/files/list-blobs`,
        {
          path: azureContainer.fileSystemId + SAVED_MAPPINGS_PATH,
        },
        {
          headers,
        }
      )
      setMappingList(response.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!testingMode) {
      getSavedMappings()
    }
  }, [])

  const onExport = () => {
    const data = JSON.stringify({ cdm_fields: manualSelections })
    const blob = new Blob([data], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'customMapping.json'
    link.click()

    URL.revokeObjectURL(url)
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onImport = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = async function (event: any) {
      try {
        const data = JSON.parse(event.target.result)
        if (data) {
          const { cdm_fields } = data

          cdm_fields.forEach((field) => {
            /**
             * If operation type is CONCAT and separator is not present or empty string then set default value as -
             */
            if (field.operation === 'CONCAT' && !field.separator) {
              field.separator = '-'
            }
          })

          /**
           * TODO: Temporary fix until all wrong mapping will get converted into the correct one
           * Replace date formats from python date_format to js date_format
           */
          const new_cdm_fields = cdm_fields.map(updateFieldDateFormat)

          new_cdm_fields.forEach((field) => {
            if (
              field.operation === 'Date Mask' &&
              field.date_format.includes('SSS')
            ) {
              field.date_format = field.date_format.replace(/SSS\b/, 'ms')
              field.date_format_text = field.date_format_text.replace(
                /SSS\b/,
                'ms'
              )
            }
          })

          isAllFieldsPresent(
            new_cdm_fields,
            cMapper,
            draftRun,
            dispatch,
            instance,
            inProgress,
            accounts
          )
          dispatch(fetchStop({}))
        } else {
          dispatch(fetchStop({}))
        }
      } catch (err) {
        console.error('error', err)
      }
    }
    reader.readAsText(file)
    event.target.value = null
  }
  const openFileInput = () => {
    fileInputRef.current?.click()
  }

  const onSaveHandler = (type: string) => {
    setSaveMapDialog(true)
    setSaveType(type)
}

  const onApplyHandler = (type) => {
    if(isSaved) {
      setSaveMapDialog(false)
      dispatch(toggleCustomMapperVisibility(false))
      applyRemapping('apply', true, customMappingFileName)
    } else {
      onSaveHandler(type)
    }
  }


  return (
    <div className={classes.mapperView}>
      <div className="reset-title">
        <Typography variant="h5" className={classes.title}>
          Custom Mapper
        </Typography>

        <div className="action-btn">
          {/* <Button variant="contained" color="secondary">
            Auto Map
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onSaveHandler('save')}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPrevMapDialog(true)}
          >
            Previous Mappings
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            disabled={Object.keys(customFileMappings).length === 0}
          >
            Reset Mappings
          </Button>
          <Button variant="contained" color="primary" onClick={onExport}>
            Export
          </Button>
          <Button variant="contained" color="primary" onClick={openFileInput}>
            Import
          </Button>

          <input
            id="file-input"
            type="file"
            accept=".json"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={onImport}
          />
        </div>
      </div>

      {/* Filter Component - Table component imported in Filter component*/}
      <Filter />

      <div className={classes.applyButton}>
        <Button
          className="back-btn"
          variant="outlined"
          onClick={() => dispatch(toggleCustomMapperVisibility(false))}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabledApplyExit()}
          onClick={() => !disabledApplyExit() && onApplyHandler('apply')}
        >
          Apply & Exit
        </Button>
      </div>

      <DialogComponent
        open={open}
        onClose={onCloseHandler}
        cancelText="No, apply no changes"
        confirmText="Yes, reset mappings"
        title="Are you sure you want to reset all mappings?"
        subtitle="Once reset your information will be lost."
      />
      <SaveMappingDialog
        open={saveMapDialog}
        setSaveMapDialog={setSaveMapDialog}
        onClose={applyRemapping}
        savedMappings={mappingList}
        customMappingFileName={customMappingFileName}
        setCustomMappingFileName={setCustomMappingFileName}
        saveType={saveType}
      />
      <ExistingMappingDialog
        open={prevMapDialog}
        onClose={(result, file_path) => {
          onPrevMappingSelect(
            result,
            file_path,
            draftRun,
            cMapper,
            dispatch,
            instance,
            inProgress,
            accounts,
            setPrevMapDialog
          )
        }}
        savedMappings={mappingList}
        loading={loading}
      />
    </div>
  )
}

export default MapperView
