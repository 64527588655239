import { ApolloClient } from '@apollo/client'
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import {
  GET_GROUPING,
  GET_GROUPING_OPTIONS,
  SAVE_GROUPING,
  GET_GROUPING_RECOMMENDATIONS,
  BEGIN_DATAINGESTION_GROUP
} from '@engine-b/integration-engine/data/data-ingestion-api'
import axios from 'axios'
import _ from 'lodash'

export interface CUSTOM_GROUPING_DATA {
  index: number
  nominalCode: string
  mapped: string
  accountType: string
  accountSubType: string
  fsCaption: string
  accountName: string
  glAccountName: string
  selected?: any
  glMapNumber?: string
}

export interface GroupingDetails {
  id: string
  name: string
  version: string
  lastUpdatedBy: string
  auditFirmId: string
  complete: boolean
  groupingCodes: CUSTOM_GROUPING_DATA[]
}

export interface GroupingSuggestions {
  items: string[]
}

export interface CUSTOM_GROUPING {
  groupingDetails: GroupingDetails | any
  groupingSuggestions: GroupingSuggestions | any
  loading: boolean
  groupingLoadingState: boolean
  loadingSuggestions: boolean
  loadingEntities: boolean
  loadingCGfile: boolean
  loadingFileGrouping: boolean
  error: any
  groupingOptions: any
  isGroupingSaved: boolean
  operationLogStarted: boolean
  groupingId: any
  dataIngestionGrouperId: any
  groupingFileUploadError: boolean
  groupingDuplicateAccountsError: boolean
  AIGroupedRows: number
  userGroupedRows: number
  incompleteRows: number
  hasRecommendations: boolean
}
export const INITIAL_CUSTOM_GROUPING_STATE: CUSTOM_GROUPING = {
  groupingDetails: null,
  groupingSuggestions: null,
  loading: false,
  loadingEntities: false,
  groupingLoadingState: false,
  loadingSuggestions: false,
  loadingCGfile: false,
  loadingFileGrouping: false,
  error: null,
  groupingOptions: {},
  isGroupingSaved: false,
  operationLogStarted: false,
  groupingId: '',
  dataIngestionGrouperId: '',
  groupingFileUploadError: false,
  groupingDuplicateAccountsError: false,
  AIGroupedRows: 0,
  userGroupedRows: 0,
  incompleteRows: 0,
  hasRecommendations: false,
}


const filterEmptyNominalCodes = (array) => {
  return array.filter((gC) => {
    return gC['nominalCode'] !== null
  })
}

export const getGroup = createAsyncThunk(
  'custom-grouping/getGroup',
  async ({ client, id }: { client: ApolloClient<unknown>; id: any }) => {
    const { data, errors } = await client.query({
      query: GET_GROUPING,
      variables: {
        groupId: id,
      },
    })
    if (errors) {
      throw errors
    }
    return data
  }
)

export const getGroupingOptions = createAsyncThunk(
  'custom-grouping/getGroupingOptions',
  async ({ client, fileName }: { client: ApolloClient<unknown>, fileName: string }) => {
    const { data, errors } = await client.query({
      query: GET_GROUPING_OPTIONS,
      variables: {
        fileName: fileName
      },
      fetchPolicy: 'no-cache'
    })
    if (errors) {
      throw errors
    }
    return data
  }
)

export const getGroupingSuggestions = createAsyncThunk(
  'custom-grouping/getGrouperRecommendations',
  async (payload: { grouping, client: ApolloClient<unknown> }) => {
    const { data, errors } = await payload.client.query({
      query: GET_GROUPING_RECOMMENDATIONS,
      variables: {
        items: payload.grouping.filter((item) => item.glAccountName !== null)
      }
    })

    if (errors) {
      throw errors
    }
    return data
  }
)


export const getCGfileGrouping: any = createAsyncThunk(
  'custom-grouping/fetchCGfileGrouping',
  async (payload: { formData: any; headers: any }, { rejectWithValue }) => {
    const TB_FILE_EXTRACT_URI = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/grouper/cg-file-extract`
    try {
      return await axios.post(TB_FILE_EXTRACT_URI, payload.formData, {
        headers: payload.headers,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCGfileGroupingCodes: any = createAsyncThunk(
  'custom-grouping/fetchCGfileGroupingCodes',
  async (payload: { formData: any; headers: any }, { rejectWithValue }) => {
    const TB_CDM_FILE_URI = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/grouper/get-cdm-file`
    try {
      return await axios.post(TB_CDM_FILE_URI, payload.formData, {
        headers: payload.headers,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const saveGroupings: any = createAsyncThunk(
  'custom-grouping/saveGroupings',
  async (
    payload: { client: ApolloClient<unknown>; variables: any },
    { rejectWithValue }
  ) => {
    const { data, errors } = await payload.client.mutate({
      mutation: SAVE_GROUPING,
      variables: payload.variables,
    })

    if (errors) {
      return rejectWithValue(errors)
    }
    return data
  }
)

const checkDuplicateAccounts = (values) => {
  const unique = values.filter((item, index) => {
    return values.findIndex(t => t.glAccountName === item.glAccountName && t.glAccountNumber === item.glAccountNumber) === index
  })
  return unique
}

const handleMultipleRecordsUniqueness = (GCfiles) => {
  const groupedGC = _(GCfiles)
    .groupBy('nominalCode')
    .map(objects => _.mergeWith([...objects], (a, b) => a || b))
    .value();
  const uniqValues = [];

  groupedGC.forEach((arr) => {
    arr.filter((item, index) => { console.log(item) })
    const unique = arr.filter((item, index) => { return item.glAccountName !== null })
    unique.length > 1
      ? uniqValues.push(...checkDuplicateAccounts(unique)) // If the glAccountName differs, all items are pushed;
      : unique.length > 0
        ? uniqValues.push(unique[0]) // If a unique glAccountName value is found, push that item;
        : uniqValues.push(arr[0]) // If neither has a glAccountName, just push the first grouped item           
  })

  return uniqValues
}

export const beginDataIngestionGroup = createAsyncThunk<
  any,
  { client: ApolloClient<unknown>, dataIngestionGrouperId: string },
  { state: { customGrouper: CUSTOM_GROUPING } }
>(
  'dataIngestion/beginDataIngestionGroup',
  async ({ client, dataIngestionGrouperId }, { rejectWithValue }) => {
    try {
      if (dataIngestionGrouperId) {
        const formData = {
          beginDataIngestionGroupId: dataIngestionGrouperId,
        }
        const response = await client.mutate({
          mutation: BEGIN_DATAINGESTION_GROUP,
          variables: formData,
        })
        return response
      } else {
        return rejectWithValue({
          dataIngestionGrouperId,
        })
      }
    } catch (error) {
      return rejectWithValue({} as any)
    }
  }
)

export const customGrouperSlice = createCustomGrouperSlice(
  INITIAL_CUSTOM_GROUPING_STATE
)

export function createCustomGrouperSlice(initialState: CUSTOM_GROUPING) {
  return createSlice({
    name: 'custom-grouping',
    initialState,
    reducers: {
      setRecommendationsAvailable: (state, { payload }) => {
        state.hasRecommendations = payload
      },
      updateRow: (state, { payload: { fieldData, row, autoAssign, glMapNumber } }) => {
        if (state?.groupingDetails && state?.groupingDetails?.groupingCodes) {
          const index = row.index
          if (index > -1) {
            const rows = state.groupingDetails.groupingCodes
            rows[index] = {
              ...rows[index],
              ...fieldData,
              ...(glMapNumber && { glMapNumber: glMapNumber })
            }
            const flag = rows[index]['accountTypeId'] != -1 &&
              rows[index]['accountSubTypeId'] != -1 &&
              rows[index]['fsCaptionId'] != -1 &&
              rows[index]['accountNameId'] != -1

            if (Object.keys(fieldData)[0] !== 'selected') {
              if (!flag) {
                rows[index]['mapped'] = 'no'
              } else {
                if (autoAssign) {
                  rows[index]['mapped'] = 'AutoAssigned'
                } else {
                  rows[index]['mapped'] = 'yes'
                }
              }
            }

            rows[index]['index'] = index
          }
        }
      },
      updateRowStatus: (state, { payload: { row, status } }) => {

        const rows = state.groupingDetails.groupingCodes
        rows[row.index]['mapped'] = status

      },
      resetCustomGrouperState: (state) => {
        return INITIAL_CUSTOM_GROUPING_STATE
      },
      removeAutoGroups: (state) => {
        const records = state?.groupingDetails?.groupingCodes.map((record: CUSTOM_GROUPING_DATA) => {
          if (record.mapped === 'AutoAssigned') {
            return {
              ...record,
              accountTypeId: -1,
              accountSubTypeId: -1,
              fsCaptionId: -1,
              accountNameId: -1,
              accountName: -1,
              accountType: -1,
              accountSubType: -1,
              fsCaption: -1,
              mapped: 'no',
            }
          } else {
            return { ...record }
          }
        })
        state.groupingDetails['groupingCodes'] = records
      },
      clearGrouping: (state) => {
        const resp = state?.groupingDetails?.groupingCodes.map(
          (record: CUSTOM_GROUPING_DATA) => ({
            ...record,
            accountTypeId: -1,
            accountSubTypeId: -1,
            fsCaptionId: -1,
            accountNameId: -1,
            accountName: -1,
            accountType: -1,
            accountSubType: -1,
            fsCaption: -1,
            mapped: 'no',
          })
        )
        if (state.groupingDetails) state.groupingDetails['groupingCodes'] = resp
      },
      resetGrouping: (state) => {
        state.groupingDetails = null
      },
      setGroupingFileUploadError: (state, { payload }) => {
        state.groupingFileUploadError = payload
      },
      setgroupingDuplicateAccountsError: (state, { payload }) => {
        state.groupingDuplicateAccountsError = payload
      },
      importGroupings: (state, action) => {
        state.groupingDetails = { ...state.groupingDetails, groupingCodes: action.payload.groupingCodes }
      },
      countRows: (state) => {
        state.AIGroupedRows = state.groupingDetails?.groupingCodes?.filter((row) => row.mapped === 'AutoAssigned').length ?? 0
        state.userGroupedRows = state.groupingDetails?.groupingCodes?.filter((row) => row.mapped === 'yes').length ?? 0
        state.incompleteRows = state.groupingDetails?.groupingCodes?.filter((row) => row.mapped === 'no').length ?? 0
      },
      isLoading: (state, { payload }) => {
        state.loading = payload
      },
      isLoadingEntities: (state, { payload }) => {
        state.loadingEntities = payload
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getGroup.pending, (state, action) => {
        state.loading = true
      })
      builder.addCase(getGroup.fulfilled, (state, { payload }) => {
        state.loading = false
        const { grouping } = payload
        if (!state.groupingDetails) {
          state.groupingDetails = {
            ...grouping,
            groupingCodes: grouping.groupingCodes.map(
              ({ mapped, ...gC }, index) => ({
                ...gC,
                index,
                mapped: mapped ? 'yes' : 'no',
              })
            ),
          }
        } else {
          const { groupingCodes } = state.groupingDetails
          state.groupingDetails.groupingCodes = groupingCodes.map(
            (gC, index) => {
              const existingItem = grouping.groupingCodes.find(
                (item) =>
                  item?.glAccountName?.trim() === gC?.glAccountName?.trim() &&
                  item?.nominalCode === gC?.nominalCode &&
                  item?.mapped
              )
              if (existingItem) {
                const { nominalCode, grouperId, mapped, __typename, ...rest } =
                  existingItem
                return {
                  nominalCode: nominalCode,
                  mapped: mapped ? 'yes' : 'no',
                  index,
                  ...rest,
                }
              } else {
                return gC
              }
            }
          )
        }
      })
      builder.addCase(getGroup.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      builder.addCase(getGroupingSuggestions.pending, (state, action) => {
        state.loadingSuggestions = true
        state.groupingSuggestions = null
      })
      builder.addCase(getGroupingSuggestions.fulfilled, (state, { payload }) => {
        state.loadingSuggestions = false
        state.groupingSuggestions = payload?.getGrouperRecommendations?.items
      })
      builder.addCase(getGroupingSuggestions.rejected, (state, { payload }) => {
        state.loadingSuggestions = false
        state.error = payload
      })
      builder.addCase(beginDataIngestionGroup.pending, (state, action) => {
        state.loading = true
        state.groupingLoadingState = true
        state.operationLogStarted = false
      })
      builder.addCase(beginDataIngestionGroup.fulfilled, (state, { payload }) => {
        state.loading = false
        state.groupingLoadingState = false
        state.isGroupingSaved = true
      })
      builder.addCase(beginDataIngestionGroup.rejected, (state, { payload }) => {
        state.loading = false
        state.groupingLoadingState = false
        state.error = payload
      })
      builder.addCase(getGroupingOptions.pending, (state, action) => {
        state.groupingOptions = {}
        return state
      })
      builder.addCase(getGroupingOptions.fulfilled, (state, action) => {
        if (action?.payload?.groupingOptions) {
          const dependancyFieldsMap = {
            accountNames: {
              foreignField: 'fsCaptionId',
              labelField: 'accountName',
              valueField: 'id',
            },
            fsCaptions: {
              foreignField: 'accountSubTypeId',
              labelField: 'fsCaption',
              valueField: 'id',
            },
            accountSubTypes: {
              foreignField: 'accountTypeId',
              labelField: 'accountSubType',
              valueField: 'id',
            },
          }
          const filteredGroupingOptions = {}
          for (const key in action?.payload?.groupingOptions) {
            if (key !== '__typename' && key !== 'accountTypes') {
              const { foreignField, labelField, valueField } =
                dependancyFieldsMap[key]
              const dataArray = action?.payload?.groupingOptions[key]
              filteredGroupingOptions[key] = dataArray.reduce(
                (prev, curr, i) => {
                  if (!prev[curr[foreignField]]) {
                    prev[curr[foreignField]] = []
                  }
                  prev[curr[foreignField]].push({
                    label: curr[labelField],
                    value: curr[valueField],
                    ...(curr['glMapNumber'] && {
                      glMapNumber: curr['glMapNumber'],
                    }),
                  })
                  return prev
                },
                {}
              )
            }
          }
          const { accountTypes } = action.payload.groupingOptions

          filteredGroupingOptions['accountTypes'] = accountTypes.map(
            ({ accountType, id }) => ({
              label: accountType,
              value: id,
            })
          )

          state.groupingOptions = filteredGroupingOptions
        }
      })
      builder.addCase(getGroupingOptions.rejected, (state, action) => {
        state.error = action.payload
      })
      builder.addCase(getCGfileGrouping.pending, (state) => {
      })
      builder.addCase(getCGfileGrouping.fulfilled, (state, action) => {
        const currGroupingDetails = state.groupingDetails?.groupingCodes.length > 0 ? [...current(state.groupingDetails?.groupingCodes)] : []
        const uniqValues: any[] = handleMultipleRecordsUniqueness(action.payload.data)

        //Add the missing fields to the response to create a complete Row data object
        const resp = uniqValues.map((record, index) => ({
          ...record,
          accountTypeId: record.accountTypeId ? record.accountTypeId : -1,
          accountSubTypeId: record.accountSubTypeId ? record.accountSubTypeId : -1,
          fsCaptionId: record.fsCaptionId ? record.fsCaptionId : -1,
          accountNameId: record.accountNameId ? record.accountNameId : -1,
          mapped: record.mapped ? record.mapped : 'no'
        }))

        state.groupingDetails = {
          groupingCodes: []
        }

        const uniqueGroupingCodes = currGroupingDetails && handleMultipleRecordsUniqueness([...resp, ...currGroupingDetails])

        //Index all grouping codes properly
        const indexedGroupingCodes = currGroupingDetails.length > 0
          ? filterEmptyNominalCodes(uniqueGroupingCodes).map((gC, i) => ({
            ...gC,
            index: i
          }))
          : filterEmptyNominalCodes(resp).map((gC, i) => ({
            ...gC,
            index: i
          }))

        state.groupingDetails['groupingCodes'] = indexedGroupingCodes
        state.groupingFileUploadError = indexedGroupingCodes.length === 0
      })
      builder.addCase(getCGfileGrouping.rejected, (state, action) => {
        if (action.payload.response.data.detail === "Invalid file contains duplicate 'glAccountNumber' or 'glAccountName'") {
          state.groupingDuplicateAccountsError = true
        } else {
          state.groupingFileUploadError = true
        }
      })
      builder.addCase(getCGfileGroupingCodes.pending, (state, action) => {
        state.loadingCGfile = true
      })
      builder.addCase(getCGfileGroupingCodes.fulfilled, (state, action) => {
        const currGroupingCodes = state.groupingDetails?.groupingCodes;

        const resp = filterEmptyNominalCodes(action.payload.data.data).map((record, index) => ({
          ...record,
          accountTypeId: -1,
          accountSubTypeId: -1,
          fsCaptionId: -1,
          accountNameId: -1,
          index: index,
          mapped: 'no'
        }))
        state.groupingDetails = {}

        const fullres = currGroupingCodes?.concat((resp))
        const uniqueGroupingCodes = fullres && fullres.filter((item, index) => {
          return fullres.findIndex(t => t.glAccountName === item.glAccountName) === index
        })

        const gCodes = currGroupingCodes
          ? uniqueGroupingCodes
          : resp

        state.groupingDetails['groupingCodes'] = gCodes.length > 0
          ? gCodes
          : []
        state.groupingFileUploadError = gCodes.length === 0
        state.loadingCGfile = true
      })
      builder.addCase(getCGfileGroupingCodes.rejected, (state, action) => {
        state.loadingCGfile = false
        state.groupingFileUploadError = true
      })
      builder.addCase(saveGroupings.pending, (state) => {
        state.loading = true
      })
      builder.addCase(saveGroupings.fulfilled, (state, action) => {
        state.loading = false
        state.groupingDetails = null
        state.groupingId = action.payload?.saveGrouping?.id
        state.dataIngestionGrouperId = action.payload?.saveGrouping?.dataIngestionGrouperId
        if (!state.dataIngestionGrouperId)
          state.isGroupingSaved = action.payload?.saveGrouping?.success
      })
      builder.addCase(saveGroupings.rejected, (state) => {
        state.loading = false
      })
    },
  })
}
export const {
  updateRow,
  resetCustomGrouperState,
  clearGrouping,
  removeAutoGroups,
  updateRowStatus,
  setGroupingFileUploadError,
  setgroupingDuplicateAccountsError,
  resetGrouping,
  importGroupings,
  countRows,
  isLoading,
  isLoadingEntities,
  setRecommendationsAvailable
} = customGrouperSlice.actions
export const customGrouperReducer = customGrouperSlice.reducer
