import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { 
  Grid, 
  CircularProgress,
  Typography, 
  Button, 
  Tooltip, 
  makeStyles, 
  FormHelperText 
} from '@material-ui/core'

import {
  getClientConnections,
  getERPtypes,
  useIESelector,
  useIEDispatch,
  createERPConnection,
  updateERPConnection,
  updatePostAuthOptions,
  getPostAuthOptions,
  getConnectionPreAuthOptions,
  createERP,
  resetCreationForms
} from '@engine-b/integration-engine/data/state/redux'
import { FileCopy, Refresh } from '@material-ui/icons'
import { EBInput, EBAutoComplete, EBButton } from '@engine-b/shared/components'
import { NotFoundView } from '../../views/NotFoundView/NotFoundView'
import {
  NEW_UI_INPUT_WIDTH,
  INPUT_WIDTH,
  Description,
} from '@engine-b/integration-engine/ui/form-components'
import {   
  ERP_STATUS_LABELS,
  erp_status
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import { JsonForms } from '@jsonforms/react';
import _ from 'lodash'
import { vanillaRenderers, vanillaCells } from '@jsonforms/vanilla-renderers'
import { 
  DateTimePickerControl, 
  DatePickerControl, 
  DropdownControl,
  TextFieldControl,
  GroupControl,
  DateTimePickerTester, 
  DatePickerTester,
  DropdownTester,
  TextFieldTester,
  GroupTester,
} from '../../components/jsonForms'
import { createDefaultAjv } from 'apps/integration-engine-temporal/api-extraction/src/defaultAJV'


const useStyles = makeStyles((theme) => ({ 
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 80px)',
    maxWidth: 'calc(100vw - 280px)',
    padding: '30px',
    background: `#F0FBFA 0% 0% no-repeat padding-box`,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      rowGap: '16px',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& > label': { width: '100px' },
    },
    '& .status-text': {
      width: '292px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .MuiTextField-root': {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'hsla(205, 34%, 34%, 1)',
      },
      width: NEW_UI_INPUT_WIDTH + 'px',
      borderRadius: '8px',
      '& .MuiInputBase-root': {
        height: '44px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.5px solid ' + theme.palette.secondary.light,
        borderRadius: '8px',
      },
    },
  },
  form: {
    '& .MuiTypography-h6': {
      marginBottom: '0px'
    }
  },
  okButtonDisable: {
    background: '#e0e0e0',
    color: theme.palette.common.white,
  },
  okButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  cancelButton: {
    background: 'hsla(201,17%,61%,1)',
    color: theme.palette.common.white
  },
  title: {
    marginBottom: '40px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    lineHeight: '37px',
    letterSpacing: '0.3px',
    color: '#22353F',
    opacity: 1,
  },
  dialogContent: {
    paddingTop: '40px',
  },
  dialogClosebutton: {
    minWidth: "auto"
  },
  DateIcon: {
    position: 'absolute',
    right: '2.5px',
    top: '8.5px',
    cursor: 'pointer',
    fill: theme.palette.secondary.main,
  },
  higherLevelHeader: {
    textTransform: "capitalize",
    font: "normal normal bold 16px/18px Arial",
    color: "#44697D",
    marginBottom: "0",
    letterSpacing: "0.16px",
    verticalAlign: "center",
  },
  DatePicker: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '8px',
    cursor: 'pointer',
    border: '0.5px solid ' + theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    width: NEW_UI_INPUT_WIDTH + 'px',
    height: '44px',

    '& .react-date-picker--open': {
      borderBottom: '2px solid ' + theme.palette.secondary.light,
    },

    '& .react-date-picker__inputGroup': {
      cursor: 'pointer',
      minWidth: `${INPUT_WIDTH}px`,
    },

    '& .react-date-picker__calendar': {
      width: '230px',
      '& .react-calendar__month-view__days': {
        height: '210px',
      },
    },
    '& .react-date-picker__wrapper': {
      border: 'none',
      padding: '10.5px 14px',
    },
    '& .react-date-picker__inputGroup__input:focus-visible': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:focus': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:active': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:invalid': {
      backgroundColor: 'transparent',
    },
    '& .react-calendar__tile': {
      borderRadius: '20px',
    },
    '& .react-calendar__tile--active': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-calendar__tile--active:enabled:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-calendar__tile--now': {
      backgroundColor: theme.background.secondary.active,
    },
    '& .react-calendar__tile--now:enabled:hover': {
      backgroundColor: theme.background.secondary.active,
    },
    '& .react-date-picker__inputGroup__input::focus': {
      border: 'none',
    },
  },
}))

export const ErpConnectionView = () => {
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const ajvInstance = createDefaultAjv()

  const [copySuccess, setCopySuccess] = useState(false)
  const [clientAuthUrl, setClientAuthUrl] = useState("")
  const [connectionStatus, setConnectionStatus] = useState("DISCONNECTED");
  const [expiry, setExpiry] = useState("");
  const [erpName, setErpName] = useState("");
  const [erpId, setErpId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [preAuthConfig, setPreAuthConfig] = useState({})
  const [postauthConfig, setPostauthConfig] = useState({})
  const [system, setSystem] = useState("")
  const [configErrors, setConfigErrors] = useState([])
  const [type, setType] = useState("");
  const [configData, setConfigData] = useState({})
  const [connectionPreAuthConfigData, setConnectionPreAuthConfigData] = useState({})
  const [postAuthSchema, setPostAuthSchema] = useState({
    type: 'object',
    properties: {},
  })
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const urlParams = useParams<{ id: string }>()
  const extractions = useIESelector((state) => state.extractions)
  let history = useHistory();
  const { formatMessage, locale } = useIntl()
  const client = useApolloClient()
  const location = useLocation()

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000) // reset notification after 2 seconds
    } catch (err) {
      setErrorMessage(`Failed to copy: ${err}`)
    }
  }

  const addOrUpdateERP = async () => {
    if(erpName.length > 0 ) {
      if (erpId.length > 0) {
          if(!_.isEmpty(configData)) { 
              await dispatch(updatePostAuthOptions({
                client, 
                id: erpId,
                configData: configData,
              })
            )
           } 
          await dispatch(updateERPConnection({client, variables: {
            name: erpName,
            id: erpId,
            }}
          )).then(() => {
            history.push(localizeRouteKey(formatMessage, locale, AppRoute.ExtractionsSettings))
            dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
          })
      } else if (type?.length > 0) {
          await dispatch(createERPConnection({client, variables: {
              name: erpName,
              engagementId: extractions.engagement.id,
              system: type,
              authorisationUrl: location.pathname,
              preAuthConfigValues: connectionPreAuthConfigData
            }}
          )).then(() => {
            dispatch(resetCreationForms({client}))
            history.push(localizeRouteKey(formatMessage, locale, AppRoute.ExtractionsSettings))
            dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
          })
      }
    } else {
      setErrorMessage("Please fill out the required fields.")
    }
  }

  const PreAuthConfigRenderer = ({preAuthConfig}) => {
    return (
      <Grid container spacing={2}>
        {Object.keys(preAuthConfig).map(config => {
          return (
            <Grid container item style={{padding: "8px", display: "block", marginBottom: "8px"}} key={config}>
              <Grid item style={{flexDirection: "column", alignItems: "flex-start"}}>
                <Typography className={classes.higherLevelHeader} style={{marginBottom: "8px"}}>{config}</Typography>
              </Grid>
              <Grid  item style={{flexDirection: "column", alignItems: "flex-start"}}>
                {_.isObject(preAuthConfig[config]) 
                  ? <PreAuthConfigRenderer preAuthConfig={preAuthConfig[config]}/>
                  : <Typography >{preAuthConfig[config]} </Typography>
                }
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  
  useEffect(() => {
    if (!extractions.auditedEntity.auditFirm) {
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.Extractions))
    }
  }, [])

  const  {
    erps_types: erpTypes, 
    postAuthOptions, 
    clientConnections: erpConnection, 
    loading
  } = useIESelector((state) => state.extractions)

  useEffect(() => {
    dispatch(getERPtypes({client}))
    dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
    dispatch(getPostAuthOptions({client, connectionId: urlParams.id}))
  }, [])

  useEffect(() => {
    dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
  }, [])

  useEffect(() => {
    if (urlParams.id) {
      dispatch(getPostAuthOptions({client, connectionId: urlParams.id}))
    }
  }, [urlParams.id])

  useEffect(() => {
    if(postAuthOptions[erpId]) {
      setPostAuthSchema(postAuthOptions[erpId])
    }
  }, [postAuthOptions, erpId])

  useEffect(() => {
    const connection = erpConnection.filter((erp) => (erp.id === urlParams.id))

    if(connection[0]) {
      setClientAuthUrl(connection[0].authorisationUrl + "/" + connection[0].authorisationId)
      setConnectionStatus(connection[0].state)
      setExpiry(connection[0].expirationDateTime)
      setErpName(connection[0].name)
      setErpId(connection[0].id)
      setSystem(connection[0].system)
      setPreAuthConfig(connection[0].preAuthConfigValues)
      setPostauthConfig(connection[0].postAuthConfigValues)
      dispatch(getConnectionPreAuthOptions({client: client, connectionERP: connection[0].system}))
    }
  }, [erpConnection])

  useEffect(() => {
    dispatch(getConnectionPreAuthOptions({client: client, connectionERP: type}))
  }, [type])

  useEffect(() => {
    setDisableSubmitButton(disableButton())
  }, [
    erpName, 
    type, 
    configData, 
    configErrors,
    connectionPreAuthConfigData, 
    connectionStatus
  ])

  const disableButton = () => {
    const existingConnection = (connectionStatus !== "DISCONNECTED");

    if (existingConnection) {
      if (!_.isEmpty(postAuthSchema.properties) && connectionStatus === erp_status.AWAITING_POST_AUTH_CONFIG) {
        return _.isEmpty(configData) || erpName.length <=0
      }
      return (erpName.length === 0)
    } else {
      if (!_.isEmpty(extractions.connectionPreAuthOptions)) {
        return ( erpName?.length === 0 || 
          type?.length === 0 || 
          configErrors.length > 0 
        )
      } 
      return (erpName?.length === 0 || type?.length === 0)
    }
  }

  const renderers = [
    ...vanillaRenderers,
    { tester: DropdownTester, renderer: DropdownControl },
    { tester: DatePickerTester, renderer: DatePickerControl },
    { tester: DateTimePickerTester, renderer: DateTimePickerControl },
    { tester: TextFieldTester, renderer: TextFieldControl },
    { tester: GroupTester, renderer: GroupControl },
  ]

  const erpTypeCreate = (e, value) => {
    if (value === null || value === undefined) {
      setType("")
    } else {
      setType(value)
      dispatch(createERP({ name: erpName, type: value }));
    }
  };

  return (
    process.env.NX_ENABLE_API_EXTRACTIONS === 'true' 
    ? (
      <>
        <section className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography className={classes.title} variant={'h4'} color={'textPrimary'}>
                Add/Update ERP Connection
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.form}>
          {erpName?.length === 0 && type?.length === 0 && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
          <Grid item xs={12}>
            <Description title={'Name'} required>
              <EBInput
                width={NEW_UI_INPUT_WIDTH + 'px'}
                color="secondary"
                value={erpName}
                onChange={(e) => setErpName(e.target.value)}
              />
              
            </Description>
          </Grid>
          <Grid item xs={12}>
            {connectionStatus === "DISCONNECTED" && (
              <Description title={'Type'} required>
                <EBAutoComplete
                  forcePopupIcon
                  width={NEW_UI_INPUT_WIDTH}
                  height={44}
                  color="secondary"
                  size="small"
                  backgroundColor="#fff"
                  placeholder="Select Type"
                  options={erpTypes}
                  value={type}
                  onChange={(e, value) => erpTypeCreate(e, value)}
                />
              </Description>
            )}
          </Grid>
            {clientAuthUrl.length > 0 && (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Description title={'Client Auth URL'}>
                  <Typography className="status-text">{clientAuthUrl}</Typography>
                  <Tooltip title={copySuccess ? 'Copied!' : 'Copy'} placement="top">
                    <Button onClick={() => copyToClipboard(clientAuthUrl)}>
                      <Refresh />
                    </Button>
                  </Tooltip>
                </Description>
              </Grid>
            )}

            {expiry.length > 0 && (
              <Grid item xs={12}>
                <Description title={'Expires'}>
                  <Typography className="status-text">{`${expiry}`}</Typography>
                  <Tooltip title="Refresh" placement="top">
                    <Button>
                      <Refresh />
                    </Button>
                  </Tooltip>
                </Description>
              </Grid>
            )}

            {system !== '' && (
              <Grid item xs={12}>
                <Description title={'System'}>
                  <Typography>{system}</Typography>
                </Description>
              </Grid>    
            )} 

            {(connectionStatus !== 'DISCONNECTED' &&
              <Grid item xs={12}>
                <Description title={'Status'}>
                  <Typography>{ERP_STATUS_LABELS[connectionStatus]}</Typography>
                </Description>
              </Grid>
            )}

            {!_.isEmpty(preAuthConfig) && (
              <Grid item xs={12} style={{marginTop: '16px'}}>
                <PreAuthConfigRenderer preAuthConfig={preAuthConfig}/>
              </Grid>
            )}

            {!_.isEmpty(postauthConfig) && (
              <Grid item xs={12} style={{marginTop: '16px'}}>
                <PreAuthConfigRenderer preAuthConfig={postauthConfig}/>
              </Grid>
            )}
            
            {
              !_.isEmpty(extractions.connectionPreAuthOptions) && 
              type !== '' && 
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant={'h6'} color={'textPrimary'} style={{marginTop: "15px"}}>Pre-Auth Configuration Options</Typography>
                </Grid>
                <Grid item xs={12} data-test-id="pre-auth-form">
                  <JsonForms
                    schema={extractions.connectionPreAuthOptions}
                    data={connectionPreAuthConfigData}
                    renderers={renderers}
                    ajv={ajvInstance}
                    cells={vanillaCells}
                    onChange={({data, errors}) => {
                      setConnectionPreAuthConfigData(data)
                      setConfigErrors(errors)
                    }}
                  />
                </Grid>
              </Grid>
            }

            <Grid item container xs={12}>
              { (connectionStatus === erp_status.AWAITING_POST_AUTH_CONFIG && !_.isEmpty(postAuthSchema.properties)) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant={'h6'} color={'textPrimary'} style={{marginTop: "15px"}}>Configuration Options</Typography>
                  </Grid>
                  <Grid item xs={12} data-testid="post-auth-form">
                      <JsonForms
                        schema={postAuthSchema}
                        data={configData}
                        renderers={renderers}
                        ajv={ajvInstance}
                        cells={vanillaCells}
                        onChange={({ data, errors }) => {
                          setConfigData( data )
                          setConfigErrors(errors)
                        }}
                      />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={12} style={{ justifyContent: 'right' }} data-testid="form-controls-section">
              <Button
                style={{
                  width: '140px',
                  height: '44px',
                  textAlign: 'center',
                  lineHeight: '32px',
                  marginRight: '8px',
                }}
                onClick={() => window.history.go(-1)}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: '140px',
                  height: '44px',
                  textAlign: 'center',
                  lineHeight: '32px',
                  marginRight: '8px',
                }}
                onClick={() => addOrUpdateERP()}
                disabled={disableSubmitButton}
                color="secondary"
                variant="contained"
                data-testid="ok-button"
              >
                Ok
              </Button>
              {
                loading 
                ? (<CircularProgress data-testid="loading-component"/>) 
                : <></>
              }
            </Grid>
          </Grid>
        </section>
      </>
    ) : (
      <NotFoundView/>
    )
  )
}

export default ErpConnectionView
