import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  useIEDispatch,
  useIESelector,
  deleteFileValidation,
  CdmEntityCollection,
  addFileMapping,
  removeManualSelections,
} from '@engine-b/integration-engine/data/state/redux'
import { FileMappingEntry } from './FileMappingEntry'
import { DropDown } from '@engine-b/integration-engine/ui/form-components'
import BinIcon from './assets/bin-icon.svg'
import BinOpenIcon from './assets/bin-open-icon.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      background: '#fff',
      padding: '0.5em 1em',
      borderRadius: '4px',
      marginBottom: '1em',
    },
    reportHeader: {
      position: 'relative',
    },
    deleteIcon: {
      width: '22px',
      height: '20px',
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      backgroundImage: `url(${BinIcon})`,
      backgroundSize: 'cover',
      '&:hover': {
        backgroundImage: `url(${BinOpenIcon})`,
      },
    },
  })
)

interface Props {
  cdmEntityCollection: CdmEntityCollection
  selectedCdmEntity: string
  onRemoveRow: (x: string) => void
  source: Object
}

export function FileMappingRow(props: Props) {
  const { cdmEntityCollection, selectedCdmEntity, onRemoveRow, source } = props
  const classes = useStyles()

  const extractType = useIESelector((state) => state.runs.draftRun?.extractType)
  const dispatch = useIEDispatch()

  const cdmEntityOptions = Object.values(cdmEntityCollection).map((e) => ({
    id: e.systemName,
    label: e.name,
  }))

  const handleChangeEntity = (cdmEntityId) => {
    const fileNames = cdmEntityCollection[cdmEntityId].inputFiles.map(
      (inputFile) => inputFile.name
    )
    dispatch(addFileMapping(fileNames))
  }

  const handleDeleteEntity = () => {
    onRemoveRow(selectedCdmEntity)
    dispatch(
      deleteFileValidation({
        extractType: extractType,
        reportType: selectedCdmEntity,
      })
    )
    dispatch(removeManualSelections(selectedCdmEntity))
  }

  return (
    <div className={classes.row}>
      <div className={classes.reportHeader}>
        <DropDown
          label={'Report type'}
          onChange={handleChangeEntity}
          options={cdmEntityOptions}
          placeholderLabel="Select Report Type"
          value={selectedCdmEntity}
          disabled={true}
        />
        <div className={classes.deleteIcon} onClick={handleDeleteEntity} />
      </div>
      <div>
        {cdmEntityCollection && cdmEntityCollection[selectedCdmEntity]
          ? cdmEntityCollection[selectedCdmEntity].inputFiles.map(
              (inputFile) => (
                <FileMappingEntry
                  key={inputFile.name}
                  cdmFileName={inputFile.name}
                  reportDetail={cdmEntityCollection[selectedCdmEntity]}
                  source={source}
                  handleDeleteEntity={handleDeleteEntity}
                />
              )
            )
          : null}
      </div>
    </div>
  )
}
