import React, { useCallback, useEffect, useRef } from 'react'
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  useOnSelectionChange,
  useStoreApi,
} from 'reactflow'
import cuid from 'cuid'
import {
  addMoreExpression,
  useIESelector,
  useIEDispatch,
  updateActiveExpression,
  removeExpression,
  updateExpressionPosition,
} from '@engine-b/integration-engine/data/state/redux'
import { Button } from '@material-ui/core'

import 'reactflow/dist/style.css'
import InputNode from './Nodes/InputNode'
import ConnectionLine from './Nodes/ConnectionLine'
import OutputNode from './Nodes/OutputNode'
import ResultNode from './Nodes/ResultNode'
import DefaultNode from './Nodes/DefaultNode'

const nodeTypes = {
  customInput: InputNode,
  customOutput: OutputNode,
  customResult: ResultNode,
  defaultResult: DefaultNode,
}

const MainFlow = () => {
  const reactFlowWrapper = useRef(null)
  const connectingNodeId = useRef(null)
  const store = useStoreApi()
  const dispatch = useIEDispatch()
  const { initialNodes, expressions, activeExpression, initialEdges } =
    useIESelector((state) => state.conditionalColumn)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const { screenToFlowPosition } = useReactFlow()

  const onConnect = useCallback((params) => {
    // reset the start node on connections
    connectingNodeId.current = null
    setEdges((eds) => addEdge(params, eds))
  }, [])

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId
  }, [])

  /**
   * Get connected nodes of parent node
   * @param nds nodes
   * @param egs edges
   * @param ndId node Id
   * @returns child nodes of provided node
   */
  const getConnectedNodes = (nds, egs, ndId) => {
    const connectedEdges = egs?.filter((ed) => ed.source === ndId)
    const edgeIds = connectedEdges?.map((edg) => edg.target)
    if (edgeIds?.length > 0) {
      return nds?.filter((nd) => edgeIds.includes(nd.id))
    }
    return []
  }

  /**
   * Add new node on connection line drop.
   */
  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return
      const targetIsPane = event.target.classList.contains('react-flow__pane')
      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = cuid()
        let expressionType = 'result'
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          type: 'customResult',
          data: { label: `Result` },
        }

        const connectedNode = getConnectedNodes(
          nodes,
          edges,
          connectingNodeId.current
        )
        if (
          connectedNode?.length > 0 &&
          connectedNode[0]?.type === 'customResult'
        ) {
          newNode.type = 'customOutput'
          newNode.data = { label: 'Condition' }
          expressionType = 'condition'
        }

        setNodes((nds) => nds.concat(newNode))
        setEdges((eds) =>
          eds.concat({
            id,
            source: connectingNodeId.current,
            target: id,
            label: connectedNode[0]?.type === 'customResult' ? 'False' : 'True',
            labelStyle: {
              fill:
                connectedNode[0]?.type === 'customResult'
                  ? '#FF0072'
                  : '#3cb2aa',
            },
            style: {
              stroke: newNode.type === 'customResult' ? '#3cb2aa' : '#FF0072',
            },
            type: 'smoothstep',
            animated: true,
          })
        )

        dispatch(
          addMoreExpression({
            id,
            expressionType,
            parentNode: connectingNodeId.current,
            position: newNode?.position,
          })
        )
      }
    },
    [screenToFlowPosition, nodes, edges]
  )

  /**
   * Node selection handler method
   */
  useOnSelectionChange({
    onChange: ({ nodes: nds }) => {
      if (nds.length > 0) {
        const nodeId = nds[0].id
        const index = nodes.findIndex((node) => node.id === nodeId)
        dispatch(updateActiveExpression({ index }))
      } else {
        dispatch(updateActiveExpression({ index: -1 }))
      }
    },
  })

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node, i) => {
        if (expressions[i]?.conditionLabel !== undefined)
          if (node.id === expressions[i]?.id) {
            return {
              ...node,
              data: {
                ...node.data,
                label: expressions[i]?.conditionLabel,
                error: expressions[i]?.error,
              },
            }
          }
        return node
      })
    )
  }, [
    expressions[activeExpression]?.conditionLabel,
    expressions[activeExpression]?.error,
    setNodes,
    expressions,
  ])

  useEffect(() => {
    const { addSelectedNodes } = store.getState()
    if (activeExpression > -1) {
      const id = expressions[activeExpression]?.id
      const temp = nodes.find((node) => node.id === id)
      if (temp) addSelectedNodes([temp.id])
    } else {
      addSelectedNodes([])
    }
  }, [activeExpression])

  /**
   * Adds a new node to the pane
   * @param type Type of node to be added
   */
  const addNode = (type: string) => {
    const parentNode = nodes.find(
      (node) => node.id === expressions[activeExpression]?.id
    )
    if (parentNode) {
      const id = cuid()
      let expressionType = 'condition'
      const newNode = {
        id,
        position: {
          x: parentNode?.position?.x + parentNode?.width + 150,
          y: parentNode?.position?.y,
        },
        type: 'customOutput',
        data: { label: `Condition` },
      }
      if (type === 'result') {
        newNode.type = 'customResult'
        newNode.data = { label: 'Result' }
        expressionType = 'result'
        newNode.position = {
          x: parentNode?.position?.x + parentNode?.width + 150,
          y: parentNode?.position?.y + 100,
        }
      } else if (type === 'default') {
        newNode.type = 'defaultResult'
        newNode.data = { label: 'Default' }
        expressionType = 'default'
        newNode.position = {
          x: parentNode?.position?.x + parentNode?.width + 150,
          y: parentNode?.position?.y - 100,
        }
      }
      setNodes((nds) => nds.concat(newNode))
      setEdges((eds) =>
        eds.concat({
          id,
          source: expressions[activeExpression]?.id,
          target: id,
          label: type === 'result' ? 'True' : 'False',
          type: 'smoothstep',
          style: {
            stroke: type === 'result' ? '#3cb2aa' : '#FF0072',
          },
          labelStyle: { fill: type === 'result' ? '#3cb2aa' : '#FF0072' },
          animated: true,
        })
      )
      dispatch(
        addMoreExpression({
          id,
          expressionType,
          parentNode: expressions[activeExpression]?.id,
          position: newNode?.position,
        })
      )
    }
  }

  /**
   * Checks if parent node already has type of child
   * @param type Type of child
   * @returns Boolean if parent has child
   */
  const parentHas = (type: string) => {
    if (activeExpression === -1) {
      return true
    }
    if (['result', 'default'].includes(expressions[activeExpression]?.type))
      return true

    const connectedNodes = getConnectedNodes(
      nodes,
      edges,
      expressions[activeExpression]?.id
    )
    const connectedNodeTypes = connectedNodes.map((cN) => cN.type)

    if (
      type === 'defaultResult' &&
      (nodes.findIndex((x) => x.type === type) > -1 ||
        connectedNodeTypes.includes('customOutput'))
    )
      return true
    if (
      (type === 'customOutput' || type === 'customInput') &&
      connectedNodeTypes.includes('defaultResult')
    )
      return true
    if (connectedNodeTypes?.length > 0 && connectedNodeTypes.includes(type)) {
      return true
    }
    return false
  }

  /**
   * Handles all delete node events
   * @param deleted Nodes
   */
  const onNodesDelete = useCallback(
    (deleted) => {
      const nodeIds = deleted.map((n) => n.id)
      let children = []
      let childCondition

      nodeIds.forEach((elm) => {
        children = expressions.filter(
          (x) => x.parentNode === elm && x.type !== 'condition'
        )
        childCondition = expressions.find(
          (x) => x.parentNode === elm && x.type === 'condition'
        )
      })

      children.forEach((elm) => {
        setNodes((nds) => nds.filter((x) => x.id !== elm?.id))
        setEdges((egs) => egs.filter((x) => x.source !== elm?.id))
      })

      const deletedNode = expressions.find((x) => x.id === nodeIds[0])

      if (childCondition) {
        setEdges((eds) =>
          eds.concat({
            id: cuid(),
            source: deletedNode?.parentNode,
            target: childCondition?.id,
            label: childCondition.type === 'result' ? 'True' : 'False',
            type: 'smoothstep',
            style: {
              stroke: '#FF0072',
            },
            animated: true,
          })
        )
      }

      dispatch(removeExpression({ nodeIds, deletedNode }))
    },
    [nodes, edges]
  )

  const onDragEnd = (_, node) => {
    dispatch(updateExpressionPosition({ position: node.position }))
  }

  /**
   * Handles all the edge changes occur in pane
   * @param changes Types and values of  edge changes
   */
  const handleEdgesChange = (changes) => {
    const nextChanges = changes.reduce((acc, change) => {
      if (change.type === 'select') {
        return acc
      }
      return [...acc, change]
    }, [])
    onEdgesChange(nextChanges)
  }

  return (
    <div ref={reactFlowWrapper}>
      <div>
        <Button
          disabled={parentHas('customOutput')}
          onClick={() => addNode('input')}
        >
          Add Condition
        </Button>
        <Button
          disabled={parentHas('customResult')}
          onClick={() => addNode('result')}
        >
          Add Result
        </Button>
        <Button
          disabled={parentHas('defaultResult')}
          onClick={() => addNode('default')}
        >
          Add Default Result
        </Button>
      </div>
      <div style={{ width: '100%', height: '400px' }} ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={handleEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          nodeTypes={nodeTypes}
          snapGrid={[16, 16]}
          onNodeDragStop={onDragEnd}
          connectionLineComponent={ConnectionLine}
          onNodesDelete={onNodesDelete}
          deleteKeyCode={['Backspace', 'Delete']}
        >
          <Background />
          <Controls position="top-right" />
        </ReactFlow>
      </div>
    </div>
  )
}

export default function Flow() {
  return (
    <ReactFlowProvider>
      <MainFlow />
    </ReactFlowProvider>
  )
}
