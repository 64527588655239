import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { red } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { WEEKDAYS } from './constants'

interface WeekDayInputProps {
  onChange: (weekdays: number[]) => void
  weekdays?: number[]
}

const WeekDayInput = (props: WeekDayInputProps) => {
  const { onChange, weekdays = [] } = props // Provide a default value for weekdays

  const days = WEEKDAYS.map((day) => ({
    ...day,
    checked: weekdays.includes(day.id),
  }))

  const handleChange = (checked: boolean, id: number) => {
    const updatedDays = days.map((day) =>
      day.id === id ? { ...day, checked } : day
    )
    const newWeekDays = updatedDays
      .filter((day) => day.checked)
      .map((day) => day.id)
    onChange(newWeekDays)
  }

  return (
    <div>
      {days.map((day, index) => (
        <FormControlLabel
          key={day.id}
          style={{ margin: '0', padding: '0' }}
          control={
            <Checkbox
              onChange={(e) => handleChange(e.target.checked, day.id)}
              checked={day.checked}
              size="small"
            />
          }
          value={day.id.toString()}
          label={day.name}
          labelPlacement="bottom"
        />
      ))}
    </div>
  )
}

export default WeekDayInput
