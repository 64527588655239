import styles from './draggable-list.module.css'
import { Draggable } from 'react-beautiful-dnd'
import React, { ReactNode } from 'react'

/* eslint-disable-next-line */
export interface DraggableItemProps {
  index: number
  children: ReactNode
  draggableId: string
}

export function DraggableItem({ index, children, draggableId }: DraggableItemProps) {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <h1
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          {children}
        </h1>
      )}
    </Draggable>
  )
}

export default DraggableItem
