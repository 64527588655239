import { gql } from '@apollo/client'

export const ADD_TABLE_JOIN = gql`
  mutation addTableJoin(
    $name: String!
    $path: String!
    $engagementId: String!
    $operationType: String!
  ) {
    addTableJoin(
      name: $name
      path: $path
      engagementId: $engagementId
      operationType: $operationType
    ) {
      id
    }
  }
`
