import { gql } from '@apollo/client'

export const GET_FUNCTIONS = gql`
  query getFunctions{
    getFunctions {
      id
      name
    }
  }
`
