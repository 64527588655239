import {
  DaaOperationComponent,
  EBAutoComplete,
} from '@engine-b/shared/components'
import { Grid, InputLabel } from '@material-ui/core'
import React from 'react'

interface DaaExtractProps {
  ingestions: any
  cdm_file?: any
  ingestion?: any
  onChange: (key: string, value: any) => void
}

const DaaExtract = (props: DaaExtractProps) => {
  const { ingestions, ingestion, cdm_file, onChange } = props

  return (
    <DaaOperationComponent display_name="Input File">
      <Grid item>
        <InputLabel style={{ marginBottom: '8px' }}>Ingestion Name</InputLabel>
        <EBAutoComplete
          options={ingestions}
          getOptionLabel={(option) => option.name || ''}
          forcePopupIcon={true}
          height={48}
          size="small"
          value={ingestion || null}
          onChange={(e, value) => onChange('ingestion', value)}
        />
      </Grid>
      <Grid item>
        <InputLabel style={{ marginBottom: '8px' }}>CDM File Name</InputLabel>
        <EBAutoComplete
          options={
            ingestion?.cdmEntities?.map((item: any) => item.cdmEntity) || []
          }
          getOptionLabel={(option) => option?.systemName + '.csv' || ''}
          forcePopupIcon={true}
          height={48}
          size="small"
          value={cdm_file || null}
          onChange={(e, value) => onChange('cdm_file', value)}
        />
      </Grid>
    </DaaOperationComponent>
  )
}

export default DaaExtract
