import { createMuiTheme } from '@material-ui/core/styles'
import DropDownButton from '@material-ui/icons/KeyboardArrowDownRounded'
//This import allows to override styles and props of @material-ui/lab
import type {} from '@material-ui/lab/themeAugmentation'

declare module '@material-ui/core/styles/createMuiTheme' {
  // eslint-disable-next-line no-unused-vars
  interface Theme {
    background: {
      primary: {
        main: string
        hover: string
        active: string
      }
      secondary: {
        main: string
        hover: string
        active: string
      }
      error: {
        main: string
        hover: string
        active: string
      }
    }
  }
  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line no-unused-vars
  interface ThemeOptions {
    background?: {
      primary?: {
        main: string
        hover: string
        active: string
      }
      secondary?: {
        main: string
        hover: string
        active: string
      }
      error?: {
        main: string
        hover: string
        active: string
      }
    }
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: 'hsla(201, 49%, 64%, 1)',
      main: 'hsla(205, 34%, 34%, 1)',
      dark: 'hsla(205, 34%, 34%, 1)',
    },
    secondary: {
      main: 'hsla(181, 100%, 34%, 1)',
      light: 'hsla(177, 80%, 45%, 1)',
      dark: 'hsla(181, 100%, 23%, 1)',
    },
    background: {
      default: 'hsla(0,0,0,0)',
    },
    text: {
      primary: 'hsla(205, 34%, 34%, 1)', //primary.dark
      secondary: 'hsla(205, 34%, 34%, 0.8)', //primary.dark
    },
    common: {
      white: 'hsla(0, 0%, 100%)',
    },
  },
  background: {
    primary: {
      main: 'hsla(205, 34%, 34%, 0.08)',
      hover: 'hsla(205, 34%, 34%, 0.8)',
      active: 'hsla(205, 34%, 34%, 0.25)',
    },
    secondary: {
      main: 'hsla(181, 100%, 34%, 0.08)',
      hover: 'hsla(181, 100%, 34%, 0.8)',
      active: 'hsla(181, 100%, 34%, 0.25)',
    },
    error: {
      main: 'hsla(4, 90%, 58%, 0.8)',
      hover: 'hsla(4, 90%, 58%, 0.08)',
      active: 'hsla(4, 90%, 58%, 0.25)',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '-apple-system',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        minWidth: '164px',
      },
    },
    MuiSelect: {
      select: {
        '&.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAutocomplete: {
      // To-do : Remove StyledPopper component wherever the Autocomplete is used.
      groupLabel: {
        fontWeight: 'bold',
        fontSize: 'large',
      },
      popupIndicator: {
        '& span': {
          '& svg': {
            '& path': {
              d: "path('M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7a.9959.9959 0 010-1.41c.39-.38 1.03-.39 1.42 0z')", // your svg icon path here
            },
          },
        },
      },
    },
  },
  props: {
    MuiSelect: {
      IconComponent: DropDownButton,
    },
  },
})

export type Theme = typeof theme
