import { ApolloClient } from '@apollo/client'
import { GET_GROUPINGS } from '@engine-b/integration-engine/data/data-ingestion-api'
import { Grouping } from '@engine-b/shared/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type GroupCollection = Array<Grouping>

const INITIAL_STATE: GroupCollection = []

export const getGroupings = createAsyncThunk(
  'getGroupings',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_GROUPINGS,
    })
    return response
  }
)

export const groupingSlice = createGroupingSlice(INITIAL_STATE)

export function createGroupingSlice(initialState: GroupCollection) {
  return createSlice({
    name: 'groupings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getGroupings.fulfilled, (state, action) => {
        const { groupings } = action.payload.data
        state = [...groupings]
        return state
      })

      builder.addCase(getGroupings.rejected, (state) => {
        state = null
      })
    },
  })
}

export const groupingReducer = groupingSlice.reducer
