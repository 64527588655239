import React from 'react'
import {
  Dialog,
  Typography,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as IconClose } from '../../../assets/IconClose.svg'
import { ReactComponent as IconError } from '../../../assets/IconError.svg'

const useStyles = makeStyles((theme) => ({
  customDialog: {
    '& .MuiDialog-paper': {
      width: '780px',
      height: '500px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .close-icon': {
      width: '100%',
      padding: '30px',
      textAlign: 'end',
      '& svg:hover': {
        cursor: 'pointer',
      },
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    '& .dialog-title': {
      margin: '30px auto 20px auto',
      font: 'normal normal bold 26px/40px Arial',
      letterSpacing: '0.26px',
      color: '#44697D',
      opacity: 1,
    },
    '& .dialog-subtitle': {
      font: 'normal normal bold 22px/26px Arial',
      letterSpacing: '0.22px',
      color: '#DE4D4D',
    },
  },
  actions: {
    width: '100%',
    padding: '0 30px 30px 0',
    '& button': {
      textTransform: 'none',
      borderRadius: '5px',
      height: '44px',
      color: '#fff',
    },
  },
  yesBtn: {
    background: '#89A0AC 0% 0% no-repeat padding-box',
    '&.MuiButton-contained:hover': {
      background: '#89A0AC 0% 0% no-repeat padding-box',
    },
  },
  noBtn: {
    background: '#00B2A9 0% 0% no-repeat padding-box',
    '&.MuiButton-contained:hover': {
      background: '#00B2A9 0% 0% no-repeat padding-box',
    },
  },
}))
const DialogComponent = ({
  title,
  subtitle,
  cancelText,
  confirmText,
  open,
  onClose,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={open} maxWidth={false} className={classes.customDialog}>
      <div className="close-icon">
        <IconClose onClick={() => onClose(false)} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <IconError />
        <Typography className="dialog-title">{title}</Typography>
        <Typography className="dialog-subtitle">{subtitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="default"
          className={classes.yesBtn}
          onClick={() => onClose(true)}
        >
          {confirmText}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.noBtn}
          onClick={() => onClose(false)}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent
