import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { CompletedScreen } from '../../../components/CompletedScreen'
import { StepOne } from './StepOne'
import { StepTwo } from './StepTwo'
import { StepThree } from './StepThree'

export function StepperContent(props) {
  const { activeStep, handleNext, handleBack, handleReset, source } = props
  const { formatMessage, locale } = useIntl()

  switch (activeStep) {
    case 0:
      return <StepOne handleNext={() => handleNext(0)} />
    case 1:
      return (
        <StepTwo handleNext={() => handleNext(1)} handleBack={handleBack} source = {source}/>
      )
    case 2:
      return (
        <StepThree handleNext={() => handleNext(2)} handleBack={handleBack} />
      )
    case 3:
      return (
        <CompletedScreen
          title="Your data ingestion is in progress!"
          subtitle={
            <p>
              The CDM files will be available for download as soon as the data
              ingestion completes. <br /> You can view all the currently active
              data ingestions in the status page.
            </p>
          }
          firstButton={
            <Button
              type="button"
              text="New Data Ingestion"
              onClick={handleReset}
            />
          }
          secondButton={
            <Button
              text="View The Status Page"
              type="link"
              linkTo={localizeRouteKey(formatMessage, locale, AppRoute.Status)}
            />
          }
        />
      )
    default: {
      return <StepOne handleNext={() => handleNext(0)} />
    }
  }
}
