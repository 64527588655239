import React, { ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  useIESelector,
  useIEDispatch,
  openHelper,
} from '@engine-b/integration-engine/data/state/redux'
import { HELPER_TYPE } from '@engine-b/integration-engine/data/helper-text'

const useDescriptionClasses = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.dark,
      '& h6': {
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0.16px',
        color: '#44697D',
        verticalAlign: 'center',
        marginBottom: '8px',
        '& > span': {
          fontSize: '20px',
          color: '#DE4D4D',
          marginLeft: '2px',
        },
      },
    },
    header: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '21px',
      width: '100%',
      color: theme.palette.primary.dark,
      display: 'flex',
    },
    description: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '21px',
      width: '100%',
      marginBottom: '8px',
      color: theme.palette.primary.dark,
    },
    controls: {},
    infoIcon: {
      cursor: 'pointer',
      position: 'relative',
      height: '1rem',
      width: '1rem',
      top: '0.1rem',
      left: '0.3rem',
    },
    singleRowItem: {
      display: 'flex',
    },
    singleRow: {
      display: 'block',
    },
    headerWidth: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '21px',
      width: '250px',
      color: theme.palette.primary.dark,
      display: 'flex',
    }
  })
)

interface DescriptionProps {
  inputLabelId?: string
  title: string
  description?: string
  children?: ReactNode
  helperType?: HELPER_TYPE
  required?: boolean
  isAdditionalDropdowns?: boolean
}

export function Description(props: DescriptionProps) {
  const { inputLabelId, title, description, helperType, children, required, isAdditionalDropdowns } =
    props
  const classes = useDescriptionClasses()
  const helperState = useIESelector((state) => state.helper)
  const step = useIESelector((state) => state.customMapper.currentStep)
  const dispatch = useIEDispatch()
  const hasOpenHelper = helperState.type === helperType

  const handleOpenHelper = () => {
    dispatch(openHelper({ helperType }))
  }
  return (
    <Grid container direction={'column'} className={classes.root} spacing={0}>
      <div className={step === 2 && isAdditionalDropdowns ? classes.singleRowItem : classes.singleRow}>
        <Grid item className={step === 2 && isAdditionalDropdowns ? classes.headerWidth : classes.header}>
          {inputLabelId ? (
            <>
              <InputLabel id={inputLabelId}>
                <Typography variant={'h6'} color={'textPrimary'}>
                  {title}
                </Typography>
              </InputLabel>
              {helperType && (
                <div role="button" data-testid="open-helper">
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    style={{ color: hasOpenHelper ? '#ff5800' : '#2196F3' }}
                    onClick={handleOpenHelper}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <Typography variant={'h6'} color={'textPrimary'}>
                {title}
                <span>{required && "*"}</span>
              </Typography>
              {helperType && (
                <div role="button" data-testid="open-helper">
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    style={{ color: hasOpenHelper ? '#ff5800' : '#2196F3' }}
                    onClick={handleOpenHelper}
                  />
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item className={classes.description}>
          <Typography variant={'body1'}>{description}</Typography>
        </Grid>
      </div>
      <Grid item className={classes.controls}>
        {children}
      </Grid>
    </Grid>
  )
}
