import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { GetInTouch } from './components/GetInTouch'
import { FAQAccordion } from './components/FAQAccordion'
import { ReactComponent as IconEmail } from '../../assets/IconEmail.svg'
import { FAQ_INFO } from '@engine-b/integration-engine/data/helper-text'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: '30px',
      width: '65%',
    },
    headerTitle: {
      color: '#005955',
      fontWeight: 'bold',
      fontSize: '25px',
    },
    headerSubtitle: {
      color: '#22353F',
      fontSize: '17px',
    },
    contacts: {
      display: 'flex',
      padding: '30px',
      marginBottom: '30px',
      '& > div, & > a': {
        flex: '1',
      },
      width: '50%',
    },
    divider: {
      padding: '5px 30px',
      background: '#00B2A9',
      color: '#fff',
      fontSize: '21px',
    },
    faq: {
      display: 'flex',
      padding: '30px',
      '& > div': {
        flex: '1',
      },
    },
  })
)

export function HelpDashboardView() {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>
          Welcome to Support – How can we help you?
        </Typography>
        <Typography className={classes.headerSubtitle}>
          Support is used by customers to interact with our customer support
          team by email or a chat box.
          <br />
          Read our frequently asked questions to get started using our solution.
        </Typography>
      </div>
      <div className={classes.contacts}>
        <GetInTouch
          icon={<IconEmail />}
          title="Email"
          body="You can contact Engine B support team via email"
          url="support/email"
        />
      </div>
      {process.env.NX_FEATURE_FLAG_SUPPORT === 'true' && (
        <>
          <div className={classes.divider}>FAQs</div>
          <div className={classes.faq}>
            <Grid container spacing={2}>
              {Object.keys(FAQ_INFO).map((faq) => (
                <Grid key={FAQ_INFO[faq].title} item xs={6}>
                  <FAQAccordion
                    headerTitle={FAQ_INFO[faq].title}
                    style={{ marginRight: '20px' }}
                    faqs={FAQ_INFO[faq].faqs}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
    </div>
  )
}

export default HelpDashboardView
