import { Grid, styled, Popper, TextField } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { EBAutoComplete, Select } from '@engine-b/shared/components'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import DateRangeIcon from '@material-ui/icons/DateRange'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ReactComponent as IconClose } from '../../assets/IconClose.svg'
import {
  resetEngagementState,
  useIEDispatch,
  useIESelector,
  RunStatus,
  STATUS_TITLES,
  getAuditedEntitiesByUser,
  getEngagements,
  getDataIngestions,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { useIntl } from 'react-intl'
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => {
  return createStyles({
    clientNameFilter: {
      width: '20%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    engagementNameFilter: {
      width: '20%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    ingestionNameFilter: {
      width: '20%',
      '& .MuiTextField-root': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    ingestionDateFilter: {
      width: '15%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    statusFilter: {
      width: '15%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    datePickerStyles: {
      display: 'flex',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '8px',
      cursor: 'pointer',
      border: '1px solid #c4c4c4',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      height: '40px',
      justifyContent: 'start',
      '& .react-date-picker--open': {
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__wrapper': {
        border: 'none',
        borderRadius: '8px',
        padding: '0px 8px',
      },
      '& .react-date-picker__inputGroup__input': {
        backgroundColor: 'transparent',
      },
      '& .react-date-picker__inputGroup__divider': {
        color: '#c4c4c4',
      },
      '& .react-calendar__tile--active': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .react-date-picker__calendar': {
        zIndex: '300',
        '& .react-calendar__month-view__days': {
          height: '296px',
        },
      },
    },
    refreshIcon: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid #CBCBCB',
      height: '40px',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#395C74',
      },
    },
  })
})

type AutocompleteOptions = {
  name: string
  id: string
  group: string
}
const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    fontWeight: 'bold',
    fontSize: 'large',
  },
}))

const statusItems = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: RunStatus.COMPLETED,
    label: STATUS_TITLES[RunStatus.COMPLETED],
  },
  {
    value: RunStatus.COMPLETED_WITH_ERRORS,
    label: STATUS_TITLES[RunStatus.COMPLETED_WITH_ERRORS],
  },
  // {
  //   value: RunStatus.ERROR,
  //   label: STATUS_TITLES[RunStatus.ERROR],
  // },
  {
    value: RunStatus.IN_PROGRESS,
    label: STATUS_TITLES[RunStatus.IN_PROGRESS],
  },
  {
    value: RunStatus.FAILED,
    label: STATUS_TITLES[RunStatus.FAILED],
  },
]

type StatusViewFilterState = {
  clientName: string
  engagementName: string
  ingestionDate: string
  status: string
  ingestionName: string
  // activeEngagement: boolean
}

type StatusViewFilterProps = {
  filterState: StatusViewFilterState
  applyFilter(params: { event: any; value: any; filterName: string }): void
  page?: number
  recordsPerPage?: any
}

export function StatusViewFilters({
  filterState,
  applyFilter,
  page,
  recordsPerPage,
}: StatusViewFilterProps) {
  const {
    datePickerStyles,
    clientNameFilter,
    engagementNameFilter,
    ingestionDateFilter,
    statusFilter,
    refreshIcon,
    ingestionNameFilter,
  } = useStyles()

  const { locale } = useIntl()

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { loadingState } = useIESelector((state) => state.runs)
  const dispatch = useIEDispatch()
  const client = useApolloClient()

  const [keyValue, setKeyValue] = useState<number>(0)

  const applyNameFilter = debounce((event) => {
    const value = event.target.value;
    applyFilter({
      event: null,
      value: value,
      filterName: 'ingestionName',
    });
  }, 1000);

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getEngagements({ client, id: filterState.clientName }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.clientName])
  return (
    <>
      <Grid item className={clientNameFilter}>
        <EBAutoComplete
          PopperComponent={StyledPopper}
          options={auditedEntities}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Client Names'}
          size="small"
          onChange={(e: any, optionData: AutocompleteOptions, reason) => {
            setKeyValue((prev) => prev + 1)
            let val = optionData?.id
            if (reason === 'clear') {
              val = ''
            }
            applyFilter({
              event: e,
              value: val,
              filterName: 'clientName',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Client Name"
          color="primary"
          backgroundColor="#fff"
        />
      </Grid>
      <Grid item className={engagementNameFilter}>
        <EBAutoComplete
          key={keyValue}
          PopperComponent={StyledPopper}
          options={engagements}
          getOptionLabel={(option) => option.name}
          noOptionsText={'No Engagement Names'}
          size="small"
          onChange={(e: any, optionData: AutocompleteOptions, reason) => {
            let val = optionData?.id
            if (reason === 'clear') {
              val = ''
            }
            applyFilter({
              event: e,
              value: val,
              filterName: 'engagementName',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Engagement Name"
          color="primary"
          backgroundColor="#fff"
        />
      </Grid>
      <Grid item className={ingestionNameFilter}>
        <TextField
          variant="outlined"
          hiddenLabel
          placeholder="Select Ingestion Name"
          size="small"
          InputProps={{
            style: {
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '8px',
            },
          }}
          onChange={applyNameFilter}
        />
      </Grid>
      <Grid item className={ingestionDateFilter}>
        <DatePicker
          clearIcon={filterState.ingestionDate && <IconClose />}
          onChange={(date, e) => {
            const formattedDate = date
              ? date.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              : ''
            return applyFilter({
              event: e,
              value: formattedDate,
              filterName: 'ingestionDate',
            })
          }}
          value={
            filterState.ingestionDate
              ? new Date(filterState.ingestionDate)
              : null
          }
          calendarIcon={<DateRangeIcon />}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          required
          className={datePickerStyles}
        />
      </Grid>
      <Grid item className={statusFilter}>
        <Select
          selectProps={{
            defaultValue: 'all',
            value: filterState.status,
            style: { backgroundColor: 'white', borderRadius: '8px' },
          }}
          placeholder="Please Select Status"
          value={filterState.status}
          items={statusItems}
          onChangeHandler={(value) =>
            applyFilter({ event: null, value, filterName: 'status' })
          }
        />
      </Grid>
      <Grid item>
        <button
          className={refreshIcon}
          disabled={loadingState === 'loading'}
          onClick={async () => {
            client.cache.reset()
            dispatch(
              getDataIngestions({
                client,
                page,
                pageSize: recordsPerPage,
                auditedEntityId: filterState.clientName,
                engagementId: filterState.engagementName,
                status: filterState.status,
                initiatedAt: filterState.ingestionDate,
                name: filterState.ingestionName,
              })
            )
          }}
        >
          <RefreshIcon />
        </button>
      </Grid>
      {/* <Grid item style={{ flex: 1 }}>
        <Grid container justify="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  applyFilter({
                    event: e,
                    value: e.target.checked || false,
                    filterName: 'activeEngagement',
                  })
                }
              />
            }
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Active Engagement
              </Typography>
            }
          />
        </Grid>
      </Grid> */}
    </>
  )
}

export default StatusViewFilters