import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { useIESelector } from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles((theme) => ({
  PreviewPannel: {
    width: '100%',
    background: 'white',
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: '72px',
  },
  blackText: {
    color: 'black',
  },
  errorText: {
    color: '#DE4D4D',
  },
  successText: {
    color: '#155724',
  },
  table: {
    minWidth: 650,
  },
  mappedText: {
    display: 'inline-block',
    color: '#00B2A9',
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: '#D9F4F2',
    borderRadius: '12px',
    padding: '4px 8px',
  },
}))

const MandatoryFields = ({ data, errorCount, validate }) => {
  const cMapper = useIESelector((state) => state.customMapper)

  const classes = useStyles()

  const userMappedOrNot = (row) => {
    // If erp field has below map types then it is user mapped field
    const mapTypes = ['calculated', 'date-mask']

    // If erp fields has confidence score property then it is user mapped field
    const mappedSameField = (item) =>
      (item.erp_fields.length === 1 &&
      item.erp_fields[0].field_name === row.input_field) || item.erp_fields.length === 0

    const index = cMapper.manualSelections.findIndex((cs) => {
      return (
        cs.cdm_field === row.engineb_attribute &&
        cs.report_type === row.report_type &&
        (mapTypes.includes(cs.map_type) || !mappedSameField(cs))
      )
    })
    return index > -1 && !!cMapper.remappingFile ? (
      <Typography className={classes.mappedText}>User Mapped</Typography>
    ) : (
      row.input_field
    )
  }

  return (
    <Box className={classes.PreviewPannel}>
      <Grid container direction="column">
        <Grid item>
          {errorCount && !validate ? (
            <Alert
              icon={<CancelIcon />}
              severity="error"
              style={{ justifyContent: 'center', marginBottom: 10 }}
            >
              <Typography
                className={classes.errorText}
                style={{ textAlign: 'center' }}
              >
                <strong>{errorCount} Errors found.</strong> Some mandatory
                fields are absent in the uploaded file: &nbsp;
                <strong>{data.length > 0 && data[0].uploadedFile}</strong>
                <Typography>
                  Please review uploaded file, correct errors and &nbsp;
                  <strong>upload file again.</strong>
                </Typography>
              </Typography>
            </Alert>
          ) : (
            <Alert
              icon={<CheckCircleIcon />}
              severity="success"
              style={{
                justifyContent: 'center',
                marginBottom: 10,
                backgroundColor: '#EBFFF4',
              }}
            >
              <Typography className={classes.successText}>
                <strong>{validate ? 0 : errorCount} Error found.</strong> All
                mandatory fields are present in uploaded file
                <strong> {data.length > 0 && data[0].uploadedFile}</strong>
              </Typography>
            </Alert>
          )}
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      className={classes.blackText}
                      variant="subtitle2"
                    >
                      Mandatory Field
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.blackText}
                      variant="subtitle2"
                    >
                      ERP Field
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.blackText}
                      variant="subtitle2"
                    >
                      Present
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, idx) => (
                  <TableRow key={`${row.engineb_attribute}-${idx}`}>
                    <TableCell component="th" scope="row">
                      <Typography
                        className={classes.blackText}
                        variant="caption"
                      >
                        {row.engineb_attribute}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        className={row.present ? classes.blackText : ''}
                        variant="caption"
                        color={
                          row.present || validate || !errorCount
                            ? 'textPrimary'
                            : 'error'
                        }
                      >
                        {userMappedOrNot(row)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={row.present ? 'textPrimary' : 'error'}
                      >
                        {row.present || validate || !errorCount ? (
                          <DoneIcon style={{ color: '#37AB3F' }} />
                        ) : (
                          <ClearIcon />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MandatoryFields
