import { makeStyles, TextField, Typography } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { Theme, Grid, StyleRules } from '@material-ui/core'
import IcBrowseFile from './assets/ic_browse_file.svg'
import UploadStateMessage from '../upload-state-message/upload-state-message'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import BounceLoader from 'react-spinners/BounceLoader'
import { UPLOAD_STATE } from '@engine-b/integration-engine/data/state/redux'

export interface FileMappingDataProps {
  cdmFileName: string
  reportType: string
  size: number
  uploaded: number
  state: UPLOAD_STATE
  originalFileName: string
  fileNameByUser: string
  dfData: any
  isPreviewConfirmed: boolean
  header_row: number
}
export interface FileSelectorProps {
  uploadLabel: string
  disableUpload: boolean
  style?: StyleRules
  showOnDragMessage?: boolean
  locale?: string
  uploadFile: (e: any, id?: string) => void
  onDragOver: (e: any) => void
  handleButtonClick: (e: any) => void
  children?: ReactElement | null
  id?: string
  fileMappingData?: FileMappingDataProps
  selectedReportDetail?: any
  selectedCdmEntity?: any
  type?: string
  index?: number
  isUploadInfo: boolean
  isUploadedFile: boolean
  isPreUploadFile: boolean
  isDisplayVertical: boolean
  enableDragAndDrop: boolean
  allowMultiple: boolean
  value?: string
  setValue?: (value: string) => void
  loading?: boolean
  uploaded?: boolean
  handleInputChange?: (e: any, value: any) => void
  options?: any[]
}

interface RenderGridContainerProps {
  props: FileSelectorProps;
  classes: any;
  gridXs: GridSize;
  gridLg: GridSize;
  isHeaderRow: boolean;
  uploadAlert: boolean;
  setUploadAlert: React.Dispatch<React.SetStateAction<boolean>>;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto'

const useStyles = makeStyles<
  Theme,
  {
    stateContainerStyles: StyleRules
    displayVertical: boolean
    UploadInfo: boolean
    isDisplayInfo: boolean
  }
>((theme) => ({
  stateContainer: ({ stateContainerStyles }) => ({
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    textAlign: 'center',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: '132px',
    '& .MuiGrid-item': {
      margin: theme.spacing(2, 1, 2, 1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 0.5, 0.5, 0.5),
      },
    },
    ...stateContainerStyles,
  }),
  alert: {
    marginBottom: '16px',
    width: '100%',
  },
  uploadFileContainer: ({ UploadInfo }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: UploadInfo ? 'space-between' : 'space-around',
    background: '#F8FAFB 0% 0% no-repeat padding-box',
    border: '2px dashed #CCD8DE',
    borderRadius: 10,
    padding: '28px 30px',
  }),
  selectBoxDirection: ({ UploadInfo }) => ({
    flexDirection: 'row',
    justifyContent: UploadInfo ? 'space-between' : 'space-around',
    ['@media (max-width:1300px)']: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  selectIconChooseFile: ({ UploadInfo, displayVertical }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: UploadInfo && !displayVertical ? 'flex-start' : 'flex-end',
  }),
  documentIcon: {
    minWidth: 38,
    height: 44,
    marginRight: 20,
    backgroundImage: `url(${IcBrowseFile})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width:1300px)']: {
      width: 34,
      height: 38,
    },
  },
  messageContainer: {
    textAlign: 'left',
  },
  fileName: {
    textAlign: 'left',
    font: 'normal normal normal Arial',
    letterSpacing: 0,
    color: '#00B2A9',
    fontSize: 14,
    padding: 0,
    margin: 0,
    wordBreak: 'break-word',
    ['@media (max-width:1300px)']: {
      fontSize: 10,
    },
  },
  helpingFor: {
    textAlign: 'left',
    font: 'normal normal normal Arial',
    letterSpacing: 0.18,
    color: '#22353F',
    fontSize: 14,
    padding: 0,
    marginTop: 8,
    ['@media (max-width:1300px)']: {
      fontSize: 10,
    },
  },
  uploadButton: {
    font: 'normal normal medium 18px/28px Roboto',
    letterSpacing: 0.18,
    color: '#00B2A9',
    cursor: 'pointer',
    fontSize: 14,
    ['@media (max-width:1300px)']: {
      fontSize: 10,
    },
  },
  buttonLable: {
    font: 'normal normal medium 18px/28px Roboto',
    letterSpacing: 0.18,
    color: '#00B2A9',
    cursor: 'pointer',
    fontSize: 14,
    ['@media (max-width:1300px)']: {
      fontSize: 10,
    },
  },
  messageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
  },
  uploadFile: {
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 0.18,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: 14,
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectBox: ({ UploadInfo, displayVertical }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: UploadInfo && !displayVertical ? 'center' : 'flex-start',
  }),
  selectButton: {
    cursor: 'pointer',
    background: '#00B2A9 0% 0% no-repeat padding-box',
    borderRadius: 8,
    textAlign: 'center',
    font: 'normal normal medium 16px/22px Roboto',
    letterSpacing: 0,
    color: '#FFFFFF',
    height: 'auto',
    border: 0,
    marginLeft: 10,
    padding: '12px',
    fontSize: 14,
    textTransform: 'capitalize',
    ['@media (max-width:1300px)']: {
      fontSize: 10,
    },
    '&:hover': {
      background: '#00B2A9 0% 0% no-repeat padding-box',
    },
  },
  preSelect: {
    whiteSpace: 'nowrap',
  },
  orText: {
    textAlign: 'center',
    letterSpacing: 0.16,
    fontFamily: theme.typography.fontFamily,
    color: '#22353F',
    fontSize: 14,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0.5, 0.5, 0.5),
    ['@media (max-width:1300px)']: {
      fontSize: 10,
      margin: '10px',
    },
  },
  fcrInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '265px',
    height: '132px',
    '& > label': {
      textAlign: 'left',
      font: 'normal normal bold 16px/18px Arial',
      letterSpacing: '0.16px',
    },
    '& > span': {
      font: 'normal normal normal 14px/20px Arial',
      letterSpacing: '0.14px',
      color: '#44697D',
      margin: '10px 0',
      textAlign: 'left',
    },
    '& > .MuiInputBase-root, .MuiOutlinedInput-root': {
      height: '44px',
    },
  },
  errorMessage: {
    textAlign: 'left',
    font: 'normal normal 15px/10px Arial',
    color: 'red',
    marginTop: '10px',
  },
  screenSize: ({ displayVertical, isDisplayInfo }) => ({
    ['@media (min-width:1407px) and (max-width:1661px)']: {
      maxWidth: displayVertical || isDisplayInfo ? '100%' : '50%',
      flexBasis: displayVertical || isDisplayInfo ? '100%' : '50%',
    },
    ['@media (min-width:1662px) and (max-width:1920px)']: {
      maxWidth: displayVertical ? '50%' : '',
      flexBasis: displayVertical ? '50%' : '',
    },
    ['@media (min-width:1921px)']: {
      maxWidth: displayVertical ? '590px' : '',
      flexBasis: displayVertical ? '590px' : '',
    },
  }),
}))

export const FileSelector = (props: FileSelectorProps) => {
  const {
    style: fileSelectorStyle,
    isDisplayVertical: displayVertical,
    disableUpload,
    isUploadInfo: UploadInfo,
    fileMappingData,
    uploaded,
    loading,
  } = props

  const shouldDisplay = displayVertical ? uploaded : true
  const isHeaderRow = Boolean(shouldDisplay && (loading || fileMappingData?.header_row))
  const isDisplayInfo = !displayVertical && !UploadInfo
  const gridxs = UploadInfo ? 6 : 12
  const gridlg = UploadInfo ? 5 : 12
  const gridXs: GridSize =
    displayVertical || !(isHeaderRow || isDisplayInfo)
      ? 6
      : (gridxs as GridSize)
  const gridLg: GridSize =
    displayVertical || !(isHeaderRow || isDisplayInfo)
      ? 6
      : (gridlg as GridSize)

  const classes = useStyles({
    stateContainerStyles: fileSelectorStyle || {},
    displayVertical: displayVertical,
    UploadInfo: UploadInfo,
    isDisplayInfo: isDisplayInfo,
  })

  const [uploadAlert, setUploadAlert] = useState(false)

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      props.uploadFile(files, props.id)
    }
    event.target.value = ''
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    props.enableDragAndDrop && props.onDragOver(e)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (!props.allowMultiple && e.dataTransfer.files.length > 1) {
      setUploadAlert(true)
    } else {
      !disableUpload &&
        props.enableDragAndDrop &&
        props.uploadFile(e.dataTransfer.files, props.id)
    }
  }

  return (
    <Grid
      container
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ ...props.style }}
    >
      {renderGridContainer({
        props,
        classes,
        gridXs,
        gridLg,
        isHeaderRow,
        uploadAlert,
        setUploadAlert,
        handleFileUpload
      })}
      {props.children}
    </Grid>
  )
}

const renderGridContainer = ({
  props,
  classes,
  gridXs,
  gridLg,
  isHeaderRow,
  uploadAlert,
  setUploadAlert,
  handleFileUpload,
}: RenderGridContainerProps) => {
  return (
    <Grid
      container
      spacing={props.isUploadInfo || props.isDisplayVertical ? 4 : 0}
      xs={12}
      className={classes['stateContainer']}
    >
      <Grid
        xs={gridXs}
        lg={gridLg}
        className={classes['screenSize']}
        style={{ padding: '16px' }}
      >
        <Grid
          className={classes['uploadFileContainer']}
          style={{ opacity: props.disableUpload ? '0.5' : '1' }}
        >
          <Grid container className={classes['selectBoxDirection']}>
            {uploadAlert && (
              <Alert
                className={classes['alert']}
                severity="error"
                onClose={() => setUploadAlert(false)}
              >
                You can only upload 1 file per Report Type. You can join files
                together in our
                <Link to={`/${props.locale}/table-joins`}>Table Joins</Link>
                function.
              </Alert>
            )}
            <Grid xs={12} lg={5} className={classes['selectIconChooseFile']}>
              <div className={classes['documentIcon']} />
              <Grid className={classes['messageContainer']}>
                <p className={classes['fileName']}>
                  {props?.fileMappingData?.originalFileName}
                </p>
                <p className={classes['helpingFor']}>
                  {props.uploadLabel} <br />
                  {props.isUploadedFile && (
                    <>
                      <input
                        type="file"
                        id={`${
                          props.id
                            ? `file-input-${props.id}`
                            : 'component-error'
                        }`}
                        multiple={props.allowMultiple}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        data-testid="step-3-select-file"
                        disabled={props.disableUpload}
                      />
                      <label
                        htmlFor={`file-input-${props.id}`}
                        className={classes['buttonLable']}
                      >
                        Choose File
                      </label>
                    </>
                  )}
                </p>
              </Grid>
            </Grid>

            {props.isPreUploadFile && (
              <>
                <Grid xs={12} lg={2} className={classes['textBox']}>
                  <p className={classes['orText']}>OR</p>
                </Grid>
                <Grid xs={12} lg={5} className={classes['selectBox']}>
                  <button
                    className={classes['selectButton']}
                    onClick={(e) => props.handleButtonClick(e)}
                    disabled={props.disableUpload}
                  >
                    {'Select '}
                    <span className={classes['preSelect']}>Pre-uploaded</span>
                    {' File'}
                  </button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.isUploadInfo &&
        props.fileMappingData &&
        props.selectedReportDetail &&
        props.selectedCdmEntity && (
          <Grid
            xs={6}
            lg={props.isDisplayVertical || !isHeaderRow ? 6 : 5}
            className={classes['screenSize']}
            style={{
              minHeight: '132px',
              display: 'flex',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <UploadStateMessage
              fileMappingData={props.fileMappingData}
              selectedReportDetail={props.selectedReportDetail}
              selectedCdmEntity={props.selectedCdmEntity}
              type={props.type}
              index={props.index}
            />
          </Grid>
        )}
      <Grid
        xs={4}
        lg={props.isDisplayVertical ? 4 : 2}
        xl={props.isDisplayVertical ? 3 : 2}
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        {props.loading ? (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '132px',
            }}
          >
            <BounceLoader color={'#00A9AB'} size={40} />
          </Grid>
        ) : (
          isHeaderRow && (
            <Grid className={classes['fcrInputGroup']}>
              <label htmlFor="suggested-file-name">Header Row *</label>
              <span>Which row are your headers (column names) in?</span>
              <Autocomplete
                value={props.value}
                onChange={(event, newValue) => {
                  if (props.setValue) {
                    if (typeof newValue === 'string') {
                      props.setValue(newValue)
                    } else if (newValue?.header_row) {
                      props.setValue(newValue.header_row?.toString())
                    }
                  }
                }}
                inputValue={props.value}
                onInputChange={props.handleInputChange}
                options={props.options ?? []}
                getOptionLabel={(option) =>
                  typeof option === 'string'
                    ? option
                    : `Row: ${option.header_row?.toString()} Confidence: ${(
                        option.probability * 100
                      )
                        .toFixed(2)
                        .toString()}%`
                }
                style={{ maxWidth: 230 }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Select or enter a value"
                  />
                )}
                freeSolo
              />
              {!props.value && (
                <Typography className={classes['errorMessage']}>
                  Provide Header Row
                </Typography>
              )}
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  )
}

export default FileSelector
