import React from 'react'
import PropTypes, { ReactNodeArray, ReactNodeLike } from 'prop-types'
import { Card, CardContent, Grid, makeStyles, Tooltip } from '@material-ui/core'
import { ReactComponent as IconInfo } from './IconInfo.svg'

export interface DaaOperationComponentProps {
  display_name: string
  info?: string
  children: ReactNodeArray | ReactNodeLike
}

const useStyles = makeStyles({
  operationName: {
    textTransform: 'uppercase',
    color: '#395B73',
    fontWeight: 'bold',
  },
  wrapper: {
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: 13,
  },
})

export function DaaOperationComponent(
  props: Readonly<DaaOperationComponentProps>
) {
  const { operationName, wrapper, tooltip } = useStyles()
  const { display_name, info, children } = props

  return (
    <Card style={{ marginBottom: '1rem', textAlign: 'start' }}>
      <CardContent>
        <Grid container direction="column" style={{ rowGap: '1rem' }}>
          <Grid item className={wrapper}>
            <span className={operationName}>
              {display_name?.split('_')?.join(' ')} &nbsp;
              {info && (
                <Tooltip title={info} placement="right" classes={{ tooltip }}>
                  <IconInfo />
                </Tooltip>
              )}
            </span>
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </Card>
  )
}

DaaOperationComponent.propTypes = {
  display_name: PropTypes.string,
}

export default DaaOperationComponent
