import {
  createSlice,
  SliceCaseReducers,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import {
  join,
  JOINS_UPLOAD_STATE,
  JOIN_DIRECTION,
  FILE_TYPE,
  PRE_UP_LOADING_STATE,
  OperationType,
} from './typedefs'
import { INIT_JOINS_STATE, INIT_OPERATIONS_STATE } from './state'
import { uploadFileToAzureContainer } from '@engine-b/integration-engine/data/azure-data-factory'
import { ApolloClient } from '@apollo/client'
import {
  ADD_TABLE_JOIN,
  BEGIN_TABLE_JOINS,
  GET_JOINS,
  GET_PRE_UPLOADED_FILES_BY_FILE_SYSTEM_NAME,
} from '@engine-b/integration-engine/data/data-ingestion-api'

import axios from 'axios'
import { RootState } from '../../store'

export const joinsStateSlice = createJoinsStateSlice(INIT_JOINS_STATE)

const GET_COLUMNS_ENDPOINT = `${process.env['NX_CUSTOM_MAPPER_API_URL']}/files/get-columns`

// interface ValidateResult {
//   name: string,
//   id: string
// }
export const createAndUploadOperationsFile = createAsyncThunk(
  'createAndUploadOperationsFile',
  async ({ azureClient }: any, { getState }) => {
    const state: any = getState()
    const {
      auditedEntity,
      joinId,
      operations,
      tables,
      startsWith,
      name,
      engagement,
      operationType,
    } = state.joins
    const { auditFirm } = auditedEntity

    const formattedTables = Object.keys(tables).reduce((result, key) => {
      const { name, files, virtual_table_columns } = tables[key]

      const formattedFiles = Object.keys(files).reduce((fileArr, fileKey) => {
        const { path } = files[fileKey]

        const splittedArr = path.split('/')
        const dbName = splittedArr[splittedArr.length - 1]

        fileArr.push({ name: dbName, path })

        return fileArr
      }, [])

      result.push({
        name,
        files: formattedFiles,
        columns: virtual_table_columns,
      })
      return result
    }, [])

    const data = {
      operationType: operationType,
      auditedEntity: { id: auditedEntity.id, name: auditedEntity.name },
      auditFirm: {
        id: auditFirm.id,
        name: auditFirm.name,
        systemName: auditFirm.systemName,
      },
      engagement: { id: engagement.id, name: engagement.name },
      name,
      startsWith,
      operations,
      tables: formattedTables,
    }

    const str = JSON.stringify(data)
    const bytes = new TextEncoder().encode(str)
    const file = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    })

    if (
      auditFirm &&
      auditFirm.systemName &&
      auditedEntity?.name &&
      engagement?.name
      // joinId
    ) {
      const uploadPath = `incoming/${auditedEntity.name}/${engagement.name}/joins/${joinId}/join-operations.json`
      await uploadFileToAzureContainer({
        azureClient,
        containerId: auditFirm.systemName,
        file,
        inputPath: uploadPath,
      })
      return uploadPath
    } else {
      throw new Error('Missing Data')
    }
  }
)

export const getPreUploadedFilesByFileSystemName = createAsyncThunk(
  'preUploadedFiles/preUploadedFiles',
  async (client: ApolloClient<unknown>, thunkAPI) => {
    const state: any = thunkAPI.getState()

    // Query parameters
    const { engagement = {}, auditedEntity = {} } = state.joins
    const { name: clientName = '', auditFirm = {} } = auditedEntity
    const { name: engagementName = '' } = engagement
    const { systemName = '' } = auditFirm

    // Throwing error on invalid data
    if (!systemName && !engagementName && !clientName) {
      throw new Error(
        'Error getting pre-uploaded files with unknown data ingestion'
      )
    }

    const response = await client.query({
      query: GET_PRE_UPLOADED_FILES_BY_FILE_SYSTEM_NAME,
      variables: {
        engagement: engagementName,
        clientName,
      },
    })

    return response
  }
)

export const fetchColumns = createAsyncThunk<
  string[],
  { fileId: string; tableName: string; token: string },
  { state: { joins: join } }
>('joins/fetchColumns', async ({ fileId, tableName, token }, { getState }) => {
  const state = getState()
  const {
    auditedEntity: { auditFirm },
  } = state.joins
  const file_path = `${auditFirm.systemName}/${state.joins.tables[tableName].files[fileId].path}`
  try {
    const response = await axios.post(
      GET_COLUMNS_ENDPOINT,
      { file_path },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    const { data, status } = response.data
    if (status === 'error') {
      throw new Error('Fetch columns failed')
    }
    return data
  } catch (err) {
    throw new Error(err.message)
  }
})

export const uploadFileToContainer = createAsyncThunk<
  string,
  { file: File; azureClient: DataLakeServiceClient; tableName?: string },
  { state: { joins: join } }
>(
  'joins/uploadFile',
  async (
    { file, azureClient, tableName },
    { getState, dispatch, requestId }
  ) => {
    const state = getState()
    const { engagement, auditedEntity } = state.joins
    const { auditFirm } = auditedEntity
    if (
      tableName &&
      auditFirm &&
      auditFirm.systemName &&
      auditedEntity?.name &&
      engagement?.name &&
      file.name
    ) {
      const today = new Date()
      const dataAndTime = `${today.getFullYear()}${
        today.getMonth() + 1
      }${today.getDate()}_${today.getHours()}${today.getMinutes()}`
      const uploadPath = `incoming/${auditedEntity.name}/${engagement.name}/join-temp/${dataAndTime}_${requestId}_${file.name}`
      await uploadFileToAzureContainer({
        azureClient,
        containerId: auditFirm.systemName,
        file,
        inputPath: uploadPath,
        uploadOptions: {
          onProgress: (progress) => {
            dispatch(
              updateFileUploadProgress({
                tableName,
                requestId,
                uploaded: progress.loadedBytes,
              })
            )
          },
        },
      })
      return uploadPath
    } else {
      throw new Error('Missing Data')
    }
  }
)

export const getJoins = createAsyncThunk(
  'getJoins',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_JOINS,
    })
    return response
  }
)

export const addTableJoin = createAsyncThunk<
  any,
  { client: ApolloClient<unknown> },
  { state: { joins: join } }
>('joins/addTableJoin', async ({ client }, { getState, rejectWithValue }) => {
  const state = getState()
  try {
    const { name, engagement, operationType } = state.joins
    const id = engagement.id
    if (name && id !== null) {
      const formData = {
        name: name,
        path: '',
        engagementId: id,
        operationType,
      }
      const response = await client.mutate({
        mutation: ADD_TABLE_JOIN,
        variables: formData,
      })
      return response
    } else {
      return rejectWithValue({
        name,
        id,
      })
    }
  } catch (error) {
    return rejectWithValue({} as any)
  }
})

export const beginTableJoins = createAsyncThunk<
  any,
  { client: ApolloClient<unknown> },
  { state: { joins: join } }
>(
  'joins/beginTableJoins',
  async ({ client }, { getState, rejectWithValue }) => {
    const state = getState()
    try {
      const { joinId } = state.joins
      if (joinId) {
        const formData = {
          id: joinId,
        }
        const response = await client.mutate({
          mutation: BEGIN_TABLE_JOINS,
          variables: formData,
        })
        return response
      } else {
        return rejectWithValue({
          joinId,
        })
      }
    } catch (error) {
      return rejectWithValue({} as any)
    }
  }
)

function createJoinsStateSlice(initialState) {
  return createSlice<join, SliceCaseReducers<join>>({
    name: 'joins',
    initialState,
    reducers: {
      addJoins: (state, { payload }) => {
        state.joinListing = [...payload]
        // const { name, startDate, endDate, user, status } = payload
        // state.joinListing.push({
        //   name: name,
        //   startDate: startDate,
        //   endDate: endDate,
        //   status: status,
        //   user: user,
        // })
      },
      addTable: (state, { payload }) => {
        const { tableName } = payload
        state.tables[tableName] = {
          virtual_table_columns: [],
          name: tableName,
          files: {},
          isDeleteDisabled: false,
        }
        state.operationType = OperationType.COMBINE
        if (Object.keys(state.tables).length > 1) {
          state.operationType = OperationType.COMBINEANDJOIN
          state.operations.push({
            direction: JOIN_DIRECTION.LEFT,
            joinTo: '',
            rules: [
              {
                origin: {
                  table: '',
                  column: '',
                },
                target: {
                  table: '',
                  column: '',
                },
              },
            ],
          })
        }
      },

      setBasicDetails: (state, { payload: { key, value } }) => {
        state[key] = value
        if (key !== 'name') {
          state.tables = INIT_JOINS_STATE.tables
          state.startsWith = INIT_JOINS_STATE.startsWith
          state.operations = INIT_JOINS_STATE.operations
        }
      },
      resetBasicDetails: (state) => {
        state.engagement = INIT_JOINS_STATE.engagement
        state.auditedEntity = INIT_JOINS_STATE.auditedEntity
        return state
      },
      validateName: (state, { payload: { validation } }) => {
        state['validName'] = validation
      },
      addFileToTable: (state, { payload: { tableName, file, fileId } }) => {
        //For files uploaded through system - fileId is requestId generated by thunk.
        //For pre-uploaded files - should keep something unique - filename ??
        // state.tables[tableName].isDeleteDisabled = true
        state.tables[tableName].files[fileId] = file
        return state
      },
      updateFileUploadProgress: (
        state,
        { payload: { tableName, requestId, uploaded } }
      ) => {
        state.tables[tableName].files[requestId].uploaded = uploaded
      },
      updateFileUploadState: (
        state,
        {
          payload: {
            tableName,
            fileId,
            uploadState,
            fileValidationMessage = '',
          },
        }
      ) => {
        // if (uploadState === JOINS_UPLOAD_STATE.COMPLETE) {
        //   state.tables[tableName].isDeleteDisabled = false
        // }
        state.tables[tableName].files[fileId].uploadState = uploadState
        state.tables[tableName].files[fileId].fileValidationMessage =
          fileValidationMessage
      },
      deleteTable: (state, { payload: { tableName } }) => {
        delete state.tables[tableName]

        if (state['startsWith'] === tableName) {
          state['startsWith'] = ''
          state.operations = state.operations.map((operation) => {
            return INIT_OPERATIONS_STATE
          })
        }
        if (Object.keys(state.tables).length < 2) {
          state.operationType = OperationType.COMBINE
          state.operations = []
        } else {
          state.operationType = OperationType.COMBINEANDJOIN
          state.operations = state.operations.filter((o) => {
            return o.joinTo !== tableName
          })

          if (
            Object.keys(state.operations).length ==
            Object.keys(state.tables).length
          ) {
            const emptyJoinTo = state.operations.find((e) => {
              return e.joinTo == ''
            })
            if (emptyJoinTo) {
              state.operations.splice(state.operations.indexOf(emptyJoinTo), 1)
            }
          }

          state.operations.map((o) => {
            return o.rules.map((r) => {
              if (r.origin.table === tableName) {
                r.origin.table = ''
                r.origin.column = ''
              }
              if (r.target.table === tableName) {
                r.target.table = ''
                r.target.column = ''
              }
              return r
            })
          })
        }
      },
      updateJoin: (state, { payload }) => {
        return (state = {
          ...state,
          ...payload,
        })
      },
      updateRuleOptions: (
        state,
        { payload: { index, ruleIndex, ruleType, tableType, value } }
      ) => {
        state.operations[index].rules[ruleIndex][ruleType][tableType] = value
      },
      updateRuleTargetOptions: (
        state,
        { payload: { index, ruleIndex, value } }
      ) => {
        state.operations[index].rules[ruleIndex].target = value
      },
      deleteFile: (state, { payload: { tableName, tableId, fileId } }) => {
        delete state.tables[tableName].files[fileId]
      },
      setVirtualTableColumns: (
        state,
        { payload: { tableName, columns = [] } }
      ) => {
        state.tables[tableName].virtual_table_columns = columns
      },
      addRule: (state, { payload: { index } }) => {
        state.operations[index].rules.push({
          origin: {
            table: '',
            column: '',
          },
          target: {
            table: '',
            column: '',
          },
        })
      },
      changeRuleValue: (state, { payload: { index, value, title } }) => {
        state.operations[index][title] = value
      },
      deleteOneRule: (state, { payload: { index, ruleIndex } }) => {
        state.operations[index].rules.splice(ruleIndex, 1)
      },
      reArrangeRules: (state, { payload: { ops } }) => {
        state.operations = ops
      },
      changeStartsWithValue: (state, { payload: { value } }) => {
        state['startsWith'] = value
      },
      toggleTableDelete: (state, { payload: { tableName, flag } }) => {
        state.tables[tableName].isDeleteDisabled = flag
      },
      resetJoins: (state) => {
        return INIT_JOINS_STATE
      },
      resetJoinsTableFiles: (state, { payload: { tableName } }) => {
        if (state.tables[tableName]) {
          state.tables[tableName].files = {}
        }
      },
      resetOperations: (state) => {
        state.startsWith = INIT_JOINS_STATE.startsWith
        state.operations = state.operations.map((operation) => {
          return { ...INIT_OPERATIONS_STATE, direction: operation.direction }
        })
      },
      resetOperation: (state, { payload: { opIndex } }) => {
        state.operations = state.operations.map((operation, i) =>
          i >= opIndex ? INIT_OPERATIONS_STATE : operation
        )
      },
      setOutputFileDownloadStatus: (
        state,
        { payload: { rowIndex, isDownloading } }
      ) => {
        state.joinListing[rowIndex].isDownloading = isDownloading
      },
      setOutputFileDownloadProgress: (
        state,
        { payload: { rowIndex, downloadProgress } }
      ) => {
        state.joinListing[rowIndex].downloadProgress = downloadProgress
      },
      uploadExcelFileData: (state, { payload }) => {
        const { tableName, name, path, requestId, column } = payload
        state.tables[tableName].files[requestId] = {
          name: name,
          path: path, //updated only if file is uploaded successfully (in uploadFileToContainer.fullfiled)
          columns: column,
          uploadState: JOINS_UPLOAD_STATE.VALIDATING_FILE_COMPLETE,
          fileSize: 0,
          uploaded: 0,
          requestId,
          fileType: FILE_TYPE.UPLOADED,
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getJoins.pending, (state, action) => {
        state.loading = true
      })
      builder.addCase(getJoins.fulfilled, (state, action) => {
        const { getTablejoins } = action.payload.data
        const joinListing = getTablejoins.map((row) => ({
          ...row,
          isDownloading: false,
        }))
        state.joinListing = joinListing
        state.loading = false
        return state
      })
      builder.addCase(getJoins.rejected, (state) => {
        state.joinListing = []
        state.loading = false
      })
      builder.addCase(fetchColumns.fulfilled, (state, { payload, meta }) => {
        const {
          arg: { tableName, fileId },
        } = meta
        if (state.tables[tableName]?.files[fileId]) {
          state.tables[tableName].files[fileId].columns = payload || []
          state.tables[tableName].files[fileId].uploadState =
            JOINS_UPLOAD_STATE.FETCHING_COLUMNS_COMPLETE
        }
      })
      builder.addCase(fetchColumns.rejected, (state, { meta }) => {
        const {
          arg: { tableName, fileId },
        } = meta
        state.tables[tableName].files[fileId].uploadState =
          JOINS_UPLOAD_STATE.FETCHING_COLUMNS_FAILED
      })
      builder.addCase(
        uploadFileToContainer.pending,
        (state, { payload, meta }) => {
          const {
            requestId,
            arg: { tableName, file },
          } = meta
          state.tables[tableName].files[requestId] = {
            name: file.name,
            path: '', //updated only if file is uploaded successfully (in uploadFileToContainer.fullfiled)
            columns: [],
            uploadState: JOINS_UPLOAD_STATE.IN_PROGRESS,
            fileSize: file.size,
            uploaded: 0,
            requestId,
            fileType: FILE_TYPE.UPLOADED,
          }
        }
      )
      builder.addCase(
        uploadFileToContainer.rejected,
        (state, { payload, meta }) => {
          const {
            requestId,
            arg: { tableName },
          } = meta
          state.tables[tableName].files[requestId].uploadState =
            JOINS_UPLOAD_STATE.ERROR
        }
      )
      builder.addCase(
        uploadFileToContainer.fulfilled,
        (state, { payload, meta }) => {
          const {
            requestId,
            arg: { tableName },
          } = meta
          state.tables[tableName].files[requestId].path = payload
          state.tables[tableName].files[requestId].uploadState =
            JOINS_UPLOAD_STATE.FETCHING_COLUMNS
        }
      )
      builder.addCase(addTableJoin.pending, (state, _) => {
        state.isLoading = PRE_UP_LOADING_STATE.LOADING
      })
      builder.addCase(addTableJoin.fulfilled, (state, action) => {
        const { preUploadedFiles } = action.payload.data
        const { id } = action.payload.data.addTableJoin
        state.preUpFiles = preUploadedFiles
        state.isLoading = PRE_UP_LOADING_STATE.DONE
        state.joinId = id
        return state
      })
      builder.addCase(addTableJoin.rejected, (state) => {
        state.isLoading = PRE_UP_LOADING_STATE.ERROR
      })

      builder.addCase(
        getPreUploadedFilesByFileSystemName.pending,
        (state, _) => {
          state.isLoading = PRE_UP_LOADING_STATE.LOADING
        }
      )
      builder.addCase(
        getPreUploadedFilesByFileSystemName.fulfilled,
        (state, action) => {
          const { preUploadedFiles } = action.payload.data
          state.preUpFiles = preUploadedFiles
          state.isLoading = PRE_UP_LOADING_STATE.DONE
          return state
        }
      )
      builder.addCase(getPreUploadedFilesByFileSystemName.rejected, (state) => {
        state.isLoading = PRE_UP_LOADING_STATE.ERROR
      })
    },
  })
}

export const {
  addTable,
  setBasicDetails,
  addFileToTable,
  updateFileUploadProgress,
  updateFileUploadState,
  deleteTable,
  resetJoins,
  resetJoinsTableFiles,
  resetOperations,
  resetOperation,
  addJoins,
  resetBasicDetails,
  updateJoin,
  updateRuleOptions,
  deleteFile,
  setVirtualTableColumns,
  addRule,
  changeRuleValue,
  deleteOneRule,
  reArrangeRules,
  updateRuleTargetOptions,
  changeStartsWithValue,
  toggleTableDelete,
  setOutputFileDownloadStatus,
  setOutputFileDownloadProgress,
  validateName,
  uploadExcelFileData,
} = joinsStateSlice.actions

export const joinsReducer = joinsStateSlice.reducer
