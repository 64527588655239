import { gql } from '@apollo/client';

export const GET_PRE_UPLOADED_JOIN_FILES = gql`
    query PreUploadedJoinsFiles($clientName: String!, $engagement: String!) {
        preUploadedJoinsFiles(clientName: $clientName, engagement: $engagement) {
            name
            contentLength
            lastModified
            }
        }
    `