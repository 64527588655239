import React, { useState } from 'react'
import {
  TextField,
  Grid,
  IconButton,
  Chip,
  Typography,
} from '@material-ui/core'
import { EventAvailableOutlined, EventBusyOutlined } from '@material-ui/icons'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

interface MultipleDatePickerProps {
  onChange: (dates: string[]) => void
  custom_dates?: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: '100px',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    clearButton: {
      color: red[400],
    },
  })
)

const MultipleDatePicker = (props: MultipleDatePickerProps) => {
  const { custom_dates = [], onChange } = props

  const classes = useStyles()
  const [inputDate, setInputDate] = useState<string>('')

  const handleAddDates = () => {
    if (custom_dates.includes(inputDate) || inputDate === '') {
      return
    }
    const newDates = [...custom_dates, inputDate]
    onChange(newDates)
  }

  const handleRemoveDates = (date: string) => {
    const newDates = custom_dates.filter((d) => d !== date)
    onChange(newDates)
  }

  const clearDates = () => {
    onChange([])
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.root}>
        {custom_dates?.length > 0 ? (
          custom_dates.map((date) => (
            <Chip
              key={date}
              variant="outlined"
              size="small"
              label={date}
              onDelete={(_) => handleRemoveDates(date)}
              color="primary"
            />
          ))
        ) : (
          <Typography>No Dates Selected</Typography>
        )}
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignContent="flex-end"
        >
          <Grid item>
            <TextField
              type="date"
              fullWidth
              onChange={(e) => setInputDate(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <IconButton
                  size="medium"
                  color="secondary"
                  onClick={(_) => handleAddDates()}
                  disabled={inputDate === ''}
                >
                  <EventAvailableOutlined />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="medium"
                  className={classes.clearButton}
                  onClick={(_) => clearDates()}
                  disabled={custom_dates?.length === 0}
                >
                  <EventBusyOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MultipleDatePicker
