import { DigitalAuditAnalytics } from './typedefs'

export const INITIAL_DIGITAL_AUDIT_ANALYTICS: DigitalAuditAnalytics = {
  name: '',
  clientDetails: null,
  engagementDetails: null,
  nodes: [],
  edges: [],
  activeNodeIndx: -1,
  configPanel: false,
  analyticsPipeline: null,
  loader: false,
  analyticsPipelines: [],
  dataIngestions: [],
  pipelineNameError: false,
  totalPipelines: 0,
  savedPipelines: [],
  loadingMap: false,
  zeroConfigNodeIndex: -1,
  outputs: [],
}
