import { gql } from '@apollo/client'

export const CREATE_PRELOADER = gql`
  mutation createPreloader($preloaderInput: CreatePreloaderInput!) {
    createPreloader(preloaderInput: $preloaderInput) {
      success
      message
      url
    }
  }
`