import React, { useEffect, useState, useContext } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  CdmEntityCollection,
  getCdmEntities,
  addFileMapping,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { CdmEntity, Erp, ExtractType } from '@engine-b/shared/types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FileMappingRow } from './FileMappingRow'
import { DropDown } from '@engine-b/integration-engine/ui/form-components'
import {
  AzureClientContext,
  deleteFileFromAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: '1px solid ' + theme.palette.primary.main,
      borderRadius: '4px',
      padding: '0.5em',
      background: '#F3F7F9',
    },
  })
)

interface UploaderProps {
  erpId: Erp['id']
  extractType: ExtractType
  handleAddCdmEntity: (systemName: CdmEntity['systemName']) => void
  source: Object
}

export function UploadManager(props: UploaderProps) {
  const { erpId, extractType, handleAddCdmEntity, source } = props
  const classes = useStyles()
  const client = useApolloClient()
  const dispatch = useIEDispatch()

  const cdmEntityCollection = useIESelector<CdmEntityCollection>(
    (state) => state.cdmEntities
  )
  const azureClient = useContext(AzureClientContext)
  const fileMapping = useIESelector((state) => state.fileMappings)
  const azureContainer = useIESelector(
    (state) => state.runs.draftRun?.container
  )
  const cdmEntitySystemNames = useIESelector(
    (state) => state.runs.draftRun?.cdmEntitySystemNames
  )

  let initRows = []
  cdmEntitySystemNames.map((v) => {
    initRows.push(cdmEntityCollection[v])
  })

  const [rows, setRows] = useState(initRows)

  const TRIAL_BALANCE = 'trialBalance'
  const QUICKBOOK_UK = 'quickbookUK'
  const QUICKBOOK_US = 'quickbookUS'

  const options = React.useMemo(() => {
    const inputOptions = Object.assign({}, cdmEntityCollection)
    if (rows.length) {
      rows.forEach(({ systemName }) => {
        delete inputOptions[systemName]
      })
    }

    return Object.values(inputOptions)
      .filter((e) => {
        if (
          (e.erp.id === QUICKBOOK_UK || e.erp.id === QUICKBOOK_US) &&
          e.systemName === TRIAL_BALANCE
        ) {
          return false
        }
        return true
        // remove this block once trial blance is working
      })
      .map((e) => ({
        id: e.systemName,
        label: e.name,
      }))
  }, [rows, cdmEntityCollection])

  useEffect(() => {
    dispatch(getCdmEntities({ client, erpId, extractType }))
  }, [client, dispatch, erpId, extractType])

  const handleAddRow = (cdmEntitySystemName) => {
    if (cdmEntityCollection[cdmEntitySystemName]) {
      const fileNames = cdmEntityCollection[cdmEntitySystemName].inputFiles.map(
        (inputFile) => inputFile.name
      )
      dispatch(
        addFileMapping({
          fileNames,
          reportType: cdmEntityCollection[cdmEntitySystemName].systemName,
        })
      )
      setRows((state) => state.concat(cdmEntityCollection[cdmEntitySystemName]))
      handleAddCdmEntity(cdmEntitySystemName)
    }
  }

  const handleRemoveRow = (cdmEntity) => {
    setRows(rows.filter(({ systemName }) => systemName !== cdmEntity))
    const inputFiles = cdmEntityCollection[cdmEntity].inputFiles
    const reportType = cdmEntityCollection[cdmEntity].systemName
    inputFiles.forEach((file) => {
      const fileMappObj = fileMapping[`${reportType}_${file.name}`]
      const newInputPath = fileMappObj?.fileNameByUser
      const uploaded: number = fileMappObj?.uploaded
      if (uploaded) {
        deleteFileFromAzureContainer({
          azureClient,
          fileSystemId: azureContainer?.fileSystemId || '',
          filePath: newInputPath,
        })
      }
    })
  }

  return (
    <div className={classes.root}>
      {rows?.map((row, i) => {
        const { systemName, ...rest } = row
        return (
          <FileMappingRow
            key={systemName}
            selectedCdmEntity={systemName}
            cdmEntityCollection={cdmEntityCollection}
            onRemoveRow={handleRemoveRow}
            source={source}
            {...rest}
          />
        )
      })}
      {Object.keys(options).length ? (
        <DropDown
          label={'Report type'}
          onChange={handleAddRow}
          options={options}
          placeholderLabel="Select Report Type"
          value={-1}
        />
      ) : null}
    </div>
  )
}
