import { useApolloClient } from '@apollo/client'
import {
  useIESelector,
  useIEDispatch,
  JOIN_STATUS,
  getOperationLogs,
  Run,
  ANALYTICS_STATUS_TITLES_COLORS,
  ANALYTICS_STATUS_TITLES,
} from '@engine-b/integration-engine/data/state/redux'
import { Grid, Typography, Button, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { EBTable } from '@engine-b/shared/components'
import AnalyticsFilters from './AnalyticsFilters'
import { useIntl } from 'react-intl'
import { ReactComponent as IconSummary } from '../../assets/IconSummary.svg'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { enUS, enGB } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { saveAs } from 'file-saver'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import { ReactComponent as IconDownload } from '../../assets/IconDownload.svg'
import JSZip from 'jszip'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { trackPageView } from '../../services/AppInsights'

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      display: 'flex',
      '& td': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
    createJoinsContainer: {
      display: 'flex',
      gap: 15,
      padding: '25px 0px 25px 0px',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('lg')]: {
        padding: '0px 0px 25px 0px',
        justifyContent: 'flex-end',
      },
    },
    sectionStyles: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: `#F0FBFA 0% 0% no-repeat padding-box`,
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px !important',
      },
    },
    header: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '30px',
      lineHeight: '37px',
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
    },
    actionButtons: {
      marginRight: '10px',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      minWidth: '50px',
      height: '34px',
      borderRadius: '5px',
    },
    createAuditAnalyticsButton: {
      fontSize: '1rem',
      fontFamily:
        'Roboto, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system',
      fontWeight: 400,
      lineHeight: 1.5,
    },
  })
})

export function StatusView() {
  const {
    container,
    header,
    sectionStyles,
    createJoinsContainer,
    actionButtons,
    createAuditAnalyticsButton,
  } = useStyles()

  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const { locale, formatMessage } = useIntl()
  const azureClient = useContext(AzureClientContext)

  const formatDate = (date) => {
    if (date) {
      return format(parseISO(date), 'P, h:mm:ss a', {
        locale: locale === 'en-US' ? enUS : enGB,
      })
    } else {
      return '-'
    }
  }

  const downloadOutPutFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    operationLogId: string,
    operationLogName: string,
    bundle: any
  ) => {
    setLoading(true)
    const zip = new JSZip() // Create a new instance of JSZip for creating a zip file
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const functions = bundle?.bundleFunctions
    for (const el of functions) {
      const {
        order,
        function: { name },
      } = el
      const FILE_NAME = `tmp_${name}_${order}.csv`
      const fileClient = containerClient.getFileClient(
        `${diContainer.sharePath}/daa/${operationLogId}/out/${FILE_NAME}`
      )
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      zip.file(FILE_NAME, downloadBlob)
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' })
    saveAs(zipBlob, `original_for_${operationLogId}.zip`)
    setLoading(false)
  }

  const downloadErrorLogFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    operationLogId: string,
    operationLogName: string,
    bundle: any
  ) => {
    setLoading(true)
    const zip = new JSZip() // Create a new instance of JSZip for creating a zip file
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const functions = bundle?.bundleFunctions
    for (const el of functions) {
      const {
        order,
        function: { name },
      } = el
      const FILE_NAME = `log.txt`
      const fileClient = containerClient.getFileClient(
        `${diContainer.sharePath}/daa/${operationLogId}/out/${FILE_NAME}`
      )
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      zip.file(FILE_NAME, downloadBlob)
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' })
    saveAs(zipBlob, `log_${operationLogId}.zip`)
    setLoading(false)
  }

  const tableHeadersData = useMemo(() => {
    return [
      {
        title: 'Client Name',
        value: 'client',
        width: '100px',
        renderer: function (params) {
          const {
            row: { dataIngestion },
          } = params
          return <Typography>{dataIngestion.auditedEntity.name}</Typography>
        },
      },
      {
        title: 'Engagement Name',
        value: 'engagement',
        width: '100px',
        renderer: function (params) {
          const {
            row: { dataIngestion },
          } = params
          return <Typography>{dataIngestion.engagement.name}</Typography>
        },
      },
      {
        title: 'Ingestion Name',
        value: 'ingestion',
        width: '100px',
        renderer: function (params) {
          const {
            row: { dataIngestion },
          } = params
          return <Typography>{dataIngestion.name}</Typography>
        },
      },
      {
        title: 'Operation Name',
        value: 'name',
        width: '100px',
      },
      {
        title: 'Created By',
        value: 'createdBy',
        width: '100px',
      },
      {
        title: 'Initiated Date-Time',
        value: 'initiatedAt',
        width: '220px',
        renderer: function (params) {
          const {
            row: { initiatedAt },
          } = params
          return <Typography>{formatDate(initiatedAt)}</Typography>
        },
      },
      {
        title: 'Status',
        value: 'status',
        width: '100px',
        renderer: function (params) {
          const {
            row: { status },
          } = params
          return (
            <Typography
              style={{ color: ANALYTICS_STATUS_TITLES_COLORS[status] }}
            >
              {ANALYTICS_STATUS_TITLES[status] || 'N/A'}
            </Typography>
          )
        },
      },
      {
        title: '',
        value: 'actions',
        width: '160px',
        renderer: function ({ row }) {
          const { dataIngestion, id, name, bundle } = row
          return (
            <>
              {row.status == JOIN_STATUS.FAILED ? (
                <Tooltip title="Download Error Log">
                  <Button
                    className={actionButtons}
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      downloadErrorLogFile(
                        azureClient,
                        dataIngestion.container,
                        id,
                        name,
                        bundle
                      )
                    }
                    disabled={loading}
                  >
                    <IconDownload />
                  </Button>
                </Tooltip>
              ) : (
                row.status === JOIN_STATUS.COMPLETED && (
                  <>
                    <Tooltip title="Download Output File">
                      <Button
                        className={actionButtons}
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          downloadOutPutFile(
                            azureClient,
                            dataIngestion.container,
                            id,
                            name,
                            bundle
                          )
                        }
                        disabled={loading}
                      >
                        <IconDownload />
                      </Button>
                    </Tooltip>
                    <Tooltip title="View Summary">
                      <Button
                        className={actionButtons}
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          history.push(
                            localizeRouteKey(
                              formatMessage,
                              locale,
                              '/audit-view'
                            ) +
                              '/' +
                              id
                          )
                        }
                      >
                        <IconSummary />
                      </Button>
                    </Tooltip>
                  </>
                )
              )}
            </>
          )
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadingState = useIESelector((state) => state.auditAnalytics.loading)
  const { operationLogs } = useIESelector((state) => state.auditAnalytics)

  useEffect(() => {
    dispatch(getOperationLogs(client))
    trackPageView({name: 'Digital Audit Analytics'});
  }, [])

  const [filterState, setFilterState] = useState({
    name: '',
    status: 'all',
    engagement: null,
    client: null,
    ingestion: null,
  })
  const [loading, setLoading] = useState(false)

  const filteredOperations = useMemo(() => {
    return operationLogs.filter((row) => {
      const flagArr = [
        filterState.name === '' ||
          row.name.toLowerCase().includes(filterState.name?.toLowerCase()),
        filterState.status === 'all' ||
          filterState.status === JOIN_STATUS[row.status],
        filterState.client === null ||
          row.dataIngestion?.auditedEntity?.id
            .toLowerCase()
            .includes(filterState.client.id?.toLowerCase()),
        filterState.engagement === null ||
          row.dataIngestion.engagement.id
            .toLowerCase()
            .includes(filterState.engagement.id?.toLowerCase()),
        filterState.ingestion === null ||
          row.dataIngestion?.id
            .toLowerCase()
            .includes(filterState.ingestion.id),
      ]
      return !flagArr.includes(false)
    })
  }, [operationLogs, filterState])

  const applyFilter = ({ value, filterName }) => {
    setFilterState((state) => ({ ...state, [filterName]: value }))
  }

  const history = useHistory()

  const handleAuditAnalytics = () => {
    history.push('audit-analytics-create')
  }

  return (
    <section className={sectionStyles}>
      <Grid container className={container} spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography className={header}>Digital Audit Analytics</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={createJoinsContainer}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleAuditAnalytics}
            className={createAuditAnalyticsButton}
          >
            Create Audit Analytics
          </Button>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <AnalyticsFilters
            rows={filteredOperations}
            filterState={filterState}
            applyFilter={applyFilter}
          />
        </Grid>
        <Grid container item xs={12} direction="column" style={{ flexGrow: 1 }}>
          <EBTable
            loading={loadingState}
            headers={tableHeadersData}
            rows={filteredOperations}
            recordsPerPage={5}
            footerContent={null}
            styles={{
              tableBoxStyles: {
                borderRadius: '8px',
              },
            }}
            emptyLabel={'No Data To Display'}
          />
        </Grid>
      </Grid>
    </section>
  )
}

export default StatusView
