import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Radio,
  Table,
  Tabs,
  Tab,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Checkbox,
  Tooltip,
} from '@material-ui/core'

import {
  getPreUploadedFiles,
  useIEDispatch,
  useIESelector,
  getPreUploadedJoinFiles,
  getPreUploadedExtractionFiles,
} from '@engine-b/integration-engine/data/state/redux'

import React, { useEffect, useState } from 'react'
import { ReactComponent as IconClose } from './assets/IconClose.svg'
import SearchIcon from '@material-ui/icons/Search'
import { format, parseISO } from 'date-fns'
import { enUS, enGB } from 'date-fns/locale'
import { FixedSizeList, ListRowProps } from 'react-window'
import _ from 'lodash'

/* eslint-disable-next-line */
export interface PreUploadedFilesProps {
  open: boolean
  onClose: Function
  dialogTitle: string
  auditedEntity?: string
  engagementId?: string
  allowMultiple: boolean
  preUploadedFilePath?: string
  disableExtraction?: boolean
  disableJoin?: boolean
  disablePreUpload?: boolean
  client: any
  locale: string
}

interface UploadedFiles {
  __typename?: string
  name: string
  lastModified: string
  contentLength: number
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const useStyles = makeStyles(() => ({
  fixedSizedList: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
    },
  },
  uplodedFile: {
    '& .flexDiv': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .textGreen': {
      color: '#00B2A9',
    },
    '& .secondHeading': {
      font: 'normal normal bold 18px/21px Arial',
      letterSpacing: '0.18px',
      color: '#22353F',
      marginTop: '30px',
    },
    '& .MuiDialog-paper': {
      width: '880px',
      height: '655px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '30px 30px 30px 35px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      '& > h2': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        font: 'normal normal bold 24px/40px Arial',
        letterSpacing: '0.24px',
        color: '#22353F',
      },
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginTop: '12px',
    },
    '& .MuiInputBase-root': {
      width: '510px',
      height: '52px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiTableCell-sizeSmall': {
      fontSize: '16px',
      color: '#22353F',
      fontWeight: 'Regular',
      '& .fileDirectory': {
        color: '#44697D',
        margin: 0,
        fontSize: '14px',
        maxWidth: '300px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '& .MuiDialogActions-root': {
      flexDirection: 'column',
      '& > .action-btns': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& > button': {
          height: '44px',
          minWidth: 0,
        },
        '& > button:first-child': {
          width: '132px',
          background: '#89A0AC 0% 0% no-repeat padding-box',
          color: '#fff',
        },
        '& > button:last-child': {
          width: '128px',
          marginLeft: '15px',
        },
      },
    },
  },
  loader: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  makeCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileDirectory: {
    marginTop: 0,
    wordBreak: 'break-word',
    color: '#44697D',
    overflow: 'hidden',
    fontSize: 14,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  inputSearch: {
    width: '324px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    '& .MuiOutlinedInput-root': {
      height: '44px',
    },
  },
  file_Name: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    marginBottom: 0,
  },
  radioButton: {
    paddingLeft: '12px',
  },
}))

export function PreUploadedFiles({
  open,
  onClose,
  dialogTitle,
  auditedEntity,
  engagementId,
  allowMultiple,
  preUploadedFilePath,
  disableExtraction,
  disableJoin,
  disablePreUpload,
  client,
  locale,
}: PreUploadedFilesProps) {
  const { uplodedFile, loader, fixedSizedList, inputSearch } = useStyles()
  const classes = useStyles()
  const dispatch = useIEDispatch()

  const { files, isLoading } = useIESelector((state) => state.preUploadedFiles)
  const { joinFiles } = useIESelector((state) => state.preUploadedJoinsFiles)
  const { extractionFiles } = useIESelector(
    (state) => state.preUploadedExtractionFiles
  )

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedValue, setSelectedValue] = useState<any>()
  const [multipleValues, setMultipleValues] = useState<any>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  useEffect(() => {
    dispatch(getPreUploadedFiles({ client: client, auditedEntity, engagementId }))
    dispatch(getPreUploadedJoinFiles({ client: client, auditedEntity, engagementId }))
    dispatch(getPreUploadedExtractionFiles({ client: client, auditedEntity, engagementId }))
  }, [])

  useEffect(() => {
    if (open) {
      if (selectedTab == 0) {
        setUploadedFiles(files)
      } else if (selectedTab == 1) {
        setUploadedFiles(joinFiles)
      } else {
        setUploadedFiles(extractionFiles)
      }
    }
  }, [selectedTab, open, isLoading])

  useEffect(() => {
    if (preUploadedFilePath) {
      setMultipleValues([
        ...multipleValues.filter(
          (item: UploadedFiles) => item.name !== preUploadedFilePath
        ),
      ])
    }
  }, [preUploadedFilePath])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
    setMultipleValues([])
    setSelectedValue({})
  }

  const onSearchHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (selectedTab == 0) {
      setUploadedFiles(
        files.filter((item) =>
          item.name.toUpperCase().includes(e.target.value.toUpperCase())
        )
      )
    } else if (selectedTab == 1) {
      setUploadedFiles(
        joinFiles.filter((item) =>
          item.name.toUpperCase().includes(e.target.value.toUpperCase())
        )
      )
    } else {
      setUploadedFiles(
        extractionFiles.filter((item) =>
          item.name.toUpperCase().includes(e.target.value.toUpperCase())
        )
      )
    }
  }

  const humanReadableFileSize = (bytes: number) => {
    const i: number =
      bytes == 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024))
    return (
      Number((bytes / Math.pow(1024, i)).toFixed(2)) * 1 +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    )
  }

  function renderRow(
    { index, style }: ListRowProps,
    classes: Record<string, string>
  ) {
    const file = uploadedFiles[index]
    const fileName = file.name
      ?.split('/')
      ?.pop()
      ?.match(/\d{6,8}_\d{2,4}_(.*)/)
    const size = humanReadableFileSize(file.contentLength)
    return (
      <Table>
        <TableBody>
          <TableRow key={index} style={style}>
            <TableCell
              size="small"
              style={{ maxWidth: '350px', width: '350px' }}
            >
              <Tooltip
                title={
                  fileName && fileName?.length > 1
                    ? fileName[1]
                    : file.name?.split('/')?.pop()
                }
              >
                <span className={classes['file_Name']}>
                  {fileName && fileName?.length > 1
                    ? fileName[1]
                    : file.name?.split('/')?.pop()}
                </span>
              </Tooltip>
              <p className={classes['fileDirectory']}>
                {file.name
                  .split('/')
                  .filter(
                    (name: string) =>
                      !name.includes('csv') && !name.includes('txt')
                  )
                  .join('/')
                  .substr(0, 65)}
                /
              </p>
            </TableCell>
            <TableCell
              size="small"
              style={{ maxWidth: '150px', width: '150px' }}
            >
              <span>{size}</span>
            </TableCell>
            <TableCell
              size="small"
              style={{
                maxWidth: '300px',
                width: '300px',
              }}
            >
              <div className={classes['makeCenter']}>
                <span>
                  {format(parseISO(file.lastModified), 'dd/MM/yyyy h:mm:ss a', {
                    locale: locale === 'en-US' ? enUS : enGB,
                  })}
                </span>
                {allowMultiple ? (
                  <Checkbox
                    checked={multipleValues.includes(uploadedFiles[index])}
                    onChange={(e) => {
                      !multipleValues.includes(uploadedFiles[index])
                        ? setMultipleValues([
                            ...multipleValues,
                            uploadedFiles[e.target.value],
                          ])
                        : setMultipleValues(
                            multipleValues.filter(
                              (item: UploadedFiles) => item !== uploadedFiles[e.target.value]
                            )
                          )
                    }}
                    value={index}
                    name="uploaded-files"
                  />
                ) : (
                  <Radio
                    className={classes['radioButton']}
                    checked={selectedValue === uploadedFiles[index]}
                    onChange={(e) =>
                      setSelectedValue(uploadedFiles[e.target.value])
                    }
                    value={String(index)}
                    name="uploaded-files"
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <Dialog open={open} className={uplodedFile} maxWidth={false}>
      <DialogTitle>
        <div className="flexDiv">
          <span>{dialogTitle}</span>
          <Box>
            <Tabs value={selectedTab} onChange={handleChange}>
              {!disablePreUpload && (
                <Tab
                  label="Select Pre-Uploaded File"
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
              )}
              {!disableJoin && (
                <Tab
                  label="Select Join File"
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              )}
              {!disableExtraction && (
                <Tab
                  label="Select Extraction File"
                  id="tab-3"
                  aria-controls="tabpanel-3"
                />
              )}
            </Tabs>
          </Box>
        </div>
        <IconButton onClick={() => onClose(false)}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          placeholder="Search"
          classes={{ root: inputSearch }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchHandler}
        />

        {isLoading === 'done' ? (
          <>
            <Table style={{ height: 50 }}>
              <TableHead>
                <TableRow style={{ height: 50 }}>
                  <TableCell
                    style={{
                      maxWidth: '350px',
                      width: '350px',
                      display: 'inline-block',
                    }}
                  >
                    File Name
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '150px',
                      width: '150px',
                      display: 'inline-block',
                    }}
                  >
                    File Size
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: '300px',
                      width: '300px',
                      display: 'inline-block',
                    }}
                  >
                    Upload Date-Time
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <FixedSizeList
              width={815}
              height={325}
              itemSize={55}
              itemCount={uploadedFiles.length}
              className={fixedSizedList}
            >
              {(props: ListRowProps) => renderRow(props, classes)}
            </FixedSizeList>
          </>
        ) : (
          <div className={loader}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div className="action-btns">
          <Button variant="contained" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              allowMultiple
                ? multipleValues.length === 0
                : _.isEmpty(selectedValue)
            }
            onClick={() =>
              onClose(true, allowMultiple ? multipleValues : selectedValue)
            }
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default PreUploadedFiles
