import React from 'react'
import { Stack, Typography } from '@mui/material'
import { Tooltip, createStyles, makeStyles } from '@material-ui/core'
import WorkOffOutlinedIcon from '@material-ui/icons/WorkOffOutlined'
import UpdateIcon from '@material-ui/icons/Update'
import ScheduleIcon from '@material-ui/icons/Schedule'
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined'
import { ReactComponent as NetIcon } from './assets/net.svg'
import { ReactComponent as SumIcon } from './assets/sum.svg'
import { ReactComponent as MaxIcon } from './assets/max.svg'
import { ReactComponent as MinIcon } from './assets/min.svg'
import { ReactComponent as CountIcon } from './assets/count.svg'
import { ReactComponent as GroupIcon } from './assets/group.svg'
import { ReactComponent as CompareIcon } from './assets/compare.svg'
import { ReactComponent as FileIcon } from './assets/fileOp.svg'
import { ReactComponent as ExtractIcon } from './assets/extractOp.svg'
import { ReactComponent as DuplicateIcon } from './assets/duplicate.svg'
import { ReactComponent as FilterIcon } from './assets/filter.svg'
import { ReactComponent as DeleteIcon } from './assets/delete.svg'
import { ReactComponent as AverageIcon } from './assets/avg.svg'
import { ReactComponent as IconInfo } from './assets/IconInfo.svg'
import { ReactComponent as OverlapIcon } from './assets/overlap.svg'
import {
  COUNT,
  DELETE_COLUMN,
  EXTRACT,
  GROUP_BY,
  LOAD,
  MAX,
  MIN,
  NET,
  SUM,
  AVG,
  NON_WORKING_DAYS,
  OUTSIDE_WORKING_HOURS,
  BACKDATING,
  MULTIPLE_CALCULATION,
  FILTER,
  DUPLICATE_ROW,
  COMPARE,
  OVERLAP,
} from './constants'

const useStyles = makeStyles(() =>
  createStyles({
    sideBar: {
      overflowY: 'auto',
      position: 'absolute',
      boxShadow: `0px 0px 16px 0px rgba(57, 91, 115, 0.08)`,
      backgroundColor: '#ffffff',
      top: 0,
      left: 0,
      padding: '20px',
      width: '200px',
      height: '100%',
      borderRadius: '12px 0 0 12px',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#ffffff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#395B73',
        borderRadius: '8px',
        height: '300px',
      },
    },
    sideBarList: {
      padding: '0',
      margin: '10px 0 0',
      listStyle: 'none',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        padding: '10px 5px',
        borderRadius: '4px',
        marginBottom: '10px',
        cursor: 'pointer',
        border: '1px dashed transparent',

        '&:hover': {
          border: '1px dashed #395B7333',
          background: '#395b730d',
        },
      },
    },
    tooltip: {
      fontSize: 13,
    },
    iconStyle: { color: '#a5b2bd', width: '20px', height: '20px' },
  })
)

export default () => {
  const classes = useStyles()
  const onDragStart = (event, nodeType, icon = '') => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.setData('application/reactflow/icon', icon)
    event.dataTransfer.effectAllowed = 'move'
  }

  const localOperations = [
    {
      title: 'Input File',
      icon: <ExtractIcon className={classes.iconStyle} />,
      description:
        'Select the input file from the original CDM output, to carry out the desired Data and Analytics operations on.',
      value: EXTRACT,
    },
    {
      title: 'Output File',
      icon: <FileIcon />,
      description:
        'Document the name of the new file you wish the operation to output to.',
      value: LOAD,
    },
  ]

  const transformOperations = [
    {
      title: 'AVERAGE',
      icon: <AverageIcon />,
      description:
        'The average operation is used to retrieve the average value from a numeric column.',
      value: AVG,
    },
    {
      title: 'BACKDATING',
      icon: <UpdateIcon className={classes.iconStyle} />,
      description:
        'Backdating operation is used to identify backdated transactions; the function is based on the number of elapsed calendar days. The output file will return the number of elapsed calendar days between a first date given and a second date given, for example an Effective Date and a Created Date. The first date given can be a selected column or a custom input. In the output file a negative number will be shown where the second date occurred after the first, and a 0 when they occurred on the same day.',
      value: BACKDATING,
    },
    {
      title: 'COUNT',
      icon: <CountIcon />,
      description:
        'Count operation is used to retrieve the total number of rows of a given column, or the number of rows with a unique value of a given column.',
      value: COUNT,
    },
    {
      title: 'DELETE',
      icon: <DeleteIcon className={classes.iconStyle} />,
      description:
        'Delete operation is used to delete a column from a given file.',
      value: DELETE_COLUMN,
    },
    {
      title: 'DUPLICATES',
      icon: <DuplicateIcon className={classes.iconStyle} />,
      description:
        "Duplicate operation allows a user to identify any rows in a table which are exact duplicates. The output file will return the same table with a new column added which flags duplicate items as 'Y'.",
      value: DUPLICATE_ROW,
    },
    {
      title: 'FILTER',
      icon: <FilterIcon className={classes.iconStyle} />,
      description:
        'FILTER operation is used to selectively retrieve rows from a given file based on specified conditions.',
      value: FILTER,
    },

    {
      title: 'GROUP',
      icon: <GroupIcon />,
      description:
        'Group operation is used to group rows that have the same values into summary rows/categories. The operation is performed using an aggregate function of SUM, COUNT, AVG, MAX or MIN. This operation allows potential comparison across categories.',
      value: GROUP_BY,
    },
    {
      title: 'MATHS',
      icon: <ExposureOutlinedIcon className={classes.iconStyle} />,
      description:
        'Mathematical operations allow users to perform arithmetic calculations on numerical columns. The output file will create a new column with the result of the operation. The user can apply operations such as addition (+), subtraction (-), multiplication (*), division (/), and power (** or ^). Round brackets can be used to specify the order of operations.',
      value: MULTIPLE_CALCULATION,
    },
    {
      title: 'MAX',
      icon: <MaxIcon />,
      description:
        'MAX operation is used to retrieve the maximum value from a column.',
      value: MAX,
    },
    {
      title: 'MIN',
      icon: <MinIcon />,
      description:
        'MIN operation is used to retrieve the minimum value from a column.',
      value: MIN,
    },

    {
      title: 'NET',
      icon: <NetIcon />,
      description:
        'NET operation is used to allow users to view transaction data as a non-absolute value. This is based on an amount and an Credit Debit Indicator column from the input file. The output will produce a new column labelled "net", showing all values as positive or negative rather than an absolute value.',
      value: NET,
    },
    {
      title: 'NON-WORKING DAYS',
      icon: <WorkOffOutlinedIcon className={classes.iconStyle} />,
      description:
        'Non-working days operation allows users to identify transactions that occur on specified days of the week which are classed as non-working days. The output file will create a new column "non_working_days" and populate "Y" where the date field chosen occurs on one of the days selected by the user.',
      value: NON_WORKING_DAYS,
    },
    {
      title: 'OUTSIDE WORKING HOURS',
      icon: <ScheduleIcon className={classes.iconStyle} />,
      description:
        'Outside Working Hours operation allows users to identify transactions that occur outside of specified working hours. This is based on an input of specified working hours. The output file will create a new column "outside_Working_hours" and populate "Y" where the recorded time occurs before or after these specified hours.',
      value: OUTSIDE_WORKING_HOURS,
    },
    {
      title: 'SUM',
      icon: <SumIcon />,
      description:
        'SUM operation is used to calculate the total sum of numerical values in a numerical column.',
      value: SUM,
    },
  ]

  return (
    <Stack className={classes.sideBar}>
      <Typography
        variant="h6"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
        color="black"
      >
        Files: &nbsp;
        <Tooltip
          title={
            'Drag and drop the below files and operations onto the workspace area.'
          }
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconInfo />
        </Tooltip>
      </Typography>

      <ul className={classes.sideBarList}>
        {localOperations.map((operation) => (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            key={operation.title}
            title={operation.description}
            placement="right"
          >
            <li draggable onDragStart={(e) => onDragStart(e, operation.value)}>
              {operation.icon}
              <Typography variant="body2">{operation.title}</Typography>
            </li>
          </Tooltip>
        ))}
      </ul>
      <Typography
        variant="body2"
        style={{ textAlign: 'left', marginTop: '10px' }}
      >
        Operations:
      </Typography>
      <ul className={classes.sideBarList}>
        {transformOperations.map((operation) => (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            key={operation.title}
            title={operation.description}
            placement="right"
          >
            <li
              draggable
              onDragStart={(e) => onDragStart(e, 'transform', operation.value)}
            >
              {operation.icon}
              <Typography variant="body2">{operation.title}</Typography>
            </li>
          </Tooltip>
        ))}
      </ul>
    </Stack>
  )
}
