import React, { useState } from 'react'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import { Button, Grid, TextField, Typography, createStyles, makeStyles, IconButton, Tooltip } from '@material-ui/core'
import { ReactComponent as IconDelete } from './assets/ic_delete.svg'
import { ReactComponent as IconError } from './assets/IconError.svg'
import { ReactComponent as IconClose } from './assets/IconClose.svg'
import { Step, OPERATION_STATUS, addFilters, deleteFilter, deleteFunction, handleFiltersChange, handleFunctionChange, handleStepChange, useIEDispatch } from '@engine-b/integration-engine/data/state/redux'

const useStyles = makeStyles((theme) =>
    createStyles({
        clientNameFilter: {},
        engagementNameFilter: {},

        dividerMargin: {
            margin: "20px 0"
        },
        JoinInputStyle: {
            '& .MuiInputBase-root': {
                borderRadius: '8px',
            },
        },
        moreFunction: {
            width: '164px',
            height: '40px',
            background: '#D9F4F2 0% 0% no-repeat padding-box',
            borderRadius: '5px',
            textAlign: 'center',
            font: 'normal normal normal 16px/18px Arial',
            letterSpacing: '0px',
            color: '#00B2A9',
            border: 'none',
            padding: '13px 20px 11px',
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            '& span > svg': {
                marginRight: '12px',
            },
            '&:hover': {
                background: '#00b2a9 0% 0% no-repeat padding-box',
                textAlign: 'center',
                font: 'normal normal normal 16px/18px Arial',
                letterSpacing: '0px',
                color: '#FFFFFF',
                '& span > svg path,line': {
                    stroke: '#FFFFFF',
                },
            },
        },
        deleteTableButtonIcon: {
            width: '40px',
            display: "block",
            cursor: 'pointer',
            marginLeft: 'auto'
        },
        stepNumber: {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.primary.main,
            marginBottom: '12px',
            fontSize: '1rem',
        },
        textCloseIcon: {
            padding: '4px'
        },
    })
)

//Input conditiin 
const filterConditions: any = [
    {
        id: "lt",
        name: "Less Than",
        type: "numeric"
    },
    {
        id: "gt",
        name: "Greater Than",
        type: "numeric"
    },
    {
        id: "eq",
        name: "Equals To",
        type: "both"
    },
    {
        id: "neq",
        name: "Not Equals To",
        type: "both"
    },
    {
        id: "contain",
        name: "Contains",
        type: "both"
    },
]

export interface DaaFunctionProps {
    editMode: boolean,
    functionsList: any,
    index: number,
    row: any,
    functions: any,
    attributes: any
    stepOptions: Array<Step>
}

function DaaFunction({ editMode, functionsList, index, row, functions, attributes, stepOptions }: DaaFunctionProps) {
    const classes = useStyles()
    const dispatch = useIEDispatch()
    const [columns, setColumns] = useState(attributes)
    const [isDeleteFunctionModalOpen, setIsDeleteFunctionModalOpen] = useState<boolean>(false)
    const [filterFunctionIdToDelete, setFilterFunctionIdToDelete] = useState<object | null>(null)
    const [functionIdToDelete, setFunctionIdToDelete] = useState<object | null>(null)

    const handlerFunctionChange = (index: number, optionData: any, reason: string) => {
        if (reason === "clear") {
            dispatch(handleFunctionChange({ index, value: { id: '', name: '' } }))
        } else {
            let filteredColumns = attributes
            if (optionData?.name === "Sum") {
                filteredColumns = attributes.filter((option: any) => ["decimal", "integer"].includes(option.dataType))
            }
            setColumns(filteredColumns)
            dispatch(handleFunctionChange({ index, value: optionData }))
        }
    }

    const handlerStepChange = (index: number, optionData: any, reason: string) => {
        if (reason === "clear") {
            dispatch(
                handleStepChange({
                    index,
                    value: { id: "", name: "" },
                })
            );
        } else {
            dispatch(handleStepChange({ index, value: optionData }));
        }
    };

    const handleInputChange = (
        index: number,
        filterIndex: number,
        value: string,
        dataType: string
    ) => {
        if (["decimal", "integer"].includes(dataType)) {
            if (value === "" || !isNaN(Number(value)) || value.startsWith("-")) {
                const numberRegex = dataType === "integer" ? /^[-+]?\d*$/ : /^[-+]?\d*\.?\d*$/;
                if (numberRegex.test(value)) {
                    dispatch(
                        handleFiltersChange({ index, filterIndex, name: "input", value })
                    );
                }
            }
            return false
        } else {
            dispatch(
                handleFiltersChange({ index, filterIndex, name: "input", value })
            );
            return
        }
    }
    const handleFilterFunctinDelete = (v: boolean) => {
        if (v) {
            if (filterFunctionIdToDelete) {
                dispatch(deleteFilter(filterFunctionIdToDelete))
            }
            else if (functionIdToDelete) {
                dispatch(deleteFunction(functionIdToDelete))
            }
        }
        setIsDeleteFunctionModalOpen(false)
        setFilterFunctionIdToDelete(null)
        setFunctionIdToDelete(null)
    }

    const handleFiltersConditions = (type: string) => {
        if (type === 'string') {
            return filterConditions.filter((x: any) => x.type === 'both')
        }
        return filterConditions.filter((x: any) => x.type === 'numeric' || x.type === 'both')
    }
    return (
        <>
            <Typography className={classes.stepNumber}>
                Step - {index + 1}
            </Typography>
            <Grid container spacing={3} alignItems="center" style={{ height: "100px" }}>
                <Grid item xs={10} md={3} style={{ flexBasis: "30.333333%", maxWidth: "30.333333%", paddingTop: 0 }}>
                    <EBAutoComplete
                        forcePopupIcon={true}
                        options={functionsList}
                        getOptionLabel={(option) => option.name || ''}
                        value={row.value}
                        noOptionsText={'No Function Names'}
                        size="small"
                        onChange={(e: any, optionData, reason) => {
                            handlerFunctionChange(index, optionData, reason)
                        }}
                        disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                        hiddenLabel={true}
                        color='primary'
                        placeholder="Function"
                        label="Function"
                        backgroundColor='#fff'
                    />
                </Grid>
                {
                    <>
                        {
                            (row?.id != '' || row?.value?.id != '') &&
                            <>
                                {
                                    ['Sum', 'Count'].includes(row?.value?.name) ?
                                        <Grid item xs={10} md={3} style={{ paddingTop: 0 }}>
                                            <EBAutoComplete
                                                forcePopupIcon={true}
                                                options={columns}
                                                getOptionLabel={(option) => option.name ? `${option?.name} - (${option?.dataType})` : ""}
                                                noOptionsText={'No Input Column Names'}
                                                size="small"
                                                value={row.filters.length ? row.filters[0]?.filterColumn : ""}
                                                onChange={(e: any, optionData: any, reason) => {
                                                    if (reason === "clear") {
                                                        dispatch(handleFiltersChange({ index, filterIndex: 0, name: "filterColumn", value: "" }))
                                                    }
                                                    else {
                                                        dispatch(handleFiltersChange({ index, filterIndex: 0, name: "filterColumn", value: optionData }))
                                                    }
                                                }}
                                                disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                                                hiddenLabel={true}
                                                color='primary'
                                                placeholder="Input Column"
                                                label="Input Column"
                                                backgroundColor='#fff'
                                            />
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={10} md={4} style={{ paddingBottom: 0 }}>
                                    {index !== 0 && <EBAutoComplete
                                        options={stepOptions}
                                        getOptionLabel={(option) => option.name.toString() || ''}
                                        value={row.step}
                                        noOptionsText={'No Input Step'}
                                        size="small"
                                        onChange={(e: any, optionData, reason) => {
                                            handlerStepChange(index, optionData, reason)
                                        }}
                                        disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                                        hiddenLabel={true}
                                        color='primary'
                                        placeholder="Input Step"
                                        helperText="Leave blank to use the original file"
                                        label="Input Step"
                                        backgroundColor='#fff'
                                        freeSolo
                                    />}
                                </Grid>

                            </>
                        }
                        {!editMode && functions.length !== 1 && (
                            <Grid item xs={1} md={1}>
                                <IconDelete className={classes.deleteTableButtonIcon}
                                    onClick={() => {
                                        setFunctionIdToDelete({ index })
                                        setIsDeleteFunctionModalOpen(true)
                                    }}
                                />
                            </Grid>
                        )}
                    </>
                }
            </Grid>
            {
                (row?.id != '' || row?.value?.id != '') &&
                <>
                    {
                        !['Sum', 'Count'].includes(row?.value?.name) &&
                        row.filters.map((filter: any, filterIndex: number) => (
                            <Grid container spacing={3} alignItems="center" key={`filters-${filterIndex}`} >
                                <Grid item xs={12} md={3} style={{ flexBasis: "30.333333%", maxWidth: "30.333333%" }}>
                                    <EBAutoComplete
                                        forcePopupIcon={true}
                                        options={attributes}
                                        getOptionLabel={(option) => option.name ? `${option?.name} - (${option?.dataType})` : ""}
                                        noOptionsText={'No Column Names'}
                                        size="small"
                                        value={filter.filterColumn}
                                        onChange={(e: any, optionData: any, reason) => {
                                            dispatch(handleFiltersChange({ index, filterIndex, name: "input", value: "" }))
                                            if (reason === "clear") {
                                                dispatch(handleFiltersChange({ index, filterIndex, name: "filterColumn", value: "" }))
                                            }
                                            else {
                                                dispatch(handleFiltersChange({ index, filterIndex, name: "filterColumn", value: optionData }))
                                            }
                                        }}
                                        disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                                        hiddenLabel={true}
                                        color='primary'
                                        placeholder="Input Column"
                                        label="Input Column"
                                        backgroundColor='#fff'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} style={{ flexBasis: "30.333333%", maxWidth: "30.333333%" }}>
                                    <EBAutoComplete
                                        forcePopupIcon={true}
                                        options={handleFiltersConditions(filter?.filterColumn?.dataType)}
                                        getOptionLabel={(option) => option.name || ''}
                                        getOptionSelected={(option) => option == filter.filterCondition}
                                        noOptionsText={'No Input Condition Names'}
                                        size="small"
                                        onChange={(e: any, optionData: any, reason) => {
                                            if (reason === "clear") {
                                                dispatch(handleFiltersChange({ index, filterIndex, name: "filterCondition", value: "" }))
                                            }
                                            else {
                                                dispatch(handleFiltersChange({ index, filterIndex, name: "filterCondition", value: optionData }))
                                            }
                                        }}
                                        disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                                        value={filter.filterCondition}
                                        hiddenLabel={true}
                                        color='primary'
                                        placeholder="Input Condition"
                                        label="Input Condition"
                                        backgroundColor='#fff'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} style={{ flexBasis: "30.333333%", maxWidth: "30.333333%" }}>
                                    <TextField
                                        name="inputValue"
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        label={row.filters[filterIndex].filterColumn.dataType === "datetime" ? "" : "Input"}
                                        value={filter.input}
                                        className={classes.JoinInputStyle}
                                        onChange={(e) => handleInputChange(index, filterIndex, e.target.value, row.filters[filterIndex].filterColumn.dataType)}
                                        disabled={(row?.status !== OPERATION_STATUS.FAILED && editMode)}
                                        placeholder='Enter Input Value'
                                        type={row.filters[filterIndex].filterColumn.dataType === "datetime" ? "date" : row.filters[filterIndex].filterColumn.dataType === 'decimal' ? "number" : "text"}
                                        fullWidth={row.filters[filterIndex].filterColumn.dataType === "datetime" ? false : true}
                                        InputProps={{
                                            endAdornment: (row.filters[filterIndex].filterColumn.dataType === "string" ?
                                                <Tooltip title="Clear">
                                                    <IconButton
                                                        className={classes.textCloseIcon}
                                                        style={{ visibility: filter.input ? "visible" : "hidden" }}
                                                        onClick={(e) => handleInputChange(index, filterIndex, '', row.filters[filterIndex].filterColumn.dataType)}
                                                    >
                                                        <IconClose />
                                                    </IconButton>
                                                </Tooltip>
                                                : null
                                            ),
                                            ...(row.filters[filterIndex].filterColumn.dataType === "datetime"
                                                ? { inputProps: { min: "0001-01-01", max: "9999-12-31" } }
                                                : {}),
                                        }}
                                    />
                                </Grid>
                                {
                                    !editMode &&
                                    row.filters.length > 1 && (<Grid item xs={12} md={3} style={{ flexBasis: "8.333333%", maxWidth: "8.333333%" }}>
                                        <Grid className='closeIcon'>
                                            <IconDelete style={{ marginLeft: 0 }} className={classes.deleteTableButtonIcon}
                                                onClick={() => {
                                                    setFilterFunctionIdToDelete({ index, filterIndex })
                                                    setIsDeleteFunctionModalOpen(true)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    )
                                }
                            </Grid>
                        ))
                    }
                    {
                        !['Sum', 'Count'].includes(row?.value?.name) &&
                        !editMode && row?.value?.name !== "Sum" &&
                        <Grid container justify='flex-end' style={{ marginTop: "20px" }}>
                            <Button
                                variant="outlined"
                                className={classes.moreFunction}
                                size="small"
                                onClick={() => dispatch(addFilters(index))}
                            >
                                + Add more Filter
                            </Button>
                        </Grid>
                    }
                </>
            }
            <Dialog
                title=""
                cancelText="No"
                confirmText="Yes"
                open={isDeleteFunctionModalOpen}
                onClose={handleFilterFunctinDelete}
                width="674px"
                height="350px"
            >
                <>
                    <IconError />
                    <br />
                    {functionIdToDelete ?
                        <Typography className="dialog-title">
                            Are you sure You want to Delete this Function?
                        </Typography>
                        :
                        <Typography className="dialog-title">
                            Are you sure You want to Delete this Filter?
                        </Typography>
                    }
                </>
            </Dialog>
        </>
    )
}

export default DaaFunction
