import { gql } from '@apollo/client'

export const REMOVE_DI_GROUPER = gql`
  mutation removeDataIngestionGrouper($id: String) {
    removeDataIngestionGrouper(id: $id) {
      id
      name
      startDate
      endDate
      createdBy
      isActive
      dataIngestionId
      grouperId
      status
    }
  }
`