import { gql } from '@apollo/client'

export const BEGIN_OPERATION_LOG = gql`
  mutation beginOperationLog(
    $id: String!
  ) {
    beginOperationLog(
        id: $id
    ) {
      id
    }
  }
`
