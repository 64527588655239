import React, { useEffect, useMemo, useState } from 'react'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import {
  Button,
  IconButton,
  TextField,
  Typography,
  Grid,
  Checkbox,
  FormLabel,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import {
  useIESelector,
  useIEDispatch,
  IExRuleColumns,
  updateExclusionRules,
  updateLegacyExclusionRules,
} from '@engine-b/integration-engine/data/state/redux'
import { ReactComponent as IconDelete } from '../../assets/IconDelete2.svg'
import { ReactComponent as IconInfo } from '../../assets/IconInfo.svg'
import { useStyle } from './CustomERPDialogStyle'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'

interface IExclusionRuleColumn {
  key: string
  columnName: IAutocompleteOptions | null
  exclusionType: IAutocompleteOptions | null
  text: string
  exact: boolean
  index?: number
}

interface IAutocompleteOptions {
  title: string
  value: string
}

interface ICustomERPDialog {
  visibility: boolean
  index: number
  type: string
  handleDialogClose: (flag: boolean) => void
  isDisable: boolean
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

const availableExclusionTypes = [
  { title: 'Null', value: 'NULL' },
  { title: 'Not Null', value: 'Not Null' },
  { title: 'Text', value: 'text' },
]

const availableAggregations = [
  { title: 'And', value: 'and' },
  { title: 'Or', value: 'or' },
]

export const CustomERPDialog = (props: ICustomERPDialog) => {
  const dispatch = useIEDispatch()
  const classes = useStyle()
  const { instance, inProgress, accounts } = useMsal()
  const {
    visibility,
    handleDialogClose,
    type,
    index,
    isDisable,
    setIsDisable,
  } = props
  const entities = useIESelector(
    (state) => state.customERP.entities[type][index]
  )
  const isLegacy = useIESelector((state) => state.customERP.is_legacy)

  const [initialExRuleColumn, setInitialExRuleColumn] =
    useState<IExclusionRuleColumn>({
      key: '0',
      columnName: null,
      exclusionType: null,
      text: '',
      exact: false,
    })
  const [exclusionRulesCulumn, setExclutionRulesCulumn] = useState<
    IExclusionRuleColumn[]
  >([])

  const [invalidExpressions, setInvalidExpressions] = useState<boolean>(false)
  const [aggregation, setAggregation] = useState('')
  const [legacyAggregation, setLegacyAggregation] = useState(null)
  const [whiteList, setWhiteList] = useState([
    { value: 'AND', type: 'operation' },
    { value: 'OR', type: 'operation' },
  ])

  useEffect(() => {
    if (entities?.template?.exclusion?.rules?.length) {
      const d = entities?.template?.exclusion?.rules.map((item, i) => {
        const isText = ['Not Null', 'NULL'].indexOf(item.contains) === -1
        if (isText) {
          return {
            key: i + 1,
            columnName: columnsData.find((data) => data.value === item.column),
            exclusionType: { value: 'text', title: 'Text' },
            text: item.contains,
            exact: item.exact,
          }
        } else {
          return {
            key: i + 1,
            columnName: columnsData.find((data) => data.value === item.column),
            exclusionType: { value: item.contains, title: item.contains },
          }
        }
      })

      if (isLegacy) {
        setExclutionRulesCulumn(d.slice(1))
        setInitialExRuleColumn(d[0])
      } else {
        setAggregation(entities?.template?.exclusion?.aggr)
        setExclutionRulesCulumn(d)
      }
    } else {
      setExclutionRulesCulumn([
        {
          key: Date.now().toLocaleString(),
          columnName: null,
          exclusionType: null,
          text: '',
          exact: false,
          index: exclusionRulesCulumn.length + 1,
        },
      ])
      setInitialExRuleColumn({
        key: '0',
        columnName: null,
        exclusionType: null,
        text: '',
        exact: false,
      })
    }
  }, [entities])

  const columnsData = useMemo(() => {
    return (
      entities?.customErpFieldsConfig?.map((col, i) => {
        return { title: col.field_name, value: i + 1 }
      }) || []
    )
  }, [entities])

  const handleLegacyRulesChangeHandler = (flag: boolean) => {
    const exRules: IExclusionRuleColumn[] = [
      initialExRuleColumn,
      ...exclusionRulesCulumn,
    ]
    if (flag) {
      const otherRules: IExRuleColumns[] = exRules.map((rule) => {
        return {
          column: rule.columnName.value,
          contains:
            rule.exclusionType.value === 'text'
              ? rule.text
              : rule.exclusionType.value,
          exact: rule.exact,
        }
      })
      dispatch(
        updateLegacyExclusionRules({
          exRules: {
            aggr: legacyAggregation?.value || 'and',
            rules: [...otherRules],
          },
          entityIndex: index,
          extractType: type,
        })
      )
    } else {
      if (entities?.template?.exclusion?.rules?.length) {
        const d = entities?.template?.exclusion?.rules.map((item, i) => {
          const isText =
            item.contains !== 'NULL' && item.contains !== 'Not Null'
          if (isText) {
            return {
              key: i + 1,
              columnName: columnsData.find(
                (data) => data.value === item.column
              ),
              exclusionType: { value: 'text', title: 'Text' },
              text: item.contains,
            }
          } else {
            return {
              key: i + 1,
              columnName: columnsData.find(
                (data) => data.value === item.column
              ),
              exclusionType: { value: item.contains, title: item.contains },
            }
          }
        })
        setExclutionRulesCulumn(d)
        setInitialExRuleColumn(d[0])
        setLegacyAggregation(
          availableAggregations?.find(
            (item) => item?.value === entities?.template?.exclusion?.aggr
          )
        )
      } else {
        setExclutionRulesCulumn([])
        setInitialExRuleColumn({
          key: '0',
          columnName: null,
          exclusionType: null,
          text: '',
          exact: false,
        })
      }
    }

    handleDialogClose(false)
  }

  const handleRulesChangeHandler = async (flag: boolean) => {
    const result = await validateAggregation(exclusionRulesCulumn.length === 0 ? '' : aggregation)
    if (flag) {
      const otherRules: IExRuleColumns[] = exclusionRulesCulumn.map(
        (rule, index) => {
          return {
            column: rule.columnName.value,
            contains:
              rule.exclusionType.value === 'text'
                ? rule.text
                : rule.exclusionType.value,
            exact: rule.exact,
            index: index,
          }
        }
      )

      dispatch(
        updateExclusionRules({
          exRules: {
            aggr: aggregation,
            rules: [...otherRules],
          },
          entityIndex: index,
          extractType: type,
        })
      )
    } else {
      if (entities?.template?.exclusion?.rules?.length) {
        const d = entities?.template?.exclusion?.rules.map((item, i) => {
          const isText =
            item.contains !== 'NULL' && item.contains !== 'Not Null'
          if (isText) {
            return {
              key: i + 1,
              columnName: columnsData.find(
                (data) => data.value === item.column
              ),
              exclusionType: { value: 'text', title: 'Text' },
              text: item.contains,
            }
          } else {
            return {
              key: i + 1,
              columnName: columnsData.find(
                (data) => data.value === item.column
              ),
              exclusionType: { value: item.contains, title: item.contains },
            }
          }
        })
        setExclutionRulesCulumn(d)
        setInitialExRuleColumn(d[0])
      } else {
        setExclutionRulesCulumn([
          {
            key: Date.now().toLocaleString(),
            columnName: null,
            exclusionType: null,
            text: '',
            exact: false,
            index: exclusionRulesCulumn.length + 1,
          },
        ])
        setInitialExRuleColumn({
          key: '0',
          columnName: null,
          exclusionType: null,
          text: '',
          exact: false,
        })
      }
    }
    handleDialogClose(false)
  }

  const handleInitialExChangeHandler = (
    target: string,
    value: IAutocompleteOptions | string | boolean
  ) => {
    setInitialExRuleColumn({
      ...initialExRuleColumn,
      [target]: value,
    })
    setIsDisable(false)
  }

  const handleLegacyAggregationChange = (e, value: IAutocompleteOptions) => {
    setLegacyAggregation(value)
    setIsDisable(false)
  }

  const validateAggregation = async (input) => {
    const regex = /(\d+)/g
    const expression = input?.replace(regex, '<$1>')
    const max = exclusionRulesCulumn?.length
    try {
      // Bring token for protected resource
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await axios.post(
        `${process.env.NX_CUSTOM_MAPPER_API_URL}/post_mapping/validate/expression`,
        { expression, max },
        { headers }
      )
      return true
    } catch (error) {
      return false
    }
  }

  const handleAggregationChange = async (e) => {
    const value = e.target.value
    setAggregation(value)
    const result = await validateAggregation(e.target.value)
    /**
     * TODO: set error on result
     */
    setInvalidExpressions(!result)
    setIsDisable(false)
  }

  const handleAddExclusionRule = () => {
    setExclutionRulesCulumn((prev) => [
      ...prev,
      {
        key: Date.now().toLocaleString(),
        columnName: null,
        exclusionType: null,
        text: '',
        exact: false,
        index: exclusionRulesCulumn.length + 1,
      },
    ])
  }

  const handleRemoveExclusionRule = (key: string) => {
    const exRules = exclusionRulesCulumn.filter((x) => x.key !== key)
    setExclutionRulesCulumn(exRules)
    setIsDisable(false)
  }

  const exclusionRuleChangeHandler = (
    key: string,
    target: string,
    value: IAutocompleteOptions | string | boolean
  ) => {
    const ex: IExclusionRuleColumn[] = exclusionRulesCulumn.map((rule) => rule)

    const row = ex.find((x) => x.key === key)
    if (row) {
      row[target] = value
    }
    if (target === 'columnName') {
      row['exclusionType'] = null
    }

    setExclutionRulesCulumn(ex)
    setIsDisable(false)
  }

  const disableApply = () => {
    if (isLegacy) {
      /**
       * Disable apply button If more than one column is available and aggregation is not selected
       */
      if (exclusionRulesCulumn?.length > 0 && !legacyAggregation) return true

      /**
       * Disable apply button If Column Name, Exclusion Type or Text is not selected
       */
      const exRules: IExclusionRuleColumn[] = [
        initialExRuleColumn,
        ...exclusionRulesCulumn,
      ]

      const result = exRules.reduce(
        (acc, value) =>
          acc &&
          !!value.columnName &&
          !!value.exclusionType &&
          (value.exclusionType.value === 'text' ? !!value.text?.trim() : true),
        true
      )

      if (result) {
        if (exclusionRulesCulumn.length > 0) {
          if (isDisable) {
            return true
          } else {
            return false
          }
        } else {
          if (isDisable) {
            return true
          }
          !!!legacyAggregation?.value ? false : true
        }
      } else {
        return true
      }
    } else {
      /**
       * Disable apply button If more than one column is available and aggregation is not set
       */
      if (
        (exclusionRulesCulumn?.length > 0 && aggregation?.length <= 0) ||
        invalidExpressions
      )
        return true
      /**
       * Enable apply button If No Column Name, Exclusion Type or Text is selected
       */
      if (exclusionRulesCulumn?.length === 0) return false

      /**
       * Disable apply button If Column Name, Exclusion Type or Text is not selected
       */
      const result = exclusionRulesCulumn.reduce(
        (acc, value) =>
          acc &&
          !!value.columnName &&
          !!value.exclusionType &&
          (value.exclusionType.value === 'text' ? !!value.text?.trim() : true),
        true
      )

      if (result) {
        if (exclusionRulesCulumn.length > 0) {
          if (isDisable) {
            return true
          } else {
            return false
          }
        } else {
          if (isDisable) {
            return true
          }
          aggregation?.length > 0 ? false : true
        }
      } else {
        return true
      }
    }
  }

  useEffect(() => {
    if (exclusionRulesCulumn.length > 0) {
      if (aggregation) {
        validateAggregation(aggregation).then((result) => {
          setInvalidExpressions(!result);
        });
      }
    }
  }, [exclusionRulesCulumn.length, aggregation])

  useEffect(() => {
    if (isLegacy) {
      exclusionRulesCulumn.length === 0 && setLegacyAggregation(null)
    }
  }, [exclusionRulesCulumn.length])

  useEffect(() => {
    if (
      visibility &&
      exclusionRulesCulumn?.length > 0 &&
      entities?.template?.exclusion
    ) {
      if (isLegacy) {
        setLegacyAggregation(
          availableAggregations?.find(
            (item) => item?.value === entities?.template?.exclusion?.aggr
          )
        )
      } else {
        setAggregation(entities?.template?.exclusion?.aggr)
      }
    } else {
      setLegacyAggregation(null)
      if (aggregation?.length === 0) {
        setAggregation('1')
      }
    }
  }, [visibility])

  return (
    <Dialog
      cancelText="Cancel"
      confirmText="Apply"
      open={visibility}
      header="Remove Rows"
      onClose={
        isLegacy ? handleLegacyRulesChangeHandler : handleRulesChangeHandler
      }
      height="auto"
      width="991px"
      disabledApply={disableApply()}
    >
      <Grid container className={classes.root}>
        {isLegacy ? (
          <Grid item>
            <div className="info">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div>
                  <IconInfo />
                </div>
                <Typography className="desc">
                  If your data has totals, subtotals, or other rows that need to
                  be removed, use this form to tell the tool which rows should
                  be removed. You will need to look at your data and identify
                  what the rows that need to be removed have in common. <br />{' '}
                  Use the 'Column Name' dropdown to select a column which can be
                  used to identify rows that need removing. Then in 'Exclusion
                  type' choose the logic used to remove rows.
                </Typography>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item>
            <div className="info">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div>
                  <IconInfo />
                </div>
                <Typography className="desc">
                  If your data has totals, subtotals, or other rows that need to
                  be removed, use this form to tell the tool which rows should
                  be removed. You will need to look at your data and identify
                  what the rows that need to be removed have in common. <br />{' '}
                  Use the 'Column Name' dropdown to select a column which can be
                  used to identify rows that need removing. Then in 'Exclusion
                  type' choose the logic used to remove rows.
                </Typography>
              </div>
              <div className="example">
                <div style={{ marginBottom: '2rem' }}>
                  <Typography>Example</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '16px',
                    }}
                  >
                    <div className="child-div">
                      <InputLabel className="input-label">
                        Combined Exclusions Rules:
                      </InputLabel>
                      <span>(1 AND 2) OR 3</span>
                    </div>
                    <div className="child-div">
                      <InputLabel className="input-label">1 :</InputLabel>
                      <span> Column 1 is NULL</span>
                    </div>
                    <div className="child-div">
                      <InputLabel className="input-label">2 :</InputLabel>
                      <span> Column 2 is NULL</span>
                    </div>
                    <div className="child-div">
                      <InputLabel className="input-label">3 :</InputLabel>
                      <span> Column 3 is NULL</span>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  <Typography>Output</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '16px',
                    }}
                  >
                    <span>
                      All rows where column 1 and column 2 are NULL removed
                    </span>
                    <span>All rows where column 3 is NULL removed</span>
                  </div>
                </div>
              </div>
              <div>
                <Typography className="desc">
                  **Please avoid using special characters other than
                  parentheses. Refer to the columns by their indexed number or
                  use the control below the form. i.e.: "1 AND (2 OR 3)" or "1
                  OR 2 AND (3 OR 4)".
                </Typography>
              </div>
            </div>
          </Grid>
        )}
        <Grid container item alignItems="flex-end" style={{ gap: '8px' }}>
          <Grid container item direction="column">
            {isLegacy && (
              <Grid container item direction="row" style={{ gap: '8px' }}>
                <Grid item className="combo-box">
                  <label>
                    Column Name <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={columnsData}
                    getOptionLabel={(option) => option.title || ''}
                    value={initialExRuleColumn?.columnName}
                    autoSelect={true}
                    onChange={(e: any, value: IAutocompleteOptions) => {
                      handleInitialExChangeHandler('columnName', value)
                    }}
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Column Name"
                  />
                </Grid>
                <Grid item className="combo-box">
                  <label>
                    Exclusion Type <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={availableExclusionTypes}
                    getOptionLabel={({ title }) => title || ''}
                    value={initialExRuleColumn?.exclusionType}
                    onChange={(e: any, value: IAutocompleteOptions) =>
                      handleInitialExChangeHandler('exclusionType', value)
                    }
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Exclusion Type"
                  />
                </Grid>
                {initialExRuleColumn?.exclusionType?.title.toLowerCase() ===
                  'text' ? (
                  <>
                    <Grid item className="combo-box">
                      <label>
                        Text <span>*</span>
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Text"
                        value={initialExRuleColumn?.text}
                        onChange={(e) =>
                          handleInitialExChangeHandler('text', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item className="combo-box">
                      <label>Exact</label>
                      <Checkbox
                        id="initialexRule-exact"
                        name="initialexRule-exact"
                        checked={initialExRuleColumn.exact ? true : false}
                        onChange={(e) =>
                          handleInitialExChangeHandler(
                            'exact',
                            e.target.checked
                          )
                        }
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            )}
          </Grid>
        </Grid>
        {isLegacy ? (
          <>
            {exclusionRulesCulumn.length > 0 && (
              <Grid container item className="combo-box d-row" direction="row">
                <label>
                  And/Or <span>*</span>
                </label>
                <EBAutoComplete
                  options={availableAggregations}
                  getOptionLabel={({ title }) => title || ''}
                  defaultValue={{
                    title: entities?.template?.exclusion?.aggr,
                    value: entities?.template?.exclusion?.aggr,
                  }}
                  value={legacyAggregation}
                  onChange={(e: any, value: IAutocompleteOptions) =>
                    handleLegacyAggregationChange(e, value)
                  }
                  size="small"
                  fullWidth={false}
                  placeholder="Choose Exclusion Type"
                />
              </Grid>
            )}
            {exclusionRulesCulumn.map((rule, i) => (
              <Grid
                container
                item
                key={rule.key}
                alignItems="flex-end"
                style={{ gap: '23px' }}
              >
                <Grid item className="combo-box">
                  <label>
                    Column Name <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={columnsData}
                    getOptionLabel={({ title }) => title || ''}
                    value={rule.columnName}
                    onChange={(e: any, value: IAutocompleteOptions) =>
                      exclusionRuleChangeHandler(rule.key, 'columnName', value)
                    }
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Column Name"
                  />
                </Grid>
                <Grid item className="combo-box">
                  <label>
                    Exclusion Type <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={availableExclusionTypes}
                    getOptionLabel={({ title }) => title || ''}
                    value={rule.exclusionType}
                    onChange={(e: any, value: IAutocompleteOptions) =>
                      exclusionRuleChangeHandler(
                        rule.key,
                        'exclusionType',
                        value
                      )
                    }
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Exclusion Type"
                  />
                </Grid>
                {rule.exclusionType?.value === 'text' ? (
                  <>
                    <Grid item className="combo-box">
                      <label>
                        Text <span>*</span>
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Text"
                        value={rule.text}
                        onChange={(e) =>
                          exclusionRuleChangeHandler(
                            rule.key,
                            'text',
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item className="combo-box">
                      <>
                        <label>Exact</label>
                        <Checkbox
                          id="exact"
                          name="exact"
                          checked={rule.exact ? true : false}
                          onChange={(e) =>
                            exclusionRuleChangeHandler(
                              rule.key,
                              'exact',
                              e.target.checked
                            )
                          }
                        />
                      </>
                    </Grid>
                  </>
                ) : null}
                <Grid item className="combo-box">
                  <IconButton
                    aria-label="delete"
                    component="span"
                    style={{ marginBottom: '-12px' }}
                    onClick={() => handleRemoveExclusionRule(rule.key)}
                  >
                    <IconDelete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {exclusionRulesCulumn.length > 0 && (
              <Grid container item className="combo-box d-row" direction="row">
                <Grid container item className="combo-box" direction="column">
                  <label>
                    Combined Exclusions Rules <span>*</span>
                  </label>
                  <TextField
                    error={invalidExpressions}
                    size="small"
                    style={{ width: '540px' }}
                    placeholder="Choose Exclusion Type"
                    value={aggregation}
                    onChange={handleAggregationChange}
                  />
                  <Grid container item direction="column">
                    {invalidExpressions && (
                      <FormLabel
                        error
                        style={{ textAlign: 'left', marginTop: '8px' }}
                      >
                        There may be something wrong with this expression
                      </FormLabel>
                    )}
                    <FormHelperText
                      style={{
                        width: '540px',
                      }}
                    >
                      Please write your combined exclusions rules after setting
                      the conditions for each of your columns below. If you add
                      a new column condition below after writing your rules, the
                      best practice is to rewrite the rule to ensure it is
                      accurate Doing this will enable to 'Apply' button
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {exclusionRulesCulumn.map((rule, index) => (
              <Grid
                container
                item
                key={rule.key}
                alignItems="flex-end"
                style={{ gap: '8px' }}
              >
                <Grid item className="column-number">
                  <label>No.</label>
                  {index + 1}
                </Grid>

                <Grid item className="combo-box">
                  <label>
                    Column Name <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={columnsData}
                    getOptionLabel={({ title }) => title || ''}
                    value={rule.columnName}
                    onChange={(e: any, value: IAutocompleteOptions) =>
                      exclusionRuleChangeHandler(rule.key, 'columnName', value)
                    }
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Column Name"
                  />
                </Grid>
                <Grid item className="combo-box">
                  <label>
                    Exclusion Type <span>*</span>
                  </label>
                  <EBAutoComplete
                    options={availableExclusionTypes}
                    getOptionLabel={({ title }) => title || ''}
                    value={rule.exclusionType}
                    onChange={(e: any, value: IAutocompleteOptions) =>
                      exclusionRuleChangeHandler(
                        rule.key,
                        'exclusionType',
                        value
                      )
                    }
                    size="small"
                    fullWidth={false}
                    placeholder="Choose Exclusion Type"
                  />
                </Grid>
                {rule.exclusionType?.value === 'text' ? (
                  <>
                    <Grid item className="combo-box">
                      <label>
                        Text <span>*</span>
                      </label>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Text"
                        value={rule.text}
                        onChange={(e) =>
                          exclusionRuleChangeHandler(
                            rule.key,
                            'text',
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item className="combo-box">
                      <>
                        <label>Exact</label>
                        <Checkbox
                          id="exact"
                          name="exact"
                          checked={rule.exact ? true : false}
                          onChange={(e) =>
                            exclusionRuleChangeHandler(
                              rule.key,
                              'exact',
                              e.target.checked
                            )
                          }
                        />
                      </>
                    </Grid>
                  </>
                ) : null}
                <Grid item className="combo-box">
                  <IconButton
                    aria-label="delete"
                    component="span"
                    style={{ marginBottom: '-12px' }}
                    onClick={() => handleRemoveExclusionRule(rule.key)}
                  >
                    <IconDelete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleAddExclusionRule}
            style={{ height: '44px', width: '150px' }}
          >
            + Add Column
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}
