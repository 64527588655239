import {
  createSlice,
  SliceCaseReducers,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import {
  IAuditAnalytics,
  IFunctions,
  LOADING,
  OPERATION_STATUS
} from './typedefs'
import { INIT_AUDIT_ANALYTICS, INIT_FILTERS, INIT_FUNCTIONS } from './state'
import { uploadFileToAzureContainer } from '@engine-b/integration-engine/data/azure-data-factory'
import { ApolloClient } from '@apollo/client'
import { ADD_OPERATION_LOG, GET_OPERATION_LOGS, BEGIN_OPERATION_LOG, GET_FUNCTIONS, GET_BUNDLES } from '@engine-b/integration-engine/data/data-ingestion-api'
import { Query } from '@engine-b/shared/types'

export const auditStateSlice = createAuditSlice(INIT_AUDIT_ANALYTICS)

// Create Oepration Log Handler 
export const CreateOperationLog = createAsyncThunk<
  any,
  { client: ApolloClient<unknown> },
  {
    state: { auditAnalytics: IAuditAnalytics }
  }
>('operationLogs/createOpertaionLog', async ({ client }, { getState, rejectWithValue }) => {
  const state = getState()
  try {
    const { name, selectedIngestionDetails, selectedFilePath, selectedBundleDetails, functions, operationID } = state.auditAnalytics;
    if (name && selectedIngestionDetails.id) {
      const response = await client.mutate({
        mutation: ADD_OPERATION_LOG,
        variables: {
          ...(!!operationID && { id: operationID }),
          name,
          dataIngestionId: selectedIngestionDetails.id,
          path: "",
          bundleFunctions: functions.map((el, i) => ({
            ...(!!el.id && { id: el.id }),
            order: String(i + 1),
            functionId: el.value.id,
            params: JSON.stringify(el.filters.map(elm => ({
              filter_column: elm.filterColumn,
              filter_condition: elm.filterCondition,
              input: elm.input
            }))),
            source: String(el.step.id),
            status: el.status
          })),
          bundle: {
            id: selectedBundleDetails.id || "",
            name: selectedBundleDetails.name || "",
            entityName: selectedFilePath.id
          },
        },
      })
      return response
    }
    else {
      return rejectWithValue({
        name,
        id: selectedIngestionDetails.id,
      })
    }
  } catch (error) {
    return rejectWithValue({} as any)
  }
})


// Get Oepration log handler 
export const getOperationLogs = createAsyncThunk(
  'operationLogs',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query<{
      getOperationLogs: Query['getOperationLogs']
    }>({
      query: GET_OPERATION_LOGS,
    })
    return response
  }
)

// Get operation log by id
export const getOperationLog = createAsyncThunk(
  'operationLog',
  async (
    { id, client }: { id: string; client: ApolloClient<unknown> },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.query<{
        getOperationLogs: Query['getOperationLogs']
      }>({
        query: GET_OPERATION_LOGS,
        variables: { auditAnalyticsId: id },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Get Function Handler 
export const getFunctions = createAsyncThunk(
  'getFunctions',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query<{
      getFunctions: Query['getFunctions']
    }>({
      query: GET_FUNCTIONS,
    })
    return response
  }
)

// Get Budles Handler 
export const getBundles = createAsyncThunk(
  'getBundles',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query<{
      getBundles: Query['getBundles']
    }>({
      query: GET_BUNDLES,
    })
    return response
  }
)

export const beginOperationLog = createAsyncThunk<
  any,
  { client: ApolloClient<unknown> },
  { state: { auditAnalytics: IAuditAnalytics } }
>(
  'operationLogs/beginOperationLog',
  async ({ client }, { getState, rejectWithValue }) => {
    const state = getState()
    try {
      const { operationID } = state.auditAnalytics
      if (operationID) {
        const formData = {
          id: operationID,
        }
        const response = await client.mutate({
          mutation: BEGIN_OPERATION_LOG,
          variables: formData,
        })
        return response
      } else {
        return rejectWithValue({
          operationID,
        })
      }
    } catch (error) {
      return rejectWithValue({} as any)
    }
  }
)

// upload file handler  
export const createAndUploadOperationsLogFile = createAsyncThunk(
  'createAndUploadOperationsLogFile',
  async ({ azureClient }: any, { getState }) => {
    const { attributes, auditAnalytics }: any = getState();
    const { name, selectedFilePath, functions, selectedIngestionDetails, operationID, selectedClientDetails } = auditAnalytics;
    const { auditFirm } = selectedClientDetails
    const failedSource = functions.find((fn) => fn.status === "FAILED")
    const formattedData = {
      export_file_name: name + ".csv",
      file_container_path: `data-ingestions/${selectedIngestionDetails.id}/share/zip/cdm.zip`,
      file_name: selectedFilePath.id + ".csv",
      column_name: attributes.attributes.map((el: any) => (el.name)),
      source: failedSource?.order || "",
      operation: functions.map((el: IFunctions, i: number) => ({
        order: i + 1,
        file_name: selectedFilePath.id + ".csv",
        operation_type: el.value.name,
        source: el.step.id,
        conditions: el.filters.map((row: any) => (
          {
            column: row.filterColumn.id,
            condition: row.filterCondition.id,
            input: row.input,
            unique: "",
          }
        ))
      })),
    }

    const str = JSON.stringify(formattedData)
    const bytes = new TextEncoder().encode(str)
    const file = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    })
    if (
      auditFirm &&
      auditFirm.systemName
    ) {
      const uploadPath = `data-ingestions/${selectedIngestionDetails.id}/share/daa/${operationID}/in/analytic_operation.json`
      await uploadFileToAzureContainer({
        azureClient,
        containerId: auditFirm.systemName,
        file,
        inputPath: uploadPath,
      })
      return uploadPath
    }
  })

function createAuditSlice(initialState: IAuditAnalytics) {
  return createSlice<IAuditAnalytics, SliceCaseReducers<IAuditAnalytics>>({
    name: 'auditAnalytics',
    initialState,
    reducers: {
      // Initially Setting functiona Values handler
      initFunctionSetter: (state, action) => {
        let modifiedData = [];
        action.payload.bundleFunctions.map((el) => {
          const parsedFilters = el.params ? JSON.parse(el.params) : {};
          modifiedData.push({
            value: el.function,
            order: el.order,
            step: {
              id: el.source,
              name: el.source
            },
            status: OPERATION_STATUS.INITIALIZED,
            // Not all params are set as an array for some reason
            // this tertiary will prevent the UI from crashing
            filters: parsedFilters.length > 0 
              ? parsedFilters.map((pFilter) => ({
                filterColumn: pFilter.filter_column,
                filterCondition: pFilter.filter_condition,
                input: pFilter.input,
              }))
              : [{
                filterColumn: parsedFilters.filter_column,
                filterCondition: parsedFilters.filter_condition,
                input: parsedFilters.input,
              }]
          })
        });

        if (modifiedData.length) {
          state.functions = modifiedData
          return state
        }
      },

      addFunctions: (state) => {
        state.functions.push(INIT_FUNCTIONS)
        return state
      },
      // add Filters Handler this Function Except index  of Filters Array i.e at Which Function user want to add Filters 
      addFilters: (state, { payload }) => {
        // Finding function by Index provided By User
        state.functions[payload]?.filters.push(INIT_FILTERS)
        return state
      },

      handleBasicForm: (state, { payload }) => {
        const { name, value } = payload
        state[name] = value;
        return state
      },

      // in Payload Excepting index of functions array

      handleFunctionChange: (state, { payload }) => {
        const { index, value } = payload
        state.functions[index].value = value
        state.functions[index].filters = [INIT_FILTERS]
        state.functions[index].stepOptions = state.functions
          .map((fn, i) => ({ id: i + 1, name: i + 1, value: fn.value.name }))
          .filter((fn, i) => !["Sum", "Count"].includes(fn.value) && i < index)
        return state
      },

      handleStepChange: (state, { payload }) => {
        const { index, value } = payload
        state.functions[index].step = value
      },

      // in Payload Excepting index of functions Array and index of filters array i.e inside the Functions array 
      handleFiltersChange: (state, { payload }) => {
        const { index, filterIndex, name, value } = payload;
        state.functions[index].filters[filterIndex][name] = value;
        return state
      },

      // in Payload Excepting index of functions Array 
      deleteFunction: (state, { payload }) => {
        const { index } = payload;
        // Filtering functions 
        const filteredFunctions = state.functions.filter((_, i) => (i !== index)).map((fn, i) => {
          if (i >= index) {
            return {
              ...fn,
              step: {
                id: "",
                name: ""
              },
              stepOptions: state.functions
                .map((fn, j) => ({ id: j + 1, name: j + 1, value: fn.value.name }))
                .filter((fn, k) => !["Sum", "Count"].includes(fn.value) && k !== i)
            }
          }
          return fn;
        })

        return {
          ...state,
          functions: filteredFunctions
        }
      },

      clearAllFunctions: (state, { payload }) => {
        state.functions = [INIT_FUNCTIONS]
        state.selectedBundleDetails = {
          id: null,
          name: '',
        }
        return state
      },

      clearFilePath: (state, { payload }) => {
        state.selectedFilePath = INIT_AUDIT_ANALYTICS.selectedFilePath
      },

      // in Payload Excepting index of functions Array and index of filters array i.e inside the Functions array 

      deleteFilter: (state, { payload }) => {
        const { index, filterIndex } = payload;

        // Filtering Filters That user want to Delete
        const filterdFunctionsFilters = state.functions[index].filters.filter((_, i) => (i !== filterIndex));
        state.functions[index].filters = filterdFunctionsFilters;
        return state
      },

      // Resetting Data to initial State 
      resetAuditAnalytics: () => {
        return INIT_AUDIT_ANALYTICS
      },
    },
    extraReducers: (builder) => {

      // Get  Functions 
      builder.addCase(getFunctions.pending, (state, _) => {
        state.loading = true
      }).addCase(getFunctions.fulfilled, (state, action) => {
        state.loading = false
        state.functionsList = action.payload.data.getFunctions
      }).addCase(getFunctions.rejected, (state) => {
        state.functionsList = []
        state.loading = false
      })

      // Get  Bundles 
      builder.addCase(getBundles.pending, (state, _) => {
        state.loading = true
      }).addCase(getBundles.fulfilled, (state, action) => {
        state.loading = false
        state.bundlesList = action.payload.data.getBundles
      }).addCase(getBundles.rejected, (state) => {
        state.bundlesList = []
        state.loading = false
      })

      // Get  Operation 
      builder.addCase(getOperationLogs.pending, (state, _) => {
        state.loading = true
      }).addCase(getOperationLogs.fulfilled, (state, action) => {
        state.loading = false
        state.operationLogs = action.payload.data.getOperationLogs
      }).addCase(getOperationLogs.rejected, (state) => {
        state.operationLogs = []
        state.loading = false
      })

      // Create Operation 
      builder.addCase(CreateOperationLog.pending, (state, _) => {
        state.isLoading = LOADING.LOADING
      })
        .addCase(CreateOperationLog.fulfilled, (state, action) => {
          state.operationID = action.payload.data.addOperationLog.id
        })
        .addCase(CreateOperationLog.rejected, (state) => {
          state.isLoading = LOADING.ERROR
        })

      // Get Operation Log By auditAnalyticsId
      builder.addCase(getOperationLog.pending, (state, _) => {
        state.isLoading = LOADING.LOADING
      }).addCase(getOperationLog.fulfilled, (state, action) => {
        const { getOperationLogs } = action.payload.data
        const { id, name, bundle, dataIngestion } = getOperationLogs[0]
        state.name = name
        state.operationID = id
        state.selectedBundleDetails = { id: bundle.id, name: bundle.name || 'New' }
        state.selectedIngestionDetails = { id: dataIngestion.id, name: dataIngestion.name }
        state.selectedClientDetails = dataIngestion.auditedEntity
        state.selectedEngagementDetails = dataIngestion.engagement
        state.selectedFilePath = { id: bundle.entity.name, name: bundle.entity.displayName }
        state.functions = bundle.bundleFunctions.map(bf => ({
          id: bf.id,
          value: bf.function,
          stepOptions: [],
          filters: JSON.parse(bf.params).map(item => ({
            ...item,
            filterColumn: item.filter_column,
            filterCondition: item.filter_condition
          })),
          order: bf.order,
          step: { id: bf.source, name: bf.source },
          status: bf.status
        })).sort((a: any, b: any) => (a.order - b.order))
      }).addCase(getOperationLog.rejected, (state) => {
        state.isLoading = LOADING.ERROR
      })
    }
  })

}

export const {
  addFilters,
  addFunctions,
  handleBasicForm,
  handleFunctionChange,
  handleStepChange,
  handleFiltersChange,
  deleteFunction,
  deleteFilter,
  initFunctionSetter,
  resetAuditAnalytics,
  clearAllFunctions,
  clearFilePath
} = auditStateSlice.actions

export const auditStateReducer = auditStateSlice.reducer
