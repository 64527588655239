import { gql } from '@apollo/client'

export const GET_ENGAGEMENTS_BY_USER_AND_AUDITED_ENTITY = gql`
  query engagementsByUserAndAuditedEntity($auditedEntityId: String!) {
    engagementsByUserAndAuditedEntity(auditedEntityId: $auditedEntityId) {
      id
      name
    }
  }
`
