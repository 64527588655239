import React, { useEffect, useState, useContext } from 'react'
import {
  Grid,
  createStyles,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core'
import {
  useIESelector,
  getAuditedEntitiesByUser,
  resetEngagementState,
  useIEDispatch,
  getEngagements,
  basicDetailsSelector,
  validateName,
  setBasicDetails,
  resetJoinsTableFiles,
  FILE_TYPE,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { Dialog, EBAutoComplete } from '@engine-b/shared/components'
import { ReactComponent as IconError } from './assets/IconError.svg'
import { deleteFileFromAzureContainer } from '@engine-b/integration-engine/data/azure-data-factory'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'

const useStyles = makeStyles((theme) =>
  createStyles({
    clientNameFilter: {},
    engagementNameFilter: {},
    JoinInputStyle: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
  })
)
/* eslint-disable-next-line */
export interface BasicDetailsProps { }
export function BasicDetails(props: BasicDetailsProps) {
  const DIALOG_WIDTH = '600px'
  const DIALOG_HEIGHT = '450px'
  const client = useApolloClient()
  const classes = useStyles()
  const dispatch = useIEDispatch()

  interface DialogState {
    isOpen: boolean
  }

  const azureClient = useContext(AzureClientContext)

  const auditedEntities = useIESelector(
    (state) => state.engagement.auditedEntities
  )
  const engagements = useIESelector((state) => state.engagement.engagements)
  const { tables } = useIESelector((state) => state.joins)
  const [fileNameValidation, setfileNameValidation] = useState({
    isInvalid: false,
    message: '',
  })
  const [auditedEntityDialogData, setauditedEntityDialogData] =
    useState<DialogState>({
      isOpen: false,
    })
  const [currentEngagementInfo, setCurrentEngagementInfo] = useState({
    client: { id: '', name: '' },
    engagement: { id: '', name: '' },
  })

  const {
    auditedEntity: selectedClientDetails,
    engagement: selectedEngagementDetails,
    filename,
  } = useIESelector((state) => basicDetailsSelector(state))
  const { joinId } = useIESelector((state) => state.joins)

  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getEngagements({ client, id: selectedClientDetails.id || '' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientDetails.id])

  const handleClientChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
      dispatch(
        setBasicDetails({
          key: 'auditedEntity',
          value: {
            name: '',
            id: null,
            auditFirm: { name: '', id: '', systemName: '' },
          },
        })
      )
      dispatch(
        setBasicDetails({ key: 'engagement', value: { name: '', id: null } })
      )
      return
    }
    dispatch(
      setBasicDetails({
        key: 'auditedEntity',
        value: data,
      })
    )
  }

  const handleEngagementChange = (data: any, reason: string) => {
    if (reason === 'clear') {
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
      dispatch(
        setBasicDetails({ key: 'engagement', value: { name: '', id: null } })
      )
      return
    }
    dispatch(
      setBasicDetails({
        key: 'engagement',
        value: { name: data.name, id: data.id },
      })
    )
  }

  const handleNameChange = (data: string) => {
    const flagArr: Array<boolean> = [];
    const validation: any = { ...fileNameValidation };
    setfileNameValidation({ ...validation });
    if (/[^a-zA-Z0-9\-.]/.test(data)) {
      flagArr.push(true);
      validation.message = "Please enter a valid name. No special characters allowed.";
    }

    if (data.length <= 0) {
      flagArr.push(true);
      validation.message = "Please enter a name.";
    }

    if (data.split('.').length > 2) {
      flagArr.push(true);
      validation.message = "Please enter a valid name. Use dots only to specify the file format. i.e. '.csv' "
    }

    if (flagArr.length > 0) {
      validation.isInvalid = true;
      setfileNameValidation({ ...validation });
    } else {
      validation.isInvalid = false;
      validation.message = '';
      setfileNameValidation({ ...validation });
    }

    dispatch(setBasicDetails({ key: 'name', value: data }));
    dispatch(validateName({ validation: !validation.isInvalid }));
  }

  const handleOnBlur = (data: string) => {
    const splitData: Array<string> = data.split(".");
    let validFileName: string;
    switch (true) {

      case (data.length === 0):
        break;

      case (
        splitData[1] &&
        splitData[1].length === 3 &&
        splitData[1].toLocaleLowerCase() != 'csv'
      ):
        // User tried to use an invalid format;
        validFileName = splitData[0] + ".csv";
        handleNameChange(validFileName);
        break;

      case (
        data.slice(-4).toLowerCase() != '.csv' &&
        !fileNameValidation.isInvalid
      ):
        // User didn't include a file format;
        validFileName = data + ".csv";
        handleNameChange(validFileName);
        break;

      default:
        handleNameChange(data);
        break;
    }
  }

  const handleClientChangeDialog = (
    isOpen: boolean,
    type: string,
    optionData: any,
    reason: string,
    selectedClient: string = selectedClientDetails.id,
    selectedEngagement: any = selectedEngagementDetails.id
  ) => {
    let hasFiles = false

    Object.keys(tables).forEach((table) => {
      const files = tables[table].files
      if (Object.keys(files).length > 0) hasFiles = true
    })
    
    if (type === 'client') {
      setCurrentEngagementInfo({
        client: { ...optionData },
        engagement: { ...currentEngagementInfo.engagement },
      })
    } else {
      setCurrentEngagementInfo({
        client: { ...currentEngagementInfo.client },
        engagement: { ...optionData },
      })
    }

    if (hasFiles) {
      if (
        (optionData?.id !== selectedClient &&
          optionData?.id !== selectedEngagement) ||
        reason === 'clear'
      ) {
        setauditedEntityDialogData({ isOpen: isOpen })
      }
    } else {
      switch (type) {
        case 'client':
          handleClientChange(optionData, reason)
          dispatch(
            setBasicDetails({
              key: 'engagement',
              value: { name: '', id: null },
            })
          )
          setCurrentEngagementInfo({
            client: { ...currentEngagementInfo.client },
            engagement: { id: '', name: '' },
          })
          break

        case 'engagement':
          handleEngagementChange(optionData, reason)
          break
      }
    }
  }

  const handleChangeDialogResponse = (resp: boolean) => {
    if (resp) {
      if (currentEngagementInfo.client.id !== '') {
        handleClientChange(currentEngagementInfo.client, '')
        setCurrentEngagementInfo({
          client: { ...currentEngagementInfo.client },
          engagement: { id: '', name: '' },
        })
        handleEngagementChange(currentEngagementInfo.engagement, 'clear')
      } else if (currentEngagementInfo.engagement.id !== '') {
        handleEngagementChange(currentEngagementInfo.engagement, '')
      }

      Object.keys(tables).forEach((table) => {
        dispatch(resetJoinsTableFiles({ tableName: table }))
        const files = tables[table].files
        Object.keys(files).forEach((f) => {
          const file = files[f]
          if (file.fileType === FILE_TYPE.UPLOADED) {
            const filePath = file?.path || ''
            if (filePath) {
              deleteFileFromAzureContainer({
                azureClient,
                fileSystemId: selectedClientDetails.auditFirm?.systemName || '',
                filePath,
              })
            }
          }
        })
      })

      setauditedEntityDialogData({ isOpen: false })
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
    } else {
      setauditedEntityDialogData({ isOpen: false })
      setCurrentEngagementInfo({
        client: { id: '', name: '' },
        engagement: { id: '', name: '' },
      })
    }
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Dialog
        cancelText="Cancel"
        confirmText="Yes"
        width={DIALOG_WIDTH}
        height={DIALOG_HEIGHT}
        open={auditedEntityDialogData.isOpen}
        onClose={(confirmation: boolean) =>
          handleChangeDialogResponse(confirmation)
        }
      >
        <>
          <IconError />
          <Typography component={'div'} className="dialog-title">
            You’ve changed the name of the client or engagement you’re adding
            data to - this will clear all of the information (including uploaded
            files) you’ve provided so far. Are you sure you want to continue?
          </Typography>
        </>
      </Dialog>

      <Grid xs={12} md={4} item className={classes.clientNameFilter}>
        <EBAutoComplete
          options={auditedEntities}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Client Names'}
          size="small"
          value={selectedClientDetails}
          onChange={(e: any, optionData, reason) => {
            handleClientChangeDialog(true, 'client', optionData, reason)
          }}
          disabled={joinId !== ''}
          color="primary"
          placeholder="Select Client Name"
          label="Client Name"
          backgroundColor='#fff'
          required={true}
        />
      </Grid>
      <Grid xs={12} md={4} item className={classes.engagementNameFilter}>
        <EBAutoComplete
          options={engagements}
          getOptionLabel={(option) => option.name}
          noOptionsText={'No Engagement Names'}
          size="small"
          value={selectedEngagementDetails}
          onChange={(e: any, optionData, reason) => {
            handleClientChangeDialog(true, 'engagement', optionData, reason)
          }}
          disabled={joinId !== ''}
          hiddenLabel={true}
          color='primary'
          placeholder="Select Engagement Name"
          label="Engagement Name"
          backgroundColor='#fff'
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="joinName"
          size="small"
          variant="outlined"
          color="primary"
          label="Output CSV Name"
          className={classes.JoinInputStyle}
          value={filename}
          disabled={joinId !== ''}
          onChange={(e) => {
            handleNameChange(e.target.value)
          }}
          onBlur={(e) => {
            handleOnBlur(e.target.value);
          }}
          error={fileNameValidation.isInvalid}
          helperText={fileNameValidation.message}
          placeholder={'Enter Output CSV Name'}
          required
          type="text"
          fullWidth={true}
        />
      </Grid>
    </Grid>
  )
}

export default BasicDetails
