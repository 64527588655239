import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useTraverseStyles = makeStyles((theme) =>
  createStyles({
    root: {
      bottom: '0',
      height: '72px',
      paddingTop: '12px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '16px',
      backgroundColor: theme.palette.background.paper,
    },
  })
)

export function TraverseButtonsLayout({ children, ...overrides }) {
  const classes = useTraverseStyles()
  return (
    <Grid
      container
      className={classes.root}
      direction={'row'}
      justify={'space-between'}
      {...overrides}
    >
      {children}
    </Grid>
  )
}
