import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Chip  } from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

/* eslint-disable-next-line */
export interface AutoSuggestFieldFiltersProps {
  categories: Array<string>,
  limitTags: number,
  categoryLevel: number,
  disabled: boolean,
  handleClose: () => void,
  updateCategoryLevel: (index: number) => void
}


const useStyles = makeStyles((theme) =>
  createStyles({
    chipSelected: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    chipUnselected: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    closeIcon: {
      height: '21px',
      width: '21px',
      position: 'relative',
      cursor: 'pointer',
      top: '4px',
    },
  })
)

export function AutoSuggestFieldFilters(props: AutoSuggestFieldFiltersProps) {
  const classes = useStyles()
  return (
    <div>
      { props.categories.map((cat, index) => (
        <Chip 
          className={ index <= props.categoryLevel ? classes.chipSelected : classes.chipUnselected} 
          key={`currLevel-${cat}-${index}`} 
          label={`${cat}` } 
          onClick={() => props.updateCategoryLevel(index-1)}
          disabled={props.disabled}
        />
        )) 
      }
      <CloseIcon
          className={classes.closeIcon}
          onClick={props.handleClose}
          role="button"
          data-testid="close-helper"
        />
    </div>
  )
}

AutoSuggestFieldFilters.propTypes = {
  categories: PropTypes.array,
  limitTags: PropTypes.number,
  valueToDisplay: PropTypes.array,
  categoryLevel: PropTypes.number,
}

AutoSuggestFieldFilters.defaultProps = {
  categories: ["Category 1", "Category 2", "Category 3", "Category 4"],
  limitTags: 4,
  valueToDisplay: ["1", "2", "3", "4"],
  categoryLevel: 1,
}

export default AutoSuggestFieldFilters
