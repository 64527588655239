import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '@engine-b/integration-engine/features/auth'
import { Button } from '@material-ui/core'

export const SignInButton = () => {
  const { instance } = useMsal()

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() =>
        instance.loginRedirect(loginRequest).catch((e) => {
          console.error(e)
        })
      }
    >
      Login
    </Button>
  )
}
