import React, { useCallback, ChangeEvent, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  DraftRunUpdate,
  Run,
  updateDraftRun,
  getGroupings,
  useIEDispatch,
  useIESelector,
  getAuditedEntitiesByUser,
  getEngagements,
  setIds,
  getDataIngestion,
  EBAutocompleteReason,
  createDataIngestion,
  getErps,
  updateLoaded,
} from '@engine-b/integration-engine/data/state/redux'
import {
  Description,
  INPUT_WIDTH,
  NEW_UI_INPUT_WIDTH,
} from '@engine-b/integration-engine/ui/form-components'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Step, disableField } from './Step'
import { TraverseButtons } from './TraverseButtons'
import { HELPER_TYPE } from '@engine-b/integration-engine/data/helper-text'
import { ReactComponent as IconInfo } from '../../../assets/IconInfo.svg'
import { EBAutoComplete } from '@engine-b/shared/components'
import { trackPageView } from '../../../services/AppInsights'
import { useHistory, useParams } from 'react-router-dom'
import CreateClientEngagement from './CreateClientEngagement'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import BounceLoader from 'react-spinners/BounceLoader'
import { EXTRACT_TYPE_LABELS,VALIDATION_MESSAGES } from './constants'
import { styled, Popper } from '@material-ui/core'
import { erpTypes } from '../../AddCustomERP/AddCustomERP'
import { COMPLETED_INGESTION } from 'apps/integration-engine/src/components/constants'
import DialogComponent from 'apps/integration-engine/src/components/SideBarNav/UserLeaveDialog'
import DatePicker from 'react-date-picker'
import DateRangeIcon from '@material-ui/icons/DateRange'


interface StepOneProps {
  handleNext: () => void
}

function isSet(field) {
  return (
    field !== false &&
    field !== undefined &&
    field !== null &&
    field !== -1 &&
    field !== '' &&
    typeof field !== 'number'
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionTitles: {
      margin: '10px 0',
      color: theme.palette.primary.dark,
      '& b': {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        width: '100%',
        margin: '0 0 10px 0',
      },
      '& span': {
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '21px',
        width: '100%',
      },
    },
    InputsBlock: {
      width: '100%',
      paddingTop: theme.spacing(4),
      textAlign: 'left',
      '& .combo-box': {
        flexDirection: 'column',
        width: NEW_UI_INPUT_WIDTH + 25 + 'px',
        height: '100px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        marginBottom: '20px',
        '& > label': {
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '0.16px',
          color: '#44697D',
          verticalAlign: 'center',
          marginBottom: '8px',
          '& > span': {
            fontSize: '20px',
            color: '#DE4D4D',
          },
        },
        '& .MuiTextField-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'hsla(205, 34%, 34%, 1)',
          },
          width: NEW_UI_INPUT_WIDTH + 'px',
          borderRadius: '8px',
          '& .MuiInputBase-root': {
            height: '44px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid ' + theme.palette.secondary.light,
            borderRadius: '8px',
          },
        },
      },
      '& .create-client-engagement': {
        display: 'flex',
        alignItems: 'flex-end',
        '@media (min-width: 1350px)': {
          height: '100px',
        },
        justifyContent: 'flex-end',
        '& .add-new-button': {
          minWidth: '164px',
          height: '44px',
          background:
            process.env.NX_ALLOW_CLIENT_ENGAGEMENT_CREATE === 'true'
              ? '#395c74'
              : '#e0e0e0',
          color: '#ffffff',
          textTransform: 'none',
        },
        '& .add-new-message': {
          display: 'flex',
          alignItems: 'center',
          height: '44px',
          '& .info-icon': {
            color: '#2196F3',
            marginLeft: '10px',
            width: '1rem',
            height: '1rem',
          },
        },
      },
      '& .notification': {
        maxWidth: '778px',
        width: 'auto',
        height: 'auto',
        minHeight: '58px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #89A0AC',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
        margin: '60px 0',
        '& > .title': {
          textAlign: 'left',
          font: 'normal normal bold 16px/10px Arial',
          letterSpacing: '0.16px',
          color: '#44697D',
          margin: '0 12px',
          lineHeight: '22px',
        },
      },
    },
    section: {
      width: '44%',
    },
    MuiInput: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      color: 'black',
      borderRadius: '8px',
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '10px',
      minWidth: `${INPUT_WIDTH}px`,
      width: NEW_UI_INPUT_WIDTH + 'px',
      height: '44px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.5px solid ' + theme.palette.secondary.light,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'hsla(205, 34%, 34%, 1)',
      },
      '&:before': {
        border: 'none',
      },
      '& .MuiInput-underline:not(.Mui-disabled):before': {
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },
      '&:hover:not(.Mui-disabled):before': {
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },
      '&.MuiAutocomplete-root': {
        padding: '0px',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
        {
          padding: '3px 4px 3px',
        },
    },
    startDate: {
      width: '230px',
      borderRadius: '4px',
      border: '0.5px solid ' + theme.palette.secondary.light,
      backgroundColor: 'white',
      position: 'relative',
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    endDate: {
      width: '230px',
      borderRadius: '4px',
      border: '0.5px solid ' + theme.palette.secondary.light,
      backgroundColor: 'white',
      position: 'relative',
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    startDateInput: {
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '0.5px solid ' + theme.palette.secondary.light,
      padding: '5px 10px',
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '21px',
      marginRight: '10px',
    },
    DateIcon: {
      position: 'absolute',
      right: '2.5px',
      top: '8.5px',
      cursor: 'pointer',
      fill: theme.palette.secondary.main,
    },
    DatePicker: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '8px',
      cursor: 'pointer',
      border: '0.5px solid ' + theme.palette.secondary.light,
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      width: NEW_UI_INPUT_WIDTH + 'px',
      height: '44px',

      '& .react-date-picker--open': {
        borderBottom: '2px solid ' + theme.palette.secondary.light,
      },

      '& .react-date-picker__inputGroup': {
        cursor: 'pointer',
        minWidth: `${INPUT_WIDTH}px`,
      },

      '& .react-date-picker__calendar': {
        width: '230px',
        '& .react-calendar__month-view__days': {
          height: '210px',
        },
      },
      '& .react-date-picker__wrapper': {
        border: 'none',
        padding: '10.5px 14px',
      },
      '& .react-date-picker__inputGroup__input:focus-visible': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:focus': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:active': {
        border: '1px solid ' + theme.palette.secondary.light,
      },
      '& .react-date-picker__inputGroup__input:invalid': {
        backgroundColor: 'transparent',
      },
      '& .react-calendar__tile': {
        borderRadius: '20px',
      },
      '& .react-calendar__tile--active': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .react-calendar__tile--active:enabled:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .react-calendar__tile--now': {
        backgroundColor: theme.background.secondary.active,
      },
      '& .react-calendar__tile--now:enabled:hover': {
        backgroundColor: theme.background.secondary.active,
      },
      '& .react-date-picker__inputGroup__input::focus': {
        border: 'none',
      },
    },
    rowItem: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '32px',
      rowGap: '26px',
    },
    errorMessage: {
      textAlign: 'left',
      font: 'normal normal 15px/10px Arial',
      color: 'red',
      marginTop: '10px',
    },
    FormWrapper: {
      width: '100%',
      paddingTop: '32px',
      rowGap: '26px',
    },
    MuiAutocomplete: {
      '& .MuiAutocomplete-root': {
        color: 'black',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        marginRight: '10px',
        minWidth: `${INPUT_WIDTH}px`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0.5px solid ' + theme.palette.secondary.light,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'hsla(205, 34%, 34%, 1)',
        },
        '& .MuiInputBase-input': {
          color: 'black',
          fontSize: '14px',
        },
      },
    },
  })
)

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    fontWeight: 'bold',
    fontSize: 'large',
  },
}))

const filterOptions = (options, { inputValue, getOptionLabel }) => {
  if (!inputValue) {
    return options
  }
  const input = inputValue.toString().toLowerCase()
  return options.filter((option) => {
    return (
      getOptionLabel(option).toLowerCase().includes(input) ||
      option?.erpType?.toLowerCase().includes(input)
    )
  })
}

export interface GroupingObj {
  name?: string
  version?: string
  lastUpdatedBy?: string
  id?: string
}

export function StepOne(props: Readonly<StepOneProps>) {
  const { formatMessage, locale } = useIntl()
  const { ingestionId } = useParams()
  const dispatch = useIEDispatch()
  const history = useHistory()

  const { draftRun, run, loaded } = useIESelector((state) => state.runs)
  const erpCollection = useIESelector((state) => state.erps)

  const groupings = useIESelector((state) => state.groupings)
  const { auditedEntities, engagements, auditedEntity, engagement } =
    useIESelector((state) => state.engagement)

  const [selectedGrouping, setSelectedGrouping] = useState<GroupingObj | null>(
    null
  )
  const [clientName, setClientName] = useState('')
  const [engagementName, setEngagementName] = useState('')
  const [isNext, setIsNext] = useState(false)
  const [newClientEngagement, setNewClientEngagement] = useState(false)
  const [clients, setClients] = useState(auditedEntity)
  const [engagementsList, setEngagementsList] = useState(engagement)
  const classes = useStyles()
  const [validationErrors, setValidationErrors] = useState({
    clientError: '',
    engagementValueError: '',
  })
  const [disableFields, setDisableFields] = useState([])
  const [validationMessage, setValidationMessage] = useState('')
  const [isCompletedIngestion, setIsCompletedIngestion] = useState(false)
  const client = useApolloClient()
  const invalidNames: string[] = [
    'LPT1',
    'LPT2',
    'LPT3',
    'LPT4',
    'LPT5',
    'LPT6',
    'LPT7',
    'LPT8',
    'LPT9',
    'COM1',
    'COM2',
    'COM3',
    'COM4',
    'COM5',
    'COM6',
    'COM7',
    'COM8',
    'COM9',
    'PRN',
    'AUX',
    'NUL',
    'CON',
    'CLOCK$',
    '.',
    '..'
  ]

  const { handleNext } = props
  const isPrefilledIngestion = () => run != null && !loaded && ingestionId
  const renderStepOneInfo = () => {
    setClientName(run.auditedEntity.name)
    dispatch(setIds({ key: 'auditedEntity', value: run.auditedEntity }))
    setTimeout(() => {
      setEngagementName(run.engagement.name)
      dispatch(setIds({ key: 'engagement', value: run.engagement }))
      dispatch(updateDraftRun(run))
      handleNext()
    }, 0)
  }

  useEffect(() => {
    // only execute when has prefilled data and loaded as false
    if (isPrefilledIngestion()) {
      if (run.uiState === 'COMPLETED') {
        setIsCompletedIngestion(true)
        return
      }
      renderStepOneInfo()
      dispatch(updateLoaded())
      handleOnNextStep()
    }
  }, [run])

  useEffect(() => {
    // only run when path has ingestionId
    if (ingestionId != undefined && !loaded) {
      dispatch(getDataIngestion({ client, id: ingestionId }))
    }
    dispatch(getAuditedEntitiesByUser(client))
    dispatch(getGroupings(client))
    dispatch(getErps(client))

    /**
     * Stage 11: Disable fields
     * Collects all the fields that need to be disabled
     */
    if (draftRun.preloaderInfo?.disableFields) {
      const disableFieldList = draftRun.preloaderInfo?.disableFields
        .filter((field: string) => field.startsWith('step1'))
        .map((field: string) => field.replace('step1', ''))
      setDisableFields(disableFieldList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    trackPageView({ name: 'Add Data: Step One' })
  }, [])

  function handleStartDateSelect(date: Date) {
    if (!date) return
    const updatePayload: DraftRunUpdate = { dataStartDate: date.toString() }
    dispatch(updateDraftRun(updatePayload))
  }

  function handleEndDateSelect(date: Date) {
    if (!date) return
    const updatePayload: DraftRunUpdate = { dataEndDate: date.toString() }
    dispatch(updateDraftRun(updatePayload))
  }

  function handleIngestionName(e) {
    dispatch(updateDraftRun({ name: e.target.value }))
    if (e.target.value.trim()?.length > 50) {
      setValidationMessage('Ingestion Name allows a maximum of 50 characters.')
    } else {
      setValidationMessage('')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  function handleGroupingSelect(e: ChangeEvent<{}>, selectedGroup: any) {
    selectedGroup
      ? setSelectedGrouping({ ...selectedGroup })
      : setSelectedGrouping(null)
    const updatePayload: DraftRunUpdate = {
      groupName: selectedGroup.name,
      groupVersion: selectedGroup.version,
      groupLastUpdatedBy: selectedGroup.lastUpdatedBy,
      groupId: selectedGroup.id,
    }
    dispatch(updateDraftRun(updatePayload))
  }

  const isEngagementSelect = (name: string) => {
    return (
      engagements.filter((engagement) => engagement.name === name).length > 0
    )
  }

  // This will need to be updated
  function isNextDisabled(draftRun: Run) {
    return !(
      !validationMessage &&
      isSet(draftRun.name) &&
      isSet(draftRun.extractType) &&
      isSet(draftRun.dataStartDate) &&
      isSet(draftRun.dataEndDate) &&
      isSet(auditedEntity?.id || clientName) &&
      !containsInvalidOrDirectoryChars(clientName) &&
      !isReservedName(clientName) &&
      isSet(engagement?.id || engagementName) &&
      !containsInvalidOrDirectoryChars(engagementName) &&
      !isReservedName(engagementName) &&
      isEngagementSelect(engagementName) &&
      !newClientEngagement
    )
  }

  const handleOnNextStep = () => {
    if (!run) {
      dispatch(createDataIngestion(client))
    }
    handleNext()
  }

  function containsInvalidOrDirectoryChars(name: string) {
    // Regular expression to match slash in name and returns error
    const invalidClientOrEngagementNameRegex =  /[/\\:*?"<>|]/g
    return invalidClientOrEngagementNameRegex.test(name)
  }

  function isReservedName(name: string) {
    return invalidNames.includes(name.toLocaleUpperCase())
  }


  const validateClientName=async (value:string) => {
    if (containsInvalidOrDirectoryChars(value)) {
      validationErrors.clientError =
      VALIDATION_MESSAGES.INVALID_CLIENT_NAME_SPECIAL_CHARACTERS
      setValidationErrors({ ...validationErrors })
    } else if (isReservedName(value)) {
      validationErrors.clientError =
      VALIDATION_MESSAGES.INVALID_CLIENT_NAME_RESERVED_KEYWORD
      setValidationErrors({ ...validationErrors })
    } else if (value?.trim()?.length > 50) {
      validationErrors.clientError =
      VALIDATION_MESSAGES.CLIENT_NAME_LENGTH
      setValidationErrors({ ...validationErrors })
    } else if (value?.length>0 && value?.trim()?.length===0) {
      validationErrors.clientError =
      VALIDATION_MESSAGES.CLIENT_NAME_REQUIRED
      setValidationErrors({ ...validationErrors })
    }else {
      validationErrors.clientError = ''
      setValidationErrors({ ...validationErrors })
    }
    setClientName(value)
  }

  const validateEngagementName=async (value:string) => {
    if (containsInvalidOrDirectoryChars(value)) {
      validationErrors.engagementValueError =
        VALIDATION_MESSAGES.INVALID_ENGAGEMENT_NAME_SPECIAL_CHARACTERS
      setValidationErrors({ ...validationErrors })
    } else if (isReservedName(value)) {
      validationErrors.engagementValueError =
      VALIDATION_MESSAGES.INVALID_ENGAGEMENT_NAME_RESERVED_KEYWORD
      setValidationErrors({ ...validationErrors })
    } else if (value?.trim()?.length > 50) {
      validationErrors.engagementValueError =
      VALIDATION_MESSAGES.ENGAGEMENT_NAME_LENGTH
      setValidationErrors({ ...validationErrors })
    }  else if (value?.length>0 && value?.trim()?.length===0) {
      validationErrors.engagementValueError =
      VALIDATION_MESSAGES.ENGAGEMENT_NAME_REQUIRED
      setValidationErrors({ ...validationErrors })
    } else {
      validationErrors.engagementValueError = ''
      setValidationErrors({ ...validationErrors })
    }
    setEngagementName(value)
  }
  

  useEffect(() => {
    const auditClient = auditedEntities.find(
      (audit) => audit.name === clientName
    )
    if (auditClient && !ingestionId) {
      dispatch(getEngagements({ client, id: auditClient.id }))
      dispatch(setIds({ key: 'auditedEntity', value: auditClient }))
    }
  }, [clientName])

  useEffect(() => {
    const auditEngagement = engagements.find(
      (engagement) => engagement.name === engagementName
    )
    if (auditEngagement) {
      dispatch(setIds({ key: 'engagement', value: auditEngagement }))
    } else if (engagementName && !ingestionId) {
      dispatch(setIds({ key: 'engagement', value: null }))
      setEngagementsList(null)
    }
  }, [engagementName])

  const onAuditedEntityChange = (e: any, value: any, reason: string) => {
    const result =
      reason === EBAutocompleteReason.CLEAR || typeof value === 'string'
        ? null
        : value
    dispatch(setIds({ key: 'auditedEntity', value: result }))
    setClients(result)
    if (result) {
      dispatch(getEngagements({ client, id: result.id }))
    }
  }

  const onEngagementChange = (e: any, value: any, reason: string) => {
    const result =
      reason === EBAutocompleteReason.CLEAR || typeof value === 'string'
        ? null
        : value
    dispatch(setIds({ key: 'engagement', value: result }))
    setEngagementsList(result)

    if (reason === 'all') {
      handleNext()
    }
  }

  useEffect(() => {
    if (auditedEntity === null) {
      dispatch(setIds({ key: 'engagement', value: null }))
      setClients(null)
    } else {
      dispatch(getEngagements({ client, id: auditedEntity.id }))
    }
    if (engagement === null) setEngagementsList(null)
  }, [auditedEntity])

  useEffect(() => {
    if (isNext && auditedEntity) {
      onEngagementChange(null, engagementName, 'all')
    }
  }, [auditedEntity, isNext])

  useEffect(() => {
    if (newClientEngagement) {
      dispatch(setIds({ key: 'auditedEntity', value: null }))
      dispatch(setIds({ key: 'engagement', value: null }))
    }
  }, [newClientEngagement])

  const availableOptions = Object.keys(erpCollection)
    .filter(
      (id) =>
        erpCollection[id]?.isActive &&
        erpCollection[id]?.extractType?.length > 0
    )
    .map((id) => {
      return {
        title: erpCollection[id]?.name,
        value: id,
        erpType: erpTypes[erpCollection[id]?.erpType],
      }
    })
    .sort(
      (erpIdA, erpIdB) =>
        erpIdA?.erpType?.localeCompare(erpIdB?.erpType) ||
        erpIdA?.title?.localeCompare(erpIdB?.title)
    )

  const getExtractTypeOptions = useCallback(() => {
    const selectedErp = erpCollection[draftRun.erpId]
    const extractTypes = selectedErp?.extractType
    // eslint-disable-next-line no-prototype-builtins
    const availableExtractTypeOptions = extractTypes
    return [
      <MenuItem key={-1} value={-1} disabled={true}>
        Please select a data extract type
      </MenuItem>,
    ].concat(
      availableExtractTypeOptions?.map((extractType) => {
        return (
          <MenuItem
            key={extractType}
            value={extractType}
            data-testid="menu-item-extract-type"
          >
            {EXTRACT_TYPE_LABELS[extractType]}
          </MenuItem>
        )
      })
    )
  }, [draftRun])

  const handleErpSelection = (selectedErp) => {
    const updatePayload: DraftRunUpdate = {
      erpId: selectedErp?.value || null,
      extractType: null,
    }
    dispatch(updateDraftRun(updatePayload))
  }

  const handleSelection = (stateKey: string) => (e) => {
    const updatePayload: DraftRunUpdate = {
      [stateKey]: e.target.value || null,
      extractType: stateKey === 'erpId' ? -1 : e.target.value,
    }
    dispatch(updateDraftRun(updatePayload))
  }

  const extractValue = (id) =>
    availableOptions.find((option) => option.value === id) || null

  return (
    <Step
      content={
        <>
          <DialogComponent
            open={isCompletedIngestion}
            onClose={() => {
              setIsCompletedIngestion(false)
              history.push(
                localizeRouteKey(formatMessage, locale, AppRoute.AddData)
              )
            }}
            confirmText="Okay"
            title={COMPLETED_INGESTION}
            subtitle={''}
          />
          <CreateClientEngagement
            newClientEngagement={newClientEngagement}
            setNewClientEngagement={setNewClientEngagement}
            clientName={clientName}
            setClientName={setClientName}
            containsInvalidOrDirectoryChars={containsInvalidOrDirectoryChars}
            isReservedName={isReservedName}
            disableFields={disableFields}
            setClients={setClients}
            setEngagementsList={setEngagementsList}
          />
          <Typography
            className="step-title"
            variant={'h5'}
            color={'textPrimary'}
          >
            Step 1: Audited Entity Details and Define Data Source
          </Typography>
          <Grid
            container
            direction={'column'}
            spacing={3}
            className={classes.InputsBlock}
          >
            <Grid item xl={12} className={classes.rowItem}>
              <div className="combo-box">
                <label htmlFor="client-name">
                  Client Name <span>*</span>
                </label>
                <EBAutoComplete
                  freeSolo={true}
                  forcePopupIcon={true}
                  id="client-name"
                  options={auditedEntities}
                  getOptionLabel={(option) => option.name || ''}
                  value={clients}
                  onChange={onAuditedEntityChange}
                  onInputChange={(e, value) => validateClientName(value)}
                  disabled={disableField(disableFields, 'clientName')}
                  size="small"
                  fullWidth={false}
                  placeholder="Select Client Name"
                  error={validationErrors.clientError !== ''}
                  helperText={validationErrors.clientError}
                />
              </div>
              <div className="combo-box">
                <label htmlFor="engagement-name">
                  Engagement Name <span>*</span>
                </label>
                <EBAutoComplete
                  freeSolo={true}
                  forcePopupIcon={true}
                  id="engagement-name"
                  options={engagements}
                  getOptionLabel={(option) => option?.name || ''}
                  value={engagementsList}
                  onChange={onEngagementChange}
                  onInputChange={(e, value) => validateEngagementName(value)}
                  disabled={disableField(disableFields, 'engagementName')}
                  size="small"
                  fullWidth={false}
                  placeholder="Select Engagement Name"
                  error={validationErrors.engagementValueError != ''}
                  helperText={validationErrors.engagementValueError}
                />
              </div>

              <div className="create-client-engagement">
                <Button
                  onClick={() => setNewClientEngagement(true)}
                  variant="contained"
                  className="add-new-button"
                  disabled={
                    process.env.NX_ALLOW_CLIENT_ENGAGEMENT_CREATE !== 'true'
                  }
                >
                  Add New
                </Button>
                <Description
                  title={''}
                  helperType={HELPER_TYPE.CLIENT_ENGAGEMENT_CREATE}
                />
              </div>
            </Grid>
            <Grid item xl={12} className={classes.rowItem}>
              <div className="combo-box">
                <Description
                  title={'Client System Name'}
                  description={
                    'Please select from which client system the data was exported:'
                  }
                  helperType={HELPER_TYPE.CLIENT_SYSTEM}
                  required
                >
                  {erpCollection ? (
                    <div className={classes.MuiAutocomplete}>
                      <EBAutoComplete
                        freeSolo={true}
                        forcePopupIcon={true}
                        PopperComponent={StyledPopper}
                        options={availableOptions}
                        groupBy={(option) => option.erpType}
                        getOptionLabel={(option) => option.title || ''}
                        value={extractValue(draftRun?.erpId)}
                        onChange={(e: any, value, reason) =>
                          handleErpSelection(value)
                        }
                        filterOptions={filterOptions}
                        width={NEW_UI_INPUT_WIDTH}
                        size="small"
                        hiddenLabel={true}
                        placeholder="Please select an ERP system"
                        backgroundColor="#fff"
                        height={44}
                        disabled={disableField(disableFields, 'systemName')}
                      />
                    </div>
                  ) : (
                    <BounceLoader color={'#00A9AB'} size={20} />
                  )}
                </Description>
              </div>
              <div className="combo-box">
                <Description
                  title={'Data Extract Type'}
                  description={
                    'Please select the export format of the data files:'
                  }
                  helperType={HELPER_TYPE.DATA_EXTRACT_TYPE}
                  required
                >
                  <Select
                    labelId="extractTypeLabel"
                    id="select"
                    variant="outlined"
                    value={draftRun?.extractType || -1}
                    onChange={handleSelection('extractType')}
                    className={classes.MuiInput}
                    disabled={disableField(disableFields, 'extractType')}
                  >
                    {getExtractTypeOptions()}
                  </Select>
                </Description>
              </div>
            </Grid>
            <Grid item container xl={12} style={{paddingTop: "0"}}>
              <Description
                title={'Accounting Dates'}
                description={'Please enter the dates of the audited entity:'}
                helperType={HELPER_TYPE.ACCOUNTING_DATES}
              >
                <div className={classes.rowItem}>
                  <Grid item>
                    <div className={'description'}>Start Date:</div>
                    <DatePicker
                      onChange={handleStartDateSelect}
                      value={new Date(draftRun.dataStartDate)}
                      calendarIcon={
                        <DateRangeIcon className={classes.DateIcon} />
                      }
                      clearIcon={null}
                      dayPlaceholder=""
                      monthPlaceholder=""
                      yearPlaceholder=""
                      format={locale === 'en-US' ? 'MM/dd/y' : 'dd/MM/y'}
                      required
                      className={classes.DatePicker}
                      disabled={disableField(disableFields, 'startDate')}
                    />
                  </Grid>
                  <Grid item>
                    <div className={'description'}>End Date:</div>
                    <DatePicker
                      onChange={handleEndDateSelect}
                      minDate={new Date(draftRun.dataStartDate)}
                      value={new Date(draftRun.dataEndDate)}
                      calendarIcon={
                        <DateRangeIcon className={classes.DateIcon} />
                      }
                      clearIcon={null}
                      dayPlaceholder=""
                      monthPlaceholder=""
                      yearPlaceholder=""
                      format={locale === 'en-US' ? 'MM/dd/y' : 'dd/MM/y'}
                      required
                      className={classes.DatePicker}
                      disabled={disableField(disableFields, 'endDate')}
                    />
                  </Grid>
                </div>
              </Description>
            </Grid>
            <Grid item xl={12} className={classes.rowItem}>
              <div className="combo-box">
                <Description
                  title={'Ingestion Name'}
                  description={'Please name the data you are uploading:'}
                  helperType={HELPER_TYPE.ENTITY_LEGAL_NAME}
                  required
                >
                  <TextField
                    id="ingestionName"
                    name="ingestionName"
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth={false}
                    value={draftRun.name || ''}
                    onChange={handleIngestionName}
                    placeholder={'Enter Ingestion Name'}
                    required
                    type="text"
                    disabled={disableField(disableFields, 'ingestionName')}
                  />
                </Description>
                {validationMessage && (
                  <Typography className={classes.errorMessage}>
                    {validationMessage}
                  </Typography>
                )}
              </div>
              <div className="combo-box">
                {process.env.NX_ENABLE_GROUPINGS === 'true' &&
                  process.env.NX_ENABLE_NORMAL_GROUPINGS === 'true' && (
                    <Description
                      title={'Grouping Details'}
                      description={'Please select grouping details:'}
                      helperType={HELPER_TYPE.GROUPER_SELECTION}
                    >
                      <EBAutoComplete
                        forcePopupIcon={true}
                        options={groupings.filter((item) => item.complete)}
                        getOptionLabel={({ name, version }) =>
                          name && version ? `${name} (${version})` : ''
                        }
                        value={
                          isPrefilledIngestion && selectedGrouping
                            ? selectedGrouping
                            : {
                                id: run?.groupId,
                                name: run?.groupName,
                                version: run?.groupVersion,
                                lastUpdatedBy: run?.groupLastUpdatedBy,
                              }
                        }
                        onChange={handleGroupingSelect}
                        size="small"
                        fullWidth={false}
                        placeholder="Select Grouping"
                        disabled={disableField(disableFields, 'grouping')}
                      />
                    </Description>
                  )}
              </div>
            </Grid>
    
            <Grid item xl={12}>
              <div className="notification">
                <IconInfo />
                <Typography className="title">
                  Can't see your Client or Engagement in the list? Please
                  contact your system administrator.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </>
      }
      footer={
        <TraverseButtons
          isBackDisabled={false}
          handleNext={handleOnNextStep}
          isNextDisabled={isNextDisabled(draftRun)}
        />
      }
    />
  )
}
