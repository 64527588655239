import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  updateEdge,
  addEdge,
  ReactFlowProvider,
  Background,
  BackgroundVariant,
  useOnSelectionChange,
  Node,
  Edge,
  MarkerType,
} from 'reactflow'
import Sidebar from './Operations'
import 'reactflow/dist/style.css'
import cuid from 'cuid'
import {
  ExtractNode,
  FileNode,
  TransformNode,
} from '@engine-b/shared/components'
import WorkOffOutlinedIcon from '@material-ui/icons/WorkOffOutlined'
import ScheduleIcon from '@material-ui/icons/Schedule'
import UpdateIcon from '@material-ui/icons/Update'
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined'
import { ReactComponent as NetIcon } from './assets/net.svg'
import { ReactComponent as SumIcon } from './assets/sum.svg'
import { ReactComponent as MaxIcon } from './assets/max.svg'
import { ReactComponent as MinIcon } from './assets/min.svg'
import { ReactComponent as CountIcon } from './assets/count.svg'
import { ReactComponent as GroupIcon } from './assets/group.svg'
import { ReactComponent as CompareIcon } from './assets/compare.svg'
import { ReactComponent as FileIcon } from './assets/file.svg'
import { ReactComponent as ExtractIcon } from './assets/extract.svg'
import { ReactComponent as DuplicateIcon } from './assets/duplicate.svg'
import { ReactComponent as FilterIcon } from './assets/filter.svg'
import { ReactComponent as DeleteIcon } from './assets/delete.svg'
import { ReactComponent as AverageIcon } from './assets/avg.svg'
import { ReactComponent as OverlapIcon } from './assets/overlap.svg'
import {
  removeNode,
  addNode,
  addEdges,
  useIEDispatch,
  updateActiveNodeIndex,
  useIESelector,
  removeEdge,
  modifyEdge,
  resetNode,
  modifyNodePosition,
} from '@engine-b/integration-engine/data/state/redux'
import { getTargetNodeIds } from './ConfigurationPanel'
import {
  COMPARE,
  COUNT,
  DELETE_COLUMN,
  DUPLICATE_ROW,
  EXTRACT,
  FILTER,
  GROUP_BY,
  LOAD,
  MAX,
  MIN,
  NET,
  SUM,
  AVG,
  NON_WORKING_DAYS,
  OUTSIDE_WORKING_HOURS,
  BACKDATING,
  MULTIPLE_CALCULATION,
  OVERLAP,
} from './constants'

const initialNodes: Node<any, string | undefined>[] = []

const initialEdges: Edge<any>[] = []

const nodeTypes = {
  extract: ExtractNode,
  transform: TransformNode,
  load: FileNode,
}

export interface DAAFlowProps {
  hideOperations?: boolean
  loadMap?: boolean
  styles?: any
}

const DAAFlow = (props: DAAFlowProps) => {
  const { hideOperations, loadMap } = props
  const edgeUpdateSuccessful = useRef(true)
  const dispatch = useIEDispatch()
  const { nodes: savedNodes, edges: savedEdges } = useIESelector(
    (state) => state.digitalAuditAnalytics
  )

  const reactFlowWrapper = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)

  const onConnect = useCallback((params) => {
    const { source, target, targetHandle } = params
    let label = null
    if (targetHandle === 'a') {
      label = 'Source'
    } else if (targetHandle === 'b') {
      label = 'Support'
    }
    const edge = {
      id: cuid(),
      source,
      target,
      label,
      updatable: false,
      targetHandle: targetHandle,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    }
    dispatch(addEdges({ edge }))
    setEdges((els) => addEdge(edge, els))
  }, [])

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false
  }, [])

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true
    setEdges((els) =>
      updateEdge(oldEdge, newConnection, els, { shouldReplaceId: false })
    )
    const edge = {
      id: oldEdge.id,
      source: oldEdge.source,
      target: newConnection.target,
      updatable: false,
    }
    dispatch(modifyEdge(edge))
  }, [])

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id))
    }

    edgeUpdateSuccessful.current = true
  }, [])

  const onDrop = (event: {
    preventDefault: () => void
    dataTransfer: { getData: (arg0: string) => any }
    clientX: number
    clientY: number
  }) => {
    event.preventDefault()

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const type = event.dataTransfer.getData('application/reactflow')
    const icon = event.dataTransfer.getData('application/reactflow/icon')
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
    if (type) {
      const newNode = {
        id: cuid(),
        type,
        position,
        data: { icon: getIcon(icon, type), label: icon },
      }

      const payload = {
        node: {
          id: newNode.id,
          position: newNode.position,
          data: {
            ...newNode.data,
            operation_name: icon,
            type: newNode.type,
            ...(icon === COUNT && { unique: false }),
            ...(icon === BACKDATING && { is_bkd_custom: false }),
          },
        },
      }

      delete payload.node.data.icon
      dispatch(addNode(payload))

      setNodes((nds) => nds.concat(newNode))
    }
  }

  const onNodesDelete = useCallback(
    (deleted) => {
      const nodeIds = deleted.map((n) => n.id)
      if (nodeIds.length > 0) {
        const nodeId = nodeIds[0]
        dispatch(removeNode({ nodeId }))
      }
    },
    [nodes, edges]
  )

  const onEdgesDelete = useCallback(
    (deleted) => {
      if (deleted.length > 0) {
        const edgeIds = deleted.map((e) => {
          resetAllDescendantNodes(e.target)
          return e.id
        })

        dispatch(removeEdge({ edgeIds }))
      }
    },
    [nodes, edges]
  )

  /**
   * Handles all the edge changes occur in pane
   * @param changes Types and values of  edge changes
   */
  const handleEdgesChange = (changes: any[]) => {
    onEdgesChange(changes)
  }

  const onDragOver = (event: {
    preventDefault: () => void
    dataTransfer: { dropEffect: string }
  }) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onInit = (_reactFlowInstance: any) => {
    setReactFlowInstance(_reactFlowInstance)
  }

  /**
   * Node selection handler method
   */
  useOnSelectionChange({
    onChange: ({ nodes: nds }) => {
      if (nds.length > 0) {
        const nodeId = nds[0].id
        const index = nodes.findIndex((node) => node.id === nodeId)
        dispatch(updateActiveNodeIndex({ index }))
      } else {
        dispatch(updateActiveNodeIndex({ index: -1 }))
      }
    },
  })

  const getIcon = (icon: string, type: string) => {
    let nodeIcon

    if (type === EXTRACT) {
      nodeIcon = <ExtractIcon />
    } else if (type === LOAD) {
      nodeIcon = <FileIcon />
    } else {
      switch (icon) {
        case NET:
          nodeIcon = <NetIcon />
          break
        case SUM:
          nodeIcon = <SumIcon />
          break
        case AVG:
          nodeIcon = <AverageIcon />
          break
        case MAX:
          nodeIcon = <MaxIcon />
          break
        case MIN:
          nodeIcon = <MinIcon />
          break
        case COUNT:
          nodeIcon = <CountIcon />
          break
        case FILTER:
          nodeIcon = <FilterIcon />
          break
        case DELETE_COLUMN:
          nodeIcon = <DeleteIcon />
          break
        case GROUP_BY:
          nodeIcon = <GroupIcon />
          break
        case COMPARE:
          nodeIcon = <CompareIcon />
          break
        case OVERLAP:
          nodeIcon = <OverlapIcon />
          break
        case DUPLICATE_ROW:
          nodeIcon = <DuplicateIcon />
          break
        case NON_WORKING_DAYS:
          nodeIcon = <WorkOffOutlinedIcon style={{ color: '#a5b2bd' }} />
          break
        case OUTSIDE_WORKING_HOURS:
          nodeIcon = <ScheduleIcon style={{ color: '#a5b2bd' }} />
          break
        case BACKDATING:
          nodeIcon = <UpdateIcon style={{ color: '#a5b2bd' }} />
          break
        case MULTIPLE_CALCULATION:
          nodeIcon = <ExposureOutlinedIcon color="disabled" />
          break
        default:
          break
      }
    }

    return nodeIcon
  }

  /**
   * Reset all descendants of given source id
   * Used recursion to achieve this scenario
   * @param id
   */
  const resetAllDescendantNodes = (id: any): any => {
    dispatch(resetNode(id))
    const targetNodes = getTargetNodeIds(id, edges)
    if (targetNodes.length === 0) {
      return 1
    }
    return targetNodes.forEach((id) => resetAllDescendantNodes(id))
  }

  useEffect(() => {
    if (savedNodes) {
      if (hideOperations || loadMap) {
        const copy = savedNodes.map((node: any) => ({
          ...node,
          type: node.data.type,
          data: {
            ...node.data,
            icon: getIcon(node.data.operation_name, node.data.type),
          },
        }))
        setNodes(copy)
        if (savedEdges) {
          setEdges(
            savedEdges.map((eg) => ({
              ...eg,
              updatable: false,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
              },
            }))
          )
        }
      } else {
        const copy = savedNodes.map((node: any, i) => ({
          ...nodes[i],
          data: {
            ...node.data,
            icon: getIcon(node.data.operation_name, node.data.type),
          },
        }))
        setNodes(copy)
      }
    }
  }, [savedNodes, savedEdges])

  const onNodeDragStop = useCallback(
    (_, node) => {
      const { icon, ...nodeData } = node.data
      dispatch(modifyNodePosition({ node: { ...node, data: nodeData } }))
    },
    [edges]
  )

  return (
    <div
      style={{
        width: '100%',
        height: '886px',
        position: 'relative',
        ...props?.styles,
      }}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onDrop={onDrop}
        onInit={onInit}
        onDragOver={onDragOver}
        onNodeDragStop={onNodeDragStop}
        onNodesChange={onNodesChange}
        onEdgesChange={handleEdgesChange}
        snapGrid={[16, 16]}
        fitView
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        deleteKeyCode={!hideOperations ? ['Backspace', 'Delete'] : null}
        attributionPosition="top-right"
        edgesUpdatable={!hideOperations}
        edgesFocusable={!hideOperations}
        nodesDraggable={!hideOperations}
        nodesConnectable={!hideOperations}
        nodesFocusable={!hideOperations}
        zoomOnDoubleClick={!hideOperations}
        zoomOnPinch={!hideOperations}
      >
        <Controls showInteractive={!hideOperations} position="top-right" />
        <Background variant={BackgroundVariant.Dots} />
      </ReactFlow>
      {!hideOperations && <Sidebar />}
    </div>
  )
}

const MainFlow = (props: DAAFlowProps) => (
  <ReactFlowProvider>
    <DAAFlow
      hideOperations={props.hideOperations}
      loadMap={props.loadMap}
      styles={props.styles}
    />
  </ReactFlowProvider>
)

DAAFlow.defaultProps = {
  hideOperations: false,
  loadMap: false,
  styles: {},
}

MainFlow.defaultProps = {
  hideOperations: false,
  loadMap: false,
  styles: {},
}

export default MainFlow
