import { configureStore } from '@reduxjs/toolkit'
import { cdmEntitiesReducer } from './slices/cdmEntities-slice'
import { erpsReducer } from './slices/erps-slice'
import { fileMappingsReducer } from './slices/fileMappings-slice'
import { runsReducer } from './slices/runs-slice'
import { helperReducer } from './slices/helper-slice'
import { customMapperReducer } from './slices/customMapper-slice'
import { customERPReducer } from './slices/custom-erp-slice'
import { groupingReducer } from './slices/grouping-slice'
import { customGrouperReducer } from './slices/custom-grouper-slice'
import { preUploadReducer } from './slices/preUploadedFiles-slice'
import { preUploadJoinReducer } from './slices/preUploadedJoinFiles-slice'
import { preUploadedExtractionReducer } from './slices/preUploadedExtractionFiles-slice'
import { engagementReducer } from './slices/engagement-slice'
import { joinsReducer } from './slices/joins'
import { extractionReducer } from './slices/extractions'
import { auditStateReducer } from './slices/auditAnalytics'
import { attributeReducer } from './slices/attributes-slice'
import { userReducer } from './slices/user-slice'
import { ugmReducer } from './slices/ugm-slice'
import { conditionalColumnReducer } from './slices/conditionalColumn-slice'
import { digitalAuditReducer } from './slices/digitalAuditAnalytics'


export const reducer = {
  runs: runsReducer,
  erps: erpsReducer,
  cdmEntities: cdmEntitiesReducer,
  fileMappings: fileMappingsReducer,
  helper: helperReducer,
  customMapper: customMapperReducer,
  customERP: customERPReducer,
  groupings: groupingReducer,
  customGrouper: customGrouperReducer,
  preUploadedFiles: preUploadReducer,
  preUploadedJoinsFiles: preUploadJoinReducer,
  preUploadedExtractionFiles: preUploadedExtractionReducer,
  engagement: engagementReducer,
  attributes: attributeReducer,
  joins: joinsReducer,
  extractions: extractionReducer,
  auditAnalytics: auditStateReducer,
  user: userReducer,
  ugm: ugmReducer,
  conditionalColumn: conditionalColumnReducer,
  digitalAuditAnalytics: digitalAuditReducer
}

export const store = configureStore({
  reducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: { runs: RunsState }
export type AppDispatch = typeof store.dispatch
