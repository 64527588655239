import React from 'react';

export default function ConnectionLine({ fromX, fromY, toX, toY }) {

    return (
        <g>
            <path
                fill="none"
                stroke={'black'}
                strokeWidth={1.0}
                className="animated"
                d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
            />
            <circle
                cx={toX}
                cy={toY}
                fill="#fff"
                r={3}
                stroke={'black'}
                strokeWidth={1.0}
            />
        </g>
    );
};