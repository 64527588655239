import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import {
  ApolloProviderWithAuth,
  AzureClientProviderWithAuth,
  LoginRedirect,
} from '@engine-b/integration-engine/features/auth'
import {
  AppRoute,
  LocalizedSwitch,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { SideBarLayout } from '@engine-b/integration-engine/ui/layout'
import { theme } from '@engine-b/integration-engine/ui/theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect, Route } from 'react-router-dom'
import { SideBarNav } from '../components/SideBarNav/SideBarNav'
import { AddDataView } from '../views/AddDataView/AddDataView'
import { HelpDashboardView } from '../views/HelpView/HelpDashboardView'
import { HelpEmailView } from '../views/HelpView/HelpEmailView'
import { PipelineRunReport } from '../views/PipelineRunReport/PipelineRunReport'
import { StatusView } from '../views/StatusView/StatusView'
import { ExtractionsView } from '../views/ExtractionsView/ExtractionsView'
import { ExtractionsSettingsView } from '../views/ExtractionsView/ExtractionsSettingsView'
import AddCustomERP from '../views/AddCustomERP/AddCustomERP'
import CustomGrouping from '../views/CustomGroupingView/CustomGrouping'
import TableJoinsView from '../views/TableJoinsView/TableJoinsView'
import AuditAnalytics from '../views/AuditAnalytics'
import { TableJoinsView as CreateTableJoinsView } from '@engine-b/integration-engine/features/table-joins'
import { NotFoundView } from '../views/NotFoundView/NotFoundView'
import {
  AuditAnalyticsView as CreateAuditAnalyticsView,
  DataAndAnalyticsView,
  CreateAnalyticsPipeline,
  AnalyticsPipelineSummary,
} from '@engine-b/integration-engine/features/audit-analytics'
import GroupingsDataScoreCard from '../views/GroupingsDataScoreCard/GroupingsDataScoreCard'
import ErpConnectionView from '../views/ExtractionsView/ErpConnectionView'
import ManageExtractionsView from '../views/ExtractionsView/ManageExtractionsView'
import ConnectionAuthorizationView from '../views/ExtractionsView/ConnectionAuthorizationView'
import AuditAnalyticsView from '../views/AuditAnalytics/Status'

export function App() {
  const { formatMessage, locale } = useIntl()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticatedTemplate>
        <ApolloProviderWithAuth>
          <AzureClientProviderWithAuth>
            <SideBarLayout sideBarContent={<SideBarNav />}>
              <LocalizedSwitch>
                <Redirect
                  from="/"
                  exact
                  to={localizeRouteKey(formatMessage, locale, AppRoute.Status)}
                />
                <Route path="/status/:runId">
                  <PipelineRunReport />
                </Route>
                <Route path="/status">
                  <StatusView />
                </Route>
                <Route path="/add-data/:ingestionId">
                  <AddDataView />
                </Route>
                <Route path="/add-data">
                  <AddDataView />
                </Route>
                <Route path="/support/email">
                  <HelpEmailView />
                </Route>
                {process.env.NX_ENABLE_SUPPORT_PAGE === 'true' && (
                  <Route path="/support">
                    <HelpDashboardView />
                  </Route>
                )}
                {process.env.NX_ENABLE_QUICK_GROUPING === 'true' && (
                  <Route path="/custom-grouping/:type/:id">
                    <CustomGrouping />
                  </Route>
                )}
                <Route path="/custom-grouping">
                  <CustomGrouping />
                </Route>
                <Route path="/table-joins/create">
                  <CreateTableJoinsView />
                </Route>
                <Route path="/table-joins">
                  <TableJoinsView />
                </Route>
                {process.env.NX_ENABLE_DNA === 'true' && (
                  <Route path="/data-and-analytics/create-pipeline">
                    <CreateAnalyticsPipeline />
                  </Route>
                )}
                {process.env.NX_ENABLE_DNA === 'true' && (
                  <Route path="/data-and-analytics/:pipelineId">
                    <AnalyticsPipelineSummary />
                  </Route>
                )}
                {process.env.NX_ENABLE_DNA === 'true' && (
                  <Route path="/data-and-analytics">
                    <DataAndAnalyticsView />
                  </Route>
                )}
                {process.env.NX_ENABLE_DAA === 'true' && (
                  <Route path="/audit-analytics">
                    <AuditAnalytics />
                  </Route>
                )}
                {process.env.NX_ENABLE_DAA === 'true' && (
                  <Route path="/audit-view/:operationId">
                    <AuditAnalyticsView />
                  </Route>
                )}
                {process.env.NX_ENABLE_DAA === 'true' && (
                  <Route path="/audit-analytics-create/:operationId">
                    <CreateAuditAnalyticsView />
                  </Route>
                )}
                {process.env.NX_ENABLE_DAA === 'true' && (
                  <Route path="/audit-analytics-create">
                    <CreateAuditAnalyticsView />
                  </Route>
                )}

                {process.env.NX_ENABLE_CUSTOM_MAPS === 'true' && (
                  <Route path="/add-custom-erp">
                    <AddCustomERP />
                  </Route>
                )}
                <Route path="/grouping/:id">
                  <GroupingsDataScoreCard />
                </Route>
                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions/settings/authorize">
                    <ConnectionAuthorizationView />
                  </Route>
                )}
                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions/settings/manage-extractions">
                    <ManageExtractionsView />
                  </Route>
                )}
                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions/settings/manage-erp/:id">
                    <ErpConnectionView />
                  </Route>
                )}

                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions/settings/manage-erp">
                    <ErpConnectionView />
                  </Route>
                )}
                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions/settings">
                    <ExtractionsSettingsView />
                  </Route>
                )}
                {process.env.NX_ENABLE_API_EXTRACTIONS === 'true' && (
                  <Route path="/extractions">
                    <ExtractionsView />
                  </Route>
                )}
                <Route path="*">
                  <NotFoundView />
                </Route>
              </LocalizedSwitch>
            </SideBarLayout>
          </AzureClientProviderWithAuth>
        </ApolloProviderWithAuth>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginRedirect />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  )
}
export default App
