import { Grid, TextField } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { EBAutoComplete, Select } from '@engine-b/shared/components'
import {
  useIESelector,
  useIEDispatch,
  ANALYTICS_STATUS,
  getAuditedEntitiesByUser,
  resetEngagementState,
  getEngagements,
  getDataIngestions,
  ANALYTICS_STATUS_TITLES,
} from '@engine-b/integration-engine/data/state/redux'
import { getOperationLogs } from 'libs/integration-engine/data/state/redux/src/lib/slices/auditAnalytics'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useApolloClient } from '@apollo/client'

const useStyles = makeStyles((theme) => {
  return createStyles({
    textInput: {
      width: '20%',
      height: '60px',
      '& .MuiTextField-root': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    statusFilter: {
      width: '15%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    refreshIcon: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid #CBCBCB',
      height: '40px',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#395C74'
      }
    }
  })
})

const statusItems = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: ANALYTICS_STATUS.COMPLETED,
    label: ANALYTICS_STATUS_TITLES[ANALYTICS_STATUS.COMPLETED],
  },
  {
    value: ANALYTICS_STATUS.IN_PROGRESS,
    label: ANALYTICS_STATUS_TITLES[ANALYTICS_STATUS.IN_PROGRESS],
  },
  {
    value: ANALYTICS_STATUS.INITIALIZED,
    label: ANALYTICS_STATUS_TITLES[ANALYTICS_STATUS.INITIALIZED],
  },
  {
    value: ANALYTICS_STATUS.FAILED,
    label: ANALYTICS_STATUS_TITLES[ANALYTICS_STATUS.FAILED],
  },
  {
    value: ANALYTICS_STATUS.FAILED_TO_PROCESS,
    label: ANALYTICS_STATUS_TITLES[ANALYTICS_STATUS.FAILED_TO_PROCESS],
  },
]

type AnalyticsFilterState = {
  name: string
  status: string
  engagement: {id : string , name : string}
  client: { id: string, name: string },
  ingestion: { id: string, name: string },
}

type AnalyticsFilterProps = {
  filterState: AnalyticsFilterState
  applyFilter(params: { value: any; filterName: string }): void
  rows: any[]
}

export function AnalyticsFilters({
  rows,
  filterState,
  applyFilter,
}: AnalyticsFilterProps) {
  const { textInput, statusFilter, refreshIcon } = useStyles()

  const { loading } = useIESelector((state) => state.auditAnalytics)

  const dispatch = useIEDispatch()
  const client = useApolloClient()
  useEffect(() => {
    dispatch(getAuditedEntitiesByUser(client))
    return () => {
      dispatch(resetEngagementState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filterState.client) {
      dispatch(getEngagements({ client, id: filterState.client.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.client])

  useEffect(() => {
    dispatch(getDataIngestions({ client }))
  }, [filterState.engagement])

  useEffect(() => {
    console.log(filterState)
  }, [rows])

  return (
    <>
      <Grid item className={textInput}>
        <EBAutoComplete
          freeSolo={true}
          forcePopupIcon={true}
          options={Array.from(
            new Set(rows.map((item) => item?.dataIngestion?.auditedEntity))
          )}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Client Names'}
          size="small"
          onChange={(e: any, optionData: any, reason) => {
            let val = optionData
            if (reason === 'clear') {
              val = null
            }
            applyFilter({
              value: val,
              filterName: 'client',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Client Name"
          color= 'primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item className={textInput}>
        <EBAutoComplete
          freeSolo={true}
          forcePopupIcon={true}
          options={ Array.from(
            new Set(rows.map((item) => item?.dataIngestion?.engagement))
          )}
          getOptionLabel={(option) => option?.name || ''}
          getOptionSelected={(option, value) => option.id === value.id}
          noOptionsText={'No Engagement Names'}
          size="small"
          value={filterState.engagement}
          onChange={(e: any, optionData: any, reason) => {
            let val = optionData;
            if (reason === 'clear') {
              val = null
            }
            applyFilter({
              value: val,
              filterName: 'engagement',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Engagement Name"
          color= 'primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item className={textInput}>
        <EBAutoComplete
          freeSolo={true}
          forcePopupIcon={true}
          options={Array.from(
            new Set(rows.map((item) => item?.dataIngestion))
          )}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Ingestion Names'}
          size="small"
          value={filterState.ingestion}
          onChange={(e: any, optionData: any, reason) => {
            let val = optionData
            if (reason === 'clear') {
              val = null
            }
            applyFilter({
              value: val,
              filterName: 'ingestion',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Ingestion Name"
          color='primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item className={textInput}>
        <EBAutoComplete
          freeSolo={true}
          forcePopupIcon={true}
          options={Array.from(
            new Set(rows.map((item) => item))
          )}
          getOptionLabel={(option) => option?.name || ''}
          noOptionsText={'No Names Available'}
          size="small"
          onChange={(e: any, optionData: any, reason) => {
            let val = optionData
            if (reason === 'clear') {
              val = {name: ""}
            }
            applyFilter({
              value: val.name,
              filterName: 'name',
            })
          }}
          hiddenLabel={true}
          placeholder="Select Operation Name"
          color='primary'
          backgroundColor='#fff'
        />
      </Grid>

      <Grid item className={statusFilter}>
        <Select
          selectProps={{
            defaultValue: 'all',
            value: filterState.status,
            style: {
              height: '40px',
              backgroundColor: 'white',
              borderRadius: '8px',
            },
          }}
          placeholder="Please Select Status"
          value={filterState.status}
          items={statusItems}
          onChangeHandler={(value) =>
            applyFilter({
              value,
              filterName: 'status',
            })
          }
        />
      </Grid>
    
      <Grid item>
        <button
          className={refreshIcon}
          disabled={loading}
          onClick={async () => {
            await client.cache.reset();
            dispatch(getOperationLogs(client));
          }}
        >
          <RefreshIcon />
        </button>
      </Grid>
    </>
  )
}

export default AnalyticsFilters
