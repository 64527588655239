import { extractionList, extraction_status, erp_status } from "./typedefs";

export const INIT_EXTRACTIONS_STATE: extractionList = {
    loading : false,
    postAuthOptions: {},
    preAuthOptions: {},
    connectionPreAuthOptions: {},
    extractionItems: [],
    engagement: {
        id: '',
        name: '',
    },
    auditedEntity: {
        id: '',
        name: '',
        auditFirm: null,
    },
    erpCreate: {
        name: '',
        type: '',
    },
    extractionCreate: {
        name: '',
        connection: '',
    },
    erpEntities: [],
    clientConnections: [],
    clientExtractions: [],
    erps_types: [],
    erps: [],
    extractions: [],
    connectionWorkflowId: "",
    connectionWorkflowPreAuthData: {},
    connectionWorkflowSystemName: "",
    nangoIntegrationName: "",
    message: "",
    extractionCreationInProgress: false
}
