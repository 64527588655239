import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'

export const createSupportTicket = async (data, msal) => {
  const { accounts, inProgress, instance } = msal

  const { token } = await asyncTokenLookup({
    instance,
    inProgress,
    accounts,
    tokenRequest: {
      scopes: protectedResources.dataIngestionApi.scopes,
    },
  }).catch((err) => {
    console.error('Failed to lookup token. Reason: ', err)
    return err
  })

  const url = `${process.env.NX_REST_API_URL}/create-support-ticket`

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.NX_REST_API_URL,
    Authorization: `Bearer ${token}`,
  }
  return await fetch(url, {
    method: 'POST',
    headers,
    credentials: 'same-origin',
    mode: 'cors',
    redirect: 'follow',
    body: JSON.stringify(data),
  })
}
