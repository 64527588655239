import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReactComponent as IconClose } from '../../../assets/IconClose.svg'
import { format } from 'date-fns'
import { ReactComponent as IconInfo } from '../../../assets/IconInfo.svg'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  existingMapping: {
    '& .MuiDialog-paper': {
      width: '880px',
      height: '655px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: '15px',
      padding: '30px 30px 30px 35px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      '& > h2': {
        display: 'flex',
        justifyContent: 'space-between',
        font: 'normal normal bold 24px/40px Arial',
        letterSpacing: '0.24px',
        color: '#22353F',
      },
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      marginTop: '12px',
    },
    '& .MuiInputBase-root': {
      width: '510px',
      height: '52px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #D5DCE1',
      borderRadius: '8px',
      margin: 'auto',
    },
    '& .MuiDialogActions-root': {
      flexDirection: 'column',
      '& > .action-btns': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& > button': {
          height: '44px',
          minWidth: 0,
        },
        '& > button:first-child': {
          width: '132px',
          background: '#89A0AC 0% 0% no-repeat padding-box',
          color: '#fff',
        },
        '& > button:last-child': {
          width: '128px',
          marginLeft: '15px',
        },
      },
    },
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  makeCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    background: '#f8fafb',
    width: '780px',
    height: 'auto',
    padding: '15px',
    borderRadius: '8px',
    borderLeft: '8px solid #344B5F',
    margin: '24px 0 60px',
    '& .fn-right': {
      color: '#22353F',
      marginLeft: '10px',
      '& span': {
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0px',
      },
    },
  },
  inputSearch: {
    width: '324px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    '& .MuiOutlinedInput-root': {
      height: '44px',
    },
  },
}))

const ExistingMappingDialog = ({ open, onClose, savedMappings, loading }) => {
  const { existingMapping, loader, makeCenter, info, inputSearch } = useStyles()

  const [selectedValue, setSelectedValue] = useState('')
  const [savedMappingsData, setSavedMappingsData] = useState([])

  useEffect(() => {
    if (open && savedMappings?.length) {
      setSavedMappingsData(savedMappings)
    }
  }, [savedMappings, open])

  const getDate = (date: string) => {
    try {
      return format(new Date(date), 'dd/MM/yyyy')
    } catch (error) {
      return null
    }
  }

  const onSearchHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSavedMappingsData(
      savedMappings.filter((item) =>
        item.filename.toUpperCase().includes(e.target.value.toUpperCase())
      )
    )
  }

  return (
    <Dialog open={open} className={existingMapping} maxWidth={false}>
      <DialogTitle>
        <span>Existing Mapping</span>
        <IconButton onClick={() => onClose(false)}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <TextField
        variant="outlined"
        placeholder="Search"
        classes={{ root: inputSearch }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onSearchHandler}
      />
      <DialogContent>
        {!loading ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Saved Mappings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {savedMappingsData.map((sm, i) => (
                  <TableRow key={i}>
                    <TableCell size="small" style={{ width: '25%' }}>
                      {getDate(sm.created)}
                    </TableCell>
                    <TableCell size="small" className={makeCenter}>
                      <span>{sm.filename}</span>
                      <Radio
                        checked={selectedValue === sm.filepath}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        value={sm.filepath}
                        name="mapped-files"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className={loader}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div className={info}>
          <div className="fn-left">
            <IconInfo />
          </div>
          <div className="fn-right">
            <Typography color="inherit" style={{ whiteSpace: 'pre-wrap' }}>
              On apply, this will replace the current custom mappings with the
              saved mappings. Select Cancel to return to Custom mapper, no
              changes applied
            </Typography>
          </div>
        </div>
        <div className="action-btns">
          <Button variant="contained" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={selectedValue.length === 0}
            onClick={() => onClose(true, selectedValue)}
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ExistingMappingDialog
