import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql'
import { DocumentNode } from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  URL: any
  JSON: any
}

export type AuditFirm = {
  __typename?: 'AuditFirm'
  id: Scalars['ID']
  name: Scalars['String']
  systemName: Scalars['String']
}

export type AuditedEntity = {
  __typename?: 'AuditedEntity'
  auditFirm: AuditFirm
  id: Scalars['ID']
  name: Scalars['String']
}

export type CdmEntity = {
  __typename?: 'CdmEntity'
  erp: Erp
  extractType: ExtractType
  inputFiles?: Maybe<Array<InputFile>>
  name: Scalars['ID']
  systemName: Scalars['String']
}

export type CdmEntityRelationship = {
  __typename?: 'CdmEntityRelationship'
  cdmEntity: CdmEntity
}

export type Container = {
  __typename?: 'Container'
  errorsPath: Scalars['String']
  fileSystemId: Scalars['ID']
  inputPath: Scalars['String']
  sharePath: Scalars['String']
  incomingPath: Scalars['String']
  storageAccountURL: Scalars['URL']
}

export type Engagement = {
  id: Scalars['String']
  name: Scalars['String']
  auditedEntity: AuditedEntity
  auditedEntityId: Scalars['String']
}

export type DataIngestion = {
  __typename?: 'DataIngestion'
  adfPipelineId?: Maybe<Scalars['String']>
  auditedEntity: AuditedEntity
  cdmEntities?: Maybe<Array<CdmEntityRelationship>>
  clientCode?: Maybe<Scalars['String']>
  completeAt?: Maybe<Scalars['DateTime']>
  container: Container
  createdBy?: Maybe<Scalars['String']>
  dataEndDate?: Maybe<Scalars['DateTime']>
  dataStartDate?: Maybe<Scalars['DateTime']>
  erp: Erp
  extractType: ExtractType
  id: Scalars['ID']
  initiatedAt?: Maybe<Scalars['DateTime']>
  status: DataIngestionStatus
  uploadedFiles?: Maybe<Array<Maybe<UploadedFile>>>
  isCustom: Scalars['Boolean']
  groupName: Maybe<Scalars['String']>
  groupVersion: Maybe<Scalars['String']>
  groupLastUpdatedBy: Maybe<Scalars['String']>
  groupId: Maybe<Scalars['String']>
  name: Scalars['String']
  engagement: Engagement
  preloaderInfo?: Maybe<Scalars['JSON']>
}

export type DataIngestionResponse = {
  __typename?: 'DataIngestionResponse'
  data: Maybe<Array<DataIngestion>>
  total: Scalars['Int']
}

export type IAttributesType = {
  dataType: string,
  name: string,
  __typename: string,
  entity: {
    displayName: string,
    name: string,
    __typename: string
  }
}

export type IBundleFunctionsType = {
  status: any
  id: any
  function: {
    id: string
    name: string
    __typename: string
  }
  functionId: string,
  order: string,
  params: string,
  source: string,
  __typename: string
}

export type IBundlesType = {
  id: string,
  name: string,
  __typename: string
  bundleFunctions: Maybe<Array<IBundleFunctionsType>>
  entity: {
    displayName: string
    name: string
    id: string
    __typename: string
  }
}

export type IOperationLogsType = {
  bundle: IBundlesType
  bundleId: string,
  completeAt: string | null,
  createdBy: string,
  dataIngestion: DataIngestion
  dataIngestionId: string
  id: string
  initiatedAt: string
  name: string
  path: string
  status: string
  userId: string
  __typename: string
}

export type IAnalyticsPipelineType = {
  id: string
  name: string
  engagement: Engagement
  createdBy: string,
  createdAt: string | null,
  updatedBy: string | null,
  updatedAt: string | null,
  status: string
  __typename: string
}

export type IFunctionsType = {
  id: string,
  name: string,
  __typename: string
}

export enum DataIngestionStatus {
  Completed = 'COMPLETED',
  Outdated = 'OUTDATED',
  CompletedWithErrors = 'COMPLETED_WITH_ERRORS',
  Failed = 'FAILED',
  Initialized = 'INITIALIZED',
  InProgress = 'IN_PROGRESS',
  UploadingFiles = 'UPLOADING_FILES',
}

export type Erp = {
  __typename?: 'Erp'
  id: Scalars['ID']
  name: Scalars['String']
  pipelineId?: Scalars['String']
  standard: Scalars['Boolean']
  auditFirm?: AuditFirm
  extractType?: [ExtractType]
  isActive?: Scalars['Boolean']
  inProgress?: Scalars['Boolean']
  erpType?: 'STANDARD' | 'CUSTOM' | 'INDUSTRY' | 'INACTIVE' | 'FIRM_STANDARD'
}

export type preUploadFiles = {
  __typename?: 'preUploadFiles'
  name: Scalars['String']
  lastModified: Scalars['DateTime']
  contentLength: Scalars['Int']
}

export type preUploadJoinFiles = {
  __typename?: 'preUploadJoinFiles'
  name: Scalars['String']
  lastModified: Scalars['DateTime']
  contentLength: Scalars['Int']
}

export type preUploadExtractFiles = {
  __typename?: 'preUploadExtractFiles'
  name: Scalars['String']
  lastModified: Scalars['DateTime']
  contentLength: Scalars['Int']
}

export type Grouping = {
  __typename?: 'Grouping'
  id: Scalars['ID']
  name: Scalars['String']
  version: Scalars['String']
  lastUpdatedBy: Scalars['String']
  complete: Scalars['Boolean']
}

export enum ExtractType {
  Report = 'REPORT',
  System = 'SYSTEM',
}

export type InputFile = {
  __typename?: 'InputFile'
  id: Scalars['ID']
  name: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  addUploadedFile: UploadedFile
  beginDataIngestion: DataIngestion
  createDataIngestion: DataIngestion
  updateDataIngestionCdmEntities: DataIngestion
  updateDataIngestionStatus: DataIngestion
  updateDataIngestionUploadedFiles: DataIngestion
}

export type MutationAddUploadedFileArgs = {
  dataIngestionId: Scalars['String']
  fileNameByUser: Scalars['String']
  name: Scalars['String']
  size: Scalars['String']
  status: DataIngestionStatus
}

export type MutationBeginDataIngestionArgs = {
  dataIngestionId: Scalars['String']
  isCustomMapped: Scalars['Boolean']
}

export type MutationCreateDataIngestionArgs = {
  auditedEntityName: Scalars['String']
  clientCode?: InputMaybe<Scalars['String']>
  dataEndDate?: InputMaybe<Scalars['DateTime']>
  dataStartDate?: InputMaybe<Scalars['DateTime']>
  erpId: Scalars['String']
  extractType: ExtractType
}

export type MutationUpdateDataIngestionCdmEntitiesArgs = {
  cdmEntityErpId: Scalars['String']
  cdmEntitySystemNames: Array<Scalars['String']>
  dataIngestionId: Scalars['String']
  extractType: ExtractType
}

export type MutationUpdateDataIngestionStatusArgs = {
  dataIngestionId?: Scalars['String']
  status: DataIngestionStatus
  additionalDetailValues?: InputMaybe<Scalars['JSON']>
  runId?: Scalars['String']
}

export type MutationUpdateDataIngestionUploadedFilesArgs = {
  dataIngestionId: Scalars['String']
  uploadedFiles: Array<UploadedFileInput>
}

export type Query = {
  __typename?: 'Query'
  cdmEntities?: Maybe<Array<CdmEntity>>
  dataIngestions?: Maybe<DataIngestionResponse>
  erps?: Maybe<Array<Erp>>
  attributes?: Maybe<Array<IAttributesType>>
  getOperationLogs: Maybe<Array<IOperationLogsType>>
  getFunctions: Maybe<Array<IFunctionsType>>
  getBundles: Maybe<Array<IBundlesType>>
  getAnalyticsPipeline?: Maybe<IAnalyticsPipelineType>
}

export type QueryCdmEntitiesArgs = {
  erpId: Scalars['String']
  extractType: ExtractType
}

export type UploadedFile = {
  __typename?: 'UploadedFile'
  fileNameByUser?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inputFileId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  size: Scalars['String']
  status: DataIngestionStatus
}

export type UploadedFileInput = {
  fileNameByUser: Scalars['String']
  inputFileId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  size: Scalars['String']
  status: Scalars['String']
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
  > {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
  > =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
  > =
  | ((
    ...args: any[]
  ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
  > = (
    next: NextResolverFn<TResult>,
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
  ) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AuditFirm: ResolverTypeWrapper<AuditFirm>
  AuditedEntity: ResolverTypeWrapper<AuditedEntity>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  CdmEntity: ResolverTypeWrapper<CdmEntity>
  CdmEntityRelationship: ResolverTypeWrapper<CdmEntityRelationship>
  Container: ResolverTypeWrapper<Container>
  DataIngestion: ResolverTypeWrapper<DataIngestion>
  DataIngestionStatus: DataIngestionStatus
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  Erp: ResolverTypeWrapper<Erp>
  ExtractType: ExtractType
  ID: ResolverTypeWrapper<Scalars['ID']>
  InputFile: ResolverTypeWrapper<InputFile>
  Mutation: ResolverTypeWrapper<{}>
  Query: ResolverTypeWrapper<{}>
  String: ResolverTypeWrapper<Scalars['String']>
  URL: ResolverTypeWrapper<Scalars['URL']>
  UploadedFile: ResolverTypeWrapper<UploadedFile>
  UploadedFileInput: UploadedFileInput
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AuditFirm: AuditFirm
  AuditedEntity: AuditedEntity
  Boolean: Scalars['Boolean']
  CdmEntity: CdmEntity
  CdmEntityRelationship: CdmEntityRelationship
  Container: Container
  DataIngestion: DataIngestion
  DateTime: Scalars['DateTime']
  Erp: Erp
  ID: Scalars['ID']
  InputFile: InputFile
  Mutation: {}
  Query: {}
  String: Scalars['String']
  URL: Scalars['URL']
  UploadedFile: UploadedFile
  UploadedFileInput: UploadedFileInput
}

export type AuditFirmResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuditFirm'] = ResolversParentTypes['AuditFirm']
  > = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type AuditedEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuditedEntity'] = ResolversParentTypes['AuditedEntity']
  > = {
    auditFirm?: Resolver<ResolversTypes['AuditFirm'], ParentType, ContextType>
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type CdmEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CdmEntity'] = ResolversParentTypes['CdmEntity']
  > = {
    erp?: Resolver<ResolversTypes['Erp'], ParentType, ContextType>
    extractType?: Resolver<ResolversTypes['ExtractType'], ParentType, ContextType>
    inputFiles?: Resolver<
      Maybe<Array<ResolversTypes['InputFile']>>,
      ParentType,
      ContextType
    >
    name?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    systemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type CdmEntityRelationshipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CdmEntityRelationship'] = ResolversParentTypes['CdmEntityRelationship']
  > = {
    cdmEntity?: Resolver<ResolversTypes['CdmEntity'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type ContainerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Container'] = ResolversParentTypes['Container']
  > = {
    errorsPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    fileSystemId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    inputPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    incomingPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    sharePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    storageAccountURL?: Resolver<ResolversTypes['URL'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type DataIngestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DataIngestion'] = ResolversParentTypes['DataIngestion']
  > = {
    adfPipelineId?: Resolver<
      Maybe<ResolversTypes['String']>,
      ParentType,
      ContextType
    >
    auditedEntity?: Resolver<
      ResolversTypes['AuditedEntity'],
      ParentType,
      ContextType
    >
    cdmEntities?: Resolver<
      Maybe<Array<ResolversTypes['CdmEntityRelationship']>>,
      ParentType,
      ContextType
    >
    clientCode?: Resolver<
      Maybe<ResolversTypes['String']>,
      ParentType,
      ContextType
    >
    completeAt?: Resolver<
      Maybe<ResolversTypes['DateTime']>,
      ParentType,
      ContextType
    >
    container?: Resolver<ResolversTypes['Container'], ParentType, ContextType>
    createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
    dataEndDate?: Resolver<
      Maybe<ResolversTypes['DateTime']>,
      ParentType,
      ContextType
    >
    dataStartDate?: Resolver<
      Maybe<ResolversTypes['DateTime']>,
      ParentType,
      ContextType
    >
    erp?: Resolver<ResolversTypes['Erp'], ParentType, ContextType>
    extractType?: Resolver<ResolversTypes['ExtractType'], ParentType, ContextType>
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    initiatedAt?: Resolver<
      Maybe<ResolversTypes['DateTime']>,
      ParentType,
      ContextType
    >
    status?: Resolver<
      ResolversTypes['DataIngestionStatus'],
      ParentType,
      ContextType
    >
    uploadedFiles?: Resolver<
      Maybe<Array<Maybe<ResolversTypes['UploadedFile']>>>,
      ParentType,
      ContextType
    >
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type ErpResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Erp'] = ResolversParentTypes['Erp']
  > = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type InputFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InputFile'] = ResolversParentTypes['InputFile']
  > = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
  > = {
    addUploadedFile?: Resolver<
      ResolversTypes['UploadedFile'],
      ParentType,
      ContextType,
      RequireFields<
        MutationAddUploadedFileArgs,
        'dataIngestionId' | 'fileNameByUser' | 'name' | 'size' | 'status'
      >
    >
    beginDataIngestion?: Resolver<
      ResolversTypes['DataIngestion'],
      ParentType,
      ContextType,
      RequireFields<
        MutationBeginDataIngestionArgs,
        'dataIngestionId' | 'isCustomMapped'
      >
    >
    createDataIngestion?: Resolver<
      ResolversTypes['DataIngestion'],
      ParentType,
      ContextType,
      RequireFields<
        MutationCreateDataIngestionArgs,
        'auditedEntityName' | 'erpId' | 'extractType'
      >
    >
    updateDataIngestionCdmEntities?: Resolver<
      ResolversTypes['DataIngestion'],
      ParentType,
      ContextType,
      RequireFields<
        MutationUpdateDataIngestionCdmEntitiesArgs,
        | 'cdmEntityErpId'
        | 'cdmEntitySystemNames'
        | 'dataIngestionId'
        | 'extractType'
      >
    >
    updateDataIngestionStatus?: Resolver<
      ResolversTypes['DataIngestion'],
      ParentType,
      ContextType,
      RequireFields<
        MutationUpdateDataIngestionStatusArgs,
        'dataIngestionId' | 'status' | 'additionalDetailValues' | 'runId'
      >
    >
    updateDataIngestionUploadedFiles?: Resolver<
      ResolversTypes['DataIngestion'],
      ParentType,
      ContextType,
      RequireFields<
        MutationUpdateDataIngestionUploadedFilesArgs,
        'dataIngestionId' | 'uploadedFiles'
      >
    >
  }

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
  > = {
    cdmEntities?: Resolver<
      Maybe<Array<ResolversTypes['CdmEntity']>>,
      ParentType,
      ContextType,
      RequireFields<QueryCdmEntitiesArgs, 'erpId' | 'extractType'>
    >
    dataIngestions?: Resolver<
      Maybe<Array<ResolversTypes['DataIngestion']>>,
      ParentType,
      ContextType
    >
    erps?: Resolver<Maybe<Array<ResolversTypes['Erp']>>, ParentType, ContextType>
  }

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL'
}

export type UploadedFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UploadedFile'] = ResolversParentTypes['UploadedFile']
  > = {
    fileNameByUser?: Resolver<
      Maybe<ResolversTypes['String']>,
      ParentType,
      ContextType
    >
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
    inputFileId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    size?: Resolver<ResolversTypes['String'], ParentType, ContextType>
    status?: Resolver<
      ResolversTypes['DataIngestionStatus'],
      ParentType,
      ContextType
    >
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
  }

export type Resolvers<ContextType = any> = {
  AuditFirm?: AuditFirmResolvers<ContextType>
  AuditedEntity?: AuditedEntityResolvers<ContextType>
  CdmEntity?: CdmEntityResolvers<ContextType>
  CdmEntityRelationship?: CdmEntityRelationshipResolvers<ContextType>
  Container?: ContainerResolvers<ContextType>
  DataIngestion?: DataIngestionResolvers<ContextType>
  DateTime?: GraphQLScalarType
  Erp?: ErpResolvers<ContextType>
  InputFile?: InputFileResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  URL?: GraphQLScalarType
  UploadedFile?: UploadedFileResolvers<ContextType>
}

export type Requester<C = {}> = <R, V>(
  doc: DocumentNode,
  vars?: V,
  options?: C
) => Promise<R>
export function getSdk<C>(requester: Requester<C>) {
  return {}
}
export type Sdk = ReturnType<typeof getSdk>
