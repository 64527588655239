import { GET_PRE_UPLOADED_EXTRACTION_FILES } from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApolloClient } from '@apollo/client'
import { preUploadExtractFiles } from '@engine-b/shared/types'

enum LOADING_STATE {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

interface copyP {
  status: String
  err: boolean
  message: String
  fileName: String
}

interface preUploadExtractFileCollections {
  extractionFiles: preUploadExtractFiles[]
  isLoading: LOADING_STATE
  copyPreUpload: copyP
  loading: LOADING_STATE
}

const INITIAL_STATE: preUploadExtractFileCollections = {
  extractionFiles: [],
  isLoading: LOADING_STATE.DONE,
  copyPreUpload: null,
  loading: LOADING_STATE.LOADING,
}

export const getPreUploadedExtractionFiles = createAsyncThunk(
  'preUploadedExtractionFiles/getPreUploadedExtractionFiles',
  async (payload: {client: ApolloClient<unknown>, auditedEntity, engagementId}) => {
    // Get the current auditedEntity 
    // and engagement from redux to pass to the backend
    // so that we can find the right files for the current
    // client and engagement
    const {auditedEntity, engagementId} = payload

    if (!auditedEntity || !engagementId) {
      throw new Error(
        'Error getting pre-uploaded files with unknown audited or engagement name'
      )
    }
    const response = await payload.client.query({
      query: GET_PRE_UPLOADED_EXTRACTION_FILES,
      variables: {
        clientName: auditedEntity,
        engagement: engagementId,
      },
    })
    return response
  }
)

export const preUploadedExtractionSlice =
  createPreUploadedExtractionSlice(INITIAL_STATE)

export function createPreUploadedExtractionSlice(
  initialState: preUploadExtractFileCollections
) {
  return createSlice({
    name: 'preUploadedExtractionFiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getPreUploadedExtractionFiles.pending, (state, _) => {
        state.isLoading = LOADING_STATE.LOADING
      })

      builder.addCase(
        getPreUploadedExtractionFiles.fulfilled,
        (state, action) => {
          const { preUploadedApiExtractionFiles } = action.payload.data
          state.extractionFiles = preUploadedApiExtractionFiles
          state.isLoading = LOADING_STATE.DONE
          return state
        }
      )

      builder.addCase(getPreUploadedExtractionFiles.rejected, (state) => {
        state.isLoading = LOADING_STATE.ERROR
      })
    },
  })
}

export const preUploadedExtractionReducer = preUploadedExtractionSlice.reducer
