import {
  IAuditAnalytics, IFunctionsFilters, IFunctions, LOADING, OPERATION_STATUS
} from './typedefs'

export const INIT_FILTERS: IFunctionsFilters = {
  filterColumn: {
    id: "",
    name: "",
  },
  filterCondition: {
    id: "",
    name: "",
  },
  input: ""
}
export const INIT_FUNCTIONS: IFunctions = {
  id: "",
  value: {
    id: "",
    name: "",
  },
  step: {
    id: "",
    name: "",
  },
  stepOptions: [],
  status: OPERATION_STATUS.INITIALIZED,
  filters: [INIT_FILTERS]
}

export const INIT_AUDIT_ANALYTICS: IAuditAnalytics = {
  name: "",
  selectedClientDetails: {
    id: null,
    name: "",
    auditFirm: {
      id: null,
      name: "",
      systemName: ""
    }
  },
  selectedEngagementDetails: {
    id: null,
    name: "",
  },
  selectedIngestionDetails: {
    id: null,
    name: '',
  },
  selectedFilePath: {
    id: null,
    name: '',
  },
  selectedBundleDetails: {
    id: null,
    name: '',
  },
  functions: [INIT_FUNCTIONS],
  isLoading: LOADING.LOADING,
  operationID: "",
  loading: false,
  operationLogs: [],
  functionsList: [],
  bundlesList: []
}
