import { gql } from '@apollo/client'

export const GET_AUDITED_ENTITIES_BY_USER = gql`
  query AuditedEntitiesByUser {
    auditedEntitiesByUser {
      id
      name
      auditFirm {
        id
        name
        systemName
      }
    }
  }
`
