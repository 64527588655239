import React, { useContext, useMemo, useRef, useState } from 'react'
import {
  Button,
  TextField,
  Grid,
  createStyles,
  makeStyles,
  Typography,
  FormControl,
} from '@material-ui/core'
import cuid from 'cuid'
import {
  addTable,
  useIEDispatch,
  useIESelector,
  tableNameListSelector,
  basicDetailsSelector,
  setVirtualTableColumns,
  uploadExcelFileData,
} from '@engine-b/integration-engine/data/state/redux'
import {
  AzureClientContext,
  uploadFileToAzureContainer,
} from '@engine-b/integration-engine/data/azure-data-factory'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useMsal } from '@azure/msal-react'
import BounceLoader from 'react-spinners/BounceLoader'

const useStyles = makeStyles((theme) =>
  createStyles({
    addTableContainer: {
      position: 'relative',
      alignItems: 'center',
      '& .MuiGrid-item': {
        marginRight: '8px',
        [theme.breakpoints.down('lg')]: {
          marginTop: '8px',
        },
      },
    },
    tableText: {
      '& .MuiInputBase-root': {
        borderRadius: '8px',
      },
    },
    addButton: {
      borderRadius: '8px',
      minWidth: 'unset',
    },
  })
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddTableProps {
  isLoading?: boolean
  setIsLoading: (value: boolean) => void
}
export function AddTable(props: Readonly<AddTableProps>) {
  const { isLoading, setIsLoading } = props
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const [tableName, setTableName] = useState<string>('')
  const user = useIESelector((state) => state.user)
  const azureClient = useContext(AzureClientContext)
  const { instance, inProgress, accounts } = useMsal()
  const tableNameList = useIESelector((state) => tableNameListSelector(state))
  const {
    auditedEntity: selectedClientDetails,
    engagement: selectedEngagementDetails,
    validName,
  } = useIESelector((state) => basicDetailsSelector(state))
  const { engagement, auditedEntity, tables } = useIESelector((state) => state.joins)
  const uniqTableNameList = tableNameList.map((tableName) => tableName.replace(/\s/g, ""))
  const isAddDisabled = useMemo(() => {
    return (
      !(tableName.replace(/\s/g, "").length > 0)  ||
      uniqTableNameList.includes(tableName.replace(/\s/g, "")) ||
      !selectedEngagementDetails.name ||
      !selectedClientDetails.id ||
      !validName
    )
  }, [
    validName,
    selectedEngagementDetails.name,
    selectedClientDetails.id,
    tableNameList,
    tableName,
  ])

  const handleTableNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTableName(event.target.value)
  }

  const handleAdd = () => {
    dispatch(addTable({ tableName }))
    setTableName('')
  }

  const cuid_generator = () => {
    return cuid()
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleFileUpload = async () => {
    setIsLoading(true)

    try {
      const file = fileInputRef.current?.files?.[0]
      const name = file?.name
      const cuId = cuid_generator()

      if (!file) {
        console.error('No file selected')
        return
      }
      const today = new Date()
      const dataAndTime = `${today.getFullYear()}${
        today.getMonth() + 1
      }${today.getDate()}_${today.getHours()}${today.getMinutes()}`
      const uploadPath = `incoming/${auditedEntity.name}/${engagement.name}/join-temp/${dataAndTime}_${cuId}_${name}`
      const response = await uploadFileToAzureContainer({
        azureClient,
        containerId: user.systemName,
        file,
        inputPath: uploadPath,
      })
      const url = `${user.systemName}/incoming/${auditedEntity.name}/${engagement.name}/join-temp/${dataAndTime}_${cuId}_${file.name}`
      if (response) {
        const { token } = await asyncTokenLookup({
          instance,
          inProgress,
          accounts,
          tokenRequest: protectedResources.dataIngestionApi,
        })

        const uploadFileResponse = await fetch(
          `${process.env['NX_CUSTOM_MAPPER_API_URL']}/files/get-excel-file-headers`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file_path: url,
            }),
          }
        )

        if (uploadFileResponse.ok) {
          const result = await uploadFileResponse.json()
          Object.keys(result).forEach((key) => {
            dispatch(addTable({ tableName: key }))
          })
          Object.keys(result).forEach((key) => {
            dispatch(
              setVirtualTableColumns({
                tableName: key,
                columns: result[key],
              })
            )
            dispatch(
              uploadExcelFileData({
                tableName: key,
                name: file.name,
                path: uploadPath,
                requestId: cuId,
                column: result[key],
              })
            )
          })
          setIsLoading(false)
        } else {
          console.error('Upload file request failed')
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
        console.error('File upload failed')
      }
    } catch (error) {
      console.error('An error occurred during file upload:', error)
      setIsLoading(false)
    }
  }
  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const disableUpload = useMemo(() => {
    let files: any[] = []
    Object.values(tables).forEach((table) => {
     const file = Object.values(table.files).filter(
        (file) => file.name.includes('.xlsx') || file.name.includes('.xls')
      )
      files = [...files, ...file]
    })
    return !!tableName || files?.length > 0
  }, [tableName, tables])

  return (
    <Grid container className={classes.addTableContainer}>
      <>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '200px',
              height: '200px',
            }}
          >
            <BounceLoader color={'#00A9AB'} size={40} />
          </div>
        )}
        {selectedEngagementDetails.name &&
          selectedClientDetails.id &&
          validName && (
            <>
              <Grid item>
                <Typography variant="body1">Add Virtual Table</Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  name="tableName"
                  size="small"
                  variant="outlined"
                  color="primary"
                  value={tableName}
                  className={classes.tableText}
                  onChange={(e) => {
                    handleTableNameChange(e)
                  }}
                  placeholder={'Enter Table Name'}
                  required
                  type="text"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={handleAdd}
                  disabled={isAddDisabled}
                >
                  Add Table
                </Button>
              </Grid>
              <Grid item lg={2}>
                <FormControl>
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    accept=".xlsx, .xls, application/vnd.ms-excel"
                    style={{ display: 'none' }}
                    onClick={(e: any) => (e.target.value = null)}
                    onChange={handleFileUpload}
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={handleUploadFile}
                    disabled={disableUpload}
                  >
                    Upload Excel File
                  </Button>
                </FormControl>
              </Grid>
            </>
          )}
      </>
    </Grid>
  )
}

export default AddTable
