/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloClient } from '@apollo/client'
import { GET_CDM_VERSION } from '@engine-b/integration-engine/data/data-ingestion-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const CUSTOM_MAPPER_FEATURE_KEY = 'customMapper'

type MapType = 'direct' | 'calculated' | 'date-mask' | 'dummy'

export interface MandatoryFieldValidation {
  engineb_attribute: string
  fieldName: string
  present: boolean
}

export interface ImperfectFilesSummary {
  name: string
  message: string
  valid: boolean
}

export interface CDMFields {
  order?: number
  id?: number
  field_name: string
  file_name: string
  null_check_comment?: string
  null_check_valid?: boolean
  type_check_comment?: string
  type_check_valid?: boolean
  consider_valid?: boolean
  valid?: boolean
}

export interface AvailablOptions {
  field_id: number
  field_name: string
  confidence_score: number
}

export interface CDMMapping {
  id: number
  data_type: string
  description: string
  erp_fields: Array<CDMFields>
  available_options: Array<AvailablOptions>
  mandatory: boolean
  map_type: string
  mapping_required: boolean
  name: string
  original_filename: string
  report_type: string
}

export interface CDMDetails {
  fields: Array<CDMFields>
  mapping: Array<CDMMapping>
}

export interface ImperfectFilesFields {
  data_type: string
  field_name: string
  valid: boolean
}

export interface FileDetails {
  erpId: string
  extractType: string
  reportType: string
  reportName: string
  cdmFileName: string
  inputFileName: string
  mandatoryFieldsData: Array<MandatoryFieldValidation>
  mandatoryFieldsValid: boolean
  imperfectFilesSummary: Array<ImperfectFilesSummary>
  imperfectFilesFields: Array<ImperfectFilesFields>
  imperfectFilesValid: boolean
  file: string
  cdmDetails: CDMDetails
  error?: string
  records: Array<any>
  header_row: string
}

export interface RangeBased {
  start: string
  end: string
  negatives: 'Credit' | 'Debit'
}

export interface ExtractSplitFields {
  opening_char: string
  closing_char: string
  inside_outside: string
  opening_char_number?: number
  closing_char_number?: number
}

export interface RemappingUnit {
  map_type: MapType
  cdm_field: string
  erp_fields: Array<CDMFields>
  file_name: string
  operation?: string
  extra?: string
  characters?: string
  date_format?: string
  date_format_text?: string
  time_format?: string
  time_format_text?: string
  report_type: string
  data_type: string
  mandatory: boolean
  erp_Id: string
  original_filename: string
  extract_type: string
  rangeDetails?: Array<RangeBased>
  sign_fields?: any
  extract_split_fields?: ExtractSplitFields
  separator?: string
  free_text?: string
  expression?: string
  gen_unique_id_col?: string
  split_side?: string
}

export interface CustomMapperState {
  fetching: boolean
  files: Array<FileDetails>
  customMapperVisible: boolean
  customFileMappings: object
  manualSelections: Array<RemappingUnit>
  is_header_row_changed: boolean
  remappingFile: string
  currentStep: number
  userLeaveDialog: boolean
  customMappingFileName: string
  cdmVersion: string
  unique_ids: RemappingUnit[]
}

export const INITIAL_CUSTOM_MAPPER_STATE: CustomMapperState = {
  fetching: false,
  files: [],
  customMapperVisible: false,
  customFileMappings: {},
  manualSelections: [],
  is_header_row_changed: false,
  remappingFile: '',
  currentStep: 0,
  userLeaveDialog: false,
  customMappingFileName: '',
  cdmVersion: '',
  unique_ids: [],
}

export interface CustomMapperRequest {
  erpId: string
  extract: string
  report: string
  fileName: string
  file: File
}

export const getCdmVersion = createAsyncThunk(
  'custom-mapper/getCdmVersion',
  async (client: ApolloClient<unknown>) => {
    const response = await client.query({
      query: GET_CDM_VERSION,
      fetchPolicy: 'no-cache',
    })
    return response
  }
)

export const createCustomMapperSlice = (_initialState: CustomMapperState) => {
  return createSlice({
    name: CUSTOM_MAPPER_FEATURE_KEY,
    initialState: INITIAL_CUSTOM_MAPPER_STATE,
    reducers: {
      fetchStart: (state, _) => {
        state.fetching = true
      },
      fetchStop: (state, _) => {
        state.fetching = false
      },
      saveFileInfo: (state, action) => {
        state.files.push(action.payload)
        state.fetching = !state.fetching
      },
      updateFileInfo: (state, { payload }) => {
        const index = state.files.findIndex(
          (item) => item.cdmFileName === payload.cdmFileName
        )
        state.files.splice(index, 1, payload)
        state.fetching = !state.fetching
      },
      clearValidation: (state, _) => {
        state.files = []
      },
      deleteFileValidation: (state, action) => {
        const { extractType, reportType } = action.payload
        state.files = [
          ...state.files.filter(
            (fl) =>
              !(fl.extractType === extractType && fl.reportType === reportType)
          ),
        ]
      },
      toggleCustomMapperVisibility: (state, { payload }) => {
        state.customMapperVisible = payload
      },
      setCustomFileMappings: (state, { payload }) => {
        state.customFileMappings = payload
      },
      setRemappingFile: (state, { payload }) => {
        state.remappingFile = payload
      },
      clearRemappingFile: (state) => {
        state.remappingFile = ''
      },
      setCurrentStep: (state, { payload }) => {
        state.currentStep = payload
      },
      setUserLeaveDialog: (state, { payload }) => {
        state.userLeaveDialog = payload
      },
      setCustomMappingFileName: (state, { payload }) => {
        state.customMappingFileName = payload
      },
      // Reset manual selections to default
      resetManualSelections: (state, { payload }) => {
        const copy = state.manualSelections.filter(
          ({ report_type }) => payload[0].report_type !== report_type
        )
        state.manualSelections = [...copy, ...payload]
      },
      // Replace hole manual selections
      updateManualSelections: (state, { payload }) => {
        state.manualSelections = payload
      },
      clearSingleFileManualSelection: (state, { payload }) => {
        state.manualSelections = payload
        state.is_header_row_changed = true
        state.remappingFile = ""
      },
      // empty the manual selections
      clearManualSelections: (state) => {
        state.manualSelections = []
      },
      // Remove Manual selections of given report type
      removeManualSelections: (state, { payload }) => {
        state.manualSelections = state.manualSelections.filter(
          (cs) => cs.report_type != payload
        )
      },
      // update single manual selection object
      updateSingleManualSelection: (state, { payload }) => {
        const index = state.manualSelections.findIndex(
          (item) =>
            item.cdm_field === payload.cdm_field &&
            item.report_type === payload.report_type
        )
        state.manualSelections.splice(index, 1, payload)
      },
      addUniqueIdColumn: (state, { payload }) => {
        const index = state.unique_ids.findIndex(
          (item) => item.report_type === payload.report_type
        )
        if (index > -1) {
          state.unique_ids[index].gen_unique_id_col = payload.gen_unique_id_col
        } else {
          state.unique_ids.push(payload)
        }
      },
      removeUniqueIdColumn: (state, { payload }) => {
        const index = state.unique_ids.findIndex(
          (item) => item.report_type === payload
        )
        state.unique_ids.splice(index, 1)
      },
      resetUniqueIds: (state) => {
        state.unique_ids = []
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getCdmVersion.pending, (state) => {
        state.cdmVersion = ''
      })
      builder.addCase(getCdmVersion.fulfilled, (state, { payload }) => {
        state.cdmVersion = payload.data.cdmVersion
      })
      builder.addCase(getCdmVersion.rejected, (state) => {
        state.cdmVersion = ''
      })
    },
  })
}

export const customMapperSlice = createCustomMapperSlice(
  INITIAL_CUSTOM_MAPPER_STATE
)

// Action creators are generated for each case reducer function
export const {
  fetchStart,
  fetchStop,
  saveFileInfo,
  updateFileInfo,
  clearValidation,
  deleteFileValidation,
  toggleCustomMapperVisibility,
  setCustomFileMappings,
  setRemappingFile,
  clearRemappingFile,
  setCurrentStep,
  setUserLeaveDialog,
  setCustomMappingFileName,
  resetManualSelections,
  updateManualSelections,
  clearManualSelections,
  clearSingleFileManualSelection,
  removeManualSelections,
  updateSingleManualSelection,
  addUniqueIdColumn,
  removeUniqueIdColumn,
  resetUniqueIds,
} = customMapperSlice.actions

export const customMapperReducer = customMapperSlice.reducer
