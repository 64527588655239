import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import icBrosweFile from './assets/ic_browse_file.svg'

/* eslint-disable-next-line */
export interface DragAndDropFileProps {
  fileMapping: {
    cdmFileName: String
    fileNameByUser: String
    originalFileName: String
    reportType: String
    size: Number
    state: String
    uploaded: Number
  }
  uploadFile: Function
}

const useStyles = makeStyles((theme) =>
  createStyles({
    stateContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F8FAFB 0% 0% no-repeat padding-box',
      border: '2px dashed #CCD8DE',
      borderRadius: 10,
      width: '100%',
      padding: '28px 30px',
    },
    helpingFor: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0.14,
      color: '#22353F',
      fontSize: 12,
      padding: 0,
      marginTop: 8,
    },
    fileName: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0,
      color: '#00B2A9',
      fontSize: 14,
      padding: 0,
      margin: 0,
      ['@media (max-width:1300px)']: {
        fontSize: 10,
      },
    },
    dragAndDrop: {
      textAlign: 'left',
      font: 'normal normal normal Arial',
      letterSpacing: 0.14,
      color: '#22353F',
      fontSize: 18,
      padding: 0,
      marginTop: 8,
      ['@media (max-width:1300px)']: {
        fontSize: 12,
      },
    },
    documentIcon: {
      minWidth: 38,
      height: 44,
      marginRight: 20,
      backgroundImage: `url(${icBrosweFile})`,
      backgroundSize: 'cover',
      ['@media (max-width:1300px)']: {
        width: 34,
        height: 38,
      },
    },
    messageWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '90%',
    },
    messageContainer: {
      width: '50%',
    },
    uploadFile: {
      font: 'normal normal medium 18px/28px Roboto',
      letterSpacing: 0.18,
      color: '#00B2A9',
      cursor: 'pointer',
      fontSize: 14,
      ['@media (max-width:1300px)']: {
        fontSize: 10,
      },
    },
    selectButton: {
      background: '#00B2A9 0% 0% no-repeat padding-box',
      borderRadius: 8,
      textAlign: 'center',
      font: 'normal normal medium 16px/22px Roboto',
      letterSpacing: 0,
      color: '#FFFFFF',
      height: 41,
      border: 0,
      marginLeft: 10,
      width: 130,
      textTransform:'capitalize',
      ['@media (max-width:1300px)']: {
        fontSize: 10,
        width: 100,
      },
    },
    orText: {
      textAlign: 'left',
      letterSpacing: 0.16,
      color: '#22353F',
      fontSize: 14,
      fontWeight: 'bold',
      ['@media (max-width:1300px)']: {
        fontSize: 10,
      },
    },
    preSelect: {
      whiteSpace: 'nowrap',
    },
  })
)

export function DragAndDropFile({ fileMapping, uploadFile }: any) {
  const classes = useStyles()
  return (
    <div
      className={classes.stateContainer}
      onDragLeave={(e) => {
        e.preventDefault()
      }}
      onDragOver={(e) => {
        e.preventDefault()
      }}
      onDrop={(e) => {
        e.preventDefault()
        uploadFile(e.dataTransfer.files)
      }}
    >
      <div className={classes.documentIcon} />
      <div className={classes.messageWrapper}>
        <div className={classes.messageContainer}>
          <p className={classes.fileName}>{fileMapping.originalFileName}</p>
          <p className={classes.helpingFor}>
            Drag & drop your files here or &nbsp;
            <input
              type="file"
              id="file"
              onChange={(e) => uploadFile(e.currentTarget.files)}
              style={{ display: 'none' }}
            />
            <label htmlFor="file" className={classes.uploadFile}>
              Choose File
            </label>
          </p>
        </div>
        <p className={classes.orText}>OR</p>
        <button className={classes.selectButton}>
          Select <span className={classes.preSelect}>Pre-uploaded</span> File
        </button>
      </div>
    </div>
  )
}

export default DragAndDropFile
