import { useMsal } from '@azure/msal-react'
import React, { useEffect, useRef, useState } from 'react'
import { protectedResources } from '../authConfig'
import { InteractionStatus } from '@azure/msal-browser'
import { AzureClientProvider } from '@engine-b/integration-engine/data/azure-data-factory'
import { StaticTokenCredential } from './StaticTokenCredential'
import { AccessToken } from '@azure/core-http'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import BounceLoader from 'react-spinners/BounceLoader'
import { Grid } from '@material-ui/core'
import { useApolloClient } from '@apollo/client'
import { GET_USER_DETAILS } from '@engine-b/integration-engine/data/data-ingestion-api'
import { useIEDispatch, setUserDetails } from '@engine-b/integration-engine/data/state/redux'

const convertMinutestoMS = (x) => x * 60 * 1000
const refreshTokenTimeout = convertMinutestoMS(10)

export function AzureClientProviderWithAuth({ children }) {
  const { instance, accounts, inProgress } = useMsal()
  const [hasToken, setHasToken] = useState(false)
  const [userFound, setUserFound] = useState(false)
  const [refreshToken, setRefreshToken] = useState(false)
  const clientRef = useRef(null)
  const client = useApolloClient()
  const dispatch = useIEDispatch()

  const logout = () => {
    client.resetStore()
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const getUserDetails = async () => {
    try {
      const { errors, data } = await client.query({
        query: GET_USER_DETAILS,
      })
      if (errors) {
        logout()
        return
      }
      if(data) {
        dispatch(setUserDetails(data.getUserDetails))
      }
      setUserFound(true)
    } catch (error) {
      logout()
    }
  }

  const setClientRef = (accessToken: AccessToken) => {
    clientRef.current = new DataLakeServiceClient(
      `https://${process.env.NX_ADF_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`,
      new StaticTokenCredential({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.storageAccount,
      })
    )
    setHasToken(true)
  }

  useEffect(() => {
    setInterval(async () => {
      setRefreshToken(!refreshToken)
    }, refreshTokenTimeout)
    if (
      !hasToken &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.Cypress) {
        const authToken = sessionStorage.getItem('storageAccount')
        if (authToken) {
          const accessToken: AccessToken = {
            token: authToken,
            expiresOnTimestamp: Number(
              sessionStorage.getItem('storageAccountExpiresIn')
            ),
          }
          setClientRef(accessToken)
        }
      } else {
        /* This is to be removed once the fix is confirmed to be working - other refactoring will be needed as well.*/
        instance
          .acquireTokenSilent({
            ...protectedResources.storageAccount,
            account: accounts[0],
          })
          .then((response) => {
            if (response.accessToken) {
              const accessToken: AccessToken = {
                token: response.accessToken,
                expiresOnTimestamp: response.expiresOn.getUTCMilliseconds(),
              }
              setClientRef(accessToken)
            } else {
              console.error('Unable to acquire Token.')
            }
          })
        /* This is to be removed once the fix is confirmed to be working */
      }
    } else {
      getUserDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, inProgress, hasToken, refreshToken])

  if (
    hasToken === false ||
    inProgress === InteractionStatus.AcquireToken ||
    !userFound
  ) {
    return (
      <Grid container alignItems="center" justify="center">
        <Grid item style={{ marginTop: '80px' }}>
          <BounceLoader color={'#00A9AB'} size={20} />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <AzureClientProvider value={clientRef.current}>
        {children}
      </AzureClientProvider>
    )
  }
}
