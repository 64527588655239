import React, { useState, useEffect } from 'react'
import { Grid, Typography, makeStyles, Select, Box, Chip, MenuItem, Checkbox, InputLabel, FormHelperText, FormControl, ListItemText, Button } from '@material-ui/core'
import { EBInput, EBAutoComplete, EBButton } from '@engine-b/shared/components'
import { NotFoundView } from '../../views/NotFoundView/NotFoundView'
import {
  NEW_UI_INPUT_WIDTH,
  INPUT_WIDTH,
  Description
} from '@engine-b/integration-engine/ui/form-components'
import {
  createERPExtraction,
  useIESelector,
  useIEDispatch,
  getClientConnections,
  getPreAuthOptions,
  resetExtractionSettings,
  getErpEntities,
  createExtraction,
  resetCreationForms
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { JsonForms } from '@jsonforms/react';
import { vanillaRenderers, vanillaCells } from '@jsonforms/vanilla-renderers'
import { 
  DateTimePickerControl, 
  DatePickerControl, 
  DateTimePickerTester, 
  DatePickerTester
} from '../../components/jsonForms'
import { createDefaultAjv } from 'apps/integration-engine-temporal/api-extraction/src/defaultAJV'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 80px)',
    maxWidth: 'calc(100vw - 280px)',
    padding: '30px',
    background: `#F0FBFA 0% 0% no-repeat padding-box`,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
    '& .MuiTypography-h6': {
      marginBottom: '0px'
    },
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      rowGap: '16px',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      '& > label': { width: '100px' },
    },
    '& .status-text': {
      width: '292px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .MuiTextField-root': {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'hsla(205, 34%, 34%, 1)',
      },
      width: NEW_UI_INPUT_WIDTH + 'px',
      borderRadius: '8px',
      '& .MuiAutocomplete-inputRoot': {
        height: '44px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.5px solid ' + theme.palette.secondary.light,
        borderRadius: '8px',
      },
    },
  },
  dialogContent: {
    paddingTop: '20px',
  },
  dialogClosebutton: {
    minWidth: "auto"
  },
  formsError: {
    textTransform: 'capitalize'
  },
  DateIcon: {
    position: 'absolute',
    right: '2.5px',
    top: '8.5px',
    cursor: 'pointer',
    fill: theme.palette.secondary.main,
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    lineHeight: '37px',
    letterSpacing: '0.3px',
    color: '#22353F',
    opacity: 1,
  },
  jsonFormsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "left",
    alignItems: "flex-start !important",
  },
  jsonFormsContainerTypography: {
    marginTop: '16px',
    marginBottom: '0',
  },
  DatePicker: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '8px',
    cursor: 'pointer',
    border: '0.5px solid ' + theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    width: NEW_UI_INPUT_WIDTH + 'px',
    height: '44px',

    '& .react-date-picker--open': {
      borderBottom: '2px solid ' + theme.palette.secondary.light,
    },

    '& .react-date-picker__inputGroup': {
      cursor: 'pointer',
      minWidth: `${INPUT_WIDTH}px`,
    },

    '& .react-date-picker__calendar': {
      width: '230px',
      '& .react-calendar__month-view__days': {
        height: '210px',
      },
    },
    '& .react-date-picker__wrapper': {
      border: 'none',
      padding: '10.5px 14px',
    },
    '& .react-date-picker__inputGroup__input:focus-visible': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:focus': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:active': {
      border: '1px solid ' + theme.palette.secondary.light,
    },
    '& .react-date-picker__inputGroup__input:invalid': {
      backgroundColor: 'transparent',
    },
    '& .react-calendar__tile': {
      borderRadius: '20px',
    },
    '& .react-calendar__tile--active': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-calendar__tile--active:enabled:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .react-calendar__tile--now': {
      backgroundColor: theme.background.secondary.active,
    },
    '& .react-calendar__tile--now:enabled:hover': {
      backgroundColor: theme.background.secondary.active,
    },
    '& .react-date-picker__inputGroup__input::focus': {
      border: 'none',
    },
  },
}))

const ManageExtractionsView = () => {
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const ajvInstance = createDefaultAjv()
  const [connectionStatus, setConnectionStatus] = useState("DISCONNECTED");
  const [errorMessage, setErrorMessage] = useState("");
  const [validation, setValidation] = useState({
    valid: false,
    message: ""
  });
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [erpConnectionId, setErpConnectionId] = useState("")
  const [selectedEntities, setSelectedentities] = useState([])
  const [configData, setConfigData] = useState({})
  const [preAuthOptions, setPreAuthOptions] = useState({})
  const [configErrors, setConfigErrors] = useState([])
  const [name, setName] = useState("");
  const [connection, setConnection] = useState("");

  const client = useApolloClient()
  const extractions = useIESelector((state) => state.extractions)
  let history = useHistory();
  const { formatMessage, locale } = useIntl()

  // Clears validation messages / errors when rendering a new form;
  useEffect(() => {
    setValidation({
      valid: false,
      message: ""
    });
  }, [])

  useEffect(() => {
    setConfigData({})
    setSelectedentities([]);
    setConfigErrors([])
  }, [erpConnectionId])

  useEffect(() => {
    setPreAuthOptions(extractions.preAuthOptions)
  }, [extractions.preAuthOptions])

  useEffect(() => {
    if(extractions.engagement.id) {
      dispatch(getClientConnections({client, engagement: extractions.engagement.id}))
    }
  }, [extractions.engagement])

  const extractionNameCreate = (e) => {
    setName(e.target.value)
    dispatch(createExtraction({name: e.target.value, connection: connection }))
  }

  const updateConnection = (connection, reason) => {
    if (reason == 'clear') {
      setErpConnectionId("");
    } else {
      setErpConnectionId(connection.id)
      setConnection(connection.name)
      dispatch(getPreAuthOptions({client, connectionERP: connection.system}))
      dispatch(getErpEntities({client, system: connection.system}))
      dispatch(createExtraction({name: name, connection: connection.name }))
      dispatch(resetCreationForms({client}))
    }
  }

  const addExtraction = async () => {
    if(
        name.length > 0 && 
        erpConnectionId.length && 
        selectedEntities.length > 0
      ) {
      await dispatch(createERPExtraction({client, variables: {
          name: name,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          erpConnectionId: erpConnectionId,
          auditFirm: extractions.auditedEntity.auditFirm.systemName,
          clientName: extractions.auditedEntity.name,
          engagementName: extractions.engagement.name,
          selectedEntities: selectedEntities,
          preAuthConfigValues: configData
        }}
      ))
      .then(() => {
        setConfigData({})
        resetState()
        history.push(localizeRouteKey(formatMessage, locale, AppRoute.ExtractionsSettings))
      })
    } else {
      setErrorMessage("Please fill out the required fields.")
    }
  }  
  
  useEffect(() => {
    if (!extractions.auditedEntity.auditFirm) {
      history.push(localizeRouteKey(formatMessage, locale, AppRoute.Extractions))
    }
  }, [])

  const handleEntitychange = (e, val) => {
    setSelectedentities(e.target.value);
  }

  const resetState = () => {
    dispatch(resetExtractionSettings({client}))
    dispatch(createExtraction({name: '', connection: '' }))
    setConfigData({})
    window.history.go(-1)
  }


  // Additional Validation.
  // Json forms is constantly refreshing, so any attributes related to it 
  // will also be in a constant state of change. States associated with more static components like
  // the name and selected entities fields that we have available on this page
  // would quickly be overwritten by the ones associated with the jsonForm component
  // for this reason it is preferrable to separate them.
  useEffect(() => {
    if (name.length <= 0 || selectedEntities.length <= 0) {
      setValidation({
        valid: false,
        message: "Fields with an asterisk '*' are mandatory."
      });
    } else {
      setValidation({
        valid: true,
        message: ""
      });
    }
  }, [configData, name, selectedEntities])

  const JsonRenderers = [
    ...vanillaRenderers,
    { tester: DatePickerTester, renderer: DatePickerControl },
    { tester: DateTimePickerTester, renderer: DateTimePickerControl },
  ]
  
  return (
    process.env.NX_ENABLE_API_EXTRACTIONS === 'true' 
    ? (
      <section className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography className={classes.header} variant={'h4'} color={'textPrimary'}>
              Add Extraction
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.dialogContent}>
          <Grid item xs={12}>
            <FormHelperText className={classes.formsError} error data-testid="json-forms-validation-message">{validation.message}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Description title={'Extraction Name'} required>
              <EBInput 
                width={NEW_UI_INPUT_WIDTH + 'px'} 
                color="secondary" 
                value={ name ? name : "" }
                placeholder='Enter Extraction Name'
                onChange={(e) => extractionNameCreate(e)}
              />
            </Description>
          </Grid>

          <Grid item xs={12}>
            <Description title={'Connection'} required>
              <EBAutoComplete
                forcePopupIcon
                width={NEW_UI_INPUT_WIDTH}
                height={44}
                color="secondary"
                size="small"
                placeholder="Select Connection"
                options={extractions.clientConnections}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, val, reason) => updateConnection(val, reason)}
              />
            </Description>
          </Grid>

          {(
            extractions.erpEntities.length > 0 
            && erpConnectionId.length > 0
          ) &&
            <Grid item xs={12}>
              <Description title={'Entities'} required>
                <FormControl>
                  <InputLabel id="entities-select-label">Please Select Entities</InputLabel>
                  <Select
                    labelId='entities-select-label'
                    inputProps={{ "data-testid": "entities-select" }}
                    name="Entities"
                    multiple
                    style={{"width": "400px"}}
                    label="Please Select Entities"
                    value={selectedEntities}
                    onChange={(e, val) => handleEntitychange(e, val)}
                    renderValue={(value:[]) => value.length > 0 
                      ? value.map((v) => (<Chip style={{"marginRight": "8px"}}color="primary" label={v}/>)) 
                      : "Please Select Entities"}
                  >
                    {extractions.erpEntities.map((entity) => (
                      <MenuItem value={entity} key={entity}>
                        <Checkbox checked={selectedEntities.indexOf(entity) > -1} />
                        <ListItemText primary={entity} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Description>
            </Grid>
          }

          {
            (!_.isEmpty(preAuthOptions)
            && erpConnectionId.length > 0
            ) &&
            <>
              <Grid item xs={12} className={classes.jsonFormsContainer}>
                <Typography 
                  variant="h5" 
                  color="textPrimary"
                  className={classes.jsonFormsContainerTypography}
                >
                  Pre-Authentication Configuration
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.jsonFormsContainer}>
                <JsonForms
                  schema={preAuthOptions}
                  data={configData}
                  renderers={JsonRenderers}
                  ajv={ajvInstance}
                  cells={vanillaCells}
                  onChange={({ data, errors }) => {
                    setConfigData(data)
                    setConfigErrors(errors)
                  }}
                />
              </Grid>
            </>
          }
          
          <Grid item xs={12} style={{ justifyContent: 'right' }}>
          <Button
            style={{
              width: '140px',
              height: '44px',
              textAlign: 'center',
              lineHeight: '32px',
              marginRight: '8px',
            }}
            onClick={() => resetState()}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            style={{
              width: '140px',
              height: '44px',
              textAlign: 'center',
              lineHeight: '32px',
              marginRight: '8px',
            }}
            onClick={() => addExtraction()}
            disabled={
              name.length === 0 ||
              erpConnectionId.length === 0 ||
              selectedEntities.length === 0 ||
              configErrors.length > 0 ||
              !validation.valid ||
              extractions.extractionCreationInProgress
            }
            color="secondary"
            variant="contained"
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </section>
  ) : (
    <NotFoundView />
  )
  )
}

export default ManageExtractionsView
