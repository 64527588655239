import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Select } from '@engine-b/shared/components'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { ReactComponent as IconDelete } from './assets/ic_delete.svg'
import AddIcon from '@material-ui/icons/Add'
import {
  JOIN_DIRECTION,
  rule,
  addRule,
  changeRuleValue,
  deleteOneRule,
  changeStartsWithValue,
  updateJoin,
  updateRuleTargetOptions,
  updateRuleOptions,
  useIEDispatch,
  useIESelector,
  resetOperations,
  reArrangeRules,
  resetOperation,
} from '@engine-b/integration-engine/data/state/redux'
import { DraggableList, DraggableItem } from '@engine-b/shared/components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  responsiveFlex: {
    display: 'flex',
    flexDirection: 'row',
    ['@media(max-width:1280px)']: {
      flexDirection: 'column',
    },
    '& .MuiOutlinedInput-root': {
      width: '50%',
      marginRight: '10px',
      ['@media(max-width:1280px)']: {
        width: '100%',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  spacingBar: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginOn: {
    marginLeft: '26px',
    marginTop: '24px',
    marginRight: '29px',
  },
  andSeparator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: '12px',
    fontWeight: 400,
  },
  marginStartWith: {
    marginTop: '18px',
    marginRight: '18px',
    whiteSpace: 'nowrap',
  },
  style: {
    style: theme.spacing(5),
  },
  formControl: {
    width: '100%',
    margin: '0px',
  },
  formSpace: {
    margin: theme.spacing(1),
    marginTop: '24px',
    minWidth: 45,
  },
  formControlJoint: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
  },
  joinDescription: {
    fontSize: '12px',
    marginTop: '18px',
  },
  joinDescriptionDirection: {
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    margin: theme.spacing(1),
    textAlign: 'end',
  },
  formControlWidth: {
    minWidth: 135,
    margin: theme.spacing(2),
  },
  formControlStartWith: {
    margin: theme.spacing(3),
    minWidth: 200,
  },
  infoCard1: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #44697D33',
    borderRadius: '10px',
    margin: '20px 0 30px',
    display: 'flex',
    padding: '20px',
  },
  infoCard2: {
    background: '#F0FBFA  0% 0% no-repeat padding-box',
    border: '1px solid #44697D33',
    borderRadius: '5px',
    margin: '50px 0 1px -50px',
  },
  joinToLabel: {
    display: 'flex',
  },
  joinToRightWrap: {
    display: 'inline-block',
    width: '100%',
    padding: '18px',
    borderRadius: '8px',
    backgroundColor: 'hsl(205deg 4% 52% / 5%)',
    border: '1px solid #44697D33',
    marginBottom: '1%',
  },
  ruleBox: {
    // padding: '15px',
    marginTop: '15px',
  },
  ruleRightWrap: {
    display: 'inline-block',
    width: '100%',
    padding: '18px',
    borderRadius: '8px',
    backgroundColor: 'white',
    border: 'solid 1px',
    marginTop: '1%',
  },
  deleteTableButtonIcon: {
    width: '40px',
    cursor: 'pointer',
  },
}))

interface Items {
  value: string | number
  label: string | number
  disabled?: boolean
}

interface ruleItems {
  table: Items
  column: Items
}

interface rulesEntity {
  origin: ruleItems
  target: ruleItems
}

interface operations {
  direction: Items
  joinTo: Items
  rules: rulesEntity[]
}

interface JointComponentProps {}

export const JointComponent = (props: JointComponentProps) => {
  const { infoCard1 } = useStyles()
  const { infoCard2 } = useStyles()
  const classes = useStyles()
  const operationRed = useIESelector((state) => state.joins)
  const dispatch = useIEDispatch()
  const joins = [
    {
      value: JOIN_DIRECTION.LEFT,
      label: JOIN_DIRECTION.LEFT,
    },
    {
      value: JOIN_DIRECTION.INNER,
      label: JOIN_DIRECTION.INNER,
    },
    {
      value: JOIN_DIRECTION.OUTER,
      label: JOIN_DIRECTION.OUTER,
    },
  ]

  const { startsWith = '', operations = [] } = operationRed

  const directionDescription = (index: number): Object => ({
    Left: `The Left Join returns all records from the left table (${
      startsWith || 'table1'
    }), and the matching records from the right table (${
      operations[index]?.joinTo || 'table2'
    }). The result is 0 records from the right side, if there is no match.`,
    Inner:
      'The Inner Join returns all records that have matching values in both tables.',
    Outer: `The Outer Join returns all records when there is a match in left (${
      startsWith || 'table1'
    }) or right (${operations[index]?.joinTo || 'table2'}) table records.`,
  })

  const addNewRule = (index: number) => {
    dispatch(addRule({ index }))
    const validOptions = getValidTargetColumns(index)
    const ruleIndex = operationRed.operations[index].rules.length
    changeRule(
      index,
      ruleIndex,
      'origin',
      'table',
      operationRed.operations[index].joinTo
    )
    if (validOptions.length > 0) {
      changeRule(index, ruleIndex, 'target', 'table', validOptions[0].value)
    }
  }

  const changeRule = (
    index: number,
    ruleIndex: number,
    ruleType: 'origin' | 'target',
    tableType: 'table' | 'column',
    value: string
  ) => {
    if (ruleType === 'target' && tableType === 'table') {
      dispatch(
        updateRuleTargetOptions({
          index,
          ruleIndex,
          value: { table: value, column: '' },
        })
      )
    }
    dispatch(
      updateRuleOptions({ index, ruleIndex, ruleType, tableType, value })
    )
  }

  const deleteRule = (index: number, ruleIndex: number) => {
    dispatch(deleteOneRule({ index, ruleIndex }))
  }

  const changeValue = (
    index: number,
    value: number | string | rule,
    title: 'joinTo' | 'direction'
  ) => {
    dispatch(changeRuleValue({ index, value, title }))
  }

  const changeJoinToTable = (index: number, value: string | number) => {
    const currOpIndex = operations.findIndex((op) => {
      return value === op.joinTo
    })
    if (currOpIndex > -1) {
      dispatch(resetOperation({ opIndex: index }))
    }
    changeValue(index, value, 'joinTo')
    changeRule(index, 0, 'origin', 'table', value as string)
    changeRule(index, 0, 'origin', 'column', '')

    const validOptions = getValidTargetColumns(index)
    // In order to ensure that there are no blank rules when automatically populating each row,
    // we select the last valid option to populate the target - table dropdown (validOptions[validOptions.length - 1]).
    // This ensures that when / if the user changes the table origin, there will always be a value automatically populated.
    if (validOptions.length > 0)
      changeRule(
        index,
        0,
        'target',
        'table',
        validOptions[validOptions.length - 1].value
      )
  }

  const [selectedValues, setSelectedValues] = useState([])
  const changeStartsWith = (value: string) => {
    dispatch(resetOperations(1))
    dispatch(changeStartsWithValue({ value }))
  }

  let operationR = operationRed
  let items = Object.values(operationRed.tables).map((val, index) => {
    return { value: val.name, label: val.name }
  })

  const getValidTargetColumns = (opIndex: number) => {
    const tablesAbove = operationR.operations
      .filter((op, i) => {
        return i < opIndex
      })
      .map(({ joinTo }) => {
        return { label: joinTo, value: joinTo }
      })
      .concat({ label: operationR.startsWith, value: operationR.startsWith })
    return tablesAbove
  }

  function getItems(tableIndex: string) {
    if (tableIndex === '') {
      return []
    } else {
      return operationRed.tables[tableIndex]?.virtual_table_columns.length > 0
        ? operationRed.tables[tableIndex].virtual_table_columns.map((val) => {
            return {
              value: val,
              label: val,
            }
          })
        : []
    }
  }

  function onDragEnd(e: any) {
    let ops = [...operationRed.operations]

    if (e.destination && e.source && e.destination.index !== e.source.index) {
      const slice = ops.splice(e.source.index, 1)
      ops.splice(e.destination.index, 0, slice[0])
      dispatch(reArrangeRules({ ops }))
    }

    operationRed.operations.forEach((op, index) => {
      op.rules.forEach((rule, ruleIndex) => {
        const valid_target = getValidTargetColumns(index)
        const value = {
          table: valid_target[valid_target.length - 1].value,
          column: '',
        }
        dispatch(updateRuleTargetOptions({ index, ruleIndex, value }))
      })
    })
  }

  return (
    <div className={infoCard1}>
      <div className={classes.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={3}>
                <label className={classes.marginStartWith}>Start With</label>
              </Grid>

              <Grid item xs={12} lg={7}>
                <Select
                  items={items}
                  onChangeHandler={(value) => {
                    changeStartsWith(value)
                  }}
                  value={startsWith === '' ? -1 : startsWith}
                  placeholder="Please Select First Table"
                />
              </Grid>
              <Grid item xs="auto" lg={2}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <DraggableList
          droppableId={'joins-operations-droppable'}
          onDragEnd={(e: any) => onDragEnd(e)}
        >
          {operationR.operations &&
            operationR.operations.map((operation, i) => (
              <DraggableItem
                index={i}
                draggableId={'joins-operation-draggable-' + i}
                key={`operation_${i}`}
              >
                <Box className={classes.joinToLabel}>
                  <label className={classes.formControlJoint}>Join To</label>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} lg={5}>
                        <Select
                          placeholder="Please Select Join Direction"
                          key={i}
                          items={joins}
                          value={operation.direction}
                          onChangeHandler={(value) => {
                            changeValue(i, value, 'direction')
                          }}
                        />
                        <Box
                          component="div"
                          display={{ xs: 'block', lg: 'none' }}
                        >
                          <Typography className={classes.joinDescription}>
                            <span className={classes.joinDescriptionDirection}>
                              {operation.direction}
                            </span>
                            {' : '}
                            {directionDescription(i)[operation.direction]}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <Select
                          placeholder="Please Select Virtual Table"
                          key={i}
                          items={items.filter(
                            (val) =>
                              val.value !== startsWith &&
                              !selectedValues.slice(0, i).includes(val.value)
                          )}
                          onChangeHandler={(value) => {
                            changeJoinToTable(i, value)
                            setSelectedValues((prevValues) => {
                              const newValues = [...prevValues]
                              newValues[i] = value
                              return newValues
                            })
                          }}
                          value={
                            operation.joinTo === '' ? -1 : operation.joinTo
                          }
                        />
                      </Grid>

                      <Grid item xs={12} lg={2}>
                        <span
                          className={classes.formControlJoint}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          ON
                        </span>
                      </Grid>
                      <Grid item>
                        <Box
                          component="div"
                          display={{ xs: 'none', lg: 'block' }}
                        >
                          <Typography className={classes.joinDescription}>
                            <span className={classes.joinDescriptionDirection}>
                              {operation.direction}
                            </span>
                            {' : '}
                            {directionDescription(i)[operation.direction]}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={8} className={classes.joinToRightWrap}>
                    {operation.rules?.map((rule, index) => (
                      <Box
                        className={classes.ruleBox}
                        key={`operations_${index}`}
                      >
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          style={{ marginBottom: '3px' }}
                        >
                          <Grid item xs={1}>
                            {index !== 0 && (
                              <span className={classes.andSeparator}>AND</span>
                            )}
                          </Grid>

                          <Grid item xs={4}>
                            <Box className={classes.responsiveFlex}>
                              <Select
                                placeholder="Please Select Virtual Table"
                                key={`origin_table` + index}
                                items={[
                                  {
                                    label: operation.joinTo,
                                    value: operation.joinTo,
                                  },
                                ]}
                                selectProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    marginBottom: '8px',
                                  },
                                }}
                                onChangeHandler={(value) =>
                                  changeRule(i, index, 'origin', 'table', value)
                                }
                                value={
                                  rule.origin.table === ''
                                    ? -1
                                    : rule.origin.table
                                }
                              />
                              <Select
                                placeholder="Please Select Virtual Table Column"
                                key={`origin_column` + index}
                                items={getItems(rule.origin.table)}
                                selectProps={{
                                  style: {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onChangeHandler={(value) =>
                                  changeRule(
                                    i,
                                    index,
                                    'origin',
                                    'column',
                                    value
                                  )
                                }
                                value={
                                  rule.origin.column === ''
                                    ? -1
                                    : rule.origin.column
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              EQUALS
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <Box className={classes.responsiveFlex}>
                              <Select
                                placeholder="Please Select Virtual Table"
                                key={`target_table_${index}`}
                                items={getValidTargetColumns(i)}
                                selectProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    marginBottom: '8px',
                                  },
                                }}
                                onChangeHandler={(value) =>
                                  changeRule(i, index, 'target', 'table', value)
                                }
                                value={
                                  rule.target.table === ''
                                    ? -1
                                    : rule.target.table
                                }
                              />
                              <Select
                                placeholder="Please Select Virtual Table Column"
                                key={`target_column_${index}}`}
                                items={getItems(rule.target.table)}
                                selectProps={{
                                  style: {
                                    backgroundColor: 'white',
                                  },
                                }}
                                onChangeHandler={(value) =>
                                  changeRule(
                                    i,
                                    index,
                                    'target',
                                    'column',
                                    value
                                  )
                                }
                                value={
                                  rule.target.column === ''
                                    ? '-1'
                                    : rule.target.column
                                }
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={1}>
                            {index !== 0 && (
                              <span
                                onClick={() => deleteRule(i, index)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconDelete
                                  className={classes['deleteTableButtonIcon']}
                                />
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    <Grid item xs={12}>
                      <Box className={classes.button}>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<AddIcon />}
                          style={{
                            borderRadius: '8px',
                          }}
                          onClick={() => addNewRule(i)}
                        >
                          Add New Condition
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </DraggableItem>
            ))}
        </DraggableList>
      </div>
    </div>
  )
}

export default JointComponent
