import { gql } from '@apollo/client'

export const CREATE_ERP_CONNECTION = gql`
  mutation createErpConnection(
      $name: String! 
      $engagementId: String! 
      $system: String! 
      $authorisationUrl: String!
      $preAuthConfigValues: JSON!
    ) {
    createErpConnection(
      name: $name 
      engagementId: $engagementId 
      system: $system 
      authorisationUrl: $authorisationUrl
      preAuthConfigValues: $preAuthConfigValues
    ) {
      name
    }
  }
`
