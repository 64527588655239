import { gql } from '@apollo/client'

export const GET_ERPS = gql`
  query GetErps {
    erps {
      id
      name
      standard
      pipelineId
      extractType
      erpType
      auditFirm {
        id
        name
        systemName
      }
      isActive
    }
  }
`

export const SYSTEMS_SEARCH = gql`
  query SystemsSearch($user: String! $page: Int! $results: Int! $filter: String) {
    systemsSearch(user: $user page: $page results: $results filter: $filter) {
      page
      total
      systems {
        id
        name
        standard
        pipelineId
        extractType
        auditFirm {
          id
          name
          systemName
        }
      }
      success
      message
    }
  }
`