import { AuditFirm, preUploadFiles } from '@engine-b/shared/types'

export enum ANALYTICS_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIALIZED = 'INITIALIZED',
  FAILED_TO_PROCESS = 'FAILED_TO_PROCESS',
}

export type analyticsstatustitlemap = Record<ANALYTICS_STATUS, string>

export const ANALYTICS_STATUS_TITLES: analyticsstatustitlemap = {
  [ANALYTICS_STATUS.IN_PROGRESS]: 'In-progress',
  [ANALYTICS_STATUS.COMPLETED]: 'Completed',
  [ANALYTICS_STATUS.FAILED]: 'Failed',
  [ANALYTICS_STATUS.INITIALIZED]: 'Initialized',
  [ANALYTICS_STATUS.FAILED_TO_PROCESS]: 'Failed to Process',
}

export enum JOIN_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIALIZED = 'INITIALIZED',
  FAILED_TO_PROCESS = 'FAILED_TO_PROCESS',
}

export type joinStatusTitleMap = Record<JOIN_STATUS, string>

export const JOIN_STATUS_TITLES: joinStatusTitleMap = {
  [JOIN_STATUS.IN_PROGRESS]: 'In-progress',
  [JOIN_STATUS.COMPLETED]: 'Completed',
  [JOIN_STATUS.FAILED]: 'Failed',
  [JOIN_STATUS.INITIALIZED]: 'Initialized',
  [JOIN_STATUS.FAILED_TO_PROCESS]: 'Failed to Process',
}

export const ANALYTICS_STATUS_TITLES_COLORS: analyticsstatustitlemap = {
  [ANALYTICS_STATUS.COMPLETED]: '#37AB3F',
  [ANALYTICS_STATUS.IN_PROGRESS]: '#F7931E',
  [ANALYTICS_STATUS.INITIALIZED]: '',
  [ANALYTICS_STATUS.FAILED]: '#DE4D4D',
  [ANALYTICS_STATUS.FAILED_TO_PROCESS]: '#DE4D4D',
}

export const JOIN_STATUS_COLORS: joinStatusTitleMap = {
  [JOIN_STATUS.COMPLETED]: '#37AB3F',
  [JOIN_STATUS.IN_PROGRESS]: '#F7931E',
  [JOIN_STATUS.INITIALIZED]: '',
  [JOIN_STATUS.FAILED]: '#DE4D4D',
  [JOIN_STATUS.FAILED_TO_PROCESS]: '#DE4D4D',
}

export enum JOIN_DIRECTION {
  LEFT = 'Left',
  RIGHT = 'Right',
  INNER = 'Inner',
  OUTER = 'Outer',
}

export enum OperationType {
  COMBINE = 'COMBINE',
  COMBINEANDJOIN = 'COMBINEANDJOIN',
}

export enum JOINS_UPLOAD_STATE {
  COMPLETE = 'Complete',
  ERROR = 'Error',
  UNSUPPORTED_FILE_TYPE = 'Unsupported File Type',
  IN_PROGRESS = 'Upload In Progress',
  NOT_STARTED = 'Not Started',
  VALIDATING_FILE = 'Validating The Input File',
  VALIDATING_FILE_COMPLETE = 'Input File Validation Complete',
  VALIDATING_FILE_FAILED = 'Input File Validation Failed',
  FETCHING_COLUMNS = 'Fetching Input File Columns',
  FETCHING_COLUMNS_COMPLETE = 'Input File Column Fetching Complete',
  FETCHING_COLUMNS_FAILED = 'Input File Column Fetching Failed',
}

export enum FILE_TYPE {
  UPLOADED = 'UPLOADED',
  PREUPLOADED = 'PREUPLOADED',
}
export interface file {
  name: string
  path: string
  columns: Array<string>
  uploadState: JOINS_UPLOAD_STATE
  fileSize: number
  uploaded: number
  requestId: string
  fileValidationMessage?: string
  fileType: FILE_TYPE
}

export interface table {
  name: string
  virtual_table_columns: string[]
  files: {
    [key: string]: file
  }
  isDeleteDisabled: boolean
}

export interface rule {
  origin: {
    table: string
    column: string
  }
  target: {
    table: string
    column: string
  }
}

export enum PRE_UP_LOADING_STATE {
  DONE = 'done',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface joinOperation {
  direction: JOIN_DIRECTION
  joinTo: string
  rules: Array<rule>
}

export interface joinListingItem {
  name: string
  status: string
  startDate: string
  endDate: string
  user: string
  engagement: {
    id: string
    name: string
    auditedEntity: {
      id: string
      name: string
      auditFirm: AuditFirm | null
    }
  }
  isDownloading: boolean
  downloadProgress: number
}

export interface join {
  tables: {
    [key: string]: table
  }
  operations: Array<joinOperation>
  startsWith: string
  name: string
  validName: boolean
  joinId: string
  engagement: {
    id: string
    name: string
  }
  auditedEntity: {
    id: string
    name: string
    auditFirm: AuditFirm | null
  }
  preUpFiles: Array<file>
  isLoading: PRE_UP_LOADING_STATE
  joinListing: Array<joinListingItem>
  loading: boolean
  operationType: OperationType
}
