import { gql } from '@apollo/client'

export const CREATE_ANALYTICS_PIPELINE = gql`
  mutation createAnalyticsPipeline (
    $name: String!
    $engagementId: String!
  ) {
    createAnalyticsPipeline(
      name: $name
      engagementId: $engagementId
    ) {
      id
      name
    }
  }
`
