import React from 'react'
import FileSelector, {
  FileMappingDataProps,
} from '../file-selector/file-selector'
import { StyleRules, makeStyles } from '@material-ui/core'
import PreUploadedFiles from '../pre-uploaded-files/pre-uploaded-files'
import ClientEngagementDialog from './client-engagement-dialog'

/* eslint-disable-next-line */
/* isUploadInfo: A boolean prop used to show or hide the upload info message component.
isPreUploadFile: A boolean prop used to show or hide the "Select Pre-Uploaded Files" button.
isUploadedFile: A boolean prop used to show or hide the "Choose File" button.
isDisplayVertical: A boolean prop used to display the FileSelector component in vertical or horizontal orientation.
enableDragAndDrop: A boolean prop used to enable or disable drag and drop functionality.
client: A prop of any type that represents the client. This prop is required.
locale: A string prop that represents the locale. This prop is required.
engagement: An optional string prop representing the engagement.
uploadLabel: A string prop representing the label of the upload.
disableUpload: A boolean prop used to disable the upload functionality.
style: An optional prop representing the style rules.
showOnDragMessage: An optional boolean prop used to show or hide the message on drag.
uploadFile: A function prop used to handle the file upload event.
onDragOver: A function prop used to handle the drag over event.
handleButtonClick: A function prop used to handle button click events.
id: An optional string prop representing the id.
open: A boolean prop used to open or close something.
onClose: A function prop used to handle the close event.
dialogOpen: An optional boolean prop used to open or close a dialog.
selectClientEngagement: An optional function prop used to select the client engagement.
onAuditedEntityChange: An optional function prop used to handle the change in audited entity.
onEngagementChange: An optional function prop used to handle the change in engagement.
selectedClientDetails: An optional object prop representing the selected client details.
selectedEngagementDetails: An optional object prop representing the selected engagement details.
dialogTitle: A string prop representing the title of the dialog.
auditedEntity: An optional string prop representing the audited entity.
engagementId: An optional string prop representing the engagement id.
allowMultiple: A boolean prop used to allow or disallow multiple selections.
preUploadedFilePath: An optional prop representing the pre-uploaded file path.
fileMappingData: An optional prop representing the file mapping data.
selectedReportDetail: An optional prop representing the selected report detail.
selectedCdmEntity: An optional prop representing the selected CDM entity.
type: An optional string prop representing the type.
index: An optional number prop representing the index.
disableExtraction: An optional boolean prop used to disable the extraction functionality.
disableJoin: An optional boolean prop used to disable the join functionality.
disablePreUpload: An optional boolean prop used to disable the pre-upload functionality. */

export interface FileUploaderProps {
  isUploadInfo: boolean
  isPreUploadFile: boolean
  isUploadedFile: boolean
  isDisplayVertical: boolean
  enableDragAndDrop: boolean
  client: any
  locale: string
  engagement?: string
  uploadLabel: string
  disableUpload: boolean
  style?: StyleRules
  showOnDragMessage?: boolean
  uploadFile: (e: any, id?: string) => void
  onDragOver: (e: any) => void
  handleButtonClick: (e: any) => void
  id?: string
  open: boolean
  onClose: Function
  dialogOpen?: boolean
  selectClientEngagement?: (e: any) => void
  onAuditedEntityChange?: (e: any, value: any, reason: string) => void
  onEngagementChange?: (e: any, value: any, reason: string) => void
  selectedClientDetails?: object
  selectedEngagementDetails?: object
  dialogTitle: string
  auditedEntity?: string
  engagementId?: string
  allowMultiple: boolean
  preUploadedFilePath?: string
  fileMappingData?: FileMappingDataProps
  selectedReportDetail?: any
  selectedCdmEntity?: any
  type?: string
  index?: number
  disableExtraction?: boolean
  disableJoin?: boolean
  disablePreUpload?: boolean
  value?: string
  setValue?: (value: string) => void
  loading?: boolean
  uploaded?: boolean
  handleInputChange?: (e: any, value: any) => void
  options?: any[]
}

const useStyles = makeStyles({
  fileSelector: {
    width: '100%',
    display: 'flex',
    ['@media (max-width:1300px)']: {
      flexWrap: 'wrap',
    },
  },
})

export function FileUploader(props: Readonly<FileUploaderProps>) {
  const { fileSelector } = useStyles()
  return (
    <div className={fileSelector}>
      <FileSelector
        uploadFile={props.uploadFile}
        id={props.id}
        uploadLabel={props.uploadLabel}
        style={props.style}
        onDragOver={props.onDragOver}
        locale={props.locale}
        disableUpload={props.disableUpload}
        handleButtonClick={props.handleButtonClick}
        fileMappingData={props.fileMappingData}
        selectedReportDetail={props.selectedReportDetail}
        selectedCdmEntity={props.selectedCdmEntity}
        type={props.type}
        index={props.index}
        isUploadInfo={props.isUploadInfo}
        isUploadedFile={props.isUploadedFile}
        isPreUploadFile={props.isPreUploadFile}
        isDisplayVertical={props.isDisplayVertical}
        enableDragAndDrop={props.enableDragAndDrop}
        allowMultiple={props.allowMultiple}
        value={props.value}
        setValue={props.setValue}
        loading={props.loading}
        uploaded={props.uploaded}
        handleInputChange={props.handleInputChange}
        options={props.options}
      />
      {props.auditedEntity && props.engagementId ? (
        <PreUploadedFiles
          open={props.open}
          onClose={props.onClose}
          dialogTitle={props.dialogTitle}
          auditedEntity={props.auditedEntity}
          engagementId={props.engagementId}
          allowMultiple={props.allowMultiple}
          preUploadedFilePath={props.preUploadedFilePath}
          disableExtraction={props.disableExtraction}
          disableJoin={props.disableJoin}
          disablePreUpload={props.disablePreUpload}
          client={props.client}
          locale={props.locale || ''}
        />
      ) : (
        <ClientEngagementDialog
          dialogOpen={props.dialogOpen}
          selectClientEngagement={props.selectClientEngagement}
          onAuditedEntityChange={props.onAuditedEntityChange}
          onEngagementChange={props.onEngagementChange}
          selectedClientDetails={props.selectedClientDetails}
          selectedEngagementDetails={props.selectedEngagementDetails}
          client={props.client}
        />
      )}
    </div>
  )
}

export default FileUploader
