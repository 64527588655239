import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import React, { useContext, useState, useEffect } from 'react'
import { ReactComponent as IconDownload } from '../../../assets/IconDownload.svg'
import { Run } from '@engine-b/integration-engine/data/state/redux'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import { saveAs } from 'file-saver'

const useStyles = makeStyles((theme) =>
  createStyles({
    dataHeaders: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      marginTop: 10,
      letterSpacing: '0.18px',
      marginBottom: '12px',
      color: '#44697D',
      display: 'in-line',
      marginLeft: '20px',
      justify: 'space-between',
    },
    dataValues: {
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.22px',
      color: '#22353F',
      fontWeight: 'normal',
      '& > li': {
        padding: 0,
      },
    },
    tableData: {
      '& .MuiTableCell-root': {
        border: '1px solid    rgba(224, 224, 224, 1)',
      },
    },
    tableHeader: {
      '& .MuiTableCell-head': {
        backgroundColor: 'lightgray',
      },
    },
  })
)


const XS_GRID_SIZE = 12
const LG_GRID_SIZE = 3

const OutputFunction = ({ bundleFn, auditAnalyticsId, dataIngestionContainer }) => {
  const azureClient = useContext(AzureClientContext)
  const classes = useStyles()
  const [outputHeaders, setOutputHeaders] = useState([])
  const [outputRows, setOutputRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const {
    order,
    function: { name },
    status,
  } = bundleFn

  useEffect(() => {
    (async function callReadJson() {
      await readJson(
        azureClient,
        dataIngestionContainer,
        auditAnalyticsId
      )
    })()
  }, [])
  

  const readJson = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container'],
    operationLogId
  ) => {
    try {
      const containerClient = azureClient.getFileSystemClient(
        diContainer.fileSystemId
      )
      const fileClient = containerClient.getFileClient(
        `${diContainer.sharePath}/daa/${operationLogId}/out/tmp_${name}_${order}.json`
      )
      const downloadResponse = await fileClient.read()
      const downloadBlob = await downloadResponse.contentAsBlob
      const blob = new Blob([downloadBlob], { type: 'application/json' })
      const dqsJson = await blob.text()
      const { data, count } = JSON.parse(dqsJson)
      const tableHeaders = Object.keys(data[0])
      setOutputHeaders(tableHeaders)
      setOutputRows(data)
      setTotalRows(count)
    } catch (error) {
      console.log(error)
    }
  }


  const downloadOutPutFile = async (
  ) => {
    const containerClient = azureClient.getFileSystemClient(
      dataIngestionContainer.fileSystemId
    )
    const FILE_NAME = `tmp_${name}_${order}.csv`
    const fileClient = containerClient.getFileClient(
      `${dataIngestionContainer.sharePath}/daa/${auditAnalyticsId}/out/${FILE_NAME}`
    )
    const downloadResponse = await fileClient.read()
    const downloadBlob = await downloadResponse.contentAsBlob
    saveAs(downloadBlob, FILE_NAME)
  }

  return (
    <Grid item xs={XS_GRID_SIZE}>
      <Grid container justify="space-between">
        <Grid style={{ display: 'contents' }} item xs={LG_GRID_SIZE}>
          <Typography className={classes.dataHeaders}>
            Function Name: <span>{name}</span>
          </Typography>
          <Typography className={classes.dataHeaders}>
            Data Count:&nbsp;
            <span className={classes.dataValues}>{outputRows.length} of {totalRows}</span>
          </Typography>
          {/* Below commented code will be needed in future updates */}
          {/* <Typography className={classes.dataHeaders}>
            Status:&nbsp; 
            <span style={{ color: STATUS_COLORS[status] }}>
              {STATUS_TITLES[status]}
            </span>
          </Typography> */}
        </Grid>

        <Grid
          spacing={2}
          style={{ display: 'inline-flex', alignItems: 'center' }}
        >
          {/* Below commented code will be needed in future updates  */}
          {/* {
            status === "FAILED" ?
              <Button
                style={{
                  minWidth: 'unset',
                  width: 'unset',
                  marginRight: '20px',
                }}
                color="secondary"
                variant="contained"
                component={Link}
                to={`/en-GB/audit-analytics-create/${auditAnalyticsId}`}
              >
                <EditIcon />
              </Button>
              : null
          } */}
          {
            status !== "FAILED" ?
              <Button
                style={{
                  minWidth: 'unset',
                  width: 'unset',
                  marginRight: '20px',
                  height: '37px',
                }}
                color="secondary"
                variant="contained"
                onClick={() =>
                  downloadOutPutFile()
                }

              >
                <IconDownload />
              </Button>
              : null
          }
        </Grid>

        <TableContainer className={classes.tableData}>
          <Table area-label="simple table">
            <TableHead>
              <TableRow>
                {outputHeaders?.map((data, ind) => (
                  <TableCell key={`head-${ind}`}>{data}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {outputRows?.map((data, i) => (
                <TableRow key={`row-${i}`}>
                  {
                    Object.values(data).map((row, ind) => (
                      <TableCell component="th" scope="row" key={`tc-${ind}`}>
                        {row}
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default OutputFunction
